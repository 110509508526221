import {
  PUBLIC_LEAD_CREATE_REQUESTED,
  PUBLIC_LEAD_CREATE_FAILED,
  PUBLIC_LEAD_CREATE_SUCCEEDED,
  LEAD_SEARCH_FAILED,
  LEAD_SEARCH_REQUESTED,
  LEAD_SEARCH_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  publicLeadCreateError: false,
  publicLeadCreateFetching: false,
  publicLeadCreateSuccess: false,

  leadSearchError: false,
  leadSearchFetching: false,
  leadSearchSuccess: false,

  errorMsg: "",
};

const defaultState = {
  publicLeadDetail: {},
  leads: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case LEAD_SEARCH_REQUESTED:
      return {
        ...state,
        leadSearchFetching: true,
        leadSearchError: false,
        leadSearchSuccess: false,
        errorMsg: "",
      };
    case LEAD_SEARCH_FAILED:
      return {
        ...state,
        leadSearchFetching: false,
        leadSearchError: true,
        leadSearchSuccess: false,
        errorMsg: action.payload,
      };
    case LEAD_SEARCH_SUCCEEDED:
      return {
        ...state,
        leadSearchFetching: false,
        leadSearchError: false,
        leadSearchSuccess: true,
        leads: action.payload,
        errorMsg: "",
      };
    case PUBLIC_LEAD_CREATE_REQUESTED:
      return {
        ...state,
        publicLeadCreateFetching: true,
        publicLeadCreateError: false,
        publicLeadCreateSuccess: false,
        errorMsg: "",
      };
    case PUBLIC_LEAD_CREATE_FAILED:
      return {
        ...state,
        publicLeadCreateFetching: false,
        publicLeadCreateError: true,
        publicLeadCreateSuccess: false,
        errorMsg: action.payload,
      };
    case PUBLIC_LEAD_CREATE_SUCCEEDED:
      return {
        ...state,
        publicLeadDetail: action.payload,
        publicLeadCreateFetching: false,
        publicLeadCreateError: false,
        publicLeadCreateSuccess: true,
        errorMsg: "",
      };
    default:
      return state;
  }
}
