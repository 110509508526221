import {
  CHECKIN_OLD_CREATE_REQUESTED,
  CHECKIN_OLD_CREATE_FAILED,
  CHECKIN_OLD_CREATE_SUCCEEDED,
  CHECKIN_NEW_CREATE_REQUESTED,
  CHECKIN_NEW_CREATE_FAILED,
  CHECKIN_NEW_CREATE_SUCCEEDED,
  CHECKIN_GET_ALL_REQUESTED,
  CHECKIN_GET_ALL_FAILED,
  CHECKIN_GET_ALL_SUCCEEDED,
  CHECKIN_UPDATE_REQUESTED,
  CHECKIN_UPDATE_FAILED,
  CHECKIN_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
  CHECKIN_TOGGLE_SHOW_ACTIVE,
} from "../actions";

const defaultError = {
  checkinOldCreateError: false,
  checkinOldCreateFetching: false,
  checkinOldCreateSuccess: false,

  checkinNewCreateError: false,
  checkinNewCreateFetching: false,
  checkinNewCreateSuccess: false,

  checkinUpdateError: false,
  checkinUpdateFetching: false,
  checkinUpdateSuccess: false,

  checkinGetAllError: false,
  checkinGetAllFetching: false,
  checkinGetAllSuccess: false,

  errorMsg: "",
};

const defaultState = {
  checkins: [],
  showActive: true,
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case CHECKIN_NEW_CREATE_REQUESTED:
      return {
        ...state,
        checkinNewCreateFetching: true,
        checkinNewCreateError: false,
        checkinNewCreateSuccess: false,
      };
    case CHECKIN_NEW_CREATE_FAILED:
      return {
        ...state,
        checkinNewCreateFetching: false,
        checkinNewCreateError: true,
        checkinNewCreateSuccess: false,
        errorMsg: action.payload,
      };
    case CHECKIN_NEW_CREATE_SUCCEEDED:
      return {
        ...state,
        checkinNewCreateFetching: false,
        checkinNewCreateError: false,
        checkinNewCreateSuccess: true,
      };
    case CHECKIN_OLD_CREATE_REQUESTED:
      return {
        ...state,
        checkinOldCreateFetching: true,
        checkinOldCreateError: false,
        checkinOldCreateSuccess: false,
      };
    case CHECKIN_OLD_CREATE_FAILED:
      return {
        ...state,
        checkinOldCreateFetching: false,
        checkinOldCreateError: true,
        checkinOldCreateSuccess: false,
        errorMsg: action.payload,
      };
    case CHECKIN_OLD_CREATE_SUCCEEDED:
      return {
        ...state,
        checkinOldCreateFetching: false,
        checkinOldCreateError: false,
        checkinOldCreateSuccess: true,
      };

    case CHECKIN_UPDATE_REQUESTED:
      return {
        ...state,
        checkinUpdateFetching: true,
        checkinUpdateError: false,
        checkinUpdateSuccess: false,
      };
    case CHECKIN_UPDATE_FAILED:
      return {
        ...state,
        checkinUpdateFetching: false,
        checkinUpdateError: true,
        checkinUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case CHECKIN_UPDATE_SUCCEEDED:
      return {
        ...state,
        checkinUpdateFetching: false,
        checkinUpdateError: false,
        checkinUpdateSuccess: true,
      };

    case CHECKIN_GET_ALL_REQUESTED:
      return {
        ...state,
        checkinGetAllFetching: true,
        checkinGetAllError: false,
        checkinGetAllSuccess: false,
      };
    case CHECKIN_GET_ALL_FAILED:
      return {
        ...state,
        checkinGetAllFetching: false,
        checkinGetAllError: true,
        checkinGetAllSuccess: false,
      };
    case CHECKIN_GET_ALL_SUCCEEDED:
      return {
        ...state,
        checkins: action.payload,
        checkinGetAllFetching: false,
        checkinGetAllError: false,
        checkinGetAllSuccess: true,
      };
    case CHECKIN_TOGGLE_SHOW_ACTIVE:
      return {
        ...state,
        showActive: !state.showActive,
      };
    default:
      return state;
  }
}
