import React, { useState } from "react";
import moment from "moment";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import Datetime from "react-datetime";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_WITH_MONTH,
} from "../../scripts/const";
import { groupBy } from "lodash";
import Accordion from "../Accordion/Accordion";
import "./style.css";
import { CheckBoxOutlined, Edit, Delete, Done } from "@material-ui/icons";
import CustomInput from "components/CustomInput/CustomInput";
import { Button } from "@material-ui/core";
import { DndContext } from "@dnd-kit/core";
import { SortableItem } from "./SortableItem";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import _ from "lodash";
import SidebarTodo from "./SidebarTodo";
import ClearIcon from "@mui/icons-material/Clear";

function Todo({ todo, completeTodo, editTodo, deleteTodo, allowDelete }) {
  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState("");
  const [dueDate, setDueDate] = useState("");

  const editDetail = (detail) => {
    setDetail(detail);
    setEdit(true);
  };

  const handleDatePicker = (date) => {
    if (moment.isMoment(date)) {
      setDueDate(date.format(DEFAULT_DATE_FORMAT).toString());
    } else {
      setDueDate(date);
    }
  };

  const getCheckedAction = () => {
    return (
      <Done
        className="text-light bg-primary p-1 rounded"
        role="button"
        onClick={() => completeTodo(todo.id)}
      />
    );
  };

  const getAction = () => {
    return edit ? (
      <div
        style={{
          display: "flex",
        }}
      >
        <button
          className="todo-add-btn"
          onClick={() => {
            setEdit(false);
            setDetail(todo.detail);
          }}
        >
          x
        </button>
      </div>
    ) : (
      <>
        <Edit
          className="text-light bg-success p-1 rounded"
          onClick={() => editDetail(todo.detail)}
        />
        <Delete
          className="text-light bg-danger p-1 rounded"
          onClick={() => deleteTodo(todo.id)}
        />
      </>
    );
  };

  return (
    <div className="todo">
      {edit ? (
        <div
          style={{
            display: "flex",
            gap: "10px",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomInput
            labelText="Task title"
            type="text"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              style: {
                marginTop: "12px !important",
              },
              value: detail,
              onChange: (e) => setDetail(e.target.value),
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>Due: &nbsp;</span>

            <Datetime
              closeOnSelect
              timeFormat={false}
              dateFormat={DEFAULT_DATE_FORMAT}
              value={dueDate || ""}
              onChange={(moment) => handleDatePicker(moment)}
              inputProps={{
                style: { height: "auto", padding: 3 },
                name: "dueDate",
                placeholder: `${DEFAULT_DATE_FORMAT}`,
              }}
            />
          </div>
          <div>
            <button
              className="todo-add-btn"
              onClick={() => {
                setEdit(false);
                editTodo(todo.id, detail, dueDate);
              }}
            >
              Update
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              display: "flex",
              flex: "0.6",
              hyphens: "auto",
              fontWeight: "bold",
            }}
          >
            {todo.detail}
          </span>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <span>
              <b>Due: </b>
              {`${
                todo?.dueDate
                  ? moment(todo.dueDate, DEFAULT_DATE_FORMAT).format(
                      DEFAULT_DATE_FORMAT_WITH_MONTH
                    )
                  : "N/A"
              }`}
            </span>
            <span>
              <b>Created: </b>
              {`${moment(todo.createdAt).format(
                DEFAULT_DATE_FORMAT_WITH_MONTH
              )}`}
            </span>
          </div>
        </div>
      )}

      {!todo.isCompleted && !edit && getCheckedAction()}

      {todo.isCompleted
        ? allowDelete && (
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <Delete onClick={() => deleteTodo(todo.id)} />
            </div>
          )
        : getAction()}
    </div>
  );
}

function TodoForm({ addTodo, loggedUserId }) {
  const [detail, setDetail] = useState("");
  const [dueDate, setDueDate] = useState("");

  const handleDatePicker = (date) => {
    if (moment.isMoment(date)) {
      setDueDate(date.format(DEFAULT_DATE_FORMAT).toString());
    } else {
      setDueDate(date);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!detail) return;
    addTodo(detail, dueDate, loggedUserId);
    setDetail("");
    setDueDate("");
  };

  return (
    <div
      className="todo"
      style={{
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <CustomInput
        labelText="Task title"
        type="text"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          style: { marginTop: "12px !important" },
          value: detail,
          onChange: (e) => setDetail(e.target.value),
        }}
      />

      <div style={{ display: "flex", gap: "20px" , width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>Due: &nbsp;</span>

          <Datetime
            closeOnSelect
            timeFormat={false}
            dateFormat={DEFAULT_DATE_FORMAT}
            value={dueDate || ""}
            onChange={(moment) => handleDatePicker(moment)}
            inputProps={{
              name: "dueDate",
              style: { height: "auto", padding: 3 },
              placeholder: `${DEFAULT_DATE_FORMAT}`,
            }}
          />
        </div>

        <Button variant="outlined" onClick={handleSubmit} size="small">
          Add
        </Button>
      </div>
    </div>
  );
}

function TodoComponent({
  todos,
  loggedUserId,
  addTodo,
  completeTodo,
  editTodo,
  deleteTodo,
  allowDelete = true,
  invalidateTodos,
  updateAllTodo,
}) {
  const [state, setState] = React.useState({
    right: false,
  });

  const [toggleAddButton, setToggleAddButton] = useState(false);
  const [items, setItems] = useState([]);

  React.useEffect(() => {
    setItems(todos);
  }, [todos]);

  function dragEndEvent(e) {
    const { over, active } = e;

    // interchange the order of active and over from items

    // change or exchange the order attribute of items
    const newOverItem = items.find((item) => item.id === over?.id);
    const newActiveItem = items.find((item) => item.id === active.id);

    const overIndex = newOverItem.order;
    const activeIndex = newActiveItem.order;

    newOverItem.order = activeIndex;
    newActiveItem.order = overIndex;

    const newItems = items.map((item) => {
      if (item.id === newOverItem.id) {
        return newOverItem;
      } else if (item.id === newActiveItem.id) {
        return newActiveItem;
      } else {
        return item;
      }
    });

    setItems(newItems);

    const payload = {
      todos: newItems.filter((row) => [over?.id, active?.id].includes(row.id)),
    };
    updateAllTodo({ payload });

    invalidateTodos && invalidateTodos();
  }
  function getCompletedTodo() {
    const completedTodo = [
      ...todos.filter(
        (todo) => todo.userId === loggedUserId && todo.isCompleted === true
      ),
    ].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    const allDates = completedTodo.map((m) => ({
      ...m,
      date: moment(m.updatedAt).format("MMMM D, YYYY (dddd)"),
    }));
    const dateTodo = groupBy(allDates, (todo) => todo.date);
    const components = [];
    for (let property in dateTodo) {
      if (dateTodo.hasOwnProperty(property)) {
        components.push(
          <div
            style={{
              backgroundColor: "#4c84ca",
              width: "95%",
              textAlign: "center",
              color: "white",
            }}
            key={property}
          >
            Date: {property}
          </div>
        );
        components.push(
          dateTodo[property].map((todo, index) => (
            <GridItem key={index} xs={12} sm={12} md={12}>
              <Todo
                key={index}
                index={index}
                todo={todo}
                completeTodo={completeTodo}
                editTodo={editTodo}
                deleteTodo={deleteTodo}
                allowDelete={allowDelete}
              />
            </GridItem>
          ))
        );
      }
    }
    return components;
  }

  return (
    <SidebarTodo {...{ state, setState }}>
      <GridContainer
        style={{
          width: "540px",
          padding: "20px",
          backgroundColor: "white",
        }}
      >
        <GridItem xs={12} sm={12} md={12}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #eee",
                marginBottom: "15px",
                paddingBottom: "5px",
              }}
            >
              <h5 style={{ fontWeight: "bold" }}>My Tasks</h5>
              <div>
                <Button
                  style={{
                    color: "black",
                  }}
                  onClick={() => setState({ ...state, right: !state.right })}
                >
                  <ClearIcon />
                </Button>
              </div>
            </div>

            <div>
              {!toggleAddButton ? (
                <Button
                  style={{
                    color: "blue",
                  }}
                  onClick={() => setToggleAddButton(!toggleAddButton)}
                >
                  <CheckBoxOutlined />
                  <span style={{ marginLeft: "5px" }}>Add a task</span>
                </Button>
              ) : (
                <GridContainer justifyContent={"center"}>
                  <GridItem xs={12} sm={12} md={12}>
                    <TodoForm addTodo={addTodo} loggedUserId={loggedUserId} />
                  </GridItem>
                </GridContainer>
              )}

              <GridContainer justifyContent={"center"}>
                <DndContext onDragEnd={dragEndEvent}>
                  <SortableContext items={items}>
                    {/* todo contains order  */}
                    {todos
                      .filter(
                        (todo) =>
                          todo.userId === loggedUserId &&
                          todo.isCompleted === false
                      )
                      .map((todo, index) => (
                        <GridItem
                          key={index}
                          xs={12}
                          sm={12}
                          md={12}
                          className="mt-1"
                          style={{
                            background: "#f5f6f8",
                          }}
                        >
                          <SortableItem key={todo.id} id={todo.id}>
                            <Todo
                              key={index}
                              index={index}
                              todo={todo}
                              completeTodo={completeTodo}
                              editTodo={editTodo}
                              deleteTodo={deleteTodo}
                            />
                          </SortableItem>
                        </GridItem>
                      ))}
                  </SortableContext>
                </DndContext>
              </GridContainer>
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Accordion
            summaryStyle={{}}
            collapses={[
              {
                title: "Completed",
                content: (
                  <CardBody>
                    <GridContainer justifyContent={"center"}>
                      {getCompletedTodo()}
                    </GridContainer>
                  </CardBody>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </SidebarTodo>
  );
}

export default TodoComponent;
