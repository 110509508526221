import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
  roseColor,
  hexToRgb,
  blackColor,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.js";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

const checkinPageStyle = (theme) => ({
  checkinHeader: {
    textAlign: "center",
  },
  submitBtn: {
    marginTop: "30px",
  },
  checkInContainer: {
    textAlign: "center",
  },
  checkinTable: {
    marginTop: "20px",
  },
  iconCheckbox: {},
  iconCheckboxIcon: {
    fontSize: "40px",
  },
  errorMsg: {
    color: "#de4463",
  },
  marginDown: {
    marginBottom: "20px",
  },
  iconCheckboxChecked: {
    color: roseColor[0],
    "& > span:first-child": {
      borderColor: roseColor[0],
    },
  },
  checkinButton: {
    marginTop: "20px",
    fontSize: "25px",
    borderRadius: "10px",
  },
  appBar: {
    marginBottom: "30px",
  },
  serviceSelection: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "20px",
    textDecoration: "underline",
  },
  serviceError: {
    textAlign: "center",
    fontSize: "12px",
    color: "red",
  },
  choice: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "35px",
  },
  dialogContent: {
    // marginTop: "20px",
    fontSize: "16px",
    fontWeight: "400",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      // width: 300,
    },
  },
  imgLogo: {
    // objectFit: "cover",
    width: "100%",
    maxHeight: "100%",
  },
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  resetPass: {
    fontStyle: "italic",
    fontFamily: "Dancing Script",
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  titleContainer: {
    textAlign: "center",
    marginTop: "20px",
  },
  checkInOption: {
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  labelHorizontal: {
    color: `rgba(${hexToRgb(blackColor)}, 0.5)`,
    cursor: "pointer",
    fontSize: "30px",
    lineHeight: "2",
    fontWeight: "500",
    marginRight: "0",
    textAlign: "center",
  },
  label: {
    color: `rgba(${hexToRgb(blackColor)}, 0.5)`,
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: "22px",
  },
  inlineChecks: {
    marginTop: "8px",
  },
  radioChecked: {
    width: "16px",
    height: "16px",
    border: `1px solid ${primaryColor[0]}`,
    borderRadius: "50%",
  },
  radioUnchecked: {
    width: "0px",
    height: "0px",
    padding: "7px",
    border: `1px solid rgba(${hexToRgb(blackColor)}, .54)`,
    borderRadius: "50%",
  },
  radio: {
    color: `${primaryColor[0]}!important`,
  },
  radioRoot: {
    padding: "16px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  ...buttonStyle,
});

export default checkinPageStyle;
