import { call, put, takeLatest } from "redux-saga/effects";

import {
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGOUT_SUCCEEDED,
  LOGOUT_FAILED,
  LOGOUT_REQUESTED,
} from "../actions";
import remoteResource from "../../lib/RemoteResource";

function* login(action) {
  try {
    const { payload } = action;
    const user = yield call(remoteResource.login, payload);
    yield put({
      type: LOGIN_SUCCEEDED,
      payload: user.data,
    });
  } catch (e) {
    yield put({ type: LOGIN_FAILED, payload: e.response.data.message });
  }
}

function* logOut(action) {
  try {
    yield put({ type: LOGOUT_SUCCEEDED });
  } catch (e) {
    yield put({ type: LOGOUT_FAILED, payload: e.message });
  }
}

export function* watchAuth() {
  yield takeLatest(LOGIN_REQUESTED, login);
  yield takeLatest(LOGOUT_REQUESTED, logOut);
}
