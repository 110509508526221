/* eslint-disable react/display-name */

import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clockInPageStyle from "assets/jss/material-dashboard-pro-react/views/clockInPageStyle";
import Button from "../../components/CustomButtons/Button";

import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import {
  getFullFaceDescription,
  isFaceDetectionModelLoaded,
  isFacialLandmarkDetectionModelLoaded,
  isFeatureExtractionModelLoaded,
  loadModels,
} from "../../faceUtil";
import { DEFAULT_WEBCAM_RESOLUTION, inputSize } from "../../globalData";
import { drawRectAndLabelFace } from "../../utils/drawRectAndLabelFace";
import ModelLoading from "../../utils/ModelLoading";
import ModelLoadStatus from "../../utils/ModelLoadStatus";
import { CLOCK_LOGIN_REQUESTED } from "redux/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(clockInPageStyle);

export default (props) => {
  const { faceMatcher, facePhotos, codeLogin } = props;
  console.log("MATCHER", faceMatcher);
  const classes = useStyles();

  const webcamRef = useRef();
  const canvasRef = useRef();
  const dispatch = useDispatch();

  const [inputDevices, setInputDevices] = useState([]);
  const [camWidth, setCamWidth] = useState(DEFAULT_WEBCAM_RESOLUTION.width);
  const [camHeight, setCamHeight] = useState(DEFAULT_WEBCAM_RESOLUTION.height);

  const [isAllModelLoaded, setIsAllModelLoaded] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [loadingMessageError, setLoadingMessageError] = useState("");
  const [fullDesc, setFullDesc] = useState(null);
  const [waitText, setWaitText] = useState("");

  useEffect(() => {
    async function loadingtheModel() {
      await loadModels(setLoadingMessage, setLoadingMessageError);
      setIsAllModelLoaded(true);
    }
    if (
      !!isFaceDetectionModelLoaded() &&
      !!isFacialLandmarkDetectionModelLoaded() &&
      !!isFeatureExtractionModelLoaded()
    ) {
      setIsAllModelLoaded(true);
      return;
    }
    loadingtheModel();
  }, [isAllModelLoaded]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(async (devices) => {
      let inputDevice = await devices.filter(
        (device) => device.kind === "videoinput"
      );
      setInputDevices({ ...inputDevices, inputDevice });
    });
  }, []);

  useEffect(() => {
    function capture() {
      console.log("WEBREF", webcamRef);
      if (
        typeof webcamRef.current !== "undefined" &&
        webcamRef.current !== null &&
        webcamRef.current.video.readyState === 4
      ) {
        const videoWidth = webcamRef.current.video.videoWidth;
        const videoHeight = webcamRef.current.video.videoHeight;

        // Set canvas height and width
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;

        // Draw mesh
        getFullFaceDescription(webcamRef.current.getScreenshot(), inputSize)
          .then((data) => {
            setFullDesc(data);
            setWaitText("");
          })
          .catch((err) => {
            setWaitText(
              "Preparing face matcher and device setup, please wait..."
            );
          });
        const ctx = canvasRef.current.getContext("2d");
        console.log("Now got full desc", fullDesc);

        drawRectAndLabelFace(fullDesc, faceMatcher, ctx);

        if (fullDesc) {
          console.log("Now got full desc", fullDesc);
          fullDesc.map((desc) => {
            const bestMatch = faceMatcher?.findBestMatch(desc.descriptor);
            console.log("BEST MATCH", bestMatch);
            if (bestMatch._label != "unknown") {
              console.log("Saving in db now", Number(bestMatch._label));
              dispatch({
                type: CLOCK_LOGIN_REQUESTED,
                payload: { id: Number(bestMatch._label) },
              });
            }
          });
        }
      }
    }

    let interval = setInterval(() => {
      capture();
    }, 700);

    return () => clearInterval(interval);
  });

  return (
    <Card>
      {isAllModelLoaded && loadingMessageError.length == 0 && (
        <Card className="takeAttendance__card__webcam">
          <>
            <p>{waitText}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Webcam
                muted={true}
                ref={webcamRef}
                audio={false}
                width={camWidth}
                height={camHeight}
                screenshotFormat="image/jpeg"
                mirrored
              />
              <canvas
                ref={canvasRef}
                style={{
                  position: "absolute",
                  textAlign: "center",
                  zindex: 8,
                  width: camWidth,
                  height: camHeight,
                }}
              />
            </div>
          </>
        </Card>
      )}

      {facePhotos.length === 0 && (
        <p className="alert">No have any face matcher.</p>
      )}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ModelLoadStatus errorMessage={loadingMessageError} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <p>Could not login?</p>
          <Button onClick={() => codeLogin()}>Login From Code</Button>
        </div>
      </div>

      {!isAllModelLoaded ? (
        <ModelLoading loadingMessage={loadingMessage} />
      ) : loadingMessageError ? (
        <div className="error">{loadingMessageError}</div>
      ) : (
        <div></div>
      )}
    </Card>
  );
};
