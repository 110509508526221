import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_ERROR,
  TODO_GET_ALL_REQUESTED,
  TODO_CREATE_REQUESTED,
  TODO_UPDATE_REQUESTED,
  TODO_DELETE_REQUESTED,
  TODO_COUNT_GET_REQUESTED,
  TODO_UPDATE_ALL_REQUESTED,
} from "redux/actions";
import ReactGA from "react-ga4";
import _ from "lodash";
import TodoComponent from "components/Todo/ToDo";

export default function HeaderTodo(props) {
  const [todos, setTodos] = useState([]);
  const [error, setError] = useState("");

  const errMsg = useSelector((state) => state.todo.errorMsg);
  const allToDos = useSelector((state) => state.todo.todos);
  const createSuccess = useSelector((state) => state.todo.todoCreateSuccess);
  const updateSuccess = useSelector((state) => state.todo.todoUpdateSuccess);
  const deleteSuccess = useSelector((state) => state.todo.todoDeleteSuccess);

  const { id: loggedUserId } = useSelector(
    (state) => state.login.loginUser.detail
  );

  const dispatch = useDispatch();

  function invalidateTodos() {
    dispatch({ type: TODO_COUNT_GET_REQUESTED });
    dispatch({ type: TODO_GET_ALL_REQUESTED });
  }

  useEffect(() => {
    invalidateTodos();
  }, []);

  useEffect(() => {
    const sortedTodos = _.orderBy(allToDos, ["order"], ["asc"]);
    setTodos(sortedTodos);
  }, [allToDos]);

  useEffect(() => {
    if (
      updateSuccess === true ||
      deleteSuccess === true ||
      createSuccess === true
    ) {
      dispatch({ type: TODO_GET_ALL_REQUESTED });
      dispatch({ type: TODO_COUNT_GET_REQUESTED });
      dispatch({ type: CLEAR_ERROR });
    }
  }, [createSuccess, deleteSuccess, updateSuccess]);

  useEffect(() => {
    setError(errMsg);
  }, [errMsg]);

  const addTodo = (detail, dueDate, loggedUserId) => {
    const todo = { detail, dueDate, userId: loggedUserId };
    setError("");
    dispatch({ type: TODO_CREATE_REQUESTED, payload: todo });
    ReactGA.event({
      category: "TODO",
      action: "Added a todo",
    });
  };

  const updateAllTodo = (payload) => {
    setError("");
    dispatch({ type: TODO_UPDATE_ALL_REQUESTED, payload });
    ReactGA.event({
      category: "TODO",
      action: "Update all a todo",
    });
  };

  const completeTodo = (id) => {
    setError("");
    dispatch({
      type: TODO_UPDATE_REQUESTED,
      payload: { payload: { isCompleted: true }, id },
    });
    ReactGA.event({
      category: "TODO",
      action: "Completed a todo",
    });
  };

  const deleteTodo = (id) => {
    setError("");
    dispatch({
      type: TODO_DELETE_REQUESTED,
      payload: { id },
    });
    ReactGA.event({
      category: "TODO",
      action: "Deleted a todo",
    });
  };

  const editTodo = (id, detail, dueDate) => {
    setError("");
    dispatch({
      type: TODO_UPDATE_REQUESTED,
      payload: { payload: { detail, dueDate }, id },
    });
    ReactGA.event({
      category: "TODO",
      action: "Edited a todo",
    });
  };

  return (
    <TodoComponent
      invalidateTodos={invalidateTodos}
      todos={todos}
      loggedUserId={loggedUserId}
      addTodo={addTodo}
      completeTodo={completeTodo}
      editTodo={editTodo}
      deleteTodo={deleteTodo}
      updateAllTodo={updateAllTodo}
    />
  )
}