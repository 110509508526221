import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_ERROR,
  TODO_GET_ALL_REQUESTED,
  TODO_CREATE_REQUESTED,
  TODO_UPDATE_REQUESTED,
  TODO_DELETE_REQUESTED,
  TODO_COUNT_GET_REQUESTED,
  TODO_UPDATE_ALL_REQUESTED,
} from "redux/actions";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import withTimer from "../../../components/Timer/withTimer";
import NavPills from "components/NavPills/NavPills.js";
import { SUPER_ROLE } from "../../../scripts/const";
import { getUserName } from "../../../scripts/util";
import ReactGA from "react-ga4";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import ToDoComponent from "../../../components/Todo/ToDo";
import _ from "lodash";
const Accordion = lazy(() => import("components/Accordion/Accordion.js"));

function Todo() {
  const [todos, setTodos] = useState([]);
  const [error, setError] = useState("");

  const errMsg = useSelector((state) => state.todo.errorMsg);
  const allToDos = useSelector((state) => state.todo.todos);
  const createSuccess = useSelector((state) => state.todo.todoCreateSuccess);
  const updateSuccess = useSelector((state) => state.todo.todoUpdateSuccess);
  const deleteSuccess = useSelector((state) => state.todo.todoDeleteSuccess);
  const allUsers = useSelector((state) => state.user.users);

  const createProcess = useSelector((state) => state.todo.todoCreateFetching);
  const updateProcess = useSelector((state) => state.todo.todoUpdateFetching);
  const deleteProcess = useSelector((state) => state.todo.todoDeleteFetching);

  const { roleId, id: loggedUserId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const isSuperUser = SUPER_ROLE.includes(roleId);

  const dispatch = useDispatch();

  function invalidateTodos() {
    dispatch({ type: TODO_COUNT_GET_REQUESTED });
    dispatch({ type: TODO_GET_ALL_REQUESTED });
  }

  useEffect(() => {
    invalidateTodos();
  }, []);

  useEffect(() => {
    const sortedTodos = _.orderBy(allToDos, ["order"], ["asc"]);
    setTodos(sortedTodos);
  }, [allToDos]);

  useEffect(() => {
    if (
      updateSuccess === true ||
      deleteSuccess === true ||
      createSuccess === true
    ) {
      dispatch({ type: TODO_GET_ALL_REQUESTED });
      dispatch({ type: TODO_COUNT_GET_REQUESTED });
      dispatch({ type: CLEAR_ERROR });
    }
  }, [createSuccess, deleteSuccess, updateSuccess]);

  useEffect(() => {
    setError(errMsg);
  }, [errMsg]);

  const addTodo = (detail, dueDate, loggedUserId) => {
    const todo = { detail, dueDate, userId: loggedUserId };
    setError("");
    dispatch({ type: TODO_CREATE_REQUESTED, payload: todo });
    ReactGA.event({
      category: "TODO",
      action: "Added a todo",
    });
  };

  const updateAllTodo = (payload) => {
    setError("");
    dispatch({ type: TODO_UPDATE_ALL_REQUESTED, payload });
    ReactGA.event({
      category: "TODO",
      action: "Update all a todo",
    });
  };

  const completeTodo = (id) => {
    setError("");
    dispatch({
      type: TODO_UPDATE_REQUESTED,
      payload: { payload: { isCompleted: true }, id },
    });
    ReactGA.event({
      category: "TODO",
      action: "Completed a todo",
    });
  };

  const deleteTodo = (id) => {
    setError("");
    dispatch({
      type: TODO_DELETE_REQUESTED,
      payload: { id },
    });
    ReactGA.event({
      category: "TODO",
      action: "Deleted a todo",
    });
  };

  const editTodo = (id, detail, dueDate) => {
    setError("");
    dispatch({
      type: TODO_UPDATE_REQUESTED,
      payload: { payload: { detail, dueDate }, id },
    });
    ReactGA.event({
      category: "TODO",
      action: "Edited a todo",
    });
  };

  const getAccordionContent = useMemo(() => {
    const userIds = [
      ...new Set(allUsers.filter((u) => u.isActive === true).map((m) => m.id)),
    ];
    return userIds.map((userId) => {
      const currentUserTodo = allToDos.filter((all) => all.userId === userId);
      return {
        title: getUserName(userId, allUsers),
        content: (
          <ToDoComponent
            invalidateTodos={invalidateTodos}
            todos={currentUserTodo}
            loggedUserId={userId}
            addTodo={addTodo}
            completeTodo={completeTodo}
            editTodo={editTodo}
            deleteTodo={deleteTodo}
            updateAllTodo={updateAllTodo}
          />
        ),
      };
    });
  }, [allUsers, allToDos]);

  return (
    <div>
      {error && <SnackbarContent message={error} color="warning" />}
      {createProcess && <CustomLinearProgress color="primary" />}
      {updateProcess && <CustomLinearProgress color="primary" />}
      {deleteProcess && <CustomLinearProgress color="primary" />}
      {isSuperUser ? (
        <NavPills
          color="rose"
          horizontal={{
            tabsGrid: { xs: 12, sm: 12, md: 2 },
            contentGrid: { xs: 12, sm: 12, md: 10 },
          }}
          tabs={[
            {
              tabButton: "My TODOs",
              tabContent: (
                <ToDoComponent
                  invalidateTodos={invalidateTodos}
                  todos={todos}
                  loggedUserId={loggedUserId}
                  addTodo={addTodo}
                  completeTodo={completeTodo}
                  editTodo={editTodo}
                  deleteTodo={deleteTodo}
                  updateAllTodo={updateAllTodo}
                />
              ),
            },
            {
              tabButton: "All TODOs",
              tabContent: (
                <>
                  <Suspense fallback={<></>}>
                    <Accordion collapses={getAccordionContent} />
                  </Suspense>
                </>
              ),
            },
          ]}
        />
      ) : (
        <ToDoComponent
          invalidateTodos={invalidateTodos}
          todos={todos}
          loggedUserId={loggedUserId}
          addTodo={addTodo}
          completeTodo={completeTodo}
          deleteTodo={deleteTodo}
          editTodo={editTodo}
          allowDelete={false}
          updateAllTodo={updateAllTodo}
        />
      )}
    </div>
  );
}

export default withTimer(Todo);
