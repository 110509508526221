import React from "react";

import ReactGA from "react-ga4";
import MaterialTable from "material-table";

import { useDispatch, useSelector } from "react-redux";
import { VISA_CREATE_REQUESTED } from "redux/actions";
import { VISA_GET_ALL_REQUESTED } from "redux/actions";
import { VISA_UPDATE_REQUESTED } from "redux/actions";
import { VISA_DELETE_REQUESTED } from "redux/actions";

export default function VisaList() {
  const getColumns = () => {
    return [
      {
        title: "Visa Type",
        field: "visaType",
      },
    ];
  };

  const dispatch = useDispatch();

  const visas = useSelector((state) => state.visa.visas);

  const visaFetching = useSelector(
    (state) => state.visaApplicant.visaGetAllFetching
  );
  return (
    <>
      <MaterialTable
        title="Visa Type List"
        columns={getColumns()}
        data={visas}
        options={{
          exportButton: true,
          pageSize: 50,
          pageSizeOptions: [50, 100],
          headerStyle: {
            backgroundColor: "#0077c2",
            color: "#FFF",
          },
          sorting: true,
        }}
        style={{
          marginTop: "30px",
        }}
        editable={{
          isEditable: () => true,
          onRowUpdate: (newData, oldData) => {
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const payload = {
                  ...newData,
                  id: oldData?.id,
                  payload: newData,
                };
                dispatch({ type: VISA_UPDATE_REQUESTED, payload });
                ReactGA.event({
                  category: "Visa",
                  action: "Visa Updated",
                });
              }, 200);
              setTimeout(() => {
                dispatch({ type: VISA_GET_ALL_REQUESTED });
              }, 2000);
            });
          },
          onRowAdd: (newData) => {
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const payload = { ...newData, title: newData?.id };
                dispatch({ type: VISA_CREATE_REQUESTED, payload });
                ReactGA.event({
                  category: "Visa",
                  action: "Visa Added",
                });
              }, 200);
              setTimeout(() => {
                dispatch({ type: VISA_GET_ALL_REQUESTED });
              }, 2000);
            });
          },
          onRowDelete: (newData) => {
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const payload = { ...newData, title: newData?.id };
                dispatch({ type: VISA_DELETE_REQUESTED, payload });
                ReactGA.event({
                  category: "Visa",
                  action: "Visa Added",
                });
              }, 200);
              setTimeout(() => {
                dispatch({ type: VISA_GET_ALL_REQUESTED });
              }, 2000);
            });
          },
        }}
      />
    </>
  );
}
