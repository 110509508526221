import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// core components
import Footer from "components/Footer/Footer.js";

import { publicRoute } from "routes.js";

const routes = publicRoute;

export default function ImmigrationPage() {
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/public/form") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div>
      <Switch>
        {getRoutes(routes)}
        <Redirect from="/public/form" to="/public/form/immigration" />
      </Switch>
      <Footer />
    </div>
  );
}
