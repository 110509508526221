import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  COURSE_CREATE_REQUESTED,
  COURSE_CREATE_FAILED,
  COURSE_CREATE_SUCCEEDED,
  COURSE_GET_ALL_REQUESTED,
  COURSE_GET_ALL_FAILED,
  COURSE_GET_ALL_SUCCEEDED,
  COURSE_UPDATE_REQUESTED,
  COURSE_UPDATE_FAILED,
  COURSE_UPDATE_SUCCEEDED,
  COURSE_BULK_CREATE_REQUESTED,
  COURSE_BULK_CREATE_FAILED,
  COURSE_BULK_CREATE_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchAllCourses() {
  try {
    const token = yield select(getToken);
    const courses = yield call(remoteResource.getAllCourses, token);
    yield put({
      type: COURSE_GET_ALL_SUCCEEDED,
      payload: courses.data,
    });
  } catch (e) {
    yield put({ type: COURSE_GET_ALL_FAILED, payload: e.message });
  }
}

function* createCourse(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const course = yield call(remoteResource.createCourse, payload, token);
    yield put({
      type: COURSE_CREATE_SUCCEEDED,
      payload: course.data,
    });
  } catch (e) {
    yield put({ type: COURSE_CREATE_FAILED, payload: e.message });
  }
}

function* bulkCreateCourse(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const course = yield call(remoteResource.bulkCreateCourse, payload, token);
    yield put({
      type: COURSE_BULK_CREATE_SUCCEEDED,
      payload: course.data,
    });
  } catch (e) {
    yield put({ type: COURSE_BULK_CREATE_FAILED, payload: e.message });
  }
}

function* updateCourse(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const course = yield call(remoteResource.updateCourse, id, payload, token);
    yield put({
      type: COURSE_UPDATE_SUCCEEDED,
      payload: course.data,
    });
  } catch (e) {
    yield put({ type: COURSE_UPDATE_FAILED, payload: e.message });
  }
}

export function* watchCourse() {
  yield takeLatest(COURSE_CREATE_REQUESTED, createCourse);
  yield takeLatest(COURSE_BULK_CREATE_REQUESTED, bulkCreateCourse);
  yield takeLatest(COURSE_UPDATE_REQUESTED, updateCourse);
  yield takeLatest(COURSE_GET_ALL_REQUESTED, fetchAllCourses);
}
