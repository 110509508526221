import Divider from "@material-ui/core/Divider";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import React from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CalendarPopover from "../CalendarPopover/CalendarPopover";

import {
  BRANCH_PHONE,
  BRANCH_EMAIL,
  BRANCH_NAME,
  COMPANY_NAME,
} from "../../scripts/const";

export default function ThankYou({ isApt, appointment, summary }) {
  return (
    <>
      <Card>
        <CardHeader style={{ textAlign: "center" }} color="rose" stats>
          <header>
            {isApt
              ? "Thank you for scheduling the appointment!"
              : "Thank You for Signing Up!"}
          </header>
        </CardHeader>
        <CardBody>
          {/*<h5> One of our friendly team members will call you soon. </h5>*/}
          <h5>
            {" "}
            Have any immediate questions? Please contact us at{" "}
            <a href={`mailto:${BRANCH_EMAIL}`}>{BRANCH_EMAIL}</a> or give us a
            call at <a href={`tel:${BRANCH_PHONE}`}>{BRANCH_PHONE}</a> .{" "}
          </h5>
          <h5>Looking forward to chatting with you soon.</h5>
          <h5>
            {COMPANY_NAME}, {BRANCH_NAME}
          </h5>
          {appointment && (
            <>
              <Divider></Divider>
              {summary()}
              <GridContainer justify={"flex-end"}>
                <GridItem>
                  <CalendarPopover event={appointment}></CalendarPopover>
                </GridItem>
              </GridContainer>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
