import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  APPOINTMENT_CREATE_REQUESTED,
  APPOINTMENT_CREATE_FAILED,
  APPOINTMENT_CREATE_SUCCEEDED,
  APPOINTMENT_GET_ALL_REQUESTED,
  APPOINTMENT_GET_ALL_FAILED,
  APPOINTMENT_GET_ALL_SUCCEEDED,
  APPOINTMENT_GET_REQUESTED,
  APPOINTMENT_GET_FAILED,
  APPOINTMENT_GET_SUCCEEDED,
  APPOINTMENT_UPDATE_REQUESTED,
  APPOINTMENT_UPDATE_FAILED,
  APPOINTMENT_UPDATE_SUCCEEDED,
  APPOINTMENT_DELETE_REQUESTED,
  APPOINTMENT_DELETE_FAILED,
  APPOINTMENT_DELETE_SUCCEEDED,
  APPOINTMENT_MY_GET_SUCCEEDED,
  APPOINTMENT_MY_GET_FAILED,
  APPOINTMENT_MY_GET_REQUESTED,
  APPOINTMENT_USER_GET_FAILED,
  APPOINTMENT_USER_GET_REQUESTED,
  APPOINTMENT_USER_GET_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchAppointment(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const appointment = yield call(remoteResource.getAppointment, id, token);
    yield put({
      type: APPOINTMENT_GET_SUCCEEDED,
      payload: appointment.data,
    });
  } catch (e) {
    yield put({ type: APPOINTMENT_GET_FAILED, payload: e.message });
  }
}

function* fetchMyAppointment() {
  try {
    const token = yield select(getToken);
    const myAppointment = yield call(remoteResource.getMyAppointment, token);
    yield put({
      type: APPOINTMENT_MY_GET_SUCCEEDED,
      payload: myAppointment.data,
    });
  } catch (e) {
    yield put({ type: APPOINTMENT_MY_GET_FAILED, payload: e.message });
  }
}

function* fetchAllAppointments() {
  try {
    const token = yield select(getToken);
    const appointments = yield call(remoteResource.getAllAppointments, token);
    yield put({
      type: APPOINTMENT_GET_ALL_SUCCEEDED,
      payload: appointments.data,
    });
  } catch (e) {
    yield put({ type: APPOINTMENT_GET_ALL_FAILED, payload: e.message });
  }
}

function* fetchAllAppointmentUsers() {
  try {
    const users = yield call(remoteResource.getAllAppointmentUsers);
    yield put({
      type: APPOINTMENT_USER_GET_SUCCEEDED,
      payload: users.data,
    });
  } catch (e) {
    yield put({ type: APPOINTMENT_USER_GET_FAILED, payload: e.message });
  }
}

function* createAppointment(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const appointment = yield call(
      remoteResource.createAppointment,
      payload,
      token
    );
    yield put({
      type: APPOINTMENT_CREATE_SUCCEEDED,
      payload: appointment.data,
    });
  } catch (e) {
    yield put({
      type: APPOINTMENT_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateAppointment(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const appointment = yield call(
      remoteResource.updateAppointment,
      id,
      payload,
      token
    );
    yield put({
      type: APPOINTMENT_UPDATE_SUCCEEDED,
      payload: appointment.data,
    });
  } catch (e) {
    yield put({
      type: APPOINTMENT_UPDATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* deleteAppointment(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    const appointment = yield call(remoteResource.deleteAppointment, id, token);
    yield put({
      type: APPOINTMENT_DELETE_SUCCEEDED,
      payload: appointment.data,
    });
  } catch (e) {
    yield put({
      type: APPOINTMENT_DELETE_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchAppointment() {
  yield takeLatest(APPOINTMENT_GET_REQUESTED, fetchAppointment);
  yield takeLatest(APPOINTMENT_MY_GET_REQUESTED, fetchMyAppointment);
  yield takeLatest(APPOINTMENT_CREATE_REQUESTED, createAppointment);
  yield takeLatest(APPOINTMENT_UPDATE_REQUESTED, updateAppointment);
  yield takeLatest(APPOINTMENT_GET_ALL_REQUESTED, fetchAllAppointments);
  yield takeLatest(APPOINTMENT_DELETE_REQUESTED, deleteAppointment);
  yield takeLatest(APPOINTMENT_USER_GET_REQUESTED, fetchAllAppointmentUsers);
}
