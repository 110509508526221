import React, { useState } from "react";
import axios from "axios";

import { Helmet } from "react-helmet";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import "./style.css";
import SnackBar from "@material-ui/core/Snackbar";
import ImmigrationForm from "../../../components/ImmigrationForm/ImmigrationForm";
import { COMPANY_NAME, LOGO_HORIZONTAL } from "../../../scripts/const";

export default function Immigration() {
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [succcessMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [data, setData] = useState({});
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const handleSelect = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    // Default state
    setErrorMsg("");
    setSuccessMsg("");
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_ROOT}/public/immigration/profile-info/create`,
        data
      )
      .then(({ data }) => {
        setLoading(false);
        setSuccess(true);
        setSuccessMsg(`Profile Id: ${data.id}`);
        setErrorMsg("");
        setData({});
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setSnackBarOpen(true);
        setSuccessMsg("");
        setErrorMsg("Something went wrong. Please try again.");
      });
  };
  return (
    <div>
      <Helmet>
        <title>Immigration Form | {COMPANY_NAME} </title>
        <meta
          name="description"
          content="Immigration Form. Student | Visa | Immigration"
        />
        <meta
          name="og:title"
          content={`Immigration form for ${COMPANY_NAME}`}
        />
      </Helmet>
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs="auto" className="m-3 mb-4">
            <img className={"logo"} src={LOGO_HORIZONTAL} />
          </Col>
        </Row>

        {!success && (
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Card>
                <div className="card-header">
                  <h1 className="card-title">Immigration Form</h1>
                </div>
                <Card.Body>
                  <ImmigrationForm
                    handleSelect={handleSelect}
                    handleSubmit={handleSubmit}
                    validated={validated}
                  />
                </Card.Body>
              </Card>
            </Col>
            <SnackBar
              open={snackBarOpen || loading}
              message={loading ? "Loading... " : errorMsg || ""}
              autoHideDuration={50000}
              onClose={() => setSnackBarOpen(false)}
            />
          </Row>
        )}
        {success && (
          <Row className="justify-content-md-center">
            <Col md={6}>
              <Card>
                <Card.Body>
                  <div className="wrapper">
                    <h1 className="thank-you">Thank you!</h1>
                    <p>We have received your profile. {succcessMsg}</p>
                    <p>Save this profile id for your confirmation.</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
