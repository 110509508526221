import {
  cardTitle,
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const extendedFormsStyle = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  cardTitle,
  hidden: {
    display: "none",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  gridUp: {
    marginTop: "20px",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "4px",
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "#AAA",
    fontSize: "11px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    textAlign: "left",
    width: "100%",
  },
  "@media screen and (min-width: 800px)": {
    modalMediumXL: {
      minWidth: "800px",
    },
    modalXL: {
      minWidth: "900px",
    },
    modalMedium: {
      minWidth: "600px",
    },
    modalMediumSmall: {
      minWidth: "400px",
    },
  },
  moveToRight: {
    float: "right",
  },
  mrAuto: {
    marginRight: "auto",
  },
  mlAuto: {
    marginLeft: "auto",
  },
};

export default extendedFormsStyle;
