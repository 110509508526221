/*eslint-disable*/
import React, { useEffect } from 'react';
// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer, Views } from "react-big-calendar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Datetime from "react-datetime";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

// dependency plugin for react-big-calendar
import moment from "moment";
import momentTz from "moment-timezone";

import "assets/css/big-calendar.css";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import CalendarPopover from "components/CalendarPopover/CalendarPopover.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import Clearfix from '../../components/Clearfix/Clearfix';
import { convertDate, getUserName, isValidEventInputs } from '../../scripts/util';
import { getMoment } from '../../scripts/dateUtil';
import SnackbarContent from '../../components/Snackbar/SnackbarContent';
import {
  APPOINTMENT_CREATE_REQUESTED,
  APPOINTMENT_DELETE_REQUESTED,
  APPOINTMENT_GET_ALL_REQUESTED,
  APPOINTMENT_UPDATE_REQUESTED,
  CLEAR_ERROR,
} from '../../redux/actions';
import { DEFAULT_DATE_FORMAT, CURRENT_TIME_ZONE, DEFAULT_TIME_ZONE } from 'scripts/const';

import { cloneDeep } from 'lodash';
import withTimer from '../../components/Timer/withTimer';
import SearchLead from '../../components/SearchLead';
import Close from '@material-ui/icons/Close';
import AddLead from '../hbg-view/Lead/AddLead';
import Muted from '../../components/Typography/Muted';
const DragAndDropCalendar = withDragAndDrop(BigCalendar)


const useStyles = makeStyles(styles);
let allViews = Object.keys(Views).map(k => Views[k])

function EventDialog(props) {
  const classes = useStyles();
  const { onClose, slotInfo, isEdit, open, onSubmit, onUpdate, onDelete, loggedUserId } = props;
  const [event, setEvent] = React.useState({ });
  const [validationError, setValidationError] = React.useState(null);
  const [leadId, setLeadId] = React.useState(null);
  const [addLeadModal, setAddLeadModal] = React.useState(false);

  const allUsers = useSelector((state) => state.user.users);
  const allSources = useSelector((state) => state.source.sources);
  const activeUsers = useSelector((state) => state.user.activeUsers);


  const createSuccess = useSelector((state) => state.appointment.appointmentCreateSuccess);
  const updateSuccess = useSelector((state) => state.appointment.appointmentUpdateSuccess);
  const deleteSuccess = useSelector((state) => state.appointment.appointmentDeleteSuccess);


  useEffect(() => {
    if (
      updateSuccess === true ||
      deleteSuccess === true ||
      createSuccess === true
    ) {
      handleClose();
    }
  }, [createSuccess, deleteSuccess, updateSuccess]);


  React.useEffect(() => {
    if(slotInfo && !isEdit){
      setEvent({ start: moment(slotInfo.start), end: moment(slotInfo.end), userId: loggedUserId});
    } else if(slotInfo && isEdit){
      if(slotInfo.leadId) {
        setLeadId(slotInfo.leadId)
      }
      setEvent({...slotInfo, ...{ start: moment(slotInfo.start), end: moment(slotInfo.end) }});
    }
  }, [slotInfo]);

  const handleClose = () => {
    setValidationError("");
    setEvent({  })
    onClose();
    setLeadId(null);
  };

  const handleDatePicker = (date, name) => {
    setEvent({
      ...event,
      [name]: date,
    });
  };

  const handleSelect = (e) => {
    setEvent({
      ...event,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    const errors = isValidEventInputs(event);
    // if(leadId === null){
    //   errors.push("Please select a client");
    // }
    if (errors.length > 0) {
      setValidationError(errors);
    } else {
      setValidationError("");
      const finalEvent = cloneDeep(event);
      finalEvent.start = moment(finalEvent.start).format();
      finalEvent.end = moment(finalEvent.end).format();
      finalEvent.timezone = CURRENT_TIME_ZONE;
      finalEvent.leadId = leadId
      onSubmit(finalEvent);
    }
  }

  const handleDelete = () => {
    onDelete(event);
  }

  const handleUpdate = () => {
    const errors = isValidEventInputs(event);
    // if(leadId === null){
    //   errors.push("Please select a client");
    // }
    if (errors.length > 0) {
      setValidationError(errors);
    } else {
      setValidationError("");
      const finalEvent = cloneDeep(event);
      finalEvent.start = moment(finalEvent.start).format();
      finalEvent.end = moment(finalEvent.end).format();
      finalEvent.timezone = CURRENT_TIME_ZONE;
      finalEvent.leadId = leadId
      onUpdate(finalEvent);
    }
  }

  return (
    <>
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modalMedium
      }}
      
      onClose={handleClose}
      open={open}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        className={classes.eventModalHeader}
      >
        {isEdit ? "Update appointment" : "Create an appointment"}
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {validationError && (
              <SnackbarContent
                message={validationError}
                color="warning"
              />
            )}
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Title *"
              id="title"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                value: event.title || "",
                onChange: handleSelect,
                name: "title"             }} />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <InputLabel className={classes.label}>Start Time</InputLabel>
            <FormControl fullWidth>
              <Datetime closeOnSelect
                dateFormat={DEFAULT_DATE_FORMAT}
                value={event.start || ""}
                onChange={(moment) => handleDatePicker(moment, "start")}
                inputProps={{ placeholder: "Start Time *", id: "startDate", name: "start" }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <InputLabel className={classes.label}>End Time</InputLabel>
            <FormControl fullWidth>
              <Datetime closeOnSelect
                dateFormat={DEFAULT_DATE_FORMAT}
                value={event.end || ""}
                onChange={(moment) => handleDatePicker(moment, "end")}
                inputProps={{ placeholder: "End Time *", id: "endDate", name: "end" }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Description"
              id="description"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                multiline: true,
                value: event.description || "",
                rows: 5,
                onChange: handleSelect,
                name: "description",
              }} />
          </GridItem>
           <GridItem xs={12} sm={4} md={3}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Owner
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={event.userId || loggedUserId || ""}
                  onChange={handleSelect}
                  inputProps={{
                    name: "userId",
                    id: "event-add-userId",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose User
                  </MenuItem>
                  {activeUsers &&
                  activeUsers.map((u) => {
                    return (
                      <MenuItem
                        key={u.id + "event"}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={u.id}
                      >
                        {u.firstName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={9}>
              <SearchLead setLeadId={setLeadId} leadId={leadId} open={open} openLeadModal={setAddLeadModal}/>
            </GridItem>

          {isEdit && <GridContainer justifyContent={"flex-end"}>
            <GridItem>
              <CalendarPopover event={event}></CalendarPopover>
            </GridItem>
          </GridContainer>
          }
          {isEdit && <GridItem style={{width: "100%"}}>
            <div style={{display: "flex", justifyContent: "space-between"}} >
            <div style={{display: "flex", flexDirection: "column"}}>
              <Muted>
                Updated By:{" "}
                {getUserName(event.updatedBy, allUsers)}
              </Muted>
              <Muted>
                Updated At: {convertDate(event.updatedAt)}
              </Muted>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "end"}}>
              <Muted>
                Created By:{" "}
                {getUserName(event.createdBy, allUsers)}
              </Muted>
              <Muted>
                Created At: {convertDate(event.createdAt)}
              </Muted>
            </div>
            </div>
          </GridItem>
          }
          <GridContainer  className={classes.topMargin} justifyContent={"space-between"} spacing={10}>
              <GridItem>
              {isEdit &&
              <Button
                color="danger"
                className={classes.moveToRight}
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
              >Delete
              </Button>}
              <Clearfix />
            </GridItem>
            <GridItem >
              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={(e) => {
                  e.preventDefault();
                  isEdit ? handleUpdate() :  handleSubmit();
                }}
              >
                {isEdit ? "Update" : "Create"}
              </Button>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </DialogContent>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={addLeadModal}
        
        onClose={() => setAddLeadModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAddLeadModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>New Lead</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <AddLead
            allSources={allSources}
            allUsers={allUsers}
            open={addLeadModal}
            handleSuccess={setAddLeadModal}
          />
        </DialogContent>
      </Dialog>
    </Dialog>
  </>
  );
}

const Appointment = () => {
  const classes = useStyles();
  const [calUserId, setCalUserId] = React.useState('all');
  const [events, setEvents] = React.useState([]);
  const [displayDragItemInCell, setDisplayDragItemInCell] = React.useState(true);
  const [draggedEvent, setDraggedEvent] = React.useState(true);
  const [eventsCopy, setEventsCopy] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);
  const [slotInfo, setSlotInfo] = React.useState({ });
  const [openModel, setOpenModel] = React.useState(false);
  const [allUsers, setAllUsers] = React.useState([]);
  const allU = useSelector((state) => state.user.users);
  const { id: loggedUserId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const allAppointments = useSelector((state) => state.appointment.appointments);

  const errMsg = useSelector((state) => state.appointment.errorMsg);
  const createSuccess = useSelector((state) => state.appointment.appointmentCreateSuccess);
  const updateSuccess = useSelector((state) => state.appointment.appointmentUpdateSuccess);
  const deleteSuccess = useSelector((state) => state.appointment.appointmentDeleteSuccess);
  const activeUsers = useSelector((state) => state.user.activeUsers);

  const dispatch = useDispatch();




  const handleDragStart = event => {
    setDraggedEvent(event);
  }

  const dragFromOutsideItem = () => {
    return draggedEvent
  }

  const onDropFromOutside = ({ start, end, allDay }) => {
    // const event = {
    //   id: draggedEvent.id,
    //   title: draggedEvent.title,
    //   start,
    //   end,
    //   allDay: allDay,
    // }
    //
    // setDraggedEvent(null);
    // moveEvent({ event, start, end })
    // console.log("On Drop From outside not supported");
  }

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {

    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end, allDay }
        : existingEvent
    })
    setEvents(nextEvents);
    handleUpdate({...event, start, end, allDay});
  }

  const resizeEvent = ({ event, start, end }) => {

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    setEvents(nextEvents);
    handleUpdate({...event, start, end});
  }

  useEffect(() => {
    dispatch({ type: APPOINTMENT_GET_ALL_REQUESTED });
  }, []);

  useEffect(() => {
    const timer = setInterval(
      () => dispatch({ type: APPOINTMENT_GET_ALL_REQUESTED }),
      3000
    );
    return () => clearInterval(timer);
  });

  useEffect(() => {
    filterEvent(calUserId);
  }, [calUserId]);

  useEffect(() => {
    if (
      updateSuccess === true ||
      deleteSuccess === true ||
      createSuccess === true
    ) {
      dispatch({ type: APPOINTMENT_GET_ALL_REQUESTED });
      dispatch({ type: CLEAR_ERROR });
      setOpenModel(false);
    }
  }, [createSuccess, deleteSuccess, updateSuccess]);



  useEffect(() => {
    setEvents(allAppointments);
    setEventsCopy(allAppointments);
  }, [allAppointments]);
  useEffect(() => {
    setAllUsers(allU);
  }, [allU]);

  const getEvents = () => {
    let evt = events;
    if(calUserId !== 'all'){
      evt = evt.filter(event => event.userId === calUserId )
    }
    return evt.map(e => ({...e, ...{start: momentTz.tz(e.start, e.timezone || DEFAULT_TIME_ZONE).tz(CURRENT_TIME_ZONE).toDate(), end: momentTz.tz(e.end, e.timezone || DEFAULT_TIME_ZONE).tz(CURRENT_TIME_ZONE).toDate()}}));
  }
  const selectedEvent = event => {
    setIsEdit(true);
    setSlotInfo(event);
    setOpenModel(true);
  };
  const addNewEvent = slotInfo => {
    setIsEdit(false);
    setSlotInfo(slotInfo);
    setOpenModel(true);
  };
  const handleSubmit = (info) => {
    dispatch({ type: APPOINTMENT_CREATE_REQUESTED, payload: info });
    setEvents([...events, info])
    ReactGA.event({
      category: "Appointment",
      action: "Added an appointment",
    });
  };

  const handleDelete = (info) => {
    dispatch({ type: APPOINTMENT_DELETE_REQUESTED, payload: { id: info.id } });
    ReactGA.event({
      category: "Appointment",
      action: "Deleted an appointment",
    });
  };

  const handleUpdate = (info) => {
    dispatch({ type: APPOINTMENT_UPDATE_REQUESTED, payload: { id: info.id, payload: info } });
    ReactGA.event({
      category: "Appointment",
      action: "Updated an appointment",
    });
  };

  const filterEvent = (value) => {
    if(value === 'all'){
      setEvents(eventsCopy);
    }else{
      const userData = eventsCopy.filter(event => event.userId === value );
      setEvents(userData);
    }
  }

  const handleCalUserSelect = (e) => {
    const value = e.target.value;
    setCalUserId(value)
  }

  const handleClose = () => {
    setOpenModel(false);
    setIsEdit(false);
    setSlotInfo({ });
  };

  const getColor = (userId) => {
    if(userId){
      const colors = ["green", 'c', 'a', 'azure', 'b', 'f', 'orange', 'e', 'red', 'd'];
      return colors[userId - 1];
    }else{
      return "";
    }
  };

  const eventColors = event => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + getColor(event.userId))
    return {
      className: backgroundColor
    };
  };



  function Event({ event }) {
    const isLonger = moment.duration(moment(event.end).diff(moment(event.start))).asMinutes() > 30;
    const time = (!isLonger)
    ? `${moment(event.start).format('hh:mm a')}`
      : `${moment(event.start).format('hh:mm')} - ${moment(event.end).format('hh:mm a')}`

    const getBody = () => {
      if(!isLonger){
        return <strong>{`${time} - ${event.title}`}</strong>
      }else{
        return (
          <>
          <strong>{time}</strong>
            <br></br>
            <strong>{event.title}</strong>
          </>
        )
      }
    }

    return (
      <div>
      <span>
        {getBody()}
      <small>{event.userId && " x  " + getUserName(event.userId, allUsers)}</small>
      </span>
     </div>
    );
  }
  function EventAgenda({ event }) {
    return (
      <span>
      <strong>{event.title}</strong>
      <p>{event.description}</p>
      <p>{event.userId &&  getUserName(event.userId, allUsers)}</p>
    </span>
    );
  }

  const customMoment = getMoment(CURRENT_TIME_ZONE);
  const localizer = momentLocalizer(customMoment);

  const isLoading = () => {
    const isLoading = allUsers.length === 0;
    return isLoading;
  }

  return (
    <div>
      {/*<Heading*/}
      {/*  textAlign="center"*/}
      {/*  title="Appointment Manager"*/}
      {/*/>*/}
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Select User
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={calUserId || ""}
                  onChange={handleCalUserSelect}
                  inputProps={{
                    name: "userId",
                    id: "cal-add-userId",
                  }}
                >
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={"all"}
                  >
                    All
                  </MenuItem>
                  {activeUsers && activeUsers.length > 0 &&
                  activeUsers.map((u) => {
                    return (
                      <MenuItem
                        key={u.id + "event"}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={u.id}
                      >
                        {u.firstName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </GridItem>
            </GridContainer>
          </CardHeader>
           <CardBody calendar>
            {errMsg && <SnackbarContent message={errMsg} color="warning" />}
              { isLoading()
                ?
                <div className='filtering'>
                  <DragAndDropCalendar
                    min={new Date(0, 0, 0, 7, 0, 0)}
                    max={new Date(0, 0, 0, 21, 0, 0)}
                    views={allViews}
                    localizer={localizer}
                    events={[]}
                    defaultView="work_week"
                  />
                </div>
                :
                <DragAndDropCalendar
                  selectable
                  dragFromOutsideItem={
                    displayDragItemInCell ? dragFromOutsideItem : null
                  }
                  onDropFromOutside={onDropFromOutside}
                  handleDragStart={handleDragStart}
                  views={allViews}
                  localizer={localizer}
                  onEventDrop={moveEvent}
                  onEventResize={resizeEvent}
                  events={getEvents(events)}
                  defaultView="work_week"
                  defaultDate={new Date()}
                  onSelectEvent={event => selectedEvent(event)}
                  onSelectSlot={slotInfo => addNewEvent(slotInfo)}
                  eventPropGetter={eventColors}
                  startAccessor="start"
                  endAccessor="end"
                  popup
                  min={new Date(0, 0, 0, 7, 0, 0)}
                  max={new Date(0, 0, 0, 21, 0, 0)}
                  components={{
                    event: Event,
                    agenda: {
                      event: EventAgenda
                    }
                  }}
                />
            }
          </CardBody>
        </Card>
        <EventDialog  loggedUserId={loggedUserId} slotInfo={slotInfo} isEdit={isEdit} open={openModel} onSubmit={handleSubmit} onDelete={handleDelete} onUpdate={handleUpdate} onClose={handleClose} />
        </GridItem>
        </GridContainer>
    </div>
  );
}

export default withTimer(Appointment);
