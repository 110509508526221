import {
  COURSE_CREATE_REQUESTED,
  COURSE_CREATE_FAILED,
  COURSE_CREATE_SUCCEEDED,
  COURSE_BULK_CREATE_REQUESTED,
  COURSE_BULK_CREATE_FAILED,
  COURSE_BULK_CREATE_SUCCEEDED,
  COURSE_GET_ALL_REQUESTED,
  COURSE_GET_ALL_FAILED,
  COURSE_GET_ALL_SUCCEEDED,
  COURSE_GET_REQUESTED,
  COURSE_GET_FAILED,
  COURSE_GET_SUCCEEDED,
  COURSE_UPDATE_REQUESTED,
  COURSE_UPDATE_FAILED,
  COURSE_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  courseCreateError: false,
  courseCreateFetching: false,
  courseCreateSuccess: false,

  courseBulkCreateError: false,
  courseBulkCreateFetching: false,
  courseBulkCreateSuccess: false,

  courseUpdateError: false,
  courseUpdateFetching: false,
  courseUpdateSuccess: false,

  courseGetError: false,
  courseGetFetching: false,
  courseGetSuccess: false,

  courseGetAllError: false,
  courseGetAllFetching: false,
  courseGetAllSuccess: false,
};

const defaultState = {
  courses: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case COURSE_CREATE_REQUESTED:
      return {
        ...state,
        courseCreateFetching: true,
        courseCreateError: false,
        courseCreateSuccess: false,
      };
    case COURSE_CREATE_FAILED:
      return {
        ...state,
        courseCreateFetching: false,
        courseCreateError: true,
        courseCreateSuccess: false,
      };
    case COURSE_CREATE_SUCCEEDED:
      return {
        ...state,
        courseDetail: action.payload,
        courseCreateFetching: false,
        courseCreateError: false,
        courseCreateSuccess: true,
      };

    case COURSE_BULK_CREATE_REQUESTED:
      return {
        ...state,
        courseBulkCreateFetching: true,
        courseBulkCreateError: false,
        courseBulkCreateSuccess: false,
      };
    case COURSE_BULK_CREATE_FAILED:
      return {
        ...state,
        courseBulkCreateFetching: false,
        courseBulkCreateError: true,
        courseBulkCreateSuccess: false,
      };
    case COURSE_BULK_CREATE_SUCCEEDED:
      return {
        ...state,
        courseDetail: action.payload,
        courseBulkCreateFetching: false,
        courseBulkCreateError: false,
        courseBulkCreateSuccess: true,
      };

    case COURSE_UPDATE_REQUESTED:
      return {
        ...state,
        courseUpdateFetching: true,
        courseUpdateError: false,
        courseUpdateSuccess: false,
      };
    case COURSE_UPDATE_FAILED:
      return {
        ...state,
        courseUpdateFetching: false,
        courseUpdateError: true,
        courseUpdateSuccess: false,
      };
    case COURSE_UPDATE_SUCCEEDED:
      return {
        ...state,
        courseDetail: action.payload,
        courseUpdateFetching: false,
        courseUpdateError: false,
        courseUpdateSuccess: true,
      };

    case COURSE_GET_REQUESTED:
      return {
        ...state,
        courseGetFetching: true,
        courseGetError: false,
      };
    case COURSE_GET_FAILED:
      return {
        ...state,
        courseGetFetching: false,
        courseGetError: true,
      };
    case COURSE_GET_SUCCEEDED:
      return {
        ...state,
        courseDetail: action.payload,
        courseGetFetching: false,
        courseGetError: false,
      };

    case COURSE_GET_ALL_REQUESTED:
      return {
        ...state,
        courseGetAllFetching: true,
        courseGetAllError: false,
      };
    case COURSE_GET_ALL_FAILED:
      return {
        ...state,
        courseGetAllFetching: false,
        courseGetAllError: true,
      };
    case COURSE_GET_ALL_SUCCEEDED:
      return {
        ...state,
        courses: action.payload,
        courseGetAllFetching: false,
        courseGetAllError: false,
      };
    default:
      return state;
  }
}
