import {
  TODO_CREATE_REQUESTED,
  TODO_CREATE_FAILED,
  TODO_CREATE_SUCCEEDED,
  TODO_GET_ALL_REQUESTED,
  TODO_GET_ALL_FAILED,
  TODO_GET_ALL_SUCCEEDED,
  TODO_GET_REQUESTED,
  TODO_GET_FAILED,
  TODO_GET_SUCCEEDED,
  TODO_COUNT_GET_REQUESTED,
  TODO_COUNT_GET_FAILED,
  TODO_COUNT_GET_SUCCEEDED,
  TODO_UPDATE_REQUESTED,
  TODO_UPDATE_FAILED,
  TODO_UPDATE_SUCCEEDED,
  TODO_DELETE_REQUESTED,
  TODO_DELETE_FAILED,
  TODO_DELETE_SUCCEEDED,
  CLEAR_ERROR,
  TODO_UPDATE_ALL_REQUESTED,
  TODO_UPDATE_ALL_FAILED,
  TODO_UPDATE_ALL_SUCCEEDED,
} from "../actions";

const defaultError = {
  todoCreateError: false,
  todoCreateFetching: false,
  todoCreateSuccess: false,

  todoUpdateError: false,
  todoUpdateFetching: false,
  todoUpdateSuccess: false,

  todoGetError: false,
  todoGetFetching: false,
  todoGetSuccess: false,

  todoGetCountError: false,
  todoGetCountFetching: false,
  todoGetCountSuccess: false,

  todoGetAllError: false,
  todoGetAllFetching: false,
  todoGetAllSuccess: false,

  todoDeleteError: false,
  todoDeleteFetching: false,
  todoDeleteSuccess: false,
  errorMsg: "",
};

const defaultState = {
  todos: [],
  count: 0,
  activeTodo: [],
  todoDetail: {},
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case TODO_CREATE_REQUESTED:
      return {
        ...state,
        todoCreateFetching: true,
        todoCreateError: false,
        todoCreateSuccess: false,
      };
    case TODO_CREATE_FAILED:
      return {
        ...state,
        todoCreateFetching: false,
        todoCreateError: true,
        todoCreateSuccess: false,
        errorMsg: action.payload,
      };
    case TODO_CREATE_SUCCEEDED:
      return {
        ...state,
        todoDetail: action.payload,
        todoCreateFetching: false,
        todoCreateError: false,
        todoCreateSuccess: true,
      };

    case TODO_UPDATE_REQUESTED:
      return {
        ...state,
        todoUpdateFetching: true,
        todoUpdateError: false,
        todoUpdateSuccess: false,
      };

    case TODO_UPDATE_ALL_REQUESTED:
      return {
        ...state,
        todoUpdateFetching: true,
        todoUpdateError: false,
        todoUpdateSuccess: false,
      };
    case TODO_UPDATE_ALL_FAILED:
      return {
        ...state,
        todoUpdateFetching: false,
        todoUpdateError: true,
        todoUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case TODO_UPDATE_ALL_SUCCEEDED:
      return {
        ...state,
        todoUpdateFetching: false,
        todoUpdateError: false,
        todoUpdateSuccess: true,
      };
    case TODO_UPDATE_FAILED:
      return {
        ...state,
        todoUpdateFetching: false,
        todoUpdateError: true,
        todoUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case TODO_UPDATE_SUCCEEDED:
      return {
        ...state,
        todoDetail: action.payload,
        todoUpdateFetching: false,
        todoUpdateError: false,
        todoUpdateSuccess: true,
      };

    case TODO_GET_REQUESTED:
      return {
        ...state,
        todoGetFetching: true,
        todoGetError: false,
      };
    case TODO_GET_FAILED:
      return {
        ...state,
        todoGetFetching: false,
        todoGetError: true,
      };
    case TODO_GET_SUCCEEDED:
      return {
        ...state,
        todoDetail: action.payload,
        todoGetFetching: false,
        todoGetError: false,
      };

    case TODO_COUNT_GET_REQUESTED:
      return {
        ...state,
        todoGetCountFetching: true,
        todoGetCountError: false,
        todoGetCountSuccess: false,
      };
    case TODO_COUNT_GET_FAILED:
      return {
        ...state,
        todoGetCountFetching: false,
        todoGetCountError: true,
        todoGetCountSuccess: false,
      };
    case TODO_COUNT_GET_SUCCEEDED:
      return {
        ...state,
        count: action.payload.length,
        activeTodo: action.payload,
        todoGetCountFetching: false,
        todoGetCountError: false,
        todoGetCountSuccess: true,
      };

    case TODO_GET_ALL_REQUESTED:
      return {
        ...state,
        todoGetAllFetching: true,
        todoGetAllError: false,
      };
    case TODO_GET_ALL_FAILED:
      return {
        ...state,
        todoGetAllFetching: false,
        todoGetAllError: true,
      };
    case TODO_GET_ALL_SUCCEEDED:
      return {
        ...state,
        todos: action.payload,
        todoGetAllFetching: false,
        todoGetAllError: false,
      };

    case TODO_DELETE_REQUESTED:
      return {
        ...state,
        todoDeleteFetching: true,
        todoDeleteError: false,
        todoDeleteSuccess: false,
      };
    case TODO_DELETE_FAILED:
      return {
        ...state,
        todoDeleteFetching: false,
        todoDeleteError: true,
        todoDeleteSuccess: false,
      };
    case TODO_DELETE_SUCCEEDED:
      return {
        ...state,
        todoDeleteFetching: false,
        todoDeleteError: false,
        todoDeleteSuccess: true,
      };
    default:
      return state;
  }
}
