import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ReCAPTCHA from "react-google-recaptcha";
import Checkbox from "@material-ui/core/Checkbox";
import { Divider } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import stypes2 from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { Check } from "@material-ui/icons";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  recaptcha: {
    marginTop: "10px",
    textAlign: "center",
  },
  buzz: {
    color: "#cf3046",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...stypes2,
};

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      education: false,
      visa: false,
      skillAssessment: false,
      healthInsurance: false,
      other: false,
      validation: {
        buzz: false,
      },
      smsEmailConsent: false,
      captcha: "",
    };
  }
  sendState() {
    return this.state;
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
    this.isValidated();
  };

  isValidated() {
    const isSelected = Object.values(this.state).indexOf(true) >= 0;
    if (isSelected) {
      this.setState({ validation: { buzz: false } });
    } else {
      this.setState({ validation: { buzz: true } });
    }

    const captchaText = this.state.captcha;
    if (isSelected && captchaText !== "") {
      return true;
    }
    return false;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4>Choose at least one service that you are interested in.</h4>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <GridContainer>
              <GridItem xs={6} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={this.handleChange("education")}
                    checkedIcon={
                      <i
                        className={
                          "fas fa-graduation-cap " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    icon={
                      <i
                        className={
                          "fas fa-graduation-cap " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <h6>Student</h6>
                </div>
              </GridItem>
              <GridItem xs={6} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={this.handleChange("visa")}
                    checkedIcon={
                      <i
                        className={"fas fa-file " + classes.iconCheckboxIcon}
                      />
                    }
                    icon={
                      <i
                        className={"fas fa-file " + classes.iconCheckboxIcon}
                      />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <h6>Visa</h6>
                </div>
              </GridItem>
              <GridItem xs={6} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={this.handleChange("skillAssessment")}
                    checkedIcon={
                      <i
                        className={
                          "fas fa-briefcase " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    icon={
                      <i
                        className={
                          "fas fa-briefcase " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <h6>Skill Assessment</h6>
                </div>
              </GridItem>
              <GridItem xs={6} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={this.handleChange("healthInsurance")}
                    checkedIcon={
                      <i
                        className={
                          "fas fa-plus-square " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    icon={
                      <i
                        className={
                          "fas fa-plus-square " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <h6>Health Insurance</h6>
                </div>
              </GridItem>
              <GridItem xs={6} sm={4}>
                <div className={classes.choiche}>
                  <Checkbox
                    tabIndex={-1}
                    onClick={this.handleChange("other")}
                    checkedIcon={
                      <i
                        className={"fas fa-star " + classes.iconCheckboxIcon}
                      />
                    }
                    icon={
                      <i
                        className={"fas fa-star " + classes.iconCheckboxIcon}
                      />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <h6>Other</h6>
                </div>
              </GridItem>
            </GridContainer>
            <Divider />
            <GridContainer justifyContent="center">
              <GridItem>
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() =>
                          this.setState({
                            smsEmailConsent: !this.state.smsEmailConsent,
                          })
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="I agree to receive text messages and emails for any updates."
                  />
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="center">
              <GridItem className={classes.recaptcha}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                  onChange={async (value) => {
                    await this.setState({
                      captcha: value,
                    });
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step2);
