import {
  IMMIGRATION_GET_ALL_FAILED,
  IMMIGRATION_GET_ALL_REQUESTED,
  IMMIGRATION_GET_ALL_SUCCEEDED,
  IMMIGRATION_UPDATE_FAILED,
  IMMIGRATION_UPDATE_REQUESTED,
  IMMIGRATION_UPDATE_SUCCEEDED,
  IMMIGRATION_CREATE_FAILED,
  IMMIGRATION_CREATE_REQUESTED,
  IMMIGRATION_CREATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  immigrationCreateEditSuccess: false,
  immigrationCreateEditFetching: false,
  immigrationCreateEditFailure: false,

  immigrationsGetAllSuccess: false,
  immigrationsGetAllFetching: false,
  immigrationsGetAllFailure: false,
  errorMsg: "",
};

const defaultState = {
  immigrations: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case IMMIGRATION_GET_ALL_REQUESTED:
      return {
        ...state,
        immigrationsGetAllFetching: true,
        immigrationsGetAllFailure: false,
        immigrationsGetAllSuccess: false,
      };
    case IMMIGRATION_GET_ALL_FAILED:
      return {
        ...state,
        immigrationsGetAllFetching: false,
        immigrationsGetAllFailure: true,
        immigrationsGetAllSuccess: false,
        errorMsg: action.payload.message || "",
      };
    case IMMIGRATION_GET_ALL_SUCCEEDED:
      return {
        ...state,
        immigrationsGetAllFetching: false,
        immigrationsGetAllFailure: false,
        immigrationsGetAllSuccess: true,
        immigrations: action.payload,
      };

    case IMMIGRATION_CREATE_REQUESTED:
      return {
        ...state,
        immigrationCreateEditFetching: true,
        immigrationCreateEditFailure: false,
        immigrationCreateEditSuccess: false,
      };
    case IMMIGRATION_CREATE_FAILED:
      return {
        ...state,
        immigrationCreateEditFetching: false,
        immigrationCreateEditFailure: true,
        immigrationCreateEditSuccess: false,
        errorMsg: action.payload.message || "",
      };
    case IMMIGRATION_CREATE_SUCCEEDED:
      return {
        ...state,
        immigrationCreateEditFetching: false,
        immigrationCreateEditFailure: false,
        immigrationCreateEditSuccess: true,
      };

    case IMMIGRATION_UPDATE_REQUESTED:
      return {
        ...state,
        immigrationCreateEditFetching: true,
        immigrationCreateEditFailure: false,
        immigrationCreateEditSuccess: false,
      };
    case IMMIGRATION_UPDATE_FAILED:
      return {
        ...state,
        immigrationCreateEditFetching: false,
        immigrationCreateEditFailure: true,
        immigrationCreateEditSuccess: false,
        errorMsg: action.payload.message || "",
      };
    case IMMIGRATION_UPDATE_SUCCEEDED:
      return {
        ...state,
        immigrationCreateEditFetching: false,
        immigrationCreateEditFailure: false,
        immigrationCreateEditSuccess: true,
      };
    default:
      return state;
  }
}
