import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  UNIVERSITY_CREATE_REQUESTED,
  UNIVERSITY_CREATE_FAILED,
  UNIVERSITY_CREATE_SUCCEEDED,
  UNIVERSITY_GET_ALL_REQUESTED,
  UNIVERSITY_GET_ALL_FAILED,
  UNIVERSITY_GET_ALL_SUCCEEDED,
  UNIVERSITY_UPDATE_REQUESTED,
  UNIVERSITY_UPDATE_FAILED,
  UNIVERSITY_UPDATE_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchAllUniversities(action) {
  try {
    const token = yield select(getToken);
    const universities = yield call(remoteResource.getAllUniversities, token);
    yield put({
      type: UNIVERSITY_GET_ALL_SUCCEEDED,
      payload: universities.data,
    });
  } catch (e) {
    yield put({ type: UNIVERSITY_GET_ALL_FAILED, payload: e.message });
  }
}

function* createUniversity(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const university = yield call(
      remoteResource.createUniversity,
      payload,
      token
    );
    yield put({
      type: UNIVERSITY_CREATE_SUCCEEDED,
      payload: university.data,
    });
  } catch (e) {
    yield put({ type: UNIVERSITY_CREATE_FAILED, payload: e.message });
  }
}

function* updateUniversity(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const university = yield call(
      remoteResource.updateUniversity,
      id,
      payload,
      token
    );
    yield put({
      type: UNIVERSITY_UPDATE_SUCCEEDED,
      payload: university.data,
    });
  } catch (e) {
    yield put({ type: UNIVERSITY_UPDATE_FAILED, payload: e.message });
  }
}

export function* watchUniversity() {
  yield takeLatest(UNIVERSITY_CREATE_REQUESTED, createUniversity);
  yield takeLatest(UNIVERSITY_UPDATE_REQUESTED, updateUniversity);
  yield takeLatest(UNIVERSITY_GET_ALL_REQUESTED, fetchAllUniversities);
}
