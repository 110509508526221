import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  ANNOUNCEMENT_CREATE_REQUESTED,
  ANNOUNCEMENT_CREATE_FAILED,
  ANNOUNCEMENT_CREATE_SUCCEEDED,
  ANNOUNCEMENT_GET_ALL_REQUESTED,
  ANNOUNCEMENT_GET_ALL_FAILED,
  ANNOUNCEMENT_GET_ALL_SUCCEEDED,
  ANNOUNCEMENT_GET_REQUESTED,
  ANNOUNCEMENT_GET_FAILED,
  ANNOUNCEMENT_GET_SUCCEEDED,
  ANNOUNCEMENT_UPDATE_REQUESTED,
  ANNOUNCEMENT_UPDATE_FAILED,
  ANNOUNCEMENT_UPDATE_SUCCEEDED,
  LEAD_UPDATE_SUCCEEDED,
  ANNOUNCEMENT_DELETE_SUCCEEDED,
  ANNOUNCEMENT_DELETE_FAILED,
  ANNOUNCEMENT_DELETE_REQUESTED,
} from "../actions";

import { getToken } from "./selectors";

function* fetchAnnouncement(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const announcement = yield call(remoteResource.getAnnouncment, id, token);
    yield put({
      type: ANNOUNCEMENT_GET_SUCCEEDED,
      payload: announcement.data,
    });
  } catch (e) {
    yield put({ type: ANNOUNCEMENT_GET_FAILED, payload: e.message });
  }
}

function* fetchAllAnnouncement(action) {
  try {
    const token = yield select(getToken);
    const announcment = yield call(remoteResource.getAllAnnouncements, token);
    yield put({
      type: ANNOUNCEMENT_GET_ALL_SUCCEEDED,
      payload: announcment.data,
    });
  } catch (e) {
    yield put({ type: ANNOUNCEMENT_GET_ALL_FAILED, payload: e.message });
  }
}

function* createAnnouncement(action) {
  try {
    const token = yield select(getToken);
    const announcement = yield call(
      remoteResource.createAnnouncement,
      action.payload,
      token
    );

    yield put({
      type: ANNOUNCEMENT_CREATE_SUCCEEDED,
      payload: announcement.data,
    });
  } catch (e) {
    yield put({
      type: ANNOUNCEMENT_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateAnnouncement(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const announcement = yield call(
      remoteResource.updateAnnouncement,
      id,
      payload,
      token
    );
    yield put({
      type: ANNOUNCEMENT_UPDATE_SUCCEEDED,
      payload: announcement.data,
    });
  } catch (e) {
    yield put({
      type: ANNOUNCEMENT_UPDATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* deleteAnnouncement(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    yield call(remoteResource.deleteAnnouncement, id, token);
    yield put({
      type: ANNOUNCEMENT_DELETE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: ANNOUNCEMENT_DELETE_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchAnnouncement() {
  yield takeLatest(ANNOUNCEMENT_GET_REQUESTED, fetchAnnouncement);
  yield takeLatest(ANNOUNCEMENT_CREATE_REQUESTED, createAnnouncement);
  yield takeLatest(ANNOUNCEMENT_UPDATE_REQUESTED, updateAnnouncement);
  yield takeLatest(ANNOUNCEMENT_GET_ALL_REQUESTED, fetchAllAnnouncement);
  yield takeLatest(ANNOUNCEMENT_DELETE_REQUESTED, deleteAnnouncement);
}
