import {
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  CLEAR_ERROR,
  LOGIN_SUCCEEDED,
} from "../actions";

const defaultState = {
  loginError: false,
  loginProgress: false,
  errorMsg: "",
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOGIN_FAILED:
      return {
        ...state,
        loginError: true,
        loginProgress: false,
        errorMsg: action.payload,
      };
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        loginError: false,
        loginProgress: false,
      };
    case LOGIN_REQUESTED:
      return {
        ...state,
        loginError: false,
        loginProgress: true,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        loginError: false,
        loginProgress: false,
      };
    default:
      return state;
  }
}
