import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  SOURCE_CREATE_REQUESTED,
  SOURCE_CREATE_FAILED,
  SOURCE_CREATE_SUCCEEDED,
  SOURCE_GET_ALL_REQUESTED,
  SOURCE_GET_ALL_FAILED,
  SOURCE_GET_ALL_SUCCEEDED,
  SOURCE_UPDATE_REQUESTED,
  SOURCE_UPDATE_FAILED,
  SOURCE_UPDATE_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchAllSources(action) {
  try {
    const token = yield select(getToken);
    const sources = yield call(remoteResource.getAllSources, token);
    yield put({
      type: SOURCE_GET_ALL_SUCCEEDED,
      payload: sources.data,
    });
  } catch (e) {
    yield put({ type: SOURCE_GET_ALL_FAILED, payload: e.message });
  }
}

function* createSource(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const course = yield call(remoteResource.createSource, payload, token);
    yield put({
      type: SOURCE_CREATE_SUCCEEDED,
      payload: course.data,
    });
  } catch (e) {
    yield put({ type: SOURCE_CREATE_FAILED, payload: e.message });
  }
}

function* updateSource(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const course = yield call(remoteResource.updateSource, id, payload, token);
    yield put({
      type: SOURCE_UPDATE_SUCCEEDED,
      payload: course.data,
    });
  } catch (e) {
    yield put({ type: SOURCE_UPDATE_FAILED, payload: e.message });
  }
}

export function* watchSource() {
  yield takeLatest(SOURCE_CREATE_REQUESTED, createSource);
  yield takeLatest(SOURCE_UPDATE_REQUESTED, updateSource);
  yield takeLatest(SOURCE_GET_ALL_REQUESTED, fetchAllSources);
}
