const OCCUPATION_LIST = require("./files/occupationList.json");
const pjson = require("../../package.json");
const { useSelector } = require("react-redux");

const appointmentPath = "public/appointment";
const branchTenantInfo = {
  success: [
    {
      name: "Canberra",
      url: `https://crm.successedu.com.au/${appointmentPath}`,
    },
    {
      name: "CBD-Canberra (Chinese)",
      url: `https://cbd-canberra.successedu.com.au/${appointmentPath}`,
    },
    {
      name: "Sydney",
      url: `https://sydney.successedu.com.au/${appointmentPath}`,
    },
    {
      name: "Brisbane",
      url: `https://brisbane.successedu.com.au/${appointmentPath}`,
    },
    {
      name: "GoldCoast",
      url: `https://goldcoast.successedu.com.au/${appointmentPath}`,
    },
    {
      name: "Launceston",
      url: `https://launceston.successedu.com.au/${appointmentPath}`,
    },
    {
      name: "Kathmandu",
      url: `https://kathmandu.successedu.com.au/${appointmentPath}`,
    },
  ],
  summit: [
    {
      name: "Canberra",
      url: `https://summit.eduvisahub.com/${appointmentPath}`,
    }
  ],
};
const getBranchInfo = () => {
  return branchTenantInfo[TENANT];
};

const TENANT = process.env.REACT_APP_TENANT || "success";
const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;
const TINY_INIT = {
  min_height: 200,
  menubar: false,
  plugins: [
    "advlist",
    "autolink",
    "autosave",
    "lists",
    "link",
    "image",
    "charmap",
    "preview",
    "anchor",
    "searchreplace",
    "visualblocks",
    "code",
    "fullscreen",
    "insertdatetime",
    "media",
    "table",
    "code",
    "help",
    "wordcount",
  ],
  toolbar:
    "styles | bold italic underline strikethrough |" +
    "forecolor | alignleft aligncenter |link " +
    "bullist numlist",
};
const getCurrentTimeZone = () => {
  let moment = require("moment-timezone/builds/moment-timezone-with-data-2012-2022");
  return moment.tz.guess();
};
const SUPER_ADMIN = 1;
const MANAGER = 2;
const GENERAL = 3;
const ACCOUNTING = 4;
const LEAD_MANAGEMENT = 5;

const ROLES = {
  SUPER_ADMIN,
  MANAGER,
  GENERAL,
  ACCOUNTING,
  LEAD_MANAGEMENT,
};
const SUPER_ROLE = [SUPER_ADMIN, MANAGER];
const SUPER_ADMIN_ROLES = [SUPER_ADMIN];
const GENERAL_ROLE = [GENERAL];
const ALL_ROLE = [SUPER_ADMIN, MANAGER, GENERAL];

const SEND_BIRTHDAY_EMAIL = "SEND_BIRTHDAY_EMAIL";
const SEND_BIRTHDAY_SMS = "SEND_BIRTHDAY_SMS";
const SEND_FEE_DUE_EMAIL = "SEND_FEE_DUE_EMAIL";
const SEND_FEE_DUE_SMS = "SEND_FEE_DUE_SMS";
const SEND_VISA_EXPIRY_EMAIL = "SEND_VISA_EXPIRY_EMAIL";
const SEND_VISA_EXPIRY_SMS = "SEND_VISA_EXPIRY_SMS";
const SEND_TAX_EMAIL = "SEND_TAX_EMAIL";
const SEND_TAX_SMS = "SEND_TAX_SMS";
const CUSTOM_SMS = "CUSTOM_SMS";
const CUSTOM_EMAIL = "CUSTOM_EMAIL";

const BIRTHDAY_EMAIL = "BirthdayEmail";
const FEE_DUE_EMAIL = "FeeDueEmail";
const TAX_EMAIL = "TaxPeriodEmail";
const VISA_EXPIRY_EMAIL = "VisaExpiryEmail";

const LEAD_TABLE_ACTION = [
  CUSTOM_SMS,
  CUSTOM_EMAIL,
  SEND_TAX_EMAIL,
  SEND_TAX_SMS,
];
const EMAIL_ACTION = [
  SEND_BIRTHDAY_EMAIL,
  SEND_FEE_DUE_EMAIL,
  SEND_VISA_EXPIRY_EMAIL,
];
const SMS_ACTION = [SEND_BIRTHDAY_SMS, SEND_FEE_DUE_SMS, SEND_VISA_EXPIRY_SMS];
const STUDENT_TABLE_ACTION = [
  SEND_BIRTHDAY_EMAIL,
  SEND_BIRTHDAY_SMS,
  SEND_FEE_DUE_EMAIL,
  SEND_FEE_DUE_SMS,
  SEND_TAX_EMAIL,
  SEND_TAX_SMS,
  CUSTOM_SMS,
  CUSTOM_EMAIL,
];
const VISA_APPLICANT_TABLE_ACTION = [
  SEND_BIRTHDAY_EMAIL,
  SEND_BIRTHDAY_SMS,
  SEND_VISA_EXPIRY_EMAIL,
  SEND_VISA_EXPIRY_SMS,
  SEND_TAX_EMAIL,
  SEND_TAX_SMS,
  CUSTOM_SMS,
  CUSTOM_EMAIL,
];
const SKILL_ASSESSMENT_TABLE_ACTION = [
  SEND_BIRTHDAY_EMAIL,
  SEND_BIRTHDAY_SMS,
  SEND_VISA_EXPIRY_EMAIL,
  SEND_VISA_EXPIRY_SMS,
  SEND_TAX_EMAIL,
  SEND_TAX_SMS,
  CUSTOM_SMS,
  CUSTOM_EMAIL,
];
const INSURANCE_APPLICANT_TABLE_ACTION = [CUSTOM_SMS, CUSTOM_EMAIL];

const COUNTRY_CODE_AU = "AU";
const COUNTRY_CODE_NP = "NP";

const CLOCKIN_AUTH = process.env.REACT_APP_CLOCKIN_AUTH || "6789";
const BRANCH_NAME = process.env.REACT_APP_BRANCH_NAME || "Canberra";
const LOGO_HORIZONTAL = process.env.REACT_APP_LOGO_HORIZONTAL || "";
const LOGO_VERTICAL = process.env.REACT_APP_LOGO_VERTICAL || "";
const CLIENT_SOURCE_WEBSITE = process.env.REACT_APP_SOURCE_WEBSITE || 1;
const CLIENT_SOURCE_OFFICE_CHECKIN =
  process.env.REACT_APP_SOURCE_OFFICE_CHECKIN || 2;

const APP_VERSION = pjson.version || "N/A";

const COMPANY_NAME =
  process.env.REACT_APP_COMPANY_NAME || "Success Education and Visa Services";
const COMPANY_URL =
  process.env.REACT_APP_COMPANY_URL || "https://successedu.com.au/";
const BRANCH_LOCATION =
  process.env.REACT_APP_BRANCH_LOCATION ||
  "Suit J, Level 1, 34-42 Cohen St Belconnen ACT Australia, 2617";
const BRANCH_LOCATION_LINK =
  process.env.REACT_APP_BRANCH_LOCATION_LINK ||
  "https://g.page/successeduandvisa";
const BRANCH_LOCATION_MAP =
  process.env.REACT_APP_BRANCH_LOCATION_MAP ||
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3258.672456537871!2d149.0608352157203!3d-35.239522880299724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b1652f2bcb777f1%3A0xf3983eec286cb947!2sSuccess%20Education%20and%20Visa%20Services!5e0!3m2!1sen!2snp!4v1651743193132!5m2!1sen!2snp";
const BRANCH_EMAIL =
  process.env.REACT_APP_BRANCH_EMAIL || "info@successedu.com.au";
const BRANCH_PHONE =
  process.env.REACT_APP_BRANCH_PHONE || "0430 400 478 / 0432 364 056";
const BRANCH_MOBILE = process.env.REACT_APP_BRANCH_MOBILE || "(02) 6193 4085";
const BRANCH_FACEBOOK =
  process.env.REACT_APP_BRANCH_FACEBOOK ||
  "https://www.facebook.com/successeduandvisa";

const FILE_UPLOAD_URL =
  process.env.REACT_APP_FILE_UPLOAD_URL ||
  "https://3lbqul2ps1.execute-api.us-east-1.amazonaws.com/Prod/fileUpload";

const DEFAULT_TIME_ZONE =
  process.env.REACT_APP_DEFAULT_TIME_ZONE || "Australia/Sydney";
const CURRENT_TIME_ZONE = getCurrentTimeZone() || "";
const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
const DEFAULT_DATE_FORMAT_WITH_MONTH = "DD/MMM/YYYY";
const DEFAULT_DATE_FORMAT_WITH_TIME = "LLL";
const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";
const EMAIL_TEMPLATE = {
  BIRTHDAY_EMAIL,
  FEE_DUE_EMAIL,
  VISA_EXPIRY_EMAIL,
  TAX_EMAIL,
};

const SMS_TEMPLATE = {
  BIRTHDAY: "BirthdaySMS",
  TAX: "TaxSMS",
  FeeDue: "FeeDueSMS",
  VisaExpiry: "VisaExpirySMS",
};

const COUNTRIES = [
  "Nepal",
  "Australia",
  "India",
  "Bhutan",
  "Bangladesh",
  "Vietnam",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua & Deps",
  "Argentina",
  "Armenia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bolivia",
  "Bosnia Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Rep",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo {Democratic Rep}",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland {Republic}",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar, {Burma}",
  "Namibia",
  "Nauru",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "St Kitts & Nevis",
  "St Lucia",
  "Saint Vincent & the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
const monthColor = [
  "#F2D7D5",
  "#D4E6F1",
  "#FAE5D3",
  "#D1F2EB",
  "#FCF3CF",
  "#AEB6BF",
  "#FDEBD0",
  "#D4EFDF",
  "#F6DDCC",
  "#EAEDED",
  "#EBDEF0",
  "#85C1E9",
];

const userAccess = [
  {
    name: "dashboardManagement",
    label: "Dashboard Mgmt",
  },
  {
    name: "agencyAgreementManagement",
    label: "Agency Agreement Mgmt",
  },
  {
    name: "userManagement",
    label: "User Mgmt",
  },
  {
    name: "universityManagement",
    label: "University Mgmt",
  },
  {
    name: "courseManagement",
    label: "Course Mgmt",
  },
  {
    name: "sourceManagement",
    label: "Source Mgmt",
  },
  {
    name: "settingManagement",
    label: "Setting Mgmt",
  },
];

const BranchURLs = getBranchInfo();

const NOMINATION_STATUS_LIST = [
  "Lodged",
  "Approved",
  "Refused",
  "Withdrawn",
  " Other",
];
const VISA_LIST = [
  "Student Visa (500)",
  "Student Subsequent Visa (500)",
  "Visitor Visa (600)",
  "Temporary Activity (408) - Pandemic",
  "Temporary Activity (408) - Entertainment",
  "Temporary Activity (408) - Entertainment (Sponsorship)",
  "Temporary Graduate Visa (485) - PSW",
  "Temporary Work (Short Stay Specialist) (400) Visa",
  "Temporary Graduate Subsequent Visa (485)",
  "Temporary Graudate Visa (485) - Graduate Work Stream",
  "Temporary Graudate Visa (485) - Replacement",
  "Temporary Graudate Visa (485) - Extension",
  "Skilled Nominated Visa (190)",
  "Skilled Nominated Visa (491)",
  "Skilled Nominated Visa (190) - (SBO)",
  "Skilled Nominated Visa (491) - (SBO)",
  "Skilled Nominated Subsequent Visa (491)",
  "Permanent Residence (Skilled Regional) Visa (191)",
  "Skilled Independent visa (subclass 189) - Point Test Stream",
  "Skilled Independent visa (subclass 189) - New Zealand stream",
  "Skilled Independent visa (subclass 189) -  Hong Kong stream",
  "Partner visa (820) - Stage 1 - Onshore",
  "Partner visa (801) - Stage 2 - Onshore",
  "Partner visa (309) - Stage 1 - Overseas",
  "Partner visa (100) - Stage 2 - Overseas",
  "TSS Visa (482) - Small Business Sponsorship",
  "TSS Visa (482) - Nomination",
  "TSS Visa (482) - Visa Application",
  "TSS Subsequent Visa (482) - Visa Application",
  "Employer Sponsored Regional Visa (494) - RCB",
  "Employer Sponsored Regional Visa (494) - Nomination",
  "Employer Sponsored Regional Visa (494) - Visa Application",
  "Employer Sponsored Regional Sub Visa (494) - Visa Application",
  "ENS Visa (186) - Nomination - Direct Entry",
  "ENS Visa (186) - Visa Application - Direct Entry",
  "ENS Visa (186) - Visa Application - TRT",
  "ENS Subsequent Visa (186) - Visa Application - TRT",
  "ACS with PY",
  "ACS with Employment",
  "VETASSESS - Pro Occupation",
  "VETASSESS - Trade Occupation",
  "Engineering Australia",
  "ACWA",
  "ANMAC",
  "IPA/CPA",
  "AAT Lodgement",
  "AAT Hearing",
  "Work Right Application",
  "Other",
];

const LOCATION_LIST = ["Onshore", "Offshore", "Unknown"];

const visaTypeOption = VISA_LIST.map((v) => ({ value: v, label: v }));
const nominationStatus = NOMINATION_STATUS_LIST.map((v) => ({
  value: v,
  label: v,
}));
const locationOption = LOCATION_LIST.map((v) => ({ value: v, label: v }));

const occupationOption = OCCUPATION_LIST.map((v) => ({
  value: v.title,
  label: v.title,
}));

function useGetVisaTypeOptions() {
  const visaTypeList = useSelector((state) => state.visa.visas);
  return visaTypeList && Array.isArray(visaTypeList)
    ? visaTypeList.map((v) => ({ value: v.visaType, label: v.visaType }))
    : [];
}
function useGetOccupationOptions() {
  const occupationList = useSelector((state) => state.occupation.occupations);
  return occupationList && Array.isArray(occupationList)
    ? occupationList?.map((v) => ({ value: v.title, label: v.title }))
    : [];
}

const occupationCodeOption = OCCUPATION_LIST.map((v) => ({
  value: v.code,
  label: v.code,
}));

const generalUserFilter = [
  "weekly",
  "monthly",
  "quarterly",
  "half_year",
  "yearly",
];

module.exports = {
  SUPER_ADMIN,
  GENERAL,
  MANAGER,
  SUPER_ROLE,
  GENERAL_ROLE,
  ALL_ROLE,
  ROLES,
  SEND_TAX_EMAIL,
  SEND_TAX_SMS,
  SEND_BIRTHDAY_EMAIL,
  SEND_BIRTHDAY_SMS,
  SEND_FEE_DUE_EMAIL,
  SEND_FEE_DUE_SMS,
  SEND_VISA_EXPIRY_EMAIL,
  SEND_VISA_EXPIRY_SMS,
  APP_VERSION,
  CUSTOM_SMS,
  CUSTOM_EMAIL,
  LEAD_TABLE_ACTION,
  STUDENT_TABLE_ACTION,
  VISA_APPLICANT_TABLE_ACTION,
  INSURANCE_APPLICANT_TABLE_ACTION,
  EMAIL_ACTION,
  SMS_ACTION,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_WITH_MONTH,
  SKILL_ASSESSMENT_TABLE_ACTION,
  EMAIL_TEMPLATE,
  SMS_TEMPLATE,
  DEFAULT_TIME_ZONE,
  CURRENT_TIME_ZONE,
  SUPER_ADMIN_ROLES,
  monthColor,
  DEFAULT_DATE_FORMAT_WITH_TIME,
  DEFAULT_DATE_TIME_FORMAT,
  COUNTRIES,
  BRANCH_LOCATION,
  COMPANY_NAME,
  COMPANY_URL,
  BRANCH_LOCATION_LINK,
  BRANCH_LOCATION_MAP,
  BRANCH_EMAIL,
  BRANCH_PHONE,
  BRANCH_MOBILE,
  BRANCH_FACEBOOK,
  CLIENT_SOURCE_WEBSITE,
  CLIENT_SOURCE_OFFICE_CHECKIN,
  BRANCH_NAME,
  userAccess,
  BranchURLs,
  COUNTRY_CODE_AU,
  COUNTRY_CODE_NP,
  VISA_LIST,
  OCCUPATION_LIST,
  LOCATION_LIST,
  visaTypeOption,
  occupationOption,
  occupationCodeOption,
  locationOption,
  nominationStatus,
  useGetOccupationOptions,
  useGetVisaTypeOptions,
  generalUserFilter,
  TINY_API_KEY,
  TINY_INIT,
  TENANT,
  FILE_UPLOAD_URL,
  LOGO_HORIZONTAL,
  LOGO_VERTICAL,
  CLOCKIN_AUTH,
};
