import React, { useEffect, useState } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import BackArrow from "@material-ui/icons/KeyboardBackspace";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";

import avatar from "assets/img/placeholder.jpg";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";

import { useDispatch, useSelector } from "react-redux";
import withTimer from "components/Timer/withTimer";
import Button from "../../../components/CustomButtons/Button";
import CardHeader from "../../../components/Card/CardHeader";
import { NavLink, Redirect } from "react-router-dom";
import {
  CLEAR_ERROR,
  CLOCK_GET_REQUESTED,
  LEAVE_GET_USER_REQUESTED,
} from "../../../redux/actions";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import { DEFAULT_DATE_FORMAT } from "../../../scripts/const";
import Danger from "../../../components/Typography/Danger";
import moment from "moment";
import { isValidDate } from "../../../scripts/util";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import PendingList from "./PendingList";
import ReactGA from "react-ga4";
import Slide from "@material-ui/core/Slide";
import EmployeeContainer from "./EmployeeContainer";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Employee = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const id = props.match?.params?.id;

  const [user, setUser] = useState();
  const [isRowUpdate, setIsRowUpdate] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const [openModal, setOpenModal] = useState(false);
  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const [isValidUser, setIsValidUser] = useState(true);
  const allUsers = useSelector((state) => state.user.users);
  const clockInData = useSelector((state) => state.clockIn.clockInData);
  const leaveUserData = useSelector((state) => state.leave.leaveUserData);
  const [data, setData] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [fromDateState, setFromDateState] = React.useState("");
  const [toDateState, setToDateState] = React.useState("");
  const [isFilterSet, setIsFilterSet] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({
    fromDate: moment()
      .subtract(0, "weeks")
      .startOf("isoWeek")
      .format(DEFAULT_DATE_FORMAT),
    toDate: moment()
      .subtract(0, "weeks")
      .endOf("isoWeek")
      .format(DEFAULT_DATE_FORMAT),
  });

  useEffect(() => {
    if (openModal === false && props.resetForm) {
      props.resetForm();
      setTimeout(() => {
        dispatch({ type: CLEAR_ERROR });
      }, 500);
    }
  }, [openModal]);

  useEffect(() => {
    const payload = { userId: id || props.userId };
    dispatch({ type: LEAVE_GET_USER_REQUESTED, payload });
  }, []);

  function clear() {
    setToDateState("");
    setFromDateState("");
    setDateRange({
      fromDate: "",
      toDate: "",
    });
    setFilterData(data);
  }

  const getTotalHoursWorked = () => {
    return (
      filterData.reduce((sum, { totalHours }) => {
        if (totalHours) {
          return sum + parseFloat(totalHours);
        } else {
          return sum;
        }
      }, 0) + " hr"
    );
  };

  function filterPastWeek() {
    setDateRange({
      fromDate: moment()
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .format(DEFAULT_DATE_FORMAT),
      toDate: moment()
        .subtract(1, "weeks")
        .endOf("isoWeek")
        .format(DEFAULT_DATE_FORMAT),
    });
    setIsFilterSet(true);
  }

  function filterCurrentWeek() {
    setDateRange({
      fromDate: moment()
        .subtract(0, "weeks")
        .startOf("isoWeek")
        .format(DEFAULT_DATE_FORMAT),
      toDate: moment()
        .subtract(0, "weeks")
        .endOf("isoWeek")
        .format(DEFAULT_DATE_FORMAT),
    });
    setIsFilterSet(true);
  }

  function filterToPastTwoWeeks() {
    setDateRange({
      fromDate: moment()
        .subtract(2, "weeks")
        .startOf("isoWeek")
        .format(DEFAULT_DATE_FORMAT),
      toDate: moment()
        .subtract(1, "weeks")
        .endOf("isoWeek")
        .format(DEFAULT_DATE_FORMAT),
    });
    setIsFilterSet(true);
  }

  function filter() {
    let allValid = true;
    setToDateState("");
    setFromDateState("");

    if (dateRange.fromDate === "") {
      setFromDateState("error");
      allValid = false;
    }

    if (dateRange.toDate === "") {
      setToDateState("error");
      return;
    }

    if (!isValidDate(dateRange.fromDate)) {
      setFromDateState("error");
      allValid = false;
    }

    if (!isValidDate(dateRange.toDate)) {
      setToDateState("error");
      allValid = false;
    }

    if (allValid) {
      const fromDate = moment(dateRange.fromDate, DEFAULT_DATE_FORMAT);
      const toDate = moment(dateRange.toDate, DEFAULT_DATE_FORMAT);
      const dataCopy = [...data];
      const filterData = dataCopy.filter((e) => {
        return (
          moment(e.clockInTime).isSameOrAfter(fromDate, "day") &&
          moment(e.clockInTime).isSameOrBefore(toDate, "day")
        );
      });
      setFilterData(filterData);
    }
  }

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setDateRange({
        ...dateRange,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setDateRange({
        ...dateRange,
        [name]: date,
      });
    }
  };
  useEffect(() => {
    if (allUsers && id && allUsers.length > 0) {
      const user = allUsers.find((u) => u.id == id);
      if (!user) {
        setIsValidUser(false);
      } else {
        dispatch({ type: CLOCK_GET_REQUESTED, payload: { id } });
        setUser(user);
      }
    }
  }, [allUsers, id]);

  useEffect(() => {
    if (allUsers && props.userId && allUsers.length > 0) {
      const user = allUsers.find((u) => u.id == props.userId);
      if (!user) {
        setIsValidUser(false);
      } else {
        dispatch({ type: CLOCK_GET_REQUESTED, payload: { id: props.userId } });
        setUser(user);
      }
    }
  }, [allUsers, props.userId]);

  useEffect(() => {
    if (clockInData && allUsers.length > 0) {
      setData(clockInData);
    }
  }, [clockInData]);

  useEffect(() => {
    if (data && data.length > 0) {
      filter();
    }
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0) {
      filter();
      setIsFilterSet(false);
    }
  }, [isFilterSet]);

  if (!isValidUser) {
    return <Redirect to="/admin/employees" />;
  }

  function getLeaveDetail(leaveUserData) {
    if (leaveUserData && leaveUserData.length > 0) {
      const { fromDate, toDate } = dateRange;
      const fromDateM = moment(fromDate, DEFAULT_DATE_FORMAT);
      const toDateM = moment(toDate, DEFAULT_DATE_FORMAT);
      const filteredLeave = leaveUserData.filter((leave) => {
        const startDateM = moment(leave.startDate, DEFAULT_DATE_FORMAT);
        const endDateM = moment(leave.endDate, DEFAULT_DATE_FORMAT);
        return (
          startDateM.isBetween(fromDateM, toDateM, null, "[]") ||
          endDateM.isBetween(fromDateM, toDateM, null, "[]") ||
          fromDateM.isBetween(startDateM, endDateM, null, "[]") ||
          toDateM.isBetween(startDateM, endDateM, null, "[]")
        );
      });

      const totalHours = filteredLeave
        .filter((leave) => leave.status === "approved")
        .map(({ startDate, endDate, hoursPerDay }) => {
          const endDateM = moment(endDate, DEFAULT_DATE_FORMAT);
          const startDateM = moment(startDate, DEFAULT_DATE_FORMAT);
          const dateDiff = endDateM.diff(startDateM, "days") + 1;
          return Math.round(dateDiff * hoursPerDay * 100) / 100;
        })
        .reduce((a, b) => a + b, 0);
      return (
        <>
          <h3>
            Approved Leave:{" "}
            <span style={{ color: "#00bcd4" }}>{totalHours}</span> hrs.{" "}
            {filteredLeave.length > 0 && (
              <Button
                color={"success"}
                size={"sm"}
                // link={true}
                onClick={() => {
                  ReactGA.event({
                    category: "Leave",
                    action: "View Leave Detail",
                  });
                  setOpenLeaveModal(true);
                }}
              >
                View Details
              </Button>
            )}
          </h3>
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper: classes.modal + " " + classes.modalMediumXL,
            }}
            open={openLeaveModal}
            onClose={() => setOpenLeaveModal(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => setOpenLeaveModal(false)}
              >
                <Close className={classes.modalClose} />
              </Button>
              <h3 className={classes.modalTitle}>{"Leave Detail"}</h3>
            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classes.modalBody}
            >
              {<PendingList leaveData={filteredLeave} viewOnly />}
            </DialogContent>
          </Dialog>
        </>
      );
    }
    return null;
  }

  const renderFilter = () => {
    return (
      <GridContainer>
        <GridItem style={{ flex: 1 }}>
          {getLeaveDetail(leaveUserData)}{" "}
        </GridItem>
        <GridItem>
          <Button
            color="info"
            size={"sm"}
            onClick={(e) => {
              e.preventDefault();
              filterToPastTwoWeeks();
            }}
          >
            Past Two Weeks
          </Button>
          <Button
            color="info"
            size={"sm"}
            onClick={(e) => {
              e.preventDefault();
              filterPastWeek();
            }}
          >
            Past Week
          </Button>
          <Button
            color="info"
            size={"sm"}
            onClick={(e) => {
              e.preventDefault();
              filterCurrentWeek();
            }}
          >
            Current
          </Button>
        </GridItem>
        <GridItem>
          <InputLabel className={classes.label}>From Date</InputLabel>
          <FormControl fullWidth>
            <Datetime
              closeOnSelect
              dateFormat={DEFAULT_DATE_FORMAT}
              timeFormat={false}
              value={dateRange.fromDate || ""}
              onChange={(moment) => handleDatePicker(moment, "fromDate")}
              inputProps={{
                name: "fromDate",
                id: "fromDate-employees",
                placeholder: DEFAULT_DATE_FORMAT,
                className: classes.info,
              }}
            />

            {fromDateState && (
              <GridItem>
                <Danger>Error</Danger>
              </GridItem>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <InputLabel className={classes.label}>To Date</InputLabel>
          <FormControl fullWidth>
            <Datetime
              closeOnSelect
              dateFormat={DEFAULT_DATE_FORMAT}
              timeFormat={false}
              value={dateRange.toDate || ""}
              onChange={(moment) => handleDatePicker(moment, "toDate")}
              inputProps={{
                name: "toDate",
                id: "toDate-employee",
                placeholder: DEFAULT_DATE_FORMAT,
                className: classes.info,
              }}
            />
            {toDateState && (
              <GridItem>
                <Danger>Error</Danger>
              </GridItem>
            )}
          </FormControl>
        </GridItem>
        <GridItem>
          <Button
            color="warning"
            size={"sm"}
            onClick={(e) => {
              e.preventDefault();
              filter();
            }}
          >
            Filter
          </Button>
          <Button
            color="warning"
            size={"sm"}
            onClick={(e) => {
              e.preventDefault();
              clear();
            }}
          >
            Clear
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  const columns = [
    {
      Header: "Date",
      accessor: "date",
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "#abd3f1",
      },
    },
    {
      Header: "Day",
      accessor: "clockInTime",
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "#abd3f1",
      },
      Cell: (props) => {
        if (props && props.value) {
          return moment(props.value).local().format("dddd");
        } else {
          return "";
        }
      },
    },
    {
      Header: "ClockIn",
      accessor: "clockInTime",
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "#abd3f1",
      },
      Cell: (props) => {
        if (props && props.value) {
          return moment(props.value).local().format("hh:mm A");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Break Start",
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "#ABEBC6",
      },
      accessor: "breakStartTime",
      Cell: (props) => {
        if (props && props.value) {
          return moment(props.value).local().format("hh:mm A");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Break End",
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "#ABEBC6",
      },
      accessor: "breakEndTime",
      Cell: (props) => {
        if (props && props.value) {
          return moment(props.value).local().format("hh:mm A");
        } else {
          return "";
        }
      },
    },
    {
      Header: "ClockOut",
      accessor: "clockOutTime",
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "#abd3f1",
      },
      Cell: (props) => {
        if (props && props.value) {
          return moment(props.value).local().format("hh:mm A");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Total Hours",
      accessor: "totalHours",
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "#88e9b1",
      },
      Cell: (props) => {
        if (props && props.value) {
          return props.value;
        } else {
          return "";
        }
      },
    },
  ];

  function getTrProps(state, rowInfo) {
    if (props.noAccess) {
      return {};
    }
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          e.preventDefault();
          setCurrentRow(rowInfo.row._original);
          setIsRowUpdate(true);
          ReactGA.event({
            category: "ClockIn",
            action: "Update ClockIn",
          });
        },
      };
    } else {
      return {};
    }
  }
  const renderTable = () => {
    return (
      <ReactTable
        pageSizeOptions={[10, 20, 50, 100]}
        data={filterData}
        filterable={false}
        shortable={false}
        resizable={true}
        columns={columns}
        getTrProps={getTrProps}
        defaultPageSize={10}
        showPaginationBottom={true}
        className="-striped -highlight"
      />
    );
  };

  return (
    <>
      {user ? (
        <>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              lg={3}
              md={3}
              style={{ marginLeft: "15px" }}
            >
              <Card profile className={classes.employeeDetail}>
                <CardAvatar profile>
                  <img src={user.profileUrl || avatar} alt="..." />
                </CardAvatar>
                <CardBody profile>
                  <h4
                    className={classes.cardTitle}
                  >{`${user.firstName} ${user.lastName}`}</h4>
                  <p className={classes.description}>{user.email}</p>
                  <p className={classes.description}>{user.phone}</p>
                </CardBody>
              </Card>
            </GridItem>
            <>
              <GridItem
                xs={6}
                sm={6}
                md={8}
                style={{ display: "flex", flex: 1 }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div style={{ alignSelf: "flex-end", marginBottom: "25px" }}>
                    {leaveUserData && (
                      <PendingList
                        leaveData={leaveUserData}
                        userId={id || props.userId}
                      />
                    )}
                    {props.timeSheet && (
                      <Button
                        color="info"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenModal(true);
                        }}
                      >
                        Request Leave
                      </Button>
                    )}
                  </div>
                </div>
              </GridItem>
              <Dialog
                classes={{
                  root: classes.center + " " + classes.modalRoot,
                  paper: classes.modal + " " + classes.modalMedium,
                }}
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
              >
                <DialogTitle
                  id="classic-modal-slide-title"
                  disableTypography
                  className={classes.modalHeader}
                >
                  <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => setOpenModal(false)}
                  >
                    <Close className={classes.modalClose} />
                  </Button>
                  <h3 className={classes.modalTitle}>{"Request Leave"}</h3>
                </DialogTitle>
                <DialogContent
                  id="classic-modal-slide-description"
                  className={classes.modalBody}
                >
                  {props.leaveRequestContainer}
                </DialogContent>
              </Dialog>
            </>
          </GridContainer>

          {!props.timeSheet && (
            <GridContainer
              justifyContent={"flex-start"}
              className={classes.backIcon}
            >
              <GridItem xs={3} sm={3} md={3}>
                <NavLink to={"/admin/employees"}>
                  <BackArrow />
                </NavLink>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 style={{ textAlign: "center", fontWeight: "bold" }}>
                    Time Sheet
                  </h4>
                  <p
                    style={{ textAlign: "center", fontWeight: "500" }}
                  >{`${dateRange.fromDate} - ${dateRange.toDate}`}</p>
                  <p
                    style={{
                      color: "#cbc215",
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontFamily: "helvetica",
                    }}
                  >{`Total Hours Worked: ${getTotalHoursWorked()}`}</p>
                </CardHeader>
                {clockInData && (
                  <CardBody>
                    {renderFilter()}
                    {renderTable()}
                  </CardBody>
                )}
              </Card>
            </GridItem>
            <Dialog
              classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal + " " + classes.modalMedium,
              }}
              open={isRowUpdate}
              TransitionComponent={Transition}
              onClose={() => setIsRowUpdate(false)}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => setIsRowUpdate(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h3 className={classes.modalTitle}>{"Update Sheet"}</h3>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                <EmployeeContainer
                  open={isRowUpdate}
                  setOpen={setIsRowUpdate}
                  editData={currentRow}
                  isEdit={true}
                />
              </DialogContent>
            </Dialog>
          </GridContainer>
        </>
      ) : null}
    </>
  );
};

export default withTimer(Employee);
