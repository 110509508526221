import momentTZ from "moment-timezone";
import moment from "moment";
import { isString } from "lodash";
import gPhoneNumber from "google-libphonenumber";

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_ZONE,
  COUNTRIES,
  COUNTRY_CODE_AU,
  COUNTRY_CODE_NP,
  OCCUPATION_LIST,
} from "scripts/const";
import * as Yup from "yup";

import React from "react";
import {
  LEAD_GET_ALL_REQUESTED,
  APPOINTMENT_GET_ALL_REQUESTED,
  CHECKIN_GET_ALL_REQUESTED,
  CLIENT_COUNTRY_GET_REQUESTED,
  COURSE_GET_ALL_REQUESTED,
  INSURANCE_APPLICANT_GET_ALL_REQUESTED,
  SKILL_ASSESSMENT_GET_ALL_REQUESTED,
  SOURCE_GET_ALL_REQUESTED,
  STUDENT_GET_ALL_REQUESTED,
  TODO_COUNT_GET_REQUESTED,
  UNIVERSITY_GET_ALL_REQUESTED,
  USER_GET_ALL_REQUESTED,
  VISA_APPLICANT_GET_ALL_REQUESTED,
  STUDENT_ANALYTICS_GET_REQUESTED,
  SKILL_ANALYTICS_GET_REQUESTED,
  VISA_ANALYTICS_GET_REQUESTED,
  LEAVE_GET_ALL_REQUESTED,
  IMMIGRATION_GET_ALL_REQUESTED,
  VISA_GET_ALL_REQUESTED,
  OCCUPATION_GET_ALL_REQUESTED,
  ANNOUNCEMENT_GET_ALL_REQUESTED,
} from "../redux/actions";

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();

export const clearSession = () => {
  localStorage.clear();
};

export const isAnyoneWaiting = (
  appointmentDetail,
  checkinData,
  waitTime = 60
) => {
  const activeChecking = checkinData.filter(
    (ch) =>
      ch.isActive &&
      moment.duration(moment().diff(ch.timerStart)).asMinutes() > waitTime
  );

  const todayAppointment = appointmentDetail.filter(
    (apt) => apt.leadId && moment(apt.start).isSame(moment(), "day")
  );

  const waiting = [];
  for (let i = 0; i < activeChecking.length; i++) {
    if (
      todayAppointment.find((apt) => (apt.leadId = activeChecking[i].leadId))
    ) {
      const {
        lead: { firstName, lastName },
        timerStart,
      } = activeChecking[i];
      waiting.push({
        firstName,
        lastName,
        waitTime: `${moment
          .duration(moment().diff(timerStart))
          .asMinutes()
          .toFixed(2)} min`,
      });
    }
  }
  return waiting;
};

export const hasRoleAccess = (name, userDetail) => {
  if (!name) return false;
  if (isString(name)) {
    return !!userDetail[name];
  }
  let hasAccess = false;
  if (Array.isArray(name)) {
    for (let i = 0; i < name.length; i++) {
      if (userDetail[name[i]]) {
        hasAccess = true;
        break;
      }
    }
  }
  return hasAccess;
};

export const initiateApiCalls = (dispatch, roleId) => {
  if (roleId && roleId == 4) {
    dispatch({ type: USER_GET_ALL_REQUESTED });
  } else {
    dispatch({ type: CHECKIN_GET_ALL_REQUESTED });
    dispatch({ type: USER_GET_ALL_REQUESTED });
    dispatch({ type: STUDENT_GET_ALL_REQUESTED });
    dispatch({ type: INSURANCE_APPLICANT_GET_ALL_REQUESTED });
    dispatch({ type: VISA_APPLICANT_GET_ALL_REQUESTED });
    dispatch({ type: VISA_GET_ALL_REQUESTED });
    dispatch({ type: OCCUPATION_GET_ALL_REQUESTED });
    dispatch({ type: ANNOUNCEMENT_GET_ALL_REQUESTED });
    dispatch({ type: COURSE_GET_ALL_REQUESTED });
    dispatch({ type: SOURCE_GET_ALL_REQUESTED });
    dispatch({ type: UNIVERSITY_GET_ALL_REQUESTED });
    dispatch({ type: SKILL_ASSESSMENT_GET_ALL_REQUESTED });
    dispatch({ type: TODO_COUNT_GET_REQUESTED });
    dispatch({ type: APPOINTMENT_GET_ALL_REQUESTED });
    dispatch({ type: CLIENT_COUNTRY_GET_REQUESTED });
    dispatch({ type: STUDENT_ANALYTICS_GET_REQUESTED });
    dispatch({ type: SKILL_ANALYTICS_GET_REQUESTED });
    dispatch({ type: VISA_ANALYTICS_GET_REQUESTED });
    dispatch({ type: LEAVE_GET_ALL_REQUESTED });
    dispatch({ type: IMMIGRATION_GET_ALL_REQUESTED });
    dispatch({ type: LEAD_GET_ALL_REQUESTED });
  }
};

const isValidWithCountry = (phone, countryCode, isStrict = false) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(phone, countryCode);
    if (!phoneUtil.isPossibleNumber(phoneNumber)) {
      return false;
    }
    if (isStrict) {
      return phoneUtil.isValidNumberForRegion(phoneNumber, countryCode);
    } else {
      return phoneUtil.isValidNumber(phoneNumber);
    }
  } catch (e) {
    return false;
  }
};

export const isValidPhone = (value) => {
  if (!value || value === "") {
    return false;
  }
  let countryCode = COUNTRY_CODE_AU;
  let valid = isValidWithCountry(value, countryCode);
  if (!valid) {
    countryCode = COUNTRY_CODE_NP;
    valid = isValidWithCountry(value, countryCode);
  }
  return !!valid;
};

export const createSelectDropDown = (onChange, filter, items, key) => {
  return (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: "100%" }}
      value={filter ? filter.value : "all"}
    >
      <option value={""}>{""}</option>
      {items &&
        items.map((m, i) => (
          <option key={i} value={m.id}>
            {m[key]}
          </option>
        ))}
    </select>
  );
};

export const createCustomCell = (props, items, key) => {
  if (props.value && items) {
    const item = items.find((e) => e.id == props.value);
    if (item) {
      return item[key];
    }
  }
  return props.value;
};

export const getSafe = (fn, defaultVal) => {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
};

export const convertDate = (date) => {
  if (date === "") {
    return date;
  }
  return momentTZ(date).tz(DEFAULT_TIME_ZONE).format("DD MMM, YYYY LT");
};

export const getUserName = (userId, users) => {
  if (!userId) {
    return "N/A";
  }
  if (users.length > 0 && userId) {
    const user = users.find((usr) => usr.id === userId);
    if (user) {
      return `${user.firstName} ${user.lastName}`;
    } else {
      return userId;
    }
  }
  return userId;
};

export const verifyEmail = (value) => {
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

export const trimText = (str, length) => {
  if (str.length < length) {
    return str;
  } else {
    return str.substring(0, length) + " ...";
  }
};

// Validates that the input string is a valid date formatted as "dd/mm/yyyy"
export const isValidDate = (dateString) => {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

// Validates that the input string is a valid date formatted as "dd/mm/yyyy"
export const isValidDateTime = (dateTime) => {
  const date = moment(dateTime, DEFAULT_DATE_FORMAT);
  return date.isValid();
};

export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== null && typeof row[id] === "string") {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  } else {
    return String(row[filter.id]) === filter.value;
  }
};

export const isDateWithInDays = (data, days) => {
  const today = moment();
  const yourDate = moment(data);
  const duration = yourDate.diff(today, "days");
  if (duration < days) {
    return true;
  }
  return false;
};

export const getPastYearMonth = (months = 12) => {
  let dates = [];
  for (let i = 0; i < months; i += 1) {
    let date = moment().subtract(i, "months");
    let month = date.format("MM");
    let monthName = date.format("MMM");
    let year = date.format("YYYY");
    let yearName = date.format("YYYY");
    dates.push({ month, year, monthName, yearName });
  }
  return dates.reverse();
};

export const getPastYearWeek = () => {
  let dates = [];
  const today = moment();

  const currentYear = today.year();
  const currentWeek = today.isoWeek();

  const yearStart = moment().startOf("year");
  let currentWeekDate = yearStart.clone().startOf("isoWeek");

  while (
    currentWeekDate.year() === currentYear &&
    currentWeekDate.isoWeek() <= currentWeek
  ) {
    const month = currentWeekDate.format("MM");
    const monthName = currentWeekDate.format("MMM");
    const year = currentWeekDate.format("YYYY");
    const yearName = currentWeekDate.format("YYYY");
    const week = currentWeekDate.isoWeek();

    dates.push({ month, year, monthName, yearName, week });
    currentWeekDate.add(1, "week");
  }

  return dates;
};

export const getPastYear = (year = 5) => {
  const currentYear = new Date().getFullYear();
  const yearsAgo = currentYear - (year - 1);

  const yearsInRange = Array.from(
    { length: year },
    (_, index) => yearsAgo + index
  );
  return yearsInRange;
};

//formatter for week
export const weekformatData = (data) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentWeek = getWeekNumber(today);

  // Initialize an object to store counts for each status for each week
  const weeklyStatusCounts = {
    assigned: Array(currentWeek).fill(0),
    converted: Array(currentWeek).fill(0),
    not_converted: Array(currentWeek).fill(0),
  };

  // Calculate the starting week to consider only the weeks of the current year
  let startWeek = 1; // Start from the first week of the year

  data.forEach((item) => {
    const { year, week, count, status } = item;
    // Check if the item falls within the weeks of the current year up to the current week
    if (year === currentYear && week >= startWeek && week <= currentWeek) {
      const weekIndex = week - startWeek; // Calculate the index relative to the starting week
      switch (status) {
        case "assigned":
          weeklyStatusCounts.assigned[weekIndex] += count;
          break;
        case "converted":
          weeklyStatusCounts.converted[weekIndex] += count;
          break;
        case "not_converted":
          weeklyStatusCounts.not_converted[weekIndex] += count;
          break;
        default:
          break;
      }
    }
  });

  return weeklyStatusCounts;
};

// Function to get the ISO week number of a date
const getWeekNumber = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  const yearStart = new Date(d.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return weekNumber;
};

export const monthformatData = (data) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index

  // Initialize an object to store counts for each status for each month
  const monthlyStatusCounts = {
    assigned: Array(12).fill(0),
    converted: Array(12).fill(0),
    not_converted: Array(12).fill(0),
  };

  // Calculate the starting month and year to consider the past 12 months
  let startMonth = currentMonth - 11; // Start from 12 months ago
  let startYear = currentYear;
  if (startMonth <= 0) {
    startMonth += 12;
    startYear -= 1;
  }

  data.forEach((item) => {
    const { year, month, count, status } = item;
    // Check if the item falls within the past 12 months
    if (
      (year === startYear && month >= startMonth) ||
      (year === startYear + 1 && month <= currentMonth)
    ) {
      const monthIndex = ((year - startYear) * 12 + month - startMonth) % 12; // Calculate the index relative to the starting month
      switch (status) {
        case "assigned":
          monthlyStatusCounts.assigned[monthIndex] += count;
          break;
        case "converted":
          monthlyStatusCounts.converted[monthIndex] += count;
          break;
        case "not_converted":
          monthlyStatusCounts.not_converted[monthIndex] += count;
          break;
        default:
          break;
      }
    }
  });

  return monthlyStatusCounts;
};

export const yearlyformatData = (data, year = 7) => {
  const monthlyStatusCounts = {
    assigned: Array(year).fill(0),
    converted: Array(year).fill(0),
    not_converted: Array(year).fill(0),
  };
  data.forEach((item) => {
    // Check the status of the item and increment the corresponding count for the month
    const yearIndex = item.year - (new Date().getFullYear() - year + 1);

    switch (item.status) {
      case "assigned":
        monthlyStatusCounts.assigned[yearIndex] += item.count; // Zero-based indexing
        break;
      case "converted":
        monthlyStatusCounts.converted[yearIndex] += item.count; // Zero-based indexing
        break;
      case "not_converted":
        monthlyStatusCounts.not_converted[yearIndex] += item.count; // Zero-based indexing
        break;
      default:
        break;
    }
  });

  return monthlyStatusCounts;
};

export const getChunk = (data, months = 12) => {
  const chunkBucket = getPastYearMonth(months);
  const dataFromPast12Months = data.filter((e) =>
    isDateWithInDays(e.createdAt, 366)
  );
  const chunkData = [];
  for (let i = 0; i < chunkBucket.length; i += 1) {
    let tempDataCount = dataFromPast12Months.filter((e) => {
      return (
        moment(e.createdAt).format("MM") === chunkBucket[i].month &&
        moment(e.createdAt).format("YYYY") === chunkBucket[i].year
      );
    }).length;
    chunkData.push({
      month: `${chunkBucket[i].monthName} - ${chunkBucket[i].yearName}`,
      count: tempDataCount,
    });
  }
  return chunkData;
};

export const getRoleMapping = (roleId) => {
  let role = "";
  switch (roleId) {
    case 1:
      role = "Super Admin";
      break;
    case 2:
      role = "Manager";
      break;
    case 3:
      role = "General User";
      break;
    case 4:
      role = "Accountant";
      break;
    case 5:
      role = "Lead Generator";
      break;
    default:
      role = "";
  }
  return role;
};

export const getInsuranceTypeMapping = () => {
  return [
    {
      id: 1,
      name: "Visitor Visa (600)",
    },
    {
      id: 2,
      name: "Temp Graduate Visa (485)",
    },
    {
      id: 3,
      name: "Student Visa (500)",
    },
    {
      id: 4,
      name: "Emp Sponsored (482)",
    },
    {
      id: 5,
      name: "Other",
    },
  ];
};

export const getInsuranceProviderMapping = () => {
  return [
    {
      id: 1,
      name: "Bupa",
    },
    {
      id: 2,
      name: "Allianz",
    },
    {
      id: 3,
      name: "IMan/NIB",
    },
    {
      id: 4,
      name: "AHM",
    },
    {
      id: 5,
      name: "Medibank",
    },
    {
      id: 6,
      name: "Other",
    },
  ];
};

export const isValidEventInputs = (event) => {
  const errors = [];
  if (!event.userId || event.userId === "") {
    errors.push("Owner can not be empty");
  }
  if (!event.title || event.title === "") {
    errors.push("Title can not be empty");
  }
  if (!event.start || event.start === "") {
    errors.push("Start Date can not be empty");
  }
  if (event.start) {
    if (!isValidDateTime(event.start)) {
      errors.push("Start Date is Invalid");
    }
  }

  if (!event.end || event.end === "") {
    errors.push("End Date can not be empty");
  }
  if (event.end) {
    if (!isValidDateTime(event.end)) {
      errors.push("End Date is Invalid");
    }
  }
  return errors;
};

export const isValidUserProfileInputs = (user, checkForPass) => {
  const errors = [];
  if (checkForPass) {
    if (!user.password || user.email === "") {
      errors.push("Password can not be empty");
    }
  }
  if (!user.email || user.email === "") {
    errors.push("Email can not be empty");
  }
  if (user.email) {
    if (!verifyEmail(user.email)) {
      errors.push("Please Input Valid Email");
    }
  }
  if (!user.firstName || user.firstName === "") {
    errors.push("First Name can not be empty");
  }
  if (!user.lastName || user.lastName === "") {
    errors.push("Last Name can not be empty");
  }
  if (!user.phone || user.phone === "") {
    errors.push("Phone can not be empty");
  }
  if (!user.roleId || user.roleId === "") {
    errors.push("Role can not be empty");
  }
  if (user.password && user.password !== "" && user.password.length < 6) {
    errors.push("Password should be more than 6 char long");
  }

  if (user.phone && user.phone !== "" && !isValidPhone(user.phone)) {
    errors.push("Phone number is invalid");
  }
  return errors;
};

export const checkInSchema = () => {
  return Yup.object({
    firstName: Yup.string().required("Required!"),
    lastName: Yup.string().required("Required!"),
    phone: Yup.string().required("Required!"),
    email: Yup.string().email("Invalid email").required("Required!"),
    education: Yup.boolean(),
    visa: Yup.boolean(),
    skillAssessment: Yup.boolean(),
    healthInsurance: Yup.boolean(),
    other: Yup.boolean(),
  });
};

export const reportSchema = () => {
  return Yup.object({
    year: Yup.string()
      .required("Year is Required")
      .test("len", "Invalid Year", (val) => val.length === 4),
  });
};

// export const isValidPhone = (number) => {
//   const phoneSchema = Yup.string().phone(COUNTRY_CODE_AU);
//   let isValid = phoneSchema.isValidSync(number);
//   console.log("IsValid 1", isValid);
//   if (isValid === true) {
//     return true;
//   } else {
//     const phoneSchema = Yup.string().phone(COUNTRY_CODE_NP);
//     isValid = phoneSchema.isValidSync(number);
//     console.log("IsValid 2", isValid);
//
//     return isValid;
//   }
// };

export const isValidLeadInputs = (user) => {
  const errors = [];
  if (!user.email || user.email === "") {
    errors.push("Email can not be empty");
  }
  if (user.email) {
    if (!verifyEmail(user.email)) {
      errors.push("Please Input Valid Email");
    }
  }
  if (!user.firstName || user.firstName === "") {
    errors.push("First Name can not be empty");
  }
  if (!user.lastName || user.lastName === "") {
    errors.push("Last Name can not be empty");
  }
  if (!user.phone || user.phone === "") {
    errors.push("Phone can not be empty");
  }

  if (!user.status || user.status === "") {
    errors.push("Status can not be empty");
  }
  if (!user.serviceType || user.serviceType === "") {
    errors.push("Service Type can not be empty");
  }
  if (user.phone && user.phone !== "" && !isValidPhone(user.phone)) {
    errors.push("Phone number is invalid");
  }
  if (user.status && user.status === "Follow Up") {
    if (
      !user.followUpDate ||
      user.followUpDate.trim() === "" ||
      user.followUpDate === null
    ) {
      errors.push("Follow Up Date is required");
    } else {
      if (!isValidDate(user.followUpDate)) {
        errors.push("Follow Up Date is Invalid");
      }
    }
  }

  if (user.status && user.status !== "Follow Up") {
    user.followUpDate = null;
  }

  return errors;
};

export const isNumeric = (str) => {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
};
export const isValidStudentInputs = (user) => {
  const errors = [];
  if (!user.email || user.email === "") {
    errors.push("Email can not be empty");
  }
  if (user.email) {
    if (!verifyEmail(user.email)) {
      errors.push("Please Input Valid Email");
    }
  }
  if (!user.firstName || user.firstName === "") {
    errors.push("First Name can not be empty");
  }
  if (!user.lastName || user.lastName === "") {
    errors.push("Last Name can not be empty");
  }
  if (!user.phone || user.phone === "") {
    errors.push("Phone can not be empty");
  }

  if (!user.status || user.status === "") {
    errors.push("Status can not be empty");
  }

  if (user.phone && user.phone !== "" && !isValidPhone(user.phone)) {
    errors.push("Phone number is invalid");
  }

  if (
    user.status &&
    [
      "Unconditional Offer",
      "Follow Up",
      "Coe Received",
      "Canceled",
      "Refused",
    ].includes(user.status)
  ) {
    if (!user.statusDate || user.statusDate === "") {
      errors.push(`${user.status} Date can not be empty`);
    } else {
      if (!isValidDate(user.statusDate)) {
        errors.push(`${user.status} Date is Invalid`);
      }
    }
  } else {
    user.statusDate = null;
  }

  if (user.startDate && user.startDate !== "") {
    if (!isValidDate(user.startDate)) {
      errors.push("Start Date is Invalid");
    }
  }

  if (user.endDate && user.endDate !== "") {
    if (!isValidDate(user.endDate)) {
      errors.push("End Date is Invalid");
    }
  }

  if (user.feeDueDate && user.feeDueDate !== "") {
    if (!isValidDate(user.feeDueDate)) {
      errors.push("Fee Due Date is Invalid");
    }
  }
  return errors;
};

export const isValidVisaApplicantInputs = (user) => {
  const errors = [];
  if (!user.email || user.email === "") {
    errors.push("Email can not be empty");
  }
  if (user.email) {
    if (!verifyEmail(user.email)) {
      errors.push("Please Input Valid Email");
    }
  }
  if (!user.firstName || user.firstName === "") {
    errors.push("First Name can not be empty");
  }
  if (!user.lastName || user.lastName === "") {
    errors.push("Last Name can not be empty");
  }

  if (user.phone && user.phone !== "" && !isValidPhone(user.phone)) {
    errors.push("Phone number is invalid");
  }

  if (!user.status || user.status === "") {
    errors.push("Status can not be empty");
  }

  if (
    user.status &&
    [
      "Nomination Lodged",
      "Nomination Approved",
      "Nomination Refused",
      "Visa Applied",
      "Info Requested",
      "Visa Granted",
      "Visa Rejected",
      "Withdrawn",
    ].includes(user.status)
  ) {
    if (!user.statusDate || user.statusDate === "") {
      errors.push(`${user.status} Date can not be empty`);
    } else {
      if (!isValidDate(user.statusDate)) {
        errors.push(`${user.status} Date is Invalid`);
      }
    }
  } else {
    user.statusDate = null;
  }

  if (user.visaExpiry && user.visaExpiry !== "") {
    if (!isValidDate(user.visaExpiry)) {
      errors.push("Visa Expiry Date is Invalid");
    }
  }

  if (user.dueDate && user.dueDate !== "") {
    if (!isValidDate(user.dueDate)) {
      errors.push("Due Date is Invalid");
    }
  }

  if (user.requestedDate && user.requestedDate !== "") {
    if (!isValidDate(user.requestedDate)) {
      errors.push("Requested Date is Invalid");
    }
  }
  return errors;
};

export const isValidSkillAssessmentInputs = (user) => {
  const errors = [];
  if (!user.email || user.email === "") {
    errors.push("Email can not be empty");
  }
  if (user.email) {
    if (!verifyEmail(user.email)) {
      errors.push("Please Input Valid Email");
    }
  }
  if (!user.firstName || user.firstName === "") {
    errors.push("First Name can not be empty");
  }
  if (!user.lastName || user.lastName === "") {
    errors.push("Last Name can not be empty");
  }

  if (user.phone && user.phone !== "" && !isValidPhone(user.phone)) {
    errors.push("Phone number is invalid");
  }
  if (!user.status || user.status === "") {
    errors.push("Status can not be empty");
  }

  if (
    user.status &&
    ["Applied", "Info Requested", "Approved", "Rejected"].includes(user.status)
  ) {
    if (!user.statusDate || user.statusDate === "") {
      errors.push(`${user.status} Date can not be empty`);
    } else {
      if (!isValidDate(user.statusDate)) {
        errors.push(`${user.status} Date is Invalid`);
      }
    }
  } else {
    user.statusDate = null;
  }

  if (user.skillAssessmentBody && user.skillAssessmentBody === "Other") {
    if (
      !user.otherSkillAssessmentBody ||
      user.otherSkillAssessmentBody.trim() === ""
    ) {
      errors.push("Other Body is required");
    }
  }
  if (user.skillAssessmentBody && user.skillAssessmentBody !== "Other") {
    user.otherSkillAssessmentBody = null;
  }

  if (user.visaExpiry && user.visaExpiry !== "") {
    if (!isValidDate(user.visaExpiry)) {
      errors.push("Visa Expiry Date is Invalid");
    }
  }
  return errors;
};

export const isValidInsuranceApplicantInputs = (user) => {
  const errors = [];
  if (!user.email || user.email === "") {
    errors.push("Email can not be empty");
  }
  if (user.email) {
    if (!verifyEmail(user.email)) {
      errors.push("Please Input Valid Email");
    }
  }
  if (!user.firstName || user.firstName === "") {
    errors.push("First Name can not be empty");
  }
  if (!user.lastName || user.lastName === "") {
    errors.push("Last Name can not be empty");
  }
  if (!user.phone || user.phone === "") {
    errors.push("Phone can not be empty");
  }
  if (!user.status || user.status === "") {
    errors.push("Status can not be empty");
  }

  if (user.phone && user.phone !== "" && !isValidPhone(user.phone)) {
    errors.push("Phone number is invalid");
  }
  if (user.startDate && user.startDate !== "") {
    if (!isValidDate(user.startDate)) {
      errors.push("Insurance Start Date is Invalid");
    }
  }

  if (user.endDate && user.endDate !== "") {
    if (!isValidDate(user.endDate)) {
      errors.push("Insurance End Date is Invalid");
    }
  }
  return errors;
};

const defaultColumn = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
];

export const getColumns = (name) => {
  if (name === "Student") {
    return [
      ...defaultColumn,
      {
        Header: "DOB",
        accessor: "dob",
      },
      {
        Header: "Fee Due",
        accessor: "feeDueDate",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "AssignedTo",
        accessor: "userId",
      },
    ];
  } else if (name === "agreement") {
    return [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "University",
        accessor: "universityId",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },
      {
        Header: "Commission",
        accessor: "commission",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Note",
        accessor: "note",
      },
      {
        Header: "Document",
        accessor: "fileUrl",
      },
    ];
  } else if (name === "User") {
    return [
      ...defaultColumn,
      {
        Header: "Role",
        accessor: "roleId",
      },
      {
        Header: "Active",
        accessor: "isActive",
      },
    ];
  } else if (name === "Lead") {
    return [
      ...defaultColumn,
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Occupation",
        accessor: "occupation",
      },
      {
        Header: "Qualification",
        accessor: "qualification",
      },
      {
        Header: "Note",
        accessor: "note",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "AssignedTo",
        accessor: "userId",
      },
    ];
  } else if (name === "Visa") {
    return [
      ...defaultColumn,
      {
        Header: "Current Visa",
        accessor: "currentVisa",
      },
      {
        Header: "Proposed Visa",
        accessor: "proposedVisa",
      },
      {
        Header: "Expiry Date",
        accessor: "visaExpiry",
      },
      {
        headerStyle: { textAlign: "left" },
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "AssignedTo",
        accessor: "userId",
      },
    ];
  } else if (name === "Skill") {
    return [
      ...defaultColumn,
      {
        Header: "Current Visa",
        accessor: "currentVisa",
      },
      {
        Header: "Expiry Date",
        accessor: "visaExpiry",
      },
      {
        Header: "Occupation",
        accessor: "occupation",
      },
      {
        Header: "Skill Assessment Body",
        accessor: "skillAssessmentBody",
      },
      {
        headerStyle: { textAlign: "left" },
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "AssignedTo",
        accessor: "userId",
      },
    ];
  } else if (name === "Insurance") {
    return [
      ...defaultColumn,
      {
        Header: "Visa",
        accessor: "currentVisa",
      },
      {
        Header: "Insurance",
        accessor: "currentInsurance",
        width: 200,
      },
      {
        Header: "Type",
        accessor: "insuranceTypeId",
      },
      {
        Header: "Provider",
        accessor: "insuranceProviderId",
      },
      {
        Header: "Expiry",
        accessor: "expiryDate",
      },
      {
        Header: "AssignedTo",
        accessor: "userId",
      },
    ];
  }
  return null;
};

export const getDropDown = (name) => {
  if (name === "student-status") {
    return [
      "Consultation",
      "Follow Up",
      "Collecting Docs",
      "Application Ready",
      "Application Submitted",
      "Info Requested",
      "Provisional Offer/GTE Survey",
      "Conditional Offer",
      "Unconditional Offer",
      "Coe Received",
      "Canceled",
      "Refused",
    ];
  } else if (name === "agreement-status") {
    return ["In Process", "In Effect", "Expired", "Cancelled", "Other"];
  } else if (name === "location") {
    return ["Onshore", "Offshore", "Other"];
  } else if (name === "visa-state") {
    return ["ACT", "NSW", "VIC", "QLD", "WA", "SA", "NT", "TAS", "NT", "Other"];
  } else if (name === "invoice-status") {
    return ["Paid", "Unpaid"];
  } else if (name === "agreement-type") {
    return ["Tafe", "University", "College"];
  } else if (name === "agreement-group") {
    return ["Higher Education", "VET Sector", "Both"];
  } else if (name === "leave-type") {
    return ["Sick", "Vacation", "Annual Leave", "Other"];
  } else if (name === "lead-service-type") {
    return [
      "Visa",
      "Education",
      "Skill Assessment",
      "Health Insurance",
      "Other",
    ];
  } else if (name === "lead-status") {
    return ["New", "Converted", "Not Converted", "Follow Up"];
  } else if (name === "lead-visa") {
    return [
      "Student (500) Visa",
      "PSW (485) Visa",
      "Temp Grad (485) Visa",
      "Visitor (600) Visa",
      "AAT/Federal",
      "TSS (482) Visa",
      "Temporary Work (457) Visa",
      "ENS (186) Visa",
      "RSMS (187) Visa",
      "GSM (489/491) Visa",
      "GSM (190) Visa",
      "Other",
    ];
  } else if (name === "visa-status") {
    return [
      "Discontinued",
      "Consulting",
      "Follow Up",
      "Collecting Docs",
      "EOI Lodged",
      "Nomination Lodged",
      "Nomination Approved",
      "Nomination Refused",
      "Info Requested",
      "Visa Applied",
      "Visa Granted",
      "Visa Rejected",
      "Withdrawn",
    ];
  } else if (name === "insurance-status") {
    return ["New", "Processing", "Completed", "Discontinued", "Refunded"];
  } else if (name === "visa-csa-status") {
    return ["Need to Send", "CSA/Inv Sent", "CSA Signed"];
  } else if (name === "skill-status") {
    return [
      "Consulting",
      "Follow Up",
      "Collecting Docs",
      "Applied",
      "Info Requested",
      "Approved",
      "Rejected",
      "Discontinued",
    ];
  } else if (name === "skill-csa-status") {
    return ["Need to Send", "CSA/Inv Sent", "CSA Signed"];
  } else if (name === "payment-status") {
    return ["Partial Paid", "Fully Paid", "Not Paid"];
  } else if (name === "skill-assessment-body-full") {
    return [
      "IPA ( Institute of Public Accountants)",
      "CPAA (Certified Practicing Accountants of Australia)",
      "ACS (Australian Computer Society)",
      "VETASSESS (Vocational Education and Training Assessment Services)",
      "TRA (Trade Recognition Australia)",
      "ANMAC (Australian Nursing & Midwifery Accreditation Council)",
      "Engineers Australia",
      "ACWA (Australian Community Workers Association)",
      "AASWA (Australian Association of Social Workers )",
      "AACA (Architects Accreditation Council of Australia",
      "AIMS (Australian Institute of Medical Scientists)",
      "APHARMC (Australian Pharmacy Council)",
      "Other",
    ];
  } else if (name === "insurance-category") {
    return ["Single", "Couple", "Family"];
  } else if (name === "education-level") {
    return ["Higher Education", "PY Provider", "VET Sector", ""];
  } else if (name === "true-false") {
    return [true, false];
  } else if (name === "country") {
    return COUNTRIES;
  }
};

const randomColors = [
  "#A3E4D7",
  "#7FB3D5",
  "#F4D03F",
  "#CB4335",
  "#E59866",
  "#AF7AC5",
  "#CB4335",
  "#95A5A6",
  "#2874A6",
  "#D68910",
  "#7FB3D5",
  "#922B21",
  "#0E6655",
  "#CA6F1E",
  "#0E6655",
  "#4A235A",
  "#0E6251",
  "#1B4F72",
  "#F7DC6F",
  "#2C3E50",
];
const randomColors2 = [
  "#F4D03F",
  "#CB4335",
  "#0E6655",
  "#4A235A",
  "#1B4F72",
  "#F7DC6F",
  "#0E6251",
  "#95A5A6",
  "#2874A6",
  "#A3E4D7",
  "#7FB3D5",
  "#CB4335",
  "#D68910",
  "#7FB3D5",
  "#922B21",
  "#E59866",
  "#AF7AC5",
  "#0E6655",
  "#CA6F1E",
  "#2C3E50",
];

const defaultForPie = {
  labels: [],
  datasets: [{ data: [] }],
};

export const getSourceDataForBarChart = (data, allSources) => {
  if (data && data.length > 0 && allSources && allSources.length > 0) {
    const yearMonth = getPastYearMonth(3);
    const finalData = [];
    let sources = [];
    for (let i = 0; i < yearMonth.length; i++) {
      const tempSec = {};
      tempSec.month = yearMonth[i].monthName;
      const tempData = data.filter((d) => {
        const tempD = moment(d.createdAt);
        return (
          tempD.format("MM") === yearMonth[i].month &&
          tempD.format("YYYY") === yearMonth[i].year &&
          d.sourceId !== null
        );
      });
      let sourceIds = tempData.map((m) => m.sourceId);
      sourceIds = [...new Set(sourceIds)];
      sourceIds.forEach((id) => {
        const sourceName = allSources.find((u) => u.id === id).name;
        tempSec[sourceName] = tempData.filter((d) => d.sourceId === id).length;
      });
      sources.push(...sourceIds);
      finalData.push(tempSec);
    }
    sources = [...new Set(sources)].map(
      (uId) => allSources.find((u) => uId === u.id).name
    );

    return {
      sourceChartData: finalData,
      options: { chartSources: sources, colors: randomColors2 },
    };
  } else {
    return {
      sourceChartData: [],
      options: { chartSources: [], colors: randomColors2 },
    };
  }
};

export const getInsightDataForBarChart = (
  studentInsight,
  visaInsight,
  skillInsight
) => {
  return [
    {
      name: "Student Offer",
      min: studentInsight?.min || "N/A",
      max: studentInsight?.max || "N/A",
      average: studentInsight?.average || "N/A",
    },
    {
      name: "Visa Lodged",
      min: visaInsight?.nomination?.min || "N/A",
      max: visaInsight?.nomination?.max || "N/A",
      average: visaInsight?.nomination?.average || "N/A",
    },
    {
      name: "Visa Applied",
      min: visaInsight?.visa?.min || "N/A",
      max: visaInsight?.visa?.max || "N/A",
      average: visaInsight?.visa?.average || "N/A",
    },
    {
      name: "Skill Applied",
      min: skillInsight?.min || "N/A",
      max: skillInsight?.max || "N/A",
      average: skillInsight?.average || "N/A",
    },
  ];
};

export const getDataForBarChart = (
  students,
  visaApplicants,
  skillAssessments,
  allUsers
) => {
  const yearMonth = getPastYearMonth(3);
  if (
    visaApplicants &&
    skillAssessments &&
    students &&
    students.length > 0 &&
    allUsers &&
    allUsers.length > 0
  ) {
    const finalData = [];
    let users = [];
    for (let i = 0; i < yearMonth.length; i++) {
      const tempSec = {};
      tempSec.month = yearMonth[i].monthName;
      const studentsToScan = students.filter((d) => {
        const tempD = moment(d.statusDate, DEFAULT_DATE_FORMAT);
        return (
          tempD.format("MM") === yearMonth[i].month &&
          tempD.format("YYYY") === yearMonth[i].year &&
          d.userId !== null &&
          d.status &&
          d.status === "Coe Received"
        );
      });
      const visaToScan = visaApplicants.filter((d) => {
        const tempD = moment(d.statusDate, DEFAULT_DATE_FORMAT);
        return (
          tempD.format("MM") === yearMonth[i].month &&
          tempD.format("YYYY") === yearMonth[i].year &&
          d.userId !== null &&
          d.status &&
          [
            "Visa Applied",
            "Info Requested",
            "Visa Granted",
            "Visa Rejected",
            "Withdrawn",
          ].includes(d.status)
        );
      });
      const skillToScan = skillAssessments.filter((d) => {
        const tempD = moment(d.statusDate, DEFAULT_DATE_FORMAT);
        return (
          tempD.format("MM") === yearMonth[i].month &&
          tempD.format("YYYY") === yearMonth[i].year &&
          d.userId !== null &&
          d.status &&
          ["Applied", "Info Requested", "Approved", "Rejected"].includes(
            d.status
          )
        );
      });
      const tempData = [...studentsToScan, ...visaToScan, ...skillToScan];
      let userIds = tempData.map((m) => m.userId);
      userIds = [...new Set(userIds)];
      userIds.forEach((id) => {
        const userName = allUsers.find((u) => u.id === id).firstName;
        tempSec[userName] = tempData.filter((d) => d.userId === id).length;
      });
      users.push(...userIds);
      finalData.push(tempSec);
    }
    users = [...new Set(users)].map(
      (uId) => allUsers.find((u) => uId === u.id).firstName
    );

    return {
      chartData: finalData,
      options: { chartUsers: users, colors: randomColors2 },
    };
  } else {
    return {
      chartData: [],
      options: { chartUsers: [], colors: randomColors2 },
    };
  }
};

export const getAssignedDataForBarChart = (data, allUsers) => {
  if (data && data.length > 0 && allUsers && allUsers.length > 0) {
    const yearMonth = getPastYearMonth(3);
    const finalData = [];
    let users = [];
    for (let i = 0; i < yearMonth.length; i++) {
      const tempSec = {};
      tempSec.month = yearMonth[i].monthName;
      const tempData = data.filter((d) => {
        const tempD = moment(d.createdAt);
        return (
          tempD.format("MM") === yearMonth[i].month &&
          tempD.format("YYYY") === yearMonth[i].year &&
          d.userId !== null
        );
      });
      let userIds = tempData.map((m) => m.userId);
      userIds = [...new Set(userIds)];
      userIds.forEach((id) => {
        const userName = allUsers.find((u) => u.id === id).firstName;
        tempSec[userName] = tempData.filter((d) => d.userId === id).length;
      });
      users.push(...userIds);
      finalData.push(tempSec);
    }
    users = [...new Set(users)].map(
      (uId) => allUsers.find((u) => uId === u.id).firstName
    );

    return {
      chartData: finalData,
      options: { chartUsers: users, colors: randomColors2 },
    };
  } else {
    return {
      chartData: [],
      options: { chartUsers: [], colors: randomColors2 },
    };
  }
};

export const getVisaApplicantData = (visaApplicant) => {
  if (visaApplicant && visaApplicant.length > 0) {
    const visaApp = visaApplicant.filter((e) =>
      isDateWithInDays(e.createdAt, 183)
    );
    const collectingCount = visaApp.filter(
      (e) => e.status === "Collecting Docs"
    ).length;
    const appliedCount = visaApp.filter(
      (e) => e.status === "Visa Applied"
    ).length;
    const infoRequestedCount = visaApp.filter(
      (e) => e.status === "Info Requested"
    ).length;
    const visaGrantCount = visaApp.filter(
      (e) => e.status === "Visa Granted"
    ).length;
    const visaRejectCount = visaApp.filter(
      (e) => e.status === "Visa Rejected"
    ).length;
    const visaWithdrawnCount = visaApp.filter(
      (e) => e.status === "Withdrawn"
    ).length;
    return {
      labels: [
        "Collecting",
        "Visa Applied",
        "InfoRequested",
        "VisaGranted",
        "VisaRejected",
        "Withdrawn",
      ],
      datasets: [
        {
          data: [
            collectingCount,
            appliedCount,
            infoRequestedCount,
            visaGrantCount,
            visaRejectCount,
            visaWithdrawnCount,
          ],
          backgroundColor: [
            "#00acc1", // Info
            "#2653aa", // Rose
            "#ff9800", // Warning
            "#4caf50", // Success
            "#f44336", // Danger
          ],
          hoverBackgroundColor: [
            "#00acc1", // Info
            "#2653aa", // Rose
            "#ff9800", // Warning
            "#4caf50", // Success
            "#f44336", // Danger
          ],
        },
      ],
    };
  }
  return defaultForPie;
};

export const getSkillAssessmentData = (skillAssessment) => {
  if (skillAssessment && skillAssessment.length > 0) {
    const visaApp = skillAssessment.filter((e) =>
      isDateWithInDays(e.createdAt, 183)
    );
    const collectingCount = visaApp.filter(
      (e) => e.status === "Collecting Docs"
    ).length;
    const appliedCount = visaApp.filter((e) => e.status === "Applied").length;
    const infoRequestedCount = visaApp.filter(
      (e) => e.status === "Info Requested"
    ).length;
    const approvedCount = visaApp.filter((e) => e.status === "Approved").length;
    const rejectCount = visaApp.filter((e) => e.status === "Rejected").length;
    const discontinuedCount = visaApp.filter(
      (e) => e.status === "Discontinued"
    ).length;
    return {
      labels: [
        "Collecting",
        "Applied",
        "InfoRequested",
        "Approved",
        "Rejected",
        "Discontinued",
      ],
      datasets: [
        {
          data: [
            collectingCount,
            appliedCount,
            infoRequestedCount,
            approvedCount,
            rejectCount,
            discontinuedCount,
          ],
          backgroundColor: [
            "#00acc1", // Info
            "#2653aa", // Rose
            "#ff9800", // Warning
            "#388e3c", // Success
            "#f44336", // Warning
          ],
          hoverBackgroundColor: [
            "#00acc1", // Info
            "#2653aa", // Rose
            "#ff9800", // Warning
            "#388e3c", // Success
            "#f44336", // Warning
          ],
        },
      ],
    };
  }
  return defaultForPie;
};

export const getInsuranceApplicantData = (insuranceApplicant) => {
  if (insuranceApplicant && insuranceApplicant.length > 0) {
    const insProv = getInsuranceProviderMapping();
    const insuranceApp = insuranceApplicant.filter(
      (e) =>
        isDateWithInDays(e.createdAt, 183) &&
        e.insuranceProviderId !== null &&
        e.insuranceProviderId !== ""
    );
    let allProvidersIds = insuranceApp.map((ins) => ins.insuranceProviderId);
    allProvidersIds = [...new Set(allProvidersIds)];
    const providersName = allProvidersIds.map(
      (id) => insProv.find((isP) => isP.id === id).name
    );
    const finalCount = [];
    allProvidersIds.forEach((pId) => {
      finalCount.push(
        insuranceApp.filter((ia) => ia.insuranceProviderId === pId).length
      );
    });
    return {
      labels: providersName,
      datasets: [
        {
          data: finalCount,
          backgroundColor: [...randomColors],
          hoverBackgroundColor: [...randomColors],
        },
      ],
    };
  }
  return defaultForPie;
};

export const getUserAssignedData = (students, users) => {
  if (students && students.length > 0 && users && users.length > 0) {
    const studentApp = students.filter(
      (e) => isDateWithInDays(e.createdAt, 183) && e.userId !== null
    );

    let allAssignedUserId = studentApp.map((e) => e.userId);
    allAssignedUserId = [...new Set(allAssignedUserId)];
    const finalData = [];
    allAssignedUserId.forEach((uid) => {
      const tempData = {};
      tempData.name = users.find((user) => user.id === uid).firstName;
      tempData.count = studentApp.filter((std) => std.userId === uid).length;
      finalData.push(tempData);
    });
    return {
      labels: finalData.map((fd) => fd.name),
      datasets: [
        {
          data: finalData.map((fd) => fd.count),
          backgroundColor: [...randomColors],
          hoverBackgroundColor: [...randomColors],
        },
      ],
    };
  }
  return defaultForPie;
};

export const getStudentData = (students) => {
  if (students && students.length > 0) {
    const recentLeads = students.filter((e) =>
      isDateWithInDays(e.createdAt, 183)
    );
    const consultationCount = recentLeads.filter(
      (e) => e.status === "Consultation"
    ).length;
    const collectingCount = recentLeads.filter(
      (e) => e.status === "Collecting Docs"
    ).length;
    const readyCount = recentLeads.filter(
      (e) => e.status === "Application Ready"
    ).length;
    const submittedCount = recentLeads.filter(
      (e) => e.status === "Application Submitted"
    ).length;
    const requestedCount = recentLeads.filter(
      (e) => e.status === "Info Requested"
    ).length;
    const provisionalCount = recentLeads.filter(
      (e) => e.status === "Provisional Offer/GTE Survey"
    ).length;
    const conditionalCount = recentLeads.filter(
      (e) => e.status === "Conditional Offer"
    ).length;
    const unconditionalCount = recentLeads.filter(
      (e) => e.status === "Unconditional Offer"
    ).length;
    const coeReceivedCount = recentLeads.filter(
      (e) => e.status === "Coe Received"
    ).length;
    const canceledCount = recentLeads.filter(
      (e) => e.status === "Canceled"
    ).length;
    const refusedCount = recentLeads.filter(
      (e) => e.status === "Refused"
    ).length;
    return {
      labels: [
        "Consultation",
        "Collecting",
        "Ready",
        "Submitted",
        "Info Requested",
        "Provisional Offer",
        "Conditional Offer",
        "Unconditional Offer",
        "COEReceived",
        "Canceled",
        "Refused",
      ],
      datasets: [
        {
          data: [
            consultationCount,
            collectingCount,
            readyCount,
            submittedCount,
            requestedCount,
            provisionalCount,
            conditionalCount,
            unconditionalCount,
            coeReceivedCount,
            canceledCount,
            refusedCount,
          ],
          backgroundColor: [
            "#00acc1", // Info
            "#2653aa", // Rose
            "#4caf50", // Success
            "#388e3c", // Success
            "#ff9800", // Warning
            "#135a3d", // Warning
            "#1a6069", // Info
            "#52a049", // Rose
            "#965c34", // Success
            "#de3d72", // Success
            "#f44336", // Danger
            "#9b9a3b", // Danger
          ],
          hoverBackgroundColor: [
            "#00acc1", // Info
            "#2653aa", // Rose
            "#4caf50", // Success
            "#388e3c", // Success
            "#ff9800", // Warning
            "#135a3d", // Warning
            "#1a6069", // Info
            "#52a049", // Rose
            "#965c34", // Success
            "#de3d72", // Success
            "#f44336", // Danger
            "#9b9a3b", // Danger
          ],
        },
      ],
    };
  }
  return defaultForPie;
};

export const getLeadData = (leads) => {
  if (leads && leads.length > 0) {
    const recentLeads = leads.filter((e) => isDateWithInDays(e.createdAt, 183));
    const newLeadCount = recentLeads.filter((e) => e.status === "New").length;
    const followUpCount = recentLeads.filter(
      (e) => e.status === "Follow Up"
    ).length;
    const convertedLeadCount = recentLeads.filter(
      (e) => e.status === "Converted"
    ).length;
    const notConvertedLeadCount = recentLeads.filter(
      (e) => e.status === "Not Converted"
    ).length;

    return {
      labels: ["New", "Converted", "Not Converted", "Follow Up"],
      datasets: [
        {
          data: [
            newLeadCount,
            convertedLeadCount,
            notConvertedLeadCount,
            followUpCount,
          ],
          backgroundColor: [
            "#00acc1", // Info
            "#4caf50", // Success
            "#f44336", // Danger
            "#ff9800", // Warning
          ],
          hoverBackgroundColor: [
            "#00acc1", // Info
            "#4caf50", // Success
            "#f44336", // Danger
            "#ff9800", // Warning
          ],
        },
      ],
    };
  }
  return defaultForPie;
};

const option = (color) => {
  return {
    fill: false,
    lineTension: 0.1,
    backgroundColor: color,
    borderColor: color,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: color,
    pointBackgroundColor: color,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: color,
    pointHoverBorderColor: color,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  };
};

export const getDataForGraph = (info, label, color, months = 12) => {
  const chunk = getChunk(info, months);
  const data = chunk.map((e) => e.count);
  const labels = chunk.map((e) => e.month);
  if (chunk && chunk.length > 0) {
    return {
      labels,
      dataset: {
        label,
        ...option(color),
        data,
      },
    };
  }
};

export const formatDataForGraph = (label, color, data) => {
  if (data.length > 0) {
    return {
      dataset: {
        label,
        ...option(color),
        data,
      },
    };
  }
};

export const getDOBForCalendar = (students, color) => {
  if (students && students.length > 0) {
    const resentStudents = students.filter(
      (e) =>
        isDateWithInDays(e.createdAt, 183) && e.dob !== null && e.dob !== ""
    );
    return resentStudents.map((std) => {
      const dob = moment(std.dob, DEFAULT_DATE_FORMAT);
      dob.year(moment().format("YYYY"));
      return {
        ...std,
        title: `${std.firstName}'s Birthday`,
        allDay: true,
        label: "Birthday",
        start: dob,
        end: dob,
        color: color || "default",
      };
    });
  }
  return [];
};

export const getFeeDueDateForCalendar = (
  students,
  color,
  path = "/admin/students"
) => {
  if (students && students.length > 0) {
    const resentStudents = students.filter(
      (e) =>
        isDateWithInDays(e.createdAt, 183) &&
        e.feeDueDate !== null &&
        e.feeDueDate !== ""
    );
    return resentStudents.map((std) => {
      const feeDueDate = moment(std.feeDueDate, DEFAULT_DATE_FORMAT);
      return {
        ...std,
        title: `${std.firstName}'s Fee Due`,
        allDay: true,
        label: "Fee Due Date",
        start: feeDueDate,
        end: feeDueDate,
        path,
        color: color || "default",
      };
    });
  }
  return [];
};

export const getVisaExpireDateForCalendar = (
  visaApplicants,
  color,
  path = "/admin/visa",
  name
) => {
  if (visaApplicants && visaApplicants.length > 0) {
    const resentApplicants = visaApplicants.filter(
      (e) =>
        // isDateWithInDays(e.createdAt, 183) &&
        e.visaExpiry !== null && e.visaExpiry !== ""
    );
    return resentApplicants.map((std) => {
      const visaExpiryDate = moment(std.visaExpiry, DEFAULT_DATE_FORMAT);
      return {
        ...std,
        title: `${std.firstName}'s Visa Expires${name ? ` (${name})` : ""}`,
        allDay: true,
        label: "Visa Expiry Date",
        start: visaExpiryDate,
        end: visaExpiryDate,
        path,
        color: color || "default",
      };
    });
  }
  return [];
};

export const getInfoRequestedDateForCalendar = (
  applicants,
  label,
  color,
  path,
  name
) => {
  if (applicants && applicants.length > 0) {
    const resentApplicants = applicants.filter(
      (e) =>
        // isDateWithInDays(e.createdAt, 183) &&
        e.dueDate !== null && e.dueDate !== ""
    );
    return resentApplicants.map((std) => {
      const dueDate = moment(std.dueDate, DEFAULT_DATE_FORMAT);
      return {
        ...std,
        title: `${std.firstName}'s Info Requested${name ? ` (${name})` : ""}`,
        allDay: true,
        label: label,
        start: dueDate,
        end: dueDate,
        path: path,
        color: color || "default",
      };
    });
  }
  return [];
};

export const capsFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const isDuplicateApplicant = (user, allUser) => {
  return allUser.find((x) => x.email === user.email);
};

// https://codesandbox.io/s/vj7yk35370?file=/index.js:172-210
export const reactTableHelper = (state, makeTable, showRecordInfo = true) => {
  let recordsInfoText;

  const { sortedData } = state;
  if (showRecordInfo) {
    if (sortedData && sortedData.length > 0) {
      let totalRecords = sortedData.length;
      recordsInfoText = `Total: ${totalRecords}`;
    } else recordsInfoText = "No records";
  }

  return (
    <div>
      <span
        style={{
          fontSize: "1.063rem",
        }}
      >
        {recordsInfoText}
      </span>
      {makeTable()}
    </div>
  );
};

export const getAnzsco = (occupation) => {
  const data = OCCUPATION_LIST.find((k) => k.title === occupation);
  if (data) {
    return data.code;
  } else {
    return "N/A";
  }
};

export const getOccupation = (anzscoCode) => {
  const data = OCCUPATION_LIST.find((k) => k.code === anzscoCode);
  if (data) {
    return data.title;
  } else {
    return "N/A";
  }
};

export const labelStyleFunc = (value) => {
  return {
    visibility: value ? "" : "hidden",
    color: "#AAA",
    fontSize: "11px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
    textAlign: "left",
    width: "100%",
  };
};
