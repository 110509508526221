import axios from "axios";

export default class RemoteConnection {
  constructor() {
    const baseURL = process.env.REACT_APP_API_ROOT || "http://localhost:3000";
    this.connection = axios.create({
      baseURL,
      timeout: 180000,
    });
  }

  options(token) {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
  }

  captchaOptions(captcha) {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        captcha,
      },
    };
  }

  async post(path, payload, token) {
    const newPayload = { ...payload };
    // delete newPayload.id;
    delete newPayload.deletedAt;
    delete newPayload.createdAt;
    delete newPayload.updatedAt;
    return this.connection.post(path, newPayload, this.options(token));
  }

  async postWithCaptcha(path, payload, captcha) {
    const newPayload = { ...payload };
    delete newPayload.id;
    delete newPayload.deletedAt;
    delete newPayload.createdAt;
    delete newPayload.updatedAt;
    return this.connection.post(path, newPayload, this.captchaOptions(captcha));
  }

  async put(path, payload, token) {
    const newPayload = { ...payload };
    delete newPayload.id;
    delete newPayload.deletedAt;
    delete newPayload.createdAt;
    delete newPayload.updatedAt;
    return this.connection.put(path, newPayload, this.options(token));
  }

  async delete(path, token) {
    return this.connection.delete(path, await this.options(token));
  }

  async get(path, token) {
    return this.connection.get(path, await this.options(token));
  }
}
