import RemoteConnection from "./RemoteConnection";
import axios from "axios";
const URL_SMS_EMAIL =
  process.env.REACT_APP_SMS_EMAIL ||
  "https://318k2i2kxi.execute-api.us-east-1.amazonaws.com/Prod";

class RemoteResource {
  static connection;

  static getConnection() {
    if (!RemoteResource.connection) {
      RemoteResource.connection = new RemoteConnection();
    }

    return RemoteResource.connection;
  }

  // LOGIN
  login(payload) {
    return RemoteResource.getConnection().post(`/auth/login`, payload);
  }

  forgotPassword(payload) {
    return axios.post(`${URL_SMS_EMAIL}/forgotpassword`, payload);
  }

  newUser(payload) {
    return axios.post(`${URL_SMS_EMAIL}/newuser`, payload);
  }

  customEmail(payload, token) {
    return axios.post(`${URL_SMS_EMAIL}/customemail`, payload, {
      headers: {
        Authorization: token,
      },
    });
  }

  customSMS(payload, token) {
    return axios.post(`${URL_SMS_EMAIL}/customsms`, payload, {
      headers: {
        Authorization: token,
      },
    });
  }

  templateEmail(payload, token) {
    return axios.post(`${URL_SMS_EMAIL}/emailtemplate`, payload, {
      headers: {
        Authorization: token,
      },
    });
  }

  templateSMS(payload, token) {
    return axios.post(`${URL_SMS_EMAIL}/customsms`, payload, {
      headers: {
        Authorization: token,
      },
    });
  }

  passwordReset(payload, token) {
    return RemoteResource.getConnection().post(
      `/auth/resetpassword`,
      payload,
      token
    );
  }

  // TODO ENDPOINT
  createTodo(payload, token) {
    return RemoteResource.getConnection().post(`/todo`, payload, token);
  }

  deleteTodo(id, token) {
    return RemoteResource.getConnection().delete(`/todo/${id}`, token);
  }

  updateTodo(id, payload, token) {
    return RemoteResource.getConnection().put(`/todo/${id}`, payload, token);
  }

  updateAllTodo(payload, token) {
    return RemoteResource.getConnection().put(`/todo`, payload, token);
  }

  getTodo(id, token) {
    return RemoteResource.getConnection().get(`/todo/${id}`, token);
  }

  getTodoCount(token) {
    return RemoteResource.getConnection().get(`/todo/my/active`, token);
  }

  getAllTodos(token) {
    return RemoteResource.getConnection().get(`/todo`, token);
  }

  // Appointment Endpoint
  createAppointment(payload, token) {
    return RemoteResource.getConnection().post(`/appointment`, payload, token);
  }

  deleteAppointment(id, token) {
    return RemoteResource.getConnection().delete(`/appointment/${id}`, token);
  }

  updateAppointment(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/appointment/${id}`,
      payload,
      token
    );
  }

  getAppointment(id, token) {
    return RemoteResource.getConnection().get(`/appointment/${id}`, token);
  }

  getMyAppointment(token) {
    return RemoteResource.getConnection().get(`/appointment/my/active`, token);
  }

  getAllAppointments(token) {
    return RemoteResource.getConnection().get(`/appointment`, token);
  }

  getAllAppointmentUsers(token) {
    return RemoteResource.getConnection().get(
      `/public/userForAppointment`,
      token
    );
  }

  // USERS ENDPOINT
  createUser(payload, token) {
    return RemoteResource.getConnection().post(`/user`, payload, token);
  }

  updateUser(id, payload, token) {
    return RemoteResource.getConnection().put(`/user/${id}`, payload, token);
  }

  getUser(id, token) {
    return RemoteResource.getConnection().get(`/user/${id}`, token);
  }

  getAllUser(token) {
    return RemoteResource.getConnection().get(`/user`, token);
  }

  getAllServiceData(token) {
    return RemoteResource.getConnection().get(`/user/all-service-data`, token);
  }

  updateProfile(id, payload, token) {
    return RemoteResource.getConnection().put(`/user/${id}`, payload, token);
  }

  generateClockInCode(id, token) {
    return RemoteResource.getConnection().get(
      `/user/${id}/generateCode`,
      token
    );
  }

  getProfile(id, token) {
    return RemoteResource.getConnection().get(`/user/${id}`, token);
  }

  // Leads ENDPOINT
  createLead(payload, token) {
    return RemoteResource.getConnection().post(`/lead`, payload, token);
  }

  updateLead(id, payload, token) {
    return RemoteResource.getConnection().put(`/lead/${id}`, payload, token);
  }

  updateLeadClient(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/lead/updateClient/${id}`,
      payload,
      token
    );
  }

  deleteLead(id, token) {
    return RemoteResource.getConnection().delete(`/lead/${id}`, token);
  }

  getLead(id, token) {
    return RemoteResource.getConnection().get(`/lead/${id}`, token);
  }

  getLeadLog(id, token) {
    return RemoteResource.getConnection().get(`/lead/log/${id}`, token);
  }

  getAllLeads(token, params) {
    if (params)
      return RemoteResource.getConnection().get(`/lead${params}`, token);
    else return RemoteResource.getConnection().get(`/lead`, token);
  }

  // Students ENDPOINT
  createStudent(payload, token) {
    return RemoteResource.getConnection().post(`/student`, payload, token);
  }

  updateStudent(id, payload, token) {
    return RemoteResource.getConnection().put(`/student/${id}`, payload, token);
  }

  deleteStudent(id, token) {
    return RemoteResource.getConnection().delete(`/student/${id}`, token);
  }

  getStudent(id, token) {
    return RemoteResource.getConnection().get(`/student/${id}`, token);
  }

  getStudentLog(id, token) {
    return RemoteResource.getConnection().get(`/student/log/${id}`, token);
  }

  getAllStudents(token) {
    return RemoteResource.getConnection().get(`/student`, token);
  }

  // Visa Applicant ENDPOINT
  createVisaApplicant(payload, token) {
    return RemoteResource.getConnection().post(
      `/visaApplicant`,
      payload,
      token
    );
  }

  updateVisaApplicant(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/visaApplicant/${id}`,
      payload,
      token
    );
  }

  deleteVisaApplicant(id, token) {
    return RemoteResource.getConnection().delete(`/visaApplicant/${id}`, token);
  }

  getVisaApplicant(id, token) {
    return RemoteResource.getConnection().get(`/visaApplicant/${id}`, token);
  }

  getVisaApplicantLog(id, token) {
    return RemoteResource.getConnection().get(
      `/visaApplicant/log/${id}`,
      token
    );
  }

  getAllVisaApplicants(token) {
    return RemoteResource.getConnection().get(`/visaApplicant`, token);
  }

  // Visa List Endpoint
  createVisa(payload, token) {
    return RemoteResource.getConnection().post(`/visa`, payload, token);
  }

  updateVisa(id, payload, token) {
    return RemoteResource.getConnection().put(`/visa/${id}`, payload, token);
  }

  deleteVisa(id, token) {
    return RemoteResource.getConnection().delete(`/visa/${id}`, token);
  }

  getVisa(id, token) {
    return RemoteResource.getConnection().get(`/visa/${id}`, token);
  }

  getAllVisas(token) {
    return RemoteResource.getConnection().get(`/visa`, token);
  }

  // Occupation Endpoint
  createOccupation(payload, token) {
    return RemoteResource.getConnection().post(`/occupation`, payload, token);
  }

  updateOccupation(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/occupation/${id}`,
      payload,
      token
    );
  }

  deleteOccupation(id, token) {
    return RemoteResource.getConnection().delete(`/occupation/${id}`, token);
  }

  getOccupation(id, token) {
    return RemoteResource.getConnection().get(`/occupation/${id}`, token);
  }

  getAllOccupations(token) {
    return RemoteResource.getConnection().get(`/occupation`, token);
  }

  // Announcement Endpoint
  createAnnouncement(payload, token) {
    return RemoteResource.getConnection().post(`/announcement`, payload, token);
  }

  updateAnnouncement(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/announcement/${id}`,
      payload,
      token
    );
  }

  deleteAnnouncement(id, token) {
    return RemoteResource.getConnection().delete(`/announcement/${id}`, token);
  }

  getAnnouncement(id, token) {
    return RemoteResource.getConnection().get(`/announcement/${id}`, token);
  }

  getAllAnnouncements(token) {
    return RemoteResource.getConnection().get(`/announcement`, token);
  }
  // Insurance Applicant ENDPOINT
  createInsuranceApplicant(payload, token) {
    return RemoteResource.getConnection().post(
      `/insuranceApplicant`,
      payload,
      token
    );
  }

  updateInsuranceApplicant(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/insuranceApplicant/${id}`,
      payload,
      token
    );
  }

  deleteInsuranceApplicant(id, token) {
    return RemoteResource.getConnection().delete(
      `/insuranceApplicant/${id}`,
      token
    );
  }

  getInsuranceApplicant(id, token) {
    return RemoteResource.getConnection().get(
      `/insuranceApplicant/${id}`,
      token
    );
  }

  getInsuranceApplicantLog(id, token) {
    return RemoteResource.getConnection().get(
      `/insuranceApplicant/log/${id}`,
      token
    );
  }

  getAllInsuranceApplicants(token) {
    return RemoteResource.getConnection().get(`/insuranceApplicant`, token);
  }

  // Skill Assessment ENDPOINT
  createSkillAssessment(payload, token) {
    return RemoteResource.getConnection().post(
      `/skillAssessment`,
      payload,
      token
    );
  }

  updateSkillAssessment(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/skillAssessment/${id}`,
      payload,
      token
    );
  }

  deleteSkillAssessment(id, token) {
    return RemoteResource.getConnection().delete(
      `/skillAssessment/${id}`,
      token
    );
  }

  getSkillAssessment(id, token) {
    return RemoteResource.getConnection().get(`/skillAssessment/${id}`, token);
  }

  getSkillAssessmentLog(id, token) {
    return RemoteResource.getConnection().get(
      `/skillAssessment/log/${id}`,
      token
    );
  }

  getAllSkillAssessment(token) {
    return RemoteResource.getConnection().get(`/skillAssessment`, token);
  }

  // University ENDPOINT
  createUniversity(payload, token) {
    return RemoteResource.getConnection().post(`/university`, payload, token);
  }

  updateUniversity(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/university/${id}`,
      payload,
      token
    );
  }

  getAllUniversities(token) {
    return RemoteResource.getConnection().get(`/university`, token);
  }

  // Courses ENDPOINT
  createCourse(payload, token) {
    return RemoteResource.getConnection().post(`/course`, payload, token);
  }

  bulkCreateCourse(payload, token) {
    return RemoteResource.getConnection().post(`/course/bulk`, payload, token);
  }

  updateCourse(id, payload, token) {
    return RemoteResource.getConnection().put(`/course/${id}`, payload, token);
  }

  getAllCourses(token) {
    return RemoteResource.getConnection().get(`/course`, token);
  }

  // Immigration ENDPOINT
  createImmigration(payload, token) {
    return RemoteResource.getConnection().post(
      `/immigration/profile`,
      payload,
      token
    );
  }

  updateImmigration(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/immigration/profile/${id}`,
      payload,
      token
    );
  }

  getAllImmigrations(token) {
    return RemoteResource.getConnection().get(`/immigration/profile`, token);
  }

  // Agreement ENDPOINT
  createAgreement(payload, token) {
    return RemoteResource.getConnection().post(`/agreement`, payload, token);
  }

  updateAgreement(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/agreement/${id}`,
      payload,
      token
    );
  }

  getAllAgreements(token) {
    return RemoteResource.getConnection().get(`/agreement`, token);
  }

  // Report ENDPOINT
  createReport(payload, token) {
    return RemoteResource.getConnection().post(`/fiscalReport`, payload, token);
  }

  updateReport(id, payload, token) {
    return RemoteResource.getConnection().put(
      `/fiscalReport/${id}`,
      payload,
      token
    );
  }

  getAllReports(token) {
    return RemoteResource.getConnection().get(`/fiscalReport`, token);
  }

  refreshReport(id, token) {
    return RemoteResource.getConnection().get(
      `/fiscalReport/refresh/${id}`,
      token
    );
  }

  // Source ENDPOINT
  createSource(payload, token) {
    return RemoteResource.getConnection().post(`/source`, payload, token);
  }

  updateSource(id, payload, token) {
    return RemoteResource.getConnection().put(`/source/${id}`, payload, token);
  }

  getAllSources(token) {
    return RemoteResource.getConnection().get(`/source`, token);
  }
  getVisaStats(token) {
    return RemoteResource.getConnection().get(`/stats/visa-applicant`, token);
  }

  getEducationStats(token) {
    return RemoteResource.getConnection().get(`/stats/education`, token);
  }

  getSkillAssesmentStats(token) {
    return RemoteResource.getConnection().get(`/stats/skill-assesment`, token);
  }

  getLeadStats(token) {
    return RemoteResource.getConnection().get(`/stats/lead`, token);
  }
  getHealthInsuranceStats(token) {
    return RemoteResource.getConnection().get(`/stats/health-insurance`, token);
  }
  getConvertedStats(token) {
    return RemoteResource.getConnection().get(`/stats/converted`, token);
  }

  // Setting ENDPOINT
  updateSetting(payload, token) {
    return RemoteResource.getConnection().put(`/setting/1`, payload, token);
  }

  getSetting(token) {
    return RemoteResource.getConnection().get(`/setting/1`, token);
  }

  createPublicLead(payload, captcha) {
    return RemoteResource.getConnection().postWithCaptcha(
      `/public/lead`,
      payload,
      captcha
    );
  }

  searchPublicLead(payload, captcha) {
    return RemoteResource.getConnection().postWithCaptcha(
      `/public/searchLead`,
      payload,
      captcha
    );
  }

  // Checkin ENDPOINT
  createNewCheckin(payload, captcha) {
    return RemoteResource.getConnection().postWithCaptcha(
      `/public/newcheckin`,
      payload,
      captcha
    );
  }

  createOldCheckin(payload, captcha) {
    return RemoteResource.getConnection().postWithCaptcha(
      `/public/oldCheckin`,
      payload,
      captcha
    );
  }

  updateCheckin(id, token) {
    return RemoteResource.getConnection().put(`/checkin/${id}`, null, token);
  }

  getAllCheckins(token) {
    return RemoteResource.getConnection().get(`/checkin`, token);
  }

  // Analytics ENDPOINT
  getClientCountries(token) {
    return RemoteResource.getConnection().get(
      `/analytics/clientCountry`,
      token
    );
  }

  getStudentAnalytics(token) {
    return RemoteResource.getConnection().get(
      `/analytics/studentInsight`,
      token
    );
  }

  getVisaAnalytics(token) {
    return RemoteResource.getConnection().get(`/analytics/visaInsight`, token);
  }

  getSkillAnalytics(token) {
    return RemoteResource.getConnection().get(`/analytics/skillInsight`, token);
  }

  // Leave ENDPOINT
  createLeave(payload, token) {
    return RemoteResource.getConnection().post(`/leave`, payload, token);
  }

  updateLeave(id, payload, token) {
    return RemoteResource.getConnection().put(`/leave/${id}`, payload, token);
  }

  getAllLeaves(token) {
    return RemoteResource.getConnection().get(`/leave`, token);
  }

  getAllUserLeaves(id, token) {
    return RemoteResource.getConnection().get(`/leave/user/${id}`, token);
  }

  // ClockIn Endpoint
  clockLogin(payload) {
    return RemoteResource.getConnection().post(`/auth/clockLogin`, payload);
  }

  clockIn(token) {
    return RemoteResource.getConnection().put(`clock/clockIn`, null, token);
  }

  clockOut(token) {
    return RemoteResource.getConnection().put(`clock/clockOut`, null, token);
  }

  breakStart(token) {
    return RemoteResource.getConnection().put(`clock/breakStart`, null, token);
  }

  breakEnd(token) {
    return RemoteResource.getConnection().put(`clock/breakEnd`, null, token);
  }

  getClockInData(id, token) {
    return RemoteResource.getConnection().get(`/user/${id}/clock`, token);
  }

  updateClockIn(userId, id, payload, token) {
    return RemoteResource.getConnection().put(
      `/user/${userId}/clock/${id}`,
      payload,
      token
    );
  }
  saveFacePhoto(payload, token) {
    return RemoteResource.getConnection().post(`/face/add`, payload, token);
  }
  getFacePhoto(token) {
    return RemoteResource.getConnection().get(`/face/get`, token);
  }
}

export default new RemoteResource();
