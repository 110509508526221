import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import SweetAlert from "react-bootstrap-sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

import styles from "assets/jss/material-dashboard-pro-react/views/checkInPageStyle";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { Lion as LoaderButton } from "react-button-loaders";
import { filterCaseInsensitive, verifyEmail } from "../../../scripts/util";
import {
  CHECKIN_OLD_CREATE_REQUESTED,
  CLEAR_ERROR,
  LEAD_SEARCH_REQUESTED,
} from "../../../redux/actions";
import jwt from "jsonwebtoken";
import ReactTable from "react-table";
import Button from "../../../components/CustomButtons/Button";
import ReactGA from "react-ga4";

const useStyles = makeStyles(styles);
const addCaptcha = false;

export default function ReturningCheckin({ openModel }) {
  const recaptchaRef = React.useRef();

  const dispatch = useDispatch();
  const classes = useStyles();

  const [selected, setSelected] = useState("Email");
  const [searchValue, setSearchValue] = useState("");
  const [validationErr, setValidationErr] = useState("");
  const [confirmAlert, setAlert] = React.useState(null);

  // Search
  const searchedLeads = useSelector((state) => state.public.leads);
  const searchError = useSelector((state) => state.public.leadSearchError);
  const searchErrMsg = useSelector((state) => state.public.errorMsg);
  const searchProgress = useSelector(
    (state) => state.public.leadSearchFetching
  );

  // Create
  const createError = useSelector(
    (state) => state.checkin.checkinOldCreateError
  );
  const errMsg = useSelector((state) => state.checkin.errorMsg);
  const createSuccess = useSelector(
    (state) => state.checkin.checkinOldCreateSuccess
  );
  const createProgress = useSelector(
    (state) => state.checkin.checkinOldCreateFetching
  );

  useEffect(() => {
    if (createSuccess) {
      successCreate();
    }
  }, [createSuccess]);

  useEffect(() => {
    if (createError) {
      failCreate();
    }
  }, [createError]);

  const failCreate = () => {
    setAlert(
      <SweetAlert
        danger
        title="Error"
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {errMsg || "Something went wrong"}
      </SweetAlert>
    );
  };
  const successCreate = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Checked-In"
        onConfirm={() => {
          openModel(false);
          setAlert(null);
          dispatch({
            type: CLEAR_ERROR,
          });
        }}
        onCancel={() => {
          openModel(false);
          setAlert(null);
          dispatch({
            type: CLEAR_ERROR,
          });
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Please be seated. Someone will be with you shortly.
      </SweetAlert>
    );
  };

  const confirmationMessage = (leadId) => {
    setAlert(
      <SweetAlert
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          const captcha = jwt.sign({ captchaText: "hello World" }, "NEPAL", {
            expiresIn: "1m",
          });
          dispatch({
            type: CHECKIN_OLD_CREATE_REQUESTED,
            payload: {
              leadId,
            },
            captcha,
          });
          ReactGA.event({
            category: "Check-In",
            action: "Returning Customer CheckIn",
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, Check-In!"
        cancelBtnText="Cancel"
        showCancel
      />
    );
  };

  const handleChange = (event) => {
    setSearchValue("");
    setValidationErr("");
    setSelected(event.target.value);
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = async () => {
    let captcha = "";
    if (addCaptcha) {
      captcha = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
    }

    setValidationErr("");

    if (!searchValue || searchValue === "") {
      return setValidationErr("Please search with some value");
    }
    if (selected === "Email") {
      if (!verifyEmail(searchValue)) {
        return setValidationErr("Please Input Valid Email");
      }
    } else if (selected === "Phone") {
      if (
        searchValue &&
        searchValue !== "" &&
        ![9, 10].includes(searchValue.length)
      ) {
        return setValidationErr("Phone number is invalid");
      }
    } else if (selected === "Name") {
      if (searchValue.length < 3) {
        return setValidationErr("Please enter valid name");
      }
    }
    ReactGA.event({
      category: "Check-In",
      action: "Returning Customer Search",
    });
    dispatch({
      type: LEAD_SEARCH_REQUESTED,
      payload: {
        [selected.toLowerCase()]: searchValue,
      },
      captcha,
    });
  };

  const columns = [
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      headerStyle: { textAlign: "left" },
      filterable: false,
      sortable: false,
      Header: "Action",
      accessor: "id",
      Cell: (props) => {
        const captcha = jwt.sign({ captchaText: "hello World" }, "NEPAL", {
          expiresIn: "1m",
        });
        return (
          <div className="actions">
            {/* use this button to add a like kind of action */}
            <Button
              onClick={() => {
                // dispatch({
                //   type: CHECKIN_OLD_CREATE_REQUESTED,
                //   payload: {
                //     leadId: props.value,
                //   },
                //   captcha,
                // });
                confirmationMessage(props.value);
              }}
              color="info"
              disabled={createProgress}
            >
              Check-In
            </Button>{" "}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12}>
          <Typography variant="h6" className={classes.labelHorizontal}>
            Let's First Find You
          </Typography>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <div className={classes.inlineChecks}>
            <FormControlLabel
              control={
                <Radio
                  checked={selected === "Email"}
                  onChange={handleChange}
                  value="Email"
                  name="Email"
                  aria-label="Email"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Email"
            />

            <FormControlLabel
              control={
                <Radio
                  checked={selected === "Phone"}
                  onChange={handleChange}
                  value="Phone"
                  name="Phone"
                  aria-label="Phone"
                  icon={
                    <FiberManualRecord className={classes.radioUnchecked} />
                  }
                  checkedIcon={
                    <FiberManualRecord className={classes.radioChecked} />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Phone"
            />

            {/*<FormControlLabel*/}
            {/*  control={*/}
            {/*    <Radio*/}
            {/*      checked={selected === "Name"}*/}
            {/*      onChange={handleChange}*/}
            {/*      value="Name"*/}
            {/*      name="Name"*/}
            {/*      aria-label="Name"*/}
            {/*      icon={*/}
            {/*        <FiberManualRecord className={classes.radioUnchecked} />*/}
            {/*      }*/}
            {/*      checkedIcon={*/}
            {/*        <FiberManualRecord className={classes.radioChecked} />*/}
            {/*      }*/}
            {/*      classes={{*/}
            {/*        checked: classes.radio,*/}
            {/*        root: classes.radioRoot,*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  }*/}
            {/*  classes={{*/}
            {/*    label: classes.label,*/}
            {/*  }}*/}
            {/*  label="Name"*/}
            {/*/>*/}
          </div>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={8}>
          <CustomInput
            labelText={selected}
            id="search-id"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: searchValue,
              onChange: handleSearchChange,
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem>
          {addCaptcha && (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_CAPTCHA_INVISIBLE_KEY}
              ref={recaptchaRef}
              size="invisible"
            />
          )}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <LoaderButton
            onClick={handleSearch}
            state={searchProgress ? "loading" : null}
            disabled={searchProgress}
          >
            Search
          </LoaderButton>
          {validationErr && (
            <span className={classes.errorMsg}>{validationErr}</span>
          )}
          {searchError && (
            <span className={classes.errorMsg}>
              {searchErrMsg || "Something went wrong"}
            </span>
          )}
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.checkinTable}>
        <GridItem xs={12} sm={12}>
          <ReactTable
            pageSizeOptions={[5, 10, 25]}
            data={searchedLeads}
            filterable
            resizable={true}
            columns={columns}
            defaultPageSize={5}
            showPaginationBottom={true}
            className="-striped -highlight"
            defaultFilterMethod={filterCaseInsensitive}
          />
        </GridItem>
      </GridContainer>
      {confirmAlert}
    </>
  );
}
