import React, { useEffect, useState } from "react";
import momentTZ from "moment-timezone";
import moment from "moment";

// react component for creating dynamic tables
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import history from "../../../history";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import AddSkillAssessment from "./AddSkillAssessment";
import EditSkillAssessment from "./EditSkillAssessment";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  filterCaseInsensitive,
  getColumns,
  getDropDown,
  isValidDate,
  reactTableHelper,
} from "../../../scripts/util";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { useDispatch, useSelector } from "react-redux";
import Badge from "../../../components/Badge/Badge";
import { GetApp } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import ReactGA from "react-ga4";
import withTimer from "../../../components/Timer/withTimer";
import SmsEmailAction from "../../../components/SmsEmailAction/SmsEmailAction";
import {
  SKILL_ASSESSMENT_TABLE_ACTION,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_WITH_MONTH,
  SUPER_ROLE,
  DEFAULT_TIME_ZONE,
  monthColor,
  GENERAL_ROLE,
} from "../../../scripts/const";
import DateFilter from "../../../components/DateFilter";
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { USER_GET_REQUESTED } from "redux/actions";
import { USER_UPDATE_REQUESTED } from "redux/actions";

const SelectTable = selectTableHOC(ReactTable);

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SkillAssessmentTables = ({
  match: {
    params: { id },
  },
}) => {
  const [addSkillModal, setAddSkillModal] = React.useState(false);
  const [editSkillModal, setEditSkillModal] = React.useState(false);
  const [currentSkillAssessment, setCurrentSkillAssessment] = React.useState(
    {}
  );
  const [data, setData] = React.useState([]);

  const currentUser = useSelector((state) => state.login.loginUser.detail);
  const isSelectTable = SUPER_ROLE.includes(currentUser.roleId);
  const isGeneralUser = GENERAL_ROLE.includes(currentUser.roleId);

  const skillAssessments = useSelector(
    (state) => state.skillAssessment.skillAssessments
  );

  const skillAssessmentsFetching = useSelector(
    (state) => state.skillAssessment.skillAssessmentGetAllFetching
  );

  const [users, setUsers] = React.useState([]);
  const [sources, setSources] = React.useState([]);

  const allSources = useSelector((state) => state.source.sources);
  const allUsers = useSelector((state) => state.user.users);
  const activeUsers = useSelector((state) => state.user.activeUsers);

  const userDetail = useSelector((state) => state.user.userDetail);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: USER_GET_REQUESTED,
      payload: currentUser.id,
    });
  }, []);

  const [selectedOptions, setSelectedOptions] = React.useState(
    userDetail?.hideColumn?.skillAssessmentService || []
  );
  const [columnDataloaded, setColumnDataloaded] = useState(false);

  React.useEffect(() => {
    if (userDetail?.hideColumn?.skillAssessmentService !== selectedOptions) {
      if (userDetail?.hideColumn?.skillAssessmentService)
        setSelectedOptions(userDetail?.hideColumn?.skillAssessmentService);
      setColumnDataloaded(true);
    }
  }, [userDetail?.hideColumn?.skillAssessmentService]);

  React.useEffect(() => {
    setUsers(allUsers);
  }, [allUsers]);
  React.useEffect(() => {
    setSources(allSources);
  }, [allSources]);
  React.useEffect(() => {
    setData(skillAssessments);
    if (!isGeneralUser) {
      setFilterData(skillAssessments);
    } else {
      const filterData = skillAssessments.filter(
        (student) => student.userId === currentUser.id
      );
      setFilterData(filterData);
    }
  }, [skillAssessments]);

  useEffect(() => {
    if (id !== "undefined" && id > 0 && data.length > 0) {
      const found = data.find((d) => d.id == id);
      if (found) {
        setCurrentSkillAssessment(found);
        setEditSkillModal(true);
      }
    }
  }, [id, data]);

  const [filterData, setFilterData] = React.useState([]);
  const [fromDateState, setFromDateState] = React.useState("");
  const [toDateState, setToDateState] = React.useState("");

  const [dateRange, setDateRange] = React.useState({
    fromDate: "",
    toDate: "",
  });

  const [selection, setSelection] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);

  function isSelected(key) {
    return selection.includes(`select-${key}`);
  }
  function toggleAll() {
    const keyField = "id";
    const newSelectAll = !selectAll;
    const newSelection = [];

    if (newSelectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = reactTableRef.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        newSelection.push(`select-${item._original[keyField]}`);
      });
    }
    setSelection(newSelection);
    setSelectAll(newSelectAll);
  }
  function toggleSelection(key, shift, row) {
    // start off with the existing state
    let newSelection = [...selection];
    const keyIndex = newSelection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      newSelection = [
        ...newSelection.slice(0, keyIndex),
        ...newSelection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      newSelection.push(key);
    }
    // update the state
    setSelection(newSelection);
  }

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setDateRange({
        ...dateRange,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setDateRange({
        ...dateRange,
        [name]: date,
      });
    }
  };

  function filter() {
    let allValid = true;
    setToDateState("");
    setFromDateState("");

    if (dateRange.fromDate === "") {
      setFromDateState("error");
      allValid = false;
    }

    if (dateRange.toDate === "") {
      setToDateState("error");
      allValid = false;
      return;
    }

    if (!isValidDate(dateRange.fromDate)) {
      setFromDateState("error");
      allValid = false;
    }

    if (!isValidDate(dateRange.toDate)) {
      setToDateState("error");
      allValid = false;
    }

    if (allValid) {
      const fromDate = moment(dateRange.fromDate, DEFAULT_DATE_FORMAT);
      const toDate = moment(dateRange.toDate, DEFAULT_DATE_FORMAT);
      const filterData = data.filter(
        (e) => moment(e.createdAt) >= fromDate && moment(e.createdAt) <= toDate
      );
      setFilterData(filterData);
    }
  }

  function clear() {
    setToDateState("");
    setFromDateState("");
    setDateRange({
      fromDate: "",
      toDate: "",
    });
    setFilterData(data);
  }

  let reactTableRef = null;
  let csvLink = null;
  function download(event) {
    const columns = getColumns("Skill");
    const currentRecords = isSelectTable
      ? reactTableRef.getWrappedInstance().getResolvedState().sortedData
      : reactTableRef.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] =
          currentRecords[index][columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    setDataToDownload(data_to_download);
  }

  const [dataToDownload, setDataToDownload] = React.useState([]);
  const [isDownload, setIsDownload] = React.useState(false);

  React.useEffect(() => {
    if (dataToDownload.length > 0) {
      setIsDownload(true);
    }
  }, [dataToDownload]);

  React.useEffect(() => {
    if (isDownload) {
      csvLink.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  const columns = [
    {
      sortable: false,
      Header: " ",
      accessor: "createdAt",
      show: !selectedOptions.includes(" "),

      Cell: (props) => {
        if (props.value) {
          return momentTZ(props.value).format("MMM YYYY");
        }
        return props.value;
      },
      getProps: (state, rowInfo) => {
        let color = null;
        if (rowInfo && rowInfo.row.createdAt) {
          let month = momentTZ(rowInfo.row.createdAt).format("MM");
          color = monthColor[month - 1];
        }
        return {
          style: {
            background: color,
          },
        };
      },
      filterMethod: (filter, row) => {
        return momentTZ(row[filter.id])
          .format("MMM YYYY")
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      },
      width: 70,
    },
    {
      Header: "ID",
      show: !selectedOptions.includes("ID"),
      accessor: "id",
      width: 70,
    },
    {
      Header: "First Name",
      show: !selectedOptions.includes("First Name"),
      accessor: "firstName",
      width: 150,
    },
    {
      Header: "Middle Name",
      show: !selectedOptions.includes("Middle Name"),
      accessor: "middleName",
      width: 150,
    },
    {
      Header: "Last Name",
      show: !selectedOptions.includes("Last Name"),
      accessor: "lastName",
      width: 150,
    },
    {
      Header: "Email",
      show: !selectedOptions.includes("Email"),
      accessor: "email",
      width: 150,
    },
    {
      Header: "Phone",
      show: !selectedOptions.includes("Phone"),
      accessor: "phone",
      width: 150,
    },
    {
      Header: "Country",
      show: !selectedOptions.includes("Country"),
      accessor: "country",
    },
    {
      Header: "Passport No.",
      show: !selectedOptions.includes("Passport No."),
      accessor: "passport",
      width: 150,
    },
    {
      Header: "Issue Date",
      show: !selectedOptions.includes("Issue Date"),
      accessor: "issueDate",
      width: 150,
    },
    {
      Header: "Expiry Date",
      show: !selectedOptions.includes("Expiry Date"),
      accessor: "expiryDate",
      width: 150,
    },
    {
      headerStyle: { textAlign: "left" },
      Header: "Location",
      show: !selectedOptions.includes("Location"),
      accessor: "location",
      width: 150,

      filterMethod: (filter, row) => {
        if (filter.value === "All") {
          return true;
        }
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "All"}
        >
          <option value="All">All</option>
          {getDropDown("location").map((name, i) => {
            return (
              <option key={i} value={`${name}`}>
                {name}
              </option>
            );
          })}
        </select>
      ),
    },
    {
      Header: "Occupation",
      show: !selectedOptions.includes("Occupation"),
      accessor: "occupation",
      width: 150,
    },
    {
      Header: "ANZSCO",
      show: !selectedOptions.includes("ANZSCO"),
      accessor: "anzsco",
      width: 150,
    },
    {
      Header: "Assess Body",
      show: !selectedOptions.includes("Assess Body"),
      accessor: "skillAssessmentBody",
      width: 150,
      Cell: (props) => {
        if (props.value) {
          return props.value.split("(")[0];
        }
        return props.value;
      },
    },
    // {
    //   Header: "Current Visa",
    //   accessor: "currentVisa",
    // },
    // {
    //   Header: "Expiry Date",
    //   accessor: "visaExpiry",
    //   sortMethod: (a, b) => {
    //     const a1 = new Date(a).getTime();
    //     const b1 = new Date(b).getTime();
    //     if (a1 < b1) return 1;
    //     else if (a1 > b1) return -1;
    //     else return 0;
    //   },
    //   Cell: (props) => {
    //     if (props.value) {
    //       const now = momentTZ().tz(DEFAULT_TIME_ZONE);
    //       const expiry = moment(props.value, DEFAULT_DATE_FORMAT);
    //       const dateDiff = expiry.diff(now, "days");
    //       if (dateDiff <= 30 && dateDiff >= -1) {
    //         return (
    //           <Badge color={"danger"}>
    //             {moment(props.value, DEFAULT_DATE_FORMAT).format(
    //               DEFAULT_DATE_FORMAT_WITH_MONTH
    //             )}
    //           </Badge>
    //         );
    //       } else {
    //         return moment(props.value, DEFAULT_DATE_FORMAT).format(
    //           DEFAULT_DATE_FORMAT_WITH_MONTH
    //         );
    //       }
    //     }
    //     return props.value;
    //   },
    //   defaultSorted: (a, b) => {
    //     const a1 = new Date(a).getTime();
    //     const b1 = new Date(b).getTime();
    //     if (a1 < b1) return 1;
    //     else if (a1 > b1) return -1;
    //     else return 0;
    //   },
    //   filterMethod: (filter, row) => {
    //     if (filter.value === "all") {
    //       return true;
    //     }
    //     if (filter.value === "priority") {
    //       if (row[filter.id]) {
    //         const now = momentTZ().tz(DEFAULT_TIME_ZONE);
    //         const expiry = moment(row[filter.id], DEFAULT_DATE_FORMAT);
    //         const dateDiff = expiry.diff(now, "days");
    //         return dateDiff <= 30 && dateDiff >= -1;
    //       }
    //       return false;
    //     }
    //   },
    //   Filter: ({ filter, onChange }) => (
    //     <select
    //       onChange={(event) => onChange(event.target.value)}
    //       style={{ width: "100%" }}
    //       value={filter ? filter.value : "all"}
    //     >
    //       <option value="all">All</option>
    //       <option value="priority">Need Attention</option>
    //     </select>
    //   ),
    // },
    // {
    //   Header: "Requested Date",
    //   accessor: "requestedDate",
    //   sortMethod: (a, b) => {
    //     const a1 = new Date(a).getTime();
    //     const b1 = new Date(b).getTime();
    //     if (a1 < b1) return 1;
    //     else if (a1 > b1) return -1;
    //     else return 0;
    //   },
    //   Cell: (props) => {
    //     if (props.value) {
    //       const now = momentTZ().tz(DEFAULT_TIME_ZONE);
    //       const expiry = moment(props.value, DEFAULT_DATE_FORMAT);
    //       const dateDiff = expiry.diff(now, "days");
    //       return moment(props.value, DEFAULT_DATE_FORMAT).format(
    //         DEFAULT_DATE_FORMAT_WITH_MONTH
    //       );
    //     }
    //     return props.value;
    //   },
    //   defaultSorted: (a, b) => {
    //     const a1 = new Date(a).getTime();
    //     const b1 = new Date(b).getTime();
    //     if (a1 < b1) return 1;
    //     else if (a1 > b1) return -1;
    //     else return 0;
    //   },
    //   filterMethod: (filter, row) => {
    //     if (filter.value === "all") {
    //       return true;
    //     }
    //     if (filter.value === "priority") {
    //       if (row[filter.id]) {
    //         const now = momentTZ().tz(DEFAULT_TIME_ZONE);
    //         const expiry = moment(row[filter.id], DEFAULT_DATE_FORMAT);
    //         const dateDiff = expiry.diff(now, "days");
    //         return dateDiff <= 30 && dateDiff >= -1;
    //       }
    //       return false;
    //     }
    //   },
    //   Filter: ({ filter, onChange }) => (
    //     <select
    //       onChange={(event) => onChange(event.target.value)}
    //       style={{ width: "100%" }}
    //       value={filter ? filter.value : "all"}
    //     >
    //       <option value="all">All</option>
    //       {/*<option value="priority">Need Attention</option>*/}
    //     </select>
    //   ),
    // },
    {
      Header: "Due Date",
      show: !selectedOptions.includes("Due Date"),
      accessor: "dueDate",
      width: 150,
      sortMethod: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      Cell: (props) => {
        if (props.value) {
          const now = momentTZ().tz(DEFAULT_TIME_ZONE);
          const expiry = moment(props.value, DEFAULT_DATE_FORMAT);
          const dateDiff = expiry.diff(now, "days");
          if (dateDiff <= 7 && dateDiff >= -1) {
            return (
              <Badge color={"danger"}>
                {moment(props.value, DEFAULT_DATE_FORMAT).format(
                  DEFAULT_DATE_FORMAT_WITH_MONTH
                )}
              </Badge>
            );
          } else {
            return moment(props.value, DEFAULT_DATE_FORMAT).format(
              DEFAULT_DATE_FORMAT_WITH_MONTH
            );
          }
        }
        return props.value;
      },
      defaultSorted: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "priority") {
          if (row[filter.id]) {
            const now = momentTZ().tz(DEFAULT_TIME_ZONE);
            const expiry = moment(row[filter.id], DEFAULT_DATE_FORMAT);
            const dateDiff = expiry.diff(now, "days");
            return dateDiff <= 7 && dateDiff >= -1;
          }
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="priority">Need Attention</option>
        </select>
      ),
    },
    {
      Header: "Submitted Date",
      show: !selectedOptions.includes("Submitted Date"),
      accessor: "submittedDate",
      width: 150,
    },
    {
      Header: "Decision Date",
      show: !selectedOptions.includes("Decision Date"),
      accessor: "decisionDate",
      width: 150,
    },
    {
      Header: "AssignedTo",
      show: !selectedOptions.includes("AssignedTo"),
      accessor: "userId",
      Cell: (props) => {
        if (props.value && allUsers) {
          const user = allUsers.find((e) => e.id === props.value);
          if (user) {
            return user.firstName;
          } else {
            return props.value;
          }
        } else {
          return props.value;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "notAssigned") {
          return row[filter.id] === "" || row[filter.id] === null;
        } else if (filter.value) {
          return row[filter.id] == filter.value;
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => {
            if (isGeneralUser) {
              setFilterData(skillAssessments);

              if (filterData.length === 0) {
                setTimeout(() => {
                  onChange(event.target.value);
                }, 500);
              } else {
                onChange(event.target.value);
              }
            } else {
              onChange(event.target.value);
            }
          }}
          style={{ width: "100%" }}
          value={filter ? filter.value : isGeneralUser ? currentUser.id : "all"}
        >
          <option value="all">All</option>
          <option value="notAssigned">Not Assigned</option>
          {activeUsers &&
            activeUsers.map((m, i) => (
              <option key={i} value={m.id}>
                {m.firstName}
              </option>
            ))}
        </select>
      ),
      width: 150,
    },
    {
      Header: "Payment",
      show: !selectedOptions.includes("Payment"),
      accessor: "paymentStatus",
      Cell: (props) => {
        if (props.value === "Partial Paid") {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else if (props.value === "Fully Paid") {
          return <Badge color={"success"}>{props.value}</Badge>;
        } else if (props.value === "Not Paid") {
          return <Badge color={"primary"}>{props.value}</Badge>;
        } else {
          return <Badge color={"gray"}>{props.value}</Badge>;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "All") {
          return true;
        }
        if (filter.value === "Partial Paid") {
          return row[filter.id] === "Partial Paid";
        }
        if (filter.value === "Fully Paid") {
          return row[filter.id] === "Fully Paid";
        }
        if (filter.value === "Not Paid") {
          return row[filter.id] === "Not Paid";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "All"}
        >
          <option value="All">All</option>
          <option value="Partial Paid">Partial Paid</option>
          <option value="Fully Paid">Fully Paid</option>
          <option value="Not Paid">Not Paid</option>
        </select>
      ),
      width: 150,
    },
    {
      headerStyle: { textAlign: "left" },
      Header: "CSA Status",
      show: !selectedOptions.includes("CSA Status"),
      accessor: "csaStatus",
      Cell: (props) => {
        if (props.value === "Need to Send") {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else if (props.value === "CSA/Inv Sent") {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else if (props.value === "CSA Signed") {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else {
          return <Badge color={"gray"}>{props.value}</Badge>;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "All") {
          return true;
        }
        if (filter.value === "Need to Send") {
          return row[filter.id] === "Need to Send";
        }
        if (filter.value === "CSA/Inv Sent") {
          return row[filter.id] === "CSA/Inv Sent";
        }
        if (filter.value === "CSA Signed") {
          return row[filter.id] === "CSA Signed";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "All"}
        >
          <option value="All">All</option>
          <option value="Need to Send">Need to Send</option>
          <option value="CSA/Inv Sent">CSA/Inv Sent</option>
          <option value="CSA Signed">CSA Signed</option>
        </select>
      ),
      width: 150,
    },
    {
      headerStyle: { textAlign: "left" },
      Header: "Status",
      show: !selectedOptions.includes("Status"),
      accessor: "status",
      Cell: (props) => {
        if (["Follow Up", "Info Requested"].includes(props.value)) {
          return <Badge color={"warning"}>{props.value}</Badge>;
        } else if (props.value === "Approved") {
          return <Badge color={"success"}>{props.value}</Badge>;
        } else if (
          props.value === "Rejected" ||
          props.value === "Discontinued"
        ) {
          return <Badge color={"danger"}>{props.value}</Badge>;
        } else if (props.value) {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else {
          return <Badge color={"gray"}>{props.value}</Badge>;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "All") {
          return true;
        }
        if (filter.value === "Consulting") {
          return row[filter.id] === "Consulting";
        }
        if (filter.value === "Follow Up") {
          return row[filter.id] === "Follow Up";
        }
        if (filter.value === "Collecting Docs") {
          return row[filter.id] === "Collecting Docs";
        }
        if (filter.value === "Applied") {
          return row[filter.id] === "Applied";
        }
        if (filter.value === "Info Requested") {
          return row[filter.id] === "Info Requested";
        }
        if (filter.value === "Approved") {
          return row[filter.id] === "Approved";
        }
        if (filter.value === "Rejected") {
          return row[filter.id] === "Rejected";
        }
        if (filter.value === "Discontinued") {
          return row[filter.id] === "Discontinued";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "All"}
        >
          <option value="All">All</option>
          <option value="Consulting">Consulting</option>
          <option value="Follow Up">Follow Up</option>
          <option value="Collecting Docs">Collecting Docs</option>
          <option value="Applied">Applied</option>
          <option value="Info Requested">Info Requested</option>
          <option value="Approved">Approved</option>
          <option value="Rejected">Rejected</option>
          <option value="Discontinued">Discontinued</option>
        </select>
      ),
      width: 150,
    },
  ];
  function handleDateRangeFilter(fromDate, toDate) {
    if (!fromDate || !toDate) {
      setFilterData(data);
    } else {
      const filterData = data.filter(
        (e) => moment(e.createdAt) >= fromDate && moment(e.createdAt) <= toDate
      );
      setFilterData(filterData);
    }
  }
  function getBGColor(row) {
    const { status } = row;
    if (["Follow Up", "Info Requested"].includes(status)) {
      return "#fada5e";
    }
    return undefined;
  }

  function getTrProps(state, rowInfo) {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          e.preventDefault();
          ReactGA.event({
            category: "SkillAssessmentService",
            action: "View Skill Assessment Profile",
          });
          setCurrentSkillAssessment(rowInfo.row._original);
          setEditSkillModal(true);
          history.push(`/admin/skill/${rowInfo.row._original.id}`);
        },
        style: {
          background: getBGColor(rowInfo.row._original),
        },
      };
    } else {
      return {};
    }
  }

  const handleOptionChange = (e) => {
    const option = e.target.value[0];

    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  React.useEffect(() => {
    if (
      columnDataloaded &&
      selectedOptions !== userDetail?.hideColumn?.skillAssessmentService
    ) {
      dispatch({
        type: USER_UPDATE_REQUESTED,
        payload: {
          payload: { skillAssessementServiceColumn: selectedOptions },
          id: currentUser.id,
        },
      });
    }
  }, [selectedOptions]);
  console.log(selectedOptions, userDetail);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <GridContainer>
              <GridItem xs={5}>
                <Button
                  onClick={() => {
                    setAddSkillModal(true);
                  }}
                  color="primary"
                >
                  Add Skill Assessment
                </Button>

                <div style={{ display: "inline-block", marginLeft: "5px" }}>
                  <Button
                    title="Download CSV"
                    justIcon
                    color="info"
                    onClick={download}
                  >
                    <GetApp />
                  </Button>
                  {selection && selection.length > 0 && (
                    <SmsEmailAction
                      option={SKILL_ASSESSMENT_TABLE_ACTION}
                      data={filterData}
                      selection={selection}
                    />
                  )}
                </div>
                <CSVLink
                  data={dataToDownload}
                  filename="skill-assessment.csv"
                  className="hidden"
                  ref={(r) => (csvLink = r)}
                  target="_blank"
                />
              </GridItem>

              <GridItem xs={7}>
                <DateFilter handleFilter={handleDateRangeFilter} />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <InputLabel id="column-select-label">Select Columns</InputLabel>
              <Select
                labelId="column-select-label"
                id="column-select"
                multiple
                value={[]}
                onChange={handleOptionChange}
                renderValue={(selected) => selected.join(", ")}
              >
                {columns.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data.Header}>
                      <Checkbox
                        checked={selectedOptions.includes(data.Header)}
                      />
                      <ListItemText primary={data.Header} />
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {isSelectTable && (
              <SelectTable
                keyField="id"
                toggleSelection={toggleSelection}
                selectAll={selectAll}
                selectType="checkbox"
                toggleAll={toggleAll}
                isSelected={isSelected}
                ref={(r) => (reactTableRef = r)}
                pageSizeOptions={[25, 50, 100]}
                data={filterData}
                filterable
                loading={skillAssessmentsFetching}
                resizable={true}
                getTrProps={getTrProps}
                columns={columns}
                defaultPageSize={25}
                showPaginationBottom={true}
                className="-striped -highlight"
                defaultFilterMethod={filterCaseInsensitive}
              >
                {(state, makeTable, instance) => {
                  return reactTableHelper(state, makeTable, instance);
                }}
              </SelectTable>
            )}
            {!isSelectTable && (
              <ReactTable
                ref={(r) => (reactTableRef = r)}
                pageSizeOptions={[25, 50, 100]}
                data={filterData}
                filterable
                loading={skillAssessmentsFetching}
                resizable={true}
                getTrProps={getTrProps}
                columns={columns}
                defaultPageSize={25}
                showPaginationBottom={true}
                className="-striped -highlight"
                defaultFilterMethod={filterCaseInsensitive}
              >
                {(state, makeTable, instance) => {
                  return reactTableHelper(state, makeTable, instance);
                }}
              </ReactTable>
            )}
          </CardBody>
        </Card>
      </GridItem>

      {/*Add Skill Assessment*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={addSkillModal}
        disableScrollLock={true}
        TransitionComponent={Transition}
        onClose={() => setAddSkillModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        disableBackdropClick
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAddSkillModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>Add Skill Assessment</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <AddSkillAssessment
            allUsers={activeUsers}
            open={addSkillModal}
            allSources={sources}
            allSkillAssessment={skillAssessments}
            handleSuccess={setAddSkillModal}
          />
        </DialogContent>
      </Dialog>

      {/*Edit Skill Assessment*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={editSkillModal}
        disableScrollLock={true}
        TransitionComponent={Transition}
        onClose={() => {
          setEditSkillModal(false);
          history.push("/admin/skill");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        disableBackdropClick
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setEditSkillModal(false);
              history.push("/admin/skill");
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>Edit Skill Assessment</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <EditSkillAssessment
            allUsers={activeUsers}
            open={editSkillModal}
            allSources={sources}
            allSkillAssessment={skillAssessments}
            skillAssessment={currentSkillAssessment}
            handleSuccess={setEditSkillModal}
          />
        </DialogContent>
      </Dialog>
    </GridContainer>
  );
};

export default withTimer(SkillAssessmentTables);
