import React, { useEffect } from "react";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_ERROR,
  SKILL_ASSESSMENT_DELETE_REQUESTED,
  SKILL_ASSESSMENT_GET_ALL_REQUESTED,
  SKILL_ASSESSMENT_LOG_GET_REQUESTED,
  SKILL_ASSESSMENT_UPDATE_REQUESTED,
} from "../../../redux/actions";
import {
  convertDate,
  getAnzsco,
  getDropDown,
  getOccupation,
  getUserName,
  isValidSkillAssessmentInputs,
  labelStyleFunc,
} from "../../../scripts/util";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import Muted from "../../../components/Typography/Muted";
import ReactGA from "react-ga4";
import {
  DEFAULT_DATE_FORMAT,
  GENERAL_ROLE,
  occupationCodeOption,
  SUPER_ADMIN_ROLES,
  useGetOccupationOptions,
  useGetVisaTypeOptions,
  TINY_INIT,
} from "../../../scripts/const";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SweetAlert from "react-bootstrap-sweetalert";
import { Editor } from "@tinymce/tinymce-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { default as ReactSelect } from "react-select";
import FileUpload from "../../../components/CustomUpload/FileUpload";
import DropDownWithSearch, {
  customReactSelectStyles,
} from "../../../components/DropDownWithSearch/DropDownWithSearch";

const useStyles = makeStyles({ ...styles, ...styles2 });

export default function EditSkillAssessment(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const occupationOption = useGetOccupationOptions();
  const visaTypeOption = useGetVisaTypeOptions();

  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);
  const [error, setError] = React.useState(false);
  const [validationErrMsg, setValidationErrMsg] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [skillAssessment, setSkillAssessment] = React.useState({});
  const [skillAssessmentCopy, setSkillAssessmentCopy] = React.useState({});
  const [skillLog, setSkillLog] = React.useState([]);
  const [isLog, setIsLog] = React.useState(false);
  const sLog = useSelector((state) => state.skillAssessment.skillAssessmentLog);
  const [logId, setLogId] = React.useState(null);

  const handleFileUpload = (fileUrl) => {
    if (!skillAssessment.files) {
      setSkillAssessment({
        ...skillAssessment,
        files: [fileUrl],
      });
    } else {
      setSkillAssessment({
        ...skillAssessment,
        files: [...skillAssessment.files, fileUrl],
      });
    }
  };

  const handleFileDelete = (fileUrl) => {
    setSkillAssessment({
      ...skillAssessment,
      files: skillAssessment.files.filter((f) => f != fileUrl),
    });
  };

  const handleLog = () => {
    if (isLog) {
      setSkillAssessment(skillAssessmentCopy);
    } else {
      ReactGA.event({
        category: "ViewHistory",
        action: "View Skill Assessment History",
      });
      setSkillAssessmentCopy(skillAssessment);
    }
    if (!isLog && skillLog.length === 0) {
      dispatch({
        type: SKILL_ASSESSMENT_LOG_GET_REQUESTED,
        payload: skillAssessmentId,
      });
    }
    setIsLog(!isLog);
  };

  const [skillAssessmentId, setSkillAssessmentId] = React.useState(false);
  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );
  const errMsg = useSelector((state) => state.skillAssessment.errorMsg);
  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);
  const handleSelect = (e) => {
    setSkillAssessment({
      ...skillAssessment,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditorChange = (content) => {
    setSkillAssessment({
      ...skillAssessment,
      remarks: content,
    });
  };

  const dispatch = useDispatch();

  const editError = useSelector(
    (state) => state.skillAssessment.skillAssessmentUpdateError
  );
  const editSuccess = useSelector(
    (state) => state.skillAssessment.skillAssessmentUpdateSuccess
  );
  const editProgress = useSelector(
    (state) => state.skillAssessment.skillAssessmentUpdateFetching
  );
  const deleteProgress = useSelector(
    (state) => state.skillAssessment.skillAssessmentDeleteFetching
  );
  const deleteSuccess = useSelector(
    (state) => state.skillAssessment.skillAssessmentDeleteSuccess
  );

  function clearErrors() {
    setValidationErrMsg("");
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      clearErrors();
      setSkillAssessment(skillAssessmentCopy);
      setSkillLog([]);
      setIsLog(false);
      setLogId(null);
      hideAlert();
    }
  }, [modalOpen]);

  useEffect(() => {
    setSkillLog(sLog);
  }, [sLog]);

  useEffect(() => {
    setValidationErrMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    setSkillAssessment(props.skillAssessment);
    setSkillAssessmentId(props.skillAssessment.id);
  }, [props.skillAssessment, props.open]);

  useEffect(() => {
    setError(editError);
  }, [editError]);

  useEffect(() => {
    setSuccess(editSuccess);
  }, [editSuccess]);

  useEffect(() => {
    if (editSuccess === true || deleteSuccess === true) {
      setTimeout(() => {
        if (deleteSuccess) {
          props.handleSuccess(false);
        }
        dispatch({ type: SKILL_ASSESSMENT_GET_ALL_REQUESTED });
      }, 720);
      setTimeout(() => {
        // setSkillAssessment({});
        dispatch({ type: CLEAR_ERROR });
      }, 750);
    }
  }, [editSuccess, deleteSuccess]);

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      if (name === "requestedDate") {
        setSkillAssessment({
          ...skillAssessment,
          [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
          dueDate: date
            .clone()
            .add(27, "days")
            .format(DEFAULT_DATE_FORMAT)
            .toString(),
        });
      } else {
        setSkillAssessment({
          ...skillAssessment,
          [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
        });
      }
    } else {
      setSkillAssessment({
        ...skillAssessment,
        [name]: date,
      });
    }
  };

  function editVisaApplicant() {
    const errors = isValidSkillAssessmentInputs(skillAssessment);
    if (errors.length > 0) {
      setValidationErrMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else {
      setValidationErrMsg("");
      dispatch({
        type: SKILL_ASSESSMENT_UPDATE_REQUESTED,
        payload: { payload: skillAssessment, id: skillAssessmentId },
      });
    }
  }

  function getLog() {
    return skillLog.map((st, index) => (
      <Card key={index} color={index === logId ? "primary" : null}>
        <CardBody
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setLogId(index);
            setSkillAssessment(skillLog[index]);
          }}
        >
          <small>{getUserName(st.updatedBy, props.allUsers)}</small>
          <br></br>
          <small>{convertDate(st.version_timestamp)}</small>
        </CardBody>
      </Card>
    ));
  }

  function deleteEntity() {
    dispatch({
      type: SKILL_ASSESSMENT_DELETE_REQUESTED,
      payload: { id: skillAssessmentId },
    });
    ReactGA.event({
      category: "SkillAssessmentService",
      action: "Deleted a Skill Assessment",
    });
  }

  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      />
    );
  };
  const confirmationMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteEntity();
          successDelete();
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      />
    );
  };
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem
          style={{
            textAlign: "right",
          }}
          xs={12}
          sm={12}
          md={12}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isLog}
                onChange={handleLog}
                value={isLog}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={isLog ? "HideHistory" : "ShowHistory"}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={3} sm={3} md={3}>
          {isLog && <div>{getLog()}</div>}
        </GridItem>
        <GridItem xs={isLog ? 9 : 12} sm={isLog ? 9 : 12} md={isLog ? 9 : 12}>
          {" "}
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {validationErrMsg && (
                    <SnackbarContent
                      message={validationErrMsg}
                      color="warning"
                    />
                  )}
                  {error && (
                    <SnackbarContent message={errMessage} color="warning" />
                  )}
                  {editProgress && <CustomLinearProgress color="primary" />}
                  {success && (
                    <SnackbarContent
                      message="Updated the skill applicant"
                      color="success"
                    />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="ID"
                    id="editVisa-id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessmentId || "",
                      disabled: true,
                      name: "id",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address *"
                    id="skill-edit-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.email || "",
                      onChange: handleSelect,
                      name: "email",
                      type: "email",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="skill-edit-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="First Name *"
                    id="skill-edit-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Middle Name"
                    id="skill-edit-middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.middleName || "",
                      onChange: handleSelect,
                      name: "middleName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Last Name *"
                    id="skill-edit-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Date of Birth
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.dob || ""}
                      onChange={(moment) => handleDatePicker(moment, "dob")}
                      inputProps={{
                        name: "dob",
                        id: "edit-skill-dob-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                        disabled: isLog,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Passport No"
                    id="skill-edit-passport"
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.passport,
                      onChange: handleSelect,
                      name: "passport",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Issue Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.issueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "issueDate")
                      }
                      inputProps={{
                        name: "issueDate",
                        id: "skill-edit-issueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Expiry Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.expiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "expiryDate")
                      }
                      inputProps={{
                        name: "expiryDate",
                        id: "skill-edit-expiryDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(skillAssessment.currentVisa)}
                    >
                      Current Visa
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        skillAssessment.currentVisa
                          ? {
                              label: skillAssessment.currentVisa,
                              value: skillAssessment.currentVisa,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={visaTypeOption}
                      name="currentVisa"
                      placeholder={"Current Visa"}
                      isDisabled={isLog}
                      onChange={(options) => {
                        if (!options) {
                          setSkillAssessment({
                            ...skillAssessment,
                            currentVisa: "",
                          });
                        } else {
                          setSkillAssessment({
                            ...skillAssessment,
                            currentVisa: options.value,
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Visa Expiry Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.visaExpiry || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "visaExpiry")
                      }
                      inputProps={{
                        name: "visaExpiry",
                        id: "visaExpiry-edit",
                        placeholder: DEFAULT_DATE_FORMAT,
                        disabled: isLog,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(skillAssessment.occupation)}
                    >
                      Occupation
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      isDisabled={isLog}
                      value={
                        skillAssessment.occupation
                          ? {
                              label: skillAssessment.occupation,
                              value: skillAssessment.occupation,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={occupationOption}
                      name="occupation"
                      placeholder={"Occupation"}
                      onChange={(options) => {
                        if (!options) {
                          setSkillAssessment({
                            ...skillAssessment,
                            occupation: "",
                            anzsco: "",
                          });
                        } else {
                          setSkillAssessment({
                            ...skillAssessment,
                            occupation: options.value,
                            anzsco: getAnzsco(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(skillAssessment.anzsco)}
                    >
                      ANZSCO
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      isDisabled={isLog}
                      value={
                        skillAssessment.anzsco
                          ? {
                              label: skillAssessment.anzsco,
                              value: skillAssessment.anzsco,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={occupationCodeOption}
                      name="anzsco"
                      placeholder={"ANZSCO"}
                      onChange={(options) => {
                        if (!options) {
                          setSkillAssessment({
                            ...skillAssessment,
                            anzsco: "",
                            occupation: "",
                          });
                        } else {
                          setSkillAssessment({
                            ...skillAssessment,
                            anzsco: options.value,
                            occupation: getOccupation(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("skill-assessment-body-full")}
                    selectedOption={skillAssessment.skillAssessmentBody || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"skillAssessmentBody"}
                    label={"Skill Assessment Body"}
                  />
                </GridItem>
                {skillAssessment.skillAssessmentBody &&
                  skillAssessment.skillAssessmentBody === "Other" && (
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Other Body *"
                        id="skill-edit-other"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: skillAssessment.otherSkillAssessmentBody || "",
                          onChange: handleSelect,
                          name: "otherSkillAssessmentBody",
                          disabled: isLog,
                        }}
                      />
                    </GridItem>
                  )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Invoice Number"
                    id="edit-skill-invoiceNumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.invoiceNumber || "",
                      onChange: handleSelect,
                      name: "invoiceNumber",
                      disabled: isLog,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Payment"
                    id="edit-skill-payment"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.payment || "",
                      onChange: handleSelect,
                      name: "payment",
                      disabled: isLog,
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <DropDownWithSearch
                    optionsList={getDropDown("payment-status")}
                    selectedOption={skillAssessment.paymentStatus || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"paymentStatus"}
                    label={"Payment Status"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Requested Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      autoOk
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.requestedDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "requestedDate")
                      }
                      inputProps={{
                        name: "requestedDate",
                        id: "requestedDate-add2",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Due Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.dueDate || ""}
                      onChange={(moment) => handleDatePicker(moment, "dueDate")}
                      inputProps={{
                        name: "dueDate",
                        id: "dueDate-add3",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Submitted Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.submittedDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "submittedDate")
                      }
                      inputProps={{
                        name: "submittedDate",
                        id: "submittedDate-edit2",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    DecisionDate Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.decisionDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "decisionDate")
                      }
                      inputProps={{
                        name: "decisionDate",
                        id: "decisionDate-edit2",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <DropDownWithSearch
                    optionsList={getDropDown("skill-csa-status")}
                    selectedOption={skillAssessment.csaStatus || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"csaStatus"}
                    label={"CSA Status"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <DropDownWithSearch
                    optionsList={getDropDown("skill-status")}
                    selectedOption={skillAssessment.status || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"status"}
                    label={"Status *"}
                  />
                </GridItem>
                {skillAssessment.status &&
                  [
                    "Applied",
                    "Info Requested",
                    "Approved",
                    "Rejected",
                  ].includes(skillAssessment.status) && (
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel className={classes.label}>
                        {skillAssessment.status} Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          closeOnSelect
                          timeFormat={false}
                          dateFormat={DEFAULT_DATE_FORMAT}
                          value={skillAssessment.statusDate || ""}
                          onChange={(moment) =>
                            handleDatePicker(moment, "statusDate")
                          }
                          inputProps={{
                            name: "statusDate",
                            id: "edit-skill-statusDate",
                            placeholder: DEFAULT_DATE_FORMAT,
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("country")}
                    selectedOption={skillAssessment.country || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"country"}
                    label={"Country"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("location")}
                    selectedOption={skillAssessment.location || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"location"}
                    label={"Location"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allSources && (
                        <DropDownWithSearch
                          dbOptions={props.allSources}
                          selectedOption={skillAssessment.sourceId || ""}
                          setValue={setSkillAssessment}
                          currentState={skillAssessment}
                          name={"sourceId"}
                          label={"Source"}
                        />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allUsers && (
                        <DropDownWithSearch
                          dbOptions={props.allUsers}
                          dbLabel={"firstName"}
                          selectedOption={skillAssessment.userId || ""}
                          setValue={setSkillAssessment}
                          currentState={skillAssessment}
                          name={"userId"}
                          label={"Assigned To"}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={skillAssessment.remarks || ""}
                    init={TINY_INIT}
                    onEditorChange={handleEditorChange}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div>
                    <h4>Documents:</h4>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      type={"skillAssessment"}
                      setFileUrl={(fileUrl) => handleFileUpload(fileUrl)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {skillAssessment.files &&
                  skillAssessment.files.length > 0 &&
                  skillAssessment.files.map((fileUrl, id) => {
                    return (
                      <GridItem key={id}>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            backgroundColor: "#B0BEC5",
                            padding: "20px",
                            borderRadius: "25px",
                            marginTop: "10px",
                          }}
                        >
                          <FileUpload
                            fileUrl={fileUrl}
                            key={id}
                            handleDelete={(fileUrl) => {
                              handleFileDelete(fileUrl);
                            }}
                          />
                        </div>
                      </GridItem>
                    );
                  })}
              </GridContainer>
              <br />
              <br />
              <GridContainer
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <GridItem>
                  <Muted>
                    Updated At: {convertDate(skillAssessment.updatedAt)}
                  </Muted>
                  <Muted>
                    Updated By:{" "}
                    {getUserName(skillAssessment.updatedBy, props.allUsers)}
                  </Muted>
                </GridItem>
                <GridItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.3rem",
                  }}
                >
                  {editProgress && <CircularProgress size={"2rem"} />}
                  {error && <CloseIcon style={{ color: "red" }} />}
                  {success && <CheckIcon style={{ color: "green" }} />}
                  {SUPER_ADMIN_ROLES.includes(currentRoleId) && (
                    <Button
                      color="danger"
                      disabled={!!(deleteProgress || isLog)}
                      className={classes.moveToRight}
                      onClick={(e) => {
                        e.preventDefault();
                        confirmationMessage();
                      }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    color="primary"
                    disabled={editProgress || isLog ? true : false}
                    className={classes.moveToRight}
                    onClick={(e) => {
                      ReactGA.event({
                        category: "SkillAssessmentService",
                        action: "Updated a Skill Assessment",
                      });
                      e.preventDefault();
                      editVisaApplicant();
                    }}
                  >
                    Update
                  </Button>
                </GridItem>
              </GridContainer>
              <Clearfix />
              {alert}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
