import React, { useEffect, useState } from "react";

import styles from "assets/jss/material-dashboard-pro-react/views/checkInPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import InitialCheckin from "components/CheckIn/InitialCheckin";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import NewCheckin from "./newChecking";
import ReturningCheckin from "./returningChecking";
import { CLEAR_ERROR } from "../../../redux/actions";
import { COMPANY_NAME } from "../../../scripts/const";
const manifestCheckin = require("../../../assets/files/manifestCheckin.json");
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function Checkin() {
  const dispatch = useDispatch();

  const [newCustomerModalOpen, setNewCustomerModalOpen] = useState(false);
  const [newReturningModalOpen, setReturningCustomerModalOpen] =
    useState(false);

  const handleInitialChecking = (value) => {
    if (value === "new") {
      setNewCustomerModalOpen(true);
      setReturningCustomerModalOpen(false);
    } else if (value === "returning") {
      setNewCustomerModalOpen(false);
      setReturningCustomerModalOpen(true);
    }
  };
  const classes = useStyles();
  useEffect(() => {
    const stringManifest = JSON.stringify(manifestCheckin);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector("#manifest").setAttribute("href", manifestURL);
  }, []);

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Office Check-In | {COMPANY_NAME}</title>
        <meta
          name="description"
          content="Check-In. Student | Visa | Skill Assessment | Insurance services"
        />
        <meta name="og:title" content={`Check-In for ${COMPANY_NAME}`} />
      </Helmet>
      <InitialCheckin handleClick={handleInitialChecking} />
      <Dialog
        fullScreen
        open={newCustomerModalOpen}
        onClose={() => setNewCustomerModalOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle disableTypography className={classes.appBar}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setNewCustomerModalOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">New Customer Check-In</Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <NewCheckin openModel={setNewCustomerModalOpen} />
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen
        open={newReturningModalOpen}
        onClose={() => {
          setReturningCustomerModalOpen(false);
          dispatch({
            type: CLEAR_ERROR,
          });
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle disableTypography className={classes.appBar}>
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setReturningCustomerModalOpen(false);
                  dispatch({
                    type: CLEAR_ERROR,
                  });
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Returning Customer Check-In</Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ReturningCheckin openModel={setReturningCustomerModalOpen} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
