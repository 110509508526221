import storage from "redux-persist/lib/storage";
import createEncryptor from "redux-persist-transform-encrypt";
import { persistStore, persistReducer } from "redux-persist";

import { createStore, applyMiddleware, compose } from "redux";

import createSagaMiddleware from "redux-saga";
import { watcherSaga } from "./sagas";
import reducers from "./reducers";

const secretKey = process.env.REDUX_PERSIST_ENCRYPT || "Nepal";

const encryptor = createEncryptor({
  secretKey,
  onError: (error) => {
    throw new Error("Redux Persist Encryption Failed");
  },
});

const sagaMiddleware = createSagaMiddleware();
applyMiddleware(sagaMiddleware)(createStore);

const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["login"],
  transforms: [encryptor],
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const pReducer = persistReducer(persistConfig, reducers);
const store = createStore(
  pReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(watcherSaga);
const persistor = persistStore(store);

export { persistor, store };
