export default (theme) => {
  return {
    chip: {
      backgroundColor: "white",
    },
    dynamicTagContainer: {
      width: "50%",
      position: "relative",
    },
    pop: {
      marginTop: "8px",
      overflow: "auto",
      maxHeight: "300px",
    },
    paper: {
      zIndex: 1,
    },
    chipInput: {
      // marginTop: "20px",
    },
    pchip: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "20rem",
    },
    name: {
      fontWeight: "bold",
    },
    popItem: {
      minWidth: "200px",
      whiteSpace: "preWrap",
    },
  };
};
