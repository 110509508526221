import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  VISA_CREATE_REQUESTED,
  VISA_CREATE_FAILED,
  VISA_CREATE_SUCCEEDED,
  VISA_GET_ALL_REQUESTED,
  VISA_GET_ALL_FAILED,
  VISA_GET_ALL_SUCCEEDED,
  VISA_GET_REQUESTED,
  VISA_GET_FAILED,
  VISA_GET_SUCCEEDED,
  VISA_UPDATE_REQUESTED,
  VISA_UPDATE_FAILED,
  VISA_UPDATE_SUCCEEDED,
  LEAD_UPDATE_SUCCEEDED,
  VISA_DELETE_SUCCEEDED,
  VISA_DELETE_FAILED,
  VISA_DELETE_REQUESTED,
} from "../actions";

import { getToken } from "./selectors";

function* fetchVisa(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const visa = yield call(
      remoteResource.getVisa,
      id,
      token
    );
    yield put({
      type: VISA_GET_SUCCEEDED,
      payload: visa.data,
    });
  } catch (e) {
    yield put({ type: VISA_GET_FAILED, payload: e.message });
  }
}

function* fetchAllVisas(action) {
  try {
    const token = yield select(getToken);
    const visa = yield call(
      remoteResource.getAllVisas,
      token
    );
    yield put({
      type: VISA_GET_ALL_SUCCEEDED,
      payload: visa.data,
    });
  } catch (e) {
    yield put({ type: VISA_GET_ALL_FAILED, payload: e.message });
  }
}


function* createVisa(action) {
  try {
    let payload = {};
    let leadId = "";
    if (action.payload && action.payload.payload) {
      payload = action.payload.payload;
    } else {
      payload = action.payload;
    }
    if (action.payload && action.payload && action.payload.leadId) {
      leadId = action.payload.leadId;
    }
    const token = yield select(getToken);
    const visa = yield call(
      remoteResource.createVisa,
      payload,
      token
    );
    if (leadId && leadId !== "") {
      const lead = yield call(
        remoteResource.updateLeadClient,
        leadId,
        { visaId: visa.data.id },
        token
      );
      yield put({
        type: LEAD_UPDATE_SUCCEEDED,
        payload: lead.data,
      });
    }
    yield put({
      type: VISA_CREATE_SUCCEEDED,
      payload: visa.data,
    });
  } catch (e) {
    yield put({
      type: VISA_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateVisa(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const visa = yield call(
      remoteResource.updateVisa,
      id,
      payload,
      token
    );
    yield put({
      type: VISA_UPDATE_SUCCEEDED,
      payload: visa.data,
    });
  } catch (e) {
    yield put({
      type: VISA_UPDATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* deleteVisa(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    yield call(remoteResource.deleteVisa, id, token);
    yield put({
      type: VISA_DELETE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: VISA_DELETE_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchVisa() {
  yield takeLatest(VISA_GET_REQUESTED, fetchVisa);
  yield takeLatest(VISA_CREATE_REQUESTED, createVisa);
  yield takeLatest(VISA_UPDATE_REQUESTED, updateVisa);
  yield takeLatest(VISA_GET_ALL_REQUESTED, fetchAllVisas);
  yield takeLatest(VISA_DELETE_REQUESTED, deleteVisa);
}
