import { useState, useEffect } from "react";
import moment from "moment";

function TimeSpent({ value }) {
  const [timeDiff, setTimeDiff] = useState("");

  const tick = () => {
    const duration = moment.duration(moment().diff(value));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    let tDiff = `${minutes} minutes`;
    if (hours !== 0) {
      tDiff = `${hours} hour, ${tDiff}`;
    }
    setTimeDiff(tDiff);
  };

  useEffect(() => {
    tick();
    const interval = setInterval(() => {
      tick();
    }, 10000);
    return () => clearInterval(interval);
  });

  return timeDiff;
}

export default TimeSpent;
