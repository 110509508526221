import {
  AGREEMENT_GET_ALL_FAILED,
  AGREEMENT_GET_ALL_REQUESTED,
  AGREEMENT_GET_ALL_SUCCEEDED,
  AGREEMENT_UPDATE_FAILED,
  AGREEMENT_UPDATE_REQUESTED,
  AGREEMENT_UPDATE_SUCCEEDED,
  AGREEMENT_CREATE_FAILED,
  AGREEMENT_CREATE_REQUESTED,
  AGREEMENT_CREATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  agreementCreateEditSuccess: false,
  agreementCreateEditFetching: false,
  agreementCreateEditFailure: false,

  agreementsGetAllSuccess: false,
  agreementsGetAllFetching: false,
  agreementsGetAllFailure: false,
  errorMsg: "",
};

const defaultState = {
  agreements: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case AGREEMENT_GET_ALL_REQUESTED:
      return {
        ...state,
        agreementsGetAllFetching: true,
        agreementsGetAllFailure: false,
        agreementsGetAllSuccess: false,
      };
    case AGREEMENT_GET_ALL_FAILED:
      return {
        ...state,
        agreementsGetAllFetching: false,
        agreementsGetAllFailure: true,
        agreementsGetAllSuccess: false,
        errorMsg: action.payload.message || "",
      };
    case AGREEMENT_GET_ALL_SUCCEEDED:
      return {
        ...state,
        agreementsGetAllFetching: false,
        agreementsGetAllFailure: false,
        agreementsGetAllSuccess: true,
        agreements: action.payload,
      };

    case AGREEMENT_CREATE_REQUESTED:
      return {
        ...state,
        agreementCreateEditFetching: true,
        agreementCreateEditFailure: false,
        agreementCreateEditSuccess: false,
      };
    case AGREEMENT_CREATE_FAILED:
      return {
        ...state,
        agreementCreateEditFetching: false,
        agreementCreateEditFailure: true,
        agreementCreateEditSuccess: false,
        errorMsg: action.payload.message || "",
      };
    case AGREEMENT_CREATE_SUCCEEDED:
      return {
        ...state,
        agreementCreateEditFetching: false,
        agreementCreateEditFailure: false,
        agreementCreateEditSuccess: true,
      };

    case AGREEMENT_UPDATE_REQUESTED:
      return {
        ...state,
        agreementCreateEditFetching: true,
        agreementCreateEditFailure: false,
        agreementCreateEditSuccess: false,
      };
    case AGREEMENT_UPDATE_FAILED:
      return {
        ...state,
        agreementCreateEditFetching: false,
        agreementCreateEditFailure: true,
        agreementCreateEditSuccess: false,
        errorMsg: action.payload.message || "",
      };
    case AGREEMENT_UPDATE_SUCCEEDED:
      return {
        ...state,
        agreementCreateEditFetching: false,
        agreementCreateEditFailure: false,
        agreementCreateEditSuccess: true,
      };
    default:
      return state;
  }
}
