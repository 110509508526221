import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  CUSTOM_SMS_REQUESTED,
  CUSTOM_SMS_FAILED,
  CUSTOM_SMS_SUCCEEDED,
  CUSTOM_EMAIL_REQUESTED,
  CUSTOM_EMAIL_FAILED,
  CUSTOM_EMAIL_SUCCEEDED,
  TEMPLATE_EMAIL_REQUESTED,
  TEMPLATE_EMAIL_SUCCEEDED,
  TEMPLATE_EMAIL_FAILED,
  TEMPLATE_SMS_REQUESTED,
  TEMPLATE_SMS_FAILED,
  TEMPLATE_SMS_SUCCEEDED,
} from "../actions";

import { getToken } from "./selectors";

function* sendCustomEmail(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    yield call(remoteResource.customEmail, payload, token);
    yield put({
      type: CUSTOM_EMAIL_SUCCEEDED,
    });
  } catch (e) {
    yield put({ type: CUSTOM_EMAIL_FAILED });
  }
}

function* sendCustomSms(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    yield call(remoteResource.customSMS, payload, token);
    yield put({
      type: CUSTOM_SMS_SUCCEEDED,
    });
  } catch (e) {
    yield put({ type: CUSTOM_SMS_FAILED });
  }
}

function* sendTemplateEmail(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    yield call(remoteResource.templateEmail, payload, token);
    yield put({
      type: TEMPLATE_EMAIL_SUCCEEDED,
    });
  } catch (e) {
    yield put({ type: TEMPLATE_EMAIL_FAILED });
  }
}

function* sendTemplateSMS(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    yield call(remoteResource.templateSMS, payload, token);
    yield put({
      type: TEMPLATE_SMS_SUCCEEDED,
    });
  } catch (e) {
    yield put({ type: TEMPLATE_SMS_FAILED });
  }
}

export function* watchSmsEmail() {
  yield takeLatest(CUSTOM_EMAIL_REQUESTED, sendCustomEmail);
  yield takeLatest(CUSTOM_SMS_REQUESTED, sendCustomSms);
  yield takeLatest(TEMPLATE_EMAIL_REQUESTED, sendTemplateEmail);
  yield takeLatest(TEMPLATE_SMS_REQUESTED, sendTemplateSMS);
}
