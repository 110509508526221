import {
  APPOINTMENT_CREATE_REQUESTED,
  APPOINTMENT_CREATE_FAILED,
  APPOINTMENT_CREATE_SUCCEEDED,
  APPOINTMENT_GET_ALL_REQUESTED,
  APPOINTMENT_GET_ALL_FAILED,
  APPOINTMENT_GET_ALL_SUCCEEDED,
  APPOINTMENT_GET_REQUESTED,
  APPOINTMENT_GET_FAILED,
  APPOINTMENT_GET_SUCCEEDED,
  APPOINTMENT_MY_GET_REQUESTED,
  APPOINTMENT_MY_GET_FAILED,
  APPOINTMENT_MY_GET_SUCCEEDED,
  APPOINTMENT_UPDATE_REQUESTED,
  APPOINTMENT_UPDATE_FAILED,
  APPOINTMENT_UPDATE_SUCCEEDED,
  APPOINTMENT_DELETE_REQUESTED,
  APPOINTMENT_DELETE_FAILED,
  APPOINTMENT_DELETE_SUCCEEDED,
  APPOINTMENT_USER_GET_FAILED,
  APPOINTMENT_USER_GET_REQUESTED,
  APPOINTMENT_USER_GET_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  appointmentCreateError: false,
  appointmentCreateFetching: false,
  appointmentCreateSuccess: false,

  appointmentUpdateError: false,
  appointmentUpdateFetching: false,
  appointmentUpdateSuccess: false,

  appointmentGetError: false,
  appointmentGetFetching: false,
  appointmentGetSuccess: false,

  appointmentGetMyError: false,
  appointmentGetMyFetching: false,
  appointmentGetMySuccess: false,

  appointmentGetAllError: false,
  appointmentGetAllFetching: false,
  appointmentGetAllSuccess: false,

  appointmentDeleteError: false,
  appointmentDeleteFetching: false,
  appointmentDeleteSuccess: false,

  appointmentUserGetError: false,
  appointmentUserGetFetching: false,
  appointmentUserGetSuccess: false,
  errorMsg: "",
};

const defaultState = {
  appointments: [],
  appointmentUsers: [],
  myAppointments: [],
  appointmentDetail: {},
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case APPOINTMENT_CREATE_REQUESTED:
      return {
        ...state,
        appointmentCreateFetching: true,
        appointmentCreateError: false,
        appointmentCreateSuccess: false,
      };
    case APPOINTMENT_CREATE_FAILED:
      return {
        ...state,
        appointmentCreateFetching: false,
        appointmentCreateError: true,
        appointmentCreateSuccess: false,
        errorMsg: action.payload,
      };
    case APPOINTMENT_CREATE_SUCCEEDED:
      return {
        ...state,
        appointmentDetail: action.payload,
        appointmentCreateFetching: false,
        appointmentCreateError: false,
        appointmentCreateSuccess: true,
      };

    case APPOINTMENT_UPDATE_REQUESTED:
      return {
        ...state,
        appointmentUpdateFetching: true,
        appointmentUpdateError: false,
        appointmentUpdateSuccess: false,
      };
    case APPOINTMENT_UPDATE_FAILED:
      return {
        ...state,
        appointmentUpdateFetching: false,
        appointmentUpdateError: true,
        appointmentUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case APPOINTMENT_UPDATE_SUCCEEDED:
      return {
        ...state,
        appointmentDetail: action.payload,
        appointmentUpdateFetching: false,
        appointmentUpdateError: false,
        appointmentUpdateSuccess: true,
      };

    case APPOINTMENT_GET_REQUESTED:
      return {
        ...state,
        appointmentGetFetching: true,
        appointmentGetError: false,
      };
    case APPOINTMENT_GET_FAILED:
      return {
        ...state,
        appointmentGetFetching: false,
        appointmentGetError: true,
      };
    case APPOINTMENT_GET_SUCCEEDED:
      return {
        ...state,
        appointmentDetail: action.payload,
        appointmentGetFetching: false,
        appointmentGetError: false,
      };

    case APPOINTMENT_MY_GET_REQUESTED:
      return {
        ...state,
        appointmentGetMyFetching: true,
        appointmentGetMyError: false,
        appointmentGetMySuccess: false,
      };
    case APPOINTMENT_MY_GET_FAILED:
      return {
        ...state,
        appointmentGetMyFetching: false,
        appointmentGetMyError: true,
        appointmentGetMySuccess: false,
      };
    case APPOINTMENT_MY_GET_SUCCEEDED:
      return {
        ...state,
        myAppointments: action.payload,
        appointmentGetMyFetching: false,
        appointmentGetMyError: false,
        appointmentGetMySuccess: true,
      };

    case APPOINTMENT_GET_ALL_REQUESTED:
      return {
        ...state,
        appointmentGetAllFetching: true,
        appointmentGetAllError: false,
      };
    case APPOINTMENT_GET_ALL_FAILED:
      return {
        ...state,
        appointmentGetAllFetching: false,
        appointmentGetAllError: true,
      };
    case APPOINTMENT_GET_ALL_SUCCEEDED:
      return {
        ...state,
        appointments: action.payload,
        appointmentGetAllFetching: false,
        appointmentGetAllError: false,
      };

    case APPOINTMENT_DELETE_REQUESTED:
      return {
        ...state,
        appointmentDeleteFetching: true,
        appointmentDeleteError: false,
        appointmentDeleteSuccess: false,
      };
    case APPOINTMENT_DELETE_FAILED:
      return {
        ...state,
        appointmentDeleteFetching: false,
        appointmentDeleteError: true,
        appointmentDeleteSuccess: false,
      };
    case APPOINTMENT_DELETE_SUCCEEDED:
      return {
        ...state,
        appointmentDeleteFetching: false,
        appointmentDeleteError: false,
        appointmentDeleteSuccess: true,
      };

    case APPOINTMENT_USER_GET_REQUESTED:
      return {
        ...state,
        appointmentUserGetError: false,
        appointmentUserGetFetching: true,
        appointmentUserGetSuccess: false,
      };
    case APPOINTMENT_USER_GET_FAILED:
      return {
        ...state,
        appointmentUserGetError: true,
        appointmentUserGetFetching: false,
        appointmentUserGetSuccess: false,
        errorMsg: action.payload,
      };
    case APPOINTMENT_USER_GET_SUCCEEDED:
      return {
        ...state,
        appointmentUsers: action.payload,
        appointmentUserGetError: false,
        appointmentUserGetFetching: false,
        appointmentUserGetSuccess: true,
      };

    default:
      return state;
  }
}
