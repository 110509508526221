import React, { useEffect } from "react";

// core components
import Wizard from "components/Wizard/Wizard.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { PUBLIC_LEAD_CREATE_REQUESTED } from "../../../redux/actions";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import HBGInfo from "../../../components/HBGInfo/ContactInfo";
import ReactGA from "react-ga4";

import ThankYou from "../../../components/HBGInfo/Thankyou";
import {
  BRANCH_NAME,
  CLIENT_SOURCE_WEBSITE,
  COMPANY_NAME,
  LOGO_HORIZONTAL,
} from "../../../scripts/const";

const useStyles = makeStyles(styles);

export default function WizardView() {
  const dispatch = useDispatch();
  const saveError = useSelector((state) => state.public.publicLeadCreateError);
  const saveSuccess = useSelector(
    (state) => state.public.publicLeadCreateSuccess
  );
  const saveProgress = useSelector(
    (state) => state.public.publicLeadCreateFetching
  );
  const errorMsg = useSelector((state) => state.public.errorMsg);

  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(false);

  useEffect(() => {
    setError(saveError);
  }, [saveError]);

  useEffect(() => {
    setSuccess(saveSuccess);
  }, [saveSuccess]);

  useEffect(() => {
    setErrMsg(errorMsg);
  }, [errorMsg]);

  useEffect(() => {
    setProgress(saveProgress);
  }, [saveProgress]);

  function createLeadPayload(leadObj) {
    const obj = {};
    const { account, about } = leadObj;
    obj.firstName = about.firstname;
    obj.lastName = about.lastname;
    obj.email = about.email;
    obj.phone = about.phone;
    obj.note = about.note;
    const {
      education,
      visa,
      skillAssessment,
      healthInsurance,
      other,
      smsEmailConsent,
    } = account;
    obj.isConsent = smsEmailConsent;
    const services = [];
    if (education) {
      services.push("Education");
    }
    if (visa) {
      services.push("Visa");
    }
    if (skillAssessment) {
      services.push("Skill Assessment");
    }
    if (healthInsurance) {
      services.push("Health Insurance");
    }
    if (other) {
      services.push("Other");
    }
    obj.serviceType = JSON.stringify(services);
    obj.status = "New";
    obj.sourceId = CLIENT_SOURCE_WEBSITE;
    return obj;
  }
  function createLead(leadObj) {
    const {
      account: { captcha },
    } = leadObj;
    if (!captcha || captcha === "") {
      return;
    }
    const leadPayload = createLeadPayload(leadObj);
    dispatch({
      type: PUBLIC_LEAD_CREATE_REQUESTED,
      payload: leadPayload,
      captcha,
    });
    ReactGA.event({
      category: "Public-Lead",
      action: "Lead Signed Up",
    });
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Helmet>
        <title>Sign Up Now | {COMPANY_NAME}</title>
        <meta
          name="description"
          content="Sign up now. Student | Visa | Skill Assessment | Insurance services"
        />
        <meta name="og:title" content={`Sign Up for ${COMPANY_NAME}`} />
      </Helmet>
      <Card>
        <CardBody>
          <GridContainer justifyContent="center">
            <GridItem xs={4} sm={4}>
              <img className={classes.imgLogo} src={LOGO_HORIZONTAL} />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={8}>
              {success && <ThankYou />}
              {!success && (
                <Wizard
                  validate
                  steps={[
                    {
                      stepName: "About",
                      stepComponent: Step1,
                      stepId: "about",
                    },
                    {
                      stepName: "Choose Service",
                      stepComponent: Step2,
                      stepId: "account",
                    },
                  ]}
                  title={`Sign Up for ${COMPANY_NAME}. ${BRANCH_NAME}`}
                  subtitle="We will reach out to you shortly."
                  finishButtonClick={(e) => createLead(e)}
                />
              )}
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={8}>
              {progress && <CustomLinearProgress color="primary" />}
              {error && (
                <SnackbarContent message={`${errMsg}`} color="danger" />
              )}
            </GridItem>
          </GridContainer>
          <HBGInfo />
        </CardBody>
      </Card>
    </div>
  );
}
