import React from "react";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../CustomButtons/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Heading from "components/Heading/Heading.js";
import { connect } from "react-redux";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import { LOGOUT_REQUESTED } from "../../redux/actions";
import { clearSession } from "../../scripts/util";
import { NavLink } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const withTimer = (WrappedComponent) => {
  class WithTimer extends React.Component {
    constructor(props) {
      super(props);
      const expiryTime =
        this.props.loginUser && this.props.loginUser.expiry
          ? this.props.loginUser.expiry
          : "9999999999";
      const currentTime = moment().unix();
      const warnBefore = 5 * 60; // 5 min
      const warnTime = (expiryTime - currentTime - warnBefore) * 1000; // milisecond
      this.state = {
        warningTime: warnTime,
        showModal: false,
      };
      this.setTimeout();
    }

    setTimeout = () => {
      setTimeout(this.warn, this.state.warningTime);
    };

    warn = () => {
      this.setState({
        showModal: true,
      });
    };

    logOut = () => {
      clearSession();
      this.props.logOutMe();
    };

    render() {
      return (
        <>
          <Dialog
            open={this.state.showModal}
            TransitionComponent={Transition}
            onClose={() => this.onModalClick}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle id="classic-modal-slide-title" disableTypography>
              <Heading
                textAlign="center"
                title="Auth token is about to expire."
                category={<span>Please log out and log in.</span>}
              />
            </DialogTitle>
            <DialogContent id="classic-modal-slide-description">
              <GridContainer justifyContent="center">
                <GridItem>
                  {this.state.warningTime > 0 && (
                    <Button
                      color="rose"
                      onClick={() => {
                        this.setState({ showModal: false });
                      }}
                    >
                      Okay
                    </Button>
                  )}

                  <NavLink
                    to={"/auth"}
                    onClick={() => {
                      this.logOut();
                    }}
                  >
                    <Button color="rose">Log Out Now</Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </DialogContent>
          </Dialog>

          <WrappedComponent {...this.props} {...this.props.children} />
        </>
      );
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    logOutMe: () => {
      dispatch({ type: LOGOUT_REQUESTED });
    },
  });

  const mapStateToProps = (state) => {
    return {
      loginUser: state.login.loginUser,
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithTimer);
};

export default withTimer;
