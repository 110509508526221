import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridItem from "../../components/Grid/GridItem";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { useSelector } from "react-redux";
import GridContainer from "../../components/Grid/GridContainer";

import {
  getInsuranceApplicantData,
  getLeadData,
  getSkillAssessmentData,
  getStudentData,
  getVisaApplicantData,
} from "../../scripts/util";
import CardIcon from "../../components/Card/CardIcon";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import { Pie } from "react-chartjs-2";
import SchoolIcon from "@material-ui/icons/School";
import DescriptionIcon from "@material-ui/icons/Description";
import WorkIcon from "@material-ui/icons/Work";
import HospitalIcon from "@material-ui/icons/LocalHospital";
import {
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
const useStyles = makeStyles(styles);

const CountPieChart = () => {
  Chart.register(
    ArcElement,
    PointElement,
    LinearScale,
    CategoryScale,
    BarElement,
    Legend,
    LineElement
  );
  const classes = useStyles();

  const leads = useSelector((state) => state.lead.leads);
  const students = useSelector((state) => state.student.students);
  const visaApplicants = useSelector(
    (state) => state.visaApplicant.visaApplicants
  );

  const skillAssessments = useSelector(
    (state) => state.skillAssessment.skillAssessments
  );
  const insuranceApplicants = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicants
  );

  const insuranceApplicantDataForPie = useMemo(
    () => getInsuranceApplicantData(insuranceApplicants),
    [insuranceApplicants]
  );
  const skillAssessmentsDataForPie = useMemo(
    () => getSkillAssessmentData(skillAssessments),
    [skillAssessments]
  );
  const visaApplicantsDataForPie = useMemo(
    () => getVisaApplicantData(visaApplicants),
    [visaApplicants]
  );
  const studentDataForPie = useMemo(() => getStudentData(students), [students]);
  const leadDataForPie = useMemo(() => getLeadData(leads), [leads]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <ContactPhoneIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Leads <small>in last 6 months</small>
              </h4>
            </CardHeader>
            <CardBody>
              {leads.length !== 0 && <Pie data={leadDataForPie} />}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <SchoolIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Students <small>in last 6 months</small>
              </h4>
            </CardHeader>
            <CardBody>
              {students.length !== 0 && <Pie data={studentDataForPie} />}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <DescriptionIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Visa Applicants <small>in last 6 months</small>
              </h4>
            </CardHeader>
            <CardBody>
              {visaApplicants.length !== 0 && (
                <Pie data={visaApplicantsDataForPie} />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <WorkIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Skill Assessment <small>in last 6 months</small>
              </h4>
            </CardHeader>
            <CardBody>
              {skillAssessments.length !== 0 && (
                <Pie data={skillAssessmentsDataForPie} />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <HospitalIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Insurance Providers <small>in last 6 months</small>
              </h4>
            </CardHeader>
            <CardBody>
              {insuranceApplicants.length !== 0 && (
                <Pie data={insuranceApplicantDataForPie} />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default CountPieChart;
