import {
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCEEDED,
  FORGOT_PASSWORD_FAILED,
  PASSWORD_RESET_REQUESTED,
  PASSWORD_RESET_SUCCEEDED,
  PASSWORD_RESET_FAILED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  forgotPasswordError: false,
  forgotPasswordFetching: false,
  forgotPasswordSuccess: false,

  passwordResetError: false,
  passwordResetFetching: false,
  passwordResetSuccess: false,
  errorMsg: "",
};

const defaultState = {
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case FORGOT_PASSWORD_REQUESTED:
      return {
        ...state,
        forgotPasswordError: false,
        forgotPasswordFetching: true,
        forgotPasswordSuccess: false,
      };
    case FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        forgotPasswordError: false,
        forgotPasswordFetching: false,
        forgotPasswordSuccess: true,
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        forgotPasswordError: true,
        forgotPasswordFetching: false,
        forgotPasswordSuccess: false,
        errorMsg: action.payload,
      };

    case PASSWORD_RESET_REQUESTED:
      return {
        ...state,
        passwordResetError: false,
        passwordResetFetching: true,
        passwordResetSuccess: false,
      };
    case PASSWORD_RESET_SUCCEEDED:
      return {
        ...state,
        passwordResetError: false,
        passwordResetFetching: false,
        passwordResetSuccess: true,
      };
    case PASSWORD_RESET_FAILED:
      return {
        ...state,
        passwordResetError: true,
        passwordResetFetching: false,
        passwordResetSuccess: false,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
}
