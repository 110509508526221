const STEP_MAPPER = {
  lead: 0,
  education: 1,
  visa: 2,
  skill_assessment: 3,
  health_insurance: 4,
  tribunal_review: 5,
};

const STUDENT_STEPS = [
  {
    id: STEP_MAPPER.lead,
    label: "Lead",
    name: "lead",
  },
  {
    id: STEP_MAPPER.education,
    label: "Education",
    name: "education",
  },
  {
    id: STEP_MAPPER.visa,
    label: "Visa",
    name: "visa",
  },
  {
    id: STEP_MAPPER.skill_assessment,
    label: "Skill Assessment",
    name: "skill_assessment",
  },
  {
    id: STEP_MAPPER.health_insurance,
    label: "Health Insurance",
    name: "health_insurance",
  },
  {
    id: STEP_MAPPER.tribunal_review,
    label: "Tribunal Review",
    name: "tribunal_review",
  },
];

export { STEP_MAPPER, STUDENT_STEPS };
