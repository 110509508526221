import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  dialogContent: {
    fontSize: "16px",
    fontWeight: "400",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      // width: 300,
    },
  },
  imgLogo: {
    width: "100%",
    maxHeight: "100%",
  },
  imgLogoApt: {
    width: "100%",
    maxHeight: "100%",
  },
  aptUserInfo: {
    transition: "all .15s ease-out",
    display: "flex",
    marginBottom: "20px",
    gap: "10px",
    alignItems: "center",
    "@media (max-width: 400px)": {
      fontSize: "0.8rem",
    },
  },
  aptAvatar: {
    transition: "all .15s ease-out",
    height: "50px",
    width: "50px",
    "@media (max-width: 400px)": {
      height: "40px",
      width: "40px",
    },
  },
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
    },
  },
  resetPass: {
    fontStyle: "italic",
    fontFamily: "Dancing Script",
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  title: {
    marginLeft: "20px",
  },
  titleApt: {
    marginLeft: "20px",
    "@media (max-width: 768px)": {
      fontSize: "1.3rem",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
});

export default loginPageStyle;
