import React, { useEffect } from "react";

import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";

import {
  CLEAR_ERROR,
  COURSE_CREATE_REQUESTED,
  COURSE_GET_ALL_REQUESTED,
  COURSE_UPDATE_REQUESTED,
} from "../../../../redux/actions";
import { SUPER_ROLE } from "../../../../scripts/const";
import ReactGA from "react-ga4";

export default function Course() {
  const accessName = "courseManagement";

  const currentUser = useSelector((state) => state.login.loginUser.detail);
  const hasEditAccess =
    SUPER_ROLE.includes(currentUser.roleId) || currentUser[accessName];
  // Adding `_` on id to prevent auto sorting by browser
  const getColumns = (universities) => {
    const obj = {};
    for (let i = 0; i < universities.length; i++) {
      obj[`_${universities[i].id}`] = universities[i].name;
    }
    return [
      {
        title: "University *",
        field: "universityId",
        type: "numeric",
        lookup: obj,
        customSort: (a, b) => {
          let x = obj[a.universityId];
          let y = obj[b.universityId];
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        defaultSort: "asc",
      },
      {
        title: "Course Name *",
        field: "name",
        validate: (rowData) => Boolean(rowData.name),
      },
      { title: "Description", field: "description" },
      { title: "CreatedAt", field: "createdAt", editable: "never" },
      { title: "UpdatedAt", field: "updatedAt", editable: "never" },
    ];
  };

  const [data, setData] = React.useState([]);
  const courses = useSelector((state) => state.course.courses);
  const universities = useSelector((state) => state.university.universities);

  const saveSuccess = useSelector((state) => state.course.courseCreateSuccess);
  const updateSuccess = useSelector(
    (state) => state.course.courseUpdateSuccess
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    setData(courses.map((c) => ({ ...c, universityId: `_${c.universityId}` })));
  }, [courses, universities]);

  useEffect(() => {
    if (saveSuccess === true) {
      dispatch({ type: COURSE_GET_ALL_REQUESTED });
      dispatch({ type: CLEAR_ERROR });
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (updateSuccess === true) {
      dispatch({ type: COURSE_GET_ALL_REQUESTED });
      dispatch({ type: CLEAR_ERROR });
    }
  }, [updateSuccess]);

  return (
    <MaterialTable
      title="Course Setup"
      columns={getColumns(universities)}
      data={data}
      options={{
        exportButton: true,
        pageSize: 25,
        pageSizeOptions: [25, 50, 100],
        headerStyle: {
          backgroundColor: "#0077c2",
          color: "#FFF",
        },
        sorting: true,
      }}
      style={{
        marginTop: "30px",
      }}
      editable={
        hasEditAccess
          ? {
              onRowAdd: (newData) => {
                return new Promise((resolve) => {
                  if (newData.universityId) {
                    newData.universityId = newData.universityId.substring(1);
                  }
                  setTimeout(() => {
                    resolve();
                    dispatch({
                      type: COURSE_CREATE_REQUESTED,
                      payload: newData,
                    });
                    ReactGA.event({
                      category: "Course",
                      action: "Course Added",
                    });
                  }, 200);
                });
              },
              onRowUpdate: (newData) =>
                new Promise((resolve) => {
                  if (newData.universityId) {
                    newData.universityId = newData.universityId.substring(1);
                  }
                  setTimeout(() => {
                    resolve();
                    dispatch({
                      type: COURSE_UPDATE_REQUESTED,
                      payload: { payload: newData, id: newData.id },
                    });
                    ReactGA.event({
                      category: "Course",
                      action: "Course Updated",
                    });
                  }, 200);
                }),
            }
          : {}
      }
    />
  );
}
