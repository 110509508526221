/*eslint-disable*/
import React, { lazy, Suspense } from 'react';

// core components
import Heading from "components/Heading/Heading.js";

const CustomerFlow = lazy(() => import('./CustomerFlow'));
const ClientCounts = lazy(() => import('./ClientCounts'));

import withTimer from '../../components/Timer/withTimer';
import { BRANCH_NAME, COMPANY_NAME } from '../../scripts/const';
import CountBarChart from './CountBarChart';
import CountPieChart from './CountPieChart';
import InsightsBarChart from './InsightsBarChart';
import { useMediaQuery } from 'react-responsive';

const Dashboard = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <div>
      <Heading
        textAlign="center"
        category=""
        title={`${COMPANY_NAME} - ${BRANCH_NAME}`}
      />
        <div>
            <Suspense fallback={<></>}>
              <ClientCounts />
            </Suspense>
            {!isTabletOrMobile &&
              (<Suspense fallback={<></>}>
                <CustomerFlow />
              </Suspense>)
            }
            {!isTabletOrMobile &&
              <Suspense fallback={<></>}>
                <CountBarChart />
              </Suspense>
            }
            <Suspense fallback={<></>}>
              <CountPieChart />
            </Suspense>
            {!isTabletOrMobile &&
            <Suspense fallback={<></>}>
              <InsightsBarChart />
            </Suspense>
            }
        </div>
    </div>
  );
}

export default withTimer(Dashboard);
