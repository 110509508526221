import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  LEAVE_CREATE_REQUESTED,
  LEAVE_CREATE_FAILED,
  LEAVE_CREATE_SUCCEEDED,
  LEAVE_GET_ALL_REQUESTED,
  LEAVE_GET_ALL_FAILED,
  LEAVE_GET_ALL_SUCCEEDED,
  LEAVE_UPDATE_REQUESTED,
  LEAVE_UPDATE_FAILED,
  LEAVE_UPDATE_SUCCEEDED,
  LEAVE_GET_USER_REQUESTED,
  LEAVE_GET_USER_FAILED,
  LEAVE_GET_USER_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchAllLeaves() {
  try {
    const token = yield select(getToken);
    const leaves = yield call(remoteResource.getAllLeaves, token);
    yield put({
      type: LEAVE_GET_ALL_SUCCEEDED,
      payload: leaves.data,
    });
  } catch (e) {
    yield put({ type: LEAVE_GET_ALL_FAILED, payload: e.message });
  }
}

function* fetchAllUserLeaves(action) {
  try {
    const token = yield select(getToken);
    const { userId } = action.payload;
    const leaves = yield call(remoteResource.getAllUserLeaves, userId, token);
    yield put({
      type: LEAVE_GET_USER_SUCCEEDED,
      payload: leaves.data,
    });
  } catch (e) {
    yield put({ type: LEAVE_GET_USER_FAILED, payload: e.message });
  }
}

function* createLeave(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const leave = yield call(remoteResource.createLeave, payload, token);
    yield put({
      type: LEAVE_CREATE_SUCCEEDED,
      payload: leave.data,
    });
  } catch (e) {
    yield put({ type: LEAVE_CREATE_FAILED, payload: e.message });
  }
}

function* updateLeave(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const leave = yield call(remoteResource.updateLeave, id, payload, token);
    yield put({
      type: LEAVE_UPDATE_SUCCEEDED,
      payload: leave.data,
    });
  } catch (e) {
    yield put({ type: LEAVE_UPDATE_FAILED, payload: e.message });
  }
}

export function* watchLeave() {
  yield takeLatest(LEAVE_CREATE_REQUESTED, createLeave);
  yield takeLatest(LEAVE_UPDATE_REQUESTED, updateLeave);
  yield takeLatest(LEAVE_GET_ALL_REQUESTED, fetchAllLeaves);
  yield takeLatest(LEAVE_GET_USER_REQUESTED, fetchAllUserLeaves);
}
