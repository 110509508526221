import {
  defaultFont,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";

const notificationsStyle = (theme) => ({
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: grayColor[2],
    fontSize: "18px",
  },
  backIcon: {
    marginTop: "-60px",
    paddingLeft: "15px",
  },
  employeeDetail: {},
  topMargin: {
    marginTop: "30px",
  },
  selectAction: {
    marginLeft: "20px",
    flexDirection: "row",
  },
  floatLeft: {
    float: "left",
  },
  marginLeft: {
    marginLeft: "20px",
  },
  actionH5: {
    fontFamily: "Merriweather",
  },
  cardHeader: {
    zIndex: "3",
  },
  cardSubtitle: {
    ...defaultFont,
    color: grayColor[0],
    fontSize: "14px",
    margin: "0 0 10px",
  },
  center: {
    textAlign: "center",
  },
  moveToRight: {
    float: "right",
  },
  "@media screen and (min-width: 800px)": {
    modalMedium: {
      minWidth: "600px",
    },
    modalMediumXL: {
      minWidth: "800px",
    },
    modalXL: {
      minWidth: "900px",
    },
  },
  "@media screen and (max-width: 800px)": {
    modalMedium: {
      maxWidth: "80%",
    },
    modalMediumXL: {
      minWidth: "90%",
    },
    modalXL: {
      maxWidth: "95%",
    },
  },
  right: {
    textAlign: "right",
  },
  left: {
    textAlign: "left",
  },
  marginRight: {
    marginRight: "5px",
  },
  modalSectionTitle: {
    marginTop: "30px",
  },
  ...modalStyle(theme),
});

export default notificationsStyle;
