import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ReactGA from "react-ga4";

import styles from "../../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import { useDispatch, useSelector } from "react-redux";
import { convertDate, getUserName } from "../../../../scripts/util";
import {
  CLEAR_ERROR,
  COURSE_GET_ALL_REQUESTED,
  COURSE_BULK_CREATE_REQUESTED,
  UNIVERSITY_UPDATE_REQUESTED,
} from "../../../../redux/actions";
import SnackbarContent from "../../../../components/Snackbar/SnackbarContent";
import CustomLinearProgress from "../../../../components/CustomLinearProgress/CustomLinearProgress";
import { useFormik } from "formik";
import * as Yup from "yup";
import Muted from "../../../../components/Typography/Muted";
import ReactSelect from "react-select";
import { isEmpty } from "lodash";
import FileUpload from "../../../../components/CustomUpload/FileUpload";

const useStyles = makeStyles(styles);

export default function UniversityContainer({ open, setOpen, editData = {} }) {
  const initialValues = {
    id: "",
    name: "",
    description: "",
    comment: "",
    educationLevel: "",
    location: "",
    courses: [],
    files: [],
    updatedBy: "",
    updatedAt: "",
    createdAt: "",
  };

  const dispatch = useDispatch();
  const classes = useStyles();
  const courses = useSelector((state) => state.course.courses);
  const allUsers = useSelector((state) => state.user.users);
  const [availableCourses, setAvailableCourses] = useState([]);
  const [courseOptions, setCoursesOptions] = useState([]);

  const updateSuccess = useSelector(
    (state) => state.course.courseBulkCreateSuccess
  );

  const updateProgress = useSelector(
    (state) => state.course.courseBulkCreateFetching
  );

  const updateError = useSelector(
    (state) => state.course.courseBulkCreateError
  );

  useEffect(() => {
    if (courses && courses.length > 0 && !isEmpty(editData)) {
      const availableCourses = courses
        .filter((c) => c.universityId == editData.id)
        .map((m) => m.name);

      const availableCoursesOptions = availableCourses.map((name) => ({
        value: name,
        label: name,
      }));

      setAvailableCourses(availableCoursesOptions);

      const allCourse = courses
        .map((m) => m.name)
        .filter((name) => !availableCourses.includes(name));
      const uniqueCourses = [...new Set(allCourse)];
      const uniqueCourseOptions = uniqueCourses.map((name) => ({
        value: name,
        label: name,
      }));
      setCoursesOptions(uniqueCourseOptions);
    }
  }, [courses, editData]);

  useEffect(() => {
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    if (Object.keys(editData).length > 0 && open === true) {
      for (let field of Object.keys(initialValues)) {
        if (editData[field] !== null) {
          setFieldValue(field, editData[field], false);
          setFieldValue("courses", []);
        }
      }
    }
  }, [editData, open]);

  useEffect(() => {
    if (updateSuccess === true) {
      setFieldValue("courses", []);
      dispatch({ type: COURSE_GET_ALL_REQUESTED });
      setTimeout(() => {
        dispatch({ type: CLEAR_ERROR });
      }, 1200);
    }
    if (open === false) {
      resetForm(initialValues);
    }
  }, [updateSuccess, open]);

  // Custom Validation
  const validate = (values) => {
    const errors = {};
    // if (values.status === "Other" && !values.otherStatus) {
    //   errors.otherStatus = "Other Status is required";
    // }
    //
    // if (values.startDate && values.startDate !== "") {
    //   if (!isValidDate(values.startDate)) {
    //     errors.startDate = "Start Date is Invalid";
    //   }
    // }
    //
    // if (values.endDate && values.endDate !== "") {
    //   if (!isValidDate(values.endDate)) {
    //     errors.endDate = "End Date is Invalid";
    //   }
    // }
    return errors;
  };

  const onSubmit = (values) => {
    const payload = { ...values };
    const payloadCopy = { ...values };
    delete payloadCopy["courses"];
    dispatch({
      type: UNIVERSITY_UPDATE_REQUESTED,
      payload: { payload: payloadCopy, id: payloadCopy.id },
    });
    payload.courses = payload.courses.map((m) => ({
      name: m.value,
    }));
    payload.universityId = payload.id;
    dispatch({ type: COURSE_BULK_CREATE_REQUESTED, payload });
    ReactGA.event({
      category: "University",
      action: "Bulk add courses",
    });
  };

  const mySchema = () => {
    return Yup.object({
      id: Yup.number(),
      name: Yup.string(),
      description: Yup.string(),
      comment: Yup.string(),
      educationLevel: Yup.string(),
      location: Yup.string(),
      courses: Yup.array(),
      updatedBy: Yup.string(),
      updatedAt: Yup.string(),
      createdAt: Yup.string(),
    });
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
    submitForm,
  } = useFormik({
    initialValues,
    validationSchema: mySchema(),
    validate,
    onSubmit,
  });

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {updateError && (
                    <SnackbarContent
                      message={"Something went wrong."}
                      color="warning"
                    />
                  )}
                  {updateProgress && <CustomLinearProgress color="primary" />}
                  {updateSuccess && (
                    <SnackbarContent message="Success" color="success" />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={!!(touched.name && errors.name)}
                    helperText={touched.name && errors.name ? errors.name : ""}
                    labelText="University"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onBlur: handleBlur,
                      value: values.name,
                      disabled: true,
                      onChange: handleChange,
                      name: "name",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={!!(touched.educationLevel && errors.educationLevel)}
                    helperText={
                      touched.educationLevel && errors.educationLevel
                        ? errors.educationLevel
                        : ""
                    }
                    labelText="Education Level"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onBlur: handleBlur,
                      value: values.educationLevel,
                      disabled: true,
                      onChange: handleChange,
                      name: "educationLevel",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={!!(touched.location && errors.location)}
                    helperText={
                      touched.location && errors.location ? errors.location : ""
                    }
                    labelText="Location"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onBlur: handleBlur,
                      value: values.location,
                      onChange: handleChange,
                      name: "location",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={!!(touched.description && errors.description)}
                    helperText={
                      touched.description && errors.description
                        ? errors.description
                        : ""
                    }
                    labelText="Description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onBlur: handleBlur,
                      value: values.description,
                      onChange: handleChange,
                      name: "description",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <CustomInput
                    error={!!(touched.comment && errors.comment)}
                    helperText={
                      touched.comment && errors.comment ? errors.comment : ""
                    }
                    labelText="Comment"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 3,
                      onBlur: handleBlur,
                      value: values.comment,
                      // disabled: true,
                      onChange: handleChange,
                      name: "comment",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Available Courses:</h4>
                  <ReactSelect
                    isDisabled
                    value={availableCourses}
                    isMulti
                    name="courses"
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </GridItem>
              </GridContainer>
              <br />
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Add Courses:</h4>
                  <ReactSelect
                    closeMenuOnSelect={false}
                    isMulti
                    name="courses"
                    options={courseOptions}
                    value={values.courses}
                    onChange={(value) => setFieldValue("courses", value)}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                {values.files.map((fileUrl, id) => {
                  return (
                    <GridItem key={id}>
                      <div
                        style={{
                          flexDirection: "column",
                          display: "flex",
                          backgroundColor: "#B0BEC5",
                          padding: "20px",
                          borderRadius: "25px",
                          marginTop: "10px",
                        }}
                      >
                        <h4>File:</h4>
                        <FileUpload
                          fileUrl={fileUrl}
                          key={id}
                          open={open}
                          handleDelete={(fileUrl) => {
                            setFieldValue(
                              "files",
                              values.files.filter((f) => f != fileUrl)
                            );
                          }}
                        />
                      </div>
                    </GridItem>
                  );
                })}
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      open={open}
                      setFileUrl={(fileUrl) =>
                        setFieldValue("files", [...values.files, fileUrl])
                      }
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <br />
              <br />
              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={submitForm}
              >
                {"Update"}
              </Button>
              <>
                <Muted>Updated At: {convertDate(values.updatedAt)}</Muted>
                <Muted>
                  Updated By: {getUserName(values.updatedBy, allUsers)}
                </Muted>
              </>
              {alert}
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
