import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import SweetAlert from "react-bootstrap-sweetalert";
import { default as ReactSelect } from "react-select";
import StepProgressBar from "react-step-progress";
import {
  format,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "../../../components/Clearfix/Clearfix";
import InputLabel from "@material-ui/core/InputLabel";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles2 from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_ERROR,
  LEAD_GET_ALL_REQUESTED,
  LEAD_UPDATE_REQUESTED,
  STUDENT_CREATE_REQUESTED,
  VISA_APPLICANT_CREATE_REQUESTED,
  SKILL_ASSESSMENT_CREATE_REQUESTED,
  INSURANCE_APPLICANT_CREATE_REQUESTED,
  STUDENT_GET_ALL_REQUESTED,
  INSURANCE_APPLICANT_GET_ALL_REQUESTED,
  VISA_APPLICANT_GET_ALL_REQUESTED,
  SKILL_ASSESSMENT_GET_ALL_REQUESTED,
  LEAD_LOG_GET_REQUESTED,
  LEAD_DELETE_REQUESTED,
  LEAD_GET_REQUESTED,
} from "../../../redux/actions";
import {
  convertDate,
  getAnzsco,
  getDropDown,
  getInsuranceProviderMapping,
  getInsuranceTypeMapping,
  getOccupation,
  getUserName,
  isValidLeadInputs,
} from "../../../scripts/util";
import Muted from "../../../components/Typography/Muted";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import ReactGA from "react-ga4";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  DEFAULT_DATE_FORMAT,
  GENERAL_ROLE,
  ROLES,
  locationOption,
  occupationCodeOption,
  SUPER_ADMIN_ROLES,
  useGetOccupationOptions,
  useGetVisaTypeOptions,
  TINY_INIT,
} from "../../../scripts/const";
import Datetime from "react-datetime";
import moment from "moment";
import { Link } from "react-router-dom";
import ConfirmationAlert from "../../../components/Alert/ConfirmationAlert";
import { Editor } from "@tinymce/tinymce-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import DropDownWithSearch, {
  customReactSelectStyles,
} from "../../../components/DropDownWithSearch/DropDownWithSearch";
import { STUDENT_STEPS, STEP_MAPPER } from "variables/student";
import LocationAutoComplete from "components/LocationAutocomplete/LocationAutocomplete";
import FileUpload from "../../../components/CustomUpload/FileUpload";

const localStyle = {
  ...styles,
  ...styles2,
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
  moveToRight: {
    float: "right",
  },
  marginAll: {
    marginTop: "30px",
  },
};

const useStyles = makeStyles(localStyle);

const getFormatDate = (dateString) => {
  return format(new Date(dateString), "yyyy-MM-dd HH:mm");
};

export default function Panels(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const occupationOption = useGetOccupationOptions();
  const visaTypeOption = useGetVisaTypeOptions();

  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);
  const isLeadGenerator = ROLES.LEAD_MANAGEMENT === currentRoleId;

  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );
  const [entityError, setEntityError] = React.useState(false);
  const [transitionProgress, setTransitionProgress] = React.useState(false);

  const [leadId, setLeadId] = React.useState(false);
  const [validationErrMsg, setValidationErrMsg] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [lead, setLead] = React.useState({});
  const [leadCopy, setLeadCopy] = React.useState({});
  const [leadLog, setLeadLog] = React.useState([]);
  const [isLog, setIsLog] = React.useState(false);
  const sLog = useSelector((state) => state.lead.leadLog);
  const leadDetail = useSelector((state) => state.lead.leadDetail);
  const leadDetailLoading = useSelector((state) => state.lead.leadGetFetching);
  const leadDetailSuccess = useSelector((state) => state.lead.leadGetSuccess);
  const [logId, setLogId] = React.useState(null);
  const handleFileUpload = (fileUrl) => {
    if (!lead.files) {
      setLead({
        ...lead,
        files: [fileUrl],
      });
    } else {
      setLead({
        ...lead,
        files: [...lead.files, fileUrl],
      });
    }
  };

  const handleFileDelete = (fileUrl) => {
    setLead({
      ...lead,
      files: lead.files.filter((f) => f != fileUrl),
    });
  };
  const students = useSelector((state) => state.student.students);
  const allUniversities = useSelector((state) => state.university.universities);

  const insuranceApplicants = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicants
  );
  const visaApplicants = useSelector(
    (state) => state.visaApplicant.visaApplicants
  );
  const skillAssessments = useSelector(
    (state) => state.skillAssessment.skillAssessments
  );

  function timeBetweenDates(date1, date2) {
    const days = differenceInCalendarDays(new Date(date2), new Date(date1));
    const hours = differenceInHours(new Date(date2), new Date(date1)) % 24;
    const minutes = differenceInMinutes(new Date(date2), new Date(date1)) % 60;

    return `${days} days, ${hours} hours`;
    // return { days, hours, minutes };
  }

  const getLeadStepStatus = (data) => {
    // add created_at date to STUDENT_STEPS constant
    const students_steps = STUDENT_STEPS.map((step) => {
      if (data?.id && step.id === STEP_MAPPER.lead) {
        step.subtitle = getFormatDate(data.createdAt);
      }
      if (data?.student && step.id === STEP_MAPPER.education) {
        step.subtitle =
          timeBetweenDates(data.createdAt, data.student.createdAt) ||
          getFormatDate(data.student.createdAt);
      }
      if (data?.visaApplicant && step.id === STEP_MAPPER.visa) {
        step.subtitle =
          timeBetweenDates(data.createdAt, data.visaApplicant.createdAt) ||
          getFormatDate(data.visaApplicant.createdAt);
      }
      if (data?.skillAssessment && step.id === STEP_MAPPER.skill_assessment) {
        step.subtitle =
          timeBetweenDates(data.createdAt, data.skillAssessment.createdAt) ||
          getFormatDate(data.skillAssessment.createdAt);
      }
      if (
        data?.insuranceApplicant &&
        step.id === STEP_MAPPER.health_insurance
      ) {
        step.subtitle =
          timeBetweenDates(data.createdAt, data.insuranceApplicant.createdAt) ||
          getFormatDate(data.insuranceApplicant.createdAt);
      }

      return step;
    });

    let step = STEP_MAPPER.lead;
    if (data?.insuranceApplicant) {
      step = STEP_MAPPER.health_insurance;
    } else if (data?.skillAssessment) {
      step = STEP_MAPPER.skill_assessment;
    } else if (data?.visaApplicant) {
      step = STEP_MAPPER.visa;
    } else if (data?.student) {
      step = STEP_MAPPER.education;
    }

    return { students_steps, step };
  };

  const currentStep = getLeadStepStatus(lead);

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setLead({
        ...lead,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setLead({
        ...lead,
        [name]: date,
      });
    }
  };

  const handleLog = (event) => {
    if (isLog) {
      setLead(leadCopy);
    } else {
      ReactGA.event({
        category: "ViewHistory",
        action: "View Lead History",
      });
      setLeadCopy(lead);
    }
    if (!isLog && leadLog.length === 0) {
      dispatch({ type: LEAD_LOG_GET_REQUESTED, payload: leadId });
    }
    setIsLog(!isLog);
  };

  useEffect(() => {
    setLeadLog(sLog);
  }, [sLog]);

  useEffect(() => {
    if (leadDetail.id === leadId) {
      setLead(leadDetail);
    }
  }, [leadDetail]); //currentstep effecting onchange

  const handleSelect = (e) => {
    if (e.target.name == "serviceType") {
      setLead({
        ...lead,
        [e.target.name]: JSON.stringify(e.target.value),
      });
    } else {
      setLead({
        ...lead,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleEditorChange = (content) => {
    setLead({
      ...lead,
      note: content,
    });
  };

  const dispatch = useDispatch();

  const editError = useSelector((state) => state.lead.leadUpdateError);
  const errMsg = useSelector((state) => state.lead.errorMsg);
  const editProgress = useSelector((state) => state.lead.leadUpdateFetching);
  const editSuccess = useSelector((state) => state.lead.leadUpdateSuccess);
  const deleteProgress = useSelector((state) => state.lead.leadDeleteFetching);
  const deleteSuccess = useSelector((state) => state.lead.leadDeleteSuccess);
  const studentCreateError = useSelector(
    (state) => state.student.studentCreateError
  );
  const studentCreateProgress = useSelector(
    (state) => state.student.studentCreateFetching
  );
  const visApplicantCreateProgress = useSelector(
    (state) => state.visaApplicant.visaApplicantCreateFetching
  );
  const skillAssessmentCreateProgress = useSelector(
    (state) => state.skillAssessment.skillAssessmentCreateFetching
  );
  const insuranceApplicantCreateProgress = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantCreateFetching
  );

  const visaApplicantCreateError = useSelector(
    (state) => state.visaApplicant.visaApplicantCreateError
  );
  const skillAssessmentCreateError = useSelector(
    (state) => state.skillAssessment.skillAssessmentCreateError
  );
  const insuranceApplicantCreateError = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantCreateError
  );
  function clearErrors() {
    setValidationErrMsg("");
    setEntityError("");
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      clearErrors();
      setLead(leadCopy);
      setLeadLog([]);
      setIsLog(false);
      setLogId(null);
      dispatch({ type: CLEAR_ERROR });
      hideAlert();
    } else {
      if (leadId) {
        dispatch({ type: LEAD_GET_REQUESTED, payload: { id: leadId } });
      }
    }
  }, [modalOpen]);

  useEffect(() => {
    setValidationErrMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    if (leadDetail.id !== props.lead.id) {
      setLead(props.lead);
      setLeadCopy(props.lead);
    }
    setLeadId(props.lead.id);
  }, [props.lead, props.open]);

  useEffect(() => {
    setError(editError);
  }, [editError]);

  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);

  useEffect(() => {
    setTransitionProgress(studentCreateProgress);
  }, [studentCreateProgress]);

  useEffect(() => {
    setTransitionProgress(visApplicantCreateProgress);
  }, [visApplicantCreateProgress]);

  useEffect(() => {
    setTransitionProgress(skillAssessmentCreateProgress);
  }, [skillAssessmentCreateProgress]);

  useEffect(() => {
    setTransitionProgress(insuranceApplicantCreateProgress);
  }, [insuranceApplicantCreateProgress]);

  useEffect(() => {
    setEntityError(studentCreateError);
  }, [studentCreateError]);

  useEffect(() => {
    setEntityError(insuranceApplicantCreateError);
  }, [insuranceApplicantCreateError]);

  useEffect(() => {
    setEntityError(visaApplicantCreateError);
  }, [visaApplicantCreateError]);

  useEffect(() => {
    setEntityError(skillAssessmentCreateError);
  }, [skillAssessmentCreateError]);

  useEffect(() => {
    setSuccess(editSuccess);
    if (editSuccess) {
      dispatch({ type: LEAD_GET_REQUESTED, payload: { id: leadId } });
    }
  }, [editSuccess]);

  useEffect(() => {
    if (editSuccess === true || deleteSuccess === true) {
      setTimeout(() => {
        if (deleteSuccess) {
          props.handleSuccess(false);
        }
        dispatch({ type: LEAD_GET_ALL_REQUESTED });
        dispatch({ type: STUDENT_GET_ALL_REQUESTED });
        dispatch({ type: INSURANCE_APPLICANT_GET_ALL_REQUESTED });
        dispatch({ type: VISA_APPLICANT_GET_ALL_REQUESTED });
        dispatch({ type: SKILL_ASSESSMENT_GET_ALL_REQUESTED });
      }, 720);
      setTimeout(() => {
        // setLead({});
        setIsLog(false);
        dispatch({ type: CLEAR_ERROR });
      }, 750);
    }
  }, [editSuccess, deleteSuccess]);

  function editLead() {
    const errors = isValidLeadInputs(lead);
    if (errors.length > 0) {
      setValidationErrMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else if (
      leadCopy.status === "Converted" &&
      leadCopy.status !== lead.status
    ) {
      selectedEvent();
    } else {
      confirmLeadUpdate();
    }
  }

  const [statusChangeAlert, setStatusChangeAlert] = React.useState(null);
  const hideStatusChangeAlert = () => {
    setStatusChangeAlert(null);
  };
  const selectedEvent = () => {
    setStatusChangeAlert(
      <ConfirmationAlert
        cancel={hideStatusChangeAlert}
        submit={confirmLeadUpdate}
        message={"You are updating the status of a converted lead."}
      />
    );
  };

  function confirmLeadUpdate() {
    setValidationErrMsg("");
    dispatch({
      type: LEAD_UPDATE_REQUESTED,
      payload: { payload: lead, id: leadId },
    });
  }
  function deleteEntity() {
    dispatch({
      type: LEAD_DELETE_REQUESTED,
      payload: { id: leadId },
    });
    ReactGA.event({
      category: "Lead",
      action: "Deleted a Lead",
    });
  }

  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      />
    );
  };
  const confirmationMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteEntity();
          successDelete();
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      />
    );
  };
  function getLog() {
    return leadLog.map((st, index) => (
      <Card key={index} color={index === logId ? "primary" : null}>
        <CardBody
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setLogId(index);
            setLead(leadLog[index]);
          }}
        >
          <small>{getUserName(st.updatedBy, props.allUsers)}</small>
          <br></br>
          <small>{convertDate(st.version_timestamp)}</small>
        </CardBody>
      </Card>
    ));
  }

  function createGenericPaylaod(client) {
    const payload = {};
    if (client.hasOwnProperty("firstName")) {
      payload.firstName = client.firstName;
    }
    if (client.hasOwnProperty("lastName")) {
      payload.lastName = client.lastName;
    }
    if (client.hasOwnProperty("email")) {
      payload.email = client.email;
    }
    if (client.hasOwnProperty("phone")) {
      payload.phone = client.phone;
    }
    if (client.hasOwnProperty("country")) {
      payload.country = client.country;
    }
    if (client.hasOwnProperty("userId")) {
      payload.userId = client.userId;
    }
    if (client.hasOwnProperty("sourceId")) {
      payload.sourceId = client.sourceId;
    }
    if (client.hasOwnProperty("note")) {
      payload.remarks = client.note;
    }
    if (client.hasOwnProperty("files")) {
      payload.files = client.files;
    }
    return payload;
  }
  function moveToStudentService() {
    const genericPayload = createGenericPaylaod(lead);
    dispatch({
      type: STUDENT_CREATE_REQUESTED,
      payload: { payload: genericPayload, leadId: leadId },
    });
  }

  function moveToInsuranceService() {
    const genericPayload = createGenericPaylaod(lead);
    if (lead.hasOwnProperty("visa")) {
      genericPayload.currentVisa = lead.visa;
    }
    if (lead.hasOwnProperty("occupation")) {
      genericPayload.occupation = lead.occupation;
    }
    if (lead.hasOwnProperty("anzsco")) {
      genericPayload.anzsco = lead.anzsco;
    }
    dispatch({
      type: INSURANCE_APPLICANT_CREATE_REQUESTED,
      payload: { payload: genericPayload, leadId: leadId },
    });
  }

  function moveToVisaService() {
    const genericPayload = createGenericPaylaod(lead);
    if (lead.hasOwnProperty("visa")) {
      genericPayload.currentVisa = lead.visa;
    }
    if (lead.hasOwnProperty("visaExpiry")) {
      genericPayload.visaExpiry = lead.visaExpiry;
    }
    if (lead.hasOwnProperty("occupation")) {
      genericPayload.occupation = lead.occupation;
    }
    if (lead.hasOwnProperty("anzsco")) {
      genericPayload.anzsco = lead.anzsco;
    }
    dispatch({
      type: VISA_APPLICANT_CREATE_REQUESTED,
      payload: { payload: genericPayload, leadId: leadId },
    });
  }

  function moveToSkillAssessmentService() {
    const genericPayload = createGenericPaylaod(lead);
    if (lead.hasOwnProperty("visa")) {
      genericPayload.currentVisa = lead.visa;
    }
    if (lead.hasOwnProperty("visaExpiry")) {
      genericPayload.visaExpiry = lead.visaExpiry;
    }
    if (lead.hasOwnProperty("occupation")) {
      genericPayload.occupation = lead.occupation;
    }
    if (lead.hasOwnProperty("anzsco")) {
      genericPayload.anzsco = lead.anzsco;
    }
    dispatch({
      type: SKILL_ASSESSMENT_CREATE_REQUESTED,
      payload: { payload: genericPayload, leadId: leadId },
    });
  }

  const classes = useStyles();
  return (
    <div>
      {currentStep.step ? (
        <StepProgressBar
          startingStep={currentStep.step}
          steps={currentStep.students_steps}
          buttonWrapperClass={classes.hidden}
          subtitleClass="font-weight-bold"
        />
      ) : null}

      <GridContainer>
        <GridItem
          style={{
            textAlign: "right",
          }}
          xs={12}
          sm={12}
          md={12}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isLog}
                onChange={handleLog}
                value={isLog}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={isLog ? "HideHistory" : "ShowHistory"}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={3} sm={3} md={3}>
          {isLog && <div>{getLog()}</div>}
        </GridItem>
        <GridItem xs={isLog ? 9 : 12} sm={isLog ? 9 : 12} md={isLog ? 9 : 12}>
          <Card>
            <CardBody>
              <NavPills
                color="rose"
                tabs={[
                  {
                    tabButton: "Edit Lead",
                    tabContent: (
                      <>
                        {leadDetailLoading && leadDetail.id !== leadId ? (
                          <CircularProgress size={"2rem"} />
                        ) : (
                          <div>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <React.Fragment>
                                  {validationErrMsg && (
                                    <SnackbarContent
                                      message={validationErrMsg}
                                      color="warning"
                                    />
                                  )}
                                  {error && (
                                    <SnackbarContent
                                      message={errMessage}
                                      color="warning"
                                    />
                                  )}
                                  {editProgress ||
                                    (deleteProgress && (
                                      <CustomLinearProgress color="primary" />
                                    ))}
                                  {success && (
                                    <SnackbarContent
                                      message="Updated the lead"
                                      color="success"
                                    />
                                  )}
                                </React.Fragment>
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="ID"
                                  id="lead-edit-id"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: leadId || "",
                                    disabled: true,
                                    name: "id",
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="Email Address *"
                                  id="lead-edit-email"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: lead.email || "",
                                    onChange: handleSelect,
                                    name: "email",
                                    type: "email",
                                    disabled: isLog,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <CustomInput
                                  labelText="Phone Number *"
                                  id="lead-edit-phone"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: lead.phone || "",
                                    onChange: handleSelect,
                                    name: "phone",
                                    disabled: isLog,
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="First Name *"
                                  id="lead-edit-firstName"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: lead.firstName || "",
                                    onChange: handleSelect,
                                    name: "firstName",
                                    disabled: isLog,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Middle Name *"
                                  id="lead-edit-middleName"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: lead.middleName || "",
                                    onChange: handleSelect,
                                    name: "middleName",
                                    disabled: isLog,
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Last Name *"
                                  id="lead-edit-lastName"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: lead.lastName || "",
                                    onChange: handleSelect,
                                    name: "lastName",
                                    disabled: isLog,
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <InputLabel className={classes.label}>
                                  Date of Birth
                                </InputLabel>
                                <br />
                                <FormControl fullWidth>
                                  <Datetime
                                    closeOnSelect
                                    timeFormat={false}
                                    dateFormat={DEFAULT_DATE_FORMAT}
                                    value={lead.dob || ""}
                                    onChange={(moment) =>
                                      handleDatePicker(moment, "dob")
                                    }
                                    inputProps={{
                                      name: "dob",
                                      id: "lead-edit-dob-date",
                                      placeholder: DEFAULT_DATE_FORMAT,
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Passport No"
                                  id="lead-edit-passport"
                                  type="number"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: lead.passport,
                                    onChange: handleSelect,
                                    name: "passport",
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <InputLabel className={classes.label}>
                                  Issue Date
                                </InputLabel>
                                <br />
                                <FormControl fullWidth>
                                  <Datetime
                                    closeOnSelect
                                    timeFormat={false}
                                    dateFormat={DEFAULT_DATE_FORMAT}
                                    value={lead.issueDate || ""}
                                    onChange={(moment) =>
                                      handleDatePicker(moment, "issueDate")
                                    }
                                    inputProps={{
                                      name: "issueDate",
                                      id: "lead-edit-issueDate",
                                      placeholder: DEFAULT_DATE_FORMAT,
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <InputLabel className={classes.label}>
                                  Expiry Date
                                </InputLabel>
                                <br />
                                <FormControl fullWidth>
                                  <Datetime
                                    closeOnSelect
                                    timeFormat={false}
                                    dateFormat={DEFAULT_DATE_FORMAT}
                                    value={lead.expiryDate || ""}
                                    onChange={(moment) =>
                                      handleDatePicker(moment, "expiryDate")
                                    }
                                    inputProps={{
                                      name: "expiryDate",
                                      id: "lead-edit-expiryDate",
                                      placeholder: DEFAULT_DATE_FORMAT,
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <LocationAutoComplete
                                  defaultValue={lead.address}
                                  onPlaceSelected={(value) =>
                                    handleSelect({
                                      target: {
                                        name: "address",
                                        value: value?.display_name,
                                      },
                                    })
                                  }
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Qualification"
                                  id="lead-qualification"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    value: lead.qualification || "",
                                    onChange: handleSelect,
                                    name: "qualification",
                                  }}
                                />
                              </GridItem>
                            </GridContainer>

                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <div
                                    className={classes.label}
                                    style={{
                                      visibility: lead.visa ? "" : "hidden",
                                      color: "#AAA",
                                      fontSize: "11px",
                                      lineHeight: "1.428571429",
                                      fontWeight: "400",
                                      display: "inline-flex",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                  >
                                    Visa
                                  </div>
                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable
                                    isSearchable
                                    isDisabled={isLog}
                                    value={
                                      lead.visa
                                        ? { label: lead.visa, value: lead.visa }
                                        : ""
                                    }
                                    options={visaTypeOption}
                                    name="visa"
                                    styles={customReactSelectStyles}
                                    placeholder={"Visa"}
                                    onChange={(options) => {
                                      if (!options) {
                                        setLead({
                                          ...lead,
                                          visa: "",
                                        });
                                      } else {
                                        setLead({
                                          ...lead,
                                          visa: options.value,
                                        });
                                      }
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                              {lead.visa && (
                                <GridItem xs={12} sm={12} md={6}>
                                  <InputLabel className={classes.label}>
                                    Visa Expiry
                                  </InputLabel>
                                  <br />
                                  <FormControl fullWidth>
                                    <Datetime
                                      closeOnSelect
                                      timeFormat={false}
                                      dateFormat={DEFAULT_DATE_FORMAT}
                                      value={lead.visaExpiry || ""}
                                      onChange={(moment) =>
                                        handleDatePicker(moment, "visaExpiry")
                                      }
                                      inputProps={{
                                        name: "visaExpiry",
                                        id: "edit-lead-visaExpirt",
                                        placeholder: DEFAULT_DATE_FORMAT,
                                      }}
                                    />
                                  </FormControl>
                                </GridItem>
                              )}
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <div
                                    className={classes.label}
                                    style={{
                                      visibility: lead.occupation
                                        ? ""
                                        : "hidden",
                                      color: "#AAA",
                                      fontSize: "11px",
                                      lineHeight: "1.428571429",
                                      fontWeight: "400",
                                      display: "inline-flex",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                  >
                                    Occupation
                                  </div>
                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable
                                    isSearchable
                                    isDisabled={isLog}
                                    value={
                                      lead.occupation
                                        ? {
                                            label: lead.occupation,
                                            value: lead.occupation,
                                          }
                                        : ""
                                    }
                                    options={occupationOption}
                                    name="occupation"
                                    styles={customReactSelectStyles}
                                    placeholder={"Occupation"}
                                    onChange={(options) => {
                                      if (!options) {
                                        setLead({
                                          ...lead,
                                          occupation: "",
                                          anzsco: "",
                                        });
                                      } else {
                                        setLead({
                                          ...lead,
                                          occupation: options.value,
                                          anzsco: getAnzsco(options.value),
                                        });
                                      }
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <div
                                    className={classes.label}
                                    style={{
                                      visibility: lead.anzsco ? "" : "hidden",
                                      color: "#AAA",
                                      fontSize: "11px",
                                      lineHeight: "1.428571429",
                                      fontWeight: "400",
                                      display: "inline-flex",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                  >
                                    ANZSCO
                                  </div>
                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable
                                    isSearchable
                                    value={
                                      lead.anzsco
                                        ? {
                                            label: lead.anzsco,
                                            value: lead.anzsco,
                                          }
                                        : ""
                                    }
                                    options={occupationCodeOption}
                                    name="anzsco"
                                    styles={customReactSelectStyles}
                                    placeholder={"ANZSCO"}
                                    onChange={(options) => {
                                      if (!options) {
                                        setLead({
                                          ...lead,
                                          anzsco: "",
                                          occupation: "",
                                        });
                                      } else {
                                        setLead({
                                          ...lead,
                                          anzsco: options.value,
                                          occupation: getOccupation(
                                            options.value
                                          ),
                                        });
                                      }
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <FormControl
                                  fullWidth
                                  className={classes.selectFormControl}
                                >
                                  <div
                                    className={classes.label}
                                    style={{
                                      visibility: lead.location ? "" : "hidden",
                                      color: "#AAA",
                                      fontSize: "11px",
                                      lineHeight: "1.428571429",
                                      fontWeight: "400",
                                      display: "inline-flex",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                  >
                                    Location
                                  </div>
                                  <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable
                                    isSearchable
                                    value={
                                      lead.location
                                        ? {
                                            label: lead.location,
                                            value: lead.location,
                                          }
                                        : ""
                                    }
                                    options={locationOption}
                                    name="location"
                                    styles={customReactSelectStyles}
                                    placeholder={"Location"}
                                    onChange={(options) => {
                                      if (!options) {
                                        setLead({
                                          ...lead,
                                          location: "",
                                        });
                                      } else {
                                        setLead({
                                          ...lead,
                                          location: options.value,
                                        });
                                      }
                                    }}
                                  />
                                </FormControl>
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <DropDownWithSearch
                                  isMulti
                                  optionsList={getDropDown("lead-service-type")}
                                  selectedOption={
                                    lead.serviceType
                                      ? JSON.parse(lead.serviceType)
                                      : []
                                  }
                                  label={"Service Type *"}
                                  setValue={setLead}
                                  currentState={lead}
                                  name={"serviceType"}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <DropDownWithSearch
                                  label={"Country"}
                                  optionsList={getDropDown("country")}
                                  selectedOption={lead.country || ""}
                                  setValue={setLead}
                                  currentState={lead}
                                  name={"country"}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <DropDownWithSearch
                                  optionsList={getDropDown("lead-status")}
                                  selectedOption={lead.status || ""}
                                  setValue={setLead}
                                  currentState={lead}
                                  name={"status"}
                                  label={"Status *"}
                                />
                              </GridItem>
                              {lead.status === "Follow Up" && (
                                <GridItem xs={12} sm={12} md={6}>
                                  <InputLabel className={classes.label}>
                                    Follow Up Date *
                                  </InputLabel>
                                  <br />
                                  <FormControl fullWidth>
                                    <Datetime
                                      closeOnSelect
                                      timeFormat={false}
                                      dateFormat={DEFAULT_DATE_FORMAT}
                                      value={lead.followUpDate || ""}
                                      onChange={(moment) =>
                                        handleDatePicker(moment, "followUpDate")
                                      }
                                      inputProps={{
                                        name: "followUpDate",
                                        id: "add-lead-followUpDate",
                                        placeholder: DEFAULT_DATE_FORMAT,
                                      }}
                                    />
                                  </FormControl>
                                </GridItem>
                              )}
                            </GridContainer>
                            {!isLeadGenerator && (
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                      {props.allSources && (
                                        <DropDownWithSearch
                                          dbOptions={props.allSources}
                                          selectedOption={lead.sourceId || ""}
                                          setValue={setLead}
                                          currentState={lead}
                                          name={"sourceId"}
                                          label={"Source"}
                                        />
                                      )}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      {props.allUsers && (
                                        <DropDownWithSearch
                                          dbOptions={props.allUsers}
                                          dbLabel={"firstName"}
                                          selectedOption={lead.userId || ""}
                                          setValue={setLead}
                                          currentState={lead}
                                          name={"userId"}
                                          label={"Assigned To"}
                                        />
                                      )}
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                            )}
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <h4>Note:</h4>
                                <Editor
                                  licenseKey={"gpl"}
                                  tinymceScriptSrc={
                                    "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                                  }
                                  value={lead.note || ""}
                                  init={TINY_INIT}
                                  onEditorChange={handleEditorChange}
                                />
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              <GridItem>
                                <div>
                                  <h4>Documents:</h4>
                                  <FileUpload
                                    autoUpload
                                    uploadOnly
                                    label={"Upload new file"}
                                    type={"lead"}
                                    setFileUrl={(fileUrl) =>
                                      handleFileUpload(fileUrl)
                                    }
                                  />
                                </div>
                              </GridItem>
                            </GridContainer>
                            <GridContainer>
                              {lead.files &&
                                lead.files.length > 0 &&
                                lead.files.map((fileUrl, id) => {
                                  return (
                                    <GridItem key={id}>
                                      <div
                                        style={{
                                          flexDirection: "column",
                                          display: "flex",
                                          backgroundColor: "#B0BEC5",
                                          padding: "20px",
                                          borderRadius: "25px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <FileUpload
                                          fileUrl={fileUrl}
                                          key={id}
                                          handleDelete={(fileUrl) => {
                                            handleFileDelete(fileUrl);
                                          }}
                                        />
                                      </div>
                                    </GridItem>
                                  );
                                })}
                            </GridContainer>
                            <br />
                            <br />
                            <GridContainer
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <GridItem>
                                <Muted>
                                  Updated At: {convertDate(lead.updatedAt)}
                                </Muted>
                                <Muted>
                                  Updated By:{" "}
                                  {getUserName(lead.updatedBy, props.allUsers)}
                                </Muted>
                              </GridItem>
                              <GridItem
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "0.3rem",
                                }}
                              >
                                {editProgress && (
                                  <CircularProgress size={"2rem"} />
                                )}
                                {error && (
                                  <CloseIcon style={{ color: "red" }} />
                                )}
                                {success && (
                                  <CheckIcon style={{ color: "green" }} />
                                )}
                                {SUPER_ADMIN_ROLES.includes(currentRoleId) && (
                                  <Button
                                    color="danger"
                                    disabled={!!(deleteProgress || isLog)}
                                    className={classes.moveToRight}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      confirmationMessage();
                                    }}
                                  >
                                    Delete
                                  </Button>
                                )}
                                <Button
                                  color="primary"
                                  disabled={!!(editProgress || isLog)}
                                  className={classes.moveToRight}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    ReactGA.event({
                                      category: "Lead",
                                      action: "Updated a Lead",
                                    });
                                    editLead();
                                  }}
                                >
                                  Update
                                </Button>
                                <Clearfix />
                              </GridItem>
                            </GridContainer>
                          </div>
                        )}
                      </>
                    ),
                  },
                  !isLeadGenerator && {
                    tabButton: "Transition",
                    tabContent: (
                      <div>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            {entityError && (
                              <SnackbarContent
                                message="Something went wrong. Probably Email already exists."
                                color="warning"
                              />
                            )}
                            {transitionProgress && (
                              <CustomLinearProgress color="primary" />
                            )}
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            style={{
                              textAlign: "left",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <div className="tree">
                              <Button
                                style={{
                                  minWidth: "310px",
                                  fontWeight: "bold",
                                  background:
                                    lead.clientIds && lead.clientIds.studentId
                                      ? "linear-gradient(90deg, rgba(82,75,166,1) 0%, rgba(89,123,209,1) 35%, rgba(91,173,255,1) 100%)"
                                      : "info",
                                }}
                                className={classes.marginAll}
                                disabled={!!isLog}
                                onClick={(e) => {
                                  e.preventDefault();
                                  ReactGA.event({
                                    category: "Lead",
                                    action: "Move Lead to Education Service",
                                  });
                                  moveToStudentService();
                                }}
                              >
                                Move Lead to Education Service
                              </Button>
                              <ul>
                                {lead.clientIds &&
                                  lead.clientIds.studentId &&
                                  lead.clientIds.studentId.map((studentId) => {
                                    const studentDetail = students.find(
                                      (s) => s.id === studentId
                                    );

                                    let college;
                                    if (
                                      studentDetail &&
                                      studentDetail.universityId
                                    ) {
                                      college = studentDetail.universityId
                                        ? allUniversities.find(
                                            (u) =>
                                              u.id ===
                                              studentDetail.universityId
                                          )
                                        : null;
                                    }

                                    return (
                                      <li key={studentId}>
                                        <Muted center>
                                          Student Id:{" "}
                                          <Link
                                            to={`/admin/students/${studentId}`}
                                          >
                                            {studentId}
                                          </Link>
                                          <span
                                            style={{
                                              marginLeft: "5px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {`(${studentDetail?.firstName} ${
                                              studentDetail?.middleName || ""
                                            } ${studentDetail?.lastName})`}
                                          </span>
                                        </Muted>
                                        {college && (
                                          <Muted center>{college.name}</Muted>
                                        )}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justifyContent="left">
                          <GridItem
                            style={{
                              textAlign: "left",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <div className="tree">
                              <Button
                                style={{
                                  minWidth: "310px",
                                  fontWeight: "bold",
                                  background:
                                    lead.clientIds &&
                                    lead.clientIds.visaApplicantId
                                      ? "linear-gradient(90deg, rgba(82,75,166,1) 0%, rgba(89,123,209,1) 35%, rgba(91,173,255,1) 100%)"
                                      : "info",
                                }}
                                className={classes.marginAll}
                                disabled={!!isLog}
                                onClick={(e) => {
                                  e.preventDefault();
                                  ReactGA.event({
                                    category: "Lead",
                                    action: "Move Lead to Visa Service",
                                  });
                                  moveToVisaService();
                                }}
                              >
                                Move Lead to Visa Service
                              </Button>
                              <ul>
                                {lead.clientIds &&
                                  lead.clientIds.visaApplicantId &&
                                  lead.clientIds.visaApplicantId.map(
                                    (visaApplicantId) => {
                                      const visaApplicantDetail =
                                        visaApplicants.find(
                                          (s) => s.id === visaApplicantId
                                        );
                                      return (
                                        <li key={visaApplicantId}>
                                          <Muted center>
                                            Visa Applicant Id:{" "}
                                            <Link
                                              to={`/admin/visa/${visaApplicantId}`}
                                            >
                                              {visaApplicantId}
                                            </Link>
                                            <span
                                              style={{
                                                marginLeft: "5px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {`(${
                                                visaApplicantDetail?.firstName
                                              } ${
                                                visaApplicantDetail?.middleName ||
                                                ""
                                              } ${
                                                visaApplicantDetail?.lastName
                                              })`}
                                            </span>
                                          </Muted>
                                          {visaApplicantDetail &&
                                            visaApplicantDetail.currentVisa && (
                                              <Muted center>
                                                Current Visa:{" "}
                                                {
                                                  visaApplicantDetail.currentVisa
                                                }
                                              </Muted>
                                            )}
                                          {visaApplicantDetail &&
                                            visaApplicantDetail.proposedVisa && (
                                              <Muted center>
                                                Proposed Visa:{" "}
                                                {
                                                  visaApplicantDetail.proposedVisa
                                                }
                                              </Muted>
                                            )}
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </div>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            style={{
                              textAlign: "left",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <div className="tree">
                              <Button
                                style={{
                                  minWidth: "310px",
                                  fontWeight: "bold",
                                  background:
                                    lead.clientIds &&
                                    lead.clientIds.skillAssessmentId
                                      ? "linear-gradient(90deg, rgba(82,75,166,1) 0%, rgba(89,123,209,1) 35%, rgba(91,173,255,1) 100%)"
                                      : "info",
                                }}
                                className={classes.marginAll}
                                disabled={!!isLog}
                                onClick={(e) => {
                                  e.preventDefault();
                                  ReactGA.event({
                                    category: "Lead",
                                    action:
                                      "Move Lead to Skill Assessment Service",
                                  });
                                  moveToSkillAssessmentService();
                                }}
                              >
                                Move Lead to Skill Assessment Service
                              </Button>
                              <ul>
                                {lead.clientIds &&
                                  lead.clientIds.skillAssessmentId &&
                                  lead.clientIds.skillAssessmentId.map(
                                    (skillAssessmentId) => {
                                      const skillAssessment =
                                        skillAssessments.find(
                                          (s) => s.id === skillAssessmentId
                                        );
                                      return (
                                        <li key={skillAssessmentId}>
                                          <Muted center key={skillAssessmentId}>
                                            Skill Assessment Id:{" "}
                                            <Link
                                              to={`/admin/skill/${skillAssessmentId}`}
                                            >
                                              {skillAssessmentId}
                                            </Link>
                                            <span
                                              style={{
                                                marginLeft: "5px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {`(${
                                                skillAssessment?.firstName
                                              } ${
                                                skillAssessment?.middleName ||
                                                ""
                                              } ${skillAssessment?.lastName})`}
                                            </span>
                                          </Muted>
                                          {skillAssessment &&
                                            skillAssessment.occupation && (
                                              <Muted center>
                                                {skillAssessment.occupation}
                                              </Muted>
                                            )}
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </div>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            style={{
                              textAlign: "left",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <div className="tree">
                              <Button
                                style={{
                                  minWidth: "310px",
                                  fontWeight: "bold",
                                  background:
                                    lead.clientIds &&
                                    lead.clientIds.insuranceApplicantId
                                      ? "linear-gradient(90deg, rgba(82,75,166,1) 0%, rgba(89,123,209,1) 35%, rgba(91,173,255,1) 100%)"
                                      : "info",
                                }}
                                className={classes.marginAll}
                                disabled={!!isLog}
                                onClick={(e) => {
                                  e.preventDefault();
                                  ReactGA.event({
                                    category: "Lead",
                                    action: "Move Lead to Insurance Service",
                                  });
                                  moveToInsuranceService();
                                }}
                              >
                                Move Lead to Insurance Service
                              </Button>
                              <ul>
                                {lead.clientIds &&
                                  lead.clientIds.insuranceApplicantId &&
                                  lead.clientIds.insuranceApplicantId.map(
                                    (insuranceApplicantId) => {
                                      const insuranceApplicant =
                                        insuranceApplicants.find(
                                          (s) => s.id === insuranceApplicantId
                                        );
                                      const insuranceProviders =
                                        getInsuranceProviderMapping();
                                      const insuranceTypes =
                                        getInsuranceTypeMapping();

                                      const insuranceProvider =
                                        insuranceApplicant &&
                                        insuranceApplicant.insuranceProviderId
                                          ? insuranceProviders.find(
                                              (ip) =>
                                                ip.id ===
                                                insuranceApplicant.insuranceProviderId
                                            )
                                          : null;
                                      const insuranceType =
                                        insuranceApplicant &&
                                        insuranceApplicant.insuranceTypeId
                                          ? insuranceTypes.find(
                                              (ip) =>
                                                ip.id ===
                                                insuranceApplicant.insuranceTypeId
                                            )
                                          : null;
                                      return (
                                        <li key={insuranceApplicantId}>
                                          <Muted
                                            center
                                            key={insuranceApplicantId}
                                          >
                                            Insurance Applicant Id:{" "}
                                            <Link
                                              to={`/admin/insurance/${insuranceApplicantId}`}
                                            >
                                              {insuranceApplicantId}
                                            </Link>
                                            <span
                                              style={{
                                                marginLeft: "5px",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {`(${
                                                insuranceApplicant?.firstName
                                              } ${
                                                insuranceApplicant?.middleName ||
                                                ""
                                              } ${
                                                insuranceApplicant?.lastName
                                              })`}
                                            </span>
                                          </Muted>
                                          {insuranceApplicant &&
                                            insuranceApplicant.currentVisa && (
                                              <Muted center>
                                                {insuranceApplicant.currentVisa}
                                              </Muted>
                                            )}
                                          {insuranceProvider && (
                                            <Muted center>
                                              {insuranceProvider.name}{" "}
                                              {insuranceType
                                                ? `- ${insuranceType.name}`
                                                : ""}
                                            </Muted>
                                          )}
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </div>
                    ),
                  },
                ]}
              />
              {alert}
              {statusChangeAlert}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
