import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import {
  CLEAR_ERROR,
  USER_CREATE_REQUESTED,
  USER_GET_ALL_REQUESTED,
} from "../../../redux/actions";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import { isValidUserProfileInputs } from "../../../scripts/util";
import ReactGA from "react-ga4";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { SUPER_ADMIN_ROLES, userAccess } from "../../../scripts/const";
import Divider from "@material-ui/core/Divider";
import LocationAutoComplete from "components/LocationAutocomplete/LocationAutocomplete";

const useStyles = makeStyles(styles);

export default function AddUser(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const [validationErrorMsg, setValidationErrorMsg] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [user, setUser] = React.useState({});

  const handleSelect = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckBox = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.checked,
    });
  };

  const dispatch = useDispatch();

  const saveError = useSelector((state) => state.user.userCreateError);
  const saveSuccess = useSelector((state) => state.user.userCreateSuccess);
  const saveProgress = useSelector((state) => state.user.userCreateFetching);

  function createUser() {
    const errors = isValidUserProfileInputs(user, true);
    if (errors.length > 0) {
      setValidationErrorMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else {
      setValidationErrorMsg("");
      dispatch({ type: USER_CREATE_REQUESTED, payload: user });
    }
  }

  function clearErrors() {
    setValidationErrorMsg("");
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      clearErrors();
      // setUser({});
    }
  }, [modalOpen]);

  useEffect(() => {
    setValidationErrorMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    setError(saveError);
  }, [saveError]);

  useEffect(() => {
    setSuccess(saveSuccess);
  }, [saveSuccess]);

  useEffect(() => {
    if (saveSuccess === true) {
      setTimeout(() => {
        props.handleSuccess(false);
      }, 700);
      dispatch({ type: USER_GET_ALL_REQUESTED });
      setTimeout(() => {
        setUser({});
        dispatch({ type: CLEAR_ERROR });
      }, 701);
    }
  }, [saveSuccess]);

  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {validationErrorMsg && (
                    <SnackbarContent
                      message={validationErrorMsg}
                      color="warning"
                    />
                  )}
                  {error && (
                    <SnackbarContent
                      message="Something went wrong."
                      color="warning"
                    />
                  )}
                  {saveProgress && <CustomLinearProgress color="primary" />}
                  {success && (
                    <SnackbarContent message="Added the user" color="success" />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email address *"
                    id="addUser-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: user.email || "",
                      onChange: handleSelect,
                      name: "email",
                      type: "email",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="addUser-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: user.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name *"
                    id="addUser-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: user.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name *"
                    id="addUser-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: user.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <LocationAutoComplete
                    defaultValue={user.address}
                    onPlaceSelected={(value) =>
                      handleSelect({
                        target: {
                          name: "address",
                          value: value?.display_name,
                        },
                      })
                    }
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Role *
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={user.roleId || ""}
                      onChange={handleSelect}
                      inputProps={{
                        name: "roleId",
                        id: "roleId",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Choose Role
                      </MenuItem>
                      {SUPER_ADMIN_ROLES.includes(currentRoleId) && (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="1"
                        >
                          Super Admin
                        </MenuItem>
                      )}

                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="2"
                      >
                        Manager
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="3"
                      >
                        General
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="4"
                      >
                        Accountant
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="5"
                      >
                        Lead Generator
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Password *"
                    id="addUser-password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: user.password || "",
                      onChange: handleSelect,
                      name: "password",
                      type: "password",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Detail"
                    id="add-user-detail"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      value: user.detail || "",
                      rows: 3,
                      onChange: handleSelect,
                      name: "detail",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                style={{
                  display: "flex",
                  backgroundColor: "rgb(72 119 143)",
                  padding: "20px",
                  borderRadius: "25px",
                  marginTop: "10px",
                }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Appointment Setting</h4>
                  <Divider />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <div className={classes.checkboxAndRadio}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user["onlineAppointment"] || false}
                          onChange={handleCheckBox}
                          name={"onlineAppointment"}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={"Online Appointment"}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <div className={classes.checkboxAndRadio}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={user["isPaid"] || false}
                          onChange={handleCheckBox}
                          name={"isPaid"}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={"Is Paid?"}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelProps={{ color: "secondary" }}
                    labelText="Paid Amount ($)"
                    id="paidAmount-id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: !user["isPaid"],
                      value: user["paidAmount"] || "",
                      name: "paidAmount",
                      onChange: handleSelect,
                      type: "number",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelProps={{ color: "secondary" }}
                    labelText="Slot Time (Default 30)"
                    id="slotTime-id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: user["slotTime"] || "",
                      name: "slotTime",
                      onChange: handleSelect,
                      type: "number",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelProps={{ color: "secondary" }}
                    labelText="Appointment Note"
                    id="appointmentNote"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: user["appointmentNote"] || "",
                      name: "appointmentNote",
                      onChange: handleSelect,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer
                style={{
                  display: "flex",
                  backgroundColor: "#B0BEC5",
                  padding: "20px",
                  borderRadius: "25px",
                  marginTop: "10px",
                }}
              >
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Additional Access</h4>
                  <Divider />
                </GridItem>
                {userAccess.map((access, id) => {
                  const { label, name } = access;
                  return (
                    <GridItem key={id} xs={12} sm={12} md={3}>
                      <div className={classes.checkboxAndRadio}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={user[name] || false}
                              onChange={handleCheckBox}
                              name={name}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot,
                          }}
                          label={label}
                        />
                      </div>
                    </GridItem>
                  );
                })}
              </GridContainer>
              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={(e) => {
                  e.preventDefault();
                  ReactGA.event({
                    category: "User",
                    action: "Added a User",
                  });
                  createUser();
                }}
              >
                Add User
              </Button>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
