import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Beetle as LoaderButton } from "react-button-loaders";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {
  PROFILE_UPDATE_REQUESTED,
  PROFILE_GET_REQUESTED,
  PROFILE_CODE_GENERATION_REQUESTED,
} from "../../../redux/actions";
import { connect } from "react-redux";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import Muted from "../../../components/Typography/Muted";
import { getSafe, convertDate } from "../../../scripts/util";
import ReactGA from "react-ga4";
import ImageUpload from "../../../components/CustomUpload/ImageUpload";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import LocationAutoComplete from "../../../components/LocationAutocomplete/LocationAutocomplete.js";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles(styles);

const MyProfile = (props) => {
  const [errorMsg, setErrMsg] = React.useState(null);
  const [notificationColor, setNotificationColor] = React.useState("warning");
  const [validationError, setValidationError] = React.useState(false);
  const [profile, setProfile] = React.useState({});
  const [profileId, setProfileId] = React.useState();
  const editProgress = useSelector((state) => state.user.userUpdateFetching);
  const codeGenerationProgress = useSelector(
    (state) => state.user.codeGenerationProgress
  );

  const handleSelect = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  function isValidInputs() {
    const errors = [];
    if (!profile.firstName || profile.firstName === "") {
      errors.push("First name can not be empty");
    }
    if (!profile.lastName || profile.lastName === "") {
      errors.push("Last name can not be empty");
    }
    if (
      profile.password &&
      profile.password !== "" &&
      profile.password.length < 6
    ) {
      errors.push("Password should be more than 6 char long");
    }
    if (!profile.phone || profile.phone === "") {
      errors.push("Phone can not be empty");
    }
    if (
      profile.phone &&
      profile.phone !== "" &&
      ![9, 10].includes(profile.phone.length)
    ) {
      errors.push("Phone number is invalid");
    }
    return errors;
  }
  const updateProfile = () => {
    const errors = isValidInputs();
    if (errors.length > 0) {
      setNotificationColor("warning");
      setErrMsg(errors);
      setValidationError(true);
    } else {
      setErrMsg("");
      setValidationError(false);
      props.updateMyProfile(profile, props.myId);
    }
  };

  const handleCodeGenerate = () => {
    ReactGA.event({
      category: "Profile",
      action: "ClockIn Code Generated",
    });
    props.generateProfile(props.myId);
  };

  useEffect(() => {
    props.getProfile(props.myId);
  }, []);

  useEffect(() => {
    setProfile(props.myProfile);
    setProfileId(props.myProfile.id);
  }, [props.myProfile]);

  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem
          style={{
            textAlign: "center",
            marginTop: 40,
          }}
          xs={12}
          sm={12}
          md={12}
        >
          <ImageUpload isNew isProfile user={props.myProfile} avatar />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {props.updateError && (
                    <SnackbarContent
                      message="Something went wrong"
                      color="warning"
                    />
                  )}
                  {editProgress && <CustomLinearProgress color="primary" />}
                  {validationError && (
                    <SnackbarContent
                      message={errorMsg}
                      color={notificationColor}
                    />
                  )}
                  {props.updateSuccess && (
                    <SnackbarContent
                      message="Updated the profile"
                      color="success"
                    />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="ID"
                    id="myProfile-id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: profileId || "",
                      name: "id",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address"
                    id="myProfile-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: profile.email || "",
                      name: "email",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="myProfile-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: profile.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name *"
                    id="myProfile-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: profile.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name *"
                    id="myProfile-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: profile.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <LocationAutoComplete
                    defaultValue={profile.address}
                    onPlaceSelected={(value) =>
                      handleSelect({
                        target: {
                          name: "address",
                          value: value?.display_name,
                        },
                      })
                    }
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Role
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={profile.roleId || ""}
                      onChange={handleSelect}
                      inputProps={{
                        name: "roleId",
                        id: "roleId",
                        disabled: true,
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Choose Role
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="1"
                      >
                        Super Admin
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="2"
                      >
                        Manager
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="3"
                      >
                        General
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="4"
                      >
                        Accountant
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Password"
                    id="myProfile-password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "password",
                      value: profile.password || "",
                      onChange: handleSelect,
                      name: "password",
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <CustomInput
                    labelText="Clock In-Out Code"
                    id="myProfile-clock"
                    formControlProps={{
                      fullWidth: false,
                    }}
                    inputProps={{
                      disabled: true,
                      type: "text",
                      value: profile.clockInCode || "",
                      name: "clockInCode",
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <LoaderButton
                    bgColor={"#1c636e"}
                    onClick={handleCodeGenerate}
                    state={codeGenerationProgress ? "loading" : null}
                    disabled={codeGenerationProgress}
                  >
                    Generate Code
                  </LoaderButton>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="Detail"
                    id="detail"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      value: profile.detail || "",
                      rows: 3,
                      onChange: handleSelect,
                      name: "detail",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                color="rose"
                disabled={editProgress}
                className={classes.moveToRight}
                onClick={(e) => {
                  ReactGA.event({
                    category: "Profile",
                    action: "Updated Profile",
                  });
                  e.preventDefault();
                  updateProfile(props);
                }}
              >
                {editProgress && <CircularProgress size={"2rem"} />}
                Update Profile
              </Button>
              <br />
              <Muted>Updated At: {convertDate(profile.updatedAt)}</Muted>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    myProfile: state.user.profile,
    updateError: state.user.profileUpdateError,
    updateSuccess: state.user.profileUpdateSuccess,
    myId: getSafe(() => state.login.loginUser.detail.id, ""),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProfile: (id) => {
    dispatch({ type: PROFILE_GET_REQUESTED, payload: id });
  },
  generateProfile: (id) => {
    dispatch({ type: PROFILE_CODE_GENERATION_REQUESTED, payload: { id } });
  },
  updateMyProfile: (payload, id) => {
    dispatch({ type: PROFILE_UPDATE_REQUESTED, payload: { payload, id } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
