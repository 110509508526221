import LoginPage from "views/Login/LoginPage.js";
import ResetPasswordPage from "views/Login/ResetPasswordPage.js";
import ForgotPasswordPage from "views/Login/ForgotPasswordPage.js";
import MyProfile from "views/hbg-view/User/MyProfile.js";
import UsersTables from "views/hbg-view/User/UsersTables.js";
import EmployeeList from "views/hbg-view/Employee/EmployeeList.js";
import Employee from "views/hbg-view/Employee/Employee.js";
import LeadsTables from "views/hbg-view/Lead/LeadsTables.js";
import StudentTables from "views/hbg-view/StudentService/StudentTables";
import InsuranceTables from "views/hbg-view/InsuranceService/InsuranceTables.js";
import SkillAssessmentTables from "views/hbg-view/SkillAssessmentService/SkillAssessmentTables.js";
import VisaTables from "views/hbg-view/VisaService/VisaTables.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import GeneralUserDashboard from "views/GeneralUserDashboard/Dashboard";

import FiscalReport from "views/FiscalReport/FiscalReport.jsx";
import University from "views/hbg-view/Setup/University";
import Course from "views/hbg-view/Setup/Course";
import OccupationList from "views/hbg-view/Setup/OccupationList";
import VisaList from "views/hbg-view/Setup/VisaList";
import Source from "views/hbg-view/Setup/Source";
import Setting from "views/hbg-view/Setup/Setting";
import Calendar from "views/Calendar/Calendar.js";
import ToDo from "views/hbg-view/ToDo/ToDo.js";
import Agreement from "views/hbg-view/Agreement/AgreementTable.js";
import Appointment from "views/Appointment/Appointment.js";
import SignUpPage from "views/Client-facing/Signup";
import SeminarPage from "views/Client-facing/Seminar";
import AppointmentPage from "views/Client-facing/Appointment";
import CheckInPage from "views/Client-facing/CheckIn";
import ClockInPage from "views/Client-facing/ClockIn/index";
import MigrationPage from "views/Client-facing/Immigration/index";
import CheckinDashboard from "views/Checkin/Checkin";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import RoomIcon from "@material-ui/icons/Room";
import PersonIcon from "@material-ui/icons/Person";
import SchoolIcon from "@material-ui/icons/School";
import HospitalIcon from "@material-ui/icons/LocalHospital";
import WorkIcon from "@material-ui/icons/Work";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import DescriptionIcon from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import TodayIcon from "@material-ui/icons/Today";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import PollIcon from "@material-ui/icons/Poll";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

import { SUPER_ROLE, ALL_ROLE, ROLES, GENERAL_ROLE } from "./scripts/const";
import TimeSheet from "./views/hbg-view/Employee/TimeSheet";
import FaceUpload from "views/Attendance/faceGallery/addFacePhoto";
import Announcement from "views/hbg-view/Setup/Announcement";

const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    accessRole: SUPER_ROLE,
    accessName: "dashboardManagement",
  },
  {
    path: "/user-dashboard",
    supportParam: true,
    name: "Dashboard",
    icon: DashboardIcon,
    component: GeneralUserDashboard,
    layout: "/admin",
    accessRole: GENERAL_ROLE,
  },
  {
    path: "/face-upload",
    name: "Face Upload ",
    icon: RoomIcon,
    component: FaceUpload,
    layout: "/admin",
    accessRole: ALL_ROLE,
    accessName: "dashboardManagement",
  },
  {
    path: "/fiscal-report",
    name: "Fiscal Report",
    icon: PollIcon,
    component: FiscalReport,
    layout: "/admin",
    accessRole: SUPER_ROLE,
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: TodayIcon,
    component: Calendar,
    layout: "/admin",
    accessRole: SUPER_ROLE,
  },
  {
    path: "/appointment",
    name: "Appointment",
    icon: DateRangeIcon,
    component: Appointment,
    layout: "/admin",
    accessRole: ALL_ROLE,
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: PersonIcon,
    component: MyProfile,
    layout: "/admin",
    accessRole: [...ALL_ROLE, ROLES.ACCOUNTING, ROLES.LEAD_MANAGEMENT],
  },
  {
    path: "/timesheet",
    name: "TimeSheet",
    icon: PersonIcon,
    component: TimeSheet,
    layout: "/admin",
    accessRole: [...ALL_ROLE, ROLES.ACCOUNTING, ROLES.LEAD_MANAGEMENT],
  },
  {
    path: "/checkin",
    name: "Check-In",
    icon: RoomIcon,
    component: CheckinDashboard,
    layout: "/admin",
    accessRole: ALL_ROLE,
  },
  {
    path: "/leads",
    supportParam: true,
    name: "Leads",
    icon: ContactPhoneIcon,
    component: LeadsTables,
    layout: "/admin",
    accessRole: [...ALL_ROLE, ROLES.LEAD_MANAGEMENT],
  },
  {
    path: "/students",
    supportParam: true,
    name: "Education Service",
    icon: SchoolIcon,
    component: StudentTables,
    layout: "/admin",
    accessRole: ALL_ROLE,
  },
  {
    path: "/visa",
    supportParam: true,
    name: "Visa Service",
    icon: DescriptionIcon,
    component: VisaTables,
    layout: "/admin",
    accessRole: ALL_ROLE,
  },
  {
    path: "/skill",
    supportParam: true,
    name: "Skill Assessment Service",
    icon: WorkIcon,
    component: SkillAssessmentTables,
    layout: "/admin",
    accessRole: ALL_ROLE,
  },
  {
    path: "/insurance",
    supportParam: true,
    name: "Insurance Service",
    icon: HospitalIcon,
    component: InsuranceTables,
    layout: "/admin",
    accessRole: ALL_ROLE,
  },
  {
    path: "/agreement",
    name: "Agency Agreement",
    supportParam: true,
    icon: ConfirmationNumberIcon,
    component: Agreement,
    layout: "/admin",
    accessRole: SUPER_ROLE,
    accessName: "agencyAgreementManagement",
  },
  // {
  //   path: "/immigration",
  //   name: "Immigration Profile",
  //   supportParam: true,
  //   icon: AccountBoxIcon,
  //   component: Immigration,
  //   layout: "/admin",
  //   accessRole: SUPER_ROLE,
  //   // accessName: "agencyAgreementManagement",
  // },
  {
    path: "/todo",
    name: "To Do",
    icon: AddAlertIcon,
    component: ToDo,
    layout: "/admin",
    accessRole: SUPER_ROLE,
  },
  {
    path: "/employees",
    name: "Employees",
    icon: ContactPhoneIcon,
    supportParam: true,
    paramComponent: Employee,
    component: EmployeeList,
    layout: "/admin",
    accessRole: [ROLES.ACCOUNTING],
  },
  {
    collapse: true,
    name: "Setup",
    icon: SettingsIcon,
    state: "pageCollapse",
    accessRole: SUPER_ROLE,
    accessName: [
      "userManagement",
      "universityManagement",
      "courseManagement",
      "sourceManagement",
      "settingManagement",
    ],
    views: [
      {
        path: "/users",
        name: "Users",
        mini: "USR",
        component: UsersTables,
        layout: "/admin",
        accessRole: SUPER_ROLE,
        accessName: "userManagement",
      },
      {
        path: "/employees",
        name: "Employees",
        mini: "ES",
        supportParam: true,
        paramComponent: Employee,
        component: EmployeeList,
        layout: "/admin",
        accessRole: SUPER_ROLE,
      },
      {
        path: "/occupation-list",
        name: "Occupation List",
        mini: "OCU",
        component: OccupationList,
        layout: "/admin",
        accessRole: ALL_ROLE,
        accessName: "occupationListManagement",
      },
      {
        path: "/announcement",
        name: "Announcement",
        mini: "Ann",
        component: Announcement,
        layout: "/admin",
        accessRole: ALL_ROLE,
        accessName: "announcementManagement",
      },
      {
        path: "/v-list",
        name: "Visa List",
        mini: "VIS",
        component: VisaList,
        layout: "/admin",
        accessRole: ALL_ROLE,
        accessName: "visaListManagement",
      },
      {
        path: "/university",
        name: "University",
        mini: "UNI",
        component: University,
        layout: "/admin",
        accessRole: SUPER_ROLE,
        accessName: "universityManagement",
      },
      {
        path: "/course",
        name: "Course",
        mini: "CO",
        component: Course,
        layout: "/admin",
        accessRole: SUPER_ROLE,
        accessName: "courseManagement",
      },
      {
        path: "/source",
        name: "Source",
        mini: "SO",
        component: Source,
        layout: "/admin",
        accessRole: SUPER_ROLE,
        accessName: "sourceManagement",
      },
      {
        path: "/settings",
        name: "Settings",
        mini: "SET",
        component: Setting,
        layout: "/admin",
        accessRole: SUPER_ROLE,
        accessName: "settingManagement",
      },
    ],
  },
];

const authRoutes = [
  {
    path: "/login",
    name: "Login Page",
    mini: "L",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/reset-password",
    name: "Password Reset Page",
    mini: "PR",
    component: ResetPasswordPage,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Password Reset Page",
    mini: "PR",
    component: ForgotPasswordPage,
    layout: "/auth",
  },
];

const publicRoutes = [
  {
    path: "/sign-up",
    name: "Sign Up Page",
    mini: "L",
    component: SignUpPage,
    layout: "/public",
  },
  {
    path: "/seminar",
    name: "Seminar Page",
    mini: "L",
    component: SeminarPage,
    layout: "/public",
  },
  {
    path: "/appointment",
    name: "Appointment Page",
    mini: "A",
    component: AppointmentPage,
    layout: "/public",
  },
  {
    path: "/checkin",
    name: "Check-In Page",
    mini: "C",
    component: CheckInPage,
    layout: "/public",
  },
  {
    path: "/clockin",
    name: "Clock-In Page",
    mini: "CI",
    component: ClockInPage,
    layout: "/public",
  },
  {
    path: "/immigration",
    name: "Student Migration Page",
    mini: "SMP",
    component: MigrationPage,
    layout: "/public/form",
  },
];

export const authRoute = authRoutes;

export const publicRoute = publicRoutes;

export default dashRoutes;
