import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Datetime from "react-datetime";
import moment from "moment";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  convertDate,
  getDropDown,
  getUserName,
  isValidDate,
} from "../../../scripts/util";
import {
  CLEAR_ERROR,
  AGREEMENT_CREATE_REQUESTED,
  AGREEMENT_GET_ALL_REQUESTED,
  AGREEMENT_UPDATE_REQUESTED,
} from "../../../redux/actions";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import ReactGA from "react-ga4";
import { DEFAULT_DATE_FORMAT, TINY_INIT } from "../../../scripts/const";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileUpload from "../../../components/CustomUpload/FileUpload";
import { Editor } from "@tinymce/tinymce-react";
import Muted from "../../../components/Typography/Muted";
import DropDownWithSearch from "../../../components/DropDownWithSearch/DropDownWithSearch";

const useStyles = makeStyles(styles);

export default function AgreementContainer({
  open,
  setOpen,
  editData = {},
  isEdit,
}) {
  const initialValues = {
    id: "",
    universityId: "",
    startDate: "",
    endDate: "",
    commission: "",
    location: "",
    status: "",
    otherStatus: "",
    note: "",
    type: "",
    group: "",
    webLink: "",
    fileUrl: "",
    files: [],
    updatedBy: "",
    updatedAt: "",
    createdAt: "",
  };

  const dispatch = useDispatch();
  const classes = useStyles();
  const universities = useSelector((state) => state.university.universities);
  const allUsers = useSelector((state) => state.user.users);

  const createEditSuccess = useSelector(
    (state) => state.agreement.agreementCreateEditSuccess
  );
  const createEditProgress = useSelector(
    (state) => state.agreement.agreementCreateEditFetching
  );
  const createEditFailure = useSelector(
    (state) => state.agreement.agreementCreateEditFailure
  );
  const errorMsg = useSelector((state) => state.agreement.errorMsg);

  useEffect(() => {
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      for (let field of Object.keys(initialValues)) {
        if (editData[field] !== null) {
          setFieldValue(field, editData[field], false);
        }
      }
    }
  }, [editData]);

  useEffect(() => {
    if (createEditSuccess === true) {
      setTimeout(() => {
        setOpen(false);
      }, 700);
      dispatch({ type: AGREEMENT_GET_ALL_REQUESTED });
      setTimeout(() => {
        resetForm(initialValues);
        dispatch({ type: CLEAR_ERROR });
      }, 701);
    }
    if (open === false) {
      resetForm(initialValues);
    }
  }, [createEditSuccess, open]);

  // Custom Validation
  const validate = (values) => {
    const errors = {};
    if (values.status === "Other" && !values.otherStatus) {
      errors.otherStatus = "Other Status is required";
    }

    if (values.startDate && values.startDate !== "") {
      if (!isValidDate(values.startDate)) {
        errors.startDate = "Start Date is Invalid";
      }
    }

    if (values.endDate && values.endDate !== "") {
      if (!isValidDate(values.endDate)) {
        errors.endDate = "End Date is Invalid";
      }
    }
    return errors;
  };

  const onSubmit = (values) => {
    if (isEdit) {
      const payload = values;
      dispatch({
        type: AGREEMENT_UPDATE_REQUESTED,
        payload,
        id: values.id,
      });
      ReactGA.event({
        category: "Agreement",
        action: "Update an Agreement",
      });
    } else {
      const payload = { ...values };
      delete payload.id; // remove id from payload
      dispatch({
        type: AGREEMENT_CREATE_REQUESTED,
        payload,
      });
      ReactGA.event({
        category: "Agreement",
        action: "Add an Agreement",
      });
    }
  };

  const mySchema = () => {
    return Yup.object({
      id: Yup.number(),
      universityId: Yup.string().required("University is required"),
      startDate: Yup.string(),
      endDate: Yup.string(),
      commission: Yup.number().typeError("Please only enter number"),
      location: Yup.string(),
      status: Yup.string().required("Status is required"),
      otherStatus: Yup.string(),
      note: Yup.string(),
      type: Yup.string(),
      group: Yup.string(),
      webLink: Yup.string(),
      fileUrl: Yup.string(),
      files: Yup.array(),
      updatedBy: Yup.string(),
      updatedAt: Yup.string(),
      createdAt: Yup.string(),
    });
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
    submitForm,
  } = useFormik({
    initialValues,
    validationSchema: mySchema(),
    validate,
    onSubmit,
  });

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      const dateStr = date.format(DEFAULT_DATE_FORMAT).toString();
      setFieldValue(name, dateStr);
    } else {
      setFieldValue(name, date);
    }
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {createEditFailure && (
                    <SnackbarContent
                      message={errorMsg || "Something went wrong."}
                      color="warning"
                    />
                  )}
                  {createEditProgress && (
                    <CustomLinearProgress color="primary" />
                  )}
                  {createEditSuccess && (
                    <SnackbarContent message="Success" color="success" />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  {universities && (
                    <DropDownWithSearch
                      dbOptions={universities}
                      selectedOption={values.universityId || ""}
                      setFieldValue={setFieldValue}
                      currentState={values}
                      name={"universityId"}
                      label={"University *"}
                    >
                      {touched?.universityId && errors?.universityId && (
                        <div style={{ color: "red" }}>
                          {errors.universityId}
                        </div>
                      )}
                    </DropDownWithSearch>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("agreement-type")}
                    selectedOption={values.type || ""}
                    setFieldValue={setFieldValue}
                    currentState={values}
                    name={"type"}
                    label={"Type"}
                  >
                    {touched?.type && errors?.type && (
                      <div style={{ color: "red" }}>{errors.type}</div>
                    )}
                  </DropDownWithSearch>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("agreement-group")}
                    selectedOption={values.group || ""}
                    setFieldValue={setFieldValue}
                    currentState={values}
                    name={"group"}
                    label={"Group"}
                  >
                    {touched?.group && errors?.group && (
                      <div style={{ color: "red" }}>{errors.group}</div>
                    )}
                  </DropDownWithSearch>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    error={!!(touched.webLink && errors.webLink)}
                    helperText={
                      touched.webLink && errors.webLink ? errors.webLink : ""
                    }
                    labelText="Web Link"
                    id="agreement-weblink"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onBlur: handleBlur,
                      value: values.webLink,
                      onChange: handleChange,
                      name: "webLink",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Start Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={values.startDate}
                      onChange={(moment) =>
                        handleDatePicker(moment, "startDate")
                      }
                      inputProps={{
                        name: "startDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                  {touched?.startDate && errors?.startDate && (
                    <div style={{ color: "red" }}>{errors.startDate}</div>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>End Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={values.endDate}
                      onChange={(moment) => handleDatePicker(moment, "endDate")}
                      inputProps={{
                        name: "endDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                  {touched?.endDate && errors?.endDate && (
                    <div style={{ color: "red" }}>{errors.endDate}</div>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={!!(touched.commission && errors.commission)}
                    helperText={
                      touched.commission && errors.commission
                        ? errors.commission
                        : ""
                    }
                    labelText="Commission (%)"
                    id="agreement-commission"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onBlur: handleBlur,
                      value: values.commission,
                      onChange: handleChange,
                      name: "commission",
                      type: "number",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={!!(touched.location && errors.location)}
                    helperText={
                      touched.location && errors.location ? errors.location : ""
                    }
                    labelText="Location"
                    id="agreement-location"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onBlur: handleBlur,
                      value: values.location,
                      onChange: handleChange,
                      name: "location",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("agreement-status")}
                    selectedOption={values.status || ""}
                    setFieldValue={setFieldValue}
                    currentState={values}
                    name={"status"}
                    label={"Status *"}
                  >
                    {touched?.status && errors?.status && (
                      <div style={{ color: "red" }}>{errors.status}</div>
                    )}
                  </DropDownWithSearch>
                </GridItem>
                {values.status === "Other" && (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      error={!!(touched.otherStatus && errors.otherStatus)}
                      helperText={
                        touched.otherStatus && errors.otherStatus
                          ? errors.otherStatus
                          : ""
                      }
                      labelText="Other Status"
                      id="agreement-otherStatus"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onBlur: handleBlur,
                        value: values.otherStatus,
                        onChange: handleChange,
                        name: "otherStatus",
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={values.note || ""}
                    init={TINY_INIT}
                    onEditorChange={(value) => setFieldValue("note", value)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div>
                    <h4>Agreement File:</h4>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      open={open}
                      setFileUrl={(fileUrl) =>
                        setFieldValue("files", [...values.files, fileUrl])
                      }
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {values.files &&
                  values.files.length > 0 &&
                  values.files.reverse().map((fileUrl, id) => {
                    return (
                      <GridItem key={id}>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            backgroundColor: "#B0BEC5",
                            padding: "20px",
                            borderRadius: "25px",
                            marginTop: "10px",
                          }}
                        >
                          <FileUpload
                            fileUrl={fileUrl}
                            key={id}
                            open={open}
                            handleDelete={(fileUrl) => {
                              setFieldValue(
                                "files",
                                values.files.filter((f) => f != fileUrl)
                              );
                            }}
                          />
                        </div>
                      </GridItem>
                    );
                  })}
              </GridContainer>

              <br />
              <br />
              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={submitForm}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
              {isEdit && (
                <>
                  <Muted>Updated At: {convertDate(values.updatedAt)}</Muted>
                  <Muted>
                    Updated By: {getUserName(values.updatedBy, allUsers)}
                  </Muted>
                </>
              )}
              {alert}
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
