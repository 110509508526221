import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function Muted(props) {
  const classes = useStyles();
  const { children, center = false } = props;
  return (
    <div
      className={
        classes.defaultFontStyle +
        " " +
        classes.mutedText +
        " " +
        (center ? "" : classes.moveToLeft)
      }
    >
      {children}
    </div>
  );
}

Muted.propTypes = {
  children: PropTypes.node,
};
