import {
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGOUT_SUCCEEDED,
} from "../actions";

const defaultState = {
  authorized: false,
  loginUser: {
    token: null,
    detail: {},
    expiry: null,
  },
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return {
        ...state,
        authorized: false,
      };
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        loginUser: {
          token: action.payload.token,
          detail: action.payload.user,
          expiry: action.payload.expiry,
        },
        authorized: true,
        loginError: false,
        loginProgress: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        authorized: false,
      };
    case LOGOUT_SUCCEEDED:
      return { defaultState };
    default:
      return state;
  }
}
