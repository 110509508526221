import {
  OCCUPATION_CREATE_REQUESTED,
  OCCUPATION_CREATE_FAILED,
  OCCUPATION_CREATE_SUCCEEDED,
  OCCUPATION_DELETE_REQUESTED,
  OCCUPATION_DELETE_FAILED,
  OCCUPATION_DELETE_SUCCEEDED,
  OCCUPATION_GET_ALL_REQUESTED,
  OCCUPATION_GET_ALL_FAILED,
  OCCUPATION_GET_ALL_SUCCEEDED,
  OCCUPATION_GET_REQUESTED,
  OCCUPATION_GET_FAILED,
  OCCUPATION_GET_SUCCEEDED,
  OCCUPATION_LOG_GET_REQUESTED,
  OCCUPATION_LOG_GET_FAILED,
  OCCUPATION_LOG_GET_SUCCEEDED,
  OCCUPATION_UPDATE_REQUESTED,
  OCCUPATION_UPDATE_FAILED,
  OCCUPATION_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  occupationCreateError: false,
  occupationCreateFetching: false,
  occupationCreateSuccess: false,

  occupationDeleteError: false,
  occupationDeleteFetching: false,
  occupationDeleteSuccess: false,

  occupationUpdateError: false,
  occupationUpdateFetching: false,
  occupationUpdateSuccess: false,

  occupationGetError: false,
  occupationGetFetching: false,
  occupationGetSuccess: false,

  occupationLogGetError: false,
  occupationLogGetFetching: false,
  occupationLogGetSuccess: false,

  occupationGetAllError: false,
  occupationGetAllSuccess: false,
  errorMsg: "",
};

const defaultState = {
  occupations: [],
  occupationLog: [],
  occupationDetail: {},
  occupationGetAllFetching: false,
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case OCCUPATION_CREATE_REQUESTED:
      return {
        ...state,
        occupationCreateFetching: true,
        occupationCreateError: false,
        occupationCreateSuccess: false,
      };
    case OCCUPATION_CREATE_FAILED:
      return {
        ...state,
        occupationCreateFetching: false,
        occupationCreateError: true,
        occupationCreateSuccess: false,
        errorMsg: action.payload,
      };
    case OCCUPATION_CREATE_SUCCEEDED:
      return {
        ...state,
        occupationDetail: action.payload,
        occupationCreateFetching: false,
        occupationCreateError: false,
        occupationCreateSuccess: true,
      };

    case OCCUPATION_DELETE_REQUESTED:
      return {
        ...state,
        occupationDeleteFetching: true,
        occupationDeleteError: false,
        occupationDeleteSuccess: false,
      };
    case OCCUPATION_DELETE_FAILED:
      return {
        ...state,
        occupationDeleteFetching: false,
        occupationDeleteError: true,
        occupationDeleteSuccess: false,
        errorMsg: action.payload,
      };
    case OCCUPATION_DELETE_SUCCEEDED:
      return {
        ...state,
        occupationDeleteFetching: false,
        occupationDeleteError: false,
        occupationDeleteSuccess: true,
      };

    case OCCUPATION_UPDATE_REQUESTED:
      return {
        ...state,
        occupationUpdateFetching: true,
        occupationUpdateError: false,
        occupationUpdateSuccess: false,
      };
    case OCCUPATION_UPDATE_FAILED:
      return {
        ...state,
        occupationUpdateFetching: false,
        occupationUpdateError: true,
        occupationUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case OCCUPATION_UPDATE_SUCCEEDED:
      return {
        ...state,
        occupationDetail: action.payload,
        occupationUpdateFetching: false,
        occupationUpdateError: false,
        occupationUpdateSuccess: true,
      };

    case OCCUPATION_GET_REQUESTED:
      return {
        ...state,
        occupationGetFetching: true,
        occupationGetError: false,
      };
    case OCCUPATION_GET_FAILED:
      return {
        ...state,
        occupationGetFetching: false,
        occupationGetError: true,
      };
    case OCCUPATION_GET_SUCCEEDED:
      return {
        ...state,
        occupationDetail: action.payload,
        occupationGetFetching: false,
        occupationGetError: false,
      };

  
    case OCCUPATION_GET_ALL_REQUESTED:
      return {
        ...state,
        occupationGetAllFetching: true,
        occupationGetAllError: false,
        occupationGetAllSuccess: false,
      };
    case OCCUPATION_GET_ALL_FAILED:
      return {
        ...state,
        occupationGetAllFetching: false,
        occupationGetAllError: true,
        occupationGetAllSuccess: false,
      };
    case OCCUPATION_GET_ALL_SUCCEEDED:
      return {
        ...state,
        occupations: action.payload,
        occupationGetAllFetching: false,
        occupationGetAllError: false,
        occupationGetAllSuccess: true,
      };
    default:
      return state;
  }
}
