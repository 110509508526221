import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import ReactGA from "react-ga4";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import history from "../../history";

import { PASSWORD_RESET_REQUESTED } from "../../redux/actions";
import Icon from "@material-ui/core/Icon";
import Version from "../../components/Version";
const useStyles = makeStyles(styles);
const qs = require("qs");

const ResetPasswordPage = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [user, setState] = React.useState({
    password: "",
    confirmPassword: "",
  });
  const [passState, setPassState] = React.useState("");
  const [passConfirmState, setPassConfirmState] = React.useState("");

  const dispatch = useDispatch();

  const resetProgress = useSelector(
    (state) => state.password.passwordResetFetching
  );
  const resetSuccess = useSelector(
    (state) => state.password.passwordResetSuccess
  );
  const resetError = useSelector((state) => state.password.passwordResetError);

  function resetPassword(props) {
    let allFieldGood = true;
    if (user.password.length >= 6) {
      allFieldGood = true;
      setPassState("success");
    } else {
      allFieldGood = false;
      setPassState("error");
    }
    if (user.confirmPassword.length >= 6) {
      allFieldGood = true;
      setPassConfirmState("success");
    } else {
      allFieldGood = false;
      setPassConfirmState("error");
      return;
    }
    if (user.confirmPassword !== user.password) {
      allFieldGood = false;
      setPassState("error");
      setPassConfirmState("error");
      return;
    }
    if (allFieldGood) {
      const token = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }).token;
      if (!token || token.length > 10) {
        dispatch({
          type: PASSWORD_RESET_REQUESTED,
          payload: { password: user.password },
          token,
        });
      }
    }
  }

  useEffect(() => {
    if (resetSuccess) {
      setTimeout(() => {
        history.push("/auth/login");
      }, 500);
    }
  }, [resetSuccess]);

  const handleChange = (e) => {
    setState({
      ...user,
      [e.target.id]: e.target.value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      resetPassword(props);
    }
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Reset Password</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  success={passState === "success"}
                  error={passState === "error"}
                  labelText="New Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    onKeyDown: handleKeyDown,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
                <CustomInput
                  success={passConfirmState === "success"}
                  error={passConfirmState === "error"}
                  labelText="Confirm New Password"
                  id="confirmPassword"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    onKeyDown: handleKeyDown,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
                <small className={classes.resetPass}>
                  * Password has to be minimum 6 char long.
                </small>
                {resetProgress && <CustomLinearProgress color="primary" />}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  disabled={resetProgress}
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={() => {
                    ReactGA.event({
                      category: "Login",
                      action: "Reset Password",
                    });
                    resetPassword(props);
                  }}
                >
                  Reset
                </Button>
              </CardFooter>
              {resetSuccess && (
                <SnackbarContent
                  message={"Password Reset Done"}
                  color="success"
                />
              )}
              {resetError && (
                <SnackbarContent
                  message={"Something went wrong. Try again."}
                  color="danger"
                />
              )}
            </Card>
            <Version />
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ResetPasswordPage;
