import React, { useEffect } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { useDispatch, useSelector } from "react-redux";
import {
  hasRoleAccess,
  initiateApiCalls,
  isAnyoneWaiting,
} from "../scripts/util";
import ReactGA from "react-ga4";
import { LOGO_VERTICAL, TENANT } from "../scripts/const";

var ps;

const useStyles = makeStyles(styles);

const Dashboard = (props) => {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color] = React.useState("blue");
  const [bgColor] = React.useState("black");
  const [logo] = React.useState(LOGO_VERTICAL);
  const currentUser = useSelector((state) => state.login.loginUser.detail);
  const appointmentDetail = useSelector(
    (state) => state.appointment.appointments
  );
  const checkinData = useSelector((state) => state.checkin.checkins);

  const dispatch = useDispatch();
  React.useEffect(() => {
    initiateApiCalls(dispatch, currentUser?.roleId);
  }, []);

  const createNotification = (type, message, subtitle, timeout) => {
    switch (type) {
      case "info":
        return NotificationManager.info(
          subtitle,
          message || "Info message",
          timeout || 300000
        );
      case "success":
        return NotificationManager.success(
          subtitle,
          message || "Info message",
          timeout || 300000
        );
      case "warning":
        return NotificationManager.warning(
          subtitle,
          message || "Info message",
          timeout || 300000
        );
      case "error":
        return NotificationManager.error(
          subtitle,
          message || "Info message",
          timeout || 300000
        );
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const isWaiting = isAnyoneWaiting(appointmentDetail, checkinData);
      isWaiting.forEach((client) => {
        createNotification(
          "info",
          `Reminder: ${client.firstName} ${client.lastName}`,
          `Waiting since ${client.waitTime}`
        );
        ReactGA.event({
          category: "Notification",
          action: "Client Waiting",
        });
      });
    }, 600000); // every 10 min, 600000 ms
    return () => clearInterval(timer);
  });

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "CRM";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getParamRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getParamRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        if (
          prop.accessRole.includes(currentUser.roleId) ||
          (prop.accessName
            ? hasRoleAccess(prop.accessName, currentUser)
            : false)
        ) {
          if (prop.supportParam) {
            const component = prop.paramComponent || prop.component;
            return (
              <Route
                key={key}
                path={prop.layout + prop.path + "/:id"}
                component={component}
              />
            );
          }
        }
        return null;
      } else {
        return null;
      }
    });
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        if (
          prop.accessRole.includes(currentUser.roleId) ||
          (prop.accessName
            ? hasRoleAccess(prop.accessName, currentUser)
            : false)
        ) {
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              component={prop.component}
            />
          );
        }
        return null;
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const redirectForRoles = (roleId) => {
    if (roleId == 3) {
      return <Redirect from="/admin" to="/admin/user-dashboard" />;
    } else if (roleId == 4) {
      return <Redirect from="/admin" to="/admin/employees" />;
    } else {
      return <Redirect from="/admin" to="/admin/dashboard" />;
    }
  };
  return (
    <div className={classes.wrapper}>
      <Sidebar
        currentUser={currentUser}
        routes={routes}
        logoText={TENANT.toUpperCase()}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {getParamRoutes(routes)}
              {getRoutes(routes)}
              {redirectForRoles(currentUser.roleId)}
            </Switch>
          </div>
        </div>
        <Footer fluid />
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Dashboard;
