import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { Editor } from "@tinymce/tinymce-react";
import { default as ReactSelect } from "react-select";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import InputLabel from "@material-ui/core/InputLabel";

import { useDispatch, useSelector } from "react-redux";
import {
  getAnzsco,
  getDropDown,
  getOccupation,
  isValidLeadInputs,
} from "../../../scripts/util";
import {
  CLEAR_ERROR,
  LEAD_CREATE_REQUESTED,
  LEAD_GET_ALL_REQUESTED,
} from "../../../redux/actions";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import ReactGA from "react-ga4";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import Datetime from "react-datetime";
import {
  DEFAULT_DATE_FORMAT,
  GENERAL_ROLE,
  ROLES,
  locationOption,
  occupationCodeOption,
  useGetOccupationOptions,
  useGetVisaTypeOptions,
  TINY_INIT,
} from "../../../scripts/const";
import moment from "moment";
import DropDownWithSearch, {
  customReactSelectStyles,
} from "../../../components/DropDownWithSearch/DropDownWithSearch";
import LocationAutoComplete from "components/LocationAutocomplete/LocationAutocomplete.js";
import FileUpload from "../../../components/CustomUpload/FileUpload";

const useStyles = makeStyles(styles);

export default function AddLead(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const occupationOption = useGetOccupationOptions();
  const visaTypeOption = useGetVisaTypeOptions();
  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);
  const isLeadGenerator = ROLES.LEAD_MANAGEMENT === currentRoleId;
  const [validationErrorMsg, setValidationErrorMsg] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [lead, setLead] = React.useState({});

  const handleFileUpload = (fileUrl) => {
    if (!lead.files) {
      setLead({
        ...lead,
        files: [fileUrl],
      });
    } else {
      setLead({
        ...lead,
        files: [...lead.files, fileUrl],
      });
    }
  };

  const handleFileDelete = (fileUrl) => {
    setLead({
      ...lead,
      files: lead.files.filter((f) => f != fileUrl),
    });
  };

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setLead({
        ...lead,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setLead({
        ...lead,
        [name]: date,
      });
    }
  };

  const handleEditorChange = (content) => {
    setLead({
      ...lead,
      note: content,
    });
  };

  const handleSelect = (e) => {
    if (e.target.name == "serviceType") {
      setLead({
        ...lead,
        [e.target.name]: JSON.stringify(e.target.value),
      });
    } else {
      setLead({
        ...lead,
        [e.target.name]: e.target.value,
      });
    }
  };

  const dispatch = useDispatch();

  const saveError = useSelector((state) => state.lead.leadCreateError);
  const saveSuccess = useSelector((state) => state.lead.leadCreateSuccess);
  const saveProgress = useSelector((state) => state.lead.leadCreateFetching);
  const errMsg = useSelector((state) => state.lead.errorMsg);
  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );
  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);

  function createLead() {
    const errors = isValidLeadInputs(lead);
    if (errors.length > 0) {
      setValidationErrorMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else {
      setValidationErrorMsg("");
      dispatch({ type: LEAD_CREATE_REQUESTED, payload: lead });
    }
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      // setLead({});
    }
  }, [modalOpen]);

  useEffect(() => {
    setValidationErrorMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    setError(saveError);
  }, [saveError]);

  useEffect(() => {
    setSuccess(saveSuccess);
  }, [saveSuccess]);

  useEffect(() => {
    if (saveSuccess === true) {
      setTimeout(() => {
        props.handleSuccess(false);
      }, 700);
      dispatch({ type: LEAD_GET_ALL_REQUESTED });
      setTimeout(() => {
        setLead({});
        dispatch({ type: CLEAR_ERROR });
      }, 701);
    }
  }, [saveSuccess]);

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {validationErrorMsg && (
                    <SnackbarContent
                      message={validationErrorMsg}
                      color="warning"
                    />
                  )}
                  {error && (
                    <SnackbarContent message={errMessage} color="warning" />
                  )}
                  {saveProgress && <CustomLinearProgress color="primary" />}
                  {success && (
                    <SnackbarContent message="Added the lead" color="success" />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email Address *"
                    id="lead-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lead.email || "",
                      onChange: handleSelect,
                      name: "email",
                      type: "email",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="lead-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lead.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name *"
                    id="lead-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lead.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Middle Name *"
                    id="lead-middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lead.middleName || "",
                      onChange: handleSelect,
                      name: "middleName",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name *"
                    id="lead-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lead.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Date of Birth
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={lead.dob || ""}
                      onChange={(moment) => handleDatePicker(moment, "dob")}
                      inputProps={{
                        name: "dob",
                        id: "lead-dob-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Passport No"
                    id="lead-passport"
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lead.passport,
                      onChange: handleSelect,
                      name: "passport",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Issue Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={lead.issueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "issueDate")
                      }
                      inputProps={{
                        name: "issueDate",
                        id: "lead-issueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Expiry Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={lead.expiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "expiryDate")
                      }
                      inputProps={{
                        name: "expiryDate",
                        id: "lead-expiryDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <LocationAutoComplete
                    defaultValue={lead.address}
                    onPlaceSelected={(value) =>
                      handleSelect({
                        target: {
                          name: "address",
                          value: value?.display_name,
                        },
                      })
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Qualification"
                    id="lead-qualification"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lead.qualification || "",
                      onChange: handleSelect,
                      name: "qualification",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={{
                        visibility: lead.visa ? "" : "hidden",
                        color: "#AAA",
                        fontSize: "11px",
                        lineHeight: "1.428571429",
                        fontWeight: "400",
                        display: "inline-flex",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      Visa
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        lead.visa ? { label: lead.visa, value: lead.visa } : ""
                      }
                      options={visaTypeOption}
                      styles={customReactSelectStyles}
                      placeholder={"Visa"}
                      name="visa"
                      onChange={(options) => {
                        if (!options) {
                          setLead({
                            ...lead,
                            visa: "",
                          });
                        } else {
                          setLead({
                            ...lead,
                            visa: options.value,
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                {lead.visaExpiry && (
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel
                      className={classes.label}
                      style={{
                        visibility: lead.visaExpiry ? "" : "hidden",
                        color: "#AAA",
                        fontSize: "11px",
                        lineHeight: "1.428571429",
                        fontWeight: "400",
                        display: "inline-flex",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      Visa Expiry
                    </InputLabel>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        closeOnSelect
                        timeFormat={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        value={lead.visaExpiry || ""}
                        onChange={(moment) =>
                          handleDatePicker(moment, "visaExpiry")
                        }
                        inputProps={{
                          name: "visaExpiry",
                          id: "add-lead-visaExpirt",
                          placeholder: DEFAULT_DATE_FORMAT,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={{
                        visibility: lead.occupation ? "" : "hidden",
                        color: "#AAA",
                        fontSize: "11px",
                        lineHeight: "1.428571429",
                        fontWeight: "400",
                        display: "inline-flex",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      Occupation
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        lead.occupation
                          ? { label: lead.occupation, value: lead.occupation }
                          : ""
                      }
                      options={occupationOption}
                      styles={customReactSelectStyles}
                      placeholder={"Occupation"}
                      name="occupation"
                      onChange={(options) => {
                        if (!options) {
                          setLead({
                            ...lead,
                            occupation: "",
                            anzsco: "",
                          });
                        } else {
                          setLead({
                            ...lead,
                            occupation: options.value,
                            anzsco: getAnzsco(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={{
                        visibility: lead.anzsco ? "" : "hidden",
                        color: "#AAA",
                        fontSize: "11px",
                        lineHeight: "1.428571429",
                        fontWeight: "400",
                        display: "inline-flex",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      ANZSCO
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        lead.anzsco
                          ? { label: lead.anzsco, value: lead.anzsco }
                          : ""
                      }
                      options={occupationCodeOption}
                      name="anzsco"
                      styles={customReactSelectStyles}
                      placeholder={"ANZSCO"}
                      onChange={(options) => {
                        if (!options) {
                          setLead({
                            ...lead,
                            anzsco: "",
                            occupation: "",
                          });
                        } else {
                          setLead({
                            ...lead,
                            anzsco: options.value,
                            occupation: getOccupation(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={{
                        visibility: lead.location ? "" : "hidden",
                        color: "#AAA",
                        fontSize: "11px",
                        lineHeight: "1.428571429",
                        fontWeight: "400",
                        display: "inline-flex",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      Location
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        lead.location
                          ? { label: lead.location, value: lead.location }
                          : ""
                      }
                      options={locationOption}
                      name="location"
                      styles={customReactSelectStyles}
                      placeholder={"Location"}
                      onChange={(options) => {
                        if (!options) {
                          setLead({
                            ...lead,
                            location: "",
                          });
                        } else {
                          setLead({
                            ...lead,
                            location: options.value,
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    isMulti
                    optionsList={getDropDown("lead-service-type")}
                    selectedOption={
                      lead.serviceType ? JSON.parse(lead.serviceType) : []
                    }
                    setValue={setLead}
                    currentState={lead}
                    name={"serviceType"}
                    label={"Service Type *"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("country")}
                    selectedOption={lead.country || ""}
                    setValue={setLead}
                    currentState={lead}
                    name={"country"}
                    label={"Country"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("lead-status")}
                    selectedOption={lead.status || ""}
                    setValue={setLead}
                    currentState={lead}
                    name={"status"}
                    label={"Status *"}
                  />
                </GridItem>
                {lead.status === "Follow Up" && (
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel className={classes.label}>
                      Follow Up Date *
                    </InputLabel>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        closeOnSelect
                        timeFormat={false}
                        dateFormat={DEFAULT_DATE_FORMAT}
                        value={lead.followUpDate || ""}
                        onChange={(moment) =>
                          handleDatePicker(moment, "followUpDate")
                        }
                        inputProps={{
                          name: "followUpDate",
                          id: "add-lead-followUpDate",
                          placeholder: DEFAULT_DATE_FORMAT,
                        }}
                      />
                    </FormControl>
                  </GridItem>
                )}
              </GridContainer>
              {!isLeadGenerator && (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        {props.allSources && (
                          <DropDownWithSearch
                            dbOptions={props.allSources}
                            selectedOption={lead.sourceId || ""}
                            setValue={setLead}
                            currentState={lead}
                            name={"sourceId"}
                            label={"Source"}
                          />
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        {props.allUsers && (
                          <DropDownWithSearch
                            dbOptions={props.allUsers}
                            dbLabel={"firstName"}
                            selectedOption={lead.userId || ""}
                            setValue={setLead}
                            currentState={lead}
                            name={"userId"}
                            label={"Assigned To"}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              )}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={lead.note || ""}
                    init={TINY_INIT}
                    onEditorChange={handleEditorChange}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem>
                  <div>
                    <h4>Documents:</h4>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      type={"lead"}
                      setFileUrl={(fileUrl) => handleFileUpload(fileUrl)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {lead.files &&
                  lead.files.length > 0 &&
                  lead.files.map((fileUrl, id) => {
                    return (
                      <GridItem key={id}>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            backgroundColor: "#B0BEC5",
                            padding: "20px",
                            borderRadius: "25px",
                            marginTop: "10px",
                          }}
                        >
                          <FileUpload
                            fileUrl={fileUrl}
                            key={id}
                            handleDelete={(fileUrl) => {
                              handleFileDelete(fileUrl);
                            }}
                          />
                        </div>
                      </GridItem>
                    );
                  })}
              </GridContainer>

              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={(e) => {
                  e.preventDefault();
                  ReactGA.event({
                    category: "Lead",
                    action: "Added a Lead",
                  });
                  createLead();
                }}
              >
                Add
              </Button>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
