import React, { useEffect } from "react";
import { useFormik } from "formik";
import SweetAlert from "react-bootstrap-sweetalert";
import { Lion as LoaderButton } from "react-button-loaders";

import styles from "assets/jss/material-dashboard-pro-react/views/checkInPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import PhoneIcon from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
import Note from "@material-ui/icons/Note";
import GridContainer from "../../../components/Grid/GridContainer";
import Checkbox from "@material-ui/core/Checkbox";
import {
  checkInSchema,
  getDropDown,
  isValidPhone,
} from "../../../scripts/util";
import {
  CHECKIN_NEW_CREATE_REQUESTED,
  CLEAR_ERROR,
} from "../../../redux/actions";
import ReactGA from "react-ga4";
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { CLIENT_SOURCE_OFFICE_CHECKIN } from "../../../scripts/const";

const useStyles = makeStyles(styles);

const addCaptcha = false;
const initialValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  note: "",
  country: "",
  education: false,
  visa: false,
  skillAssessment: false,
  healthInsurance: false,
  other: false,
};

export default function NewCheckin({ openModel }) {
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();

  const [confirmAlert, setAlert] = React.useState(null);
  const createError = useSelector(
    (state) => state.checkin.checkinNewCreateError
  );
  const errMsg = useSelector((state) => state.checkin.errorMsg);
  const createSuccess = useSelector(
    (state) => state.checkin.checkinNewCreateSuccess
  );
  const createProgress = useSelector(
    (state) => state.checkin.checkinNewCreateFetching
  );

  useEffect(() => {
    if (createSuccess) {
      successAlert();
    }
  }, [createSuccess]);

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Checked In"
        onConfirm={() => {
          openModel(false);
          setAlert(null);
          dispatch({
            type: CLEAR_ERROR,
          });
        }}
        onCancel={() => {
          openModel(false);
          setAlert(null);
          dispatch({
            type: CLEAR_ERROR,
          });
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Please be seated. Someone will be with you shortly.
      </SweetAlert>
    );
  };

  const getLoadingState = () => {
    if (createProgress) {
      return "loading";
    }
    return null;
  };

  const validate = (values) => {
    const errors = {};
    if (
      !(
        values.education ||
        values.visa ||
        values.skillAssessment ||
        values.healthInsurance ||
        values.other
      )
    ) {
      errors.education = "Select at least one service";
    }
    if (values.phone) {
      if (!isValidPhone(values.phone)) {
        errors.phone = "Phone number not valid";
      }
    }
    return errors;
  };

  const getLeadPayload = (values) => {
    const { education, visa, skillAssessment, healthInsurance, other } = values;
    const payload = {};
    payload.firstName = values.firstName;
    payload.lastName = values.lastName;
    payload.phone = values.phone;
    payload.email = values.email;
    payload.note = values.note;
    payload.country = values.country;
    const services = [];
    if (education) {
      services.push("Education");
    }
    if (visa) {
      services.push("Visa");
    }
    if (skillAssessment) {
      services.push("Skill Assessment");
    }
    if (healthInsurance) {
      services.push("Health Insurance");
    }
    if (other) {
      services.push("Other");
    }
    payload.serviceType = JSON.stringify(services);
    payload.sourceId = CLIENT_SOURCE_OFFICE_CHECKIN;
    payload.status = "New";
    payload.isConsent = true;
    return payload;
  };

  const onSubmit = async (values) => {
    const payload = getLeadPayload(values);
    let captcha = "";
    if (addCaptcha) {
      captcha = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
    }

    dispatch({
      type: CHECKIN_NEW_CREATE_REQUESTED,
      payload,
      captcha,
    });
    ReactGA.event({
      category: "Check-In",
      action: "New Customer CheckIn",
    });
  };
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    submitForm,
  } = useFormik({
    initialValues,
    validationSchema: checkInSchema(),
    validate,
    onSubmit,
  });
  const classes = useStyles();
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={6}>
          <CustomInput
            error={!!(touched.firstName && errors.firstName)}
            helperText={
              touched.firstName && errors.firstName ? errors.firstName : ""
            }
            labelText={
              <span>
                First Name <small>(required)</small>
              </span>
            }
            name="firstName"
            id="firstName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onBlur: handleBlur,
              value: values.firstName,
              onChange: handleChange,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            error={!!(touched.lastName && errors.lastName)}
            helperText={
              touched.lastName && errors.lastName ? errors.lastName : ""
            }
            labelText={
              <span>
                Last Name <small>(required)</small>
              </span>
            }
            id="lastName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onBlur: handleBlur,
              value: values.lastName,
              onChange: handleChange,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            error={!!(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone ? errors.phone : ""}
            labelText={
              <span>
                Phone Number <small>(required)</small>
              </span>
            }
            id="phone"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onBlur: handleBlur,
              value: values.phone,
              onChange: handleChange,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <PhoneIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            error={!!(touched.email && errors.email)}
            helperText={touched.email && errors.email ? errors.email : ""}
            labelText={
              <span>
                Email <small>(required)</small>
              </span>
            }
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              onBlur: handleBlur,
              value: values.email,
              onChange: handleChange,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Country of Origin
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={values.country}
              onChange={(e) => setFieldValue("country", e.target.value)}
              inputProps={{
                name: "country",
                id: "country-list",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Service Type
              </MenuItem>
              {getDropDown("country").map((name, i) => {
                return (
                  <MenuItem
                    key={i}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelectedMultiple,
                    }}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <CustomInput
            error={!!(touched.note && errors.note)}
            helperText={touched.note && errors.note ? errors.note : ""}
            labelText={<span>Personal Message</span>}
            id="note"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onBlur: handleBlur,
              value: values.note,
              onChange: handleChange,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Note className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
            }}
          />
        </GridItem>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12}>
            <h6 className={classes.serviceSelection}>Select Service</h6>
            {(touched.education ||
              touched.visa ||
              touched.other ||
              touched.skillAssessment ||
              touched.healthInsurance) &&
              errors.education && (
                <h6 className={classes.serviceError}>{errors.education}</h6>
              )}
          </GridItem>
          <GridItem>
            <div className={classes.choice}>
              <Checkbox
                onBlur={handleBlur}
                size={"medium"}
                onChange={() => setFieldValue("education", !values.education)}
                name={"education"}
                tabIndex={-1}
                checkedIcon={
                  <i
                    className={
                      "fas fa-graduation-cap " + classes.iconCheckboxIcon
                    }
                  />
                }
                icon={
                  <i
                    className={
                      "fas fa-graduation-cap " + classes.iconCheckboxIcon
                    }
                  />
                }
                classes={{
                  checked: classes.iconCheckboxChecked,
                  root: classes.iconCheckbox,
                }}
              />
              <h6>Student</h6>
            </div>
          </GridItem>
          <GridItem>
            <div className={classes.choice}>
              <Checkbox
                size={"medium"}
                onBlur={handleBlur}
                onChange={() => setFieldValue("visa", !values.visa)}
                name={"visa"}
                value={"visa"}
                tabIndex={-1}
                checkedIcon={
                  <i className={"fas fa-file " + classes.iconCheckboxIcon} />
                }
                icon={
                  <i className={"fas fa-file " + classes.iconCheckboxIcon} />
                }
                classes={{
                  checked: classes.iconCheckboxChecked,
                  root: classes.iconCheckbox,
                }}
              />
              <h6>Visa</h6>
            </div>
          </GridItem>
          <GridItem>
            <div className={classes.choice}>
              <Checkbox
                size={"medium"}
                onBlur={handleBlur}
                onChange={() =>
                  setFieldValue("skillAssessment", !values.skillAssessment)
                }
                name={"skillAssessment"}
                value={"skillAssessment"}
                tabIndex={-1}
                checkedIcon={
                  <i
                    className={"fas fa-briefcase " + classes.iconCheckboxIcon}
                  />
                }
                icon={
                  <i
                    className={"fas fa-briefcase " + classes.iconCheckboxIcon}
                  />
                }
                classes={{
                  checked: classes.iconCheckboxChecked,
                  root: classes.iconCheckbox,
                }}
              />
              <h6>Skill Assessment</h6>
            </div>
          </GridItem>
          <GridItem>
            <div className={classes.choice}>
              <Checkbox
                size={"medium"}
                onBlur={handleBlur}
                onChange={() =>
                  setFieldValue("healthInsurance", !values.healthInsurance)
                }
                name={"healthInsurance"}
                value={"healthInsurance"}
                tabIndex={-1}
                checkedIcon={
                  <i
                    className={"fas fa-plus-square " + classes.iconCheckboxIcon}
                  />
                }
                icon={
                  <i
                    className={"fas fa-plus-square " + classes.iconCheckboxIcon}
                  />
                }
                classes={{
                  checked: classes.iconCheckboxChecked,
                  root: classes.iconCheckbox,
                }}
              />
              <h6>Health Insurance</h6>
            </div>
          </GridItem>
          <GridItem>
            <div className={classes.choice}>
              <Checkbox
                size={"medium"}
                onBlur={handleBlur}
                onChange={() => setFieldValue("other", !values.other)}
                name={"other"}
                value={"other"}
                tabIndex={-1}
                checkedIcon={
                  <i className={"fas fa-star " + classes.iconCheckboxIcon} />
                }
                icon={
                  <i className={"fas fa-star " + classes.iconCheckboxIcon} />
                }
                classes={{
                  checked: classes.iconCheckboxChecked,
                  root: classes.iconCheckbox,
                }}
              />
              <h6>Other</h6>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem>
            {addCaptcha && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_INVISIBLE_KEY}
                ref={recaptchaRef}
                size="invisible"
              />
            )}
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent={"center"}>
          <GridItem>
            <LoaderButton
              onClick={submitForm}
              state={getLoadingState()}
              disabled={createProgress}
            >
              Submit
            </LoaderButton>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent={"center"} className={classes.marginDown}>
          <GridItem>
            <div className={classes.errorMsg}>
              {errMsg && createError && <span>{errMsg}</span>}
            </div>
          </GridItem>
        </GridContainer>
      </GridContainer>
      {createSuccess && confirmAlert}
    </>
  );
}
