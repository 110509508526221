import { combineReducers } from "redux";
import LoginReducer from "./Login";
import UserReducer from "./User";
import LoginErrorReducer from "./LoginError";
import LeadReducer from "./Lead";
import StudentReducer from "./Student";
import VisaApplicantReducer from "./VisaApplicant";
import VisaReducer from "./Visa";
import OccupationReducer from "./Occupation";
import InsuranceApplicantReducer from "./InsuranceApplicant";
import SkillAssessmentReducer from "./SkillAssessment";
import UniversityReducer from "./University";
import SourceReducer from "./Source";
import CourseReducer from "./Course";
import PasswordReducer from "./Password";
import SettingReducer from "./Setting";
import SmsEmailReducer from "./SmsEmail";
import PublicReducer from "./Public";
import TodoReducer from "./Todo";
import AppointmentReducer from "./Appointment";
import CheckinReducer from "./Checkin";
import ReportReducer from "./Report";
import AnalyticsReducer from "./Analytics";
import ClockInReducer from "./ClockIn";
import AgreementReducer from "./Agreement";
import ImmigrationReducer from "./Immigration";
import LeaveReducer from "./Leave";
import StatsReducer from "./Stats";
import FaceReducer from "./Face";
import AnnouncementReducer from "./Announcement";

const rootReducer = combineReducers({
  login: LoginReducer,
  loginError: LoginErrorReducer,
  user: UserReducer,
  lead: LeadReducer,
  student: StudentReducer,
  visaApplicant: VisaApplicantReducer,
  visa: VisaReducer,
  occupation: OccupationReducer,
  skillAssessment: SkillAssessmentReducer,
  insuranceApplicant: InsuranceApplicantReducer,
  university: UniversityReducer,
  source: SourceReducer,
  course: CourseReducer,
  password: PasswordReducer,
  setting: SettingReducer,
  smsEmail: SmsEmailReducer,
  public: PublicReducer,
  todo: TodoReducer,
  appointment: AppointmentReducer,
  checkin: CheckinReducer,
  report: ReportReducer,
  analytics: AnalyticsReducer,
  clockIn: ClockInReducer,
  agreement: AgreementReducer,
  immigration: ImmigrationReducer,
  leave: LeaveReducer,
  stats: StatsReducer,
  face: FaceReducer,
  announcement: AnnouncementReducer,
});

export default rootReducer;
