import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  STUDENT_CREATE_REQUESTED,
  STUDENT_CREATE_FAILED,
  STUDENT_CREATE_SUCCEEDED,
  STUDENT_GET_ALL_REQUESTED,
  STUDENT_GET_ALL_FAILED,
  STUDENT_GET_ALL_SUCCEEDED,
  STUDENT_GET_REQUESTED,
  STUDENT_GET_FAILED,
  STUDENT_GET_SUCCEEDED,
  STUDENT_LOG_GET_REQUESTED,
  STUDENT_LOG_GET_FAILED,
  STUDENT_LOG_GET_SUCCEEDED,
  STUDENT_UPDATE_REQUESTED,
  STUDENT_UPDATE_FAILED,
  STUDENT_UPDATE_SUCCEEDED,
  LEAD_UPDATE_SUCCEEDED,
  STUDENT_DELETE_REQUESTED,
  STUDENT_DELETE_SUCCEEDED,
  STUDENT_DELETE_FAILED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchStudent(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const student = yield call(remoteResource.getStudent, id, token);
    yield put({
      type: STUDENT_GET_SUCCEEDED,
      payload: student.data,
    });
  } catch (e) {
    yield put({ type: STUDENT_GET_FAILED, payload: e.message });
  }
}

function* fetchStudentLog(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const student = yield call(remoteResource.getStudentLog, id, token);
    yield put({
      type: STUDENT_LOG_GET_SUCCEEDED,
      payload: student.data,
    });
  } catch (e) {
    yield put({ type: STUDENT_LOG_GET_FAILED, payload: e.message });
  }
}

function* fetchAllStudents(action) {
  try {
    const token = yield select(getToken);
    const students = yield call(remoteResource.getAllStudents, token);
    yield put({
      type: STUDENT_GET_ALL_SUCCEEDED,
      payload: students.data,
    });
  } catch (e) {
    yield put({ type: STUDENT_GET_ALL_FAILED, payload: e.message });
  }
}

function* createStudent(action) {
  try {
    let payload = {};
    let leadId = "";
    if (action.payload && action.payload.payload) {
      payload = action.payload.payload;
    } else {
      payload = action.payload;
    }
    if (action.payload && action.payload && action.payload.leadId) {
      leadId = action.payload.leadId;
    }
    const token = yield select(getToken);
    const student = yield call(remoteResource.createStudent, payload, token);
    if (leadId && leadId !== "") {
      const lead = yield call(
        remoteResource.updateLeadClient,
        leadId,
        { studentId: student.data.id },
        token
      );
      yield put({
        type: LEAD_UPDATE_SUCCEEDED,
        payload: lead.data,
      });
    }
    yield put({
      type: STUDENT_CREATE_SUCCEEDED,
      payload: student.data,
    });
  } catch (e) {
    yield put({
      type: STUDENT_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateStudent(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const student = yield call(
      remoteResource.updateStudent,
      id,
      payload,
      token
    );
    yield put({
      type: STUDENT_UPDATE_SUCCEEDED,
      payload: student.data,
    });
  } catch (e) {
    yield put({
      type: STUDENT_UPDATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* deleteStudent(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    yield call(remoteResource.deleteStudent, id, token);
    yield put({
      type: STUDENT_DELETE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: STUDENT_DELETE_FAILED,
      payload: e.response.data.message,
    });
  }
}
export function* watchStudent() {
  yield takeLatest(STUDENT_GET_REQUESTED, fetchStudent);
  yield takeLatest(STUDENT_CREATE_REQUESTED, createStudent);
  yield takeLatest(STUDENT_UPDATE_REQUESTED, updateStudent);
  yield takeLatest(STUDENT_GET_ALL_REQUESTED, fetchAllStudents);
  yield takeLatest(STUDENT_LOG_GET_REQUESTED, fetchStudentLog);
  yield takeLatest(STUDENT_DELETE_REQUESTED, deleteStudent);
}
