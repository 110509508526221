/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import ReactTable from "react-table";

import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

// core components

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import withTimer from '../../components/Timer/withTimer';
import {
  CHECKIN_GET_ALL_REQUESTED, CHECKIN_TOGGLE_SHOW_ACTIVE,
  CHECKIN_UPDATE_REQUESTED, LEAD_GET_ALL_REQUESTED
} from '../../redux/actions';
import { filterCaseInsensitive, reactTableHelper } from '../../scripts/util';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_DATE_FORMAT_WITH_TIME,

} from '../../scripts/const';
import history from "../../history";
import CardBody from '../../components/Card/CardBody';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import TimeSpent from '../../components/Timer/TimeSpent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ReactGA from 'react-ga4';


const useStyles = makeStyles(styles);

const Checkin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeLeadId, setActiveLeadId] = useState(null);

  const showActive = useSelector((state) => state.checkin.showActive);

  const checkinData = useSelector(
    (state) => state.checkin.checkins
  );

  const [latestCheckingData, setLatestCheckinData]  = useState(checkinData);

  const updateSuccess = useSelector(
    (state) => state.checkin.checkinUpdateSuccess
  );

  const updateProgress = useSelector(
    (state) => state.checkin.checkinUpdateFetching
  );

  const updateError = useSelector(
    (state) => state.checkin.checkinUpdateError
  );

  useEffect(() => {
    if(checkinData.length !== latestCheckingData.length){
      setLatestCheckinData(checkinData);
      dispatch({ type: LEAD_GET_ALL_REQUESTED });
    }
  }, [checkinData]);

  useEffect(() => {
    if(updateSuccess){
      dispatch({ type: CHECKIN_GET_ALL_REQUESTED });
    }
  }, [updateSuccess]);

  const getActiveCheckins = () => {
    return checkinData.filter(ck => ck.timerStop == null);
  }
  const getInactiveCheckins = () => {
    return checkinData.filter(ck => ck.timerStop !== null);
  }

  const commonColumns = [
    {
      Header: "ID",
      accessor: "id",
      width: 70,
    },
    {
      Header: "First Name",
      accessor: "lead.firstName",
    },
    {
      Header: "Last Name",
      accessor: "lead.lastName",
    },
    {
      Header: "Email",
      accessor: "lead.email",
    },
    {
      Header: "Phone",
      accessor: "lead.phone",
    },
    {
      id: 'isNew',
      Header: "Status",
      accessor: d => {
        if(d.isNew === true){
          return "New"
        }else if (d.isNew === false){
          return "Returning"
        }
        return "";
      }
    },
    {
      Header: "TimerStart",
      accessor: "timerStart",
      sortMethod: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      Cell: (props) => {
        if (props.value) {
          return moment(props.value).format(
            DEFAULT_DATE_FORMAT_WITH_TIME
          );
        }
        return props.value;
      },
    },
  ]
  const inActiveColumns = [
    ...commonColumns,
    {
      Header: "TimerEnd",
      accessor: "timerStop",
      sortMethod: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      Cell: (props) => {
        if (props.value) {
          return moment(props.value).format(
            DEFAULT_DATE_FORMAT_WITH_TIME
          );
        }
        return props.value;
      },
    },
    {
      Header: "WaitTime",
      accessor: "waitTime",
      sortMethod: (a, b) => {
        return parseInt(a, 10) - parseInt(b, 10);
      },
      Cell: (props) => {
        const hours = Math.floor(props.value / 60);
        const minutes = props.value % 60;
        let timeDiff = `${minutes} minutes`;
        if (hours !== 0) {
          timeDiff = `${hours} hour, ${timeDiff}`;
        }
        return timeDiff;
      },
    },
    {
      width: 100,
      sortable: false,
      filterable: false,
      headerStyle: { textAlign: "center" },
      Header: "Action",
      accessor: "id",
      Cell: (props) => {
        return <div className="actions">
          <Button
            onClick={() => {
              history.push(`/admin/leads/${props.original.lead.id}`)
              ReactGA.event({
                category: "Check-In",
                action: "View Profile",
              });
            }}
            color="info"
            disabled={updateProgress}
          >
            View
          </Button>{" "}
        </div>
      },
    },
  ];

const activeColumns = [
    ...commonColumns,
    {
      Header: "WaitTime",
      accessor: "timerStart",
      sortMethod: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      Cell: (props) => {
        if (props.value) {
          return <TimeSpent value={props.value} />
        }
        return props.value;
      },
    },
    {
      width: 200,
      sortable: false,
      filterable: false,
      headerStyle: { textAlign: "center" },
      Header: "Action",
      accessor: "id",
      Cell: (props) => {
        return <div className="actions">
          <Button
            onClick={() => {
              dispatch({ type: CHECKIN_UPDATE_REQUESTED, payload: {id: props.value} });
              ReactGA.event({
                category: "Check-In",
                action: "Check-In marked done",
              });
            }}
            color="info"
            disabled={updateProgress}
          >
            Done
          </Button>{" "}
          <Button
            onClick={() => {
              history.push(`/admin/leads/${props.original.lead.id}`)
              ReactGA.event({
                category: "Check-In",
                action: "View Profile",
              });
            }}
            color="info"
            disabled={updateProgress}
          >
            View
          </Button>{" "}
          {/*</Link>*/}
        </div>
      },
    },
  ];

  return (
    <Card>
      <CardHeader color="primary" icon>
        <div className={classes.block}>
          <FormControlLabel
            control={
              <Switch
                checked={showActive}
                onChange={(event) =>
                  dispatch({
                    type: CHECKIN_TOGGLE_SHOW_ACTIVE
                  })
                }
                value={showActive}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={showActive ? "Active Check-Ins" : "Checkin History"}
          />
        </div>
    </CardHeader>
      <CardBody>
        {showActive ?
          <ReactTable
          pageSizeOptions={[25, 50, 100]}
          data={getActiveCheckins()}
          filterable
          resizable={true}
          columns={activeColumns}
          defaultPageSize={25}
          showPaginationBottom={true}
          className="-striped -highlight"
          defaultFilterMethod={filterCaseInsensitive}
          >
            {(state, makeTable, instance) => {
            return reactTableHelper(state, makeTable, instance);
            }}
          </ReactTable>
          :
          <ReactTable
              pageSizeOptions={[25, 50, 100]}
              data={getInactiveCheckins()}
              filterable
              resizable={true}
              columns={inActiveColumns}
              defaultPageSize={25}
              showPaginationBottom={true}
              className="-striped -highlight"
              defaultFilterMethod={filterCaseInsensitive}
          >
            {(state, makeTable, instance) => {
            return reactTableHelper(state, makeTable, instance);
            }}
          </ReactTable>
        }
          </CardBody>
</Card>
  )

}

export default withTimer(Checkin);
