import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import PropTypes from "prop-types";

import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(alertStyles);

export default function ConfirmationAlert({ cancel, submit, message }) {
  const classes = useStyles();

  return (
    <>
      <SweetAlert
        style={{
          display: "block",
          marginTop: "-100px",
        }}
        title="Are you sure?"
        onConfirm={() => {
          cancel();
          submit();
        }}
        onCancel={() => cancel()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes"
        cancelBtnText="Cancel"
        showCancel
      >
        {message
          ? message
          : `Customer already exist, is the customer signing up for a new service?`}
      </SweetAlert>
    </>
  );
}

ConfirmationAlert.propTypes = {
  cancel: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  message: PropTypes.string,
};
