import React, { useEffect } from "react";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles2 from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_ERROR,
  INSURANCE_APPLICANT_DELETE_REQUESTED,
  INSURANCE_APPLICANT_GET_ALL_REQUESTED,
  INSURANCE_APPLICANT_LOG_GET_REQUESTED,
  INSURANCE_APPLICANT_UPDATE_REQUESTED,
} from "../../../redux/actions";
import {
  GENERAL_ROLE,
  occupationCodeOption,
  SUPER_ADMIN_ROLES,
  useGetOccupationOptions,
  useGetVisaTypeOptions,
  TINY_INIT,
} from "../../../scripts/const";

import {
  convertDate,
  getAnzsco,
  getDropDown,
  getInsuranceProviderMapping,
  getInsuranceTypeMapping,
  getOccupation,
  getUserName,
  isValidInsuranceApplicantInputs,
  labelStyleFunc,
} from "../../../scripts/util";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import Muted from "../../../components/Typography/Muted";
import ReactGA from "react-ga4";
import { DEFAULT_DATE_FORMAT } from "../../../scripts/const";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SweetAlert from "react-bootstrap-sweetalert";
import { Editor } from "@tinymce/tinymce-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { default as ReactSelect } from "react-select";
import FileUpload from "../../../components/CustomUpload/FileUpload";
import DropDownWithSearch, {
  customReactSelectStyles,
} from "../../../components/DropDownWithSearch/DropDownWithSearch";

const useStyles = makeStyles({ ...styles, ...styles2 });

export default function EditInsurance(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const occupationOption = useGetOccupationOptions();
  const visaTypeOption = useGetVisaTypeOptions();

  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);

  const insuranceProviders = getInsuranceProviderMapping();
  const insuranceTypes = getInsuranceTypeMapping();

  const [error, setError] = React.useState(false);
  const [validationErrMsg, setValidationErrMsg] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [insuranceApplicant, setInsuranceApplicant] = React.useState({});
  const [insuranceAssessmentCopy, setInsuranceAssessmentCopy] = React.useState(
    {}
  );
  const [insuranceLog, setInsuranceLog] = React.useState([]);
  const [isLog, setIsLog] = React.useState(false);
  const sLog = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantLog
  );
  const [logId, setLogId] = React.useState(null);

  const handleFileUpload = (fileUrl) => {
    if (!insuranceApplicant.files) {
      setInsuranceApplicant({
        ...insuranceApplicant,
        files: [fileUrl],
      });
    } else {
      setInsuranceApplicant({
        ...insuranceApplicant,
        files: [...insuranceApplicant.files, fileUrl],
      });
    }
  };

  const handleFileDelete = (fileUrl) => {
    setInsuranceApplicant({
      ...insuranceApplicant,
      files: insuranceApplicant.files.filter((f) => f != fileUrl),
    });
  };

  const handleLog = () => {
    if (isLog) {
      setInsuranceApplicant(insuranceAssessmentCopy);
    } else {
      ReactGA.event({
        category: "ViewHistory",
        action: "View Insurance Applicant History",
      });
      setInsuranceAssessmentCopy(insuranceApplicant);
    }
    if (!isLog && insuranceLog.length === 0) {
      dispatch({
        type: INSURANCE_APPLICANT_LOG_GET_REQUESTED,
        payload: insuranceApplicantId,
      });
    }
    setIsLog(!isLog);
  };

  const [insuranceApplicantId, setInsuranceApplicantId] = React.useState(false);
  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );
  const errMsg = useSelector((state) => state.insuranceApplicant.errorMsg);
  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);

  const handleSelect = (e) => {
    setInsuranceApplicant({
      ...insuranceApplicant,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditorChange = (content) => {
    setInsuranceApplicant({
      ...insuranceApplicant,
      remarks: content,
    });
  };

  const dispatch = useDispatch();

  const editError = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantUpdateError
  );
  const editSuccess = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantUpdateSuccess
  );
  const editProgress = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantUpdateFetching
  );

  const deleteProgress = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantDeleteFetching
  );

  const deleteSuccess = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantDeleteSuccess
  );

  function clearErrors() {
    setValidationErrMsg("");
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      setInsuranceApplicant(insuranceAssessmentCopy);
      clearErrors();
      setInsuranceLog([]);
      setIsLog(false);
      setLogId(null);
      hideAlert();
    }
  }, [modalOpen]);

  useEffect(() => {
    setInsuranceLog(sLog);
  }, [sLog]);

  useEffect(() => {
    setValidationErrMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    setInsuranceApplicant(props.insuranceApplicant);
    setInsuranceApplicantId(props.insuranceApplicant.id);
  }, [props.insuranceApplicant, props.open]);

  useEffect(() => {
    setError(editError);
  }, [editError]);

  useEffect(() => {
    setSuccess(editSuccess);
  }, [editSuccess]);

  useEffect(() => {
    if (editSuccess === true || deleteSuccess === true) {
      setTimeout(() => {
        if (deleteSuccess) {
          props.handleSuccess(false);
        }
        dispatch({ type: INSURANCE_APPLICANT_GET_ALL_REQUESTED });
      }, 720);
      setTimeout(() => {
        // setInsuranceApplicant({});
        dispatch({ type: CLEAR_ERROR });
      }, 750);
    }
  }, [editSuccess, deleteSuccess]);

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setInsuranceApplicant({
        ...insuranceApplicant,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setInsuranceApplicant({
        ...insuranceApplicant,
        [name]: date,
      });
    }
  };

  function editInsuranceApplicant() {
    const errors = isValidInsuranceApplicantInputs(insuranceApplicant);
    if (errors.length > 0) {
      setValidationErrMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else {
      setValidationErrMsg("");
      dispatch({
        type: INSURANCE_APPLICANT_UPDATE_REQUESTED,
        payload: { payload: insuranceApplicant, id: insuranceApplicantId },
      });
    }
  }

  function getLog() {
    return insuranceLog.map((st, index) => (
      <Card key={index} color={index === logId ? "primary" : null}>
        <CardBody
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setLogId(index);
            setInsuranceApplicant(insuranceLog[index]);
          }}
        >
          <small>{getUserName(st.updatedBy, props.allUsers)}</small>
          <br></br>
          <small>{convertDate(st.version_timestamp)}</small>
        </CardBody>
      </Card>
    ));
  }

  function deleteEntity() {
    dispatch({
      type: INSURANCE_APPLICANT_DELETE_REQUESTED,
      payload: { id: insuranceApplicantId },
    });
    ReactGA.event({
      category: "InsuranceService",
      action: "Deleted an Insurance Applicant",
    });
  }

  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      />
    );
  };
  const confirmationMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteEntity();
          successDelete();
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      />
    );
  };

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem
          style={{
            textAlign: "right",
          }}
          xs={12}
          sm={12}
          md={12}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isLog}
                onChange={handleLog}
                value={isLog}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={isLog ? "HideHistory" : "ShowHistory"}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={3} sm={3} md={3}>
          {isLog && <div>{getLog()}</div>}
        </GridItem>
        <GridItem xs={isLog ? 9 : 12} sm={isLog ? 9 : 12} md={isLog ? 9 : 12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {validationErrMsg && (
                    <SnackbarContent
                      message={validationErrMsg}
                      color="warning"
                    />
                  )}
                  {error && (
                    <SnackbarContent message={errMessage} color="warning" />
                  )}
                  {editProgress && <CustomLinearProgress color="primary" />}
                  {success && (
                    <SnackbarContent
                      message="Added the insurance applicant"
                      color="success"
                    />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="ID"
                    id="editInsurance-id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicantId || "",
                      disabled: true,
                      name: "id",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email Address *"
                    id="insurance-edit-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.email || "",
                      onChange: handleSelect,
                      name: "email",
                      type: "email",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="insurance-edit-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="First Name *"
                    id="edit-ins-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Middle Name"
                    id="edit-ins-middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.middleName || "",
                      onChange: handleSelect,
                      name: "middleName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Last Name *"
                    id="edit-ins-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Date of Birth
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.dob || ""}
                      onChange={(moment) => handleDatePicker(moment, "dob")}
                      inputProps={{
                        name: "dob",
                        id: "edit-ins-dob-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                        disabled: isLog,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Passport No"
                    id="edit-ins-passport"
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.passport,
                      onChange: handleSelect,
                      name: "passport",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Issue Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.passportIssueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "passportIssueDate")
                      }
                      inputProps={{
                        name: "passportIssueDate",
                        id: "edit-ins-passportIssueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Expiry Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.passportExpiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "passportExpiryDate")
                      }
                      inputProps={{
                        name: "passportExpiryDate",
                        id: "edit-ins-passportExpiryDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(insuranceApplicant.currentVisa)}
                    >
                      Current Visa
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        insuranceApplicant.currentVisa
                          ? {
                              label: insuranceApplicant.currentVisa,
                              value: insuranceApplicant.currentVisa,
                            }
                          : ""
                      }
                      options={visaTypeOption}
                      name="currentVisa"
                      styles={customReactSelectStyles}
                      placeholder={"Current Visa"}
                      isDisabled={isLog}
                      onChange={(options) => {
                        if (!options) {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            currentVisa: "",
                          });
                        } else {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            currentVisa: options.value,
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Current Insurance"
                    id="edit-ins-currentInsurance"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.currentInsurance || "",
                      onChange: handleSelect,
                      name: "currentInsurance",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(insuranceApplicant.occupation)}
                    >
                      Occupation
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        insuranceApplicant.occupation
                          ? {
                              label: insuranceApplicant.occupation,
                              value: insuranceApplicant.occupation,
                            }
                          : ""
                      }
                      options={occupationOption}
                      name="occupation"
                      styles={customReactSelectStyles}
                      placeholder={"Occupation"}
                      onChange={(options) => {
                        if (!options) {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            occupation: "",
                            anzsco: "",
                          });
                        } else {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            occupation: options.value,
                            anzsco: getAnzsco(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(insuranceApplicant.anzsco)}
                    >
                      ANZSCO
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        insuranceApplicant.anzsco
                          ? {
                              label: insuranceApplicant.anzsco,
                              value: insuranceApplicant.anzsco,
                            }
                          : ""
                      }
                      options={occupationCodeOption}
                      name="anzsco"
                      styles={customReactSelectStyles}
                      placeholder={"ANZSCO"}
                      onChange={(options) => {
                        if (!options) {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            anzsco: "",
                            occupation: "",
                          });
                        } else {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            anzsco: options.value,
                            occupation: getOccupation(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Insurance Start Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.startDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "startDate")
                      }
                      inputProps={{
                        name: "startDate",
                        id: "startDate-edit-ins",
                        placeholder: DEFAULT_DATE_FORMAT,
                        disabled: isLog,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Insurance Expiry Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.expiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "expiryDate")
                      }
                      inputProps={{
                        name: "expiryDate",
                        id: "expiryDate-edit-ins",
                        placeholder: DEFAULT_DATE_FORMAT,
                        disabled: isLog,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("insurance-category")}
                    selectedOption={insuranceApplicant.category || ""}
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"category"}
                    label={"Category"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Payment Plan"
                    id="edit-ins-payment-plan"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.paymentPlan || "",
                      onChange: handleSelect,
                      name: "paymentPlan",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Policy Number"
                    id="edit-ins-policy-no"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.policyNumber || "",
                      onChange: handleSelect,
                      name: "policyNumber",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Total Paid"
                    id="edit-ins-total-paid"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.totalPaid || "",
                      onChange: handleSelect,
                      name: "totalPaid",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <br />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    dbOptions={insuranceTypes}
                    selectedOption={insuranceApplicant.insuranceTypeId || ""}
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"insuranceTypeId"}
                    label={"Insurance Type"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    dbOptions={insuranceProviders}
                    selectedOption={
                      insuranceApplicant.insuranceProviderId || ""
                    }
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"insuranceProviderId"}
                    label={"Insurance Provider"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("country")}
                    selectedOption={insuranceApplicant.country || ""}
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"country"}
                    label={"Country"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("insurance-status")}
                    selectedOption={insuranceApplicant.status || ""}
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"status"}
                    label={"Status *"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allSources && (
                        <DropDownWithSearch
                          dbOptions={props.allSources}
                          selectedOption={insuranceApplicant.sourceId || ""}
                          setValue={setInsuranceApplicant}
                          currentState={insuranceApplicant}
                          name={"sourceId"}
                          label={"Source"}
                        />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allUsers && (
                        <DropDownWithSearch
                          dbOptions={props.allUsers}
                          dbLabel={"firstName"}
                          selectedOption={insuranceApplicant.userId || ""}
                          setValue={setInsuranceApplicant}
                          currentState={insuranceApplicant}
                          name={"userId"}
                          label={"Assigned To"}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={insuranceApplicant.remarks || ""}
                    init={TINY_INIT}
                    onEditorChange={handleEditorChange}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div>
                    <h4>Documents:</h4>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      type={"insuranceApplicant"}
                      setFileUrl={(fileUrl) => handleFileUpload(fileUrl)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {insuranceApplicant.files &&
                  insuranceApplicant.files.length > 0 &&
                  insuranceApplicant.files.map((fileUrl, id) => {
                    return (
                      <GridItem key={id}>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            backgroundColor: "#B0BEC5",
                            padding: "20px",
                            borderRadius: "25px",
                            marginTop: "10px",
                          }}
                        >
                          <FileUpload
                            fileUrl={fileUrl}
                            key={id}
                            handleDelete={(fileUrl) => {
                              handleFileDelete(fileUrl);
                            }}
                          />
                        </div>
                      </GridItem>
                    );
                  })}
              </GridContainer>
              <br />
              <br />
              <GridContainer
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <GridItem>
                  <Muted>
                    Updated At: {convertDate(insuranceApplicant.updatedAt)}
                  </Muted>
                  <Muted>
                    Updated By:{" "}
                    {getUserName(insuranceApplicant.updatedBy, props.allUsers)}
                  </Muted>
                </GridItem>
                <GridItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.3rem",
                  }}
                >
                  {editProgress && <CircularProgress size={"2rem"} />}
                  {error && <CloseIcon style={{ color: "red" }} />}
                  {success && <CheckIcon style={{ color: "green" }} />}
                  {SUPER_ADMIN_ROLES.includes(currentRoleId) && (
                    <Button
                      color="danger"
                      disabled={!!(deleteProgress || isLog)}
                      className={classes.moveToRight}
                      onClick={(e) => {
                        e.preventDefault();
                        confirmationMessage();
                      }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    color="primary"
                    disabled={editProgress || isLog ? true : false}
                    className={classes.moveToRight}
                    onClick={(e) => {
                      e.preventDefault();
                      ReactGA.event({
                        category: "InsuranceService",
                        action: "Updated a Insurance Applicant Profile",
                      });
                      editInsuranceApplicant();
                    }}
                  >
                    Update
                  </Button>
                </GridItem>
              </GridContainer>
              <Clearfix />
              {alert}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
