import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  CHECKIN_NEW_CREATE_REQUESTED,
  CHECKIN_NEW_CREATE_FAILED,
  CHECKIN_NEW_CREATE_SUCCEEDED,
  CHECKIN_OLD_CREATE_REQUESTED,
  CHECKIN_OLD_CREATE_FAILED,
  CHECKIN_OLD_CREATE_SUCCEEDED,
  CHECKIN_GET_ALL_REQUESTED,
  CHECKIN_GET_ALL_FAILED,
  CHECKIN_GET_ALL_SUCCEEDED,
  CHECKIN_UPDATE_REQUESTED,
  CHECKIN_UPDATE_FAILED,
  CHECKIN_UPDATE_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchAllCheckins() {
  try {
    const token = yield select(getToken);
    const checkins = yield call(remoteResource.getAllCheckins, token);
    yield put({
      type: CHECKIN_GET_ALL_SUCCEEDED,
      payload: checkins.data,
    });
  } catch (e) {
    yield put({ type: CHECKIN_GET_ALL_FAILED, payload: e.message });
  }
}

function* createNewCheckin(action) {
  try {
    const { payload, captcha } = action;
    const newCheckin = yield call(
      remoteResource.createNewCheckin,
      payload,
      captcha
    );
    yield put({
      type: CHECKIN_NEW_CREATE_SUCCEEDED,
      payload: newCheckin.data,
    });
  } catch (e) {
    yield put({
      type: CHECKIN_NEW_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* createOldCheckin(action) {
  try {
    const { payload, captcha } = action;
    const oldCheckin = yield call(
      remoteResource.createOldCheckin,
      payload,
      captcha
    );
    yield put({
      type: CHECKIN_OLD_CREATE_SUCCEEDED,
      payload: oldCheckin.data,
    });
  } catch (e) {
    yield put({
      type: CHECKIN_OLD_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateCheckin(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    const checkin = yield call(remoteResource.updateCheckin, id, token);
    yield put({
      type: CHECKIN_UPDATE_SUCCEEDED,
      payload: checkin.data,
    });
  } catch (e) {
    yield put({
      type: CHECKIN_UPDATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchCheckin() {
  yield takeLatest(CHECKIN_UPDATE_REQUESTED, updateCheckin);
  yield takeLatest(CHECKIN_GET_ALL_REQUESTED, fetchAllCheckins);
  yield takeLatest(CHECKIN_NEW_CREATE_REQUESTED, createNewCheckin);
  yield takeLatest(CHECKIN_OLD_CREATE_REQUESTED, createOldCheckin);
}
