import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import MobileIcon from "@material-ui/icons/PhoneIphone";
import LocationIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Facebook from "@material-ui/icons/Facebook";
import Button from "@material-ui/core/Button";
import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";

import {
  BRANCH_FACEBOOK,
  BRANCH_MOBILE,
  BRANCH_PHONE,
  BRANCH_EMAIL,
  BRANCH_LOCATION,
  BRANCH_LOCATION_LINK,
} from "../../scripts/const";

const useStyles = makeStyles(styles);
export default function ContactInfo({ isVertical = false }) {
  const classes = useStyles();

  return (
    <>
      <GridContainer justifyContent={isVertical ? "" : "center"}>
        {isVertical && (
          <GridItem
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "5px",
              padding: "5px",
            }}
          >
            <LocationIcon />
            <small style={{ marginLeft: isVertical ? "10px" : "" }}>
              <a
                href={BRANCH_LOCATION_LINK}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                {BRANCH_LOCATION}
              </a>
              {""}
            </small>
          </GridItem>
        )}
        <GridItem
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "5px",
            padding: "5px",
          }}
        >
          <MobileIcon />
          <small style={{ marginLeft: isVertical ? "10px" : "" }}>
            <a href={`tel:${BRANCH_MOBILE}`}>{BRANCH_MOBILE}</a>
            {"  "}
          </small>
        </GridItem>
        <GridItem
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "5px",
            padding: "5px",
          }}
        >
          <PhoneIcon />
          <small style={{ marginLeft: isVertical ? "10px" : "" }}>
            <a href={`tel:${BRANCH_PHONE}`}>{BRANCH_PHONE}</a>
            {"  "}
          </small>
        </GridItem>
        <GridItem
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "5px",
            padding: "5px",
          }}
        >
          {"  "}
          <EmailIcon />
          <small style={{ marginLeft: isVertical ? "10px" : "" }}>
            <a href={`mailto:${BRANCH_EMAIL}`}>{BRANCH_EMAIL}</a>
          </small>
        </GridItem>
        {isVertical && (
          <GridItem
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: "5px",
              padding: "5px",
            }}
          >
            <Facebook />
            <small style={{ marginLeft: isVertical ? "10px" : "" }}>
              <a
                href={BRANCH_FACEBOOK}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                Facebook
              </a>
              {""}
            </small>
          </GridItem>
        )}
      </GridContainer>
      {!isVertical && (
        <GridContainer
          justifyContent="center"
          alignItems="center"
          spacing={0}
          direction="column"
        >
          <GridItem xs={12} sm={4}>
            <div className={classes.center}>
              <Button onClick={() => window.open(BRANCH_FACEBOOK, "_blank")}>
                <Facebook />
              </Button>
              {` `}
            </div>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
}
