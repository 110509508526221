import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  USER_CREATE_REQUESTED,
  USER_CREATE_FAILED,
  USER_CREATE_SUCCEEDED,
  USER_GET_ALL_REQUESTED,
  USER_GET_ALL_FAILED,
  USER_GET_ALL_SUCCEEDED,
  USER_GET_REQUESTED,
  USER_GET_FAILED,
  USER_GET_SUCCEEDED,
  USER_UPDATE_REQUESTED,
  USER_UPDATE_FAILED,
  USER_UPDATE_SUCCEEDED,
  PROFILE_GET_FAILED,
  PROFILE_GET_REQUESTED,
  PROFILE_GET_SUCCEEDED,
  PROFILE_UPDATE_FAILED,
  PROFILE_UPDATE_REQUESTED,
  PROFILE_UPDATE_SUCCEEDED,
  PROFILE_CODE_GENERATION_FAILED,
  PROFILE_CODE_GENERATION_REQUESTED,
  PROFILE_CODE_GENERATION_SUCCEEDED,
  GET_ALL_SERVICE_DATA_FAILED,
  GET_ALL_SERVICE_DATA_REQUESTED,
  GET_ALL_SERVICE_DATA_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchUser(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const user = yield call(remoteResource.getUser, id, token);
    user.data.password = "";
    yield put({
      type: USER_GET_SUCCEEDED,
      payload: user.data,
    });
  } catch (e) {
    yield put({ type: USER_GET_FAILED, payload: e.message });
  }
}

function* fetchAllUsers(action) {
  try {
    const token = yield select(getToken);
    const users = yield call(remoteResource.getAllUser, token);
    const allUsers = users.data.map((m) => ({ ...m, password: "" }));
    yield put({
      type: USER_GET_ALL_SUCCEEDED,
      payload: allUsers,
    });
  } catch (e) {
    yield put({ type: USER_GET_ALL_FAILED, payload: e.message });
  }
}

function* createUser(action) {
  try {
    const { payload } = action;
    if (payload.hasOwnProperty("password") && payload.password === "") {
      delete payload.password;
    }
    const token = yield select(getToken);
    const user = yield call(remoteResource.createUser, payload, token);
    user.data.password = "";
    yield put({
      type: USER_CREATE_SUCCEEDED,
      payload: user.data,
    });
    yield call(remoteResource.newUser, payload, token);
  } catch (e) {
    yield put({ type: USER_CREATE_FAILED, payload: e.message });
  }
}

function* updateUser(action) {
  try {
    const { payload, id } = action.payload;
    if (payload.hasOwnProperty("password") && payload.password === "") {
      delete payload.password;
    }
    const token = yield select(getToken);
    const user = yield call(remoteResource.updateUser, id, payload, token);
    user.data.password = "";
    yield put({
      type: USER_UPDATE_SUCCEEDED,
      payload: user.data,
    });
  } catch (e) {
    yield put({ type: USER_UPDATE_FAILED });
  }
}

function* fetchProfile(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const user = yield call(remoteResource.getProfile, id, token);
    user.data.password = "";
    yield put({
      type: PROFILE_GET_SUCCEEDED,
      payload: user.data,
    });
  } catch (e) {
    yield put({ type: PROFILE_GET_FAILED, payload: e.message });
  }
}

function* updateProfile(action) {
  try {
    const { id, payload } = action.payload;
    if (payload.hasOwnProperty("password") && payload.password === "") {
      delete payload.password;
    }
    const token = yield select(getToken);
    const user = yield call(remoteResource.updateProfile, id, payload, token);
    user.data.password = "";
    yield put({
      type: PROFILE_UPDATE_SUCCEEDED,
      payload: user.data,
    });
  } catch (e) {
    yield put({ type: PROFILE_UPDATE_FAILED, payload: e.message });
  }
}

function* generateCode(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    const user = yield call(remoteResource.generateClockInCode, id, token);
    user.data.password = "";
    yield put({
      type: PROFILE_CODE_GENERATION_SUCCEEDED,
      payload: user.data,
    });
  } catch (e) {
    yield put({ type: PROFILE_CODE_GENERATION_FAILED, payload: e.message });
  }
}

function* fetchAllServiceData(action) {
  try {
    const token = yield select(getToken);
    const data = yield call(remoteResource.getAllServiceData, token);
    yield put({
      type: GET_ALL_SERVICE_DATA_SUCCEEDED,
      payload: data?.data,
    });
  } catch (e) {
    yield put({ type: GET_ALL_SERVICE_DATA_FAILED, payload: e.message });
  }
}

export function* watchUser() {
  yield takeLatest(USER_GET_REQUESTED, fetchUser);
  yield takeLatest(USER_CREATE_REQUESTED, createUser);
  yield takeLatest(USER_UPDATE_REQUESTED, updateUser);
  yield takeLatest(USER_GET_ALL_REQUESTED, fetchAllUsers);
  yield takeLatest(PROFILE_GET_REQUESTED, fetchProfile);
  yield takeLatest(PROFILE_UPDATE_REQUESTED, updateProfile);
  yield takeLatest(PROFILE_CODE_GENERATION_REQUESTED, generateCode);
  yield takeLatest(GET_ALL_SERVICE_DATA_REQUESTED, fetchAllServiceData);
}
