import React, { useEffect } from "react";
import ReactTable from "react-table";

import { CSVLink } from "react-csv";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import DateFilter from "components/DateFilter";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  createCustomCell,
  createSelectDropDown,
  filterCaseInsensitive,
  getColumns,
  reactTableHelper,
} from "../../../scripts/util";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { GetApp } from "@material-ui/icons";
import withTimer from "../../../components/Timer/withTimer";

import AgreementContainer from "./AgreementContainer";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_WITH_MONTH,
} from "../../../scripts/const";
import Badge from "../../../components/Badge/Badge";
import ReactGA from "react-ga4";
import { AGREEMENT_GET_ALL_REQUESTED } from "../../../redux/actions";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AgreementTable = ({
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);

  const agreements = useSelector((state) => state.agreement.agreements);
  const universities = useSelector((state) => state.university.universities);

  const [currentAgreement, setCurrentAgreement] = React.useState({});
  const [data, setData] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!(agreements.length > 0)) {
      dispatch({ type: AGREEMENT_GET_ALL_REQUESTED });
    }
  }, []);

  React.useEffect(() => {
    setData(agreements);
    setFilterData(agreements);
    if (!openModal) {
      setCurrentAgreement({});
      setIsEdit(false);
    }
  }, [agreements, openModal]);

  useEffect(() => {
    if (id !== "undefined" && id > 0 && data.length > 0) {
      const found = data.find((d) => d.id == id);
      if (found) {
        setCurrentAgreement(found);
        setIsEdit(true);
        setOpenModal(true);
      }
    }
  }, [id, data]);

  function handleDateRangeFilter(fromDate, toDate) {
    if (!fromDate || !toDate) {
      setFilterData(data);
    } else {
      const filterData = data.filter(
        (e) => moment(e.createdAt) >= fromDate && moment(e.createdAt) <= toDate
      );
      setFilterData(filterData);
    }
  }

  // Export
  const [dataToDownload, setDataToDownload] = React.useState([]);
  const [isDownload, setIsDownload] = React.useState(false);

  function download() {
    const columns = getColumns("agreement");
    const currentRecords = reactTableRef.getResolvedState().sortedData;
    let data_to_download = [];
    for (let index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        if (
          columns[colIndex].Header === "University" &&
          currentRecords[index][columns[colIndex].accessor]
        ) {
          record_to_download[columns[colIndex].Header] = universities.find(
            (e) => e.id === currentRecords[index][columns[colIndex].accessor]
          ).name;
        } else {
          record_to_download[columns[colIndex].Header] =
            currentRecords[index][columns[colIndex].accessor];
        }
      }
      data_to_download.push(record_to_download);
    }
    setDataToDownload(data_to_download);
  }

  React.useEffect(() => {
    if (dataToDownload.length > 0) {
      setIsDownload(true);
    }
  }, [dataToDownload]);

  React.useEffect(() => {
    if (isDownload) {
      csvLink.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  let reactTableRef = null;
  let csvLink = null;

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      width: 70,
    },
    {
      Header: "University",
      accessor: "universityId",
      Cell: (props) => createCustomCell(props, universities, "name"),
      Filter: ({ filter, onChange }) =>
        createSelectDropDown(onChange, filter, universities, "name"),
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Group",
      accessor: "group",
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      sortMethod: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      Cell: (props) => {
        if (props.value) {
          return moment(props.value, DEFAULT_DATE_FORMAT).format(
            DEFAULT_DATE_FORMAT_WITH_MONTH
          );
        }
        return props.value;
      },
    },
    {
      Header: "End Date",
      accessor: "endDate",
      sortMethod: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      Cell: (props) => {
        if (props.value) {
          return moment(props.value, DEFAULT_DATE_FORMAT).format(
            DEFAULT_DATE_FORMAT_WITH_MONTH
          );
        }
        return props.value;
      },
    },
    {
      Header: "Commission",
      accessor: "commission",
      Cell: (props) => {
        if (props.value) {
          return <Badge color={"gray"}>{`${props.value} %`}</Badge>;
        }
        return props.value;
      },
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => {
        if (!props.value) {
          return props.value;
        }
        if (["In Process", "In Effect", "Other"].includes(props.value)) {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else if (["Expired", "Cancelled"].includes(props.value)) {
          return <Badge color={"danger"}>{props.value}</Badge>;
        } else {
          return <Badge color={"gray"}>{props.value}</Badge>;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "All") {
          return true;
        }
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "All"}
        >
          <option value="All">All</option>
          <option value="In Process">In Process</option>
          <option value="In Effect">In Effect</option>
          <option value="Other">Other</option>
          <option value="Expired">Expired</option>
          <option value="Cancelled">Cancelled</option>
        </select>
      ),
    },
    {
      Header: "File",
      accessor: "files",
      Cell: (props) => {
        if (props.value && props.value.length > 0) {
          const lastElem = props.value.length - 1;
          if (props.value[lastElem].includes("_")) {
            return props.value[lastElem].substring(
              props.value[lastElem].indexOf("_") + 1
            );
          }
        }
        return props.value;
      },
      headerStyle: { textAlign: "left" },
    },
    {
      show: false,
      Header: "Weblink",
      accessor: "webLink",
    },
    {
      show: false,
      Header: "Note",
      accessor: "note",
      headerStyle: { textAlign: "left" },
    },
  ];

  function getBGColor(row) {
    const { status, endDate } = row;
    if (endDate) {
      const endDateM = moment(endDate, DEFAULT_DATE_FORMAT);
      const today = moment();
      const dateDiff = endDateM.diff(today, "days");
      if (dateDiff < 31 && dateDiff > -1) {
        return "#fada5e";
      }
    }
    if (["Expired", "Cancelled"].includes(status)) {
      return "#E24F59";
    }
    return undefined;
  }

  function getTrProps(state, rowInfo) {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          e.preventDefault();
          setCurrentAgreement(rowInfo.row._original);
          setOpenModal(true);
          setIsEdit(true);
          ReactGA.event({
            category: "Agreement",
            action: "View Agreement",
          });
        },
        style: {
          background: getBGColor(rowInfo.row._original),
        },
      };
    } else {
      return {};
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <GridContainer>
              <GridItem xs={5}>
                <Button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  color="primary"
                >
                  Add Agreement
                </Button>
                <Button
                  title="Download CSV"
                  justIcon
                  color="info"
                  onClick={download}
                >
                  <GetApp />
                </Button>{" "}
                <CSVLink
                  data={dataToDownload}
                  filename="agreements.csv"
                  className="hidden"
                  ref={(r) => (csvLink = r)}
                  target="_blank"
                />
              </GridItem>
              <GridItem xs={7}>
                <DateFilter handleFilter={handleDateRangeFilter} />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <ReactTable
              ref={(r) => (reactTableRef = r)}
              pageSizeOptions={[25, 50, 100]}
              data={filterData}
              filterable
              resizable={true}
              getTrProps={getTrProps}
              columns={columns}
              defaultPageSize={25}
              showPaginationBottom={true}
              className="-striped -highlight"
              defaultFilterMethod={filterCaseInsensitive}
            >
              {(state, makeTable, instance) => {
                return reactTableHelper(state, makeTable, instance);
              }}
            </ReactTable>
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={openModal}
        disableScrollLock={true}
        TransitionComponent={Transition}
        onClose={() => setOpenModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        disableBackdropClick
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setOpenModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>
            {isEdit ? "Update Agreement" : "Create Agreement"}
          </h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <AgreementContainer
            open={openModal}
            setOpen={setOpenModal}
            editData={currentAgreement}
            isEdit={isEdit}
          />
        </DialogContent>
      </Dialog>
    </GridContainer>
  );
};

export default withTimer(AgreementTable);
