import {
  CUSTOM_SMS_REQUESTED,
  CUSTOM_SMS_FAILED,
  CUSTOM_SMS_SUCCEEDED,
  CUSTOM_EMAIL_REQUESTED,
  CUSTOM_EMAIL_FAILED,
  CUSTOM_EMAIL_SUCCEEDED,
  TEMPLATE_EMAIL_REQUESTED,
  TEMPLATE_EMAIL_FAILED,
  TEMPLATE_EMAIL_SUCCEEDED,
  TEMPLATE_SMS_REQUESTED,
  TEMPLATE_SMS_FAILED,
  TEMPLATE_SMS_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultState = {
  customSmsError: false,
  customSmsFetching: false,
  customSmsSuccess: false,

  customEmailError: false,
  customEmailFetching: false,
  customEmailSuccess: false,

  templateSmsError: false,
  templateSmsFetching: false,
  templateSmsSuccess: false,

  templateEmailError: false,
  templateEmailFetching: false,
  templateEmailSuccess: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultState,
      };
    case CUSTOM_SMS_REQUESTED:
      return {
        ...state,
        customSmsError: false,
        customSmsFetching: true,
        customSmsSuccess: false,
      };
    case CUSTOM_SMS_FAILED:
      return {
        ...state,
        customSmsError: true,
        customSmsFetching: false,
        customSmsSuccess: false,
      };
    case CUSTOM_SMS_SUCCEEDED:
      return {
        ...state,
        customSmsError: false,
        customSmsFetching: false,
        customSmsSuccess: true,
      };

    case CUSTOM_EMAIL_REQUESTED:
      return {
        ...state,
        customEmailError: false,
        customEmailFetching: true,
        customEmailSuccess: false,
      };
    case CUSTOM_EMAIL_FAILED:
      return {
        ...state,
        customEmailError: true,
        customEmailFetching: false,
        customEmailSuccess: false,
      };
    case CUSTOM_EMAIL_SUCCEEDED:
      return {
        ...state,
        customEmailError: false,
        customEmailFetching: false,
        customEmailSuccess: true,
      };

    case TEMPLATE_EMAIL_REQUESTED:
      return {
        ...state,
        templateEmailError: false,
        templateEmailFetching: true,
        templateEmailSuccess: false,
      };
    case TEMPLATE_EMAIL_FAILED:
      return {
        ...state,
        templateEmailError: true,
        templateEmailFetching: false,
        templateEmailSuccess: false,
      };
    case TEMPLATE_EMAIL_SUCCEEDED:
      return {
        ...state,
        templateEmailError: false,
        templateEmailFetching: false,
        templateEmailSuccess: true,
      };

    case TEMPLATE_SMS_REQUESTED:
      return {
        ...state,
        templateSmsError: false,
        templateSmsFetching: true,
        templateSmsSuccess: false,
      };
    case TEMPLATE_SMS_FAILED:
      return {
        ...state,
        templateSmsError: true,
        templateSmsFetching: false,
        templateSmsSuccess: false,
      };
    case TEMPLATE_SMS_SUCCEEDED:
      return {
        ...state,
        templateSmsError: false,
        templateSmsFetching: false,
        templateSmsSuccess: true,
      };
    default:
      return state;
  }
}
