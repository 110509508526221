import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  INSURANCE_APPLICANT_CREATE_REQUESTED,
  INSURANCE_APPLICANT_CREATE_FAILED,
  INSURANCE_APPLICANT_CREATE_SUCCEEDED,
  INSURANCE_APPLICANT_GET_ALL_REQUESTED,
  INSURANCE_APPLICANT_GET_ALL_FAILED,
  INSURANCE_APPLICANT_GET_ALL_SUCCEEDED,
  INSURANCE_APPLICANT_GET_REQUESTED,
  INSURANCE_APPLICANT_GET_FAILED,
  INSURANCE_APPLICANT_GET_SUCCEEDED,
  INSURANCE_APPLICANT_LOG_GET_REQUESTED,
  INSURANCE_APPLICANT_LOG_GET_FAILED,
  INSURANCE_APPLICANT_LOG_GET_SUCCEEDED,
  INSURANCE_APPLICANT_UPDATE_REQUESTED,
  INSURANCE_APPLICANT_UPDATE_FAILED,
  INSURANCE_APPLICANT_UPDATE_SUCCEEDED,
  LEAD_UPDATE_SUCCEEDED,
  INSURANCE_APPLICANT_DELETE_SUCCEEDED,
  INSURANCE_APPLICANT_DELETE_FAILED,
  INSURANCE_APPLICANT_DELETE_REQUESTED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchInsuranceApplicant(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const insuranceApplicant = yield call(
      remoteResource.getInsuranceApplicant,
      id,
      token
    );
    yield put({
      type: INSURANCE_APPLICANT_GET_SUCCEEDED,
      payload: insuranceApplicant.data,
    });
  } catch (e) {
    yield put({ type: INSURANCE_APPLICANT_GET_FAILED, payload: e.message });
  }
}

function* fetchInsuranceApplicantLog(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const insuranceApplicant = yield call(
      remoteResource.getInsuranceApplicantLog,
      id,
      token
    );
    yield put({
      type: INSURANCE_APPLICANT_LOG_GET_SUCCEEDED,
      payload: insuranceApplicant.data,
    });
  } catch (e) {
    yield put({ type: INSURANCE_APPLICANT_LOG_GET_FAILED, payload: e.message });
  }
}

function* fetchAllInsuranceApplicants(action) {
  try {
    const token = yield select(getToken);
    const insuranceApplicants = yield call(
      remoteResource.getAllInsuranceApplicants,
      token
    );
    yield put({
      type: INSURANCE_APPLICANT_GET_ALL_SUCCEEDED,
      payload: insuranceApplicants.data,
    });
  } catch (e) {
    yield put({ type: INSURANCE_APPLICANT_GET_ALL_FAILED, payload: e.message });
  }
}

function* createInsuranceApplicant(action) {
  try {
    let payload = {};
    let leadId = "";
    if (action.payload && action.payload.payload) {
      payload = action.payload.payload;
    } else {
      payload = action.payload;
    }
    if (action.payload && action.payload && action.payload.leadId) {
      leadId = action.payload.leadId;
    }
    const token = yield select(getToken);
    const insuranceApplicant = yield call(
      remoteResource.createInsuranceApplicant,
      payload,
      token
    );
    if (leadId && leadId !== "") {
      const lead = yield call(
        remoteResource.updateLeadClient,
        leadId,
        { insuranceApplicantId: insuranceApplicant.data.id },
        token
      );
      yield put({
        type: LEAD_UPDATE_SUCCEEDED,
        payload: lead.data,
      });
    }
    yield put({
      type: INSURANCE_APPLICANT_CREATE_SUCCEEDED,
      payload: insuranceApplicant.data,
    });
  } catch (e) {
    yield put({
      type: INSURANCE_APPLICANT_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateInsuranceApplicant(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const insuranceApplicant = yield call(
      remoteResource.updateInsuranceApplicant,
      id,
      payload,
      token
    );
    yield put({
      type: INSURANCE_APPLICANT_UPDATE_SUCCEEDED,
      payload: insuranceApplicant.data,
    });
  } catch (e) {
    yield put({
      type: INSURANCE_APPLICANT_UPDATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* deleteInsuranceApplicant(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    yield call(remoteResource.deleteInsuranceApplicant, id, token);
    yield put({
      type: INSURANCE_APPLICANT_DELETE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: INSURANCE_APPLICANT_DELETE_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchInsuranceApplicant() {
  yield takeLatest(INSURANCE_APPLICANT_GET_REQUESTED, fetchInsuranceApplicant);
  yield takeLatest(
    INSURANCE_APPLICANT_CREATE_REQUESTED,
    createInsuranceApplicant
  );
  yield takeLatest(
    INSURANCE_APPLICANT_UPDATE_REQUESTED,
    updateInsuranceApplicant
  );
  yield takeLatest(
    INSURANCE_APPLICANT_GET_ALL_REQUESTED,
    fetchAllInsuranceApplicants
  );
  yield takeLatest(
    INSURANCE_APPLICANT_LOG_GET_REQUESTED,
    fetchInsuranceApplicantLog
  );

  yield takeLatest(
    INSURANCE_APPLICANT_DELETE_REQUESTED,
    deleteInsuranceApplicant
  );
}
