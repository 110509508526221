import React, { useEffect, useState } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import ReactGA from "react-ga4";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import history from "../../../history";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import SmsEmailAction from "components/SmsEmailAction/SmsEmailAction.js";

import AddLead from "./AddLead";
import EditLeadPanel from "./EditLeadPanel";
import { getColumns, isValidDate } from "../../../scripts/util";
import { filterCaseInsensitive, reactTableHelper } from "../../../scripts/util";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { useDispatch, useSelector } from "react-redux";
import { GetApp } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import moment from "moment";

import withTimer from "../../../components/Timer/withTimer";
import {
  SUPER_ROLE,
  LEAD_TABLE_ACTION,
  DEFAULT_DATE_FORMAT,
  monthColor,
  DEFAULT_DATE_FORMAT_WITH_MONTH,
  DEFAULT_TIME_ZONE,
} from "scripts/const";
import momentTZ from "moment-timezone";
import DateFilter from "../../../components/DateFilter";
import { ROLES } from "scripts/const";
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { USER_UPDATE_REQUESTED } from "redux/actions";
import { USER_GET_REQUESTED } from "redux/actions";
const SelectTable = selectTableHOC(ReactTable);

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const LeadsTables = ({
  match: {
    params: { id },
  },
}) => {
  const [addLeadModal, setAddLeadModal] = React.useState(false);
  const [editLeadModal, setEditLeadModal] = React.useState(false);
  const [currentLead, setCurrentLead] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [sources, setSources] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [fromDateState, setFromDateState] = React.useState("");
  const [toDateState, setToDateState] = React.useState("");

  const [selection, setSelection] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  function isSelected(key) {
    return selection.includes(`select-${key}`);
  }

  useEffect(() => {
    if (id !== "undefined" && id > 0 && data.length > 0) {
      const found = data.find((d) => d.id == id);
      if (found) {
        setCurrentLead(found);
        setEditLeadModal(true);
      }
    }
  }, [id, data]);

  function toggleAll() {
    const keyField = "id";
    const newSelectAll = !selectAll;
    const newSelection = [];

    if (newSelectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = reactTableRef.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        newSelection.push(`select-${item._original[keyField]}`);
      });
    }
    setSelection(newSelection);
    setSelectAll(newSelectAll);
  }
  function toggleSelection(key, shift, row) {
    // start off with the existing state
    let newSelection = [...selection];
    const keyIndex = newSelection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      newSelection = [
        ...newSelection.slice(0, keyIndex),
        ...newSelection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      newSelection.push(key);
    }
    // update the state
    setSelection(newSelection);
  }

  const dispatch = useDispatch();
  const leads = useSelector((state) => state.lead.leads);
  const leadsFetching = useSelector((state) => state.lead.leadGetAllFetching);
  const allUsers = useSelector((state) => state.user.users);
  const activeUsers = useSelector((state) => state.user.activeUsers);
  const allSources = useSelector((state) => state.source.sources);
  const currentUser = useSelector((state) => state.login.loginUser.detail);
  const isSelectTable = SUPER_ROLE.includes(currentUser.roleId);
  const isGeneralUser = currentUser.roleId == ROLES.GENERAL;
  const isLeadGenerator = currentUser.roleId == ROLES.LEAD_MANAGEMENT;
  const leadGeneratorUser = allUsers.filter(
    (user) => user.roleId === ROLES.LEAD_MANAGEMENT
  );
  const userDetail = useSelector((state) => state.user.userDetail);

  React.useEffect(() => {
    dispatch({
      type: USER_GET_REQUESTED,
      payload: currentUser.id,
    });
  }, []);

  const [selectedOptions, setSelectedOptions] = React.useState(
    userDetail?.hideColumn?.leadService || []
  );
  const [columnDataloaded, setColumnDataloaded] = useState(false);

  React.useEffect(() => {
    if (userDetail?.hideColumn?.leadService !== selectedOptions) {
      if (userDetail?.hideColumn?.leadService)
        setSelectedOptions(userDetail?.hideColumn?.leadService);
      setColumnDataloaded(true);
    }
  }, [userDetail?.hideColumn?.leadService]);

  React.useEffect(() => {
    setData(leads);
    if (!isGeneralUser) {
      setFilterData(leads);
    } else {
      const filterData = leads.filter(
        (student) => student.userId === currentUser.id
      );
      setFilterData(filterData);
    }
  }, [leads]);
  React.useEffect(() => {
    setUsers(allUsers);
  }, [allUsers]);

  React.useEffect(() => {
    setSources(allSources);
  }, [allSources]);

  const [dateRange, setDateRange] = React.useState({
    fromDate: "",
    toDate: "",
  });

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setDateRange({
        ...dateRange,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setDateRange({
        ...dateRange,
        [name]: date,
      });
    }
  };

  function filter() {
    let allValid = true;
    setToDateState("");
    setFromDateState("");

    if (dateRange.fromDate === "") {
      setFromDateState("error");
      allValid = false;
    }

    if (dateRange.toDate === "") {
      setToDateState("error");
      allValid = false;
      return;
    }

    if (!isValidDate(dateRange.fromDate)) {
      setFromDateState("error");
      allValid = false;
    }

    if (!isValidDate(dateRange.toDate)) {
      setToDateState("error");
      allValid = false;
    }

    if (allValid) {
      const fromDate = moment(dateRange.fromDate, DEFAULT_DATE_FORMAT);
      const toDate = moment(dateRange.toDate, DEFAULT_DATE_FORMAT);
      const filterData = data.filter(
        (e) => moment(e.createdAt) >= fromDate && moment(e.createdAt) <= toDate
      );
      setFilterData(filterData);
    }
  }

  function getBGColor(row) {
    if (row.followUpDate) {
      return "#fada5e";
    }
    return undefined;
  }

  function getTrProps(state, rowInfo) {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          e.preventDefault();
          setCurrentLead(rowInfo.row._original);
          setEditLeadModal(true);
          history.push(`/admin/leads/${rowInfo.row._original.id}`);
          ReactGA.event({
            category: "Lead",
            action: "View Lead Profile",
          });
        },
        style: {
          background: getBGColor(rowInfo.row._original),
        },
      };
    } else {
      return {};
    }
  }
  const columns = [
    {
      sortable: false,
      Header: " ",
      show: !selectedOptions.includes(" "),
      accessor: "createdAt",
      Cell: (props) => {
        if (props.value) {
          return momentTZ(props.value).format("MMM YYYY");
        }
        return props.value;
      },
      getProps: (state, rowInfo) => {
        let color = null;
        if (rowInfo && rowInfo.row.createdAt) {
          let month = momentTZ(rowInfo.row.createdAt).format("MM");
          color = monthColor[month - 1];
        }
        return {
          style: {
            background: color,
          },
        };
      },
      filterMethod: (filter, row) => {
        return momentTZ(row[filter.id])
          .format("MMM YYYY")
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      },
      width: 70,
    },
    {
      Header: "ID",
      show: !selectedOptions.includes("ID"),
      accessor: "id",
      width: 70,
    },
    {
      Header: "First Name",
      show: !selectedOptions.includes("First Name"),
      accessor: "firstName",
      width: 150,
    },
    {
      Header: "Middle Name",
      show: !selectedOptions.includes("Middle Name"),
      accessor: "middleName",
      width: 150,
    },
    {
      Header: "Last Name",
      show: !selectedOptions.includes("Last Name"),
      accessor: "lastName",
      width: 150,
    },
    {
      Header: "Date of Birth",
      show: !selectedOptions.includes("Date of Birth"),
      accessor: "dob",
      width: 150,
    },
    {
      Header: "Email",
      show: !selectedOptions.includes("Email"),
      accessor: "email",
      width: 150,
    },
    {
      Header: "Phone",
      show: !selectedOptions.includes("Phone"),
      accessor: "phone",
      width: 150,
    },

    {
      Header: "Passport No.",
      show: !selectedOptions.includes("Passport No."),
      accessor: "passport",
      width: 150,
    },
    {
      Header: "Issue Date",
      show: !selectedOptions.includes("Issue Date"),
      accessor: "issueDate",
      width: 150,
    },
    {
      Header: "Expiry Date",
      show: !selectedOptions.includes("Expiry Date"),
      accessor: "expiryDate",
      width: 150,
    },
    {
      Header: "Address",
      show: !selectedOptions.includes("Address"),
      accessor: "address",
    },
    {
      Header: "Location",
      show: !selectedOptions.includes("Location"),
      accessor: "location",
    },
    {
      Header: "Occupation",
      show: !selectedOptions.includes("Occupation"),
      accessor: "occupation",
      width: 120,
    },
    {
      Header: "Qualification",
      show: !selectedOptions.includes("Qualification"),
      accessor: "qualification",
      width: 120,
    },
    {
      Header: "Country",
      show: !selectedOptions.includes("Country"),
      accessor: "country",
    },
    {
      Header: "Visa",
      show: !selectedOptions.includes("Visa"),
      accessor: "visa",
      width: 150,
    },
    {
      Header: "Visa Expiry",
      show: !selectedOptions.includes("Visa Expiry"),
      accessor: "visaExpiry",
      width: 150,
    },
    {
      Header: "Service Type",
      show: !selectedOptions.includes("Service Type"),

      accessor: "serviceType",
      width: 150,
      Cell: (props) => {
        if (props.value && props.value.length > 0) {
          return JSON.parse(props.value).map((v, i) => {
            return (
              <Badge key={i} color={"info"}>
                {v}
              </Badge>
            );
          });
        } else {
          return "";
        }
      },
    },
    !isLeadGenerator && {
      Header: "Source",
      accessor: "sourceId",
      show: !selectedOptions.includes("Source"),
      width: 150,
      Cell: (props) => {
        if (props.value && allSources) {
          const source = allSources.find((e) => e.id === props.value);
          if (source) {
            return source.name;
          } else {
            return props.value;
          }
        } else {
          return props.value;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "noSource") {
          return row[filter.id] === "" || row[filter.id] === null;
        } else if (filter.value) {
          return row[filter.id] == filter.value;
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="noSource">No Source</option>
          {allSources &&
            allSources.map((m, i) => (
              <option key={i} value={m.id}>
                {m.name}
              </option>
            ))}
        </select>
      ),
    },
    !isLeadGenerator && {
      Header: "AssignedTo",
      show: !selectedOptions.includes("AssignedTo"),
      accessor: "userId",
      width: 150,
      Cell: (props) => {
        if (props.value && allUsers) {
          const user = allUsers.find((e) => e.id === props.value);
          if (user) {
            return user.firstName;
          } else {
            return props.value;
          }
        } else {
          return props.value;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "notAssigned") {
          return row[filter.id] === "" || row[filter.id] === null;
        } else if (filter.value) {
          return row[filter.id] == filter.value;
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => {
            if (isGeneralUser) {
              setFilterData(leads);

              if (filterData.length === 0) {
                setTimeout(() => {
                  onChange(event.target.value);
                }, 500);
              } else {
                onChange(event.target.value);
              }
            } else {
              onChange(event.target.value);
            }
          }}
          style={{ width: "100%" }}
          value={filter ? filter.value : isGeneralUser ? currentUser.id : "all"}
        >
          <option value="all">All</option>
          <option value="notAssigned">Not Assigned</option>
          {activeUsers &&
            activeUsers.map((m, i) => (
              <option key={i} value={m.id}>
                {m.firstName}
              </option>
            ))}
        </select>
      ),
    },
    isSelectTable && {
      Header: "Lead Generator",
      show: !selectedOptions.includes("Lead Generator"),
      accessor: "updatedBy",
      width: 150,
      Cell: (props) => {
        if (props.value && leadGeneratorUser) {
          const user = leadGeneratorUser.find((e) => e.id === props.value);
          if (user) {
            return user.firstName;
          } else {
            return props.value;
          }
        } else {
          return props.value;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value) {
          return row[filter.id] == filter.value;
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => {
            onChange(event.target.value);
          }}
          style={{ width: "100%" }}
          value={filter ? filter.value : ""}
        >
          <option value="all">All</option>

          {leadGeneratorUser &&
            leadGeneratorUser.map((m, i) => (
              <option key={i} value={m.id}>
                {m.firstName}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Follow Up",
      show: !selectedOptions.includes("Follow Up"),
      accessor: "followUpDate",
      width: 150,
      Cell: (props) => {
        if (props.value) {
          return moment(props.value, DEFAULT_DATE_FORMAT).format(
            DEFAULT_DATE_FORMAT_WITH_MONTH
          );
        }
        return props.value;
      },
      sortMethod: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "futureFollowUps") {
          if (row[filter.id]) {
            const now = momentTZ().tz(DEFAULT_TIME_ZONE);
            const followUpdate = moment(row[filter.id], DEFAULT_DATE_FORMAT);
            const dateDiff = followUpdate.diff(now, "days");
            return dateDiff >= 0;
          }
          return false;
        }
        if (filter.value === "allFollowUPs") {
          return !!row[filter.id];
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="allFollowUPs">Show Follow Ups</option>
          <option value="futureFollowUps">Future Follow Ups</option>
        </select>
      ),
    },
    {
      Header: "Status",
      show: !selectedOptions.includes("Status"),
      accessor: "status",
      headerStyle: { textAlign: "left" },
      Cell: (props) => {
        if (["Follow Up"].includes(props.value)) {
          return <Badge color={"warning"}>{props.value}</Badge>;
        } else if (props.value === "Not Converted") {
          return <Badge color={"danger"}>{props.value}</Badge>;
        } else if (props.value === "Converted") {
          return <Badge color={"success"}>{props.value}</Badge>;
        } else if (props.value) {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else {
          return <Badge color={"gray"}>{props.value}</Badge>;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "All") {
          return true;
        }
        if (filter.value === "New") {
          return row[filter.id] === "New";
        }
        if (filter.value === "Converted") {
          return row[filter.id] === "Converted";
        }
        if (filter.value === "Follow Up") {
          return row[filter.id] === "Follow Up";
        }
        if (filter.value === "Not Converted") {
          return row[filter.id] === "Not Converted";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "All"}
        >
          <option value="All">All</option>
          <option value="New">New</option>
          <option value="Converted">Converted</option>
          <option value="Not Converted">Not Converted</option>
          <option value="Follow Up">Follow Up</option>
        </select>
      ),
    },
  ];

  function clear() {
    setToDateState("");
    setFromDateState("");
    setDateRange({
      fromDate: "",
      toDate: "",
    });
    setFilterData(data);
  }

  let reactTableRef = null;
  let csvLink = null;

  function handleDateRangeFilter(fromDate, toDate) {
    if (!fromDate || !toDate) {
      setFilterData(data);
    } else {
      const filterData = data.filter(
        (e) => moment(e.createdAt) >= fromDate && moment(e.createdAt) <= toDate
      );
      setFilterData(filterData);
    }
  }
  function download(event) {
    const columns = getColumns("Lead");
    const currentRecords = isSelectTable
      ? reactTableRef.getWrappedInstance().getResolvedState().sortedData
      : reactTableRef.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] =
          currentRecords[index][columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    setDataToDownload(data_to_download);
  }

  const [dataToDownload, setDataToDownload] = React.useState([]);
  const [isDownload, setIsDownload] = React.useState(false);

  React.useEffect(() => {
    if (dataToDownload.length > 0) {
      setIsDownload(true);
    }
  }, [dataToDownload]);

  React.useEffect(() => {
    if (isDownload) {
      csvLink.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  const handleOptionChange = (e) => {
    const option = e.target.value[0];
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  React.useEffect(() => {
    if (
      columnDataloaded &&
      selectedOptions !== userDetail?.hideColumn?.leadService
    ) {
      dispatch({
        type: USER_UPDATE_REQUESTED,
        payload: {
          payload: { leadsColumn: selectedOptions },
          id: currentUser.id,
        },
      });
    }
  }, [selectedOptions]);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <GridContainer direction="row">
              <GridItem xs={5}>
                <Button
                  onClick={() => {
                    setAddLeadModal(true);
                  }}
                  color="primary"
                >
                  Add Lead
                </Button>

                <div style={{ display: "inline-block", marginLeft: "5px" }}>
                  <Button
                    title="Download CSV"
                    justIcon
                    color="info"
                    onClick={download}
                  >
                    <GetApp />
                  </Button>
                  {selection && selection.length > 0 && (
                    <SmsEmailAction
                      option={LEAD_TABLE_ACTION}
                      data={filterData}
                      selection={selection}
                    />
                  )}
                </div>
              </GridItem>

              <CSVLink
                data={dataToDownload}
                filename="leads.csv"
                className="hidden"
                ref={(r) => (csvLink = r)}
                target="_blank"
              />
              <GridItem xs={7}>
                <DateFilter handleFilter={handleDateRangeFilter} />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <InputLabel id="column-select-label">Select Columns</InputLabel>
              <Select
                labelId="column-select-label"
                id="column-select"
                multiple
                value={[]}
                onChange={handleOptionChange}
                renderValue={(selected) => selected.join(", ")}
              >
                {columns.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data.Header}>
                      <Checkbox
                        checked={selectedOptions.includes(data.Header)}
                      />
                      <ListItemText primary={data.Header} />
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {isSelectTable && (
              <SelectTable
                keyField="id"
                toggleSelection={toggleSelection}
                selectAll={selectAll}
                selectType="checkbox"
                toggleAll={toggleAll}
                isSelected={isSelected}
                ref={(r) => (reactTableRef = r)}
                pageSizeOptions={[25, 50, 10]}
                data={filterData}
                filterable
                loading={leadsFetching}
                resizable={true}
                getTrProps={getTrProps}
                columns={columns}
                defaultPageSize={25}
                showPaginationBottom={true}
                className="-striped -highlight"
                defaultFilterMethod={filterCaseInsensitive}
              >
                {(state, makeTable, instance) => {
                  return reactTableHelper(state, makeTable, instance);
                }}
              </SelectTable>
            )}
            {!isSelectTable && (
              <ReactTable
                ref={(r) => (reactTableRef = r)}
                pageSizeOptions={[25, 50, 100]}
                data={filterData}
                filterable
                resizable={true}
                getTrProps={getTrProps}
                columns={columns}
                loading={leadsFetching}
                defaultPageSize={25}
                showPaginationBottom={true}
                className="-striped -highlight"
                defaultFilterMethod={filterCaseInsensitive}
              >
                {(state, makeTable, instance) => {
                  return reactTableHelper(state, makeTable, instance);
                }}
              </ReactTable>
            )}
          </CardBody>
        </Card>
      </GridItem>

      {/*Add Lead*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={addLeadModal}
        TransitionComponent={Transition}
        disableScrollLock={true}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return false;
          }

          if (reason === "escapeKeyDown") {
            return false;
          }
          setAddLeadModal(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAddLeadModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>New Lead</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <AddLead
            allSources={sources}
            allUsers={activeUsers}
            open={addLeadModal}
            handleSuccess={setAddLeadModal}
          />
        </DialogContent>
      </Dialog>

      {/*Edit Lead*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        disableScrollLock={true}
        open={editLeadModal}
        TransitionComponent={Transition}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return false;
          }

          if (reason === "escapeKeyDown") {
            return false;
          }

          setEditLeadModal(false);
          history.push("/admin/leads");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setEditLeadModal(false);
              history.push("/admin/leads");
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>Edit Lead</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <EditLeadPanel
            allSources={sources}
            open={editLeadModal}
            lead={currentLead}
            allUsers={activeUsers}
            handleSuccess={setEditLeadModal}
          />
        </DialogContent>
      </Dialog>
    </GridContainer>
  );
};

export default withTimer(LeadsTables);
