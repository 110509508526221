import {
  LEAVE_CREATE_REQUESTED,
  LEAVE_CREATE_FAILED,
  LEAVE_CREATE_SUCCEEDED,
  LEAVE_GET_ALL_REQUESTED,
  LEAVE_GET_ALL_FAILED,
  LEAVE_GET_ALL_SUCCEEDED,
  LEAVE_GET_REQUESTED,
  LEAVE_GET_FAILED,
  LEAVE_GET_SUCCEEDED,
  LEAVE_UPDATE_REQUESTED,
  LEAVE_UPDATE_FAILED,
  LEAVE_UPDATE_SUCCEEDED,
  LEAVE_GET_USER_REQUESTED,
  LEAVE_GET_USER_FAILED,
  LEAVE_GET_USER_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  leaveCreateError: false,
  leaveCreateFetching: false,
  leaveCreateSuccess: false,

  leaveUpdateError: false,
  leaveUpdateFetching: false,
  leaveUpdateSuccess: false,

  leaveGetError: false,
  leaveGetFetching: false,
  leaveGetSuccess: false,

  leaveGetAllError: false,
  leaveGetAllFetching: false,
  leaveGetAllSuccess: false,

  leaveGetUserError: false,
  leaveGetUserFetching: false,
  leaveGetUserSuccess: false,
};

const defaultState = {
  leaves: [],
  leaveUserData: [],
  pendingLeave: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return { ...state, ...defaultError };
    case LEAVE_CREATE_REQUESTED:
      return {
        ...state,
        leaveCreateFetching: true,
        leaveCreateError: false,
        leaveCreateSuccess: false,
      };
    case LEAVE_CREATE_FAILED:
      return {
        ...state,
        leaveCreateFetching: false,
        leaveCreateError: true,
        leaveCreateSuccess: false,
      };
    case LEAVE_CREATE_SUCCEEDED:
      return {
        ...state,
        leaveDetail: action.payload,
        leaveCreateFetching: false,
        leaveCreateError: false,
        leaveCreateSuccess: true,
      };

    case LEAVE_UPDATE_REQUESTED:
      return {
        ...state,
        leaveUpdateFetching: true,
        leaveUpdateError: false,
        leaveUpdateSuccess: false,
      };
    case LEAVE_UPDATE_FAILED:
      return {
        ...state,
        leaveUpdateFetching: false,
        leaveUpdateError: true,
        leaveUpdateSuccess: false,
      };
    case LEAVE_UPDATE_SUCCEEDED:
      return {
        ...state,
        leaveDetail: action.payload,
        leaveUpdateFetching: false,
        leaveUpdateError: false,
        leaveUpdateSuccess: true,
      };

    case LEAVE_GET_REQUESTED:
      return {
        ...state,
        leaveGetFetching: true,
        leaveGetError: false,
      };
    case LEAVE_GET_FAILED:
      return {
        ...state,
        leaveGetFetching: false,
        leaveGetError: true,
      };
    case LEAVE_GET_SUCCEEDED:
      return {
        ...state,
        leaveDetail: action.payload,
        leaveGetFetching: false,
        leaveGetError: false,
      };

    case LEAVE_GET_ALL_REQUESTED:
      return {
        ...state,
        leaveGetAllFetching: true,
        leaveGetAllError: false,
        leaveGetAllSuccess: false,
      };
    case LEAVE_GET_ALL_FAILED:
      return {
        ...state,
        leaveGetAllFetching: false,
        leaveGetAllError: true,
        leaveGetAllSuccess: false,
      };
    case LEAVE_GET_ALL_SUCCEEDED:
      return {
        ...state,
        leaves: action.payload,
        pendingLeave: action.payload.filter(
          (leave) => leave.status === "pending"
        ),
        leaveGetAllFetching: false,
        leaveGetAllError: false,
        leaveGetAllSuccess: true,
      };

    case LEAVE_GET_USER_REQUESTED:
      return {
        ...state,
        leaveGetUserFetching: true,
        leaveGetUserError: false,
        leaveGetUserSuccess: false,
      };
    case LEAVE_GET_USER_FAILED:
      return {
        ...state,
        leaveGetUserFetching: false,
        leaveGetUserError: true,
        leaveGetUserSuccess: false,
      };
    case LEAVE_GET_USER_SUCCEEDED:
      return {
        ...state,
        leaveUserData: action.payload,
        leaveGetUserFetching: false,
        leaveGetUserError: false,
        leaveGetUserSuccess: true,
      };
    default:
      return state;
  }
}
