import {
  UNIVERSITY_CREATE_REQUESTED,
  UNIVERSITY_CREATE_FAILED,
  UNIVERSITY_CREATE_SUCCEEDED,
  UNIVERSITY_GET_ALL_REQUESTED,
  UNIVERSITY_GET_ALL_FAILED,
  UNIVERSITY_GET_ALL_SUCCEEDED,
  UNIVERSITY_GET_REQUESTED,
  UNIVERSITY_GET_FAILED,
  UNIVERSITY_GET_SUCCEEDED,
  UNIVERSITY_UPDATE_REQUESTED,
  UNIVERSITY_UPDATE_FAILED,
  UNIVERSITY_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  universityCreateError: false,
  universityCreateFetching: false,
  universityCreateSuccess: false,

  universityUpdateError: false,
  universityUpdateFetching: false,
  universityUpdateSuccess: false,

  universityGetError: false,
  universityGetFetching: false,
  universityGetSuccess: false,

  universityGetAllError: false,
  universityGetAllFetching: false,
  universityGetAllSuccess: false,
};

const defaultState = {
  universities: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case UNIVERSITY_CREATE_REQUESTED:
      return {
        ...state,
        universityCreateFetching: true,
        universityCreateError: false,
        universityCreateSuccess: false,
      };
    case UNIVERSITY_CREATE_FAILED:
      return {
        ...state,
        universityCreateFetching: false,
        universityCreateError: true,
        universityCreateSuccess: false,
      };
    case UNIVERSITY_CREATE_SUCCEEDED:
      return {
        ...state,
        universityDetail: action.payload,
        universityCreateFetching: false,
        universityCreateError: false,
        universityCreateSuccess: true,
      };

    case UNIVERSITY_UPDATE_REQUESTED:
      return {
        ...state,
        universityUpdateFetching: true,
        universityUpdateError: false,
        universityUpdateSuccess: false,
      };
    case UNIVERSITY_UPDATE_FAILED:
      return {
        ...state,
        universityUpdateFetching: false,
        universityUpdateError: true,
        universityUpdateSuccess: false,
      };
    case UNIVERSITY_UPDATE_SUCCEEDED:
      return {
        ...state,
        universityDetail: action.payload,
        universityUpdateFetching: false,
        universityUpdateError: false,
        universityUpdateSuccess: true,
      };

    case UNIVERSITY_GET_REQUESTED:
      return {
        ...state,
        universityGetFetching: true,
        universityGetError: false,
      };
    case UNIVERSITY_GET_FAILED:
      return {
        ...state,
        universityGetFetching: false,
        universityGetError: true,
      };
    case UNIVERSITY_GET_SUCCEEDED:
      return {
        ...state,
        universityDetail: action.payload,
        universityGetFetching: false,
        universityGetError: false,
      };

    case UNIVERSITY_GET_ALL_REQUESTED:
      return {
        ...state,
        universityGetAllFetching: true,
        universityGetAllError: false,
      };
    case UNIVERSITY_GET_ALL_FAILED:
      return {
        ...state,
        universityGetAllFetching: false,
        universityGetAllError: true,
      };
    case UNIVERSITY_GET_ALL_SUCCEEDED:
      return {
        ...state,
        universities: action.payload,
        universityGetAllFetching: false,
        universityGetAllError: false,
      };
    default:
      return state;
  }
}
