import React, { useEffect } from "react";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnzsco,
  getDropDown,
  getOccupation,
  isDuplicateApplicant,
  isValidSkillAssessmentInputs,
  labelStyleFunc,
} from "../../../scripts/util";
import {
  CLEAR_ERROR,
  SKILL_ASSESSMENT_GET_ALL_REQUESTED,
  SKILL_ASSESSMENT_CREATE_REQUESTED,
} from "../../../redux/actions";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import ReactGA from "react-ga4";
import {
  DEFAULT_DATE_FORMAT,
  GENERAL_ROLE,
  occupationCodeOption,
  useGetOccupationOptions,
  useGetVisaTypeOptions,
  TINY_INIT,
} from "../../../scripts/const";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import ConfirmationAlert from "../../../components/Alert/ConfirmationAlert";
import { Editor } from "@tinymce/tinymce-react";
import { default as ReactSelect } from "react-select";
import FileUpload from "../../../components/CustomUpload/FileUpload";
import DropDownWithSearch, {
  customReactSelectStyles,
} from "../../../components/DropDownWithSearch/DropDownWithSearch";

const useStyles = makeStyles(styles);

export default function AddSkillAssessment(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const occupationOption = useGetOccupationOptions();
  const visaTypeOption = useGetVisaTypeOptions();

  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const submit = () => {
    confirmSkillAssessment();
  };
  const selectedEvent = () => {
    setAlert(<ConfirmationAlert cancel={hideAlert} submit={submit} />);
  };

  const [validationErrorMsg, setValidationErrorMsg] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [skillAssessment, setSkillAssessment] = React.useState({});
  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );
  const errMsg = useSelector((state) => state.skillAssessment.errorMsg);
  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);

  const handleFileUpload = (fileUrl) => {
    if (!skillAssessment.files) {
      setSkillAssessment({
        ...skillAssessment,
        files: [fileUrl],
      });
    } else {
      setSkillAssessment({
        ...skillAssessment,
        files: [...skillAssessment.files, fileUrl],
      });
    }
  };

  const handleFileDelete = (fileUrl) => {
    setSkillAssessment({
      ...skillAssessment,
      files: skillAssessment.files.filter((f) => f != fileUrl),
    });
  };

  const handleSelect = (e) => {
    setSkillAssessment({
      ...skillAssessment,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditorChange = (content) => {
    setSkillAssessment({
      ...skillAssessment,
      remarks: content,
    });
  };

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      if (name === "requestedDate") {
        setSkillAssessment({
          ...skillAssessment,
          [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
          dueDate: date
            .clone()
            .add(27, "days")
            .format(DEFAULT_DATE_FORMAT)
            .toString(),
        });
      } else {
        setSkillAssessment({
          ...skillAssessment,
          [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
        });
      }
    } else {
      setSkillAssessment({
        ...skillAssessment,
        [name]: date,
      });
    }
  };

  const dispatch = useDispatch();

  const saveError = useSelector(
    (state) => state.skillAssessment.skillAssessmentCreateError
  );
  const saveSuccess = useSelector(
    (state) => state.skillAssessment.skillAssessmentCreateSuccess
  );
  const saveProgress = useSelector(
    (state) => state.skillAssessment.skillAssessmentCreateFetching
  );

  function createSkillAssessment() {
    const errors = isValidSkillAssessmentInputs(skillAssessment);
    if (errors.length > 0) {
      setValidationErrorMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else if (
      isDuplicateApplicant(skillAssessment, props.allSkillAssessment)
    ) {
      selectedEvent();
    } else {
      confirmSkillAssessment();
    }
  }

  function confirmSkillAssessment() {
    setValidationErrorMsg("");
    dispatch({
      type: SKILL_ASSESSMENT_CREATE_REQUESTED,
      payload: skillAssessment,
    });
  }

  function clearErrors() {
    setValidationErrorMsg("");
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      clearErrors();
      // setStudent({});
    }
  }, [modalOpen]);

  useEffect(() => {
    setValidationErrorMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    setError(saveError);
  }, [saveError]);

  useEffect(() => {
    setSuccess(saveSuccess);
  }, [saveSuccess]);

  useEffect(() => {
    if (saveSuccess === true) {
      setTimeout(() => {
        props.handleSuccess(false);
      }, 700);
      dispatch({ type: SKILL_ASSESSMENT_GET_ALL_REQUESTED });
      setTimeout(() => {
        setSkillAssessment({});
        dispatch({ type: CLEAR_ERROR });
      }, 701);
    }
  }, [saveSuccess]);

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {validationErrorMsg && (
                    <SnackbarContent
                      message={validationErrorMsg}
                      color="warning"
                    />
                  )}
                  {error && (
                    <SnackbarContent message={errMessage} color="warning" />
                  )}
                  {saveProgress && <CustomLinearProgress color="primary" />}
                  {success && (
                    <SnackbarContent
                      message="Added the skill assessment"
                      color="success"
                    />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email address *"
                    id="skill-add-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.email || "",
                      onChange: handleSelect,
                      name: "email",
                      type: "email",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="skill-add-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="First Name *"
                    id="skill-add-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Middle Name"
                    id="skill-add-middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.middleName || "",
                      onChange: handleSelect,
                      name: "middleName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Last Name *"
                    id="skill-add-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Date of Birth
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.dob || ""}
                      onChange={(moment) => handleDatePicker(moment, "dob")}
                      inputProps={{
                        name: "dob",
                        id: "add-skill-dob-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Passport No"
                    id="skill-add-passport"
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.passport,
                      onChange: handleSelect,
                      name: "passport",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Issue Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.issueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "issueDate")
                      }
                      inputProps={{
                        name: "issueDate",
                        id: "skill-add-issueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Expiry Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.expiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "expiryDate")
                      }
                      inputProps={{
                        name: "expiryDate",
                        id: "skill-add-expiryDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(skillAssessment.currentVisa)}
                    >
                      Current Visa
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        skillAssessment.currentVisa
                          ? {
                              label: skillAssessment.currentVisa,
                              value: skillAssessment.currentVisa,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={visaTypeOption}
                      name="currentVisa"
                      placeholder={"Current Visa"}
                      onChange={(options) => {
                        if (!options) {
                          setSkillAssessment({
                            ...skillAssessment,
                            currentVisa: "",
                          });
                        } else {
                          setSkillAssessment({
                            ...skillAssessment,
                            currentVisa: options.value,
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Visa Expiry Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.endDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "visaExpiry")
                      }
                      inputProps={{
                        name: "visaExpiry",
                        id: "visaExpiry-add",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(skillAssessment.occupation)}
                    ></div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        skillAssessment.occupation
                          ? {
                              label: skillAssessment.occupation,
                              value: skillAssessment.occupation,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={occupationOption}
                      name="occupation"
                      placeholder={"Occupation"}
                      onChange={(options) => {
                        if (!options) {
                          setSkillAssessment({
                            ...skillAssessment,
                            occupation: "",
                            anzsco: "",
                          });
                        } else {
                          setSkillAssessment({
                            ...skillAssessment,
                            occupation: options.value,
                            anzsco: getAnzsco(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(skillAssessment.anzsco)}
                    ></div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        skillAssessment.anzsco
                          ? {
                              label: skillAssessment.anzsco,
                              value: skillAssessment.anzsco,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={occupationCodeOption}
                      name="anzsco"
                      placeholder={"ANZSCO"}
                      onChange={(options) => {
                        if (!options) {
                          setSkillAssessment({
                            ...skillAssessment,
                            anzsco: "",
                            occupation: "",
                          });
                        } else {
                          setSkillAssessment({
                            ...skillAssessment,
                            anzsco: options.value,
                            occupation: getOccupation(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("skill-assessment-body-full")}
                    selectedOption={skillAssessment.skillAssessmentBody || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"skillAssessmentBody"}
                    label={"Skill Assessment Body"}
                  />
                </GridItem>
                {skillAssessment.skillAssessmentBody &&
                  skillAssessment.skillAssessmentBody === "Other" && (
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Other Body *"
                        id="skill-add-other"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: skillAssessment.otherSkillAssessmentBody || "",
                          onChange: handleSelect,
                          name: "otherSkillAssessmentBody",
                        }}
                      />
                    </GridItem>
                  )}
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Invoice Number"
                    id="add-skill-invoiceNumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.invoiceNumber || "",
                      onChange: handleSelect,
                      name: "invoiceNumber",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Payment"
                    id="add-skill-payment"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: skillAssessment.payment || "",
                      onChange: handleSelect,
                      name: "payment",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <DropDownWithSearch
                    optionsList={getDropDown("payment-status")}
                    selectedOption={skillAssessment.paymentStatus || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"paymentStatus"}
                    label={"Payment Status"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Requested Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      autoOk
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.requestedDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "requestedDate")
                      }
                      inputProps={{
                        name: "requestedDate",
                        id: "requestedDate-add",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Due Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.dueDate || ""}
                      onChange={(moment) => handleDatePicker(moment, "dueDate")}
                      inputProps={{
                        name: "dueDate",
                        id: "dueDate-add2",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Submitted Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.submittedDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "submittedDate")
                      }
                      inputProps={{
                        name: "submittedDate",
                        id: "submittedDate-add2",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    DecisionDate Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={skillAssessment.decisionDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "decisionDate")
                      }
                      inputProps={{
                        name: "decisionDate",
                        id: "decisionDate-add2",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <DropDownWithSearch
                    optionsList={getDropDown("skill-csa-status")}
                    selectedOption={skillAssessment.csaStatus || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"csaStatus"}
                    label={"CSA Status"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <DropDownWithSearch
                    optionsList={getDropDown("skill-status")}
                    selectedOption={skillAssessment.status || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"status"}
                    label={"Status *"}
                  />
                </GridItem>
                {skillAssessment.status &&
                  ["Applied", "Approved", "Rejected", "Discontinued"].includes(
                    skillAssessment.status
                  ) && (
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel className={classes.label}>
                        {skillAssessment.status} Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          closeOnSelect
                          timeFormat={false}
                          dateFormat={DEFAULT_DATE_FORMAT}
                          value={skillAssessment.statusDate || ""}
                          onChange={(moment) =>
                            handleDatePicker(moment, "statusDate")
                          }
                          inputProps={{
                            name: "statusDate",
                            id: "add-skill-statusDate",
                            placeholder: DEFAULT_DATE_FORMAT,
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("country")}
                    selectedOption={skillAssessment.country || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"country"}
                    label={"Country"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("location")}
                    selectedOption={skillAssessment.location || ""}
                    setValue={setSkillAssessment}
                    currentState={skillAssessment}
                    name={"location"}
                    label={"Location"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allSources && (
                        <DropDownWithSearch
                          dbOptions={props.allSources}
                          selectedOption={skillAssessment.sourceId || ""}
                          setValue={setSkillAssessment}
                          currentState={skillAssessment}
                          name={"sourceId"}
                          label={"Source"}
                        />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allUsers && (
                        <DropDownWithSearch
                          dbOptions={props.allUsers}
                          dbLabel={"firstName"}
                          selectedOption={skillAssessment.userId || ""}
                          setValue={setSkillAssessment}
                          currentState={skillAssessment}
                          name={"userId"}
                          label={"Assigned To"}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={skillAssessment.remarks || ""}
                    init={TINY_INIT}
                    onEditorChange={handleEditorChange}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div>
                    <h4>Documents:</h4>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      type={"skillAssessment"}
                      setFileUrl={(fileUrl) => handleFileUpload(fileUrl)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {skillAssessment.files &&
                  skillAssessment.files.length > 0 &&
                  skillAssessment.files.map((fileUrl, id) => {
                    return (
                      <GridItem key={id}>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            backgroundColor: "#B0BEC5",
                            padding: "20px",
                            borderRadius: "25px",
                            marginTop: "10px",
                          }}
                        >
                          <FileUpload
                            fileUrl={fileUrl}
                            key={id}
                            handleDelete={(fileUrl) => {
                              handleFileDelete(fileUrl);
                            }}
                          />
                        </div>
                      </GridItem>
                    );
                  })}
              </GridContainer>
              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={(e) => {
                  ReactGA.event({
                    category: "SkillAssessmentService",
                    action: "Added a Skill Assessment profile",
                  });
                  e.preventDefault();
                  createSkillAssessment();
                }}
              >
                Add
              </Button>
              {alert}
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
