import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  AGREEMENT_CREATE_REQUESTED,
  AGREEMENT_CREATE_FAILED,
  AGREEMENT_CREATE_SUCCEEDED,
  AGREEMENT_GET_ALL_REQUESTED,
  AGREEMENT_GET_ALL_FAILED,
  AGREEMENT_GET_ALL_SUCCEEDED,
  AGREEMENT_UPDATE_REQUESTED,
  AGREEMENT_UPDATE_FAILED,
  AGREEMENT_UPDATE_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchAllAgreements() {
  try {
    const token = yield select(getToken);
    const agreements = yield call(remoteResource.getAllAgreements, token);
    yield put({
      type: AGREEMENT_GET_ALL_SUCCEEDED,
      payload: agreements.data,
    });
  } catch (e) {
    yield put({ type: AGREEMENT_GET_ALL_FAILED, payload: e.message });
  }
}

function* createAgreement(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const agreement = yield call(
      remoteResource.createAgreement,
      payload,
      token
    );
    yield put({
      type: AGREEMENT_CREATE_SUCCEEDED,
      payload: agreement.data,
    });
  } catch (e) {
    yield put({ type: AGREEMENT_CREATE_FAILED, payload: e.message });
  }
}

function* updateAgreement(action) {
  try {
    const { payload, id } = action;
    const token = yield select(getToken);
    const agreement = yield call(
      remoteResource.updateAgreement,
      id,
      payload,
      token
    );
    yield put({
      type: AGREEMENT_UPDATE_SUCCEEDED,
      payload: agreement.data,
    });
  } catch (e) {
    yield put({ type: AGREEMENT_UPDATE_FAILED, payload: e.message });
  }
}

export function* watchAgreement() {
  yield takeLatest(AGREEMENT_CREATE_REQUESTED, createAgreement);
  yield takeLatest(AGREEMENT_UPDATE_REQUESTED, updateAgreement);
  yield takeLatest(AGREEMENT_GET_ALL_REQUESTED, fetchAllAgreements);
}
