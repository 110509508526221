import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  VISA_APPLICANT_STATS_REQUESTED,
  VISA_APPLICANT_STATS_FAILED,
  VISA_APPLICANT_STATS_SUCCEEDED,
  EDUCATION_STATS_FAILED,
  EDUCATION_STATS_REQUESTED,
  EDUCATION_STATS_SUCCEEDED,
  HEALTH_INSURANCE_STATS_FAILED,
  HEALTH_INSURANCE_STATS_REQUESTED,
  HEALTH_INSURANCE_STATS_SUCCEEDED,
  SKILL_ASSESSMENT_STATS_FAILED,
  SKILL_ASSESSMENT_STATS_REQUESTED,
  SKILL_ASSESSMENT_STATS_SUCCEEDED,
  CONVERTED_STATS_SUCCEEDED,
  CONVERTED_STATS_FAILED,
  CONVERTED_STATS_REQUESTED,
  LEAD_STATS_SUCCEEDED,
  LEAD_STATS_FAILED,
  LEAD_STATS_REQUESTED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchVisaApplicantStats() {
  try {
    const token = yield select(getToken);
    const sources = yield call(remoteResource.getVisaStats, token);
    yield put({
      type: VISA_APPLICANT_STATS_SUCCEEDED,
      payload: sources.data,
    });
  } catch (e) {
    yield put({ type: VISA_APPLICANT_STATS_FAILED, payload: e.message });
  }
}

function* fetchEducationStats() {
  try {
    const token = yield select(getToken);
    const sources = yield call(remoteResource.getEducationStats, token);
    yield put({
      type: EDUCATION_STATS_SUCCEEDED,
      payload: sources.data,
    });
  } catch (e) {
    yield put({ type: EDUCATION_STATS_FAILED, payload: e.message });
  }
}

function* fetchSkillAssessmentStats() {
  try {
    const token = yield select(getToken);
    const sources = yield call(remoteResource.getSkillAssesmentStats, token);
    yield put({
      type: SKILL_ASSESSMENT_STATS_SUCCEEDED,
      payload: sources.data,
    });
  } catch (e) {
    yield put({ type: SKILL_ASSESSMENT_STATS_FAILED, payload: e.message });
  }
}

function* fetchHealthInsuranceStats() {
  try {
    const token = yield select(getToken);
    const sources = yield call(remoteResource.getHealthInsuranceStats, token);
    yield put({
      type: HEALTH_INSURANCE_STATS_SUCCEEDED,
      payload: sources.data,
    });
  } catch (e) {
    yield put({ type: HEALTH_INSURANCE_STATS_FAILED, payload: e.message });
  }
}
function* fetchLeadStats() {
  try {
    const token = yield select(getToken);
    const sources = yield call(remoteResource.getLeadStats, token);
    yield put({
      type: LEAD_STATS_SUCCEEDED,
      payload: sources.data,
    });
  } catch (e) {
    yield put({ type: LEAD_STATS_FAILED, payload: e.message });
  }
}

function* fetchConvertedStats() {
  try {
    const token = yield select(getToken);
    const sources = yield call(remoteResource.getConvertedStats, token);
    yield put({
      type: CONVERTED_STATS_SUCCEEDED,
      payload: sources.data,
    });
  } catch (e) {
    yield put({ type: CONVERTED_STATS_FAILED, payload: e.message });
  }
}

export function* watchStats() {
  yield takeLatest(VISA_APPLICANT_STATS_REQUESTED, fetchVisaApplicantStats);
  yield takeLatest(EDUCATION_STATS_REQUESTED, fetchEducationStats);
  yield takeLatest(SKILL_ASSESSMENT_STATS_REQUESTED, fetchSkillAssessmentStats);
  yield takeLatest(HEALTH_INSURANCE_STATS_REQUESTED, fetchHealthInsuranceStats);
  yield takeLatest(LEAD_STATS_REQUESTED, fetchLeadStats);
  yield takeLatest(CONVERTED_STATS_REQUESTED, fetchConvertedStats);
}
