import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Notifications from "@material-ui/icons/Notifications";
import Accessibility from "@material-ui/icons/Accessibility";
import RoomIcon from "@material-ui/icons/Room";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { useDispatch, useSelector } from "react-redux";
import history from "../../history";
import {
  CHECKIN_GET_ALL_REQUESTED,
  LEAVE_GET_ALL_REQUESTED,
  TODO_COUNT_GET_REQUESTED,
} from "../../redux/actions";
import ReactGA from "react-ga4";
import TimeSpent from "../Timer/TimeSpent";
import HeaderTodo from "./HeaderTodo";
import { getUserName } from "../../scripts/util";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const [todos, setTodos] = useState([]);
  const [activeCheckin, setActiveCheckin] = useState([]);
  const currentUser = useSelector((state) => state.login.loginUser.detail);
  const allUsers = useSelector((state) => state.user.users);

  const hasCheckinAccess = () => currentUser && currentUser.roleId != 4;
  const hasLeaveAccess = () => currentUser && currentUser.roleId === 1;
  const dispatch = useDispatch();

  const activeTodos = useSelector((state) => state.todo.activeTodo);
  const checkins = useSelector((state) => state.checkin.checkins);
  const pendingLeave = useSelector((state) => state.leave.pendingLeave);

  useEffect(() => {
    setTodos(activeTodos);
  }, [activeTodos]);

  useEffect(() => {
    setActiveCheckin(checkins.filter((ck) => ck.timerStop == null));
  }, [checkins]);

  useEffect(() => {
    const timer = setInterval(
      () => dispatch({ type: TODO_COUNT_GET_REQUESTED }),
      300000
    );
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (hasCheckinAccess()) {
      const interval = setInterval(() => {
        dispatch({ type: CHECKIN_GET_ALL_REQUESTED });
      }, 15000);
      return () => clearInterval(interval);
    }
  });

  useEffect(() => {
    if (hasCheckinAccess()) {
      const interval = setInterval(() => {
        dispatch({ type: LEAVE_GET_ALL_REQUESTED });
      }, 900000);
      return () => clearInterval(interval);
    }
  });

  const [openNotification, setOpenNotification] = React.useState(null);
  const [openLeaveNotification, setOpenLeaveNotification] =
    React.useState(null);
  const [openCheckin, setOpenCheckin] = React.useState(null);

  const handleClickCheckin = (event) => {
    ReactGA.event({
      category: "Check-In",
      action: "Open Checkin Notification",
    });
    if (openCheckin && openCheckin.contains(event.target)) {
      setOpenCheckin(null);
    } else {
      setOpenCheckin(event.currentTarget);
    }
  };

  const handleClickNotification = (event) => {
    ReactGA.event({
      category: "TODO",
      action: "Open Todo Notification",
    });
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleClickPendingLeave = (event) => {
    ReactGA.event({
      category: "Leave",
      action: "Open Leave Notification",
    });
    if (openLeaveNotification && openLeaveNotification.contains(event.target)) {
      setOpenLeaveNotification(null);
    } else {
      setOpenLeaveNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
    setOpenCheckin(null);
  };

  const handleTodoNavigate = () => {
    setOpenNotification(null);
    history.push("/admin/todo");
  };

  const handleLeaveNavigate = (id) => {
    setOpenLeaveNotification(null);
    history.push(`/admin/employees/${id}`);
  };

  const handleCheckinNavigate = () => {
    setOpenCheckin(null);
    history.push("/admin/checkin");
  };

  const classes = useStyles();
  const { rtlActive } = props;

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });
  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        {activeCheckin && activeCheckin.length > 0 && (
          <Button
            color="transparent"
            justIcon
            aria-label="Checkins"
            aria-owns={openCheckin ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={handleClickCheckin}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
          >
            <RoomIcon
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <span className={classes.notifications}>
              {activeCheckin.length}
            </span>
          </Button>
        )}
          <HeaderTodo/>
        {todos && todos.length > 0 && (
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openNotification ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={handleClickNotification}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <span className={classes.notifications}>{todos.length}</span>
          </Button>
        )}
        {hasLeaveAccess() && pendingLeave && pendingLeave.length > 0 && (
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={openLeaveNotification ? "notification-menu-list" : null}
            aria-haspopup="true"
            onClick={handleClickPendingLeave}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
          >
            <Accessibility
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <span className={classes.notifications}>{pendingLeave.length}</span>
          </Button>
        )}
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {todos.map((todo, i) => (
                      <MenuItem
                        key={i}
                        onClick={handleTodoNavigate}
                        className={dropdownItem}
                      >
                        {todo.detail.length > 100
                          ? todo.detail.substring(0, 100) + "..."
                          : todo.detail}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      
        <Popper
          open={Boolean(openLeaveNotification)}
          anchorEl={openLeaveNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openLeaveNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {pendingLeave.map((leave, i) => (
                      <MenuItem
                        key={i}
                        onClick={() => handleLeaveNavigate(leave.userId)}
                        className={dropdownItem}
                      >
                        {`${leave.type} - ${
                          getUserName(leave.userId, allUsers).split(" ")[0]
                        }`}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Popper
          open={Boolean(openCheckin)}
          anchorEl={openCheckin}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openCheckin,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    {activeCheckin.map((checkin, i) => (
                      <MenuItem
                        key={i}
                        onClick={handleCheckinNavigate}
                        className={dropdownItem}
                      >
                        {checkin.lead &&
                          `${checkin.lead.firstName} ${checkin.lead.lastName} - `}
                        <TimeSpent value={checkin.timerStart} />
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
