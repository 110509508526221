import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import "./styles.css";

import styles from "assets/jss/material-dashboard-pro-react/components/accordionStyle.js";
import GridItem from "../Grid/GridItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import { DEFAULT_DATE_FORMAT } from "../../scripts/const";
import Danger from "../Typography/Danger";
import Button from "../CustomButtons/Button";
import GridContainer from "../Grid/GridContainer";
import moment from "moment";
import { isValidDate } from "../../scripts/util";

const useStyles = makeStyles(styles);

export default function DateFilter({ handleFilter }) {
  const [fromDateState, setFromDateState] = React.useState("");
  const [toDateState, setToDateState] = React.useState("");

  const [dateRange, setDateRange] = React.useState({
    fromDate: "",
    toDate: "",
  });

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setDateRange({
        ...dateRange,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setDateRange({
        ...dateRange,
        [name]: date,
      });
    }
  };

  function filter() {
    let allValid = true;
    setToDateState("");
    setFromDateState("");

    if (dateRange.fromDate === "") {
      setFromDateState("error");
      allValid = false;
    }

    if (dateRange.toDate === "") {
      setToDateState("error");
      allValid = false;
      return;
    }

    if (!isValidDate(dateRange.fromDate)) {
      setFromDateState("error");
      allValid = false;
    }

    if (!isValidDate(dateRange.toDate)) {
      setToDateState("error");
      allValid = false;
    }

    if (allValid) {
      const fromDate = moment(dateRange.fromDate, DEFAULT_DATE_FORMAT);
      const toDate = moment(dateRange.toDate, DEFAULT_DATE_FORMAT);
      handleFilter(fromDate, toDate);
    }
  }

  function clear() {
    setToDateState("");
    setFromDateState("");
    setDateRange({
      fromDate: "",
      toDate: "",
    });
    handleFilter("", "");
  }

  const classes = useStyles();
  return (
    <GridContainer
      alignItems="flex-end"
      justifyContent="flex-end"
      direction="row"
    >
      <GridItem>
        <InputLabel className={classes.label}>From Date</InputLabel>
        <FormControl fullWidth>
          <Datetime
            timeFormat={false}
            dateFormat={DEFAULT_DATE_FORMAT}
            value={dateRange.fromDate || ""}
            onChange={(moment) => handleDatePicker(moment, "fromDate")}
            inputProps={{
              name: "fromDate",
              id: "fromDate-items",
              placeholder: DEFAULT_DATE_FORMAT,
              className: classes.info,
            }}
          />

          {fromDateState && (
            <GridItem>
              <Danger>Error</Danger>
            </GridItem>
          )}
        </FormControl>
      </GridItem>
      <GridItem>
        <InputLabel className={classes.label}>To Date</InputLabel>
        <FormControl fullWidth>
          <Datetime
            timeFormat={false}
            dateFormat={DEFAULT_DATE_FORMAT}
            value={dateRange.toDate || ""}
            onChange={(moment) => handleDatePicker(moment, "toDate")}
            inputProps={{
              name: "toDate",
              id: "toDate-items",
              placeholder: DEFAULT_DATE_FORMAT,
              className: classes.info,
            }}
          />
          {toDateState && (
            <GridItem>
              <Danger>Error</Danger>
            </GridItem>
          )}
        </FormControl>
      </GridItem>
      <GridItem>
        <Button
          color="warning"
          size={"sm"}
          onClick={(e) => {
            e.preventDefault();
            filter();
          }}
        >
          Filter
        </Button>
        <Button
          color="warning"
          size={"sm"}
          onClick={(e) => {
            e.preventDefault();
            clear();
          }}
        >
          Clear
        </Button>
      </GridItem>
    </GridContainer>
  );
}
