import React, { useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import imageCompression from "browser-image-compression";
import axios from "axios";
import { useDispatch } from "react-redux";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultAvatar from "assets/img/placeholder.jpg";
import {
  USER_UPDATE_REQUESTED,
  USER_UPDATE_FETCHING,
  CLEAR_ERROR,
  PROFILE_UPDATE_REQUESTED,
  USER_GET_ALL_REQUESTED,
} from "../../redux/actions";
import ReactGA from "react-ga4";
import { FILE_UPLOAD_URL, TENANT } from "../../scripts/const";

export default function ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [file2, setFile2] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(defaultAvatar);
  const [isChanged, setIsChanged] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  useEffect(() => {
    if (props.user.profileUrl) {
      setImagePreviewUrl(props.user.profileUrl);
    } else {
      setImagePreviewUrl(defaultAvatar);
      setIsChanged(false);
      setFile(null);
      setFile2(null);
    }
  }, [props.user, props.isNew]);

  const dispatch = useDispatch();
  let fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    setIsChanged(true);
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile2(file);
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleSave = () => {
    ReactGA.event({
      category: "IMAGE",
      action: `Profile Image Uploaded`,
    });
    setIsSaving(true);
    dispatch({
      type: CLEAR_ERROR,
    });
    dispatch({
      type: USER_UPDATE_FETCHING,
    });
    uploadImage((remoteFileUrl) => {
      setIsChanged(false);
      dispatch({
        type: props.isProfile
          ? PROFILE_UPDATE_REQUESTED
          : USER_UPDATE_REQUESTED,
        payload: {
          payload: { profileUrl: remoteFileUrl },
          id: props.user.id,
        },
      });
      dispatch({
        type: CLEAR_ERROR,
      });
      dispatch({
        type: USER_GET_ALL_REQUESTED,
      });
      setIsSaving(false);
    });
  };

  function uploadImage(callback) {
    const fileUploadUrl = FILE_UPLOAD_URL;

    let remoteFileUrl = null;
    if (file2 && file2 instanceof Blob) {
      const fileName = file2.name;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };
      imageCompression(file2, options)
        .then(function (compressedFile) {
          axios
            .post(fileUploadUrl, {
              name: fileName,
              folder: `${TENANT}/${props.user.firstName.toLowerCase()}`,
            })
            .then(function (response) {
              const responseBody = response.data;
              const formData = new FormData();
              Object.keys(responseBody.fields).forEach((key) => {
                formData.append(key, responseBody.fields[key]);
              });
              formData.append("file", compressedFile);
              const uploadURL = responseBody.url;
              remoteFileUrl = responseBody.fileUrl;
              axios
                .post(uploadURL, formData)
                .then(function () {
                  callback(remoteFileUrl);
                })
                .catch(function (error) {
                  setIsSaving(false);
                  console.log("Image Upload error: ", error);
                });
            })
            .catch(function (error) {
              setIsSaving(false);
              console.log("Image Upload error: ", error);
            });
        })
        .catch(function (error) {
          setIsSaving(false);
          console.log("Error: ", error.message);
        });
    } else {
      dispatch({
        type: CLEAR_ERROR,
      });
      setIsSaving(false);
    }
  }
  let { avatar } = props;
  const addButtonProps = {
    color: "rose",
    round: true,
  };
  const changeButtonProps = {
    color: "rose",
    round: true,
  };
  const saveButtonProps = {
    color: "success",
    round: true,
  };
  return (
    <div className="fileinput text-center">
      <input
        type="file"
        accept="image/x-png,image/gif,image/jpeg"
        onChange={handleImageChange}
        ref={fileInput}
      />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img className="picture-src" src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {file === null && !props.user.profileUrl ? (
          <Button size={"sm"} {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span>
            <Button
              disabled={isSaving ? true : false}
              size={"sm"}
              {...changeButtonProps}
              onClick={() => handleClick()}
            >
              Change
            </Button>
            {avatar ? <br /> : null}
            {isChanged && (
              <Button
                disabled={isSaving ? true : false}
                size={"sm"}
                {...saveButtonProps}
                onClick={() => handleSave()}
              >
                <i className="fas fa-save" /> Save
              </Button>
            )}
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  isProfile: PropTypes.bool,
  isNew: PropTypes.bool,
  user: PropTypes.object,
};
