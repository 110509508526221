import {
  LEAD_CREATE_REQUESTED,
  LEAD_CREATE_FAILED,
  LEAD_CREATE_SUCCEEDED,
  LEAD_DELETE_REQUESTED,
  LEAD_DELETE_FAILED,
  LEAD_DELETE_SUCCEEDED,
  LEAD_GET_ALL_REQUESTED,
  LEAD_GET_ALL_FAILED,
  LEAD_GET_ALL_SUCCEEDED,
  LEAD_SELF_ASSIGNED_SUCCEEDED,
  LEAD_GET_REQUESTED,
  LEAD_GET_FAILED,
  LEAD_GET_SUCCEEDED,
  LEAD_LOG_GET_REQUESTED,
  LEAD_LOG_GET_FAILED,
  LEAD_LOG_GET_SUCCEEDED,
  LEAD_UPDATE_REQUESTED,
  LEAD_UPDATE_FAILED,
  LEAD_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  leadCreateError: false,
  leadCreateFetching: false,
  leadCreateSuccess: false,

  leadDeleteError: false,
  leadDeleteFetching: false,
  leadDeleteSuccess: false,

  leadUpdateError: false,
  leadUpdateFetching: false,
  leadUpdateSuccess: false,

  leadGetError: false,
  leadGetFetching: false,
  leadGetSuccess: false,

  leadLogGetError: false,
  leadLogGetFetching: false,
  leadLogGetSuccess: false,

  leadGetAllError: false,
  leadGetAllSuccess: false,
  errorMsg: "",
};

const defaultState = {
  leads: [],
  selfAssignedLead: [],
  leadLog: [],
  leadDetail: {},
  leadGetAllFetching: false,
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case LEAD_CREATE_REQUESTED:
      return {
        ...state,
        leadCreateFetching: true,
        leadCreateError: false,
        leadCreateSuccess: false,
      };
    case LEAD_CREATE_FAILED:
      return {
        ...state,
        leadCreateFetching: false,
        leadCreateError: true,
        leadCreateSuccess: false,
        errorMsg: action.payload,
      };
    case LEAD_CREATE_SUCCEEDED:
      return {
        ...state,
        leadDetail: action.payload,
        leadCreateFetching: false,
        leadCreateError: false,
        leadCreateSuccess: true,
      };

    case LEAD_DELETE_REQUESTED:
      return {
        ...state,
        leadDeleteFetching: true,
        leadDeleteError: false,
        leadDeleteSuccess: false,
      };
    case LEAD_DELETE_FAILED:
      return {
        ...state,
        leadDeleteFetching: false,
        leadDeleteError: true,
        leadDeleteSuccess: false,
        errorMsg: action.payload,
      };
    case LEAD_DELETE_SUCCEEDED:
      return {
        ...state,
        leadDeleteFetching: false,
        leadDeleteError: false,
        leadDeleteSuccess: true,
      };

    case LEAD_UPDATE_REQUESTED:
      return {
        ...state,
        leadUpdateFetching: true,
        leadUpdateError: false,
        leadUpdateSuccess: false,
      };
    case LEAD_UPDATE_FAILED:
      return {
        ...state,
        leadUpdateFetching: false,
        leadUpdateError: true,
        leadUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case LEAD_UPDATE_SUCCEEDED:
      return {
        ...state,
        leadDetail: action.payload,
        leadUpdateFetching: false,
        leadUpdateError: false,
        leadUpdateSuccess: true,
      };

    case LEAD_GET_REQUESTED:
      return {
        ...state,
        leadGetFetching: true,
        leadGetError: false,
      };
    case LEAD_GET_FAILED:
      return {
        ...state,
        leadGetFetching: false,
        leadGetError: true,
      };
    case LEAD_GET_SUCCEEDED:
      return {
        ...state,
        leadDetail: action.payload,
        leadGetFetching: false,
        leadGetError: false,
      };

    case LEAD_LOG_GET_REQUESTED:
      return {
        ...state,
        leadLogGetFetching: true,
        leadLogGetError: false,
      };
    case LEAD_LOG_GET_FAILED:
      return {
        ...state,
        leadLogGetFetching: false,
        leadLogGetError: true,
      };
    case LEAD_LOG_GET_SUCCEEDED:
      return {
        ...state,
        leadLog: action.payload,
        leadLogGetFetching: false,
        leadLogGetError: false,
      };

    case LEAD_GET_ALL_REQUESTED:
      return {
        ...state,
        leadGetAllFetching: true,
        leadGetAllError: false,
        leadGetAllSuccess: false,
      };
    case LEAD_GET_ALL_FAILED:
      return {
        ...state,
        leadGetAllFetching: false,
        leadGetAllError: true,
        leadGetAllSuccess: false,
      };
    case LEAD_GET_ALL_SUCCEEDED:
      return {
        ...state,
        leads: action.payload,
        leadGetAllFetching: false,
        leadGetAllError: false,
        leadGetAllSuccess: true,
      };
    case LEAD_SELF_ASSIGNED_SUCCEEDED:
      return {
        ...state,
        selfAssignedLead: action.payload,
        leadGetAllFetching: false,
        leadGetAllError: false,
        leadGetAllSuccess: true,
      };
    default:
      return state;
  }
}
