import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "components/CustomButtons/Button.js";

import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/checkInPageStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import { COMPANY_NAME } from "../../scripts/const";

const { BRANCH_NAME } = require("../../scripts/const");
const useStyles = makeStyles(styles);

export default function InitialCheckin({ handleClick }) {
  const classes = useStyles();

  return (
    <>
      <GridContainer className={classes.checkInContainer}>
        <GridItem xs={12} sm={12}>
          <div className={classes.titleContainer}>
            <h1 className={classes.title}>
              Welcome to {COMPANY_NAME}, {BRANCH_NAME}
            </h1>
            <h3>Please touch below to Check-In</h3>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Button
            onClick={() => handleClick("new")}
            className={classes.checkinButton}
            color="info"
          >
            New Customer
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Button
            onClick={() => handleClick("returning")}
            className={classes.checkinButton}
            color="info"
          >
            Returning Customer
          </Button>
        </GridItem>
      </GridContainer>
    </>
  );
}
