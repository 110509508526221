import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  LEAD_CREATE_REQUESTED,
  LEAD_CREATE_FAILED,
  LEAD_CREATE_SUCCEEDED,
  LEAD_GET_ALL_REQUESTED,
  LEAD_GET_ALL_FAILED,
  LEAD_GET_ALL_SUCCEEDED,
  LEAD_GET_REQUESTED,
  LEAD_GET_FAILED,
  LEAD_GET_SUCCEEDED,
  LEAD_UPDATE_REQUESTED,
  LEAD_UPDATE_FAILED,
  LEAD_UPDATE_SUCCEEDED,
  LEAD_LOG_GET_SUCCEEDED,
  LEAD_LOG_GET_FAILED,
  LEAD_LOG_GET_REQUESTED,
  LEAD_DELETE_SUCCEEDED,
  LEAD_DELETE_FAILED,
  LEAD_DELETE_REQUESTED,
  LEAD_SELF_ASSIGNED_SUCCEEDED,
  LEAD_SELF_ASSIGNED_REQUESTED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchLead(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    const lead = yield call(remoteResource.getLead, id, token);
    yield put({
      type: LEAD_GET_SUCCEEDED,
      payload: lead.data,
    });
  } catch (e) {
    yield put({ type: LEAD_GET_FAILED, payload: e.message });
  }
}

function* fetchLeadLog(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const lead = yield call(remoteResource.getLeadLog, id, token);
    yield put({
      type: LEAD_LOG_GET_SUCCEEDED,
      payload: lead.data,
    });
  } catch (e) {
    yield put({ type: LEAD_LOG_GET_FAILED, payload: e.message });
  }
}

function* fetchAllLeads(action) {
  try {
    const token = yield select(getToken);
    const leads = yield call(remoteResource.getAllLeads, token);
    yield put({
      type: LEAD_GET_ALL_SUCCEEDED,
      payload: leads.data,
    });
  } catch (e) {
    yield put({ type: LEAD_GET_ALL_FAILED, payload: e.message });
  }
}

function* fetchSelfAssignLeads(action) {
  try {
    const token = yield select(getToken);
    const leads = yield call(remoteResource.getAllLeads, token, action.payload);
    yield put({
      type: LEAD_SELF_ASSIGNED_SUCCEEDED,
      payload: leads.data,
    });
  } catch (e) {
    yield put({ type: LEAD_GET_ALL_FAILED, payload: e.message });
  }
}

function* createLead(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const lead = yield call(remoteResource.createLead, payload, token);
    yield put({
      type: LEAD_CREATE_SUCCEEDED,
      payload: lead.data,
    });
  } catch (e) {
    yield put({ type: LEAD_CREATE_FAILED, payload: e.response.data.message });
  }
}

function* updateLead(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const lead = yield call(remoteResource.updateLead, id, payload, token);
    yield put({
      type: LEAD_UPDATE_SUCCEEDED,
      payload: lead.data,
    });
  } catch (e) {
    yield put({ type: LEAD_UPDATE_FAILED, payload: e.response.data.message });
  }
}

function* deleteLead(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    yield call(remoteResource.deleteLead, id, token);
    yield put({
      type: LEAD_DELETE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: LEAD_DELETE_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchLead() {
  yield takeLatest(LEAD_GET_REQUESTED, fetchLead);
  yield takeLatest(LEAD_CREATE_REQUESTED, createLead);
  yield takeLatest(LEAD_UPDATE_REQUESTED, updateLead);
  yield takeLatest(LEAD_GET_ALL_REQUESTED, fetchAllLeads);
  yield takeLatest(LEAD_LOG_GET_REQUESTED, fetchLeadLog);
  yield takeLatest(LEAD_DELETE_REQUESTED, deleteLead);
  yield takeLatest(LEAD_SELF_ASSIGNED_REQUESTED, fetchSelfAssignLeads);
}
