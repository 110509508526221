import {
  SETTING_GET_REQUESTED,
  SETTING_GET_FAILED,
  SETTING_GET_SUCCEEDED,
  SETTING_UPDATE_REQUESTED,
  SETTING_UPDATE_FAILED,
  SETTING_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  settingUpdateError: false,
  settingUpdateFetching: false,
  settingUpdateSuccess: false,

  settingGetError: false,
  settingGetFetching: false,
  settingGetSuccess: false,
};

const defaultState = {
  settingDetail: {},
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case SETTING_UPDATE_REQUESTED:
      return {
        ...state,
        settingUpdateError: false,
        settingUpdateFetching: true,
        settingUpdateSuccess: false,
      };
    case SETTING_UPDATE_FAILED:
      return {
        ...state,
        settingUpdateError: true,
        settingUpdateFetching: false,
        settingUpdateSuccess: false,
      };
    case SETTING_UPDATE_SUCCEEDED:
      return {
        ...state,
        settingDetail: action.payload,
        settingUpdateError: false,
        settingUpdateFetching: false,
        settingUpdateSuccess: true,
      };

    case SETTING_GET_REQUESTED:
      return {
        ...state,
        settingGetError: false,
        settingGetFetching: true,
        settingGetSuccess: false,
      };
    case SETTING_GET_FAILED:
      return {
        ...state,
        settingGetError: true,
        settingGetFetching: false,
        settingGetSuccess: false,
      };
    case SETTING_GET_SUCCEEDED:
      return {
        ...state,
        settingDetail: action.payload,
        settingGetError: false,
        settingGetFetching: false,
        settingGetSuccess: true,
      };
    default:
      return state;
  }
}
