export const DEFAULT_WEBCAM_RESOLUTION = {
  label: "640x480",
  width: 640,
  height: 480,
};

export const webcamResolutionType = [
  {
    label: "300x250",
    width: 300,
    height: 250,
  },
  {
    label: "500x350",
    width: 500,
    height: 350,
  },
  {
    label: "640x480",
    width: 640,
    height: 480,
  },
  {
    label: "960x720",
    width: 960,
    height: 720,
  },
  {
    label: "1024x768",
    width: 1024,
    height: 768,
  },
  {
    label: "1280x960",
    width: 1280,
    height: 960,
  },
];
