import React, { useEffect } from "react";

import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";

import {
  CLEAR_ERROR,
  SOURCE_CREATE_REQUESTED,
  SOURCE_GET_ALL_REQUESTED,
  SOURCE_UPDATE_REQUESTED,
} from "../../../../redux/actions";
import ReactGA from "react-ga4";

export default function Source(props) {
  const columns = [
    { width: 50, title: "ID", field: "id", editable: "never", type: "numeric" },
    {
      title: "Name",
      field: "name",
      validate: (rowData) => Boolean(rowData.name),
    },
    { title: "Description", field: "description" },
    { title: "CreatedAt", field: "createdAt", editable: "never" },
    { title: "UpdatedAt", field: "updatedAt", editable: "never" },
  ];

  const [data, setData] = React.useState([]);

  const sources = useSelector((state) => state.source.sources);
  const saveSuccess = useSelector((state) => state.source.sourceCreateSuccess);
  const updateSuccess = useSelector(
    (state) => state.source.sourceUpdateSuccess
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    setData(sources);
  }, [sources]);

  useEffect(() => {
    if (saveSuccess === true) {
      dispatch({ type: SOURCE_GET_ALL_REQUESTED });
      dispatch({ type: CLEAR_ERROR });
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (updateSuccess === true) {
      dispatch({ type: SOURCE_GET_ALL_REQUESTED });
      dispatch({ type: CLEAR_ERROR });
    }
  }, [updateSuccess]);

  return (
    <MaterialTable
      title="Source Setup"
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        pageSize: 25,
        pageSizeOptions: [25, 50, 100],
        headerStyle: {
          backgroundColor: "#0077c2",
          color: "#FFF",
        },
      }}
      style={{
        marginTop: "30px",
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              dispatch({ type: SOURCE_CREATE_REQUESTED, payload: newData });
              ReactGA.event({
                category: "Source",
                action: "Source Added",
              });
            }, 200);
          }),
        onRowUpdate: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              dispatch({
                type: SOURCE_UPDATE_REQUESTED,
                payload: { payload: newData, id: newData.id },
              });
              ReactGA.event({
                category: "Source",
                action: "Source Updated",
              });
            }, 200);
          }),
      }}
    />
  );
}
