import React from "react";

const countryOption = () => {
  return (
    <>
      <option value=""></option>
      <option value="AFG">AFGHANISTAN - AFG</option>
      <option value="ALB">ALBANIA - ALB</option>
      <option value="DZA">ALGERIA - DZA</option>
      <option value="ASM">AMERICAN SAMOA - ASM</option>
      <option value="AND">ANDORRA - AND</option>
      <option value="AGO">ANGOLA - AGO</option>
      <option value="AIA">ANGUILLA - AIA</option>
      <option value="ATG">ANTIGUA AND BARBUDA - ATG</option>
      <option value="ARG">ARGENTINA - ARG</option>
      <option value="ARM">ARMENIA - ARM</option>
      <option value="ABW">ARUBA - ABW</option>
      <option value="AUS">AUSTRALIA - AUS</option>
      <option value="AUT">AUSTRIA - AUT</option>
      <option value="AZE">AZERBAIJAN - AZE</option>
      <option value="BHS">BAHAMAS - BHS</option>
      <option value="BHR">BAHRAIN - BHR</option>
      <option value="BGD">BANGLADESH - BGD</option>
      <option value="BRB">BARBADOS - BRB</option>
      <option value="BLR">BELARUS - BLR</option>
      <option value="BEL">BELGIUM - BEL</option>
      <option value="BLZ">BELIZE - BLZ</option>
      <option value="BEN">BENIN - BEN</option>
      <option value="BMU">BERMUDA - BMU</option>
      <option value="BTN">BHUTAN - BTN</option>
      <option value="BOL">BOLIVIA - BOL</option>
      <option value="BES">BONAIRE, SINT EUSTATIUS AND SABA - BES</option>
      <option value="BIH">BOSNIA AND HERZEGOVINA - BIH</option>
      <option value="BWA">BOTSWANA - BWA</option>
      <option value="BRA">BRAZIL - BRA</option>
      <option value="BRN">BRUNEI DARUSSALAM - BRN</option>
      <option value="BGR">BULGARIA - BGR</option>
      <option value="BFA">BURKINA FASO - BFA</option>
      <option value="MMR">MYANMAR - MMR</option>
      <option value="BDI">BURUNDI - BDI</option>
      <option value="CPV">CABO VERDE - CPV</option>
      <option value="KHM">CAMBODIA - KHM</option>
      <option value="CMR">CAMEROON - CMR</option>
      <option value="CAN">CANADA - CAN</option>
      <option value="CYM">CAYMAN ISLANDS - CYM</option>
      <option value="CAF">CENTRAL AFRICAN REPUBLIC - CAF</option>
      <option value="TCD">CHAD - TCD</option>
      <option value="CHL">CHILE - CHL</option>
      <option value="CHN">CHINA - CHN</option>
      <option value="COL">COLOMBIA - COL</option>
      <option value="COM">COMOROS - COM</option>
      <option value="COG">CONGO - COG</option>
      <option value="COD">CONGO, DEMOCRATIC REPUBLIC OF THE - COD</option>
      <option value="COK">COOK ISLANDS - COK</option>
      <option value="CRI">COSTA RICA - CRI</option>
      <option value="CIV">COTE D'IVOIRE - CIV</option>
      <option value="HRV">CROATIA - HRV</option>
      <option value="CUB">CUBA - CUB</option>
      <option value="CUW">CURACAO - CUW</option>
      <option value="CYP">CYPRUS - CYP</option>
      <option value="CZE">CZECHIA (CZECH REP) - CZE</option>
      <option value="DNK">DENMARK - DNK</option>
      <option value="DJI">DJIBOUTI - DJI</option>
      <option value="DMA">DOMINICA - DMA</option>
      <option value="DOM">DOMINICAN REPUBLIC - DOM</option>
      <option value="TMP">EAST TIMOR - TMP</option>
      <option value="ECU">ECUADOR - ECU</option>
      <option value="EGY">EGYPT - EGY</option>
      <option value="SLV">EL SALVADOR - SLV</option>
      <option value="GNQ">EQUATORIAL GUINEA - GNQ</option>
      <option value="ERI">ERITREA - ERI</option>
      <option value="EST">ESTONIA - EST</option>
      <option value="SWZ">ESWATINI - SWZ</option>
      <option value="ETH">ETHIOPIA - ETH</option>
      <option value="FLK">FALKLAND ISLANDS (MALVINAS) - FLK</option>
      <option value="FJI">FIJI - FJI</option>
      <option value="FIN">FINLAND - FIN</option>
      <option value="FRA">FRANCE - FRA</option>
      <option value="GUF">FRENCH GUIANA - GUF</option>
      <option value="PYF">FRENCH POLYNESIA - PYF</option>
      <option value="GAB">GABON - GAB</option>
      <option value="GMB">GAMBIA - GMB</option>
      <option value="GEO">GEORGIA - GEO</option>
      <option value="D">GERMANY - D</option>
      <option value="GHA">GHANA - GHA</option>
      <option value="GIB">GIBRALTAR - GIB</option>
      <option value="GRC">GREECE - GRC</option>
      <option value="GRL">GREENLAND - GRL</option>
      <option value="GRD">GRENADA - GRD</option>
      <option value="GLP">GUADELOUPE - GLP</option>
      <option value="GUM">GUAM - GUM</option>
      <option value="GTM">GUATEMALA - GTM</option>
      <option value="GIN">GUINEA - GIN</option>
      <option value="GNB">GUINEA-BISSAU - GNB</option>
      <option value="GUY">GUYANA - GUY</option>
      <option value="HTI">HAITI - HTI</option>
      <option value="HND">HONDURAS - HND</option>
      <option value="HKG">HONG KONG SAR - HKG</option>
      <option value="HUN">HUNGARY - HUN</option>
      <option value="ISL">ICELAND - ISL</option>
      <option value="IND">INDIA - IND</option>
      <option value="IDN">INDONESIA - IDN</option>
      <option value="IRN">IRAN, ISLAMIC REPUBLIC OF - IRN</option>
      <option value="IRQ">IRAQ - IRQ</option>
      <option value="IRL">IRELAND - IRL</option>
      <option value="ISR">ISRAEL - ISR</option>
      <option value="ITA">ITALY - ITA</option>
      <option value="JAM">JAMAICA - JAM</option>
      <option value="JPN">JAPAN - JPN</option>
      <option value="JOR">JORDAN - JOR</option>
      <option value="KAZ">KAZAKHSTAN - KAZ</option>
      <option value="KEN">KENYA - KEN</option>
      <option value="KIR">KIRIBATI - KIR</option>
      <option value="PRK">
        KOREA, DEMOCRATIC PEOPLES REPUBLIC OF (NORTH) - PRK
      </option>
      <option value="KOR">KOREA, REPUBLIC OF (SOUTH) - KOR</option>
      <option value="RKS">KOSOVO - RKS</option>
      <option value="KWT">KUWAIT - KWT</option>
      <option value="KGZ">KYRGYZSTAN - KGZ</option>
      <option value="LAO">LAO PEOPLE'S DEMOCRATIC REPUBLIC - LAO</option>
      <option value="LVA">LATVIA - LVA</option>
      <option value="LBN">LEBANON - LBN</option>
      <option value="LSO">LESOTHO - LSO</option>
      <option value="LBR">LIBERIA - LBR</option>
      <option value="LBY">LIBYA - LBY</option>
      <option value="LIE">LIECHTENSTEIN - LIE</option>
      <option value="LTU">LITHUANIA - LTU</option>
      <option value="LUX">LUXEMBOURG - LUX</option>
      <option value="MAC">MACAU SAR - MAC</option>
      <option value="MDG">MADAGASCAR - MDG</option>
      <option value="MWI">MALAWI - MWI</option>
      <option value="MYS">MALAYSIA - MYS</option>
      <option value="MDV">MALDIVES - MDV</option>
      <option value="MLI">MALI - MLI</option>
      <option value="MLT">MALTA - MLT</option>
      <option value="MHL">MARSHALL ISLANDS - MHL</option>
      <option value="MTQ">MARTINIQUE - MTQ</option>
      <option value="MRT">MAURITANIA - MRT</option>
      <option value="MUS">MAURITIUS - MUS</option>
      <option value="MEX">MEXICO - MEX</option>
      <option value="FSM">MICRONESIA, FEDERATED STATES OF - FSM</option>
      <option value="MDA">MOLDOVA, REPUBLIC OF - MDA</option>
      <option value="MCO">MONACO - MCO</option>
      <option value="MNG">MONGOLIA - MNG</option>
      <option value="MNE">MONTENEGRO - MNE</option>
      <option value="MSR">MONTSERRAT - MSR</option>
      <option value="MAR">MOROCCO - MAR</option>
      <option value="MOZ">MOZAMBIQUE - MOZ</option>
      <option value="NAM">NAMIBIA - NAM</option>
      <option value="NRU">NAURU - NRU</option>
      <option value="NPL">NEPAL - NPL</option>
      <option value="NLD">NETHERLANDS - NLD</option>
      <option value="ANT">NETHERLANDS ANTILLES - ANT</option>
      <option value="NCL">NEW CALEDONIA - NCL</option>
      <option value="NZL">NEW ZEALAND - NZL</option>
      <option value="NIC">NICARAGUA - NIC</option>
      <option value="NER">NIGER - NER</option>
      <option value="NGA">NIGERIA - NGA</option>
      <option value="NIU">NIUE - NIU</option>
      <option value="MKD">NORTH MACEDONIA - MKD</option>
      <option value="NOR">NORWAY - NOR</option>
      <option value="OMN">OMAN - OMN</option>
      <option value="PAK">PAKISTAN - PAK</option>
      <option value="PLW">PALAU - PLW</option>
      <option value="PSE">PALESTINIAN AUTHORITY - PSE</option>
      <option value="PAN">PANAMA - PAN</option>
      <option value="PNG">PAPUA NEW GUINEA - PNG</option>
      <option value="PRY">PARAGUAY - PRY</option>
      <option value="PER">PERU - PER</option>
      <option value="PHL">PHILIPPINES - PHL</option>
      <option value="PCN">PITCAIRN - PCN</option>
      <option value="POL">POLAND - POL</option>
      <option value="PRT">PORTUGAL - PRT</option>
      <option value="PRI">PUERTO RICO - PRI</option>
      <option value="QAT">QATAR - QAT</option>
      <option value="REU">REUNION - REU</option>
      <option value="ROU">ROMANIA - ROU</option>
      <option value="ROM">ROMANIA PRE 1/2/2002 - ROM</option>
      <option value="RUS">RUSSIAN FEDERATION - RUS</option>
      <option value="RWA">RWANDA - RWA</option>
      <option value="SHN">
        SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA - SHN
      </option>
      <option value="KNA">SAINT KITTS AND NEVIS - KNA</option>
      <option value="LCA">SAINT LUCIA - LCA</option>
      <option value="VCT">SAINT VINCENT AND THE GRENADINES - VCT</option>
      <option value="WSM">SAMOA - WSM</option>
      <option value="SMR">SAN MARINO - SMR</option>
      <option value="STP">SAO TOME AND PRINCIPE - STP</option>
      <option value="SAU">SAUDI ARABIA - SAU</option>
      <option value="SEN">SENEGAL - SEN</option>
      <option value="SRB">SERBIA - SRB</option>
      <option value="SCG">SERBIA AND MONTENEGRO - SCG</option>
      <option value="SYC">SEYCHELLES - SYC</option>
      <option value="SLE">SIERRA LEONE - SLE</option>
      <option value="SGP">SINGAPORE - SGP</option>
      <option value="SXM">SINT MAARTEN (DUTCH PART) - SXM</option>
      <option value="SVK">SLOVAK REPUBLIC - SVK</option>
      <option value="SVN">SLOVENIA - SVN</option>
      <option value="SLB">SOLOMON ISLANDS - SLB</option>
      <option value="SOM">SOMALIA - SOM</option>
      <option value="ZAF">SOUTH AFRICA - ZAF</option>
      <option value="SSD">SOUTH SUDAN - SSD</option>
      <option value="ESP">SPAIN - ESP</option>
      <option value="LKA">SRI LANKA - LKA</option>
      <option value="SDN">SUDAN - SDN</option>
      <option value="SUR">SURINAME - SUR</option>
      <option value="SWE">SWEDEN - SWE</option>
      <option value="CHE">SWITZERLAND - CHE</option>
      <option value="SYR">SYRIAN ARAB REPUBLIC - SYR</option>
      <option value="TWN">TAIWAN - TWN</option>
      <option value="TJK">TAJIKISTAN - TJK</option>
      <option value="TZA">TANZANIA, UNITED REPUBLIC OF - TZA</option>
      <option value="THA">THAILAND - THA</option>
      <option value="TLS">TIMOR-LESTE - TLS</option>
      <option value="TGO">TOGO - TGO</option>
      <option value="TKL">TOKELAU - TKL</option>
      <option value="TON">TONGA - TON</option>
      <option value="TTO">TRINIDAD AND TOBAGO - TTO</option>
      <option value="TUN">TUNISIA - TUN</option>
      <option value="TUR">TURKIYE - TUR</option>
      <option value="TKM">TURKMENISTAN - TKM</option>
      <option value="TCA">TURKS AND CAICOS ISLANDS - TCA</option>
      <option value="TUV">TUVALU - TUV</option>
      <option value="UGA">UGANDA - UGA</option>
      <option value="UKR">UKRAINE - UKR</option>
      <option value="ARE">UNITED ARAB EMIRATES - ARE</option>
      <option value="GBR">UNITED KINGDOM - BRITISH CITIZEN - GBR</option>
      <option value="GBN">
        UNITED KINGDOM - BRITISH NATIONAL (OVERSEAS) - GBN
      </option>
      <option value="GBO">
        UNITED KINGDOM - BRITISH OVERSEAS CITIZEN - GBO
      </option>
      <option value="GBD">
        UNITED KINGDOM - BRITISH OVERSEAS TERRITORIES CITIZEN - GBD
      </option>
      <option value="GBS">UNITED KINGDOM - BRITISH SUBJECT - GBS</option>
      <option value="USA">UNITED STATES - USA</option>
      <option value="URY">URUGUAY - URY</option>
      <option value="UZB">UZBEKISTAN - UZB</option>
      <option value="VUT">VANUATU - VUT</option>
      <option value="VAT">VATICAN CITY STATE (HOLY SEE) - VAT</option>
      <option value="VEN">VENEZUELA - VEN</option>
      <option value="VNM">VIET NAM - VNM</option>
      <option value="VGB">VIRGIN ISLANDS (BRITISH) - VGB</option>
      <option value="YEM">YEMEN, REPUBLIC OF - YEM</option>
      <option value="YUG">YUGOSLAVIA - YUG</option>
      <option value="ZAR">ZAIRE - ZAR</option>
      <option value="ZMB">ZAMBIA - ZMB</option>
      <option value="ZWE">ZIMBABWE - ZWE</option>
    </>
  );
};

const countryOptionWithFourLetter = () => {
  return (
    <>
      <option value=""></option>
      <option value="AFGH">AFGHANISTAN</option>
      <option value="ALBA">ALBANIA</option>
      <option value="ALGE">ALGERIA</option>
      <option value="ASAM">AMERICAN SAMOA</option>
      <option value="ANDO">ANDORRA</option>
      <option value="ANGA">ANGOLA</option>
      <option value="ANGU">ANGUILLA</option>
      <option value="ANBA">ANTIGUA AND BARBUDA</option>
      <option value="ARGE">ARGENTINA</option>
      <option value="ARME">ARMENIA</option>
      <option value="ARUB">ARUBA</option>
      <option value="A">AUSTRALIA</option>
      <option value="AUST">AUSTRIA</option>
      <option value="AZER">AZERBAIJAN</option>
      <option value="BHMS">BAHAMAS</option>
      <option value="BAHR">BAHRAIN</option>
      <option value="BDES">BANGLADESH</option>
      <option value="BARS">BARBADOS</option>
      <option value="BYEL">BELARUS</option>
      <option value="BELM">BELGIUM</option>
      <option value="BELZ">BELIZE</option>
      <option value="BENI">BENIN</option>
      <option value="BERM">BERMUDA</option>
      <option value="BHUT">BHUTAN</option>
      <option value="BOLI">BOLIVIA</option>
      <option value="BSES">BONAIRE, SINT EUSTATIUS AND SABA</option>
      <option value="BOHE">BOSNIA &amp; HERZEGOVINA</option>
      <option value="BOTS">BOTSWANA</option>
      <option value="BVTI">BOUVET ISLAND</option>
      <option value="BRAZ">BRAZIL</option>
      <option value="BDTC">BRIT DEPEND TERR CIT</option>
      <option value="BIOT">BRIT INDIAN OCN TERR</option>
      <option value="BWIN">BRITISH WEST INDIES</option>
      <option value="BRUI">BRUNEI DARUSSALAM</option>
      <option value="BULG">BULGARIA</option>
      <option value="UVOL">BURKINA FASO</option>
      <option value="BURU">BURUNDI</option>
      <option value="CABV">CABO VERDE</option>
      <option value="CAMB">CAMBODIA</option>
      <option value="CREP">CAMEROON</option>
      <option value="CANA">CANADA</option>
      <option value="CVER">CAPE VERDE</option>
      <option value="CAIS">CAYMAN ISLANDS</option>
      <option value="CARE">CENTRAL AFRICAN REP</option>
      <option value="CHAD">CHAD</option>
      <option value="CLIS">CHANNEL ISLANDS</option>
      <option value="CHIL">CHILE</option>
      <option value="PRCH">CHINA</option>
      <option value="CSIS">CHRISTMAS ISLAND</option>
      <option value="COCO">COCOS (KEELING) ISL</option>
      <option value="COLA">COLOMBIA</option>
      <option value="COMO">COMOROS</option>
      <option value="CONG">CONGO</option>
      <option value="DRCO">CONGO, DEMOCRATIC REPUBLIC OF THE</option>
      <option value="COOI">COOK ISLANDS</option>
      <option value="CRIC">COSTA RICA</option>
      <option value="ICOA">COTE D'IVOIRE</option>
      <option value="CROA">CROATIA</option>
      <option value="CUBA">CUBA</option>
      <option value="CURA">CURACAO</option>
      <option value="CYPR">CYPRUS</option>
      <option value="CZER">CZECH REPUBLIC</option>
      <option value="CZEH">CZECHIA (CZECH REP)</option>
      <option value="CZEC">CZECHOSLOVAKIA</option>
      <option value="DENM">DENMARK</option>
      <option value="JIBU">DJIBOUTI</option>
      <option value="DOMI">DOMINICA</option>
      <option value="DREP">DOMINICAN REPUBLIC</option>
      <option value="ECUA">ECUADOR</option>
      <option value="EGYP">EGYPT</option>
      <option value="ESAL">EL SALVADOR</option>
      <option value="ENGL">ENGLAND</option>
      <option value="EQGN">EQUATORIAL GUINEA</option>
      <option value="ERIT">ERITREA</option>
      <option value="ESTO">ESTONIA</option>
      <option value="ESWA">ESWATINI</option>
      <option value="ETHI">ETHIOPIA</option>
      <option value="FALI">FALKLAND ISLANDS</option>
      <option value="FAIS">FAROE ISLANDS</option>
      <option value="FIJI">FIJI</option>
      <option value="FINL">FINLAND</option>
      <option value="FRAE">FRANCE</option>
      <option value="GUYE">FRENCH GUIANA</option>
      <option value="FPOL">FRENCH POLYNESIA</option>
      <option value="MKDA">FYR MACEDONIA</option>
      <option value="GABO">GABON</option>
      <option value="GAMB">GAMBIA</option>
      <option value="GEOG">GEORGIA</option>
      <option value="SGSI">GEORGIA/SANDWICH ISL</option>
      <option value="GDR">GERMAN DEM REPUBLIC</option>
      <option value="GERM">GERMANY</option>
      <option value="GHAN">GHANA</option>
      <option value="GIBR">GIBRALTAR</option>
      <option value="GREE">GREECE</option>
      <option value="GRED">GREENLAND</option>
      <option value="GREN">GRENADA</option>
      <option value="GUAE">GUADELOUPE</option>
      <option value="GUAM">GUAM</option>
      <option value="GUAT">GUATEMALA</option>
      <option value="GUER">GUERNSEY</option>
      <option value="GUIN">GUINEA</option>
      <option value="GUBS">GUINEA-BISSAU</option>
      <option value="GUYN">GUYANA</option>
      <option value="HAIT">HAITI</option>
      <option value="HMDI">HEARD &amp; MCDONALD ISL</option>
      <option value="HOND">HONDURAS</option>
      <option value="HKON">HONG KONG SAR</option>
      <option value="HUNG">HUNGARY</option>
      <option value="ICEL">ICELAND</option>
      <option value="INDI">INDIA</option>
      <option value="INDO">INDONESIA</option>
      <option value="IRAN">IRAN</option>
      <option value="IRAQ">IRAQ</option>
      <option value="IREP">IRELAND</option>
      <option value="IMAN">ISLE OF MAN</option>
      <option value="ISRA">ISRAEL</option>
      <option value="ITAL">ITALY</option>
      <option value="JAMA">JAMAICA</option>
      <option value="JAPA">JAPAN</option>
      <option value="JERS">JERSEY</option>
      <option value="JORD">JORDAN</option>
      <option value="KAZA">KAZAKHSTAN</option>
      <option value="KENY">KENYA</option>
      <option value="GIIS">KIRIBATI</option>
      <option value="KORE">KOREA (SO STATED)</option>
      <option value="NKOR">KOREA, NORTH</option>
      <option value="SKOR">KOREA, SOUTH</option>
      <option value="KOSO">KOSOVO</option>
      <option value="KUWA">KUWAIT</option>
      <option value="KIRG">KYRGYZSTAN</option>
      <option value="LAOS">LAO PEOPLES DEM REP</option>
      <option value="LATV">LATVIA</option>
      <option value="LEBA">LEBANON</option>
      <option value="LESO">LESOTHO</option>
      <option value="LIBE">LIBERIA</option>
      <option value="LIBA">LIBYA</option>
      <option value="LIBY">LIBYAN ARAB JAMAHIRI</option>
      <option value="LIEC">LIECHTENSTEIN</option>
      <option value="LITH">LITHUANIA</option>
      <option value="LUXE">LUXEMBOURG</option>
      <option value="MACS">MACAU SPCL ADMIN RGN</option>
      <option value="MADA">MADAGASCAR</option>
      <option value="MALW">MALAWI</option>
      <option value="MALS">MALAYSIA</option>
      <option value="MIS">MALDIVES</option>
      <option value="M">MALI</option>
      <option value="MALT">MALTA</option>
      <option value="MAIS">MARSHALL ISLANDS</option>
      <option value="MART">MARTINIQUE</option>
      <option value="MAUA">MAURITANIA</option>
      <option value="MAUS">MAURITIUS</option>
      <option value="MAYO">MAYOTTE</option>
      <option value="MEXI">MEXICO</option>
      <option value="MICS">MICRONESIA</option>
      <option value="MOLD">MOLDOVA</option>
      <option value="MONA">MONACO</option>
      <option value="MONG">MONGOLIA</option>
      <option value="MNTE">MONTENEGRO</option>
      <option value="MONT">MONTSERRAT</option>
      <option value="MORO">MOROCCO</option>
      <option value="MOZA">MOZAMBIQUE</option>
      <option value="MYAN">MYANMAR</option>
      <option value="NAMI">NAMIBIA</option>
      <option value="NAUR">NAURU</option>
      <option value="NEPA">NEPAL</option>
      <option value="NETH">NETHERLANDS</option>
      <option value="NANT">NETHERLANDS ANTILLES</option>
      <option value="NCAL">NEW CALEDONIA</option>
      <option value="NEWG">NEW GUINEA</option>
      <option value="NHEB">NEW HEBRIDES</option>
      <option value="NZEA">NEW ZEALAND</option>
      <option value="NICA">NICARAGUA</option>
      <option value="NIGR">NIGER</option>
      <option value="NGRA">NIGERIA</option>
      <option value="NIUE">NIUE</option>
      <option value="NOIS">NORFOLK ISLAND</option>
      <option value="NMAC">NORTH MACEDONIA</option>
      <option value="NOIR">NORTHERN IRELAND</option>
      <option value="NMAI">NORTHERN MARIANA ISL</option>
      <option value="NORW">NORWAY</option>
      <option value="OMAN">OMAN</option>
      <option value="PKSN">PAKISTAN</option>
      <option value="PALA">PALAU</option>
      <option value="PALE">PALESTINIAN AUTHORTY</option>
      <option value="PANA">PANAMA</option>
      <option value="NGUI">PAPUA NEW GUINEA</option>
      <option value="PARA">PARAGUAY</option>
      <option value="PERU">PERU</option>
      <option value="PHIL">PHILIPPINES</option>
      <option value="PIIS">PITCAIRN</option>
      <option value="PLIS">PLEASANT ISLAND</option>
      <option value="POLA">POLAND</option>
      <option value="PORL">PORTUGAL</option>
      <option value="PRIC">PUERTO RICO</option>
      <option value="QATA">QATAR</option>
      <option value="REIS">REUNION</option>
      <option value="ROUM">ROMANIA</option>
      <option value="ROMN">ROMANIA PRE 1/2/2002</option>
      <option value="RFED">RUSSIAN FEDERATION</option>
      <option value="RWAN">RWANDA</option>
      <option value="STBA">SAINT BARTHELEMY</option>
      <option value="SHTC">SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA</option>
      <option value="STLU">SAINT LUCIA</option>
      <option value="STMA">SAINT MARTIN</option>
      <option value="SAMO">SAMOA</option>
      <option value="SMAR">SAN MARINO</option>
      <option value="STPR">SAO TOME &amp; PRINCIPE</option>
      <option value="SAAR">SAUDI ARABIA</option>
      <option value="SCOT">SCOTLAND</option>
      <option value="SENE">SENEGAL</option>
      <option value="SERB">SERBIA</option>
      <option value="SEMO">SERBIA AND MONTENEGRO</option>
      <option value="SEYC">SEYCHELLES</option>
      <option value="SLEO">SIERRA LEONE</option>
      <option value="SING">SINGAPORE</option>
      <option value="SXMN">SINT MAARTEN (DUTCH PART)</option>
      <option value="SVKA">SLOVAK REPUBLIC</option>
      <option value="SLOV">SLOVENIA</option>
      <option value="SOLI">SOLOMON ISLANDS</option>
      <option value="SOMA">SOMALIA</option>
      <option value="SAFR">SOUTH AFRICA</option>
      <option value="SSDN">SOUTH SUDAN</option>
      <option value="SPAI">SPAIN</option>
      <option value="SRIL">SRI LANKA</option>
      <option value="SHEL">ST HELENA</option>
      <option value="SCKN">ST KITTS AND NEVIS</option>
      <option value="STPM">ST PIERRE &amp; MIQUELON</option>
      <option value="STVG">ST VINCENT/GRENADINE</option>
      <option value="SUDA">SUDAN</option>
      <option value="SURI">SURINAME</option>
      <option value="SAJM">SVALBARD &amp; JAN MAYEN</option>
      <option value="SWAZ">SWAZILAND</option>
      <option value="SWED">SWEDEN</option>
      <option value="SWIT">SWITZERLAND</option>
      <option value="SYRI">SYRIA</option>
      <option value="TAHI">TAHITI</option>
      <option value="TAIW">TAIWAN</option>
      <option value="TADZ">TAJIKISTAN</option>
      <option value="TANZ">TANZANIA</option>
      <option value="THAI">THAILAND</option>
      <option value="TIBE">TIBET (SO STATED)</option>
      <option value="ETIM">TIMOR, EAST</option>
      <option value="TILE">TIMOR-LESTE</option>
      <option value="TOGO">TOGO</option>
      <option value="TOKE">TOKELAU</option>
      <option value="TONG">TONGA</option>
      <option value="TRIN">TRINIDAD AND TOBAGO</option>
      <option value="TUNI">TUNISIA</option>
      <option value="TURE">TURKIYE</option>
      <option value="TURM">TURKMENISTAN</option>
      <option value="TCIS">TURKS AND CAICOS ISL</option>
      <option value="TUVU">TUVALU</option>
      <option value="USOI">U.S. MINOR ISLANDS</option>
      <option value="USSR">U.S.S.R.</option>
      <option value="UGAN">UGANDA</option>
      <option value="UKRA">UKRAINE</option>
      <option value="UAEM">UNITED ARAB EMIRATES</option>
      <option value="UK">UNITED KINGDOM</option>
      <option value="USA">UNITED STATES</option>
      <option value="URUG">URUGUAY</option>
      <option value="UZBE">UZBEKISTAN</option>
      <option value="VANU">VANUATU</option>
      <option value="VCIT">VATICAN CITY STATE</option>
      <option value="VENE">VENEZUELA</option>
      <option value="VIET">VIETNAM</option>
      <option value="VIIS">VIRGIN ISLANDS (U.S.)</option>
      <option value="VIUK">VIRGIN ISLNDS (BRIT)</option>
      <option value="WALE">WALES</option>
      <option value="WAFU">WALLIS &amp; FUTUNA ISL</option>
      <option value="YEME">YEMEN</option>
      <option value="FRY">YUGOSLAVIA</option>
      <option value="ZAIR">ZAIRE</option>
      <option value="ZAMB">ZAMBIA</option>
      <option value="ZIMB">ZIMBABWE</option>
    </>
  );
};

export { countryOption, countryOptionWithFourLetter };
