import React, { useEffect } from "react";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";

import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
import { STEP_MAPPER, STUDENT_STEPS } from "variables/student.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  isValidStudentInputs,
  getDropDown,
  isDuplicateApplicant,
} from "../../../scripts/util";
import {
  CLEAR_ERROR,
  STUDENT_CREATE_REQUESTED,
  STUDENT_GET_ALL_REQUESTED,
} from "../../../redux/actions";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import ReactGA from "react-ga4";
import {
  DEFAULT_DATE_FORMAT,
  GENERAL_ROLE,
  SUPER_ROLE,
  TINY_INIT,
} from "../../../scripts/const";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import ConfirmationAlert from "../../../components/Alert/ConfirmationAlert";
import { Editor } from "@tinymce/tinymce-react";
import DropDownWithSearch from "../../../components/DropDownWithSearch/DropDownWithSearch";
import FileUpload from "../../../components/CustomUpload/FileUpload";

const useStyles = makeStyles(styles);

export default function AddStudent(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);
  const isSuperUser = SUPER_ROLE.includes(currentRoleId);

  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const submit = () => {
    confirmStudent();
  };
  const selectedEvent = () => {
    setAlert(<ConfirmationAlert cancel={hideAlert} submit={submit} />);
  };

  const [validationErrorMsg, setValidationErrorMsg] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [student, setStudent] = React.useState({});
  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );
  const errMsg = useSelector((state) => state.student.errorMsg);
  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);

  const handleFileUpload = (fileUrl) => {
    if (!student.files) {
      setStudent({
        ...student,
        files: [fileUrl],
      });
    } else {
      setStudent({
        ...student,
        files: [...student.files, fileUrl],
      });
    }
  };

  const handleFileDelete = (fileUrl) => {
    setStudent({
      ...student,
      files: student.files.filter((f) => f != fileUrl),
    });
  };
  const handleSelect = (e) => {
    setStudent({
      ...student,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditorChange = (content) => {
    setStudent({
      ...student,
      remarks: content,
    });
  };

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setStudent({
        ...student,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setStudent({
        ...student,
        [name]: date,
      });
    }
  };

  const dispatch = useDispatch();

  const saveError = useSelector((state) => state.student.studentCreateError);
  const saveProgress = useSelector(
    (state) => state.student.studentCreateFetching
  );
  const saveSuccess = useSelector(
    (state) => state.student.studentCreateSuccess
  );

  function createStudent() {
    const errors = isValidStudentInputs(student);
    if (errors.length > 0) {
      setValidationErrorMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else if (isDuplicateApplicant(student, props.allStudents)) {
      selectedEvent();
    } else {
      confirmStudent();
    }
  }

  function confirmStudent() {
    setValidationErrorMsg("");
    dispatch({ type: STUDENT_CREATE_REQUESTED, payload: student });
  }

  function clearErrors() {
    setValidationErrorMsg("");
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      clearErrors();
      // setStudent({});
    }
  }, [modalOpen]);

  useEffect(() => {
    setValidationErrorMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    setError(saveError);
  }, [saveError]);

  useEffect(() => {
    setSuccess(saveSuccess);
  }, [saveSuccess]);

  useEffect(() => {
    if (saveSuccess === true) {
      setTimeout(() => {
        props.handleSuccess(false);
      }, 700);
      dispatch({ type: STUDENT_GET_ALL_REQUESTED });
      setTimeout(() => {
        setStudent({});
        dispatch({ type: CLEAR_ERROR });
      }, 701);
    }
  }, [saveSuccess]);

  const classes = useStyles();

  const currentStep = STEP_MAPPER.lead;

  return (
    <div>
      <StepProgressBar
        startingStep={currentStep}
        steps={STUDENT_STEPS}
        buttonWrapperClass={classes.hidden}
      />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {validationErrorMsg && (
                    <SnackbarContent
                      message={validationErrorMsg}
                      color="warning"
                    />
                  )}
                  {error && (
                    <SnackbarContent message={errMessage} color="warning" />
                  )}
                  {saveProgress && <CustomLinearProgress color="primary" />}
                  {success && (
                    <SnackbarContent message="Added the user" color="success" />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email Address *"
                    id="student-add-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.email || "",
                      onChange: handleSelect,
                      name: "email",
                      type: "email",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="student-add-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="First Name *"
                    id="student-add-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Middle Name"
                    id="student-add-middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.middleName || "",
                      onChange: handleSelect,
                      name: "middleName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Last Name *"
                    id="student-add-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Date of Birth
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.dob || ""}
                      onChange={(moment) => handleDatePicker(moment, "dob")}
                      inputProps={{
                        name: "dob",
                        id: "add-student-dob-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Passport No"
                    id="student-add-passport"
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.passport,
                      onChange: handleSelect,
                      name: "passport",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Issue Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.issueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "issueDate")
                      }
                      inputProps={{
                        name: "issueDate",
                        id: "student-add-issueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Expiry Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.expiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "expiryDate")
                      }
                      inputProps={{
                        name: "expiryDate",
                        id: "student-add-expiryDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {props.allUniversities && (
                    <DropDownWithSearch
                      dbOptions={props.allUniversities}
                      selectedOption={student.universityId || ""}
                      setValue={setStudent}
                      currentState={student}
                      name={"universityId"}
                      label={"University"}
                    />
                  )}
                </GridItem>
                {student.universityId && (
                  <GridItem xs={12} sm={12} md={6}>
                    {props.allCourses && (
                      <DropDownWithSearch
                        dbOptions={props.allCourses.filter(
                          (c) => c.universityId === student.universityId
                        )}
                        selectedOption={student.courseId || ""}
                        setValue={setStudent}
                        currentState={student}
                        name={"courseId"}
                        label={"Course"}
                      />
                    )}
                  </GridItem>
                )}
              </GridContainer>
              <GridContainer spacing={5} className={classes.gridUp}>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Uni Start Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.startDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "startDate")
                      }
                      inputProps={{
                        name: "startDate",
                        id: "add-student-start-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Uni End Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      placeholder={"Uni End Date"}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.endDate || ""}
                      onChange={(moment) => handleDatePicker(moment, "endDate")}
                      inputProps={{
                        name: "endDate",
                        id: "add-student-end-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Fee Due Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.feeDueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "feeDueDate")
                      }
                      inputProps={{
                        name: "feeDueDate",
                        id: "student-add-feeDueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <br></br>
                  <br></br>
                </GridItem>
              </GridContainer>
              {isSuperUser && (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Fees Paid"
                        id="student-add-feesPaid"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          value: student.feesPaid || "",
                          onChange: handleSelect,
                          name: "feesPaid",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel className={classes.label}>
                        Fee Paid Date
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          closeOnSelect
                          timeFormat={false}
                          dateFormat={DEFAULT_DATE_FORMAT}
                          value={student.feesPaidDate || ""}
                          onChange={(moment) =>
                            handleDatePicker(moment, "feesPaidDate")
                          }
                          inputProps={{
                            name: "feesPaidDate",
                            id: "student-add-feesPaidDate",
                            placeholder: DEFAULT_DATE_FORMAT,
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Fees Due Amount"
                        id="student-add-fees-due-amount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          value: student.feesDueAmount || "",
                          onChange: handleSelect,
                          name: "feesDueAmount",
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Invoice Number"
                        id="student-add-invoice-no"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: student.invoiceNo || "",
                          onChange: handleSelect,
                          name: "invoiceNo",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel className={classes.label}>
                        Invoice Date
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          closeOnSelect
                          timeFormat={false}
                          dateFormat={DEFAULT_DATE_FORMAT}
                          value={student.invoiceDate || ""}
                          onChange={(moment) =>
                            handleDatePicker(moment, "invoiceDate")
                          }
                          inputProps={{
                            name: "invoiceDate",
                            id: "student-add-invoiceDate",
                            placeholder: DEFAULT_DATE_FORMAT,
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={4}
                      className={classes.flexCenter}
                    >
                      <DropDownWithSearch
                        optionsList={getDropDown("invoice-status")}
                        selectedOption={student.invoiceStatus || ""}
                        setValue={setStudent}
                        currentState={student}
                        name={"invoiceStatus"}
                        label={"Invoice Status"}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Commission Amount"
                        id="student-add-commission-amount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          value: student.commissionAmount || "",
                          onChange: handleSelect,
                          name: "commissionAmount",
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <br></br>
                    </GridItem>
                  </GridContainer>
                </>
              )}
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={
                    student.status &&
                    [
                      "Unconditional Offer",
                      "Follow Up",
                      "Coe Received",
                      "Canceled",
                      "Refused",
                    ].includes(student.status)
                      ? 4
                      : 6
                  }
                >
                  <DropDownWithSearch
                    optionsList={getDropDown("country")}
                    selectedOption={student.country || ""}
                    setValue={setStudent}
                    currentState={student}
                    name={"country"}
                    label={"Country"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={
                    student.status &&
                    [
                      "Unconditional Offer",
                      "Follow Up",
                      "Coe Received",
                      "Canceled",
                      "Refused",
                    ].includes(student.status)
                      ? 4
                      : 6
                  }
                >
                  <DropDownWithSearch
                    optionsList={getDropDown("student-status")}
                    selectedOption={student.status || ""}
                    setValue={setStudent}
                    currentState={student}
                    name={"status"}
                    label={"Status *"}
                  />
                </GridItem>
                {student.status &&
                  [
                    "Unconditional Offer",
                    "Follow Up",
                    "Coe Received",
                    "Canceled",
                    "Refused",
                  ].includes(student.status) && (
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel className={classes.label}>
                        {student.status} Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          closeOnSelect
                          timeFormat={false}
                          dateFormat={DEFAULT_DATE_FORMAT}
                          value={student.statusDate || ""}
                          onChange={(moment) =>
                            handleDatePicker(moment, "statusDate")
                          }
                          inputProps={{
                            name: "statusDate",
                            id: "add-student-statusDate",
                            placeholder: DEFAULT_DATE_FORMAT,
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  )}
              </GridContainer>
              <GridContainer className={classes.gridUp}>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("location")}
                    selectedOption={student.location || ""}
                    setValue={setStudent}
                    currentState={student}
                    name={"location"}
                    label={"Location"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.gridUp}>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allSources && (
                        <DropDownWithSearch
                          dbOptions={props.allSources}
                          selectedOption={student.sourceId || ""}
                          setValue={setStudent}
                          currentState={student}
                          name={"sourceId"}
                          label={"Source"}
                        />
                      )}
                    </GridItem>
                    <GridItem
                      className={classes.gridUp}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {props.allUsers && (
                        <DropDownWithSearch
                          dbOptions={props.allUsers}
                          dbLabel={"firstName"}
                          selectedOption={student.userId || ""}
                          setValue={setStudent}
                          currentState={student}
                          name={"userId"}
                          label={"Assigned To"}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.gridUp}>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={student.remarks || ""}
                    init={TINY_INIT}
                    onEditorChange={handleEditorChange}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem>
                  <div>
                    <h4>Documents:</h4>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      type={"student"}
                      setFileUrl={(fileUrl) => handleFileUpload(fileUrl)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {student.files &&
                  student.files.length > 0 &&
                  student.files.map((fileUrl, id) => {
                    return (
                      <GridItem key={id}>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            backgroundColor: "#B0BEC5",
                            padding: "20px",
                            borderRadius: "25px",
                            marginTop: "10px",
                          }}
                        >
                          <FileUpload
                            fileUrl={fileUrl}
                            key={id}
                            handleDelete={(fileUrl) => {
                              handleFileDelete(fileUrl);
                            }}
                          />
                        </div>
                      </GridItem>
                    );
                  })}
              </GridContainer>

              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={(e) => {
                  e.preventDefault();
                  ReactGA.event({
                    category: "Student",
                    action: "Added a Student",
                  });
                  createStudent();
                }}
              >
                Add
              </Button>
              {alert}
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
