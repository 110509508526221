import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  SETTING_GET_REQUESTED,
  SETTING_GET_FAILED,
  SETTING_GET_SUCCEEDED,
  SETTING_UPDATE_REQUESTED,
  SETTING_UPDATE_FAILED,
  SETTING_UPDATE_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchSetting(action) {
  try {
    const token = yield select(getToken);
    const courses = yield call(remoteResource.getSetting, token);
    yield put({
      type: SETTING_GET_SUCCEEDED,
      payload: courses.data,
    });
  } catch (e) {
    yield put({ type: SETTING_GET_FAILED, payload: e.message });
  }
}

function* updateSetting(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const course = yield call(remoteResource.updateSetting, payload, token);
    yield put({
      type: SETTING_UPDATE_SUCCEEDED,
      payload: course.data,
    });
  } catch (e) {
    yield put({ type: SETTING_UPDATE_FAILED, payload: e.message });
  }
}

export function* watchSetting() {
  yield takeLatest(SETTING_UPDATE_REQUESTED, updateSetting);
  yield takeLatest(SETTING_GET_REQUESTED, fetchSetting);
}
