import {
  VISA_CREATE_REQUESTED,
  VISA_CREATE_FAILED,
  VISA_CREATE_SUCCEEDED,
  VISA_DELETE_REQUESTED,
  VISA_DELETE_FAILED,
  VISA_DELETE_SUCCEEDED,
  VISA_GET_ALL_REQUESTED,
  VISA_GET_ALL_FAILED,
  VISA_GET_ALL_SUCCEEDED,
  VISA_GET_REQUESTED,
  VISA_GET_FAILED,
  VISA_GET_SUCCEEDED,
  VISA_LOG_GET_REQUESTED,
  VISA_LOG_GET_FAILED,
  VISA_LOG_GET_SUCCEEDED,
  VISA_UPDATE_REQUESTED,
  VISA_UPDATE_FAILED,
  VISA_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  visaCreateError: false,
  visaCreateFetching: false,
  visaCreateSuccess: false,

  visaDeleteError: false,
  visaDeleteFetching: false,
  visaDeleteSuccess: false,

  visaUpdateError: false,
  visaUpdateFetching: false,
  visaUpdateSuccess: false,

  visaGetError: false,
  visaGetFetching: false,
  visaGetSuccess: false,

  visaLogGetError: false,
  visaLogGetFetching: false,
  visaLogGetSuccess: false,

  visaGetAllError: false,
  visaGetAllSuccess: false,
  errorMsg: "",
};

const defaultState = {
  visas: [],
  visaLog: [],
  visaDetail: {},
  visaGetAllFetching: false,
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case VISA_CREATE_REQUESTED:
      return {
        ...state,
        visaCreateFetching: true,
        visaCreateError: false,
        visaCreateSuccess: false,
      };
    case VISA_CREATE_FAILED:
      return {
        ...state,
        visaCreateFetching: false,
        visaCreateError: true,
        visaCreateSuccess: false,
        errorMsg: action.payload,
      };
    case VISA_CREATE_SUCCEEDED:
      return {
        ...state,
        visaDetail: action.payload,
        visaCreateFetching: false,
        visaCreateError: false,
        visaCreateSuccess: true,
      };

    case VISA_DELETE_REQUESTED:
      return {
        ...state,
        visaDeleteFetching: true,
        visaDeleteError: false,
        visaDeleteSuccess: false,
      };
    case VISA_DELETE_FAILED:
      return {
        ...state,
        visaDeleteFetching: false,
        visaDeleteError: true,
        visaDeleteSuccess: false,
        errorMsg: action.payload,
      };
    case VISA_DELETE_SUCCEEDED:
      return {
        ...state,
        visaDeleteFetching: false,
        visaDeleteError: false,
        visaDeleteSuccess: true,
      };

    case VISA_UPDATE_REQUESTED:
      return {
        ...state,
        visaUpdateFetching: true,
        visaUpdateError: false,
        visaUpdateSuccess: false,
      };
    case VISA_UPDATE_FAILED:
      return {
        ...state,
        visaUpdateFetching: false,
        visaUpdateError: true,
        visaUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case VISA_UPDATE_SUCCEEDED:
      return {
        ...state,
        visaDetail: action.payload,
        visaUpdateFetching: false,
        visaUpdateError: false,
        visaUpdateSuccess: true,
      };

    case VISA_GET_REQUESTED:
      return {
        ...state,
        visaGetFetching: true,
        visaGetError: false,
      };
    case VISA_GET_FAILED:
      return {
        ...state,
        visaGetFetching: false,
        visaGetError: true,
      };
    case VISA_GET_SUCCEEDED:
      return {
        ...state,
        visaDetail: action.payload,
        visaGetFetching: false,
        visaGetError: false,
      };

  
    case VISA_GET_ALL_REQUESTED:
      return {
        ...state,
        visaGetAllFetching: true,
        visaGetAllError: false,
        visaGetAllSuccess: false,
      };
    case VISA_GET_ALL_FAILED:
      return {
        ...state,
        visaGetAllFetching: false,
        visaGetAllError: true,
        visaGetAllSuccess: false,
      };
    case VISA_GET_ALL_SUCCEEDED:
      return {
        ...state,
        visas: action.payload,
        visaGetAllFetching: false,
        visaGetAllError: false,
        visaGetAllSuccess: true,
      };
    default:
      return state;
  }
}
