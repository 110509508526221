import React, { useEffect, useState } from "react";
import moment from "moment";
// react component for creating dynamic tables
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import AddInsurance from "./AddInsurance";
import EditInsurance from "./EditInsurance";
import {
  getInsuranceProviderMapping,
  getInsuranceTypeMapping,
  filterCaseInsensitive,
  getColumns,
  isValidDate,
  reactTableHelper,
} from "../../../scripts/util";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { useDispatch, useSelector } from "react-redux";
import momentTZ from "moment-timezone";
import { GetApp } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import ReactGA from "react-ga4";
import withTimer from "../../../components/Timer/withTimer";
import SmsEmailAction from "../../../components/SmsEmailAction/SmsEmailAction";
import {
  INSURANCE_APPLICANT_TABLE_ACTION,
  SUPER_ROLE,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_WITH_MONTH,
  DEFAULT_TIME_ZONE,
  monthColor,
  GENERAL_ROLE,
} from "../../../scripts/const";
import Badge from "../../../components/Badge/Badge";
import history from "../../../history";
import DateFilter from "../../../components/DateFilter";
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { USER_UPDATE_REQUESTED } from "redux/actions";
import { USER_GET_REQUESTED } from "redux/actions";

const SelectTable = selectTableHOC(ReactTable);

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const InsuranceTables = ({
  match: {
    params: { id },
  },
}) => {
  const [addInsuranceModal, setAddInsuranceModal] = React.useState(false);
  const [editInsuranceModal, setEditInsuranceModal] = React.useState(false);
  const [currentInsuranceApplicant, setCurrentInsuranceApplicant] =
    React.useState({});
  const [data, setData] = React.useState([]);

  const insuranceProviders = getInsuranceProviderMapping();
  const insuranceTypes = getInsuranceTypeMapping();
  const insuranceApplicants = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicants
  );

  const insuranceApplicantsFetching = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantGetAllFetching
  );

  const [users, setUsers] = React.useState([]);
  const [sources, setSources] = React.useState([]);

  const dispatch = useDispatch();

  const allSources = useSelector((state) => state.source.sources);
  const allUsers = useSelector((state) => state.user.users);
  const userDetail = useSelector((state) => state.user.userDetail);

  const activeUsers = useSelector((state) => state.user.activeUsers);
  const currentUser = useSelector((state) => state.login.loginUser.detail);
  const isSelectTable = SUPER_ROLE.includes(currentUser.roleId);
  const isGeneralUser = GENERAL_ROLE.includes(currentUser.roleId);

  React.useEffect(() => {
    dispatch({
      type: USER_GET_REQUESTED,
      payload: currentUser.id,
    });
  }, []);

  const [selectedOptions, setSelectedOptions] = React.useState(
    userDetail?.hideColumn?.insuranceService || []
  );
  const [columnDataloaded, setColumnDataloaded] = useState(false);

  React.useEffect(() => {
    if (userDetail?.hideColumn?.insuranceService !== selectedOptions) {
      if (userDetail?.hideColumn?.insuranceService)
        setSelectedOptions(userDetail?.hideColumn?.insuranceService);
      setColumnDataloaded(true);
    }
  }, [userDetail?.hideColumn?.insuranceService]);

  React.useEffect(() => {
    setSources(allSources);
  }, [allSources]);
  React.useEffect(() => {
    setUsers(allUsers);
  }, [allUsers]);

  React.useEffect(() => {
    setData(insuranceApplicants);
    if (!isGeneralUser) {
      setFilterData(insuranceApplicants);
    } else {
      const filterData = insuranceApplicants.filter(
        (student) => student.userId === currentUser.id
      );
      setFilterData(filterData);
    }
  }, [insuranceApplicants]);

  useEffect(() => {
    if (id !== "undefined" && id > 0 && data.length > 0) {
      const found = data.find((d) => d.id == id);
      if (found) {
        setCurrentInsuranceApplicant(found);
        setEditInsuranceModal(true);
      }
    }
  }, [id, data]);

  const [filterData, setFilterData] = React.useState([]);
  const [fromDateState, setFromDateState] = React.useState("");
  const [toDateState, setToDateState] = React.useState("");

  const [dateRange, setDateRange] = React.useState({
    fromDate: "",
    toDate: "",
  });

  const [selection, setSelection] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);

  function isSelected(key) {
    return selection.includes(`select-${key}`);
  }
  function toggleAll() {
    const keyField = "id";
    const newSelectAll = !selectAll;
    const newSelection = [];

    if (newSelectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = reactTableRef.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        newSelection.push(`select-${item._original[keyField]}`);
      });
    }
    setSelection(newSelection);
    setSelectAll(newSelectAll);
  }
  function toggleSelection(key) {
    // start off with the existing state
    let newSelection = [...selection];
    const keyIndex = newSelection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      newSelection = [
        ...newSelection.slice(0, keyIndex),
        ...newSelection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      newSelection.push(key);
    }
    // update the state
    setSelection(newSelection);
  }
  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setDateRange({
        ...dateRange,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setDateRange({
        ...dateRange,
        [name]: date,
      });
    }
  };

  function filter() {
    let allValid = true;
    setToDateState("");
    setFromDateState("");

    if (dateRange.fromDate === "") {
      setFromDateState("error");
      allValid = false;
    }

    if (dateRange.toDate === "") {
      setToDateState("error");
      allValid = false;
      return;
    }

    if (!isValidDate(dateRange.fromDate)) {
      setFromDateState("error");
      allValid = false;
    }

    if (!isValidDate(dateRange.toDate)) {
      setToDateState("error");
      allValid = false;
    }

    if (allValid) {
      const fromDate = moment(dateRange.fromDate, DEFAULT_DATE_FORMAT);
      const toDate = moment(dateRange.toDate, DEFAULT_DATE_FORMAT);
      const filterData = data.filter(
        (e) => moment(e.createdAt) >= fromDate && moment(e.createdAt) <= toDate
      );
      setFilterData(filterData);
    }
  }

  function clear() {
    setToDateState("");
    setFromDateState("");
    setDateRange({
      fromDate: "",
      toDate: "",
    });
    setFilterData(data);
  }

  let reactTableRef = null;
  let csvLink = null;

  function handleDateRangeFilter(fromDate, toDate) {
    if (!fromDate || !toDate) {
      setFilterData(data);
    } else {
      const filterData = data.filter(
        (e) => moment(e.createdAt) >= fromDate && moment(e.createdAt) <= toDate
      );
      setFilterData(filterData);
    }
  }

  function download(event) {
    const columns = getColumns("Insurance");
    const currentRecords = isSelectTable
      ? reactTableRef.getWrappedInstance().getResolvedState().sortedData
      : reactTableRef.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] =
          currentRecords[index][columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    setDataToDownload(data_to_download);
  }

  const [dataToDownload, setDataToDownload] = React.useState([]);
  const [isDownload, setIsDownload] = React.useState(false);

  React.useEffect(() => {
    if (dataToDownload.length > 0) {
      setIsDownload(true);
    }
  }, [dataToDownload]);

  React.useEffect(() => {
    if (isDownload) {
      csvLink.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  const columns = [
    {
      sortable: false,
      Header: " ",
      show: !selectedOptions.includes(" "),
      accessor: "createdAt",
      Cell: (props) => {
        if (props.value) {
          return momentTZ(props.value).format("MMM YYYY");
        }
        return props.value;
      },
      getProps: (state, rowInfo) => {
        let color = null;
        if (rowInfo && rowInfo.row.createdAt) {
          let month = momentTZ(rowInfo.row.createdAt).format("MM");
          color = monthColor[month - 1];
        }
        return {
          style: {
            background: color,
          },
        };
      },
      filterMethod: (filter, row) => {
        return momentTZ(row[filter.id])
          .format("MMM YYYY")
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      },
      width: 70,
    },
    {
      Header: "ID",
      show: !selectedOptions.includes("ID"),
      accessor: "id",
      width: 70,
    },
    {
      Header: "First Name",
      show: !selectedOptions.includes("First Name"),
      accessor: "firstName",
      width: 150,
    },
    {
      Header: "Middle Name",
      show: !selectedOptions.includes("Middle Name"),
      accessor: "middleName",
      width: 150,
    },
    {
      Header: "Last Name",
      show: !selectedOptions.includes("Last Name"),
      accessor: "lastName",
      width: 150,
    },
    {
      Header: "Email",
      show: !selectedOptions.includes("Email"),
      accessor: "email",
    },
    {
      Header: "Phone",
      show: !selectedOptions.includes("Phone"),
      accessor: "phone",
    },
    {
      Header: "Country",
      show: !selectedOptions.includes("Country"),
      accessor: "country",
    },
    {
      Header: "Passport No.",
      show: !selectedOptions.includes("Passport No."),
      accessor: "passport",
      width: 150,
    },
    {
      Header: "Issue Date",
      show: !selectedOptions.includes("Issue Date"),
      accessor: "passportIssueDate",
      width: 150,
    },
    {
      Header: "Expiry Date",
      show: !selectedOptions.includes("Expiry Date"),
      accessor: "passportExpiryDate",
      width: 150,
    },
    {
      Header: "Type",
      show: !selectedOptions.includes("Type"),
      accessor: "insuranceTypeId",
      Cell: (props) => {
        if (props.value) {
          const type = insuranceTypes.find((e) => e.id === props.value);
          if (type) {
            return type.name;
          }
          return props.value;
        }
        return props.value;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "noType") {
          return row[filter.id] === "" || row[filter.id] === null;
        } else if (filter.value) {
          return row[filter.id] == filter.value;
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="noType">No Type</option>
          {insuranceTypes &&
            insuranceTypes.map((m, i) => (
              <option key={i} value={m.id}>
                {m.name}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Provider",
      show: !selectedOptions.includes("Provider"),
      accessor: "insuranceProviderId",
      Cell: (props) => {
        if (props.value) {
          const type = insuranceProviders.find((e) => e.id === props.value);
          if (type) {
            return type.name;
          }
          return props.value;
        }
        return props.value;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "noProvider") {
          return row[filter.id] === "" || row[filter.id] === null;
        } else if (filter.value) {
          return row[filter.id] == filter.value;
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="noProvider">No Provider</option>
          {insuranceProviders &&
            insuranceProviders.map((m, i) => (
              <option key={i} value={m.id}>
                {m.name}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "AssignedTo",
      show: !selectedOptions.includes("AssignedTo"),
      accessor: "userId",
      width: 150,

      Cell: (props) => {
        if (props.value && allUsers) {
          const user = allUsers.find((e) => e.id === props.value);
          if (user) {
            return user.firstName;
          } else {
            return props.value;
          }
        } else {
          return props.value;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "notAssigned") {
          return row[filter.id] === "" || row[filter.id] === null;
        } else if (filter.value) {
          return row[filter.id] == filter.value;
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => {
            if (isGeneralUser) {
              setFilterData(insuranceApplicants);

              if (filterData.length === 0) {
                setTimeout(() => {
                  onChange(event.target.value);
                }, 500);
              } else {
                onChange(event.target.value);
              }
            } else {
              onChange(event.target.value);
            }
          }}
          style={{ width: "100%" }}
          value={filter ? filter.value : isGeneralUser ? currentUser.id : "all"}
        >
          <option value="all">All</option>
          <option value="notAssigned">Not Assigned</option>
          {activeUsers &&
            activeUsers.map((m, i) => (
              <option key={i} value={m.id}>
                {m.firstName}
              </option>
            ))}
        </select>
      ),
    },
    {
      Header: "Expiry",
      show: !selectedOptions.includes("Expiry"),
      accessor: "expiryDate",
      Cell: (props) => {
        if (props.value) {
          return moment(props.value, DEFAULT_DATE_FORMAT).format(
            DEFAULT_DATE_FORMAT_WITH_MONTH
          );
        }
        return props.value;
      },
      sortMethod: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "priority") {
          if (row[filter.id]) {
            const now = momentTZ().tz(DEFAULT_TIME_ZONE);
            const expiry = moment(row[filter.id], DEFAULT_DATE_FORMAT);
            const dateDiff = expiry.diff(now, "days");
            return dateDiff <= 30 && dateDiff >= -1;
          }
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="priority">Need Attention</option>
        </select>
      ),
    },
    {
      headerStyle: { textAlign: "left" },
      Header: "Status",
      show: !selectedOptions.includes("Status"),
      accessor: "status",
      Cell: (props) => {
        if (props.value === "Completed") {
          return <Badge color={"success"}>{props.value}</Badge>;
        } else if (
          props.value === "Discontinued" ||
          props.value === "Refunded"
        ) {
          return <Badge color={"danger"}>{props.value}</Badge>;
        } else if (props.value) {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else {
          return <Badge color={"gray"}>{props.value}</Badge>;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "All") {
          return true;
        }
        if (filter.value === "New") {
          return row[filter.id] === "New";
        }
        if (filter.value === "Processing") {
          return row[filter.id] === "Processing";
        }
        if (filter.value === "Completed") {
          return row[filter.id] === "Completed";
        }
        if (filter.value === "Discontinued") {
          return row[filter.id] === "Discontinued";
        }
        if (filter.value === "Refunded") {
          return row[filter.id] === "Refunded";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "All"}
        >
          <option value="All">All</option>
          <option value="New">New</option>
          <option value="Processing">Processing</option>
          <option value="Completed">Completed</option>
          <option value="Discontinued">Discontinued</option>
          <option value="Refunded">Refunded</option>
        </select>
      ),
    },
  ];

  function getTrProps(state, rowInfo) {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          e.preventDefault();
          ReactGA.event({
            category: "InsuranceService",
            action: "View Insurance Applicant Profile",
          });
          setCurrentInsuranceApplicant(rowInfo.row._original);
          setEditInsuranceModal(true);
          history.push(`/admin/insurance/${rowInfo.row._original.id}`);
        },
      };
    } else {
      return {};
    }
  }
  const handleOptionChange = (e) => {
    const option = e.target.value[0];

    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  React.useEffect(() => {
    if (
      columnDataloaded &&
      selectedOptions !== userDetail?.hideColumn?.insuranceService
    ) {
      dispatch({
        type: USER_UPDATE_REQUESTED,
        payload: {
          payload: { insuranceServiceColumn: selectedOptions },
          id: currentUser.id,
        },
      });
    }
  }, [selectedOptions]);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <GridContainer>
              <GridItem xs={5}>
                <Button
                  onClick={() => {
                    setAddInsuranceModal(true);
                  }}
                  color="primary"
                >
                  Add Insurance Applicant
                </Button>
                <div style={{ display: "inline-block", marginLeft: "5px" }}>
                  <Button
                    title="Download CSV"
                    justIcon
                    color="info"
                    onClick={download}
                  >
                    <GetApp />
                  </Button>
                  {selection && selection.length > 0 && (
                    <SmsEmailAction
                      option={INSURANCE_APPLICANT_TABLE_ACTION}
                      data={filterData}
                      selection={selection}
                    />
                  )}
                </div>
                <CSVLink
                  data={dataToDownload}
                  filename="insurance-applicants.csv"
                  className="hidden"
                  ref={(r) => (csvLink = r)}
                  target="_blank"
                />
              </GridItem>
              <GridItem xs={7}>
                <DateFilter handleFilter={handleDateRangeFilter} />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <InputLabel id="column-select-label">Select Columns</InputLabel>
              <Select
                labelId="column-select-label"
                id="column-select"
                multiple
                value={[]}
                onChange={handleOptionChange}
                renderValue={(selected) => selected.join(", ")}
              >
                {columns.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data.Header}>
                      <Checkbox
                        checked={selectedOptions.includes(data.Header)}
                      />
                      <ListItemText primary={data.Header} />
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {isSelectTable && (
              <SelectTable
                keyField="id"
                toggleSelection={toggleSelection}
                selectAll={selectAll}
                selectType="checkbox"
                toggleAll={toggleAll}
                isSelected={isSelected}
                ref={(r) => (reactTableRef = r)}
                pageSizeOptions={[25, 50, 100]}
                data={filterData}
                filterable
                loading={insuranceApplicantsFetching}
                resizable={true}
                getTrProps={getTrProps}
                columns={columns}
                defaultPageSize={25}
                showPaginationBottom={true}
                className="-striped -highlight"
                defaultFilterMethod={filterCaseInsensitive}
              >
                {(state, makeTable, instance) => {
                  return reactTableHelper(state, makeTable, instance);
                }}
              </SelectTable>
            )}
            {!isSelectTable && (
              <ReactTable
                ref={(r) => (reactTableRef = r)}
                pageSizeOptions={[25, 50, 100]}
                data={filterData}
                filterable
                loading={insuranceApplicantsFetching}
                resizable={true}
                getTrProps={getTrProps}
                columns={columns}
                defaultPageSize={25}
                showPaginationBottom={true}
                className="-striped -highlight"
                defaultFilterMethod={filterCaseInsensitive}
              >
                {(state, makeTable, instance) => {
                  return reactTableHelper(state, makeTable, instance);
                }}
              </ReactTable>
            )}
          </CardBody>
        </Card>
      </GridItem>

      {/*Add Student*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={addInsuranceModal}
        disableScrollLock={true}
        TransitionComponent={Transition}
        onClose={() => setAddInsuranceModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        disableBackdropClick
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAddInsuranceModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>Add Insurance Applicant</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <AddInsurance
            allUsers={activeUsers}
            allSources={sources}
            open={addInsuranceModal}
            allInsuranceApplicants={insuranceApplicants}
            handleSuccess={setAddInsuranceModal}
          />
        </DialogContent>
      </Dialog>

      {/*Edit Insurance*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={editInsuranceModal}
        disableScrollLock={true}
        TransitionComponent={Transition}
        onClose={() => {
          setEditInsuranceModal(false);
          history.push("/admin/insurance");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        disableBackdropClick
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setEditInsuranceModal(false);
              history.push("/admin/insurance");
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>Edit Insurance Applicant</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <EditInsurance
            open={editInsuranceModal}
            allUsers={activeUsers}
            allSources={sources}
            allInsuranceApplicants={insuranceApplicants}
            insuranceApplicant={currentInsuranceApplicant}
            handleSuccess={setEditInsuranceModal}
          />
        </DialogContent>
      </Dialog>
    </GridContainer>
  );
};

export default withTimer(InsuranceTables);
