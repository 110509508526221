import {
  SKILL_ASSESSMENT_CREATE_REQUESTED,
  SKILL_ASSESSMENT_CREATE_FAILED,
  SKILL_ASSESSMENT_CREATE_SUCCEEDED,
  SKILL_ASSESSMENT_DELETE_REQUESTED,
  SKILL_ASSESSMENT_DELETE_FAILED,
  SKILL_ASSESSMENT_DELETE_SUCCEEDED,
  SKILL_ASSESSMENT_GET_ALL_REQUESTED,
  SKILL_ASSESSMENT_GET_ALL_FAILED,
  SKILL_ASSESSMENT_GET_ALL_SUCCEEDED,
  SKILL_ASSESSMENT_GET_REQUESTED,
  SKILL_ASSESSMENT_GET_FAILED,
  SKILL_ASSESSMENT_GET_SUCCEEDED,
  SKILL_ASSESSMENT_LOG_GET_REQUESTED,
  SKILL_ASSESSMENT_LOG_GET_FAILED,
  SKILL_ASSESSMENT_LOG_GET_SUCCEEDED,
  SKILL_ASSESSMENT_UPDATE_REQUESTED,
  SKILL_ASSESSMENT_UPDATE_FAILED,
  SKILL_ASSESSMENT_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  skillAssessmentCreateError: false,
  skillAssessmentCreateFetching: false,
  skillAssessmentCreateSuccess: false,

  skillAssessmentDeleteError: false,
  skillAssessmentDeleteFetching: false,
  skillAssessmentDeleteSuccess: false,

  skillAssessmentUpdateError: false,
  skillAssessmentUpdateFetching: false,
  skillAssessmentUpdateSuccess: false,

  skillAssessmentGetError: false,
  skillAssessmentGetFetching: false,
  skillAssessmentGetSuccess: false,

  skillAssessmentLogGetError: false,
  skillAssessmentLogGetFetching: false,
  skillAssessmentLogGetSuccess: false,

  skillAssessmentGetAllError: false,
  skillAssessmentGetAllSuccess: false,
  errorMsg: "",
};

const defaultState = {
  skillAssessments: [],
  skillAssessmentLog: [],
  skillAssessmentDetail: {},
  skillAssessmentGetAllFetching: false,
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case SKILL_ASSESSMENT_CREATE_REQUESTED:
      return {
        ...state,
        skillAssessmentCreateFetching: true,
        skillAssessmentCreateError: false,
        skillAssessmentCreateSuccess: false,
      };
    case SKILL_ASSESSMENT_CREATE_FAILED:
      return {
        ...state,
        skillAssessmentCreateFetching: false,
        skillAssessmentCreateError: true,
        skillAssessmentCreateSuccess: false,
        errorMsg: action.payload,
      };
    case SKILL_ASSESSMENT_CREATE_SUCCEEDED:
      return {
        ...state,
        skillAssessmentDetail: action.payload,
        skillAssessmentCreateFetching: false,
        skillAssessmentCreateError: false,
        skillAssessmentCreateSuccess: true,
      };

    case SKILL_ASSESSMENT_DELETE_REQUESTED:
      return {
        ...state,
        skillAssessmentDeleteFetching: true,
        skillAssessmentDeleteError: false,
        skillAssessmentDeleteSuccess: false,
      };
    case SKILL_ASSESSMENT_DELETE_FAILED:
      return {
        ...state,
        skillAssessmentDeleteFetching: false,
        skillAssessmentDeleteError: true,
        skillAssessmentDeleteSuccess: false,
        errorMsg: action.payload,
      };
    case SKILL_ASSESSMENT_DELETE_SUCCEEDED:
      return {
        ...state,
        skillAssessmentDeleteFetching: false,
        skillAssessmentDeleteError: false,
        skillAssessmentDeleteSuccess: true,
      };

    case SKILL_ASSESSMENT_UPDATE_REQUESTED:
      return {
        ...state,
        skillAssessmentUpdateFetching: true,
        skillAssessmentUpdateError: false,
        skillAssessmentUpdateSuccess: false,
      };
    case SKILL_ASSESSMENT_UPDATE_FAILED:
      return {
        ...state,
        skillAssessmentUpdateFetching: false,
        skillAssessmentUpdateError: true,
        skillAssessmentUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case SKILL_ASSESSMENT_UPDATE_SUCCEEDED:
      return {
        ...state,
        skillAssessmentDetail: action.payload,
        skillAssessmentUpdateFetching: false,
        skillAssessmentUpdateError: false,
        skillAssessmentUpdateSuccess: true,
      };

    case SKILL_ASSESSMENT_GET_REQUESTED:
      return {
        ...state,
        skillAssessmentGetFetching: true,
        skillAssessmentGetError: false,
      };
    case SKILL_ASSESSMENT_GET_FAILED:
      return {
        ...state,
        skillAssessmentGetFetching: false,
        skillAssessmentGetError: true,
      };
    case SKILL_ASSESSMENT_GET_SUCCEEDED:
      return {
        ...state,
        skillAssessmentDetail: action.payload,
        skillAssessmentGetFetching: false,
        skillAssessmentGetError: false,
      };

    case SKILL_ASSESSMENT_LOG_GET_REQUESTED:
      return {
        ...state,
        skillAssessmentLogGetFetching: true,
        skillAssessmentLogGetError: false,
      };
    case SKILL_ASSESSMENT_LOG_GET_FAILED:
      return {
        ...state,
        skillAssessmentLogGetFetching: false,
        skillAssessmentLogGetError: true,
      };
    case SKILL_ASSESSMENT_LOG_GET_SUCCEEDED:
      return {
        ...state,
        skillAssessmentLog: action.payload,
        skillAssessmentLogGetFetching: false,
        skillAssessmentLogGetError: false,
      };
    case SKILL_ASSESSMENT_GET_ALL_REQUESTED:
      return {
        ...state,
        skillAssessmentGetAllFetching: true,
        skillAssessmentGetAllError: false,
        skillAssessmentGetAllSuccess: false,
      };
    case SKILL_ASSESSMENT_GET_ALL_FAILED:
      return {
        ...state,
        skillAssessmentGetAllFetching: false,
        skillAssessmentGetAllError: true,
        skillAssessmentGetAllSuccess: false,
      };
    case SKILL_ASSESSMENT_GET_ALL_SUCCEEDED:
      return {
        ...state,
        skillAssessments: action.payload,
        skillAssessmentGetAllFetching: false,
        skillAssessmentGetAllError: false,
        skillAssessmentGetAllSuccess: true,
      };
    default:
      return state;
  }
}
