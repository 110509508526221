import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChipInput from "material-ui-chip-input";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import styles from "./styles";
import history from "../../history";
import CustomLinearProgress from "../CustomLinearProgress/CustomLinearProgress";
const useStyles = makeStyles(styles);

function SearchLead({ setLeadId, leadId, open, openLeadModal }) {
  const cs = useStyles();
  const divRef = React.useRef();

  const [progress, setProgress] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState([]);
  const [suggestionFound, setSuggestionFound] = useState(null);
  const leadList = useSelector((state) => state.lead.leads);

  const handleClose = (event) => {
    setSuggestionsOpen(false);
  };

  useEffect(() => {
    if (leadId) {
      setSelectedLead(leadList.filter((l) => l.id == leadId));
    } else {
      setSelectedLead([]);
    }
    if (!open) {
      setSelectedLead([]);
    }
  }, [leadId, open]);

  const handleAutocompleteSelect = (chip) => {
    handleAddTag(chip);
  };

  const handletextFieldInputChange = (e) => {
    const leadSearch = e.target.value.toLowerCase();
    if (leadSearch.length < 3) {
      setSuggestionsOpen(false);
      return;
    }
    const suggestions = leadList.filter((lead) => {
      return (
        `${lead.firstName} ${lead.lastName}`
          .toLowerCase()
          .includes(leadSearch) ||
        (lead.phone && lead.phone.includes(leadSearch)) ||
        (lead.email && lead.email.toLowerCase().includes(leadSearch))
      );
    });
    setSuggestionsOpen(true);
    setSuggestions(suggestions);
    setProgress(false);
    if (suggestions.length === 0) {
      setSuggestionFound(false);
    }
  };

  const handleAddTag = (chips) => {
    setSelectedLead([chips]);
    setLeadId(chips.id);
    setSuggestionsOpen(false);
    setSuggestions([]);
  };

  const handleRemoveTag = (value) => {
    const newLeads = selectedLead.filter((lead) => lead.id !== value.id);
    setSelectedLead(newLeads);
    if (newLeads.length === 0) {
      setLeadId(null);
    } else {
      setLeadId(newLeads[0].id);
    }
  };

  const handleUpdate = (value) => {};

  const getMenuItem = (suggestion) => {
    return (
      <>
        <ListItemText
          primary={`${suggestion.firstName} ${suggestion.lastName}`}
          secondary={`${suggestion.phone || ""} | ${suggestion.email}`}
        />
      </>
    );
  };

  return (
    <div className={cs.SearchTag} ref={divRef}>
      <ChipInput
        placeholder={"Search by name, email or phone"}
        label={"Client"}
        classes={{
          chip: cs.chip,
        }}
        className={cs.chipInput}
        fullWidth
        value={selectedLead}
        chipRenderer={(
          { value, handleClick, handleDelete, className },
          key
        ) => (
          <Chip
            key={key}
            clickable
            className={className}
            onClick={() => {
              history.push(`/admin/leads/${value.id}`);
            }}
            onDelete={handleDelete}
            label={
              <section>
                <div className={cs.pchip}>
                  {" "}
                  <span className={cs.name}>
                    {value.firstName} {value.lastName}
                  </span>
                </div>
                <div className={cs.pchip}>
                  {" "}
                  {value.phone} | {value.email}
                </div>
              </section>
            }
          />
        )}
        onAdd={handleAddTag}
        onDelete={handleRemoveTag}
        onChange={handleUpdate}
        onUpdateInput={(e) => {
          handletextFieldInputChange(e);
        }}
      />
      {progress && <CustomLinearProgress color="primary" />}
      <Popper
        open={suggestionsOpen}
        anchorEl={divRef.current}
        transition
        disablePortal
        className={cs.paper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className={cs.pop}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {suggestionFound === false && suggestions.length === 0 && (
                    <MenuItem
                      className={cs.popItem}
                      onClick={() => openLeadModal(true)}
                    >
                      Client not found. Create new.
                    </MenuItem>
                  )}
                  {suggestions.map((suggestion, idx) => {
                    return (
                      <MenuItem
                        className={cs.popItem}
                        key={idx}
                        onClick={() => handleAutocompleteSelect(suggestion)}
                      >
                        {getMenuItem(suggestion)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default SearchLead;
