import { call, put, takeLatest } from "redux-saga/effects";

import {
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCEEDED,
  FORGOT_PASSWORD_FAILED,
  PASSWORD_RESET_REQUESTED,
  PASSWORD_RESET_SUCCEEDED,
  PASSWORD_RESET_FAILED,
} from "../actions";
import remoteResource from "../../lib/RemoteResource";

function* forgotPassword(action) {
  try {
    const { payload } = action;
    yield call(remoteResource.forgotPassword, payload);
    yield put({
      type: FORGOT_PASSWORD_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: FORGOT_PASSWORD_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* passwordReset(action) {
  try {
    const { payload, token } = action;
    yield call(remoteResource.passwordReset, payload, token);
    yield put({
      type: PASSWORD_RESET_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: PASSWORD_RESET_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchPass() {
  yield takeLatest(FORGOT_PASSWORD_REQUESTED, forgotPassword);
  yield takeLatest(PASSWORD_RESET_REQUESTED, passwordReset);
}
