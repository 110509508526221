/*eslint-disable*/
import React, { lazy, Suspense, useEffect } from "react";

// core components
import Heading from "components/Heading/Heading.js";

const GeneralUserGraph = lazy(() => import("./GeneralUserGraph"));

import withTimer from "../../components/Timer/withTimer";

import LeadsTables from "./LeadTables";
import { Avatar, Card, CardContent, Grid, Typography } from "@material-ui/core";
import AnnouncementsTable from "./Announcement";

import { useDispatch, useSelector } from "react-redux";
import { LEAD_STATS_REQUESTED } from "redux/actions";

const Dashboard = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();
  const leadStats = useSelector((state) => state.stats.leadStats);

  useEffect(() => {
    dispatch({ type: LEAD_STATS_REQUESTED });
  }, []);
  console.log(leadStats);
  return (
    <div
      style={{
        backgroundColor: "white",
        width: "100%",
        padding: "0 10px 0 10px",
      }}
    >
      <Heading category="" title={`Home`} />
      <div>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card style={{ height: "100%" }}>
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: "#D3F3FB",
                  border: "1px solid #C0E1F0",
                }}
              >
                <Typography
                  variant="h6"
                  display="inline"
                  component="div"
                  style={{
                    marginRight: "8px",
                    color: "#2778C4",
                    fontWeight: "bold",
                  }}
                  color="#2778C4"
                >
                  Total Lead
                </Typography>
                <Avatar
                  style={{ backgroundColor: "#63CA9E" }}
                  sx={{
                    bgcolor: "#63CA9E",
                    width: 32,
                    height: 32,
                    marginLeft: 1,
                    fontWeight: "bold",
                  }}
                >
                  {leadStats?.total?.totalCount}
                </Avatar>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ height: "100%" }}>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  paddingTop: "4px",
                  backgroundColor: "#ECE8FE",
                  border: "1px solid #BCB5E2",
                }}
              >
                <Typography
                  variant="h6"
                  display="inline"
                  component="div"
                  style={{
                    marginBottom: "8px",
                    color: "#2778C4",
                    fontWeight: "bold",
                  }}
                  color="#2778C4"
                >
                  Total In-Progress
                </Typography>
                <Avatar
                  style={{ backgroundColor: "#837DF8" }}
                  sx={{
                    bgcolor: "primary.main",
                    width: 32,
                    height: 32,
                    marginLeft: 1,
                  }}
                >
                  {leadStats?.total?.inProgressCount}
                </Avatar>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ height: "100%" }}>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: "#DDF5ED",
                  border: "1px solid #C0E1F0",
                }}
              >
                <Typography
                  variant="h6"
                  display="inline"
                  component="div"
                  style={{
                    marginRight: "8px",
                    color: "#2778C4",
                    fontWeight: "bold",
                  }}
                  color="#2778C4"
                >
                  Total Converted
                </Typography>
                <Avatar
                  style={{ backgroundColor: "#63CA9E" }}
                  sx={{
                    bgcolor: "primary.main",
                    width: 32,
                    height: 32,
                    marginLeft: 1,
                  }}
                >
                  {leadStats?.total?.convertedCount}
                </Avatar>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <Card style={{ height: "100%" }}>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  paddingTop: "4px",
                  backgroundColor: "#FDC0C2",
                  border: "1px solid #CBDFEB",
                }}
              >
                <Typography
                  variant="h6"
                  display="inline"
                  component="div"
                  style={{
                    marginRight: "8px",
                    color: "#2778C4",
                    fontWeight: "bold",
                  }}
                >
                  Total Not Converted
                </Typography>
                <Avatar
                  style={{ backgroundColor: "#837DF8" }}
                  sx={{
                    bgcolor: "primary.main",
                    width: 32,
                    height: 32,
                    marginLeft: 1,
                  }}
                >
                  {leadStats?.total?.notConvertedCount}
                </Avatar>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "35px" }} container spacing={2}>
          <Grid item xs={12}>
            <Suspense fallback={<></>}>
              <GeneralUserGraph />
            </Suspense>
          </Grid>
        </Grid>
        <LeadsTables id={id} />
        <AnnouncementsTable />
      </div>
    </div>
  );
};

export default withTimer(Dashboard);
