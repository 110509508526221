import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  VISA_APPLICANT_CREATE_REQUESTED,
  VISA_APPLICANT_CREATE_FAILED,
  VISA_APPLICANT_CREATE_SUCCEEDED,
  VISA_APPLICANT_GET_ALL_REQUESTED,
  VISA_APPLICANT_GET_ALL_FAILED,
  VISA_APPLICANT_GET_ALL_SUCCEEDED,
  VISA_APPLICANT_GET_REQUESTED,
  VISA_APPLICANT_GET_FAILED,
  VISA_APPLICANT_GET_SUCCEEDED,
  VISA_APPLICANT_LOG_GET_REQUESTED,
  VISA_APPLICANT_LOG_GET_FAILED,
  VISA_APPLICANT_LOG_GET_SUCCEEDED,
  VISA_APPLICANT_UPDATE_REQUESTED,
  VISA_APPLICANT_UPDATE_FAILED,
  VISA_APPLICANT_UPDATE_SUCCEEDED,
  LEAD_UPDATE_SUCCEEDED,
  VISA_APPLICANT_DELETE_SUCCEEDED,
  VISA_APPLICANT_DELETE_FAILED,
  VISA_APPLICANT_DELETE_REQUESTED,
} from "../actions";

import { getToken } from "./selectors";

function* fetchVisaApplicant(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const visaApplicant = yield call(
      remoteResource.getVisaApplicant,
      id,
      token
    );
    yield put({
      type: VISA_APPLICANT_GET_SUCCEEDED,
      payload: visaApplicant.data,
    });
  } catch (e) {
    yield put({ type: VISA_APPLICANT_GET_FAILED, payload: e.message });
  }
}

function* fetchVisaApplicantLog(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const visaApplicant = yield call(
      remoteResource.getVisaApplicantLog,
      id,
      token
    );
    yield put({
      type: VISA_APPLICANT_LOG_GET_SUCCEEDED,
      payload: visaApplicant.data,
    });
  } catch (e) {
    yield put({ type: VISA_APPLICANT_LOG_GET_FAILED, payload: e.message });
  }
}

function* fetchAllVisaApplicants(action) {
  try {
    const token = yield select(getToken);
    const visaApplicants = yield call(
      remoteResource.getAllVisaApplicants,
      token
    );
    yield put({
      type: VISA_APPLICANT_GET_ALL_SUCCEEDED,
      payload: visaApplicants.data,
    });
  } catch (e) {
    yield put({ type: VISA_APPLICANT_GET_ALL_FAILED, payload: e.message });
  }
}

function* createVisaApplicant(action) {
  try {
    let payload = {};
    let leadId = "";
    if (action.payload && action.payload.payload) {
      payload = action.payload.payload;
    } else {
      payload = action.payload;
    }
    if (action.payload && action.payload && action.payload.leadId) {
      leadId = action.payload.leadId;
    }
    const token = yield select(getToken);
    const visaApplicant = yield call(
      remoteResource.createVisaApplicant,
      payload,
      token
    );
    if (leadId && leadId !== "") {
      const lead = yield call(
        remoteResource.updateLeadClient,
        leadId,
        { visaApplicantId: visaApplicant.data.id },
        token
      );
      yield put({
        type: LEAD_UPDATE_SUCCEEDED,
        payload: lead.data,
      });
    }
    yield put({
      type: VISA_APPLICANT_CREATE_SUCCEEDED,
      payload: visaApplicant.data,
    });
  } catch (e) {
    yield put({
      type: VISA_APPLICANT_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateVisaApplicant(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const visaApplicant = yield call(
      remoteResource.updateVisaApplicant,
      id,
      payload,
      token
    );
    yield put({
      type: VISA_APPLICANT_UPDATE_SUCCEEDED,
      payload: visaApplicant.data,
    });
  } catch (e) {
    yield put({
      type: VISA_APPLICANT_UPDATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* deleteVisaApplicant(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    yield call(remoteResource.deleteVisaApplicant, id, token);
    yield put({
      type: VISA_APPLICANT_DELETE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: VISA_APPLICANT_DELETE_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchVisaApplicant() {
  yield takeLatest(VISA_APPLICANT_GET_REQUESTED, fetchVisaApplicant);
  yield takeLatest(VISA_APPLICANT_CREATE_REQUESTED, createVisaApplicant);
  yield takeLatest(VISA_APPLICANT_UPDATE_REQUESTED, updateVisaApplicant);
  yield takeLatest(VISA_APPLICANT_GET_ALL_REQUESTED, fetchAllVisaApplicants);
  yield takeLatest(VISA_APPLICANT_LOG_GET_REQUESTED, fetchVisaApplicantLog);
  yield takeLatest(VISA_APPLICANT_DELETE_REQUESTED, deleteVisaApplicant);
}
