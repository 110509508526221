/*eslint-disable*/
import React from "react";
// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

import { getInfoRequestedDateForCalendar, getVisaExpireDateForCalendar } from "../../scripts/util";
import { useSelector } from 'react-redux';
import withTimer from '../../components/Timer/withTimer';
import { Link } from 'react-router-dom';

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);

const Calendar = () => {
  const classes = useStyles();
  const [alert, setAlert] = React.useState(null);
  const students = useSelector((state) => state.student.students);
  const visaApplicants = useSelector((state) => state.visaApplicant.visaApplicants);
  const skillAssessments = useSelector((state) => state.skillAssessment.skillAssessments);
  const leads = useSelector((state) => state.lead.leads);
  const visaExpireData = getVisaExpireDateForCalendar(visaApplicants, 'orange', '/admin/visa', 'Visa Applicant');
  const leadVisaExpiry = getVisaExpireDateForCalendar(leads, 'grey', '/admin/lead', 'Lead');
  const visaInfoRequestedData = getInfoRequestedDateForCalendar(visaApplicants, 'Visa Info Due Date','a', '/admin/visa', 'Visa Applicant');
  const skillInfoRequestedData = getInfoRequestedDateForCalendar(skillAssessments, 'Skill Info Due Date','azure', '/admin/skill', 'Skill Assessment Applicant');
  const selectedEvent = event => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={`${event.firstName} ${event.lastName}`}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
      >
        <div>
          <label style={{marginRight: '10px'}}>ID:</label>
          <small><Link to={`${event.path}/${event.id}`}>{event.id}</Link></small>
        </div>
        <div>
          <label style={{marginRight: '10px'}}>{event.label}: </label>
          <small>{event.start.format('DD MMM, YYYY')}</small>
        </div>
        <div>
          <label style={{marginRight: '10px'}}>Phone:</label>
          <small>{event.phone}</small>
        </div>
        <div>
          <label style={{marginRight: '10px'}}>Email:</label>
          <small>{event.email}</small>
        </div>
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const eventColors = event => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  };

  return (
    <div>
      <Heading
        textAlign="center"
        title="Calendar View of Important Dates"
        category={
          <span>
            Visa Expiry Date, Info Request Due Date for Visa and Skill Assessment.
          </span>
        }
      />
      {alert}
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody calendar>
              <BigCalendar
                localizer={localizer}
                events={[...visaInfoRequestedData, ...visaExpireData, ...skillInfoRequestedData, ...leadVisaExpiry]}
                defaultView="month"
                defaultDate={new Date()}
                onSelectEvent={event => selectedEvent(event)}
                eventPropGetter={eventColors}
                popup
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withTimer(Calendar);
