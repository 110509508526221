import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  SKILL_ASSESSMENT_CREATE_REQUESTED,
  SKILL_ASSESSMENT_CREATE_FAILED,
  SKILL_ASSESSMENT_CREATE_SUCCEEDED,
  SKILL_ASSESSMENT_GET_ALL_REQUESTED,
  SKILL_ASSESSMENT_GET_ALL_FAILED,
  SKILL_ASSESSMENT_GET_ALL_SUCCEEDED,
  SKILL_ASSESSMENT_GET_REQUESTED,
  SKILL_ASSESSMENT_GET_FAILED,
  SKILL_ASSESSMENT_GET_SUCCEEDED,
  SKILL_ASSESSMENT_LOG_GET_REQUESTED,
  SKILL_ASSESSMENT_LOG_GET_FAILED,
  SKILL_ASSESSMENT_LOG_GET_SUCCEEDED,
  SKILL_ASSESSMENT_UPDATE_REQUESTED,
  SKILL_ASSESSMENT_UPDATE_FAILED,
  SKILL_ASSESSMENT_UPDATE_SUCCEEDED,
  LEAD_UPDATE_SUCCEEDED,
  SKILL_ASSESSMENT_DELETE_SUCCEEDED,
  SKILL_ASSESSMENT_DELETE_FAILED,
  SKILL_ASSESSMENT_DELETE_REQUESTED,
} from "../actions";

import { getToken } from "./selectors";

function* fetchSkillAssessment(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const skillAssessment = yield call(
      remoteResource.getSkillAssessment,
      id,
      token
    );
    yield put({
      type: SKILL_ASSESSMENT_GET_SUCCEEDED,
      payload: skillAssessment.data,
    });
  } catch (e) {
    yield put({ type: SKILL_ASSESSMENT_GET_FAILED, payload: e.message });
  }
}

function* fetchSkillAssessmentLog(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const skillAssessment = yield call(
      remoteResource.getSkillAssessmentLog,
      id,
      token
    );
    yield put({
      type: SKILL_ASSESSMENT_LOG_GET_SUCCEEDED,
      payload: skillAssessment.data,
    });
  } catch (e) {
    yield put({ type: SKILL_ASSESSMENT_LOG_GET_FAILED, payload: e.message });
  }
}

function* fetchAllSkillAssessments(action) {
  try {
    const token = yield select(getToken);
    const skillAssessments = yield call(
      remoteResource.getAllSkillAssessment,
      token
    );
    yield put({
      type: SKILL_ASSESSMENT_GET_ALL_SUCCEEDED,
      payload: skillAssessments.data,
    });
  } catch (e) {
    yield put({ type: SKILL_ASSESSMENT_GET_ALL_FAILED, payload: e.message });
  }
}

function* createSkillAssessment(action) {
  try {
    let payload = {};
    let leadId = "";
    if (action.payload && action.payload.payload) {
      payload = action.payload.payload;
    } else {
      payload = action.payload;
    }
    if (action.payload && action.payload && action.payload.leadId) {
      leadId = action.payload.leadId;
    }
    const token = yield select(getToken);
    const skillAssessment = yield call(
      remoteResource.createSkillAssessment,
      payload,
      token
    );
    if (leadId && leadId !== "") {
      const lead = yield call(
        remoteResource.updateLeadClient,
        leadId,
        { skillAssessmentId: skillAssessment.data.id },
        token
      );
      yield put({
        type: LEAD_UPDATE_SUCCEEDED,
        payload: lead.data,
      });
    }
    yield put({
      type: SKILL_ASSESSMENT_CREATE_SUCCEEDED,
      payload: skillAssessment.data,
    });
  } catch (e) {
    yield put({
      type: SKILL_ASSESSMENT_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateSkillAssessment(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const skillAssessment = yield call(
      remoteResource.updateSkillAssessment,
      id,
      payload,
      token
    );
    yield put({
      type: SKILL_ASSESSMENT_UPDATE_SUCCEEDED,
      payload: skillAssessment.data,
    });
  } catch (e) {
    yield put({
      type: SKILL_ASSESSMENT_UPDATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* deleteSkillAssessment(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    yield call(remoteResource.deleteSkillAssessment, id, token);
    yield put({
      type: SKILL_ASSESSMENT_DELETE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: SKILL_ASSESSMENT_DELETE_FAILED,
      payload: e.response.data.message,
    });
  }
}
export function* watchSkillAssessment() {
  yield takeLatest(SKILL_ASSESSMENT_GET_REQUESTED, fetchSkillAssessment);
  yield takeLatest(SKILL_ASSESSMENT_LOG_GET_REQUESTED, fetchSkillAssessmentLog);
  yield takeLatest(SKILL_ASSESSMENT_CREATE_REQUESTED, createSkillAssessment);
  yield takeLatest(SKILL_ASSESSMENT_UPDATE_REQUESTED, updateSkillAssessment);
  yield takeLatest(
    SKILL_ASSESSMENT_GET_ALL_REQUESTED,
    fetchAllSkillAssessments
  );
  yield takeLatest(SKILL_ASSESSMENT_DELETE_REQUESTED, deleteSkillAssessment);
}
