import {
  VISA_APPLICANT_CREATE_REQUESTED,
  VISA_APPLICANT_CREATE_FAILED,
  VISA_APPLICANT_CREATE_SUCCEEDED,
  VISA_APPLICANT_DELETE_REQUESTED,
  VISA_APPLICANT_DELETE_FAILED,
  VISA_APPLICANT_DELETE_SUCCEEDED,
  VISA_APPLICANT_GET_ALL_REQUESTED,
  VISA_APPLICANT_GET_ALL_FAILED,
  VISA_APPLICANT_GET_ALL_SUCCEEDED,
  VISA_APPLICANT_GET_REQUESTED,
  VISA_APPLICANT_GET_FAILED,
  VISA_APPLICANT_GET_SUCCEEDED,
  VISA_APPLICANT_LOG_GET_REQUESTED,
  VISA_APPLICANT_LOG_GET_FAILED,
  VISA_APPLICANT_LOG_GET_SUCCEEDED,
  VISA_APPLICANT_UPDATE_REQUESTED,
  VISA_APPLICANT_UPDATE_FAILED,
  VISA_APPLICANT_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  visaApplicantCreateError: false,
  visaApplicantCreateFetching: false,
  visaApplicantCreateSuccess: false,

  visaApplicantDeleteError: false,
  visaApplicantDeleteFetching: false,
  visaApplicantDeleteSuccess: false,

  visaApplicantUpdateError: false,
  visaApplicantUpdateFetching: false,
  visaApplicantUpdateSuccess: false,

  visaApplicantGetError: false,
  visaApplicantGetFetching: false,
  visaApplicantGetSuccess: false,

  visaApplicantLogGetError: false,
  visaApplicantLogGetFetching: false,
  visaApplicantLogGetSuccess: false,

  visaApplicantGetAllError: false,
  visaApplicantGetAllSuccess: false,
  errorMsg: "",
};

const defaultState = {
  visaApplicants: [],
  visaApplicantLog: [],
  visaApplicantDetail: {},
  visaApplicantGetAllFetching: false,
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case VISA_APPLICANT_CREATE_REQUESTED:
      return {
        ...state,
        visaApplicantCreateFetching: true,
        visaApplicantCreateError: false,
        visaApplicantCreateSuccess: false,
      };
    case VISA_APPLICANT_CREATE_FAILED:
      return {
        ...state,
        visaApplicantCreateFetching: false,
        visaApplicantCreateError: true,
        visaApplicantCreateSuccess: false,
        errorMsg: action.payload,
      };
    case VISA_APPLICANT_CREATE_SUCCEEDED:
      return {
        ...state,
        visaApplicantDetail: action.payload,
        visaApplicantCreateFetching: false,
        visaApplicantCreateError: false,
        visaApplicantCreateSuccess: true,
      };

    case VISA_APPLICANT_DELETE_REQUESTED:
      return {
        ...state,
        visaApplicantDeleteFetching: true,
        visaApplicantDeleteError: false,
        visaApplicantDeleteSuccess: false,
      };
    case VISA_APPLICANT_DELETE_FAILED:
      return {
        ...state,
        visaApplicantDeleteFetching: false,
        visaApplicantDeleteError: true,
        visaApplicantDeleteSuccess: false,
        errorMsg: action.payload,
      };
    case VISA_APPLICANT_DELETE_SUCCEEDED:
      return {
        ...state,
        visaApplicantDeleteFetching: false,
        visaApplicantDeleteError: false,
        visaApplicantDeleteSuccess: true,
      };

    case VISA_APPLICANT_UPDATE_REQUESTED:
      return {
        ...state,
        visaApplicantUpdateFetching: true,
        visaApplicantUpdateError: false,
        visaApplicantUpdateSuccess: false,
      };
    case VISA_APPLICANT_UPDATE_FAILED:
      return {
        ...state,
        visaApplicantUpdateFetching: false,
        visaApplicantUpdateError: true,
        visaApplicantUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case VISA_APPLICANT_UPDATE_SUCCEEDED:
      return {
        ...state,
        visaApplicantDetail: action.payload,
        visaApplicantUpdateFetching: false,
        visaApplicantUpdateError: false,
        visaApplicantUpdateSuccess: true,
      };

    case VISA_APPLICANT_GET_REQUESTED:
      return {
        ...state,
        visaApplicantGetFetching: true,
        visaApplicantGetError: false,
      };
    case VISA_APPLICANT_GET_FAILED:
      return {
        ...state,
        visaApplicantGetFetching: false,
        visaApplicantGetError: true,
      };
    case VISA_APPLICANT_GET_SUCCEEDED:
      return {
        ...state,
        visaApplicantDetail: action.payload,
        visaApplicantGetFetching: false,
        visaApplicantGetError: false,
      };

    case VISA_APPLICANT_LOG_GET_REQUESTED:
      return {
        ...state,
        visaApplicantLogGetFetching: true,
        visaApplicantLogGetError: false,
      };
    case VISA_APPLICANT_LOG_GET_FAILED:
      return {
        ...state,
        visaApplicantLogGetFetching: false,
        visaApplicantLogGetError: true,
      };
    case VISA_APPLICANT_LOG_GET_SUCCEEDED:
      return {
        ...state,
        visaApplicantLog: action.payload,
        visaApplicantLogGetFetching: false,
        visaApplicantLogGetError: false,
      };

    case VISA_APPLICANT_GET_ALL_REQUESTED:
      return {
        ...state,
        visaApplicantGetAllFetching: true,
        visaApplicantGetAllError: false,
        visaApplicantGetAllSuccess: false,
      };
    case VISA_APPLICANT_GET_ALL_FAILED:
      return {
        ...state,
        visaApplicantGetAllFetching: false,
        visaApplicantGetAllError: true,
        visaApplicantGetAllSuccess: false,
      };
    case VISA_APPLICANT_GET_ALL_SUCCEEDED:
      return {
        ...state,
        visaApplicants: action.payload,
        visaApplicantGetAllFetching: false,
        visaApplicantGetAllError: false,
        visaApplicantGetAllSuccess: true,
      };
    default:
      return state;
  }
}
