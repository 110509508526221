import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
} from "@mui/material";
import { useSelector } from "react-redux";

const AnnouncementsTable = () => {
  const announcementList = useSelector(
    (state) => state.announcement.announcements
  );

  return (
    <Paper
      elevation={3}
      sx={{ borderRadius: 2, marginTop: "20px", overflow: "hidden" }}
    >
      <Box p={3}>
        <Typography variant="h5" gutterBottom>
          Updates and Announcements
        </Typography>
        <TableContainer>
          <Table>
            <TableHead
              sx={{
                background: "#f9fafc",
              }}
            >
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox />
                </TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(announcementList) && announcementList?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Typography variant="body1" fontWeight="bold">
                      {row.title}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <Typography variant="body1" fontWeight="bold">
                      {row.description}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default AnnouncementsTable;
