import {
  REPORT_CREATE_REQUESTED,
  REPORT_CREATE_FAILED,
  REPORT_CREATE_SUCCEEDED,
  REPORT_REFRESH_REQUESTED,
  REPORT_REFRESH_FAILED,
  REPORT_REFRESH_SUCCEEDED,
  REPORT_GET_ALL_REQUESTED,
  REPORT_GET_ALL_FAILED,
  REPORT_GET_ALL_SUCCEEDED,
  REPORT_GET_REQUESTED,
  REPORT_GET_FAILED,
  REPORT_GET_SUCCEEDED,
  REPORT_UPDATE_REQUESTED,
  REPORT_UPDATE_FAILED,
  REPORT_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  reportCreateError: false,
  reportCreateFetching: false,
  reportCreateSuccess: false,

  reportRefreshError: false,
  reportRefreshFetching: false,
  reportRefreshSuccess: false,

  reportUpdateError: false,
  reportUpdateFetching: false,
  reportUpdateSuccess: false,

  reportGetError: false,
  reportGetFetching: false,
  reportGetSuccess: false,

  reportGetAllError: false,
  reportGetAllFetching: false,
  reportGetAllSuccess: false,
  errorMsg: "",
};

const defaultState = {
  reports: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case REPORT_CREATE_REQUESTED:
      return {
        ...state,
        reportCreateFetching: true,
        reportCreateError: false,
        reportCreateSuccess: false,
      };
    case REPORT_CREATE_FAILED:
      return {
        ...state,
        reportCreateFetching: false,
        reportCreateError: true,
        reportCreateSuccess: false,
        errorMsg: action.payload,
      };
    case REPORT_CREATE_SUCCEEDED:
      return {
        ...state,
        reportDetail: action.payload,
        reportCreateFetching: false,
        reportCreateError: false,
        reportCreateSuccess: true,
      };

    case REPORT_REFRESH_REQUESTED:
      return {
        ...state,
        reportRefreshFetching: true,
        reportRefreshError: false,
        reportRefreshSuccess: false,
      };
    case REPORT_REFRESH_FAILED:
      return {
        ...state,
        reportRefreshFetching: false,
        reportRefreshError: true,
        reportRefreshSuccess: false,
        errorMsg: action.payload,
      };
    case REPORT_REFRESH_SUCCEEDED:
      return {
        ...state,
        reportRefreshFetching: false,
        reportRefreshError: false,
        reportRefreshSuccess: true,
      };

    case REPORT_UPDATE_REQUESTED:
      return {
        ...state,
        reportUpdateFetching: true,
        reportUpdateError: false,
        reportUpdateSuccess: false,
      };
    case REPORT_UPDATE_FAILED:
      return {
        ...state,
        reportUpdateFetching: false,
        reportUpdateError: true,
        reportUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case REPORT_UPDATE_SUCCEEDED:
      return {
        ...state,
        reportDetail: action.payload,
        reportUpdateFetching: false,
        reportUpdateError: false,
        reportUpdateSuccess: true,
      };

    case REPORT_GET_REQUESTED:
      return {
        ...state,
        reportGetFetching: true,
        reportGetError: false,
      };
    case REPORT_GET_FAILED:
      return {
        ...state,
        reportGetFetching: false,
        reportGetError: true,
      };
    case REPORT_GET_SUCCEEDED:
      return {
        ...state,
        reportDetail: action.payload,
        reportGetFetching: false,
        reportGetError: false,
      };

    case REPORT_GET_ALL_REQUESTED:
      return {
        ...state,
        reportGetAllFetching: true,
        reportGetAllError: false,
      };
    case REPORT_GET_ALL_FAILED:
      return {
        ...state,
        reportGetAllFetching: false,
        reportGetAllError: true,
      };
    case REPORT_GET_ALL_SUCCEEDED:
      return {
        ...state,
        reports: action.payload,
        reportGetAllFetching: false,
        reportGetAllError: false,
      };
    default:
      return state;
  }
}
