import React, { useEffect } from "react";
import { connect } from "react-redux";
import { CLEAR_ERROR, LOGIN_REQUESTED } from "../../redux/actions";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { NavLink, Redirect } from "react-router-dom";
import ReactGA from "react-ga4";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import Version from "../../components/Version";
import Col from "react-bootstrap/Col";
import { LOGO_HORIZONTAL } from "../../scripts/const";
import Row from "react-bootstrap/Row";
const useStyles = makeStyles(styles);

const LoginPage = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [user, setState] = React.useState({ email: "", password: "" });
  const [passState, setPassState] = React.useState("");
  const [emailState, setEmailState] = React.useState("");
  const [loginError, setLoginState] = React.useState(false);
  const [loginProgress, setLoginProgress] = React.useState(false);

  const verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (props.loginError === true || props.loginError === false) {
      setLoginState(props.loginError);
    }
  }, [props.loginError]);

  useEffect(() => {
    if (props.loginProgress === true || props.loginProgress === false) {
      setLoginProgress(props.loginProgress);
    }
  }, [props.loginProgress]);

  function login(props) {
    let allFieldGood = true;
    if (user.email && verifyEmail(user.email)) {
      allFieldGood = true;
      setEmailState("success");
    } else {
      allFieldGood = false;
      setEmailState("error");
      return;
    }

    if (user.password.length >= 6) {
      allFieldGood = true;
      setPassState("success");
    } else {
      allFieldGood = false;
      setPassState("error");
      return;
    }
    if (allFieldGood) {
      if (props && props.loginMe) {
        props.clearErrors();
        props.loginMe(user);
      }
    }
  }

  const handleChange = (e) => {
    setState({
      ...user,
      [e.target.id]: e.target.value,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login(props);
    }
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  if (props.authorized === true) {
    return <Redirect to="/admin" />;
  }

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <Row className="justify-content-center">
                <Col xs="auto" className="m-3">
                  <img style={{ height: "50px" }} src={LOGO_HORIZONTAL} />
                </Col>
              </Row>
              <CardBody>
                <CustomInput
                  success={emailState === "success"}
                  error={emailState === "error"}
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    onKeyDown: handleKeyDown,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  success={passState === "success"}
                  error={passState === "error"}
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    onKeyDown: handleKeyDown,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
                {loginProgress && <CustomLinearProgress color="primary" />}
                <Button
                  disabled={loginProgress ? true : false}
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={() => {
                    ReactGA.event({
                      category: "Login",
                      action: "Logging In",
                    });
                    login(props);
                  }}
                >
                  Let{"'"}s Go
                </Button>
              </CardBody>

              <CardFooter className={classes.justifyContentCenter}>
                <NavLink to={"/auth/forgot-password"}>
                  <small>Forgot Password?</small>
                </NavLink>
              </CardFooter>
              {loginError && (
                <SnackbarContent message={"Login Failed"} color="danger" />
              )}
            </Card>
            <Version />
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authorized: state.login.authorized,
    loginError: state.loginError.loginError,
    loginProgress: state.loginError.loginProgress,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginMe: (payload) => {
    dispatch({ type: LOGIN_REQUESTED, payload });
  },
  clearErrors: () => {
    dispatch({ type: CLEAR_ERROR });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
