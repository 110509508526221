import React from "react";

import PropTypes from "prop-types";
import ReactSelect from "react-select";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles();

export const customReactSelectStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%", // Adjust as needed
    // margin: "10px",
  }),
  control: (provided) => ({
    ...provided,
    border: "none",
    outline: "none",
    borderRadius: "none",
    borderBottom: "1px solid #d9d9d9",

    "&:hover": {
      borderBottom: "1px solid purple",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1400, // Ensure menu is on top of other components
  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: "left",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

export default function DropDownWithSearch(props) {
  const {
    selectedOption,
    options,
    optionsList,
    dbOptions,
    dbLabel,
    setValue,
    setFieldValue,
    currentState,
    name,
    isMulti,
    label,
    children,
  } = props;
  const classes = useStyles(styles);

  const getFinalOptions = () => {
    if (options) {
      return options;
    }
    if (optionsList) {
      return optionsList.map((v) => ({ value: v, label: v }));
    }
    if (dbOptions) {
      return dbOptions.map((v) => ({ value: v.id, label: v[dbLabel] }));
    }
  };
  const finalOptions = getFinalOptions();

  const getValue = () => {
    if (dbOptions) {
      if (isMulti) {
      } else {
        return selectedOption
          ? {
              label: dbOptions.find((opt) => opt.id == selectedOption)?.[
                dbLabel
              ],
              value: selectedOption,
            }
          : "";
      }
    } else {
      if (isMulti) {
        if (selectedOption.length > 0) {
          return selectedOption.map((v) => ({ label: v, value: v }));
        } else {
          return selectedOption;
        }
      } else {
        return selectedOption
          ? { label: selectedOption, value: selectedOption }
          : "";
      }
    }
  };

  const customOnChange = (finalOptions) => {
    if (isMulti) {
      if (finalOptions.length === 0) {
        setValue({
          ...currentState,
          [name]: JSON.stringify([]),
        });
      } else {
        setValue({
          ...currentState,
          [name]: JSON.stringify(finalOptions.map((o) => o.value)),
        });
      }
    } else {
      if (!finalOptions) {
        if (setFieldValue) {
          setFieldValue(name, null);
          return;
        }
        setValue({
          ...currentState,
          [name]: null,
        });
      } else {
        if (setFieldValue) {
          setFieldValue(name, finalOptions.value);
          return;
        }
        setValue({
          ...currentState,
          [name]: finalOptions.value,
        });
      }
    }
  };

  const isMultipleValue =
    Array.isArray(getValue()) && getValue().length > 0 ? "" : "hidden";
  const isSingleValue = getValue() ? "" : "hidden";

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <div
        className={classes.customDropdownLabel}
        style={{
          visibility: isMulti ? isMultipleValue : isSingleValue,
          color: "#AAA",
          fontSize: "11px",
          lineHeight: "1.428571429",
          fontWeight: "400",
          display: "inline-flex",
          textAlign: "left",
          width: "100%",
          // marginTop: "18px",
        }}
      >
        {label}
      </div>

      <ReactSelect
        className="basic-single"
        classNamePrefix="select"
        isClearable
        isSearchable
        isMulti={isMulti}
        placeholder={label || "select"}
        styles={customReactSelectStyles}
        value={getValue()}
        options={finalOptions}
        name={name}
        onChange={customOnChange}
      />
      {children}
    </FormControl>
  );
}

DropDownWithSearch.defaultProps = {
  isMulti: false,
  dbLabel: "name",
};

DropDownWithSearch.propTypes = {
  selectedOption: PropTypes.node,
  name: PropTypes.string,
  optionsList: PropTypes.array,
  dbOptions: PropTypes.array,
  isMulti: PropTypes.bool,
  options: PropTypes.object,
  setValue: PropTypes.func,
  setFieldValue: PropTypes.func,
  currentState: PropTypes.object,
  handleSelect: PropTypes.func,
  label: PropTypes.string,
  dbLabel: PropTypes.string,
};
