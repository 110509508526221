import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridItem from "../../components/Grid/GridItem";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { useSelector } from "react-redux";
import GridContainer from "../../components/Grid/GridContainer";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  getAssignedDataForBarChart,
  getDataForBarChart,
  getSourceDataForBarChart,
} from "../../scripts/util";
const useStyles = makeStyles(styles);

const CountBarChart = () => {
  const classes = useStyles();

  const users = useSelector((state) => state.user.users);
  const sources = useSelector((state) => state.source.sources);
  const leads = useSelector((state) => state.lead.leads);
  const students = useSelector((state) => state.student.students);
  const visaApplicants = useSelector(
    (state) => state.visaApplicant.visaApplicants
  );

  const skillAssessments = useSelector(
    (state) => state.skillAssessment.skillAssessments
  );

  const {
    chartData: employeePerformanceData,
    options: { colors, chartUsers },
  } = useMemo(
    () => getDataForBarChart(students, visaApplicants, skillAssessments, users),
    [students, visaApplicants, skillAssessments, users]
  );

  const {
    chartData: clientAssignedData,
    options: {
      colors: clientAssignedColors,
      chartUsers: clientAssignedChartUsers,
    },
  } = useMemo(
    () =>
      getAssignedDataForBarChart(
        [...students, ...skillAssessments, ...visaApplicants],
        users
      ),
    [students, skillAssessments, visaApplicants, users]
  );

  const {
    sourceChartData: studentSourceChartData,
    options: { colors: studentSourceColors, chartSources: studentChartSources },
  } = useMemo(
    () => getSourceDataForBarChart(students, sources),
    [students, sources]
  );

  const {
    sourceChartData: leadSourceChartData,
    options: { colors: leadSourceColors, chartSources: leadChartSources },
  } = useMemo(() => getSourceDataForBarChart(leads, sources), [leads, sources]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <h4 className={classes.cardIconTitle}>
                Employee&apos;s Client Count{" "}
                <small>by student, visa, skill assigned</small>
              </h4>
            </CardHeader>
            <CardBody>
              {users.length !== 0 &&
                (students.length > 0 ||
                  visaApplicants.length > 0 ||
                  skillAssessments.length > 0) && (
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={clientAssignedData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {clientAssignedChartUsers &&
                        clientAssignedChartUsers.map((user, i) => {
                          return (
                            <Bar
                              key={i}
                              dataKey={user}
                              fill={clientAssignedColors[i]}
                            />
                          );
                        })}
                    </BarChart>
                  </ResponsiveContainer>
                )}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <h4 className={classes.cardIconTitle}>
                Employee&apos;s Performance{" "}
                <small>
                  Student Coe Received + Visa Applied + Skill Applied
                </small>
              </h4>
            </CardHeader>
            <CardBody>
              {users.length !== 0 &&
                (students.length > 0 ||
                  visaApplicants.length > 0 ||
                  skillAssessments.length > 0) && (
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={employeePerformanceData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      {chartUsers &&
                        chartUsers.map((user, i) => {
                          return (
                            <Bar key={i} dataKey={user} fill={colors[i]} />
                          );
                        })}
                    </BarChart>
                  </ResponsiveContainer>
                )}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <h4 className={classes.cardIconTitle}>
                Lead count <small>by sources</small>
              </h4>
            </CardHeader>
            <CardBody>
              {leads.length !== 0 && (
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={leadSourceChartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {leadChartSources &&
                      leadChartSources.map((user, i) => {
                        return (
                          <Bar
                            key={i}
                            dataKey={user}
                            fill={leadSourceColors[i]}
                          />
                        );
                      })}
                  </BarChart>
                </ResponsiveContainer>
              )}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <h4 className={classes.cardIconTitle}>
                Student count <small>by sources</small>
              </h4>
            </CardHeader>
            <CardBody>
              {students.length !== 0 && (
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={studentSourceChartData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {studentChartSources &&
                      studentChartSources.map((user, i) => {
                        return (
                          <Bar
                            key={i}
                            dataKey={user}
                            fill={studentSourceColors[i]}
                          />
                        );
                      })}
                  </BarChart>
                </ResponsiveContainer>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default CountBarChart;
