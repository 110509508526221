import React from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../views/Client-facing/Immigration/style.css";
import {
  countryOption,
  countryOptionWithFourLetter,
} from "../../views/Client-facing/Immigration/helper";

import PropTypes from "prop-types";

export default function ImmigrationForm(props) {
  const { values, validated, handleSubmit, handleSelect, isEdit } = props;

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <h3>Passport Details</h3>
      {isEdit && (
        <Row className="mb-3">
          <Form.Group as={Col} md="3">
            <Form.Label>Id</Form.Label>
            <Form.Control
              required
              disabled
              readOnly
              type="text"
              value={values?.id}
              placeholder="Id"
              name="id"
            />
          </Form.Group>
        </Row>
      )}
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>First name</Form.Label>
          <Form.Control
            required
            type="text"
            value={values?.givenName}
            placeholder="First name"
            name="givenName"
            onChange={handleSelect}
          />
          <Form.Control.Feedback type="invalid">
            Please Provide First Name
          </Form.Control.Feedback>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last name"
            value={values?.familyName}
            name="familyName"
            onChange={handleSelect}
          />
          <Form.Control.Feedback type="invalid">
            Please Provide Last Name
          </Form.Control.Feedback>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
          <Form.Label>Gender</Form.Label>
          <Form.Select
            required
            name="sex"
            onChange={handleSelect}
            value={values?.sex}
          >
            <option></option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="U">Other</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please choose valid Gender
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>Date of Birth</Form.Label>
          <Form.Control
            type="date"
            required
            name="dob"
            onChange={handleSelect}
            value={values?.dob}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid date.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>Passport No.</Form.Label>
          <Form.Control
            type="text"
            placeholder="Passport #"
            required
            name="passportNumber"
            onChange={handleSelect}
            value={values?.passportNumber}
          />
          <Form.Control.Feedback type="invalid">
            This field can't be empty
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustomUsername">
          <Form.Label>Country of Passport</Form.Label>
          <Form.Select
            required
            name="countryOfPassport"
            onChange={handleSelect}
            value={values?.countryOfPassport}
          >
            {countryOption()}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please choose valid Country
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>Nationality of Passport</Form.Label>
          <Form.Select
            required
            name="nationalityOfPassportHolder"
            onChange={handleSelect}
            value={values?.nationalityOfPassportHolder}
          >
            {countryOption()}{" "}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please choose valid Country
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>Date of Issue</Form.Label>
          <Form.Control
            type="date"
            required
            name="dateOfIssue"
            onChange={handleSelect}
            value={values?.dateOfIssue}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid date.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>Date of Expiry</Form.Label>
          <Form.Control
            type="date"
            required
            name="dateOfExpiry"
            onChange={handleSelect}
            value={values?.dateOfExpiry}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid date.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label>Place of issue / issuing authority</Form.Label>
          <Form.Control
            type="text"
            required
            name="placeOfIssue"
            onChange={handleSelect}
            value={values?.placeOfIssue}
          />
          <Form.Control.Feedback type="invalid">
            This field can't be empty
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br></br>
      <h3>Place of Birth</h3>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>Town / City</Form.Label>
          <Form.Control
            type="text"
            placeholder="Town / City"
            required
            name="birthCity"
            value={values?.birthCity}
            onChange={handleSelect}
          />
          <Form.Control.Feedback type="invalid">
            This field can't be empty
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>State / Province</Form.Label>
          <Form.Control
            type="text"
            placeholder="State / Province"
            required
            name="birthState"
            value={values?.birthState}
            onChange={handleSelect}
          />
          <Form.Control.Feedback type="invalid">
            This field can't be empty
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>Country of Birth</Form.Label>
          <Form.Select
            aria-label="Country Select"
            required
            name="birthCountry"
            value={values?.birthCountry}
            onChange={handleSelect}
          >
            {countryOptionWithFourLetter()}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Please choose valid Country
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <br></br>
      <h3>Contact Information</h3>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="email"
            required
            name="email"
            value={values?.email}
            onChange={handleSelect}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Phone #"
            required
            pattern="[0-9]+"
            name="phone"
            minLength="9"
            value={values?.phone}
            onChange={handleSelect}
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid phone number
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      {!isEdit && (
        <Form.Group className="mb-3">
          <Form.Check
            required
            label="I have double checked all the information"
            feedbackType="invalid"
          />
        </Form.Group>
      )}
      <Button type="submit">{isEdit ? "Save" : "Submit form"}</Button>
    </Form>
  );
}

ImmigrationForm.propTypes = {
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleSelect: PropTypes.func,
  validated: PropTypes.bool,
  isClientFacing: PropTypes.bool,
  isEdit: PropTypes.bool,
};

ImmigrationForm.defaultProps = {
  values: {},
  isClientFacing: true,
  validated: false,
  isEdit: false,
};
