export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";

export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOGOUT_SUCCEEDED = "LOGOUT_SUCCEEDED";

export const FORGOT_PASSWORD_REQUESTED = "FORGOT_PASSWORD_REQUESTED";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_SUCCEEDED = "FORGOT_PASSWORD_SUCCEEDED";

export const PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED";
export const PASSWORD_RESET_FAILED = "PASSWORD_RESET__FAILED";
export const PASSWORD_RESET_SUCCEEDED = "PASSWORD_RESET__SUCCEEDED";

export const CLEAR_ERROR = "CLEAR_ERROR";

// Leave
export const LEAVE_GET_ALL_REQUESTED = "LEAVE_GET_ALL_REQUESTED";
export const LEAVE_GET_ALL_FAILED = "LEAVE_GET_ALL_FAILED";
export const LEAVE_GET_ALL_SUCCEEDED = "LEAVE_GET_ALL_SUCCEEDED";

export const LEAVE_CREATE_REQUESTED = "LEAVE_CREATE_REQUESTED";
export const LEAVE_CREATE_FAILED = "LEAVE_CREATE_FAILED";
export const LEAVE_CREATE_SUCCEEDED = "LEAVE_CREATE_SUCCEEDED";

export const LEAVE_UPDATE_REQUESTED = "LEAVE_UPDATE_REQUESTED";
export const LEAVE_UPDATE_FAILED = "LEAVE_UPDATE_FAILED";
export const LEAVE_UPDATE_SUCCEEDED = "LEAVE_UPDATE_SUCCEEDED";

export const LEAVE_GET_REQUESTED = "LEAVE_GET_REQUESTED";
export const LEAVE_GET_FAILED = "LEAVE_GET_FAILED";
export const LEAVE_GET_SUCCEEDED = "LEAVE_GET_SUCCEEDED";

export const LEAVE_GET_USER_REQUESTED = "LEAVE_GET_USER_REQUESTED";
export const LEAVE_GET_USER_FAILED = "LEAVE_GET_USER_FAILED";
export const LEAVE_GET_USER_SUCCEEDED = "LEAVE_GET_USER_SUCCEEDED";

// Immigration
export const IMMIGRATION_GET_ALL_REQUESTED = "IMMIGRATION_GET_ALL_REQUESTED";
export const IMMIGRATION_GET_ALL_FAILED = "IMMIGRATION_GET_ALL_FAILED";
export const IMMIGRATION_GET_ALL_SUCCEEDED = "IMMIGRATION_GET_ALL_SUCCEEDED";

export const IMMIGRATION_CREATE_REQUESTED = "IMMIGRATION_CREATE_REQUESTED";
export const IMMIGRATION_CREATE_FAILED = "IMMIGRATION_CREATE_FAILED";
export const IMMIGRATION_CREATE_SUCCEEDED = "IMMIGRATION_CREATE_SUCCEEDED";

export const IMMIGRATION_UPDATE_REQUESTED = "IMMIGRATION_UPDATE_REQUESTED";
export const IMMIGRATION_UPDATE_FAILED = "IMMIGRATION_UPDATE_FAILED";
export const IMMIGRATION_UPDATE_SUCCEEDED = "IMMIGRATION_UPDATE_SUCCEEDED";

// Agreement
export const AGREEMENT_GET_ALL_REQUESTED = "AGREEMENT_GET_ALL_REQUESTED";
export const AGREEMENT_GET_ALL_FAILED = "AGREEMENT_GET_ALL_FAILED";
export const AGREEMENT_GET_ALL_SUCCEEDED = "AGREEMENT_GET_ALL_SUCCEEDED";

export const AGREEMENT_CREATE_REQUESTED = "AGREEMENT_CREATE_REQUESTED";
export const AGREEMENT_CREATE_FAILED = "AGREEMENT_CREATE_FAILED";
export const AGREEMENT_CREATE_SUCCEEDED = "AGREEMENT_CREATE_SUCCEEDED";

export const AGREEMENT_UPDATE_REQUESTED = "AGREEMENT_UPDATE_REQUESTED";
export const AGREEMENT_UPDATE_FAILED = "AGREEMENT_UPDATE_FAILED";
export const AGREEMENT_UPDATE_SUCCEEDED = "AGREEMENT_UPDATE_SUCCEEDED";

// Clock In Out
export const CLOCK_GET_REQUESTED = "CLOCK_GET_REQUESTED";
export const CLOCK_GET_FAILED = "CLOCK_GET_FAILED";
export const CLOCK_GET_SUCCEEDED = "CLOCK_GET_SUCCEEDED";

export const CLOCK_LOGIN_REQUESTED = "CLOCK_LOGIN_REQUESTED";
export const CLOCK_LOGIN_FAILED = "CLOCK_LOGIN_FAILED";
export const CLOCK_LOGIN_SUCCEEDED = "CLOCK_LOGIN_SUCCEEDED";

export const CLOCK_IN_REQUESTED = "CLOCK_IN_REQUESTED";
export const CLOCK_IN_FAILED = "CLOCK_IN_FAILED";
export const CLOCK_IN_SUCCEEDED = "CLOCK_IN_SUCCEEDED";

export const CLOCK_OUT_REQUESTED = "CLOCK_OUT_REQUESTED";
export const CLOCK_OUT_FAILED = "CLOCK_OUT_FAILED";
export const CLOCK_OUT_SUCCEEDED = "CLOCK_OUT_SUCCEEDED";

export const CLOCK_BREAK_START_REQUESTED = "CLOCK_BREAK_START_REQUESTED";
export const CLOCK_BREAK_START_FAILED = "CLOCK_BREAK_START_FAILED";
export const CLOCK_BREAK_START_SUCCEEDED = "CLOCK_BREAK_START_SUCCEEDED";

export const CLOCK_BREAK_END_REQUESTED = "CLOCK_BREAK_END_REQUESTED";
export const CLOCK_BREAK_END_FAILED = "CLOCK_BREAK_END_FAILED";
export const CLOCK_BREAK_END_SUCCEEDED = "CLOCK_BREAK_END_SUCCEEDED";

export const CLOCK_IN_UPDATE_REQUESTED = "CLOCK_IN_UPDATE_REQUESTED";
export const CLOCK_IN_UPDATE_FAILED = "CLOCK_IN_UPDATE_FAILED";
export const CLOCK_IN_UPDATE_SUCCEEDED = "CLOCK_IN_UPDATE_SUCCEEDED";

// EMAIL SMS -----------------------------------------------------
export const CUSTOM_SMS_REQUESTED = "CUSTOM_SMS_REQUESTED";
export const CUSTOM_SMS_FAILED = "CUSTOM_SMS_FAILED";
export const CUSTOM_SMS_SUCCEEDED = "CUSTOM_SMS_SUCCEEDED";

export const CUSTOM_EMAIL_REQUESTED = "CUSTOM_EMAIL_REQUESTED";
export const CUSTOM_EMAIL_FAILED = "CUSTOM_EMAIL_FAILED";
export const CUSTOM_EMAIL_SUCCEEDED = "CUSTOM_EMAIL_SUCCEEDED";

export const TEMPLATE_SMS_REQUESTED = "TEMPLATE_SMS_REQUESTED";
export const TEMPLATE_SMS_FAILED = "TEMPLATE_SMS_FAILED";
export const TEMPLATE_SMS_SUCCEEDED = "TEMPLATE_SMS_SUCCEEDED";

export const TEMPLATE_EMAIL_REQUESTED = "TEMPLATE_EMAIL_REQUESTED";
export const TEMPLATE_EMAIL_FAILED = "TEMPLATE_EMAIL_FAILED";
export const TEMPLATE_EMAIL_SUCCEEDED = "TEMPLATE_EMAIL_SUCCEEDED";

// SETTING -----------------------------------------------------
export const SETTING_UPDATE_REQUESTED = "SETTING_UPDATE_REQUESTED";
export const SETTING_UPDATE_FAILED = "SETTING_UPDATE_FAILED";
export const SETTING_UPDATE_SUCCEEDED = "SETTING_UPDATE_SUCCEEDED";

export const SETTING_GET_REQUESTED = "SETTING_GET_REQUESTED";
export const SETTING_GET_FAILED = "SETTING_GET_FAILED";
export const SETTING_GET_SUCCEEDED = "SETTING_GET_SUCCEEDED";

// MY_PROFILE -----------------------------------------------------
export const PROFILE_UPDATE_REQUESTED = "PROFILE_UPDATE_REQUESTED";
export const PROFILE_UPDATE_FAILED = "PROFILE_UPDATE_FAILED";
export const PROFILE_UPDATE_SUCCEEDED = "PROFILE_UPDATE_SUCCEEDED";

export const PROFILE_GET_REQUESTED = "PROFILE_GET_REQUESTED";
export const PROFILE_GET_FAILED = "PROFILE_GET_FAILED";
export const PROFILE_GET_SUCCEEDED = "PROFILE_GET_SUCCEEDED";

// TODO -----------------------------------------------------
export const TODO_COUNT_GET_REQUESTED = "TODO_COUNT_GET_REQUESTED";
export const TODO_COUNT_GET_FAILED = "TODO_COUNT_GET_FAILED";
export const TODO_COUNT_GET_SUCCEEDED = "TODO_COUNT_GET_SUCCEEDED";

export const TODO_DELETE_REQUESTED = "TODO_DELETE_REQUESTED";
export const TODO_DELETE_FAILED = "TODO_DELETE_FAILED";
export const TODO_DELETE_SUCCEEDED = "TODO_DELETE_SUCCEEDED";

export const TODO_CREATE_REQUESTED = "TODO_CREATE_REQUESTED";
export const TODO_CREATE_FAILED = "TODO_CREATE_FAILED";
export const TODO_CREATE_SUCCEEDED = "TODO_CREATE_SUCCEEDED";

export const TODO_UPDATE_REQUESTED = "TODO_UPDATE_REQUESTED";
export const TODO_UPDATE_FAILED = "TODO_UPDATE_FAILED";
export const TODO_UPDATE_SUCCEEDED = "TODO_UPDATE_SUCCEEDED";

export const TODO_UPDATE_ALL_REQUESTED = "TODO_UPDATE_ALL_REQUESTED";
export const TODO_UPDATE_ALL_SUCCEEDED = "TODO_UPDATE_ALL_SUCCEEDED";
export const TODO_UPDATE_ALL_FAILED = "TODO_UPDATE_ALL_FAILED";

export const TODO_GET_REQUESTED = "TODO_GET_REQUESTED";
export const TODO_GET_FAILED = "TODO_GET_FAILED";
export const TODO_GET_SUCCEEDED = "TODO_GET_SUCCEEDED";

export const TODO_GET_ALL_REQUESTED = "TODO_GET_ALL_REQUESTED";
export const TODO_GET_ALL_FAILED = "TODO_GET_ALL_FAILED";
export const TODO_GET_ALL_SUCCEEDED = "TODO_GET_ALL_SUCCEEDED";

// APPOINTMENT -----------------------------------------------------
export const APPOINTMENT_MY_GET_REQUESTED = "APPOINTMENT_MY_GET_REQUESTED";
export const APPOINTMENT_MY_GET_FAILED = "APPOINTMENT_MY_GET_FAILED";
export const APPOINTMENT_MY_GET_SUCCEEDED = "APPOINTMENT_MY_GET_SUCCEEDED";

export const APPOINTMENT_DELETE_REQUESTED = "APPOINTMENT_DELETE_REQUESTED";
export const APPOINTMENT_DELETE_FAILED = "APPOINTMENT_DELETE_FAILED";
export const APPOINTMENT_DELETE_SUCCEEDED = "APPOINTMENT_DELETE_SUCCEEDED";

export const APPOINTMENT_CREATE_REQUESTED = "APPOINTMENT_CREATE_REQUESTED";
export const APPOINTMENT_CREATE_FAILED = "APPOINTMENT_CREATE_FAILED";
export const APPOINTMENT_CREATE_SUCCEEDED = "APPOINTMENT_CREATE_SUCCEEDED";

export const APPOINTMENT_UPDATE_REQUESTED = "APPOINTMENT_UPDATE_REQUESTED";
export const APPOINTMENT_UPDATE_FAILED = "APPOINTMENT_UPDATE_FAILED";
export const APPOINTMENT_UPDATE_SUCCEEDED = "APPOINTMENT_UPDATE_SUCCEEDED";

export const APPOINTMENT_GET_REQUESTED = "APPOINTMENT_GET_REQUESTED";
export const APPOINTMENT_GET_FAILED = "APPOINTMENT_GET_FAILED";
export const APPOINTMENT_GET_SUCCEEDED = "APPOINTMENT_GET_SUCCEEDED";

export const APPOINTMENT_GET_ALL_REQUESTED = "APPOINTMENT_GET_ALL_REQUESTED";
export const APPOINTMENT_GET_ALL_FAILED = "APPOINTMENT_GET_ALL_FAILED";
export const APPOINTMENT_GET_ALL_SUCCEEDED = "APPOINTMENT_GET_ALL_SUCCEEDED";

// FiscalReport -----------------------------------------------------
export const REPORT_CREATE_REQUESTED = "REPORT_CREATE_REQUESTED";
export const REPORT_CREATE_FAILED = "REPORT_CREATE_FAILED";
export const REPORT_CREATE_SUCCEEDED = "REPORT_CREATE_SUCCEEDED";

export const REPORT_REFRESH_REQUESTED = "REPORT_REFRESH_REQUESTED";
export const REPORT_REFRESH_FAILED = "REPORT_REFRESH_FAILED";
export const REPORT_REFRESH_SUCCEEDED = "REPORT_REFRESH_SUCCEEDED";

export const REPORT_UPDATE_REQUESTED = "REPORT_UPDATE_REQUESTED";
export const REPORT_UPDATE_FAILED = "REPORT_UPDATE_FAILED";
export const REPORT_UPDATE_SUCCEEDED = "REPORT_UPDATE_SUCCEEDED";
export const REPORT_UPDATE_FETCHING = "REPORT_UPDATE_FETCHING";

export const REPORT_GET_REQUESTED = "REPORT_GET_REQUESTED";
export const REPORT_GET_FAILED = "REPORT_GET_FAILED";
export const REPORT_GET_SUCCEEDED = "REPORT_GET_SUCCEEDED";

export const REPORT_GET_ALL_REQUESTED = "REPORT_GET_ALL_REQUESTED";
export const REPORT_GET_ALL_FAILED = "REPORT_GET_ALL_FAILED";
export const REPORT_GET_ALL_SUCCEEDED = "REPORT_GET_ALL_SUCCEEDED";

// USER -----------------------------------------------------
export const PROFILE_CODE_GENERATION_REQUESTED =
  "PROFILE_CODE_GENERATION_REQUESTED";
export const PROFILE_CODE_GENERATION_FAILED = "PROFILE_CODE_GENERATION_FAILED";
export const PROFILE_CODE_GENERATION_SUCCEEDED =
  "PROFILE_CODE_GENERATION_SUCCEEDED";

export const USER_CREATE_REQUESTED = "USER_CREATE_REQUESTED";
export const USER_CREATE_FAILED = "USER_CREATE_FAILED";
export const USER_CREATE_SUCCEEDED = "USER_CREATE_SUCCEEDED";

export const USER_UPDATE_REQUESTED = "USER_UPDATE_REQUESTED";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";
export const USER_UPDATE_SUCCEEDED = "USER_UPDATE_SUCCEEDED";
export const USER_UPDATE_FETCHING = "USER_UPDATE_FETCHING";

export const USER_GET_REQUESTED = "USER_GET_REQUESTED";
export const USER_GET_FAILED = "USER_GET_FAILED";
export const USER_GET_SUCCEEDED = "USER_GET_SUCCEEDED";

export const USER_GET_ALL_REQUESTED = "USER_GET_ALL_REQUESTED";
export const USER_GET_ALL_FAILED = "USER_GET_ALL_FAILED";
export const USER_GET_ALL_SUCCEEDED = "USER_GET_ALL_SUCCEEDED";

export const GET_ALL_SERVICE_DATA_REQUESTED = "GET_ALL_SERVICE_DATA_REQUESTED";
export const GET_ALL_SERVICE_DATA_FAILED = "GET_ALL_SERVICE_DATA_FAILED";
export const GET_ALL_SERVICE_DATA_SUCCEEDED = "GET_ALL_SERVICE_DATA_SUCCEEDED";

// LEAD -----------------------------------------------------
export const LEAD_CREATE_REQUESTED = "LEAD_CREATE_REQUESTED";
export const LEAD_CREATE_FAILED = "LEAD_CREATE_FAILED";
export const LEAD_CREATE_SUCCEEDED = "LEAD_CREATE_SUCCEEDED";

export const LEAD_UPDATE_REQUESTED = "LEAD_UPDATE_REQUESTED";
export const LEAD_UPDATE_FAILED = "LEAD_UPDATE_FAILED";
export const LEAD_UPDATE_SUCCEEDED = "LEAD_UPDATE_SUCCEEDED";

export const LEAD_GET_REQUESTED = "LEAD_GET_REQUESTED";
export const LEAD_GET_FAILED = "LEAD_GET_FAILED";
export const LEAD_GET_SUCCEEDED = "LEAD_GET_SUCCEEDED";

export const LEAD_LOG_GET_REQUESTED = "LEAD_LOG_GET_REQUESTED";
export const LEAD_LOG_GET_FAILED = "LEAD_LOG_GET_FAILED";
export const LEAD_LOG_GET_SUCCEEDED = "LEAD_LOG_GET_SUCCEEDED";

export const LEAD_GET_ALL_REQUESTED = "LEAD_GET_ALL_REQUESTED";
export const LEAD_GET_ALL_FAILED = "LEAD_GET_ALL_FAILED";
export const LEAD_GET_ALL_SUCCEEDED = "LEAD_GET_ALL_SUCCEEDED";

export const LEAD_SELF_ASSIGNED_REQUESTED = "LEAD_SELF_ASSIGNED_REQUESTED";
export const LEAD_SELF_ASSIGNED_FAILED = "LEAD_SELF_ASSIGNED_FAILED";
export const LEAD_SELF_ASSIGNED_SUCCEEDED = "LEAD_SELF_ASSIGNED_SUCCEEDED";

export const LEAD_DELETE_REQUESTED = "LEAD_DELETE_REQUESTED";
export const LEAD_DELETE_FAILED = "LEAD_DELETE_FAILED";
export const LEAD_DELETE_SUCCEEDED = "LEAD_DELETE_SUCCEEDED";

// STUDENT -----------------------------------------------------
export const STUDENT_CREATE_REQUESTED = "STUDENT_CREATE_REQUESTED";
export const STUDENT_CREATE_FAILED = "STUDENT_CREATE_FAILED";
export const STUDENT_CREATE_SUCCEEDED = "STUDENT_CREATE_SUCCEEDED";

export const STUDENT_UPDATE_REQUESTED = "STUDENT_UPDATE_REQUESTED";
export const STUDENT_UPDATE_FAILED = "STUDENT_UPDATE_FAILED";
export const STUDENT_UPDATE_SUCCEEDED = "STUDENT_UPDATE_SUCCEEDED";

export const STUDENT_GET_REQUESTED = "STUDENT_GET_REQUESTED";
export const STUDENT_GET_FAILED = "STUDENT_GET_FAILED";
export const STUDENT_GET_SUCCEEDED = "STUDENT_GET_SUCCEEDED";

export const STUDENT_LOG_GET_REQUESTED = "STUDENT_LOG_GET_REQUESTED";
export const STUDENT_LOG_GET_FAILED = "STUDENT_LOG_GET_FAILED";
export const STUDENT_LOG_GET_SUCCEEDED = "STUDENT_LOG_GET_SUCCEEDED";

export const STUDENT_GET_ALL_REQUESTED = "STUDENT_GET_ALL_REQUESTED";
export const STUDENT_GET_ALL_FAILED = "STUDENT_GET_ALL_FAILED";
export const STUDENT_GET_ALL_SUCCEEDED = "STUDENT_GET_ALL_SUCCEEDED";

export const STUDENT_DELETE_REQUESTED = "STUDENT_DELETE_REQUESTED";
export const STUDENT_DELETE_FAILED = "STUDENT_DELETE_FAILED";
export const STUDENT_DELETE_SUCCEEDED = "STUDENT_DELETE_SUCCEEDED";

// SKILL_ASSESSMENT -----------------------------------------------------
export const SKILL_ASSESSMENT_CREATE_REQUESTED =
  "SKILL_ASSESSMENT_CREATE_REQUESTED";
export const SKILL_ASSESSMENT_CREATE_FAILED = "SKILL_ASSESSMENT_CREATE_FAILED";
export const SKILL_ASSESSMENT_CREATE_SUCCEEDED =
  "SKILL_ASSESSMENT_CREATE_SUCCEEDED";

export const SKILL_ASSESSMENT_UPDATE_REQUESTED =
  "SKILL_ASSESSMENT_UPDATE_REQUESTED";
export const SKILL_ASSESSMENT_UPDATE_FAILED = "SKILL_ASSESSMENT_UPDATE_FAILED";
export const SKILL_ASSESSMENT_UPDATE_SUCCEEDED =
  "SKILL_ASSESSMENT_UPDATE_SUCCEEDED";

export const SKILL_ASSESSMENT_GET_REQUESTED = "SKILL_ASSESSMENT_GET_REQUESTED";
export const SKILL_ASSESSMENT_GET_FAILED = "SKILL_ASSESSMENT_GET_FAILED";
export const SKILL_ASSESSMENT_GET_SUCCEEDED = "SKILL_ASSESSMENT_GET_SUCCEEDED";

export const SKILL_ASSESSMENT_LOG_GET_REQUESTED =
  "SKILL_ASSESSMENT_LOG_GET_REQUESTED";
export const SKILL_ASSESSMENT_LOG_GET_FAILED =
  "SKILL_ASSESSMENT_LOG_GET_FAILED";
export const SKILL_ASSESSMENT_LOG_GET_SUCCEEDED =
  "SKILL_ASSESSMENT_LOG_GET_SUCCEEDED";

export const SKILL_ASSESSMENT_GET_ALL_REQUESTED =
  "SKILL_ASSESSMENT_GET_ALL_REQUESTED";
export const SKILL_ASSESSMENT_GET_ALL_FAILED =
  "SKILL_ASSESSMENT_GET_ALL_FAILED";
export const SKILL_ASSESSMENT_GET_ALL_SUCCEEDED =
  "SKILL_ASSESSMENT_GET_ALL_SUCCEEDED";

export const SKILL_ASSESSMENT_DELETE_REQUESTED =
  "SKILL_ASSESSMENT_DELETE_REQUESTED";
export const SKILL_ASSESSMENT_DELETE_FAILED = "SKILL_ASSESSMENT_DELETE_FAILED";
export const SKILL_ASSESSMENT_DELETE_SUCCEEDED =
  "SKILL_ASSESSMENT_DELETE_SUCCEEDED";

// VISA_APPLICANT -----------------------------------------------------
export const VISA_APPLICANT_CREATE_REQUESTED =
  "VISA_APPLICANT_CREATE_REQUESTED";
export const VISA_APPLICANT_CREATE_FAILED = "VISA_APPLICANT_CREATE_FAILED";
export const VISA_APPLICANT_CREATE_SUCCEEDED =
  "VISA_APPLICANT_CREATE_SUCCEEDED";

export const VISA_APPLICANT_UPDATE_REQUESTED =
  "VISA_APPLICANT_UPDATE_REQUESTED";
export const VISA_APPLICANT_UPDATE_FAILED = "VISA_APPLICANT_UPDATE_FAILED";
export const VISA_APPLICANT_UPDATE_SUCCEEDED =
  "VISA_APPLICANT_UPDATE_SUCCEEDED";

export const VISA_APPLICANT_GET_REQUESTED = "VISA_APPLICANT_GET_REQUESTED";
export const VISA_APPLICANT_GET_FAILED = "VISA_APPLICANT_GET_FAILED";
export const VISA_APPLICANT_GET_SUCCEEDED = "VISA_APPLICANT_GET_SUCCEEDED";

export const VISA_APPLICANT_LOG_GET_REQUESTED =
  "VISA_APPLICANT_LOG_GET_REQUESTED";
export const VISA_APPLICANT_LOG_GET_FAILED = "VISA_APPLICANT_LOG_GET_FAILED";
export const VISA_APPLICANT_LOG_GET_SUCCEEDED =
  "VISA_APPLICANT_LOG_GET_SUCCEEDED";

export const VISA_APPLICANT_GET_ALL_REQUESTED =
  "VISA_APPLICANT_GET_ALL_REQUESTED";
export const VISA_APPLICANT_GET_ALL_FAILED = "VISA_APPLICANT_GET_ALL_FAILED";
export const VISA_APPLICANT_GET_ALL_SUCCEEDED =
  "VISA_APPLICANT_GET_ALL_SUCCEEDED";

export const VISA_APPLICANT_DELETE_REQUESTED =
  "VISA_APPLICANT_DELETE_REQUESTED";
export const VISA_APPLICANT_DELETE_FAILED = "VISA_APPLICANT_DELETE_FAILED";
export const VISA_APPLICANT_DELETE_SUCCEEDED =
  "VISA_APPLICANT_DELETE_SUCCEEDED";

// VISA -----------------------------------------------------
export const VISA_CREATE_REQUESTED = "VISA_CREATE_REQUESTED";
export const VISA_CREATE_FAILED = "VISA_CREATE_FAILED";
export const VISA_CREATE_SUCCEEDED = "VISA_CREATE_SUCCEEDED";

export const VISA_UPDATE_REQUESTED = "VISA_UPDATE_REQUESTED";
export const VISA_UPDATE_FAILED = "VISA_UPDATE_FAILED";
export const VISA_UPDATE_SUCCEEDED = "VISA_UPDATE_SUCCEEDED";

export const VISA_GET_REQUESTED = "VISA_GET_REQUESTED";
export const VISA_GET_FAILED = "VISA_GET_FAILED";
export const VISA_GET_SUCCEEDED = "VISA_GET_SUCCEEDED";

export const VISA_LOG_GET_REQUESTED = "VISA_LOG_GET_REQUESTED";
export const VISA_LOG_GET_FAILED = "VISA_LOG_GET_FAILED";
export const VISA_LOG_GET_SUCCEEDED = "VISA_LOG_GET_SUCCEEDED";

export const VISA_GET_ALL_REQUESTED = "VISA_GET_ALL_REQUESTED";
export const VISA_GET_ALL_FAILED = "VISA_GET_ALL_FAILED";
export const VISA_GET_ALL_SUCCEEDED = "VISA_GET_ALL_SUCCEEDED";

export const VISA_DELETE_REQUESTED = "VISA_DELETE_REQUESTED";
export const VISA_DELETE_FAILED = "VISA_DELETE_FAILED";
export const VISA_DELETE_SUCCEEDED = "VISA_DELETE_SUCCEEDED";

// OCCUPATION -----------------------------------------------------
export const OCCUPATION_CREATE_REQUESTED = "OCCUPATION_CREATE_REQUESTED";
export const OCCUPATION_CREATE_FAILED = "OCCUPATION_CREATE_FAILED";
export const OCCUPATION_CREATE_SUCCEEDED = "OCCUPATION_CREATE_SUCCEEDED";

export const OCCUPATION_UPDATE_REQUESTED = "OCCUPATION_UPDATE_REQUESTED";
export const OCCUPATION_UPDATE_FAILED = "OCCUPATION_UPDATE_FAILED";
export const OCCUPATION_UPDATE_SUCCEEDED = "OCCUPATION_UPDATE_SUCCEEDED";

export const OCCUPATION_GET_REQUESTED = "OCCUPATION_GET_REQUESTED";
export const OCCUPATION_GET_FAILED = "OCCUPATION_GET_FAILED";
export const OCCUPATION_GET_SUCCEEDED = "OCCUPATION_GET_SUCCEEDED";

export const OCCUPATION_LOG_GET_REQUESTED = "OCCUPATION_LOG_GET_REQUESTED";
export const OCCUPATION_LOG_GET_FAILED = "OCCUPATION_LOG_GET_FAILED";
export const OCCUPATION_LOG_GET_SUCCEEDED = "OCCUPATION_LOG_GET_SUCCEEDED";

export const OCCUPATION_GET_ALL_REQUESTED = "OCCUPATION_GET_ALL_REQUESTED";
export const OCCUPATION_GET_ALL_FAILED = "OCCUPATION_GET_ALL_FAILED";
export const OCCUPATION_GET_ALL_SUCCEEDED = "OCCUPATION_GET_ALL_SUCCEEDED";

export const OCCUPATION_DELETE_REQUESTED = "OCCUPATION_DELETE_REQUESTED";
export const OCCUPATION_DELETE_FAILED = "OCCUPATION_DELETE_FAILED";
export const OCCUPATION_DELETE_SUCCEEDED = "OCCUPATION_DELETE_SUCCEEDED";

// ANNOUNCEMENT -----------------------------------------------------
export const ANNOUNCEMENT_CREATE_REQUESTED = "ANNOUNCEMENT_CREATE_REQUESTED";
export const ANNOUNCEMENT_CREATE_FAILED = "ANNOUNCEMENT_CREATE_FAILED";
export const ANNOUNCEMENT_CREATE_SUCCEEDED = "ANNOUNCEMENT_CREATE_SUCCEEDED";

export const ANNOUNCEMENT_UPDATE_REQUESTED = "ANNOUNCEMENT_UPDATE_REQUESTED";
export const ANNOUNCEMENT_UPDATE_FAILED = "ANNOUNCEMENT_UPDATE_FAILED";
export const ANNOUNCEMENT_UPDATE_SUCCEEDED = "ANNOUNCEMENT_UPDATE_SUCCEEDED";

export const ANNOUNCEMENT_GET_REQUESTED = "ANNOUNCEMENT_GET_REQUESTED";
export const ANNOUNCEMENT_GET_FAILED = "ANNOUNCEMENT_GET_FAILED";
export const ANNOUNCEMENT_GET_SUCCEEDED = "ANNOUNCEMENT_GET_SUCCEEDED";

export const ANNOUNCEMENT_LOG_GET_REQUESTED = "ANNOUNCEMENT_LOG_GET_REQUESTED";
export const ANNOUNCEMENT_LOG_GET_FAILED = "ANNOUNCEMENT_LOG_GET_FAILED";
export const ANNOUNCEMENT_LOG_GET_SUCCEEDED = "ANNOUNCEMENT_LOG_GET_SUCCEEDED";

export const ANNOUNCEMENT_GET_ALL_REQUESTED = "ANNOUNCEMENT_GET_ALL_REQUESTED";
export const ANNOUNCEMENT_GET_ALL_FAILED = "ANNOUNCEMENT_GET_ALL_FAILED";
export const ANNOUNCEMENT_GET_ALL_SUCCEEDED = "ANNOUNCEMENT_GET_ALL_SUCCEEDED";

export const ANNOUNCEMENT_DELETE_REQUESTED = "ANNOUNCEMENT_DELETE_REQUESTED";
export const ANNOUNCEMENT_DELETE_FAILED = "ANNOUNCEMENT_DELETE_FAILED";
export const ANNOUNCEMENT_DELETE_SUCCEEDED = "ANNOUNCEMENT_DELETE_SUCCEEDED";

// INSURANCE_APPLICANT -----------------------------------------------------
export const INSURANCE_APPLICANT_CREATE_REQUESTED =
  "INSURANCE_APPLICANT_CREATE_REQUESTED";
export const INSURANCE_APPLICANT_CREATE_FAILED =
  "INSURANCE_APPLICANT_CREATE_FAILED";
export const INSURANCE_APPLICANT_CREATE_SUCCEEDED =
  "INSURANCE_APPLICANT_CREATE_SUCCEEDED";

export const INSURANCE_APPLICANT_UPDATE_REQUESTED =
  "INSURANCE_APPLICANT_UPDATE_REQUESTED";
export const INSURANCE_APPLICANT_UPDATE_FAILED =
  "INSURANCE_APPLICANT_UPDATE_FAILED";
export const INSURANCE_APPLICANT_UPDATE_SUCCEEDED =
  "INSURANCE_APPLICANT_UPDATE_SUCCEEDED";

export const INSURANCE_APPLICANT_GET_REQUESTED =
  "INSURANCE_APPLICANT_GET_REQUESTED";
export const INSURANCE_APPLICANT_GET_FAILED = "INSURANCE_APPLICANT_GET_FAILED";
export const INSURANCE_APPLICANT_GET_SUCCEEDED =
  "INSURANCE_APPLICANT_GET_SUCCEEDED";

export const INSURANCE_APPLICANT_LOG_GET_REQUESTED =
  "INSURANCE_APPLICANT_LOG_GET_REQUESTED";
export const INSURANCE_APPLICANT_LOG_GET_FAILED =
  "INSURANCE_APPLICANT_LOG_GET_FAILED";
export const INSURANCE_APPLICANT_LOG_GET_SUCCEEDED =
  "INSURANCE_APPLICANT_LOG_GET_SUCCEEDED";

export const INSURANCE_APPLICANT_GET_ALL_REQUESTED =
  "INSURANCE_APPLICANT_GET_ALL_REQUESTED";
export const INSURANCE_APPLICANT_GET_ALL_FAILED =
  "INSURANCE_APPLICANT_GET_ALL_FAILED";
export const INSURANCE_APPLICANT_GET_ALL_SUCCEEDED =
  "INSURANCE_APPLICANT_GET_ALL_SUCCEEDED";

export const INSURANCE_APPLICANT_DELETE_REQUESTED =
  "INSURANCE_APPLICANT_DELETE_REQUESTED";
export const INSURANCE_APPLICANT_DELETE_FAILED =
  "INSURANCE_APPLICANT_DELETE_FAILED";
export const INSURANCE_APPLICANT_DELETE_SUCCEEDED =
  "INSURANCE_APPLICANT_DELETE_SUCCEEDED";

// INSURANCE_TYPE -----------------------------------------------------
export const INSURANCE_TYPE_GET_REQUESTED = "INSURANCE_TYPE_GET_REQUESTED";
export const INSURANCE_TYPE_GET_FAILED = "INSURANCE_TYPE_GET_FAILED";
export const INSURANCE_TYPE_GET_SUCCEEDED = "INSURANCE_TYPE_GET_SUCCEEDED";

export const INSURANCE_TYPE_GET_ALL_REQUESTED =
  "INSURANCE_TYPE_GET_ALL_REQUESTED";
export const INSURANCE_TYPE_GET_ALL_FAILED = "INSURANCE_TYPE_GET_ALL_FAILED";
export const INSURANCE_TYPE_GET_ALL_SUCCEEDED =
  "INSURANCE_TYPE_GET_ALL_SUCCEEDED";

// INSURANCE_PROVIDER -----------------------------------------------------
export const INSURANCE_PROVIDER_GET_REQUESTED =
  "INSURANCE_PROVIDER_GET_REQUESTED";
export const INSURANCE_PROVIDER_GET_FAILED = "INSURANCE_PROVIDER_GET_FAILED";
export const INSURANCE_PROVIDER_GET_SUCCEEDED =
  "INSURANCE_PROVIDER_GET_SUCCEEDED";

export const INSURANCE_PROVIDER_GET_ALL_REQUESTED =
  "INSURANCE_PROVIDER_GET_ALL_REQUESTED";
export const INSURANCE_PROVIDER_GET_ALL_FAILED =
  "INSURANCE_PROVIDER_GET_ALL_FAILED";
export const INSURANCE_PROVIDER_GET_ALL_SUCCEEDED =
  "INSURANCE_PROVIDER_GET_ALL_SUCCEEDED";

// University -----------------------------------------------------
export const UNIVERSITY_CREATE_REQUESTED = "UNIVERSITY_CREATE_REQUESTED";
export const UNIVERSITY_CREATE_FAILED = "UNIVERSITY_CREATE_FAILED";
export const UNIVERSITY_CREATE_SUCCEEDED = "UNIVERSITY_CREATE_SUCCEEDED";

export const UNIVERSITY_UPDATE_REQUESTED = "UNIVERSITY_UPDATE_REQUESTED";
export const UNIVERSITY_UPDATE_FAILED = "UNIVERSITY_UPDATE_FAILED";
export const UNIVERSITY_UPDATE_SUCCEEDED = "UNIVERSITY_UPDATE_SUCCEEDED";

export const UNIVERSITY_GET_REQUESTED = "UNIVERSITY_GET_REQUESTED";
export const UNIVERSITY_GET_FAILED = "UNIVERSITY_GET_FAILED";
export const UNIVERSITY_GET_SUCCEEDED = "UNIVERSITY_GET_SUCCEEDED";

export const UNIVERSITY_GET_ALL_REQUESTED = "UNIVERSITY_GET_ALL_REQUESTED";
export const UNIVERSITY_GET_ALL_FAILED = "UNIVERSITY_GET_ALL_FAILED";
export const UNIVERSITY_GET_ALL_SUCCEEDED = "UNIVERSITY_GET_ALL_SUCCEEDED";

// SOURCE -----------------------------------------------------
export const SOURCE_CREATE_REQUESTED = "SOURCE_CREATE_REQUESTED";
export const SOURCE_CREATE_FAILED = "SOURCE_CREATE_FAILED";
export const SOURCE_CREATE_SUCCEEDED = "SOURCE_CREATE_SUCCEEDED";

export const SOURCE_UPDATE_REQUESTED = "SOURCE_UPDATE_REQUESTED";
export const SOURCE_UPDATE_FAILED = "SOURCE_UPDATE_FAILED";
export const SOURCE_UPDATE_SUCCEEDED = "SOURCE_UPDATE_SUCCEEDED";

export const SOURCE_GET_REQUESTED = "SOURCE_GET_REQUESTED";
export const SOURCE_GET_FAILED = "SOURCE_GET_FAILED";
export const SOURCE_GET_SUCCEEDED = "SOURCE_GET_SUCCEEDED";

export const SOURCE_GET_ALL_REQUESTED = "SOURCE_GET_ALL_REQUESTED";
export const SOURCE_GET_ALL_FAILED = "SOURCE_GET_ALL_FAILED";
export const SOURCE_GET_ALL_SUCCEEDED = "SOURCE_GET_ALL_SUCCEEDED";

// COURSE -----------------------------------------------------
export const COURSE_CREATE_REQUESTED = "COURSE_CREATE_REQUESTED";
export const COURSE_CREATE_FAILED = "COURSE_CREATE_FAILED";
export const COURSE_CREATE_SUCCEEDED = "COURSE_CREATE_SUCCEEDED";

export const COURSE_BULK_CREATE_REQUESTED = "COURSE_BULK_CREATE_REQUESTED";
export const COURSE_BULK_CREATE_FAILED = "COURSE_BULK_CREATE_FAILED";
export const COURSE_BULK_CREATE_SUCCEEDED = "COURSE_BULK_CREATE_SUCCEEDED";

export const COURSE_UPDATE_REQUESTED = "COURSE_UPDATE_REQUESTED";
export const COURSE_UPDATE_FAILED = "COURSE_UPDATE_FAILED";
export const COURSE_UPDATE_SUCCEEDED = "COURSE_UPDATE_SUCCEEDED";

export const COURSE_GET_REQUESTED = "COURSE_GET_REQUESTED";
export const COURSE_GET_FAILED = "COURSE_GET_FAILED";
export const COURSE_GET_SUCCEEDED = "COURSE_GET_SUCCEEDED";

export const COURSE_GET_ALL_REQUESTED = "COURSE_GET_ALL_REQUESTED";
export const COURSE_GET_ALL_FAILED = "COURSE_GET_ALL_FAILED";
export const COURSE_GET_ALL_SUCCEEDED = "COURSE_GET_ALL_SUCCEEDED";

// PUBLIC -----------------------------------------------------
export const PUBLIC_LEAD_CREATE_REQUESTED = "PUBLIC_LEAD_CREATE_REQUESTED";
export const PUBLIC_LEAD_CREATE_FAILED = "PUBLIC_LEAD_CREATE_FAILED";
export const PUBLIC_LEAD_CREATE_SUCCEEDED = "PUBLIC_LEAD_CREATE_SUCCEEDED";

export const LEAD_SEARCH_REQUESTED = "LEAD_SEARCH_REQUESTED";
export const LEAD_SEARCH_FAILED = "LEAD_SEARCH_FAILED";
export const LEAD_SEARCH_SUCCEEDED = "LEAD_SEARCH_SUCCEEDED";

export const CLIENT_COUNTRY_GET_REQUESTED = "CLIENT_COUNTRY_GET_REQUESTED";
export const CLIENT_COUNTRY_GET_FAILED = "CLIENT_COUNTRY_GET_FAILED";
export const CLIENT_COUNTRY_GET_SUCCEEDED = "CLIENT_COUNTRY_GET_SUCCEEDED";

export const STUDENT_ANALYTICS_GET_REQUESTED =
  "STUDENT_ANALYTICS_GET_REQUESTED";
export const STUDENT_ANALYTICS_GET_FAILED = "STUDENT_ANALYTICS_GET_FAILED";
export const STUDENT_ANALYTICS_GET_SUCCEEDED =
  "STUDENT_ANALYTICS_GET_SUCCEEDED";

export const VISA_ANALYTICS_GET_REQUESTED = "VISA_ANALYTICS_GET_REQUESTED";
export const VISA_ANALYTICS_GET_FAILED = "VISA_ANALYTICS_GET_FAILED";
export const VISA_ANALYTICS_GET_SUCCEEDED = "VISA_ANALYTICS_GET_SUCCEEDED";

export const SKILL_ANALYTICS_GET_REQUESTED = "SKILL_ANALYTICS_GET_REQUESTED";
export const SKILL_ANALYTICS_GET_FAILED = "SKILL_ANALYTICS_GET_FAILED";
export const SKILL_ANALYTICS_GET_SUCCEEDED = "SKILL_ANALYTICS_GET_SUCCEEDED";

export const APPOINTMENT_USER_GET_REQUESTED = "APPOINTMENT_USER_GET_REQUESTED";
export const APPOINTMENT_USER_GET_FAILED = "APPOINTMENT_USER_GET_FAILED";
export const APPOINTMENT_USER_GET_SUCCEEDED = "APPOINTMENT_USER_GET_SUCCEEDED";

// CHECKIN -----------------------------------------------------
export const CHECKIN_OLD_CREATE_REQUESTED = "CHECKIN_OLD_CREATE_REQUESTED";
export const CHECKIN_OLD_CREATE_FAILED = "CHECKIN_OLD_CREATE_FAILED";
export const CHECKIN_OLD_CREATE_SUCCEEDED = "CHECKIN_OLD_CREATE_SUCCEEDED";

export const CHECKIN_NEW_CREATE_REQUESTED = "CHECKIN_NEW_CREATE_REQUESTED";
export const CHECKIN_NEW_CREATE_FAILED = "CHECKIN_NEW_CREATE_FAILED";
export const CHECKIN_NEW_CREATE_SUCCEEDED = "CHECKIN_NEW_CREATE_SUCCEEDED";

export const CHECKIN_UPDATE_REQUESTED = "CHECKIN_UPDATE_REQUESTED";
export const CHECKIN_UPDATE_FAILED = "CHECKIN_UPDATE_FAILED";
export const CHECKIN_UPDATE_SUCCEEDED = "CHECKIN_UPDATE_SUCCEEDED";

export const CHECKIN_GET_ALL_REQUESTED = "CHECKIN_GET_ALL_REQUESTED";
export const CHECKIN_GET_ALL_FAILED = "CHECKIN_GET_ALL_FAILED";
export const CHECKIN_GET_ALL_SUCCEEDED = "CHECKIN_GET_ALL_SUCCEEDED";

export const CHECKIN_TOGGLE_SHOW_ACTIVE = "CHECKIN_TOGGLE_SHOW_ACTIVE";

// general user stats
export const VISA_APPLICANT_STATS_REQUESTED = "VISA_APPLICANT_STATS_REQUESTED";
export const VISA_APPLICANT_STATS_FAILED = "VISA_APPLICANT_STATS_FAILED";
export const VISA_APPLICANT_STATS_SUCCEEDED = "VISA_APPLICANT_STATS_SUCCEEDED";

export const EDUCATION_STATS_REQUESTED = "EDUCATION_STATS_REQUESTED";
export const EDUCATION_STATS_FAILED = "EDUCATION_STATS_FAILED";
export const EDUCATION_STATS_SUCCEEDED = "EDUCATION_STATS_SUCCEEDED";

export const SKILL_ASSESSMENT_STATS_REQUESTED =
  "SKILL_ASSESSMENT_STATS_REQUESTED";
export const SKILL_ASSESSMENT_STATS_FAILED = "SKILL_ASSESSMENT_STATS_FAILED";
export const SKILL_ASSESSMENT_STATS_SUCCEEDED =
  "SKILL_ASSESSMENT_STATS_SUCCEEDED";

export const HEALTH_INSURANCE_STATS_REQUESTED =
  "HEALTH_INSURANCE_STATS_REQUESTED";
export const HEALTH_INSURANCE_STATS_FAILED = "HEALTH_INSURANCE_STATS_FAILED";
export const HEALTH_INSURANCE_STATS_SUCCEEDED =
  "HEALTH_INSURANCE_STATS_SUCCEEDED";

export const LEAD_STATS_REQUESTED = "LEAD_STATS_REQUESTED";
export const LEAD_STATS_FAILED = "LEAD_STATS_FAILED";
export const LEAD_STATS_SUCCEEDED = "LEAD_STATS_SUCCEEDED";

export const CONVERTED_STATS_REQUESTED = "CONVERTED_STATS_REQUESTED";
export const CONVERTED_STATS_FAILED = "CONVERTED_STATS_FAILED";
export const CONVERTED_STATS_SUCCEEDED = "CONVERTED_STATS_SUCCEEDED";
// FACE RECONIZATION
export const CREATE_FACE_PHOTO_REQUESTED = "CREATE_FACE_PHOTO_REQUESTED";
export const FACE_PHOTO_CREATE_SUCCEEDED = "FACE_PHOTO_CREATE_SUCCEEDED";
export const FACE_PHOTO_CREATE_FAILED = "FACE_PHOTO_CREATE_FAILED";

export const GET_FACE_PHOTO_REQUESTED = "GET_FACE_PHOTO_REQUESTED";
export const FACE_PHOTO_GET_SUCCEEDED = "FACE_PHOTO_GET_SUCCEEDED";
export const FACE_PHOTO_GET_FAILED = "FACE_PHOTO_GET_FAILED";
