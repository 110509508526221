import {
  ANNOUNCEMENT_CREATE_REQUESTED,
  ANNOUNCEMENT_CREATE_FAILED,
  ANNOUNCEMENT_CREATE_SUCCEEDED,
  ANNOUNCEMENT_DELETE_REQUESTED,
  ANNOUNCEMENT_DELETE_FAILED,
  ANNOUNCEMENT_DELETE_SUCCEEDED,
  ANNOUNCEMENT_GET_ALL_REQUESTED,
  ANNOUNCEMENT_GET_ALL_FAILED,
  ANNOUNCEMENT_GET_ALL_SUCCEEDED,
  ANNOUNCEMENT_GET_REQUESTED,
  ANNOUNCEMENT_GET_FAILED,
  ANNOUNCEMENT_GET_SUCCEEDED,
  ANNOUNCEMENT_UPDATE_REQUESTED,
  ANNOUNCEMENT_UPDATE_FAILED,
  ANNOUNCEMENT_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  announcementCreateError: false,
  announcementCreateFetching: false,
  announcementCreateSuccess: false,

  announcementDeleteError: false,
  announcementDeleteFetching: false,
  announcementDeleteSuccess: false,

  announcementUpdateError: false,
  announcementUpdateFetching: false,
  announcementUpdateSuccess: false,

  announcementGetError: false,
  announcementGetFetching: false,
  announcementGetSuccess: false,

  announcementLogGetError: false,
  announcementLogGetFetching: false,
  announcementLogGetSuccess: false,

  announcementGetAllError: false,
  announcementGetAllSuccess: false,
  errorMsg: "",
};

const defaultState = {
  announcements: [],
  announcementLog: [],
  announcementDetail: {},
  announcementGetAllFetching: false,
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case ANNOUNCEMENT_CREATE_REQUESTED:
      return {
        ...state,
        announcementCreateFetching: true,
        announcementCreateError: false,
        announcementCreateSuccess: false,
      };
    case ANNOUNCEMENT_CREATE_FAILED:
      return {
        ...state,
        announcementCreateFetching: false,
        announcementCreateError: true,
        announcementCreateSuccess: false,
        errorMsg: action.payload,
      };
    case ANNOUNCEMENT_CREATE_SUCCEEDED:
      return {
        ...state,
        announcementDetail: action.payload,
        announcementCreateFetching: false,
        announcementCreateError: false,
        announcementCreateSuccess: true,
      };

    case ANNOUNCEMENT_DELETE_REQUESTED:
      return {
        ...state,
        announcementDeleteFetching: true,
        announcementDeleteError: false,
        announcementDeleteSuccess: false,
      };
    case ANNOUNCEMENT_DELETE_FAILED:
      return {
        ...state,
        announcementDeleteFetching: false,
        announcementDeleteError: true,
        announcementDeleteSuccess: false,
        errorMsg: action.payload,
      };
    case ANNOUNCEMENT_DELETE_SUCCEEDED:
      return {
        ...state,
        announcementDeleteFetching: false,
        announcementDeleteError: false,
        announcementDeleteSuccess: true,
      };

    case ANNOUNCEMENT_UPDATE_REQUESTED:
      return {
        ...state,
        announcementUpdateFetching: true,
        announcementUpdateError: false,
        announcementUpdateSuccess: false,
      };
    case ANNOUNCEMENT_UPDATE_FAILED:
      return {
        ...state,
        announcementUpdateFetching: false,
        announcementUpdateError: true,
        announcementUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case ANNOUNCEMENT_UPDATE_SUCCEEDED:
      return {
        ...state,
        announcementDetail: action.payload,
        announcementUpdateFetching: false,
        announcementUpdateError: false,
        announcementUpdateSuccess: true,
      };

    case ANNOUNCEMENT_GET_REQUESTED:
      return {
        ...state,
        announcementGetFetching: true,
        announcementGetError: false,
      };
    case ANNOUNCEMENT_GET_FAILED:
      return {
        ...state,
        announcementGetFetching: false,
        announcementGetError: true,
      };
    case ANNOUNCEMENT_GET_SUCCEEDED:
      return {
        ...state,
        announcementDetail: action.payload,
        announcementGetFetching: false,
        announcementGetError: false,
      };

    case ANNOUNCEMENT_GET_ALL_REQUESTED:
      return {
        ...state,
        announcementGetAllFetching: true,
        announcementGetAllError: false,
        announcementGetAllSuccess: false,
      };
    case ANNOUNCEMENT_GET_ALL_FAILED:
      return {
        ...state,
        announcementGetAllFetching: false,
        announcementGetAllError: true,
        announcementGetAllSuccess: false,
      };
    case ANNOUNCEMENT_GET_ALL_SUCCEEDED:
      return {
        ...state,
        announcements: action.payload,
        announcementGetAllFetching: false,
        announcementGetAllError: false,
        announcementGetAllSuccess: true,
      };
    default:
      return state;
  }
}
