import {
  SOURCE_CREATE_REQUESTED,
  SOURCE_CREATE_FAILED,
  SOURCE_CREATE_SUCCEEDED,
  SOURCE_GET_ALL_REQUESTED,
  SOURCE_GET_ALL_FAILED,
  SOURCE_GET_ALL_SUCCEEDED,
  SOURCE_GET_REQUESTED,
  SOURCE_GET_FAILED,
  SOURCE_GET_SUCCEEDED,
  SOURCE_UPDATE_REQUESTED,
  SOURCE_UPDATE_FAILED,
  SOURCE_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  sourceCreateError: false,
  sourceCreateFetching: false,
  sourceCreateSuccess: false,

  sourceUpdateError: false,
  sourceUpdateFetching: false,
  sourceUpdateSuccess: false,

  sourceGetError: false,
  sourceGetFetching: false,
  sourceGetSuccess: false,

  sourceGetAllError: false,
  sourceGetAllFetching: false,
  sourceGetAllSuccess: false,
};

const defaultState = {
  sources: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case SOURCE_CREATE_REQUESTED:
      return {
        ...state,
        sourceCreateFetching: true,
        sourceCreateError: false,
        sourceCreateSuccess: false,
      };
    case SOURCE_CREATE_FAILED:
      return {
        ...state,
        sourceCreateFetching: false,
        sourceCreateError: true,
        sourceCreateSuccess: false,
      };
    case SOURCE_CREATE_SUCCEEDED:
      return {
        ...state,
        sourceDetail: action.payload,
        sourceCreateFetching: false,
        sourceCreateError: false,
        sourceCreateSuccess: true,
      };

    case SOURCE_UPDATE_REQUESTED:
      return {
        ...state,
        sourceUpdateFetching: true,
        sourceUpdateError: false,
        sourceUpdateSuccess: false,
      };
    case SOURCE_UPDATE_FAILED:
      return {
        ...state,
        sourceUpdateFetching: false,
        sourceUpdateError: true,
        sourceUpdateSuccess: false,
      };
    case SOURCE_UPDATE_SUCCEEDED:
      return {
        ...state,
        sourceDetail: action.payload,
        sourceUpdateFetching: false,
        sourceUpdateError: false,
        sourceUpdateSuccess: true,
      };

    case SOURCE_GET_REQUESTED:
      return {
        ...state,
        sourceGetFetching: true,
        sourceGetError: false,
      };
    case SOURCE_GET_FAILED:
      return {
        ...state,
        sourceGetFetching: false,
        sourceGetError: true,
      };
    case SOURCE_GET_SUCCEEDED:
      return {
        ...state,
        sourceDetail: action.payload,
        sourceGetFetching: false,
        sourceGetError: false,
      };

    case SOURCE_GET_ALL_REQUESTED:
      return {
        ...state,
        sourceGetAllFetching: true,
        sourceGetAllError: false,
        sourceGetAllSuccess: false,
      };
    case SOURCE_GET_ALL_FAILED:
      return {
        ...state,
        sourceGetAllFetching: false,
        sourceGetAllError: true,
        sourceGetAllSuccess: false,
      };
    case SOURCE_GET_ALL_SUCCEEDED:
      return {
        ...state,
        sources: action.payload,
        sourceGetAllFetching: false,
        sourceGetAllError: false,
        sourceGetAllSuccess: true,
      };
    default:
      return state;
  }
}
