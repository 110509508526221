import {
  USER_CREATE_REQUESTED,
  USER_CREATE_FAILED,
  USER_CREATE_SUCCEEDED,
  USER_GET_ALL_REQUESTED,
  USER_GET_ALL_FAILED,
  USER_GET_ALL_SUCCEEDED,
  USER_GET_REQUESTED,
  USER_GET_FAILED,
  USER_GET_SUCCEEDED,
  USER_UPDATE_REQUESTED,
  USER_UPDATE_FAILED,
  USER_UPDATE_SUCCEEDED,
  PROFILE_GET_FAILED,
  PROFILE_GET_REQUESTED,
  PROFILE_GET_SUCCEEDED,
  PROFILE_UPDATE_FAILED,
  PROFILE_UPDATE_REQUESTED,
  PROFILE_UPDATE_SUCCEEDED,
  USER_UPDATE_FETCHING,
  CLEAR_ERROR,
  PROFILE_CODE_GENERATION_REQUESTED,
  PROFILE_CODE_GENERATION_FAILED,
  PROFILE_CODE_GENERATION_SUCCEEDED,
  GET_ALL_SERVICE_DATA_FAILED,
  GET_ALL_SERVICE_DATA_REQUESTED,
  GET_ALL_SERVICE_DATA_SUCCEEDED,
} from "../actions";

const defaultError = {
  profileGetFetching: false,
  profileGetError: false,
  profileGetSuccess: false,

  profileUpdateFetching: false,
  profileUpdateError: false,
  profileUpdateSuccess: false,

  userCreateError: false,
  userCreateFetching: false,
  userCreateSuccess: false,

  userUpdateError: false,
  userUpdateFetching: false,
  userUpdateSuccess: false,

  userGetError: false,
  userGetFetching: false,
  userGetSuccess: false,

  userGetAllError: false,
  userGetAllFetching: false,
  userGetAllSuccess: false,

  codeGenerationError: false,
  codeGenerationProgress: false,
  codeGenerationSuccess: false,

  getAllServiceDataError: false,
  getAllServiceDataFetching: false,
  getAllServiceDataSuccess: false,

  errorMsg: "",
};

const defaultState = {
  users: [],
  allServiceData: [],
  activeUsers: [],
  userDetail: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    roleId: "",
    password: "",
  },
  profile: {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    roleId: "",
    password: "",
  },
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case USER_CREATE_REQUESTED:
      return {
        ...state,
        userCreateFetching: true,
        userCreateError: false,
        userCreateSuccess: false,
      };
    case USER_CREATE_FAILED:
      return {
        ...state,
        userCreateFetching: false,
        userCreateError: true,
        userCreateSuccess: false,
        errorMsg: action.payload,
      };
    case USER_CREATE_SUCCEEDED:
      return {
        ...state,
        userDetail: action.payload,
        userCreateFetching: false,
        userCreateError: false,
        userCreateSuccess: true,
      };

    case USER_UPDATE_REQUESTED:
      return {
        ...state,
        userUpdateFetching: true,
        userUpdateError: false,
        userUpdateSuccess: false,
      };
    case USER_UPDATE_FETCHING:
      return {
        ...state,
        userUpdateFetching: true,
      };
    case USER_UPDATE_FAILED:
      return {
        ...state,
        userUpdateFetching: false,
        userUpdateError: true,
        userUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case USER_UPDATE_SUCCEEDED:
      return {
        ...state,
        userDetail: action.payload,
        userUpdateFetching: false,
        userUpdateError: false,
        userUpdateSuccess: true,
      };

    case USER_GET_REQUESTED:
      return {
        ...state,
        userGetFetching: true,
        userGetError: false,
      };
    case USER_GET_FAILED:
      return {
        ...state,
        userGetFetching: false,
        userGetError: true,
      };
    case USER_GET_SUCCEEDED:
      return {
        ...state,
        userDetail: action.payload,
        userGetFetching: false,
        userGetError: false,
      };

    case USER_GET_ALL_REQUESTED:
      return {
        ...state,
        userGetAllFetching: true,
        userGetAllError: false,
      };
    case USER_GET_ALL_FAILED:
      return {
        ...state,
        userGetAllFetching: false,
        userGetAllError: true,
        userGetAllSuccess: false,
      };
    case USER_GET_ALL_SUCCEEDED:
      return {
        ...state,
        users: action.payload,
        activeUsers: action.payload
          .filter((u) => u.isActive === true)
          .sort((a, b) =>
            a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0
          ),
        userGetAllFetching: false,
        userGetAllError: false,
        userGetAllSuccess: true,
      };

    case PROFILE_GET_REQUESTED:
      return {
        ...state,
        profileGetFetching: true,
        profileGetError: false,
      };
    case PROFILE_GET_FAILED:
      return {
        ...state,
        profileGetFetching: false,
        profileGetError: true,
      };
    case PROFILE_GET_SUCCEEDED:
      return {
        ...state,
        profile: action.payload,
        profileGetFetching: false,
        profileGetError: false,
      };

    case PROFILE_UPDATE_REQUESTED:
      return {
        ...state,
        profileUpdateFetching: true,
        profileUpdateError: false,
        profileUpdateSuccess: false,
      };
    case PROFILE_UPDATE_FAILED:
      return {
        ...state,
        profileUpdateFetching: false,
        profileUpdateError: true,
        profileUpdateSuccess: false,
      };
    case PROFILE_UPDATE_SUCCEEDED:
      return {
        ...state,
        profile: action.payload,
        profileUpdateFetching: false,
        profileUpdateError: false,
        profileUpdateSuccess: true,
      };
    case PROFILE_CODE_GENERATION_REQUESTED:
      return {
        ...state,
        codeGenerationProgress: true,
        codeGenerationError: false,
        codeGenerationSuccess: false,
      };
    case PROFILE_CODE_GENERATION_FAILED:
      return {
        ...state,
        codeGenerationProgress: false,
        codeGenerationError: true,
        codeGenerationSuccess: false,
      };
    case PROFILE_CODE_GENERATION_SUCCEEDED:
      return {
        ...state,
        codeGenerationProgress: false,
        codeGenerationError: false,
        codeGenerationSuccess: false,
        profile: action.payload,
      };

    case GET_ALL_SERVICE_DATA_REQUESTED:
      return {
        ...state,
        getAllServiceDataError: false,
        getAllServiceDataFetching: true,
      };
    case GET_ALL_SERVICE_DATA_FAILED:
      return {
        ...state,
        getAllServiceDataError: true,
        getAllServiceDataFetching: false,
      };
    case GET_ALL_SERVICE_DATA_SUCCEEDED:
      return {
        ...state,
        allServiceData: action.payload,
        getAllServiceDataError: false,
        getAllServiceDataFetching: false,
      };
    default:
      return state;
  }
}
