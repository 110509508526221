import React, { useEffect, useState } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";

import { CSVLink } from "react-csv";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import jsPDF from "jspdf";
import "jspdf-autotable";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import AddStudent from "./AddStudent";
import EditStudent from "./EditStudent";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import history from "../../../history";
import "../../FiscalReport/style.css";

import {
  filterCaseInsensitive,
  getColumns,
  getDropDown,
  isValidDate,
  reactTableHelper,
} from "../../../scripts/util";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { useDispatch, useSelector } from "react-redux";
import Badge from "../../../components/Badge/Badge";
import momentTZ from "moment-timezone";
import moment from "moment";
import { GetApp, PictureAsPdf } from "@material-ui/icons";
import ReactGA from "react-ga4";
import withTimer from "../../../components/Timer/withTimer";
import SmsEmailAction from "../../../components/SmsEmailAction/SmsEmailAction";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_FORMAT_WITH_MONTH,
  STUDENT_TABLE_ACTION,
  SUPER_ROLE,
  DEFAULT_TIME_ZONE,
  monthColor,
  GENERAL_ROLE,
} from "../../../scripts/const";
import DateFilter from "../../../components/DateFilter";
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { USER_GET_REQUESTED } from "redux/actions";
import { USER_UPDATE_REQUESTED } from "redux/actions";

const SelectTable = selectTableHOC(ReactTable);

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const StudentTables = ({
  match: {
    params: { id },
  },
}) => {
  const [addStudentModal, setAddStudentModal] = React.useState(false);
  const [editStudentModal, setEditStudentModal] = React.useState(false);
  const [currentStudent, setCurrentStudent] = React.useState({});
  const [data, setData] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [courses, setCourses] = React.useState([]);
  const [sources, setSources] = React.useState([]);
  const [universities, setUniversities] = React.useState([]);

  const activeUsers = useSelector((state) => state.user.activeUsers);
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const { id: currentUserId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const isSuperUser = SUPER_ROLE.includes(currentRoleId);
  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);

  const studentsFetching = useSelector(
    (state) => state.student.studentGetAllFetching
  );
  const [filterData, setFilterData] = React.useState([]);
  const [fromDateState, setFromDateState] = React.useState("");
  const [toDateState, setToDateState] = React.useState("");

  const userDetail = useSelector((state) => state.user.userDetail);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch({
      type: USER_GET_REQUESTED,
      payload: currentUser.id,
    });
  }, []);

  const [selectedOptions, setSelectedOptions] = React.useState(
    userDetail?.hideColumn?.educationService || []
  );
  const [columnDataloaded, setColumnDataloaded] = useState(false);

  React.useEffect(() => {
    if (userDetail?.hideColumn?.educationService !== selectedOptions) {
      if (userDetail?.hideColumn?.educationService)
        setSelectedOptions(userDetail?.hideColumn?.educationService);
      setColumnDataloaded(true);
    }
  }, [userDetail?.hideColumn?.educationService]);

  useEffect(() => {
    if (id !== "undefined" && id > 0 && data.length > 0) {
      const found = data.find((d) => d.id == id);
      if (found) {
        setCurrentStudent(found);
        setEditStudentModal(true);
      }
    }
  }, [id, data]);

  const [dateRange, setDateRange] = React.useState({
    fromDate: "",
    toDate: "",
  });

  const [selection, setSelection] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);

  function isSelected(key) {
    return selection.includes(`select-${key}`);
  }
  function toggleAll() {
    const keyField = "id";
    const newSelectAll = !selectAll;
    const newSelection = [];

    if (newSelectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = reactTableRef.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        newSelection.push(`select-${item._original[keyField]}`);
      });
    }
    setSelection(newSelection);
    setSelectAll(newSelectAll);
  }

  function handleDateRangeFilter(fromDate, toDate) {
    if (!fromDate || !toDate) {
      setFilterData(data);
    } else {
      const filterData = data.filter(
        (e) => moment(e.createdAt) >= fromDate && moment(e.createdAt) <= toDate
      );
      setFilterData(filterData);
    }
  }
  function toggleSelection(key, shift, row) {
    // start off with the existing state
    let newSelection = [...selection];
    const keyIndex = newSelection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      newSelection = [
        ...newSelection.slice(0, keyIndex),
        ...newSelection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      newSelection.push(key);
    }
    // update the state
    setSelection(newSelection);
  }

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setDateRange({
        ...dateRange,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setDateRange({
        ...dateRange,
        [name]: date,
      });
    }
  };

  function filter() {
    let allValid = true;
    setToDateState("");
    setFromDateState("");

    if (dateRange.fromDate === "") {
      setFromDateState("error");
      allValid = false;
    }

    if (dateRange.toDate === "") {
      setToDateState("error");
      allValid = false;
      return;
    }

    if (!isValidDate(dateRange.fromDate)) {
      setFromDateState("error");
      allValid = false;
    }

    if (!isValidDate(dateRange.toDate)) {
      setToDateState("error");
      allValid = false;
    }

    if (allValid) {
      const fromDate = moment(dateRange.fromDate, DEFAULT_DATE_FORMAT);
      const toDate = moment(dateRange.toDate, DEFAULT_DATE_FORMAT);
      const filterData = data.filter(
        (e) => moment(e.createdAt) >= fromDate && moment(e.createdAt) <= toDate
      );
      setFilterData(filterData);
    }
  }

  function clear() {
    setToDateState("");
    setFromDateState("");
    setDateRange({
      fromDate: "",
      toDate: "",
    });
    setFilterData(data);
  }

  const students = useSelector((state) => state.student.students);
  const allUsers = useSelector((state) => state.user.users);
  const allCourses = useSelector((state) => state.course.courses);
  const allSources = useSelector((state) => state.source.sources);
  const allUniversities = useSelector((state) => state.university.universities);
  const currentUser = useSelector((state) => state.login.loginUser.detail);
  const isSelectTable = SUPER_ROLE.includes(currentUser.roleId);

  React.useEffect(() => {
    setUsers(allUsers);
  }, [allUsers]);

  React.useEffect(() => {
    setCourses(allCourses);
  }, [allCourses]);

  React.useEffect(() => {
    setSources(allSources);
  }, [allSources]);

  React.useEffect(() => {
    setUniversities(allUniversities);
  }, [allUniversities]);

  React.useEffect(() => {
    setData(students);
    if (!isGeneralUser) {
      setFilterData(students);
    } else {
      const filterData = students.filter(
        (student) => student.userId === currentUserId
      );
      setFilterData(filterData);
    }
  }, [students]);

  let reactTableRef = null;
  let csvLink = null;
  function download(event) {
    const columns = getColumns("Student");
    const currentRecords = isSelectTable
      ? reactTableRef.getWrappedInstance().getResolvedState().sortedData
      : reactTableRef.getResolvedState().sortedData;
    let data_to_download = [];
    for (let index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        if (
          columns[colIndex].Header === "AssignedTo" &&
          currentRecords[index][columns[colIndex].accessor]
        ) {
          record_to_download[columns[colIndex].Header] = allUsers.find(
            (e) => e.id === currentRecords[index][columns[colIndex].accessor]
          ).firstName;
        } else {
          record_to_download[columns[colIndex].Header] =
            currentRecords[index][columns[colIndex].accessor];
        }
      }
      data_to_download.push(record_to_download);
    }
    setDataToDownload(data_to_download);
  }

  const [dataToDownload, setDataToDownload] = React.useState([]);
  const [isDownload, setIsDownload] = React.useState(false);

  React.useEffect(() => {
    if (dataToDownload.length > 0) {
      setIsDownload(true);
    }
  }, [dataToDownload]);

  React.useEffect(() => {
    if (isDownload) {
      csvLink.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Students";
    const columns = getColumns("Student");
    const head = [getColumns("Student").map((e) => e.Header)];
    const currentRecords = isSelectTable
      ? reactTableRef.getWrappedInstance().getResolvedState().sortedData
      : reactTableRef.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = [];
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        if (
          columns[colIndex].Header === "AssignedTo" &&
          currentRecords[index][columns[colIndex].accessor]
        ) {
          const user = allUsers.find(
            (e) => e.id === currentRecords[index][columns[colIndex].accessor]
          );
          if (user) {
            record_to_download.push(user.firstName);
          } else {
            record_to_download.push(
              currentRecords[index][columns[colIndex].accessor]
            );
          }
        } else {
          record_to_download.push(
            currentRecords[index][columns[colIndex].accessor]
          );
        }
      }
      data_to_download.push(record_to_download);
    }
    let content = {
      startY: 50,
      head,
      body: data_to_download,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("students.pdf"); // Save the PDF
    // doc.output('dataurlnewwindow');     //opens the data uri in new window
  };

  const classes = useStyles();

  function getBGColor(row) {
    const { status } = row;
    if (["Follow Up", "Info Requested"].includes(status)) {
      return "#fada5e";
    }
    return undefined;
  }

  function getTrProps(state, rowInfo) {
    {
      if (rowInfo && rowInfo.row) {
        return {
          onClick: (e) => {
            e.preventDefault();
            ReactGA.event({
              category: "Student",
              action: "View Student Profile",
            });
            setCurrentStudent(rowInfo.row._original);
            setEditStudentModal(true);
            history.push(`/admin/students/${rowInfo.row._original.id}`);
          },
          style: {
            background: getBGColor(rowInfo.row._original),
          },
        };
      } else {
        return {};
      }
    }
  }
  let columns = [
    {
      sortable: false,
      Header: " ",
      show: !selectedOptions.includes(" "),
      accessor: "createdAt",
      headerStyle: {
        className: "sticky",
        headerClassName: "sticky",
      },
      Cell: (props) => {
        if (props.value) {
          return momentTZ(props.value).format("MMM YYYY");
        }
        return props.value;
      },
      getProps: (state, rowInfo) => {
        let color = null;
        if (rowInfo && rowInfo.row.createdAt) {
          let month = momentTZ(rowInfo.row.createdAt).format("MM");
          color = monthColor[month - 1];
        }
        return {
          style: {
            background: color,
          },
        };
      },
      filterMethod: (filter, row) => {
        return momentTZ(row[filter.id])
          .format("MMM YYYY")
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      },
      width: 70,
    },
    {
      Header: "ID",
      show: !selectedOptions.includes("ID"),
      accessor: "id",
      width: 70,
    },
    {
      Header: "First Name",
      show: !selectedOptions.includes("First Name"),

      accessor: "firstName",
      width: 150,
    },
    {
      Header: "Middle Name",
      show: !selectedOptions.includes("Middle Name"),

      accessor: "middleName",
      width: 150,
    },
    {
      Header: "Last Name",
      show: !selectedOptions.includes("Last Name"),
      accessor: "lastName",
      width: 150,
    },
    // {
    //   Header: "Date of Birth",
    //   accessor: "dob",
    //   Cell: (props) => {
    //     if (props.value) {
    //       return moment(props.value, DEFAULT_DATE_FORMAT).format(
    //         DEFAULT_DATE_FORMAT_WITH_MONTH
    //       );
    //     }
    //     return props.value;
    //   },
    // },
    {
      Header: "Email",
      show: !selectedOptions.includes("Email"),
      accessor: "email",
      width: 150,
    },
    {
      Header: "Phone",
      show: !selectedOptions.includes("Phone"),
      accessor: "phone",
      width: 150,
    },
    {
      Header: "Country",
      show: !selectedOptions.includes("Country"),
      accessor: "country",
      width: 150,
    },
    {
      Header: "Passport No.",
      show: !selectedOptions.includes("Passport No."),
      accessor: "passport",
      width: 150,
    },
    {
      Header: "Issue Date",
      show: !selectedOptions.includes("Issue Date"),
      accessor: "issueDate",
      width: 150,
    },
    {
      Header: "Expiry Date",
      show: !selectedOptions.includes("Expiry Date"),
      accessor: "expiryDate",
      width: 150,
    },
    {
      headerStyle: { textAlign: "left" },
      Header: "Location",
      show: !selectedOptions.includes("Location"),
      accessor: "location",
      width: 150,

      filterMethod: (filter, row) => {
        if (filter.value === "All") {
          return true;
        }
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "All"}
        >
          <option value="All">All</option>
          {getDropDown("location").map((name, i) => {
            return (
              <option key={i} value={`${name}`}>
                {name}
              </option>
            );
          })}
        </select>
      ),
    },
    {
      Header: "Uni",
      show: !selectedOptions.includes("Uni"),
      accessor: "universityId",
      Cell: (props) => {
        if (props.value && allUniversities) {
          const university = allUniversities.find((e) => e.id === props.value);
          if (university) {
            return university.name;
          } else {
            return props.value;
          }
        } else {
          return props.value;
        }
      },
      filterMethod: (filter, row) => {
        if (allUniversities) {
          const universiity = allUniversities
            .filter((e) =>
              e.name.toLowerCase().includes(filter.value.toLowerCase())
            )
            .map((m) => m.id);
          if (universiity && universiity.length > 0) {
            return universiity.includes(row[filter.id]);
          }
        } else {
          return true;
        }
      },
      width: 150,
    },
    {
      Header: "Course",
      show: !selectedOptions.includes("Course"),
      accessor: "courseId",
      Cell: (props) => {
        if (props.value && allCourses) {
          const source = allCourses.find((e) => e.id === props.value);
          if (source) {
            return source.name;
          } else {
            return props.value;
          }
        } else {
          return props.value;
        }
      },
      filterMethod: (filter, row) => {
        if (allCourses) {
          const course = allCourses
            .filter((e) =>
              e.name.toLowerCase().includes(filter.value.toLowerCase())
            )
            .map((m) => m.id);
          if (course && course.length > 0) {
            return course.includes(row[filter.id]);
          }
        } else {
          return true;
        }
      },
      width: 150,
    },
    {
      Header: "Start Date",
      show: !selectedOptions.includes("Start Date"),
      accessor: "startDate",
      width: 150,
    },
    {
      Header: "End Date",
      show: !selectedOptions.includes("End Date"),
      accessor: "endDate",
      width: 150,
    },
    {
      Header: "Fee Due",
      show: !selectedOptions.includes("Fee Due"),
      accessor: "feeDueDate",
      sortMethod: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) return 1;
        else if (a1 > b1) return -1;
        else return 0;
      },
      Cell: (props) => {
        if (props.value) {
          const now = momentTZ().tz(DEFAULT_TIME_ZONE);
          const expiry = moment(props.value, DEFAULT_DATE_FORMAT);
          const dateDiff = expiry.diff(now, "days");
          if (dateDiff <= 30 && dateDiff >= -1) {
            return (
              <Badge color={"danger"}>
                {moment(props.value, DEFAULT_DATE_FORMAT).format(
                  DEFAULT_DATE_FORMAT_WITH_MONTH
                )}
              </Badge>
            );
          } else {
            return moment(props.value, DEFAULT_DATE_FORMAT).format(
              DEFAULT_DATE_FORMAT_WITH_MONTH
            );
          }
        }
        return props.value;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value === "priority") {
          if (row[filter.id]) {
            const now = momentTZ().tz(DEFAULT_TIME_ZONE);
            const expiry = moment(row[filter.id], DEFAULT_DATE_FORMAT);
            const dateDiff = expiry.diff(now, "days");
            return dateDiff <= 30 && dateDiff >= -1;
          }
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="priority">Need Attention</option>
        </select>
      ),
      width: 150,
    },
  ];

  if (isSuperUser) {
    columns.push(
      {
        Header: "Fees Paid",
        show: !selectedOptions.includes("Fees Paid"),
        accessor: "feesPaid",
        width: 150,
      },
      {
        Header: "Fees Paid Date",
        show: !selectedOptions.includes("Fees Paid Date"),
        accessor: "feesPaidDate",
        width: 150,
      },
      {
        Header: "Fees Due Amount",
        show: !selectedOptions.includes("Fees Due Amount"),
        accessor: "feesDueAmount",
        width: 180,
      },
      {
        Header: "Commission",
        show: !selectedOptions.includes("Commission"),
        accessor: "commissionAmount",
        width: 150,
      },
      {
        Header: "Invoice #",
        show: !selectedOptions.includes("Invoice #"),
        accessor: "invoiceNo",
        width: 150,
      },
      {
        Header: "Invoice Date",
        show: !selectedOptions.includes("Invoice Date"),
        accessor: "invoiceDate",
        width: 150,
      },
      {
        Header: "Invoice Status",
        show: !selectedOptions.includes("Invoice Status"),
        accessor: "invoiceStatus",
        width: 150,
      }
      // {
      //   Header: "Remarks",
      //   accessor: "remarks",
      //   Cell: (props) => {
      //     if (props.value) {
      //       return <Interweave content={props.value} />;
      //     } else {
      //       return props.value;
      //     }
      //   },
      // },)
    );
  }

  columns.push(
    {
      Header: "Source",
      accessor: "sourceId",
      show: !selectedOptions.includes("Source"),

      Cell: (props) => {
        if (props.value && allSources) {
          const source = allSources.find((e) => e.id === props.value);
          if (source) {
            return source.name;
          } else {
            return props.value;
          }
        } else {
          return props.value;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "noSource") {
          return row[filter.id] === "" || row[filter.id] === null;
        } else if (filter.value) {
          return row[filter.id] == filter.value;
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="noSource">No Source</option>
          {allSources &&
            allSources.map((m, i) => (
              <option key={i} value={m.id}>
                {m.name}
              </option>
            ))}
        </select>
      ),
      width: 150,
    },
    {
      Header: "AssignedTo",
      show: !selectedOptions.includes("AssignedTo"),
      accessor: "userId",
      Cell: (props) => {
        if (props.value && allUsers) {
          const user = allUsers.find((e) => e.id === props.value);
          if (user) {
            return user.firstName;
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "notAssigned") {
          return row[filter.id] === "" || row[filter.id] === null;
        } else if (filter.value) {
          return row[filter.id] == filter.value;
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => {
            if (isGeneralUser) {
              setFilterData(students);

              if (filterData.length === 0) {
                setTimeout(() => {
                  onChange(event.target.value);
                }, 500);
              } else {
                onChange(event.target.value);
              }
            } else {
              onChange(event.target.value);
            }
          }}
          style={{ width: "100%" }}
          value={filter ? filter.value : isGeneralUser ? currentUserId : "all"}
        >
          <option value="all">All</option>
          <option value="notAssigned">Not Assigned</option>
          {activeUsers &&
            activeUsers.map((m, i) => (
              <option key={i} value={m.id}>
                {m.firstName}
              </option>
            ))}
        </select>
      ),
      width: 150,
    },
    {
      headerStyle: { textAlign: "left" },
      Header: "Status",
      show: !selectedOptions.includes("Status"),
      accessor: "status",
      Cell: (props) => {
        if (
          [
            "Info Requested",
            "Follow Up",
            "Provisional Offer/GTE Survey",
          ].includes(props.value)
        ) {
          return <Badge color={"warning"}>{props.value}</Badge>;
        } else if (
          [
            "Offer Received",
            "Coe Received",
            "Conditional Offer",
            "Unconditional Offer",
          ].includes(props.value)
        ) {
          return <Badge color={"success"}>{props.value}</Badge>;
        } else if (props.value === "Canceled" || props.value === "Refused") {
          return <Badge color={"danger"}>{props.value}</Badge>;
        } else if (props.value) {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else {
          return <Badge color={"gray"}>{props.value}</Badge>;
        }
      },
      filterMethod: (filter, row) => {
        if (filter.value === "All") {
          return true;
        }

        return row[filter.id] === filter.value;
        if (filter.value === "Collecting Docs") {
          return row[filter.id] === "Collecting Docs";
        }
        if (filter.value === "Follow Up") {
          return row[filter.id] === "Follow Up";
        }
        if (filter.value === "Consultation") {
          return row[filter.id] === "Consultation";
        }
        if (filter.value === "Application Ready") {
          return row[filter.id] === "Application Ready";
        }
        if (filter.value === "Application Submitted") {
          return row[filter.id] === "Application Submitted";
        }
        if (filter.value === "Info Requested") {
          return row[filter.id] === "Info Requested";
        }
        if (filter.value === "Provisional Offer/GTE Survey") {
          return row[filter.id] === "Provisional Offer/GTE Survey";
        }
        if (filter.value === "Conditional Offer") {
          return row[filter.id] === "Conditional Offer";
        }
        if (filter.value === "Unconditional Offer") {
          return row[filter.id] === "Unconditional Offer";
        }
        if (filter.value === "Coe Received") {
          return row[filter.id] === "Coe Received";
        }
        if (filter.value === "Refused") {
          return row[filter.id] === "Refused";
        }
        if (filter.value === "Canceled") {
          return row[filter.id] === "Canceled";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "All"}
        >
          <option value="All">All</option>
          <option value="Consultation">Consultation</option>
          <option value="Follow Up">Follow Up</option>
          <option value="Collecting Docs">Collecting Docs</option>
          <option value="Application Ready">Application Ready</option>
          <option value="Application Submitted">Application Submitted</option>
          <option value="Info Requested">Info Requested</option>
          <option value="Offer Received">Offer Received</option>
          <option value="Provisional Offer/GTE Survey">
            Provisional Offer/GTE Survey
          </option>
          <option value="Conditional Offer">Conditional Offer</option>
          <option value="Unconditional Offer">Unconditional Offer</option>
          <option value="Coe Received">Coe Received</option>
          <option value="Refused">Refused</option>
          <option value="Canceled">Canceled</option>
        </select>
      ),
      width: 150,
    }
  );

  const handleOptionChange = (e) => {
    const option = e.target.value[0];

    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };
  React.useEffect(() => {
    if (
      columnDataloaded &&
      selectedOptions !== userDetail?.hideColumn?.educationService
    ) {
      dispatch({
        type: USER_UPDATE_REQUESTED,
        payload: {
          payload: { educationServiceColumn: selectedOptions },
          id: currentUser.id,
        },
      });
    }
  }, [selectedOptions]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <GridContainer>
              <GridItem xs={5}>
                <Button
                  onClick={() => {
                    setAddStudentModal(true);
                  }}
                  color="primary"
                >
                  Add Student
                </Button>

                <div style={{ display: "inline-block", marginLeft: "5px" }}>
                  <Button
                    title="Download CSV"
                    justIcon
                    color="info"
                    onClick={download}
                  >
                    <GetApp />
                  </Button>{" "}
                  <Button title="PDF" justIcon color="info" onClick={exportPDF}>
                    <PictureAsPdf />
                  </Button>
                  {selection && selection.length > 0 && (
                    <SmsEmailAction
                      option={STUDENT_TABLE_ACTION}
                      data={filterData}
                      selection={selection}
                    />
                  )}
                </div>
                <CSVLink
                  data={dataToDownload}
                  filename="students.csv"
                  className="hidden"
                  ref={(r) => (csvLink = r)}
                  target="_blank"
                />
              </GridItem>
              <GridItem xs={7}>
                <DateFilter handleFilter={handleDateRangeFilter} />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <InputLabel id="column-select-label">Select Columns</InputLabel>
              <Select
                labelId="column-select-label"
                id="column-select"
                multiple
                value={[]}
                onChange={handleOptionChange}
                renderValue={(selected) => selected.join(", ")}
              >
                {columns.map((data, i) => {
                  return (
                    <MenuItem key={i} value={data.Header}>
                      <Checkbox
                        checked={selectedOptions.includes(data.Header)}
                      />
                      <ListItemText primary={data.Header} />
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {isSelectTable && (
              <SelectTable
                // style={{
                //   maxWidth: "100%",
                //   overflowX: "auto",
                // }}
                ref={(r) => (reactTableRef = r)}
                keyField="id"
                toggleSelection={toggleSelection}
                selectAll={selectAll}
                selectType="checkbox"
                toggleAll={toggleAll}
                isSelected={isSelected}
                pageSizeOptions={[25, 50, 100]}
                data={filterData}
                loading={studentsFetching}
                filterable
                resizable={true}
                getTrProps={getTrProps}
                columns={columns}
                defaultPageSize={25}
                showPaginationBottom={true}
                className="-striped -highlight"
                defaultFilterMethod={filterCaseInsensitive}
              >
                {(state, makeTable, instance) => {
                  return reactTableHelper(state, makeTable, instance);
                }}
              </SelectTable>
            )}
            {!isSelectTable && (
              <ReactTable
                // style={{
                //   maxWidth: "100%",
                //   overflowX: "auto",
                // }}
                ref={(r) => (reactTableRef = r)}
                pageSizeOptions={[25, 50, 100]}
                data={filterData}
                filterable
                resizable={true}
                getTrProps={getTrProps}
                columns={columns}
                loading={studentsFetching}
                defaultPageSize={25}
                showPaginationBottom={true}
                className="-striped -highlight"
                defaultFilterMethod={filterCaseInsensitive}
              >
                {(state, makeTable, instance) => {
                  return reactTableHelper(state, makeTable, instance);
                }}
              </ReactTable>
            )}
          </CardBody>
        </Card>
      </GridItem>

      {/*Add Student*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={addStudentModal}
        disableScrollLock={true}
        TransitionComponent={Transition}
        onClose={() => setAddStudentModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        disableBackdropClick
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAddStudentModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>New Student</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <AddStudent
            open={addStudentModal}
            allUsers={activeUsers}
            allCourses={courses}
            allSources={sources}
            allStudents={students}
            allUniversities={universities}
            handleSuccess={setAddStudentModal}
          />
        </DialogContent>
      </Dialog>

      {/*Edit Student*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={editStudentModal}
        disableScrollLock={true}
        TransitionComponent={Transition}
        onClose={() => {
          setEditStudentModal(false);
          history.push("/admin/students");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setEditStudentModal(false);
              history.push("/admin/students");
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>Edit Student</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <EditStudent
            open={editStudentModal}
            allUsers={activeUsers}
            allCourses={courses}
            allSources={sources}
            allUniversities={universities}
            student={currentStudent}
            allStudents={students}
            handleSuccess={setEditStudentModal}
          />
        </DialogContent>
      </Dialog>
    </GridContainer>
  );
};

export default withTimer(StudentTables);
