import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridItem from "../../components/Grid/GridItem";
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { useSelector } from "react-redux";
import GridContainer from "../../components/Grid/GridContainer";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getInsightDataForBarChart } from "../../scripts/util";
import CardIcon from "../../components/Card/CardIcon";
import { Bar as Bar2 } from "react-chartjs-2";
import PublicIcon from "@material-ui/icons/Public";
const useStyles = makeStyles(styles);

const InsightsBarChart = () => {
  const classes = useStyles();

  const clientCountries = useSelector(
    (state) => state.analytics.clientCountries
  );

  const studentInsight = useSelector(
    (state) => state.analytics.studentAnalytics
  );
  const visaInsight = useSelector((state) => state.analytics.visaAnalytics);

  const skillInsight = useSelector((state) => state.analytics.skillAnalytics);

  const studentInsightFetching = useSelector(
    (state) => state.analytics.studentAnalyticsGetFetching
  );
  const visaInsightFetching = useSelector(
    (state) => state.analytics.visaAnalyticsGetFetching
  );
  const skillInsightFetching = useSelector(
    (state) => state.analytics.skillAnalyticsGetFetching
  );

  const insightDataForBarChart = useMemo(
    () => getInsightDataForBarChart(studentInsight, visaInsight, skillInsight),
    [studentInsight, visaInsight, skillInsight]
  );

  const getAllCountries = () => {
    if (clientCountries && Object.keys(clientCountries).length > 0) {
      const allCountries = [];
      allCountries.push(...clientCountries.lead.map((l) => l.country));
      allCountries.push(...clientCountries.student.map((l) => l.country));
      allCountries.push(...clientCountries.skill.map((l) => l.country));
      allCountries.push(...clientCountries.insurance.map((l) => l.country));
      allCountries.push(...clientCountries.visa.map((l) => l.country));
      return [...new Set(allCountries)];
    }
    return [];
  };

  const hasCountry = (obj, country) => {
    return obj.filter((e) => e.country === country).length;
  };
  const getDataSet = (countries, data) => {
    let dataSet = [];
    countries.forEach(function (country) {
      if (hasCountry(data, country)) {
        dataSet.push(data.find((e) => e.country === country).total);
      } else {
        dataSet.push(0);
      }
    });
    return dataSet;
  };

  const countryData = useMemo(() => {
    const countries = getAllCountries();
    return {
      labels: countries,
      datasets: [
        {
          label: "Lead",
          data: getDataSet(countries, clientCountries.lead),
          backgroundColor: "rgb(255, 99, 132)",
          hidden: true,
        },
        {
          label: "Student",
          data: getDataSet(countries, clientCountries.student),
          backgroundColor: "rgb(54,162,235)",
        },
        {
          label: "VisaApplicant",
          data: getDataSet(countries, clientCountries.visa),
          backgroundColor: "rgb(205,188,87)",
        },
        {
          label: "SkillAssessment",
          data: getDataSet(countries, clientCountries.skill),
          backgroundColor: "rgb(113,198,77)",
        },
        {
          label: "InsuranceApplicant",
          data: getDataSet(countries, clientCountries.insurance),
          backgroundColor: "rgb(126,63,210)",
        },
      ],
    };
  }, [clientCountries]);

  const clientByCountryOptions = {
    // maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
  };
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <PublicIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Clients by Country</h4>
            </CardHeader>
            <CardBody>
              {((clientCountries.lead && clientCountries.lead.length !== 0) ||
                (clientCountries.student &&
                  clientCountries.student.length !== 0) ||
                (clientCountries.visa && clientCountries.visa.length !== 0) ||
                (clientCountries.skill && clientCountries.skill.length !== 0) ||
                (clientCountries.insurance &&
                  clientCountries.insurance.length !== 0)) && (
                <Bar2
                  width={100}
                  height={50}
                  data={countryData}
                  options={clientByCountryOptions}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <h4 className={classes.cardIconTitle}>
                Processing Time Insights <small>(days)</small>
              </h4>
            </CardHeader>
            <CardBody>
              {!studentInsightFetching &&
                !skillInsightFetching &&
                !visaInsightFetching && (
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={insightDataForBarChart}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="min" fill="#72A98F" />
                      <Bar dataKey="average" fill="#9cadce" />
                      <Bar dataKey="max" fill="#FF6663" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default InsightsBarChart;
