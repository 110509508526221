// FUTURE: use this for google format
// const formatAddressForDisplay = (address) => {
//   if (!address) return '';

//   const STREET = address?.road ? `${address.road}, ` : '';

//   const CITY = address?.city ? `${address.city}, ` : '';
//   const POST_CODE = address?.postcode ? ` ${address.postcode}` : '';
//   const STATE = address?.state ? `${address.state}` : '';

//   return STREET + CITY + STATE + POST_CODE;
// };

export function getStateShortName(fullStateName) {
  const stateMap = {
    "Australian Capital Territory": "ACT",
    "New South Wales": "NSW",
    "Northern Territory": "NT",
    Queensland: "QLD",
    "South Australia": "SA",
    Tasmania: "TAS",
    Victoria: "VIC",
    "Western Australia": "WA",
  };

  if (!fullStateName) return "";

  return stateMap[fullStateName] || fullStateName;
}

export const formatAddressForDisplay = (result, inputValue) => {
  if (!result) return "";

  const address = result.address;

  if (!address) return "";

  let streetNumber = address.house_number || "";
  let street = address.road || "";
  const city =
    address.suburb || address.city || address.town || address.village || "";
  const state = address.state || "";
  const country = address.country || "";
  const territory = getStateShortName(address?.territory) || "";

  // If the street number is not provided separately, try to extract it from the name
  if (!streetNumber && inputValue) {
    const match = inputValue.match(/^(\d+)\s(.+?)(?:,|$)/);
    if (match) {
      streetNumber = match[1];
      street = match[2];
    }
  }
  console.log(city, address);

  const fullStreet = streetNumber ? `${streetNumber} ${street}` : street;
  const stateTerritory = state ? `${state} ${territory}` : territory || state ||  "";

  return `${fullStreet}, ${city}, ${stateTerritory}, ${country}`
    .replace(/\s+,/g, ",")
    .trim();
};
