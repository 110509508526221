import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  IMMIGRATION_CREATE_REQUESTED,
  IMMIGRATION_CREATE_FAILED,
  IMMIGRATION_CREATE_SUCCEEDED,
  IMMIGRATION_GET_ALL_REQUESTED,
  IMMIGRATION_GET_ALL_FAILED,
  IMMIGRATION_GET_ALL_SUCCEEDED,
  IMMIGRATION_UPDATE_REQUESTED,
  IMMIGRATION_UPDATE_FAILED,
  IMMIGRATION_UPDATE_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchAllImmigrations() {
  try {
    const token = yield select(getToken);
    const immigrations = yield call(remoteResource.getAllImmigrations, token);
    yield put({
      type: IMMIGRATION_GET_ALL_SUCCEEDED,
      payload: immigrations.data,
    });
  } catch (e) {
    yield put({ type: IMMIGRATION_GET_ALL_FAILED, payload: e.message });
  }
}

function* createImmigration(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const immigration = yield call(
      remoteResource.createImmigration,
      payload,
      token
    );
    yield put({
      type: IMMIGRATION_CREATE_SUCCEEDED,
      payload: immigration.data,
    });
  } catch (e) {
    yield put({ type: IMMIGRATION_CREATE_FAILED, payload: e.message });
  }
}

function* updateImmigration(action) {
  try {
    const { payload, id } = action;
    const token = yield select(getToken);
    const immigration = yield call(
      remoteResource.updateImmigration,
      id,
      payload,
      token
    );
    yield put({
      type: IMMIGRATION_UPDATE_SUCCEEDED,
      payload: immigration.data,
    });
  } catch (e) {
    yield put({ type: IMMIGRATION_UPDATE_FAILED, payload: e.message });
  }
}

export function* watchImmigration() {
  yield takeLatest(IMMIGRATION_CREATE_REQUESTED, createImmigration);
  yield takeLatest(IMMIGRATION_UPDATE_REQUESTED, updateImmigration);
  yield takeLatest(IMMIGRATION_GET_ALL_REQUESTED, fetchAllImmigrations);
}
