import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Datetime from "react-datetime";
import moment from "moment";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { convertDate, getUserName } from "../../../scripts/util";
import {
  CLEAR_ERROR,
  CLOCK_GET_REQUESTED,
  CLOCK_IN_UPDATE_REQUESTED,
} from "../../../redux/actions";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import ReactGA from "react-ga4";
import { DEFAULT_DATE_FORMAT } from "../../../scripts/const";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import { useFormik } from "formik";
import * as Yup from "yup";

import Muted from "../../../components/Typography/Muted";

const useStyles = makeStyles(styles);

export default function EmployeeContainer({
  open,
  setOpen,
  isEdit,
  editData = {},
}) {
  const initialValues = {
    id: "",
    userId: "",
    date: "",
    clockInTime: "",
    clockOutTime: "",
    breakStartTime: "",
    breakEndTime: "",
    note: "",
    totalHours: "",
    updatedBy: "",
    updatedAt: "",
    createdAt: "",
  };

  const dispatch = useDispatch();
  const classes = useStyles();

  const allUsers = useSelector((state) => state.user.users);

  const createEditSuccess = useSelector(
    (state) => state.clockIn.clockInUpdateSuccess
  );
  const createEditProgress = useSelector(
    (state) => state.clockIn.clockInUpdateFetching
  );
  const createEditFailure = useSelector(
    (state) => state.clockIn.clockInUpdateError
  );
  const errorMsg = useSelector((state) => state.clockIn.errorMsg);

  useEffect(() => {
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      for (let field of Object.keys(initialValues)) {
        if (editData[field] !== null) {
          setFieldValue(field, editData[field], false);
        }
      }
    }
  }, [editData, open]);

  useEffect(() => {
    if (createEditSuccess === true) {
      setTimeout(() => {
        setOpen(false);
      }, 700);
      dispatch({ type: CLOCK_GET_REQUESTED, payload: { id: values.userId } });
      setTimeout(() => {
        resetForm(initialValues);
        dispatch({ type: CLEAR_ERROR });
      }, 701);
    }
    if (open === false) {
      resetForm(initialValues);
    }
  }, [createEditSuccess, open]);

  // Custom Validation
  const validate = (values) => {
    const errors = {};
    if (values.clockInTime) {
      if (
        !moment(values.date, DEFAULT_DATE_FORMAT).isSame(
          moment(values.clockInTime),
          "day"
        )
      ) {
        errors.clockInTime = `Date should be ${values.date}.`;
      }
    }
    if (values.clockOutTime) {
      if (
        !moment(values.date, DEFAULT_DATE_FORMAT).isSame(
          moment(values.clockOutTime),
          "day"
        )
      ) {
        errors.clockOutTime = `Date should be ${values.date}.`;
      }
    }
    if (values.breakStartTime) {
      if (
        !moment(values.date, DEFAULT_DATE_FORMAT).isSame(
          moment(values.breakStartTime),
          "day"
        )
      ) {
        errors.breakStartTime = `Date should be ${values.date}.`;
      }
    }
    if (values.breakEndTime) {
      if (
        !moment(values.date, DEFAULT_DATE_FORMAT).isSame(
          moment(values.breakEndTime),
          "day"
        )
      ) {
        errors.breakEndTime = `Date should be ${values.date}.`;
      }
    }

    if (values.clockInTime && values.clockOutTime) {
      if (moment(values.clockOutTime).diff(moment(values.clockInTime)) < 0) {
        errors.clockOutTime = `Clock Out can not be before Clock In`;
      }
    }

    if (values.breakStartTime && values.breakEndTime) {
      if (moment(values.breakEndTime).diff(moment(values.breakStartTime)) < 0) {
        errors.breakEndTime = `Break End can not be before Break Start`;
      }
    }

    return errors;
  };

  const updatePayload = (payload) => {
    const newP = { ...payload };
    for (let key of Object.keys(newP)) {
      if (moment.isMoment(newP[key])) {
        newP[key] = newP[key].toISOString();
      }
    }
    return newP;
  };
  const onSubmit = (values) => {
    if (isEdit) {
      const payload = values;
      dispatch({
        type: CLOCK_IN_UPDATE_REQUESTED,
        payload: updatePayload(payload),
        id: values.id,
        userId: values.userId,
      });
      ReactGA.event({
        category: "ClockIn",
        action: "Update an ClockIn",
      });
    } else {
      console.log("Not implemented");
    }
  };

  const mySchema = () => {
    return Yup.object({
      id: Yup.number(),
      userId: Yup.string(),
      date: Yup.string().required("ClockIn is required"),
      clockInTime: Yup.string().required("ClockIn is required"),
      clockOutTime: Yup.string(),
      breakStartTime: Yup.string(),
      breakEndTime: Yup.string(),
      note: Yup.string(),
      totalHours: Yup.string(),
      updatedBy: Yup.string(),
      updatedAt: Yup.string(),
      createdAt: Yup.string(),
    });
  };

  const { values, touched, errors, setFieldValue, resetForm, submitForm } =
    useFormik({
      initialValues,
      validationSchema: mySchema(),
      validate,
      onSubmit,
    });

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {createEditFailure && (
                    <SnackbarContent
                      message={errorMsg || "Something went wrong."}
                      color="warning"
                    />
                  )}
                  {createEditProgress && (
                    <CustomLinearProgress color="primary" />
                  )}
                  {createEditSuccess && (
                    <SnackbarContent message="Success" color="success" />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    margin: "10px",
                    backgroundColor: "aliceblue",
                    padding: "10px",
                    fontSize: "medium",
                    fontWeight: 400,
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    Name:{" "}
                    {allUsers.find((u) => u.id == values.userId)?.firstName}
                  </div>
                  <div>Date: {values.date}</div>
                </div>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Clock In</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      value={moment(values.clockInTime)}
                      onChange={(date) => setFieldValue("clockInTime", date)}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      timeFormat="HH:mm:ss"
                    />
                  </FormControl>
                  {touched?.clockInTime && errors?.clockInTime && (
                    <div style={{ color: "red" }}>{errors.clockInTime}</div>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Clock Out</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      value={moment(values.clockOutTime)}
                      onChange={(date) => setFieldValue("clockOutTime", date)}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      timeFormat="HH:mm:ss"
                    />
                  </FormControl>
                  {touched?.clockInTime && errors?.clockOutTime && (
                    <div style={{ color: "red" }}>{errors.clockOutTime}</div>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Break Start</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      value={moment(values.breakStartTime)}
                      onChange={(date) => setFieldValue("breakStartTime", date)}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      timeFormat="HH:mm:ss"
                    />
                  </FormControl>
                  {touched?.breakStartTime && errors?.breakStartTime && (
                    <div style={{ color: "red" }}>{errors.breakStartTime}</div>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Break End</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      value={moment(values.breakEndTime)}
                      onChange={(date) => setFieldValue("breakEndTime", date)}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      timeFormat="HH:mm:ss"
                    />
                  </FormControl>
                  {touched?.breakEndTime && errors?.breakEndTime && (
                    <div style={{ color: "red" }}>{errors.breakEndTime}</div>
                  )}
                </GridItem>
              </GridContainer>
              <br />
              <br />
              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={submitForm}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
              {isEdit && (
                <>
                  <Muted>Updated At: {convertDate(values.updatedAt)}</Muted>
                  <Muted>
                    Updated By: {getUserName(values.updatedBy, allUsers)}
                  </Muted>
                </>
              )}
              {alert}
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
