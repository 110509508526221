import React from "react";

import { useDispatch, useSelector } from "react-redux";

import MaterialTable, { MTableToolbar } from "material-table";

import {
  OCCUPATION_CREATE_REQUESTED,
  OCCUPATION_DELETE_REQUESTED,
  OCCUPATION_GET_ALL_REQUESTED,
  OCCUPATION_UPDATE_REQUESTED,
} from "../../../../redux/actions";
import ReactGA from "react-ga4";

export default function OccupationList() {
  const getColumns = () => {
    return [
      {
        title: "Code",
        field: "code",
        type: "numeric",
        width: "20%",
      },
      {
        title: "Title",
        field: "title",
      },
    ];
  };
  const dispatch = useDispatch();

  const occupationList = useSelector((state) => state.occupation.occupations);

  const visaFetching = useSelector(
    (state) => state.occupation.occupationGetAllFetching
  );

  return (
    <>
      <MaterialTable
        title="Occupation List"
        columns={getColumns()}
        data={occupationList}
        options={{
          exportButton: true,
          addRowPosition: "last",
          pageSize: 50,
          pageSizeOptions: [50, 100, 200, 500, 1410],
          headerStyle: {
            backgroundColor: "#0077c2",
            color: "#FFF",
            marginTop: "15px",
          },
          sorting: true,
        }}
        style={{
          marginTop: "30px",
        }}
        editable={{
          isEditable: () => true,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const payload = { id: oldData?.id, payload: newData };

                dispatch({
                  type: OCCUPATION_UPDATE_REQUESTED,
                  payload: payload,
                });
                ReactGA.event({
                  category: "Occupation",
                  action: "Occupation Updated",
                });
              }, 200);

              setTimeout(() => {
                dispatch({ type: OCCUPATION_GET_ALL_REQUESTED });
              }, 2000);
            }),
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                dispatch({
                  type: OCCUPATION_CREATE_REQUESTED,
                  payload: newData,
                });
                ReactGA.event({
                  category: "Occupation",
                  action: "Occupation Added",
                });
              }, 200);

              setTimeout(() => {
                dispatch({ type: OCCUPATION_GET_ALL_REQUESTED });
              }, 2000);
            }),
          onRowDelete: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                dispatch({
                  type: OCCUPATION_DELETE_REQUESTED,
                  payload: newData,
                });
                ReactGA.event({
                  category: "Occupation",
                  action: "Occupation Added",
                });
              }, 200);

              setTimeout(() => {
                dispatch({ type: OCCUPATION_GET_ALL_REQUESTED });
              }, 2000);
            }),
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <h4
                style={{
                  marginLeft: "20px",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                ANZSCO - Australian and New Zealand Standard Classification of
                Occupations, 2021 Australian Update
              </h4>
              <div
                style={{
                  marginLeft: "20px",
                  paddingTop: "0px",
                  marginTop: "0px",
                }}
              >
                Released at 11:30am (Canberra time) 23 November 2021
              </div>
            </div>
          ),
        }}
      />
    </>
  );
}
