import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  CLIENT_COUNTRY_GET_FAILED,
  CLIENT_COUNTRY_GET_REQUESTED,
  CLIENT_COUNTRY_GET_SUCCEEDED,
  SKILL_ANALYTICS_GET_FAILED,
  SKILL_ANALYTICS_GET_REQUESTED,
  SKILL_ANALYTICS_GET_SUCCEEDED,
  STUDENT_ANALYTICS_GET_FAILED,
  STUDENT_ANALYTICS_GET_REQUESTED,
  STUDENT_ANALYTICS_GET_SUCCEEDED,
  VISA_ANALYTICS_GET_FAILED,
  VISA_ANALYTICS_GET_REQUESTED,
  VISA_ANALYTICS_GET_SUCCEEDED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchClientCountries() {
  try {
    const token = yield select(getToken);
    const clientCountries = yield call(
      remoteResource.getClientCountries,
      token
    );
    yield put({
      type: CLIENT_COUNTRY_GET_SUCCEEDED,
      payload: clientCountries.data,
    });
  } catch (e) {
    yield put({ type: CLIENT_COUNTRY_GET_FAILED, payload: e.message });
  }
}

function* fetchStudentAnalytics() {
  try {
    const token = yield select(getToken);
    const analytics = yield call(remoteResource.getStudentAnalytics, token);
    yield put({
      type: STUDENT_ANALYTICS_GET_SUCCEEDED,
      payload: analytics.data,
    });
  } catch (e) {
    yield put({ type: STUDENT_ANALYTICS_GET_FAILED, payload: e.message });
  }
}

function* fetchSkillAnalytics() {
  try {
    const token = yield select(getToken);
    const analytics = yield call(remoteResource.getSkillAnalytics, token);
    yield put({
      type: SKILL_ANALYTICS_GET_SUCCEEDED,
      payload: analytics.data,
    });
  } catch (e) {
    yield put({ type: SKILL_ANALYTICS_GET_FAILED, payload: e.message });
  }
}

function* fetchVisaAnalytics() {
  try {
    const token = yield select(getToken);
    const analytics = yield call(remoteResource.getVisaAnalytics, token);
    yield put({
      type: VISA_ANALYTICS_GET_SUCCEEDED,
      payload: analytics.data,
    });
  } catch (e) {
    yield put({ type: VISA_ANALYTICS_GET_FAILED, payload: e.message });
  }
}

export function* watchAnalytics() {
  yield takeLatest(CLIENT_COUNTRY_GET_REQUESTED, fetchClientCountries);
  yield takeLatest(STUDENT_ANALYTICS_GET_REQUESTED, fetchStudentAnalytics);
  yield takeLatest(SKILL_ANALYTICS_GET_REQUESTED, fetchSkillAnalytics);
  yield takeLatest(VISA_ANALYTICS_GET_REQUESTED, fetchVisaAnalytics);
}
