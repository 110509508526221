import React from "react";
import { APP_VERSION } from "../../scripts/const";

export default function Version({ isFlex = true }) {
  if (!isFlex) {
    return <span>v{APP_VERSION}</span>;
  }
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <span>v{APP_VERSION}</span>
    </div>
  );
}
