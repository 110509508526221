import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  REPORT_CREATE_REQUESTED,
  REPORT_CREATE_FAILED,
  REPORT_CREATE_SUCCEEDED,
  REPORT_GET_ALL_REQUESTED,
  REPORT_GET_ALL_FAILED,
  REPORT_GET_ALL_SUCCEEDED,
  REPORT_UPDATE_REQUESTED,
  REPORT_UPDATE_FAILED,
  REPORT_UPDATE_SUCCEEDED,
  REPORT_REFRESH_SUCCEEDED,
  REPORT_REFRESH_FAILED,
  REPORT_REFRESH_REQUESTED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchAllReports() {
  try {
    const token = yield select(getToken);
    const reports = yield call(remoteResource.getAllReports, token);
    yield put({
      type: REPORT_GET_ALL_SUCCEEDED,
      payload: reports.data,
    });
  } catch (e) {
    yield put({
      type: REPORT_GET_ALL_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* createReport(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const report = yield call(remoteResource.createReport, payload, token);
    yield put({
      type: REPORT_CREATE_SUCCEEDED,
      payload: report.data,
    });
  } catch (e) {
    yield put({ type: REPORT_CREATE_FAILED, payload: e.response.data.message });
  }
}

function* updateReport(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const report = yield call(remoteResource.updateReport, id, payload, token);
    yield put({
      type: REPORT_UPDATE_SUCCEEDED,
      payload: report.data,
    });
  } catch (e) {
    yield put({ type: REPORT_UPDATE_FAILED, payload: e.response.data.message });
  }
}

function* refreshReport(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    yield call(remoteResource.refreshReport, id, token);
    yield put({
      type: REPORT_REFRESH_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: REPORT_REFRESH_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchReport() {
  yield takeLatest(REPORT_CREATE_REQUESTED, createReport);
  yield takeLatest(REPORT_UPDATE_REQUESTED, updateReport);
  yield takeLatest(REPORT_GET_ALL_REQUESTED, fetchAllReports);
  yield takeLatest(REPORT_REFRESH_REQUESTED, refreshReport);
}
