const cardStyle = {
  clockInButton: {
    width: "80px",
    height: "35px",
    fontSize: "20px",
    backgroundColor: "#0a4c68",
    marginRight: "10px",
    marginTop: "5px",
    paddingLeft: "12px",
    color: "#c5d0d4",
  },
  clockInButtonNoNumber: {
    width: "80px",
    height: "35px",
    fontSize: "15px",
    backgroundColor: "#0d415a",
    marginRight: "10px",
    marginTop: "5px",
    paddingLeft: "12px",
    color: "#c5d0d4",
    border: "none",
  },
};

export default cardStyle;
