import {
  INSURANCE_APPLICANT_CREATE_REQUESTED,
  INSURANCE_APPLICANT_CREATE_FAILED,
  INSURANCE_APPLICANT_CREATE_SUCCEEDED,
  INSURANCE_APPLICANT_DELETE_REQUESTED,
  INSURANCE_APPLICANT_DELETE_FAILED,
  INSURANCE_APPLICANT_DELETE_SUCCEEDED,
  INSURANCE_APPLICANT_GET_ALL_REQUESTED,
  INSURANCE_APPLICANT_GET_ALL_FAILED,
  INSURANCE_APPLICANT_GET_ALL_SUCCEEDED,
  INSURANCE_APPLICANT_GET_REQUESTED,
  INSURANCE_APPLICANT_GET_FAILED,
  INSURANCE_APPLICANT_GET_SUCCEEDED,
  INSURANCE_APPLICANT_LOG_GET_REQUESTED,
  INSURANCE_APPLICANT_LOG_GET_FAILED,
  INSURANCE_APPLICANT_LOG_GET_SUCCEEDED,
  INSURANCE_APPLICANT_UPDATE_REQUESTED,
  INSURANCE_APPLICANT_UPDATE_FAILED,
  INSURANCE_APPLICANT_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  insuranceApplicantCreateError: false,
  insuranceApplicantCreateFetching: false,
  insuranceApplicantCreateSuccess: false,

  insuranceApplicantDeleteError: false,
  insuranceApplicantDeleteFetching: false,
  insuranceApplicantDeleteSuccess: false,

  insuranceApplicantUpdateError: false,
  insuranceApplicantUpdateFetching: false,
  insuranceApplicantUpdateSuccess: false,

  insuranceApplicantGetError: false,
  insuranceApplicantGetFetching: false,
  insuranceApplicantGetSuccess: false,

  insuranceApplicantLogGetError: false,
  insuranceApplicantLogGetFetching: false,
  insuranceApplicantLogGetSuccess: false,

  insuranceApplicantGetAllError: false,
  insuranceApplicantGetAllSuccess: false,
};

const defaultState = {
  insuranceApplicants: [],
  insuranceApplicantLog: [],
  insuranceApplicantDetail: {},
  errorMsg: "",
  insuranceApplicantGetAllFetching: false,
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case INSURANCE_APPLICANT_CREATE_REQUESTED:
      return {
        ...state,
        insuranceApplicantCreateFetching: true,
        insuranceApplicantCreateError: false,
        insuranceApplicantCreateSuccess: false,
      };
    case INSURANCE_APPLICANT_CREATE_FAILED:
      return {
        ...state,
        insuranceApplicantCreateFetching: false,
        insuranceApplicantCreateError: true,
        insuranceApplicantCreateSuccess: false,
        errorMsg: action.payload,
      };
    case INSURANCE_APPLICANT_CREATE_SUCCEEDED:
      return {
        ...state,
        insuranceApplicantDetail: action.payload,
        insuranceApplicantCreateFetching: false,
        insuranceApplicantCreateError: false,
        insuranceApplicantCreateSuccess: true,
      };

    case INSURANCE_APPLICANT_DELETE_REQUESTED:
      return {
        ...state,
        insuranceApplicantDeleteFetching: true,
        insuranceApplicantDeleteError: false,
        insuranceApplicantDeleteSuccess: false,
      };
    case INSURANCE_APPLICANT_DELETE_FAILED:
      return {
        ...state,
        insuranceApplicantDeleteFetching: false,
        insuranceApplicantDeleteError: true,
        insuranceApplicantDeleteSuccess: false,
        errorMsg: action.payload,
      };
    case INSURANCE_APPLICANT_DELETE_SUCCEEDED:
      return {
        ...state,
        insuranceApplicantDeleteFetching: false,
        insuranceApplicantDeleteError: false,
        insuranceApplicantDeleteSuccess: true,
      };

    case INSURANCE_APPLICANT_UPDATE_REQUESTED:
      return {
        ...state,
        insuranceApplicantUpdateFetching: true,
        insuranceApplicantUpdateError: false,
        insuranceApplicantUpdateSuccess: false,
      };
    case INSURANCE_APPLICANT_UPDATE_FAILED:
      return {
        ...state,
        insuranceApplicantUpdateFetching: false,
        insuranceApplicantUpdateError: true,
        insuranceApplicantUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case INSURANCE_APPLICANT_UPDATE_SUCCEEDED:
      return {
        ...state,
        insuranceApplicantDetail: action.payload,
        insuranceApplicantUpdateFetching: false,
        insuranceApplicantUpdateError: false,
        insuranceApplicantUpdateSuccess: true,
      };

    case INSURANCE_APPLICANT_GET_REQUESTED:
      return {
        ...state,
        insuranceApplicantGetFetching: true,
        insuranceApplicantGetError: false,
      };
    case INSURANCE_APPLICANT_GET_FAILED:
      return {
        ...state,
        insuranceApplicantGetFetching: false,
        insuranceApplicantGetError: true,
      };
    case INSURANCE_APPLICANT_GET_SUCCEEDED:
      return {
        ...state,
        insuranceApplicantDetail: action.payload,
        insuranceApplicantGetFetching: false,
        insuranceApplicantGetError: false,
      };

    case INSURANCE_APPLICANT_LOG_GET_REQUESTED:
      return {
        ...state,
        insuranceApplicantLogGetFetching: true,
        insuranceApplicantLogGetError: false,
      };
    case INSURANCE_APPLICANT_LOG_GET_FAILED:
      return {
        ...state,
        insuranceApplicantLogGetFetching: false,
        insuranceApplicantLogGetError: true,
      };
    case INSURANCE_APPLICANT_LOG_GET_SUCCEEDED:
      return {
        ...state,
        insuranceApplicantLog: action.payload,
        insuranceApplicantLogGetFetching: false,
        insuranceApplicantLogGetError: false,
      };

    case INSURANCE_APPLICANT_GET_ALL_REQUESTED:
      return {
        ...state,
        insuranceApplicantGetAllFetching: true,
        insuranceApplicantGetAllError: false,
        insuranceApplicantGetAllSuccess: false,
      };
    case INSURANCE_APPLICANT_GET_ALL_FAILED:
      return {
        ...state,
        insuranceApplicantGetAllFetching: false,
        insuranceApplicantGetAllError: true,
        insuranceApplicantGetAllSuccess: false,
      };
    case INSURANCE_APPLICANT_GET_ALL_SUCCEEDED:
      return {
        ...state,
        insuranceApplicants: action.payload,
        insuranceApplicantGetAllFetching: false,
        insuranceApplicantGetAllError: false,
        insuranceApplicantGetAllSuccess: true,
      };
    default:
      return state;
  }
}
