import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ReactGA from "react-ga4";
import GridItem from "../../../../components/Grid/GridItem";
import CardHeader from "../../../../components/Card/CardHeader";
import Card from "../../../../components/Card/Card";

import GridContainer from "../../../../components/Grid/GridContainer";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { useDispatch, useSelector } from "react-redux";
import {
  SETTING_GET_REQUESTED,
  SETTING_UPDATE_REQUESTED,
} from "../../../../redux/actions";
import CardBody from "../../../../components/Card/CardBody";
import CustomLinearProgress from "../../../../components/CustomLinearProgress/CustomLinearProgress";
import { DEFAULT_DATE_FORMAT } from "../../../../scripts/const";
import moment from "moment";
const useStyles = makeStyles(styles);

export default function Setting(props) {
  const setting = useSelector((state) => state.setting.settingDetail);
  const updateProgress = useSelector(
    (state) => state.setting.settingUpdateFetching
  );

  const handleSelect = (name, value) => {
    ReactGA.event({
      category: "EMAIL-SMS",
      action: `Toggle ${[name]}: ${value}`,
    });
    const data = { ...setting, [name]: value };
    dispatch({ type: SETTING_UPDATE_REQUESTED, payload: data });

    // setTimeout(() => {
    //   dispatch({ type: SETTING_UPDATE_REQUESTED, payload: data });
    // }, 30);
  };

  const handleDateSelect = (date, name) => {
    if (!moment.isMoment(date)) {
      return;
    }
    const value = date.format(DEFAULT_DATE_FORMAT).toString();
    ReactGA.event({
      category: "EMAIL-SMS",
      action: `Toggle ${[name]}: ${value}`,
    });

    const data = {
      ...setting,
      [name]: value,
    };
    dispatch({ type: SETTING_UPDATE_REQUESTED, payload: data });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: SETTING_GET_REQUESTED });
  }, []);

  const dynamicLabel = (realValue, defaultValue) => {
    if (!realValue) {
      if (defaultValue == 0) {
        return `On same day`;
      }
      return `Before ${defaultValue} days`;
    }
    if (realValue == 0) {
      return `On same day`;
    }
    return `Before ${realValue} days`;
  };

  const dynamicLabelForDate = (realValue, defaultValue) => {
    if (!realValue) {
      return `Every year on ${defaultValue}`;
    }
    const mDate = moment(realValue, DEFAULT_DATE_FORMAT).format("MMM DD");
    return `Every year on ${mDate}`;
  };

  const smsObj = [
    {
      label: "Tax Notice",
      subLabel: dynamicLabelForDate(
        setting.smsTaxNoticeNotificationDay,
        "June 15"
      ),
      name: "smsTaxNotice",
      color: "warning",
      extra: {
        type: "date",
        name: "smsTaxNoticeNotificationDay",
      },
    },
    {
      label: "Birthday",
      name: "smsBirthday",
      subLabel: "On Birth Date",
      color: "warning",
    },
    {
      label: "Student FeeDue",
      subLabel: dynamicLabel(setting.smsFeeDueNotificationDay, 21),
      name: "smsFeeDue",
      color: "warning",
      extra: {
        type: "number",
        name: "smsFeeDueNotificationDay",
      },
    },
    {
      label: "Visa Expiry",
      subLabel: dynamicLabel(setting.smsVisaExpiryNotificationDay, 60),
      name: "smsVisaExpiry",
      color: "warning",
      extra: {
        type: "number",
        name: "smsVisaExpiryNotificationDay",
      },
    },
  ];

  const emailObj = [
    {
      label: "Tax Notice",
      subLabel: dynamicLabelForDate(
        setting.emailTaxNoticeNotificationDay,
        "June 15"
      ),
      name: "emailTaxNotice",
      color: "info",
      extra: {
        type: "date",
        name: "emailTaxNoticeNotificationDay",
      },
    },
    {
      label: "Birthday",
      name: "emailBirthday",
      subLabel: "On Birth Date",
      color: "info",
    },
    {
      label: "Student FeeDue",
      subLabel: dynamicLabel(setting.emailFeeDueNotificationDay, 21),
      name: "emailFeeDue",
      color: "info",
      extra: {
        type: "number",
        name: "emailFeeDueNotificationDay",
      },
    },
    {
      label: "Visa Expiry",
      subLabel: dynamicLabel(setting.emailVisaExpiryNotificationDay, 60),
      name: "emailVisaExpiry",
      color: "info",
      extra: {
        type: "number",
        name: "emailVisaExpiryNotificationDay",
      },
    },
    {
      label: "Visa Request Due",
      subLabel: dynamicLabel(setting.emailVisaRequestedDueNotificationDay, 3),
      name: "emailVisaRequestedDue",
      color: "info",
      extra: {
        type: "number",
        name: "emailVisaRequestedDueNotificationDay",
      },
    },
    {
      label: "Skill Assessment Request Due",
      subLabel: dynamicLabel(setting.emailSkillRequestedDueNotificationDay, 1),
      name: "emailSkillRequestedDue",
      color: "info",
      extra: {
        type: "number",
        name: "emailSkillRequestedDueNotificationDay",
      },
    },
    {
      label: "Lead Follow Up",
      subLabel: dynamicLabel(setting.emailLeadFollowUpNotificationDay, 0),
      name: "emailLeadFollowUp",
      color: "info",
      extra: {
        type: "number",
        name: "emailLeadFollowUpNotificationDay",
      },
    },
    {
      label: "Student Follow Up",
      subLabel: dynamicLabel(setting.emailStudentFollowUpNotificationDay, 0),
      name: "emailStudentFollowUp",
      color: "info",
      extra: {
        type: "number",
        name: "emailStudentFollowUpNotificationDay",
      },
    },
    {
      label: "Student Fiscal Report",
      subLabel: "Monthly Target vs Actual Student enrollment report",
      name: "emailStudentFiscalReport",
      color: "info",
    },
  ];
  const classes = useStyles();
  return (
    <div>
      <Card>
        <CardHeader
          color={"warning"}
          stats
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <strong>SMS</strong>
          {updateProgress && <CustomLinearProgress size={"1.5rem"} />}
        </CardHeader>
        <CardBody>
          <GridContainer style={{ marginTop: "20px" }}>
            {smsObj &&
              smsObj.map((m, i) => {
                return (
                  <GridItem
                    key={i}
                    xs={12}
                    sm={12}
                    md={4}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <CardHeader
                      color={setting[m.name] ? "success" : "info"}
                      stats
                    >
                      <legend>{m.label}</legend>
                      {m.subLabel && <small>{m.subLabel}</small>}
                      <div
                        className={classes.block}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={setting[m.name] || false}
                              onChange={(event) =>
                                handleSelect(m.name, event.target.checked)
                              }
                              value={setting[m.name] || false}
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={setting[m.name] ? "ON" : "OFF"}
                        />
                        {m?.extra && m.extra?.type === "number" && (
                          <input
                            style={{
                              width: "50px",
                              height: "25px",
                              borderRadius: "8px",
                            }}
                            type="number"
                            name={m.extra.name}
                            onBlur={(event) => {
                              if (!event.target.value) {
                                return;
                              }
                              handleSelect(m.extra.name, event.target.value);
                              event.target.value = "";
                            }}
                          />
                        )}
                        {m?.extra && m.extra?.type === "date" && (
                          <input
                            style={{
                              width: "50px",
                              height: "25px",
                              borderRadius: "8px",
                            }}
                            type="date"
                            name={m.extra.name}
                            onChange={(event) => {
                              if (!event.target.value) {
                                return;
                              }
                              const value = moment(event.target.value)
                                .format(DEFAULT_DATE_FORMAT)
                                .toString();
                              handleSelect(m.extra.name, value);
                              event.target.value = "";
                            }}
                          />
                        )}
                      </div>
                    </CardHeader>
                  </GridItem>
                );
              })}
          </GridContainer>
        </CardBody>
      </Card>
      <GridContainer style={{ margin: "20px" }}></GridContainer>
      <Card>
        <CardHeader
          color={"warning"}
          stats
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <strong>Email</strong>
          {updateProgress && <CustomLinearProgress size={"1.5rem"} />}
        </CardHeader>
        <CardBody>
          <GridContainer
            style={{
              marginTop: "20px",
            }}
          >
            {emailObj &&
              emailObj.map((m, i) => {
                return (
                  <GridItem
                    key={i}
                    xs={12}
                    sm={12}
                    md={4}
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <CardHeader color={setting[m.name] ? "success" : "info"}>
                      <legend>{m.label}</legend>
                      {m.subLabel && <small>{m.subLabel}</small>}
                      <div
                        className={classes.block}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              checked={setting[m.name] || false}
                              onChange={(event) =>
                                handleSelect(m.name, event.target.checked)
                              }
                              value={setting[m.name] || false}
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={setting[m.name] ? "ON" : "OFF"}
                        />
                        {m?.extra && m.extra?.type === "number" && (
                          <input
                            style={{
                              width: "50px",
                              height: "25px",
                              borderRadius: "8px",
                            }}
                            type="number"
                            name={m.extra.name}
                            onBlur={(event) => {
                              if (!event.target.value) {
                                return;
                              }
                              handleSelect(m.extra.name, event.target.value);
                              event.target.value = "";
                            }}
                          />
                        )}
                        {m?.extra && m.extra?.type === "date" && (
                          <input
                            style={{
                              width: "50px",
                              height: "25px",
                              borderRadius: "8px",
                            }}
                            type="date"
                            name={m.extra.name}
                            onChange={(event) => {
                              if (!event.target.value) {
                                return;
                              }
                              const value = moment(event.target.value)
                                .format(DEFAULT_DATE_FORMAT)
                                .toString();
                              handleSelect(m.extra.name, value);
                              event.target.value = "";
                            }}
                          />
                        )}
                      </div>
                    </CardHeader>
                  </GridItem>
                );
              })}
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
}
