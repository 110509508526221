import React, { useEffect } from "react";
import Employee from "./Employee";
import { useDispatch, useSelector } from "react-redux";
import withTimer from "../../../components/Timer/withTimer";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import { DEFAULT_DATE_FORMAT, TINY_INIT } from "../../../scripts/const";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getDropDown, isValidDate } from "../../../scripts/util";
import { Editor } from "@tinymce/tinymce-react";
import Button from "../../../components/CustomButtons/Button";
import ReactGA from "react-ga4";
import {
  LEAVE_CREATE_REQUESTED,
  LEAVE_GET_ALL_REQUESTED,
  LEAVE_GET_USER_REQUESTED,
} from "../../../redux/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { useStyles } from "@material-ui/pickers/DatePicker/DatePickerToolbar";
import FileUpload from "../../../components/CustomUpload/FileUpload";

const TimeSheet = () => {
  const userId = useSelector((state) => state.login.loginUser.detail.id);
  const apiError = useSelector((state) => state.leave.leaveCreateError);
  const apiSuccess = useSelector((state) => state.leave.leaveCreateSuccess);
  const apiProgress = useSelector((state) => state.leave.leaveCreateFetching);
  const dispatch = useDispatch();
  const classes = useStyles();

  const initialValues = {
    id: "",
    type: "Sick",
    startDate: "",
    endDate: "",
    hoursPerDay: "8",
    managerNote: "",
    attachmentURL: "",
    note: "",
  };

  // Custom Validation
  const validate = (values) => {
    const errors = {};

    if (values.startDate && values.startDate !== "") {
      if (!isValidDate(values.startDate)) {
        errors.startDate = "Start Date is Invalid";
      }
    }

    if (values.endDate && values.endDate !== "") {
      if (!isValidDate(values.endDate)) {
        errors.endDate = "End Date is Invalid";
      }
    }

    if (values.endDate && values.startDate) {
      const startM = moment(values.startDate, DEFAULT_DATE_FORMAT);
      const endM = moment(values.endDate, DEFAULT_DATE_FORMAT);
      if (startM > endM) {
        errors.endDate = "End Date must be greater than Start Date";
      }
    }
    return errors;
  };
  const onSubmit = (values) => {
    const payload = values;
    dispatch({
      type: LEAVE_CREATE_REQUESTED,
      payload,
    });
    ReactGA.event({
      category: "Leave",
      action: "New Leave Requested",
    });
  };

  const mySchema = () => {
    return Yup.object({
      id: Yup.number(),
      endDate: Yup.string().required("End Date is required"),
      startDate: Yup.string().required("Start Date is required"),
      type: Yup.string(),
      note: Yup.string(),
      managerNote: Yup.string(),
      attachmentURL: Yup.string(),
      hoursPerDay: Yup.string().required("Required"),
    });
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
    submitForm,
  } = useFormik({
    initialValues,
    validationSchema: mySchema(),
    validate,
    onSubmit,
  });

  useEffect(() => {
    if (values.startDate) {
      setFieldValue("endDate", values.startDate);
    }
  }, [values.startDate]);

  useEffect(() => {
    if (apiSuccess) {
      const payload = { userId };
      dispatch({ type: LEAVE_GET_USER_REQUESTED, payload });
      dispatch({ type: LEAVE_GET_ALL_REQUESTED, payload });
    }
  }, [apiSuccess]);

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      const dateStr = date.format(DEFAULT_DATE_FORMAT).toString();
      setFieldValue(name, dateStr);
    } else {
      setFieldValue(name, date);
    }
  };

  function getTotalHoursCount() {
    const spanStyle = { color: "#00bcd4" };
    const { startDate, endDate, hoursPerDay } = values;
    const getTotalHours = () => {
      const endDateM = moment(endDate, DEFAULT_DATE_FORMAT);
      const startDateM = moment(startDate, DEFAULT_DATE_FORMAT);
      const dateDiff = endDateM.diff(startDateM, "days") + 1;
      return Math.round(dateDiff * hoursPerDay * 100) / 100;
    };

    return (
      <h3
        style={{
          textAlign: "center",
          color: "#bdbdbd",
          // lineHeight: 1.5,
          padding: "0 10px",
          fontFamily: "Roboto",
        }}
      >
        <span>
          Total Hours{" "}
          <span style={spanStyle}>
            {" "}
            {startDate && endDate && hoursPerDay ? getTotalHours() : "N/A"}{" "}
          </span>
        </span>
      </h3>
    );
  }

  const requestContainer = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {apiError && (
                <SnackbarContent
                  message={"Something went wrong"}
                  color="warning"
                />
              )}
              {apiProgress && <CustomLinearProgress color="primary" />}
              {apiSuccess && (
                <SnackbarContent message="Leave Requested" color="success" />
              )}
            </GridItem>
          </GridContainer>
          {!apiSuccess && (
            <>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel htmlFor="simple-select">Type *</InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={values.type}
                      onChange={(e) => setFieldValue("type", e.target.value)}
                      inputProps={{
                        name: "type",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Leave Type
                      </MenuItem>
                      {getDropDown("leave-type").map((val, i) => {
                        return (
                          <MenuItem
                            key={i}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={val}
                          >
                            {val}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {touched?.type && errors?.type && (
                      <div style={{ color: "red" }}>{errors.type}</div>
                    )}
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer style={{ marginTop: "15px" }}>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Start Date *
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={values.startDate}
                      onChange={(moment) =>
                        handleDatePicker(moment, "startDate")
                      }
                      inputProps={{
                        name: "startDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                  {errors?.startDate && (
                    <div style={{ color: "red" }}>{errors.startDate}</div>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>End Date</InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={values.endDate}
                      onChange={(moment) => handleDatePicker(moment, "endDate")}
                      inputProps={{
                        name: "endDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                  {errors?.endDate && (
                    <div style={{ color: "red" }}>{errors.endDate}</div>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    error={!!(touched.hoursPerDay && errors.hoursPerDay)}
                    helperText={
                      touched.hoursPerDay && errors.hoursPerDay
                        ? errors.hoursPerDay
                        : ""
                    }
                    labelText="Hours Per Day"
                    id="hoursPerDay"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onBlur: handleBlur,
                      value: values.hoursPerDay,
                      onChange: handleChange,
                      name: "hoursPerDay",
                      type: "number",
                    }}
                  />
                </GridItem>
                {getTotalHoursCount()}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={values.note || ""}
                    init={TINY_INIT}
                    onEditorChange={(value) => setFieldValue("note", value)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      backgroundColor: "#B0BEC5",
                      padding: "20px",
                      borderRadius: "25px",
                      marginTop: "10px",
                    }}
                  >
                    <h4>Attachment:</h4>
                    <FileUpload
                      autoUpload
                      setFileUrl={(fileUrl) =>
                        setFieldValue("attachmentURL", fileUrl)
                      }
                      fileUrl={values.attachmentURL}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <Button color="info" onClick={submitForm}>
                {"Request"}
              </Button>
            </>
          )}
        </GridItem>
      </GridContainer>
    );
  };
  return (
    <>
      <Employee
        userId={userId}
        timeSheet
        leaveRequestContainer={requestContainer()}
        resetForm={resetForm}
        noAccess
      />
    </>
  );
};

export default withTimer(TimeSheet);
