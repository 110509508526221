import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import Close from "@material-ui/icons/Backspace";
import SweetAlert from "react-bootstrap-sweetalert";

import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import moment from "moment";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import AttendanceRoom from "views/attendanceRoom/index";

import GridContainer from "../../../components/Grid/GridContainer";
import clockInPageStyle from "assets/jss/material-dashboard-pro-react/views/clockInPageStyle.js";
import ClockInButton from "../../../components/ClockIn/ClockInButton";
import animationData from "./stop-clock-lottie.json";
import welcomeDate from "./welcome-lottie.json";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import {
  CLEAR_ERROR,
  CLOCK_BREAK_END_REQUESTED,
  CLOCK_BREAK_START_REQUESTED,
  CLOCK_IN_REQUESTED,
  CLOCK_LOGIN_REQUESTED,
  CLOCK_OUT_REQUESTED,
} from "../../../redux/actions";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import ReactGA from "react-ga4";
import Card from "../../../components/Card/Card";
import CustomInput from "../../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { LOGIN_OPTION } from "./const";
import {
  CLOCKIN_AUTH,
  COMPANY_NAME,
  LOGO_HORIZONTAL,
} from "../../../scripts/const";

const { BRANCH_NAME } = require("../../../scripts/const");

const useStyles = makeStyles(clockInPageStyle);

const CLOCKIN_AUTH_NAME = "clockin_auth";

export default function ClockIn() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [clockinAuth, setClockinAuth] = useState(null);
  const [clockinAuthState, setClockinAuthState] = useState(null);

  const [isLoginValid, setIsLoginValid] = useState(null);

  const handleClockinAuthEntry = (e) => {
    setClockinAuth(e.target.value);
  };
  const isUserAuth = () => {
    const userClockInAuth = localStorage.getItem(CLOCKIN_AUTH_NAME);
    return userClockInAuth === CLOCKIN_AUTH;
  };
  const handleAuth = () => {
    if (clockinAuth !== CLOCKIN_AUTH) {
      setClockinAuthState("error");
    } else {
      localStorage.setItem(CLOCKIN_AUTH_NAME, clockinAuth);
      setIsLoginValid(true);
    }
  };
  const passwordEntryAlert = () => {
    return (
      <div>
        <GridContainer
          className={classes.clockInContainer}
          justify={"center"}
          style={{}}
        >
          <GridItem xs={12} sm={12} md={9}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                backgroundColor: "#F0F0F0",
                padding: "10px",
                borderRadius: "20px",
              }}
            >
              <CustomInput
                style={{
                  color: "white",
                }}
                error={clockinAuthState === "error"}
                id="password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: handleClockinAuthEntry,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon
                        className={classes.inputAdornmentIcon}
                        style={{
                          color: clockinAuthState === "error" ? "red" : "",
                        }}
                      >
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  autoComplete: "off",
                }}
              />
              <div>
                <Button color="primary" block onClick={() => handleAuth()}>
                  Unlock
                </Button>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  };
  const [currentTime, setCurrentTime] = useState(moment().format("llll"));
  const [code, setCode] = useState(new Array(4).fill(""));
  const [currentIndex, setCurrentIndex] = useState(0);

  const clockInAuth = useSelector((state) => state.clockIn.clockInAuth);

  const loginProgress = useSelector(
    (state) => state.clockIn.clockLoginFetching
  );
  const loginSuccess = useSelector((state) => state.clockIn.clockLoginSuccess);

  const clockInProgress = useSelector((state) => state.clockIn.clockInFetching);
  const clockInSuccess = useSelector((state) => state.clockIn.clockInSuccess);

  const clockOutProgress = useSelector(
    (state) => state.clockIn.clockOutFetching
  );
  const clockOutSuccess = useSelector((state) => state.clockIn.clockOutSuccess);

  const breakStartProgress = useSelector(
    (state) => state.clockIn.breakStartFetching
  );
  const breakStartSuccess = useSelector(
    (state) => state.clockIn.breakStartSuccess
  );

  const breakEndProgress = useSelector(
    (state) => state.clockIn.breakEndFetching
  );
  const breakEndSuccess = useSelector((state) => state.clockIn.breakEndSuccess);

  const errMsg = useSelector((state) => state.clockIn.errorMsg);
  const [clockProgress, setClockProgress] = useState(false);
  const [clockSuccess, setClockSuccess] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const [loginOption, setloginOption] = React.useState("");

  const autoCloseAlert = () => {
    setAlert(
      <SweetAlert
        success
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        showConfirm={false}
      />
    );
    setTimeout(hideAlert, 2000);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    if (clockSuccess) {
      autoCloseAlert();
    }
  }, [clockSuccess]);
  // in, out, break-start, break-end
  const handleClockIn = (action) => {
    if (action === "in") {
      ReactGA.event({
        category: "Clock-In",
        action: "User Clocked In",
      });
      dispatch({ type: CLOCK_IN_REQUESTED });
    } else if (action === "out") {
      ReactGA.event({
        category: "Clock-In",
        action: "User Clocked Out",
      });
      dispatch({ type: CLOCK_OUT_REQUESTED });
    } else if (action === "break-start") {
      ReactGA.event({
        category: "Clock-In",
        action: "User Break Start",
      });
      dispatch({ type: CLOCK_BREAK_START_REQUESTED });
    } else if (action === "break-end") {
      ReactGA.event({
        category: "Clock-In",
        action: "User Break End",
      });
      dispatch({ type: CLOCK_BREAK_END_REQUESTED });
    }
  };

  const resetCode = () => {
    setCode(["", "", "", ""]);
    setCurrentIndex(0);
  };

  const close = () => {
    dispatch({ type: CLEAR_ERROR });
    resetCode();
  };

  useEffect(() => {
    if (
      clockInSuccess ||
      clockOutSuccess ||
      breakEndSuccess ||
      breakStartSuccess
    ) {
      setClockSuccess(true);
      resetCode();
      setTimeout(() => {
        dispatch({ type: CLEAR_ERROR });
      }, 2300);
    } else {
      setClockSuccess(false);
    }
  }, [clockInSuccess, clockOutSuccess, breakEndSuccess, breakStartSuccess]);

  useEffect(() => {
    if (loginSuccess) {
      resetCode();
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (
      clockInProgress ||
      clockOutProgress ||
      breakStartProgress ||
      breakEndProgress
    ) {
      setClockProgress(true);
    } else {
      setClockProgress(false);
    }
  }, [clockInProgress, clockOutProgress, breakStartProgress, breakEndProgress]);

  const isClockedOut = () => {
    return clockInAuth.clockIn && clockInAuth.clockIn.clockOutTime;
  };

  const isClockedIn = () => {
    return (
      clockInAuth.clockIn && clockInAuth.clockIn.clockInTime && !isClockedOut()
    );
  };

  const isInBreak = () => {
    return (
      clockInAuth.clockIn &&
      clockInAuth.clockIn.breakStartTime &&
      !clockInAuth.clockIn.breakEndTime
    );
  };

  const isBreakFinished = () => {
    return (
      clockInAuth.clockIn &&
      clockInAuth.clockIn.breakStartTime &&
      clockInAuth.clockIn.breakEndTime
    );
  };

  const shouldDisplayClockIn = () => {
    return clockInAuth && clockInAuth.clockIn === null;
  };

  const shouldDisplayClockOut = () => {
    return (
      clockInAuth && clockInAuth.clockIn && !isClockedOut() && !isInBreak()
    );
  };

  const shouldDisplayBreakStart = () => {
    return (
      clockInAuth &&
      clockInAuth.clockIn &&
      isClockedIn(clockInAuth.clockIn) &&
      !isClockedOut(clockInAuth.clockIn) &&
      clockInAuth.clockIn.breakStartTime === null
    );
  };

  const shouldDisplayBreakEnd = () => {
    return (
      clockInAuth &&
      clockInAuth.clockIn &&
      isClockedIn() &&
      !isClockedOut() &&
      isInBreak()
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("llll"));
    }, 1000);
    return () => clearInterval(interval);
  });

  const codeValid = () => {
    return code.join("").length === 4;
  };

  const handleLogin = () => {
    ReactGA.event({
      category: "Clock-In",
      action: "Clock LoggedIn",
    });
    const clockInCode = code.join("");
    dispatch({ type: CLOCK_LOGIN_REQUESTED, payload: { clockInCode } });
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);
    if (index < 4) {
      setCurrentIndex(index++);
    }
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleClick = (element, value) => {
    if (isNaN(value)) return false;
    if (currentIndex > 3) {
      return;
    }
    setCode([...code.map((d, idx) => (idx === currentIndex ? value : d))]);
    if (currentIndex < 4) {
      setCurrentIndex(currentIndex + 1);
    }
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const handleDelete = (element) => {
    if (currentIndex <= 0) {
      return;
    }

    setCode([...code.map((d, idx) => (idx === currentIndex - 1 ? "" : d))]);
    if (currentIndex >= 1) {
      setCurrentIndex(currentIndex - 1);
    }
    //Focus next input
    if (element.previousSibling) {
      element.previousSibling.focus();
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const renderHeader = () => {
    return (
      <GridContainer
        className={classes.clockInContainer}
        justify={"space-between"}
      >
        <GridItem xs={12} sm={12}>
          <div className={classes.titleContainer}>
            <GridContainer justifyContent="center">
              <GridItem xs={4} sm={4}>
                <img className={classes.imgLogo} src={LOGO_HORIZONTAL} />
              </GridItem>
            </GridContainer>
            <h3 className={classes.subTitle}>Time Clock - {BRANCH_NAME}</h3>
            <h4>{currentTime}</h4>
            {!loginSuccess && (
              <Lottie options={defaultOptions} height={100} width={100} />
            )}
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoginPage = () => {
    return (
      <GridContainer
        className={classes.clockInContainer}
        justify={"space-between"}
      >
        <GridItem xs={12} sm={12}>
          {code.map((data, index) => {
            return (
              <input
                className={classes.codeField}
                type="text"
                name="otp"
                maxLength="1"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
              />
            );
          })}
        </GridItem>
        <GridContainer className={classes.clockInPadContainer}>
          <GridItem xs={12} sm={12}>
            <ClockInButton
              name="1"
              handleClick={(element) => {
                return handleClick(element, 1);
              }}
            />
            <ClockInButton
              name="2"
              handleClick={(element) => {
                return handleClick(element, 2);
              }}
            />
            <ClockInButton
              name="3"
              handleClick={(element) => {
                return handleClick(element, 3);
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12}>
            <ClockInButton
              name="4"
              handleClick={(element) => {
                return handleClick(element, 4);
              }}
            />
            <ClockInButton
              name="5"
              handleClick={(element) => {
                return handleClick(element, 5);
              }}
            />
            <ClockInButton
              name="6"
              handleClick={(element) => {
                return handleClick(element, 6);
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12}>
            <ClockInButton
              name="7"
              handleClick={(element) => {
                return handleClick(element, 7);
              }}
            />
            <ClockInButton
              name="8"
              handleClick={(element) => {
                return handleClick(element, 8);
              }}
            />
            <ClockInButton
              name="9"
              handleClick={(element) => {
                return handleClick(element, 9);
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12}>
            <ClockInButton
              name="Clear"
              isNumber={false}
              handleClick={() => {
                resetCode();
                setCurrentIndex(0);
              }}
            />
            <ClockInButton
              name="0"
              handleClick={(element) => {
                return handleClick(element, 0);
              }}
            />
            <ClockInButton
              name="Delete"
              isNumber={false}
              handleClick={(element) => {
                return handleDelete(element, "");
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12}>
            <Button
              className={classes.loginBtn}
              color={"info"}
              onClick={handleLogin}
              disabled={codeValid() ? false : true}
            >
              Login
            </Button>
          </GridItem>
        </GridContainer>
      </GridContainer>
    );
  };

  const renderLoggedIn = () => {
    const welcomeOption = {
      loop: true,
      autoplay: true,
      animationData: welcomeDate,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const spanStyle = { color: "#0098d4" };
    const { user, clockIn } = clockInAuth;
    let clockInTime, clockOutTime, breakStartTime, breakEndTime;
    if (clockIn) {
      const {
        clockInTime: cit,
        clockOutTime: cot,
        breakStartTime: bst,
        breakEndTime: bet,
      } = clockIn;
      clockInTime = cit;
      clockOutTime = cot;
      breakStartTime = bst;
      breakEndTime = bet;
    }
    return (
      <GridContainer className={classes.clockInPadContainer} justify={"center"}>
        <GridContainer justifyContent={"center"}>
          <GridItem xs={9} sm={9} md={9}>
            <Card login>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4
                  className={classes.cardTitle}
                >{`${user.firstName} ${user.lastName}`}</h4>
              </CardHeader>
              <CardBody>
                {!clockIn && (
                  <Lottie
                    options={welcomeOption}
                    height={100}
                    width={200}
                    speed={2}
                  />
                )}
                {clockInTime && (
                  <h4
                    style={{
                      textAlign: "center",
                      color: "#757272",
                      fontFamily: "Roboto",
                    }}
                  >
                    Clocked In:{" "}
                    <span style={spanStyle}>
                      {moment(clockInTime).local().format("hh:mm A")}
                    </span>
                  </h4>
                )}
                {clockOutTime && (
                  <h4
                    style={{
                      textAlign: "center",
                      color: "#757272",
                      fontFamily: "Roboto",
                    }}
                  >
                    Clocked Out:{" "}
                    <span style={spanStyle}>
                      {moment(clockOutTime).local().format("hh:mm A")}
                    </span>
                  </h4>
                )}
                {breakStartTime && (
                  <h4
                    style={{
                      textAlign: "center",
                      color: "#757272",
                      fontFamily: "Roboto",
                    }}
                  >
                    Break Start:{" "}
                    <span style={spanStyle}>
                      {moment(breakStartTime).local().format("hh:mm A")}
                    </span>
                  </h4>
                )}
                {breakEndTime && (
                  <h4
                    style={{
                      textAlign: "center",
                      color: "#757272",
                      fontFamily: "Roboto",
                    }}
                  >
                    Break End:{" "}
                    <span style={spanStyle}>
                      {moment(breakEndTime).local().format("hh:mm A")}
                    </span>
                  </h4>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {shouldDisplayClockIn() && (
          <GridItem xs={4} sm={4}>
            <Button
              color={"success"}
              onClick={() => {
                handleClockIn("in");
              }}
            >
              Clock In
            </Button>
          </GridItem>
        )}

        {shouldDisplayBreakStart() && (
          <GridItem xs={4} sm={4}>
            <Button
              color={"info"}
              onClick={() => {
                handleClockIn("break-start");
              }}
            >
              Start Break
            </Button>
          </GridItem>
        )}

        {shouldDisplayBreakEnd() && (
          <GridItem xs={4} sm={4}>
            <Button
              color={"info"}
              onClick={() => {
                handleClockIn("break-end");
              }}
            >
              End Break
            </Button>
          </GridItem>
        )}
        {shouldDisplayClockOut() && (
          <GridItem xs={4} sm={4}>
            <Button
              color={"danger"}
              onClick={() => {
                handleClockIn("out");
              }}
            >
              Clock Out
            </Button>
          </GridItem>
        )}
        <GridContainer justifyContent={"flex-end"}>
          <GridItem xs={4} sm={4}>
            <Button round simple size="sm" onClick={close}>
              <Close />
            </Button>
          </GridItem>
        </GridContainer>
      </GridContainer>
    );
  };

  const renderLoginOption = () => {
    return (
      <div className={classes.loginOption}>
        <Button onClick={() => setloginOption(LOGIN_OPTION.CODE)}>Code</Button>

        <Button onClick={() => setloginOption(LOGIN_OPTION.FACE)}>Face</Button>
      </div>
    );
  };

  const handleLoginOption = () => {
    setloginOption(LOGIN_OPTION.CODE);
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Office Clock-In | ${COMPANY_NAME}</title>
        <meta name="og:title" content={`Clock-In ${COMPANY_NAME}`} />
      </Helmet>
      {renderHeader()}
      {isUserAuth() || isLoginValid ? (
        <>
          {alert}
          {/* renderLoginPage() */}
          {/* {!(clockInAuth || loginSuccess) &&
            !loginOption &&
            renderLoginOption()} */}
          {!(clockInAuth || loginSuccess) &&
            loginOption === LOGIN_OPTION.CODE &&
            renderLoginPage()}
          {!(clockInAuth || loginSuccess) &&
            loginOption !== LOGIN_OPTION.CODE && (
              <AttendanceRoom codeLogin={handleLoginOption} />
            )}
          {clockInAuth && loginSuccess && renderLoggedIn()}
          {loginProgress && <CustomLinearProgress color="primary" />}
          {errMsg && <SnackbarContent message={errMsg} color="danger" />}
        </>
      ) : (
        passwordEntryAlert()
      )}
      {/*{clockSuccess && <SnackbarContent message={"Request Completed"} color="info" /> }*/}
    </div>
  );
}
