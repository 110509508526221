/* eslint-disable react/display-name */
import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  isFaceDetectionModelLoaded,
  isFacialLandmarkDetectionModelLoaded,
  isFeatureExtractionModelLoaded,
  loadModels,
} from "../../../faceUtil";
import ModelLoadStatus from "../../../utils/ModelLoadStatus";
import ModelLoading from "../../../utils/ModelLoading";
import { UploadFromWebcam } from "./UploadFromWebcam";

export default ({ galleryRefetch, countRefetch }) => {
  const [isAllModelLoaded, setIsAllModelLoaded] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [loadingMessageError, setLoadingMessageError] = useState("");

  useEffect(() => {
    async function loadingtheModel() {
      await loadModels(setLoadingMessage, setLoadingMessageError);
      setIsAllModelLoaded(true);
    }
    if (
      !!isFaceDetectionModelLoaded() &&
      !!isFacialLandmarkDetectionModelLoaded() &&
      !!isFeatureExtractionModelLoaded()
    ) {
      setIsAllModelLoaded(true);
      return;
    }

    loadingtheModel();
  }, [isAllModelLoaded]);

  return (
    <Card>
      <Card title="Model Load">
        <ModelLoadStatus errorMessage={loadingMessageError} />
      </Card>
      <br />
      {!isAllModelLoaded ? (
        <ModelLoading loadingMessage={loadingMessage} />
      ) : loadingMessageError ? (
        <div className="error">{loadingMessageError}</div>
      ) : (
        isAllModelLoaded &&
        loadingMessageError.length === 0 && (
          <div>
            <UploadFromWebcam
              // addFacePhotoCallback={addFacePhotoCallback}
              galleryRefetch={galleryRefetch}
              countRefetch={countRefetch}
              // loading={loading}
            />
          </div>
        )
      )}
    </Card>
  );
};
