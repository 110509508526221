/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
import { useFormik } from "formik";
import {sum, map} from "lodash";
import moment from "moment-timezone";


import "./style.css";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import withTimer from '../../components/Timer/withTimer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '../../components/CustomButtons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { reportSchema, isNumeric } from '../../scripts/util';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SnackbarContent from '../../components/Snackbar/SnackbarContent';
import CustomInput from '../../components/CustomInput/CustomInput';
import Clearfix from '../../components/Clearfix/Clearfix';
import {
  REPORT_CREATE_REQUESTED,
  CLEAR_ERROR,
  REPORT_GET_ALL_REQUESTED, REPORT_REFRESH_REQUESTED, REPORT_UPDATE_REQUESTED
} from '../../redux/actions';
import { DEFAULT_TIME_ZONE } from '../../scripts/const';
import ReactGA from 'react-ga4';
import CustomLinearProgress from '../../components/CustomLinearProgress/CustomLinearProgress';

moment.tz.setDefault(DEFAULT_TIME_ZONE);
const today = moment();


const useStyles = makeStyles(styles);

function isPastDate(userDate, format){
  const givenDate = moment(userDate, format);
  return today > givenDate;
}


function NewReportEventDialog(props) {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { onClose, open } = props;

  const createSuccess = useSelector((state) => state.report.reportCreateSuccess);
  const error = useSelector((state) => state.report.reportCreateError);
  const errMsg = useSelector((state) => state.report.errorMsg);
  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );

  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);

  const handleClose = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (
      createSuccess === true
    ) {
      handleClose();
      dispatch({ type: CLEAR_ERROR });
      dispatch({ type: REPORT_GET_ALL_REQUESTED });
    }
  }, [createSuccess]);


  useEffect(() => {
    dispatch({ type: CLEAR_ERROR });
  }, []);


  const handleSubmit = (values) => {
    const data = {
      year: `${values.year}-${parseInt(values.year, 10)+1}`,
      name: `${values.year} - ${parseInt(values.year, 10)+1} Student Enrollment`,
    }
    ReactGA.event({
      category: "FiscalReport",
      action: "Create a new Report",
    });
    dispatch({ type: REPORT_CREATE_REQUESTED, payload: data });
  }

  const validate = (values) => {
    const errors = {};
    if(!isNumeric(values.year)){
      errors.year = "Year is invalid";
    }else{
      const year = parseInt(values.year,10);
      if(!(year > 2018 && year < 2050 )){
        errors.year = "Year is invalid";
      }
    }
    return errors;
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    submitForm,
    resetForm,
  } = useFormik({
    initialValues: {
      year: ""
    },
    validationSchema: reportSchema(),
    onSubmit: handleSubmit,
    validate,
  });

  return (
    <>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modalMediumSmall
        }}
        
        onClose={handleClose}
        open={open}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          className={classes.eventModalHeader}
        >
          {"Create a Report"}
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {error && (
                <SnackbarContent message={errMessage} color="warning" />
              )}
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <CustomInput
                error={!!(touched.year && errors.year)}
                helperText={
                  touched.year && errors.year ? errors.year : ""
                }
                labelText="Year *"
                id="year"
                name="year"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: values.year,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "year"
                }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Report Name"
                id="name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: values.year && !errors.year ? `${values.year} - ${parseInt(values.year, 10)+1} Student Enrollment` : "",
                  name: "name",
                  disabled: true
                }}/>
            </GridItem>
            <GridContainer  className={classes.topMargin} justifyContent={"space-between"} spacing={10}>
              <GridItem>
                <Button
                  color="primary"
                  className={classes.moveToRight}
                  onClick={submitForm}
                  disabled={!!(!values.year || errors.year)}
                >
                  Create
                </Button>
                <Clearfix />
              </GridItem>
            </GridContainer>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

const FiscalReport = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();


  const handleCellChange = (name, month, e) => {

    let value = e.target.value;
    if(value >= 0){
      const index = updatedData.findIndex(element => element.name === name )
      let newArr = [...updatedData];
      newArr[index] = {...newArr[index], target: { ...newArr[index].target,  [month]: value}}
      setUpdatedData(newArr)
    }
  }

  const getTargetValueFromState = (name, month) => {

    let updatedTarget = updatedData.find(d => d.name === name);
    return updatedTarget.target[month]
  }

  function commonFooterProp(month, year){
    const isFromPast = isPastDate(`${month}-${year}`, 'MMM-YYYY');
    if(!isFromPast) return  commonTargetFooterProp();

    const actual = sum(map(selectedReport.data, report => {
        return parseInt(report.actual[`${month}${year}`])
      }
    ))
    const target = sum(map(selectedReport.data, report => {
        return parseInt(report.target[`${month}${year}`])
      }
    ))
    return {
      style: {
        background: actual <= target ? "#de4463" : "rgb(171, 235, 198)",
        fontWeight: 500
      }
    }
  }

  function commonTargetFooterProp(){
    return {
      style: {
        background: "#cac4c4",
        fontWeight: 500
      }
    }
  }

  function commonTotalFooterProp(){
    const actual = sum(map(selectedReport.data, report => {
        return parseInt(report.actual.total, 10)
      }
    ))
    const target = sum(map(selectedReport.data, report => {
        return parseInt(report.target.total, 10)
      }
    ))
    return {
      style: {
        background: actual < target ? "#de4463" : "rgb(171, 235, 198)",
        fontWeight: 500
      }
    }
  }
  const getColumns = () =>
  {
    return [
    {
      Header: 'Success - Canberra',
      accessor: 'name',
      width: 250,
      className: "sticky",
      headerClassName: "sticky",
      headerStyle: {
        textAlign: "center",
        background: "white"
      },
      Footer: (
        <span>
                Total
              </span>
      ),
      getFooterProps: () => {
        return {
          style: {
            fontWeight: 500
          }
        }
      },
      getProps: (state, rowInfo, column) => {
        return {
          style: {
            background: '#FFF0F5'
          },
        };
      },
    },
    {
      Header: 'Jul',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${initialYear}-Jul` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.jul${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `jul${initialYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                 return parseInt(report.target[`jul${initialYear}`], 10)
                }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.jul${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('jul', initialYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`jul${initialYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    },
    {
      Header: 'Aug',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${initialYear}-Aug` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.aug${initialYear}`,
          width: 80,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `aug${initialYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`aug${initialYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.aug${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('aug', initialYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`aug${initialYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    }, {
      Header: 'Sep',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${initialYear}-Sep` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.sep${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `sep${initialYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          width: 80,
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`sep${initialYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.sep${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('sep', initialYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`sep${initialYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    }, {
      Header: 'Oct',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${initialYear}-Oct` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.oct${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `oct${initialYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          width: 80,
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`oct${initialYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.oct${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('oct', initialYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`oct${initialYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    }, {
      Header: 'Nov',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${initialYear}-Nov` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.nov${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `nov${initialYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          width: 80,
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`nov${initialYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.nov${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('nov', initialYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`nov${initialYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    }, {
      Header: 'Dec',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${initialYear}-Dec` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.dec${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `dec${initialYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          width: 80,
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`dec${initialYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.dec${initialYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('dec', initialYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`dec${initialYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    }, {
      Header: 'Jan',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${finalYear}-Jan` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.jan${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `jan${finalYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          width: 80,
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`jan${finalYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.jan${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('jan', finalYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`jan${finalYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    }, {
      Header: 'Feb',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${finalYear}-Feb` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.feb${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `feb${finalYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          width: 80,
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`feb${finalYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.feb${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('feb', finalYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`feb${finalYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    }, {
      Header: 'Mar',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${finalYear}-Mar` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.mar${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `mar${finalYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          width: 80,
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`mar${finalYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.mar${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('mar', finalYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`mar${finalYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    }, {
      Header: 'Apr',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${finalYear}-Apr` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.apr${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `apr${finalYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`apr${finalYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.apr${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('apr', finalYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`apr${finalYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    }, {
      Header: 'May',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${finalYear}-May` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.may${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `may${finalYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`may${finalYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.may${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('may', finalYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`may${finalYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    },
    {
      Header: 'Jun',
      headerStyle: {
        textAlign: "center",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: moment().format('YYYY-MMM') === `${finalYear}-Jun` ? "#ABEBC6" : null
      },
      columns: [
        {
          Header: 'Target',
          accessor: `target.jun${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          Cell: (props) => {
            if (isEdit) {
              const rowName = props.row.name;
              const month = `jun${finalYear}`;
              return <input onChange={(e) => handleCellChange(rowName, month, e)} style={{ width: '50px'}} type="number" value={getTargetValueFromState(rowName, month)}/>
            } else {
              return props.value;
            }
          },
          width: 80,
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target[`jun${finalYear}`])
                  }
                ))}
              </span>
          )
        },
        {
          Header: 'Actual',
          accessor: `actual.jun${finalYear}`,
          headerStyle: {
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.1)"
          },
          width: 80,
          getFooterProps: () => {
            return commonFooterProp('jun', finalYear);
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.actual[`jun${finalYear}`])
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                borderRight: "1px solid rgba(0, 0, 0, 0.1)"
              },
            };
          },
        },
      ]
    },
    {
      Header: 'Total',
      headerStyle: { textAlign: "center" },
      columns: [
        {
          Header: 'Target',
          width: 80,
          accessor: `target.total`,
          getFooterProps: commonTargetFooterProp,
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                    return parseInt(report.target.total, 10)
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return commonTargetFooterProp();
          },
        },
        {
          Header: 'Actual',
          width: 80,
          accessor: `actual.total`,
          getFooterProps: () => {
            return commonTotalFooterProp();
          },
          Footer: (
            <span>
                {sum(map(selectedReport.data, report => {
                  return parseInt(report.actual.total, 10)
                  }
                ))}
              </span>
          ),
          getProps: (state, rowInfo, column) => {
            return {
              style: {
                background: sum(map(selectedReport.data, report => {
                    return parseInt(report.target.total, 10)
                  }
                )) >= sum(map(selectedReport.data, report => {
                    return parseInt(report.actual.total, 10)
                  }
                )) ? "#de4463" : "rgb(171, 235, 198)"
              },
            };
          },
        },
      ]
    },
  ]
};


  const [isEdit, setIsEdit] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState();
  const [initialYear, setInitialYear] = useState();
  const [finalYear, setFinalYear] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [updatedData, setUpdatedData] = useState();
  const [openReportModal, setOpenReportModal] = useState(false);
  const allReports = useSelector((state) => state.report.reports);
  const allReportsProgress = useSelector((state) => state.report.reportGetAllFetching);
  const updateSuccess = useSelector((state) => state.report.reportUpdateSuccess);

  const refreshSuccess = useSelector((state) => state.report.reportRefreshSuccess);
  const refreshProgress = useSelector((state) => state.report.reportRefreshFetching);
  const refreshError = useSelector((state) => state.report.reportRefreshError);


  const getLoadingState = () => {
    if (refreshProgress) {
      return "loading";
    } else if (refreshSuccess){
      return "finished";
    }
    return null;
  };

  const refreshReport = () => {
    if(!(selectedReportId == null)) {
      dispatch({
        type: REPORT_REFRESH_REQUESTED,
        payload: {
          id: selectedReportId
        }
      })
    }
  };

    const handleReportChange = (e) => {
      const value = e.target.value;
      setSelectedReportId(value)
    }

    const handleEdit = () => {
      if(selectedReport.data != updatedData){
        dispatch({ type: REPORT_UPDATE_REQUESTED, payload:
            { payload: { data: updatedData }, id: selectedReportId }} );
        ReactGA.event({
          category: "FiscalReport",
          action: "Update Target",
        });
      }
      setIsEdit(false);
    }

    useEffect(() => {
      dispatch({ type: REPORT_GET_ALL_REQUESTED });
    }, [])

    useEffect(() => {
      dispatch({ type: REPORT_GET_ALL_REQUESTED });
    }, [refreshSuccess])

    useEffect(() => {
      if(allReports.length > 0) {
        if (selectedReportId > 0) {
          const mainReport = allReports.find(r => r.id === selectedReportId);
          if (mainReport && mainReport.year) {
            setSelectedReport(mainReport);
            setUpdatedData(mainReport.data);
            const year = mainReport.year.split("-");
            setInitialYear(year[0])
            setFinalYear(year[1])
          }
        } else {
          setSelectedReportId(allReports[0].id)
        }
      }
    }, [allReports])


    useEffect(() => {
      if(updateSuccess === true){
        refreshReport();
      }
    }, [updateSuccess])

    const handleClose = () => {
      setOpenReportModal(false);
    }


  useEffect(() => {
    const mainReport = allReports.find(r => r.id === selectedReportId);
    if(mainReport && mainReport.year){
      setSelectedReport(mainReport);
      setUpdatedData(mainReport.data);
      const year = mainReport.year.split("-");
      setInitialYear(year[0])
      setFinalYear(year[1])
    }
    refreshReport()
  }, [selectedReportId])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon style={{
            marginTop: "10px",
            marginLeft: "40px"
          }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={2}>
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Select A Report
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={selectedReportId || ""}
                    onChange={handleReportChange}
                    inputProps={{
                      name: "userId",
                      id: "cal-add-userId",
                    }}
                  >
                    {allReports && allReports.length > 0 &&
                    allReports.map((r) => {
                      return (
                        <MenuItem
                          key={r.id + "event"}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={r.id}
                        >
                          {r.year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem style={{
                marginTop: "20px"
              }}>
              <Button
                size={"sm"}
                onClick={() => {
                  setOpenReportModal(true);
                }}
                color="primary"
              >
                Create a New Report
              </Button>
              </GridItem>
              {/*{ selectedReportId &&*/}
              {/*  <GridItem style={{*/}
              {/*    marginTop: "20px"*/}
              {/*  }}*/}
              {/*            xs={12} sm={12} md={8}>*/}
              {/*    <LoaderButton*/}
              {/*      style={{*/}
              {/*        width: "100%",*/}
              {/*        padding: "1rem 1.5rem",*/}
              {/*        fontSize: "0.6875rem",*/}
              {/*        lineHeight: "1.5",*/}
              {/*        borderRadius: "0.2rem",*/}
              {/*      }}*/}
              {/*      onClick={refreshReport}*/}
              {/*      state={getLoadingState()}*/}
              {/*      disabled={refreshProgress}*/}
              {/*      bgColor={"#4AD481"}*/}
              {/*      bgLoading={"#F5B041"}*/}
              {/*      bgWhenFinish={"#239B56"}*/}
              {/*    >*/}
              {/*      Refresh*/}
              {/*    </LoaderButton>*/}

              {/*  </GridItem>*/}
              {/*}*/}
            </GridContainer>
          </CardHeader>
          <CardBody>
            { allReports.length === 0 && allReportsProgress && !selectedReport &&
            <CustomLinearProgress color="primary" />}
            {selectedReport && <Card>
              <CardHeader style={{ textAlign: 'center' }} color="primary">
                <span>Target Vs Actual ( {selectedReport.name} )
                  {!isEdit && <button onClick={() => setIsEdit(true)}>✎</button>}
                  {isEdit && <button onClick={() => handleEdit()}>✓</button>}
                  {isEdit && <button onClick={() => {
                    setIsEdit(false)
                    setUpdatedData(selectedReport.data)
                  }}>x</button>}
                </span>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={selectedReport.data}
                  sortable={false}
                  showPagination={false}
                  columns={getColumns()}
                  defaultPageSize={selectedReport.data.length}
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
            }
          </CardBody>
        </Card>
        <NewReportEventDialog open={openReportModal} onClose={handleClose} />
      </GridItem>
    </GridContainer>
  );
}

export default withTimer(FiscalReport);
