import React, { useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Button from "components/CustomButtons/Button.js";

import ReactGA from "react-ga4";
import { FILE_UPLOAD_URL, TENANT } from "../../scripts/const";

export default function FileUpload({
  setFileUrl,
  fileUrl,
  open,
  autoUpload,
  uploadOnly,
  label,
  handleDelete,
  type,
  multiple,
}) {
  const [file, setFile] = React.useState(null);
  const [previewUrl, setPreviewUrl] = React.useState(null);
  const [isChanged, setIsChanged] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);

  useEffect(() => {
    if (open === false) {
      setFile(null);
      setPreviewUrl(null);
      setIsChanged(false);
      setIsSaving(false);
    }
  }, [open]);

  useEffect(() => {
    if (autoUpload) {
      handleSave();
    }
  }, [file]);

  useEffect(() => {
    if (fileUrl) {
      if (fileUrl.includes("_")) {
        setPreviewUrl(fileUrl.substring(fileUrl.indexOf("_") + 1));
      } else {
        setPreviewUrl(fileUrl);
      }
    } else {
      setPreviewUrl("");
      setIsChanged(false);
      setFile(null);
    }
  }, [fileUrl]);

  let fileInput = React.createRef();
  const handleFileChange = (e) => {
    e.preventDefault();
    setIsChanged(true);
    let file = e.target.files[0];
    setPreviewUrl(file.name);
    setFile(file);
    e.target.value = "";
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleSave = () => {
    ReactGA.event({
      category: "File",
      action: "File Uploaded",
    });
    setIsSaving(true);
    uploadFile((remoteFileUrl) => {
      setIsChanged(false);
      setIsSaving(false);
      if (uploadOnly) {
        setFile(null);
        setPreviewUrl(null);
        setIsChanged(false);
        setIsSaving(false);
      }
      setFileUrl(remoteFileUrl);
    });
  };

  function uploadFile(callback) {
    const fileUploadUrl = FILE_UPLOAD_URL;

    let remoteFileUrl = null;
    if (file instanceof Blob) {
      const fileName = file.name;
      axios
        .post(fileUploadUrl, {
          name: fileName,
          folder: `${TENANT}/${type || "agreement"}`,
        })
        .then(function (response) {
          const responseBody = response.data;
          const formData = new FormData();
          Object.keys(responseBody.fields).forEach((key) => {
            formData.append(key, responseBody.fields[key]);
          });
          formData.append("file", file);
          const uploadURL = responseBody.url;
          remoteFileUrl = responseBody.fileUrl;
          axios
            .post(uploadURL, formData)
            .then(function () {
              callback(remoteFileUrl);
            })
            .catch(function (error) {
              setIsSaving(false);
            });
        })
        .catch(function (error) {
          setIsSaving(false);
        });
    } else {
      setIsSaving(false);
    }
  }
  const addButtonProps = {
    color: "rose",
    round: true,
  };
  const changeButtonProps = {
    color: "rose",
    round: true,
  };
  const saveButtonProps = {
    color: "success",
    round: true,
  };
  return (
    <div className="fileinput text-center">
      <input
        type="file"
        accept="*/*"
        onChange={handleFileChange}
        ref={fileInput}
        multiple
      />
      <div>{previewUrl}</div>
      <div>
        {file === null && !fileUrl ? (
          <Button size={"sm"} {...addButtonProps} onClick={() => handleClick()}>
            {label || "Select File"}
          </Button>
        ) : (
          <span>
            <Button
              disabled={isSaving ? true : false}
              size={"sm"}
              {...changeButtonProps}
              onClick={() => handleClick()}
            >
              Change
            </Button>
            {handleDelete && (
              <Button
                size={"sm"}
                {...changeButtonProps}
                onClick={() => handleDelete(fileUrl)}
              >
                Delete
              </Button>
            )}

            {!isChanged && (
              <a href={fileUrl} download target="_blank">
                <Button
                  size={"sm"}
                  {...changeButtonProps}
                  disabled={isSaving ? true : false}
                >
                  View
                </Button>
              </a>
            )}

            {!autoUpload && isChanged && (
              <Button
                disabled={isSaving ? true : false}
                size={"sm"}
                {...saveButtonProps}
                onClick={() => handleSave()}
              >
                <i className="fas fa-save" /> Upload
              </Button>
            )}
            {isSaving && <CircularProgress size={"1.5rem"} />}
          </span>
        )}
      </div>
    </div>
  );
}

FileUpload.propTypes = {
  fileUrl: PropTypes.string,
  label: PropTypes.string,
  setFileUrl: PropTypes.func,
  handleDelete: PropTypes.func,
  open: PropTypes.bool,
  autoUpload: PropTypes.bool,
  uploadOnly: PropTypes.bool,
  type: PropTypes.string,
  multiple: PropTypes.bool,
};

FileUpload.defaultProps = {
  autoUpload: false,
  type: "agreement",
  multiple: false,
};
