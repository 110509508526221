import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  OCCUPATION_CREATE_REQUESTED,
  OCCUPATION_CREATE_FAILED,
  OCCUPATION_CREATE_SUCCEEDED,
  OCCUPATION_GET_ALL_REQUESTED,
  OCCUPATION_GET_ALL_FAILED,
  OCCUPATION_GET_ALL_SUCCEEDED,
  OCCUPATION_GET_REQUESTED,
  OCCUPATION_GET_FAILED,
  OCCUPATION_GET_SUCCEEDED,
  OCCUPATION_UPDATE_REQUESTED,
  OCCUPATION_UPDATE_FAILED,
  OCCUPATION_UPDATE_SUCCEEDED,
  LEAD_UPDATE_SUCCEEDED,
  OCCUPATION_DELETE_SUCCEEDED,
  OCCUPATION_DELETE_FAILED,
  OCCUPATION_DELETE_REQUESTED,
} from "../actions";

import { getToken } from "./selectors";

function* fetchOccupation(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const occupation = yield call(
      remoteResource.getOccupation,
      id,
      token
    );
    yield put({
      type: OCCUPATION_GET_SUCCEEDED,
      payload: occupation.data,
    });
  } catch (e) {
    yield put({ type: OCCUPATION_GET_FAILED, payload: e.message });
  }
}

function* fetchAllOccupations(action) {
  try {
    const token = yield select(getToken);
    const occupation = yield call(
      remoteResource.getAllOccupations,
      token
    );
    yield put({
      type: OCCUPATION_GET_ALL_SUCCEEDED,
      payload: occupation.data,
    });
  } catch (e) {
    yield put({ type: OCCUPATION_GET_ALL_FAILED, payload: e.message });
  }
}


function* createOccupation(action) {
  try {
    let payload = {};
    let leadId = "";
    if (action.payload && action.payload.payload) {
      payload = action.payload.payload;
    } else {
      payload = action.payload;
    }
    if (action.payload && action.payload && action.payload.leadId) {
      leadId = action.payload.leadId;
    }
    const token = yield select(getToken);
    const occupation = yield call(
      remoteResource.createOccupation,
      payload,
      token
    );
    if (leadId && leadId !== "") {
      const lead = yield call(
        remoteResource.updateLeadClient,
        leadId,
        { occupationId: occupation.data.id },
        token
      );
      yield put({
        type: LEAD_UPDATE_SUCCEEDED,
        payload: lead.data,
      });
    }
    yield put({
      type: OCCUPATION_CREATE_SUCCEEDED,
      payload: occupation.data,
    });
  } catch (e) {
    yield put({
      type: OCCUPATION_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateOccupation(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const occupation = yield call(
      remoteResource.updateOccupation,
      id,
      payload,
      token
    );
    yield put({
      type: OCCUPATION_UPDATE_SUCCEEDED,
      payload: occupation.data,
    });
  } catch (e) {
    yield put({
      type: OCCUPATION_UPDATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* deleteOccupation(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    yield call(remoteResource.deleteOccupation, id, token);
    yield put({
      type: OCCUPATION_DELETE_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: OCCUPATION_DELETE_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchOccupation() {
  yield takeLatest(OCCUPATION_GET_REQUESTED, fetchOccupation);
  yield takeLatest(OCCUPATION_CREATE_REQUESTED, createOccupation);
  yield takeLatest(OCCUPATION_UPDATE_REQUESTED, updateOccupation);
  yield takeLatest(OCCUPATION_GET_ALL_REQUESTED, fetchAllOccupations);
  yield takeLatest(OCCUPATION_DELETE_REQUESTED, deleteOccupation);
}
