import React, { useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import ReactGA from "react-ga4";

import selectTableHOC from "react-table/lib/hoc/selectTable";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { makeStyles } from "@material-ui/core/styles";
import CallIcon from "@mui/icons-material/Call";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import { Paper, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import Badge from "components/Badge/Badge.js";
import EditInsurance from "../hbg-view/InsuranceService/EditInsurance";
import EditSkillAssessment from "../hbg-view/SkillAssessmentService/EditSkillAssessment";
import EditStudent from "../hbg-view/StudentService/EditStudent";
import EditVisa from "../hbg-view/VisaService/EditVisa";
import EditLead from "../hbg-view/Lead/EditLeadPanel";

import history from "../../history";

import Slide from "@material-ui/core/Slide";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { useDispatch, useSelector } from "react-redux";

import withTimer from "components/Timer/withTimer";
import { filterCaseInsensitive } from "scripts/util";
import { reactTableHelper } from "scripts/util";
import { Button } from "@mui/material";
import CustomButton from "components/CustomButtons/Button.js";

import Close from "@material-ui/icons/Close";
import AddLead from "views/hbg-view/Lead/AddLead";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { LEAD_SELF_ASSIGNED_REQUESTED } from "redux/actions";
import { GET_ALL_SERVICE_DATA_REQUESTED } from "redux/actions";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const SelectTable = selectTableHOC(ReactTable);

const AllServiceTables = ({ id }) => {
  const [data, setData] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selection, setSelection] = React.useState([]);
  const [sources, setSources] = React.useState([]);
  const [editLeadModal, setEditLeadModal] = React.useState(false);
  const [currentLead, setCurrentLead] = React.useState({});
  const [courses, setCourses] = React.useState([]);
  const [universities, setUniversities] = React.useState([]);

  const [addLeadModal, setAddLeadModal] = React.useState(false);
  const allSources = useSelector((state) => state.source.sources);
  const activeUsers = useSelector((state) => state.user.activeUsers);
  const allServiceData = useSelector((state) => state.user.allServiceData);
  const insuranceApplicants = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicants
  );
  const allCourses = useSelector((state) => state.course.courses);
  const allUniversities = useSelector((state) => state.university.universities);
  const students = useSelector((state) => state.student.students);

  const skillAssessments = useSelector(
    (state) => state.skillAssessment.skillAssessments
  );

  const leadsFetching = useSelector((state) => state.lead.leadGetAllFetching);
  const leads = useSelector((state) => state.lead.selfAssignedLead);

  function isSelected(key) {
    return selection.includes(`select-${key}`);
  }
  React.useEffect(() => {
    setData(allServiceData || []);
  }, [allServiceData]);
  React.useEffect(() => {
    setCourses(allCourses);
  }, [allCourses]);
  React.useEffect(() => {
    setUniversities(allUniversities);
  }, [allUniversities]);
  // useEffect(() => {
  //   if (id !== "undefined" && id > 0 && data.length > 0) {
  //     const found = data.find((d) => d.id == id);
  //     if (found) {
  //       console.log("FOUND");
  //       setCurrentLead(found);
  //       setEditLeadModal(true);
  //     }
  //   }
  // }, [id, data]);

  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  useEffect(() => {
    dispatch({
      type: GET_ALL_SERVICE_DATA_REQUESTED,
    });
  }, []);

  React.useEffect(() => {
    setSources(allSources);
  }, [allSources]);
  function getBGColor(row) {
    if (row.followUpDate) {
      return "#fada5e";
    }
    return undefined;
  }
  function getTrProps(state, rowInfo) {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: (e) => {
          e.preventDefault();
          setCurrentLead(rowInfo.row._original);
          setEditLeadModal(true);
          history.push(`/admin/user-dashboard/${rowInfo.row._original.id}`);
          ReactGA.event({
            category: "Lead",
            action: "View Lead Profile",
          });
        },
        // style: {
        //   background: getBGColor(rowInfo.row._original),
        // },
      };
    } else {
      return {};
    }
  }

  const columns = [
    // {
    //   sortable: false,
    //   Header: ' ',
    //   show: !selectedOptions.includes(' '),
    //   accessor: 'createdAt',
    //   Cell: (props) => {
    //     if (props.value) {
    //       return momentTZ(props.value).format('MMM YYYY');
    //     }
    //     return props.value;
    //   },
    //   getProps: (state, rowInfo) => {
    //     let color = null;
    //     if (rowInfo && rowInfo.row.createdAt) {
    //       let month = momentTZ(rowInfo.row.createdAt).format('MM');
    //       color = monthColor[month - 1];
    //     }
    //     return {
    //       style: {
    //         background: color,
    //       },
    //     };
    //   },
    //   filterMethod: (filter, row) => {
    //     return momentTZ(row[filter.id])
    //       .format('MMM YYYY')
    //       .toLowerCase()
    //       .includes(filter.value.toLowerCase());
    //   },
    //   width: 70,
    // },
    // {
    //   Header: 'ID',
    //   show: !selectedOptions.includes('ID'),
    //   accessor: 'id',
    //   width: 70,
    // },
    {
      sortable: false,
      Header: "Name-Mail",
      accessor: (row) => ({
        firstName: row?.firstName,
        middleName: row?.middleName,
        lastName: row?.lastName,
        email: row?.email,
      }),
      id: "nameAndEmail",
      Cell: ({ value }) => (
        <>
          <h6>
            {value?.firstName} {value?.middleName} {value?.lastName}
          </h6>{" "}
          ({value?.email})
        </>
      ),
    },
    {
      sortable: false,

      show: !selectedOptions.includes("Phone"),
      Header: () => (
        <>
          <CallIcon /> Phone
        </>
      ),
      accessor: "phone",
      width: 150,
    },

    // {
    //   show: !selectedOptions.includes('Country'),

    //   Header: 'Country',
    //   accessor: 'country',
    // },

    // {
    //   show: !selectedOptions.includes('Service Type'),
    //   Header: 'Service Type',
    //   accessor: 'serviceType',
    //   width: 150,
    //   Cell: (props) => {
    //     if (props.value && props.value.length > 0) {
    //       return JSON.parse(props.value).map((v, i) => {
    //         return (
    //           <Badge key={i} color={'info'}>
    //             {v}
    //           </Badge>
    //         );
    //       });
    //     } else {
    //       return '';
    //     }
    //   },
    // },

    {
      show: !selectedOptions.includes("Status"),
      Header: () => (
        <>
          <ArrowCircleDownIcon /> Status
        </>
      ),
      accessor: "status",
      headerStyle: { textAlign: "left" },
      Cell: (props) => {
        if (["Follow Up"].includes(props.value)) {
          return <Badge color={"warning"}>{props.value}</Badge>;
        } else if (props.value === "Not Converted") {
          return <Badge color={"danger"}>{props.value}</Badge>;
        } else if (props.value === "Converted") {
          return <Badge color={"success"}>{props.value}</Badge>;
        } else if (props.value) {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else if (
          [
            "Info Requested",
            "Follow Up",
            "Provisional Offer/GTE Survey",
          ].includes(props.value)
        ) {
          return <Badge color={"warning"}>{props.value}</Badge>;
        } else if (
          [
            "Offer Received",
            "Coe Received",
            "Conditional Offer",
            "Unconditional Offer",
          ].includes(props.value)
        ) {
          return <Badge color={"success"}>{props.value}</Badge>;
        } else if (props.value === "Canceled" || props.value === "Refused") {
          return <Badge color={"danger"}>{props.value}</Badge>;
        } else if (props.value) {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else if (["Follow Up", "Info Requested"].includes(props.value)) {
          return <Badge color={"warning"}>{props.value}</Badge>;
        } else if (props.value === "Approved") {
          return <Badge color={"success"}>{props.value}</Badge>;
        } else if (
          props.value === "Rejected" ||
          props.value === "Discontinued"
        ) {
          return <Badge color={"danger"}>{props.value}</Badge>;
        } else if (props.value) {
          return <Badge color={"info"}>{props.value}</Badge>;
        } else {
          return <Badge color={"gray"}>{props.value}</Badge>;
        }
      },
    },
  ];
  function toggleAll() {
    const keyField = "id";
    const newSelectAll = !selectAll;
    const newSelection = [];

    if (newSelectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = reactTableRef.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        newSelection.push(`select-${item._original[keyField]}`);
      });
    }
    setSelection(newSelection);
    setSelectAll(newSelectAll);
  }
  function toggleSelection(key, shift, row) {
    // start off with the existing state
    let newSelection = [...selection];
    const keyIndex = newSelection.indexOf(key);

    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      newSelection = [
        ...newSelection.slice(0, keyIndex),
        ...newSelection.slice(keyIndex + 1),
      ];
    } else {
      // it does not exist so add it
      newSelection.push(key);
    }
    // update the state
    setSelection(newSelection);
  }

  let reactTableRef = null;

  const classes = useStyles();
  return (
    <Paper elevation={3} sx={{ borderRadius: 8, overflow: "hidden" }}>
      {/*Add Lead*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={addLeadModal}
        TransitionComponent={Transition}
        disableScrollLock={true}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return false;
          }

          if (reason === "escapeKeyDown") {
            return false;
          }
          setAddLeadModal(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAddLeadModal(false)}
          >
            <Close className={classes.modalClose} />
          </CustomButton>
          <h3 className={classes.modalTitle}>New Lead</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <AddLead
            allSources={sources}
            allUsers={activeUsers}
            open={addLeadModal}
            handleSuccess={setAddLeadModal}
          />
        </DialogContent>
      </Dialog>
      {/*Edit Lead*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        disableScrollLock={true}
        open={editLeadModal}
        TransitionComponent={Transition}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            return false;
          }

          if (reason === "escapeKeyDown") {
            return false;
          }

          setEditLeadModal(false);
          history.push("/admin/user-dashboard");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <CustomButton
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setEditLeadModal(false);
              history.push("/admin/user-dashboard");
            }}
          >
            <Close className={classes.modalClose} />
          </CustomButton>
          <h3 className={classes.modalTitle}>Edit Lead</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {currentLead.serviceName === "visa" && (
            <EditVisa
              allUsers={activeUsers}
              open={editLeadModal}
              allSources={sources}
              visaApplicant={(() => {
                const { serviceName, ...rest } = currentLead;
                return rest;
              })()}
              handleSuccess={setEditLeadModal}
            />
          )}
          {currentLead.serviceName === "insurance" && (
            <EditInsurance
              open={editLeadModal}
              allUsers={activeUsers}
              allSources={sources}
              allInsuranceApplicants={insuranceApplicants}
              insuranceApplicant={(() => {
                const { serviceName, ...rest } = currentLead;
                return rest;
              })()}
              handleSuccess={setEditLeadModal}
            />
          )}
          {currentLead.serviceName === "student" && (
            <EditStudent
              open={editLeadModal}
              allUsers={activeUsers}
              allCourses={courses}
              allSources={sources}
              allUniversities={universities}
              student={(() => {
                const { serviceName, ...rest } = currentLead;
                return rest;
              })()}
              allStudents={students}
              handleSuccess={setEditLeadModal}
            />
          )}
          {currentLead.serviceName === "skill" && (
            <EditSkillAssessment
              allUsers={activeUsers}
              open={editLeadModal}
              allSources={sources}
              allSkillAssessment={skillAssessments}
              skillAssessment={(() => {
                const { serviceName, ...rest } = currentLead;
                return rest;
              })()}
              handleSuccess={setEditLeadModal}
            />
          )}
          {currentLead.serviceName === "lead" && (
            <EditLead
              allUsers={activeUsers}
              open={editLeadModal}
              allSources={sources}
              lead={(() => {
                const { serviceName, ...rest } = currentLead;
                return rest;
              })()}
              handleSuccess={setEditLeadModal}
            />
          )}
        </DialogContent>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <CardBody>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "30px",
              }}
            >
              <div>
                <Typography variant="h5">My Assigned Leads</Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ fontSize: "13px", fontFamily: "bold" }}
                >
                  2+ items, sorted by status
                </Typography>
              </div>
              <div>
                <Button
                  rounded
                  sx={{
                    borderRadius: "25px",
                    border: "1px solid #EAE9EE",
                    backgroundColor: "#fff",
                    color: "#000",
                  }}
                  onClick={() => {
                    setAddLeadModal(true);
                  }}
                >
                  <Add />
                  create lead
                </Button>
              </div>
            </div>
            <SelectTable
              keyField="id"
              toggleSelection={toggleSelection}
              selectAll={selectAll}
              selectType="checkbox"
              toggleAll={toggleAll}
              isSelected={isSelected}
              ref={(r) => (reactTableRef = r)}
              pageSizeOptions={[25, 50, 100]}
              data={data}
              // filterable
              resizable={true}
              getTrProps={getTrProps}
              columns={columns}
              loading={leadsFetching}
              defaultPageSize={25}
              showPaginationBottom={true}
              className=""
              defaultFilterMethod={filterCaseInsensitive}
            >
              {(state, makeTable, instance) => {
                return reactTableHelper(state, makeTable, false);
              }}
            </SelectTable>
          </CardBody>
          {/* </Card> */}
        </GridItem>
      </GridContainer>
    </Paper>
  );
};

export default withTimer(AllServiceTables);
