import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import axios from "axios";
import ReactGA from "react-ga4";
import ReCAPTCHA from "react-google-recaptcha";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import SnackBar from "@material-ui/core/Snackbar";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepButton from "@material-ui/core/StepButton";

import FormLabel from "@material-ui/core/FormLabel";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import {
  DEFAULT_TIME_ZONE,
  BRANCH_LOCATION,
  BRANCH_LOCATION_MAP,
  BRANCH_NAME,
  BranchURLs,
  DEFAULT_DATE_FORMAT,
  LOGO_HORIZONTAL,
  COMPANY_NAME,
} from "../../../scripts/const";
import { Helmet } from "react-helmet";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import HBGInfo from "../../../components/HBGInfo/ContactInfo";
import ThankYou from "../../../components/HBGInfo/Thankyou.js";
import { google, ics } from "calendar-link";
import InputLabel from "@material-ui/core/InputLabel";
import { APPOINTMENT_USER_GET_REQUESTED } from "../../../redux/actions";
import Avatar from "@material-ui/core/Avatar";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { isValidPhone } from "../../../scripts/util";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
const useStyles = makeStyles(styles);
moment.tz.setDefault(DEFAULT_TIME_ZONE);

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

export default function Appointment() {
  const classes = useStyles();
  const recaptchaRef = React.useRef();

  const appointmentUsers = useSelector(
    (state) => state.appointment.appointmentUsers
  );
  const [isNewUser, setIsNewUser] = React.useState(null);
  const [isYearPickerOpen, setIsPickerOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [finalAppointment, setFinalAppointment] = React.useState({});
  const [availableSlots, setAvailableSlots] = React.useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);
  const [confirmationTextVisible, setConfirmationTextVisible] =
    React.useState(false);
  const [stepIndex, setStepIndex] = React.useState(1);
  const [smallScreen] = React.useState(window.innerWidth < 768);
  const [confirmationSnackbarOpen, setConfirmationSnackbarOpen] =
    React.useState(false);
  const [confirmationSnackbarMessage, setConfirmationSnackbarMessage] =
    React.useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: APPOINTMENT_USER_GET_REQUESTED });
  }, []);

  useEffect(() => {
    if (appointmentUsers && appointmentUsers.length > 0) {
      setAppointmentOwner(`${appointmentUsers[0].id}`);
    }
  }, [appointmentUsers]);

  const [appointmentDate, setAppointmentDate] = React.useState();
  const [appointmentOwner, setAppointmentOwner] = React.useState(null);
  const [appointmentSlot, setAppointmentSlot] = React.useState(null);
  const [amPm, setAmPm] = React.useState("AM");
  const [userInfo, setUserInfo] = React.useState({
    name: "",
    email: "",
    phone: "",
  });
  const [processed, setProcessed] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [serviceSelected, setServiceSelected] = React.useState({
    student: false,
    visa: false,
    skillAssessment: false,
    healthInsurance: false,
    other: false,
  });

  const isServiceSelected = () => {
    return Object.keys(serviceSelected).some((k) => serviceSelected[k]);
  };

  const isUserInfoCompleted = () => {
    return Object.keys(userInfo).every((k) => userInfo[k]);
  };

  const handleService = (event) => {
    setServiceSelected({
      ...serviceSelected,
      [event.target.name]: event.target.checked,
    });
  };
  function handleNextStep() {
    return stepIndex < 4 ? setStepIndex(stepIndex + 1) : null;
  }

  const handleAmPm = (event) => {
    setAmPm(event.target.value);
  };

  const handleAppointmentOwnerSelect = (event) => {
    setAppointmentOwner(event.target.value);
    setAppointmentSlot(null);
    setAmPm("AM");
    if (appointmentDate) {
      handleSetAppointmentDate(appointmentDate, event.target.value);
    }
  };
  const isSlotValid = (slot) => {
    if (!slot || !slot.userId) {
      return false;
    }
    return !(!(slot.userId.length > 0) || !slot.start || !slot.end);
  };
  const fetchAvailableAppointments = (date, userId, callback) => {
    const formattedDate = moment(date).format("YYYY-MM-DD").toString();
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_ROOT}/public/appointment?date=${formattedDate}&userId=${userId}`
      )
      .then((response) => {
        setLoading(false);
        callback(response.data, null);
      })
      .catch((error) => {
        setLoading(false);
        if (!error.response) {
          // network error
          callback(null, "Network Error, please try again later");
        } else {
          callback(null, "Sorry, some error occurred. Please try again.");
        }
      });
  };

  function handleSetAppointmentDate(date, userId) {
    setAppointmentDate(date);
    if (!date) {
      return;
    }
    setConfirmationTextVisible(true);
    fetchAvailableAppointments(date, userId, (data, error) => {
      if (error) {
        setConfirmationSnackbarMessage(error);
        setConfirmationSnackbarOpen(true);
      }
      if (data) {
        setAvailableSlots(
          data.map((m) => ({
            ...m,
            ...{ slot: moment(m.start).format("hh:mm A") },
          }))
        );
        setLoading(false);
        handleNextStep();
      }
    });
  }

  function handleSetAppointmentSlot(slot) {
    setAppointmentSlot(slot);
    handleNextStep();
  }

  function getLeadServiceTypes(services) {
    const applicableServices = Object.keys(services).filter(function (k) {
      return serviceSelected[k];
    });
    const leadServiceTypes = [];
    for (let ser of applicableServices) {
      if (ser === "student") {
        leadServiceTypes.push("Education");
      } else if (ser === "visa") {
        leadServiceTypes.push("Visa");
      } else if (ser === "skillAssessment") {
        leadServiceTypes.push("Skill Assessment");
      } else if (ser === "healthInsurance") {
        leadServiceTypes.push("Health Insurance");
      } else if (ser === "other") {
        leadServiceTypes.push("Other");
      }
    }
    return JSON.stringify(leadServiceTypes);
  }
  const handleSubmit = async () => {
    setIsError(false);

    const aptOwner = appointmentOwner
      ? appointmentUsers.find((u) => u.id == appointmentOwner)
      : null;
    let captcha;
    try {
      captcha = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
    } catch (e) {
      setIsError(true);
      setConfirmationSnackbarMessage("Something went wrong. Please try again.");
      return;
    }
    let services = Object.keys(serviceSelected)
      .filter(function (k) {
        return serviceSelected[k];
      })
      .join(", ");
    const slot = availableSlots.find((av) => av.slot === appointmentSlot);
    const fullName = userInfo.name.split(" ");
    let description = `Name: ${userInfo.name}\nEmail: ${userInfo.email}\nPhone: ${userInfo.phone}\nSelected Services: ${services}`;
    let title = `${userInfo.name} - Online Appointment`;
    if (aptOwner.isPaid && aptOwner.paidAmount) {
      title += ` (Fee: $${aptOwner.paidAmount})`;
    }
    if (!isSlotValid(slot)) {
      setConfirmationSnackbarMessage(
        "Sorry, something went wrong. Please refresh the page and try again."
      );
      setConfirmationSnackbarOpen(true);
      setProcessed(false);
      setIsError(true);
      return;
    }
    const appointment = {
      title,
      start: slot.start,
      end: slot.end,
      timezone: slot.timezone,
      userId: slot.userId,
      description,
      raw: {
        firstName: fullName[0],
        lastName: fullName[1] || "",
        email: userInfo.email,
        phone: userInfo.phone,
        serviceType: getLeadServiceTypes(serviceSelected),
        status: "New",
        note: "<i>Lead auto created from Online Appointment</i>",
      },
    };
    setFinalAppointment(appointment);
    const updatedEvent = {
      title: appointment.title || "New Event",
      description: appointment.description || "",
      location: `${COMPANY_NAME}, ${BRANCH_LOCATION}`,
      start: moment(appointment.start).toISOString(),
      end: moment(appointment.end).toISOString(),
    };
    const appointConfirm = {
      email: userInfo.email,
      data: {
        isPaid: aptOwner.isPaid,
        paidAmount: aptOwner.paidAmount,
        slotInfo: `${moment(appointmentDate).format(
          "dddd[,] MMMM Do[,] YYYY"
        )} at ${appointmentSlot}`,
        slotInfoShort: `${moment(appointmentDate).format(
          DEFAULT_DATE_FORMAT
        )} at ${appointmentSlot}`,
        name: userInfo.name,
        phone: userInfo.phone,
        googleLink: google(updatedEvent),
        iCalLink: ics(updatedEvent),
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_ROOT}/public/appointment`,
        appointment,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            captcha,
          },
        }
      )
      .then(() => {
        setSending(false);
        setConfirmationSnackbarMessage("Appointment successfully added!");
        setConfirmationSnackbarOpen(true);
        setProcessed(true);
        setConfirmationModalOpen(false);
        ReactGA.event({
          category: "Public-Appointment",
          action: "Appointment Created",
        });
        axios
          .post(
            `${process.env.REACT_APP_SMS_EMAIL}/appointmentConfirmation`,
            appointConfirm
          )
          .then(() => {
            ReactGA.event({
              category: "Public-Appointment",
              action: "Confirmation Email Sent",
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setSending(false);
        if (error.response) {
          setConfirmationSnackbarMessage(error.response.data.message);
        } else {
          setConfirmationSnackbarMessage("Error Occurred. Please try again.");
        }
        handleSetAppointmentDate(appointmentDate, appointmentOwner);
        setAppointmentSlot(null);
        setStepIndex(3);
        setConfirmationSnackbarOpen(true);
        setProcessed(false);
        setIsError(true);
      });
  };

  function checkForEmail(email) {
    setIsNewUser(true);
    // axios
    //   .get(
    //     `${process.env.REACT_APP_API_ROOT}/public/checkClient?email=${email}`
    //   )
    //   .then((response) => setIsNewUser(!response.data.isValidClient))
    //   .catch((error) => {
    //     console.log("Error", error);
    //   });
  }
  function validateEmail(email) {
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    regex.test(email)
      ? setUserInfo({ ...userInfo, ...{ email: email, validEmail: true } })
      : setUserInfo({ ...userInfo, ...{ email: email, validEmail: false } });
  }

  function validatePhone(phoneNumber) {
    const re = /^[0-9+]*$/;
    if (phoneNumber !== "" && !re.test(phoneNumber)) {
      return;
    }
    isValidPhone(phoneNumber)
      ? setUserInfo({
          ...userInfo,
          ...{ phone: phoneNumber, validPhone: true },
        })
      : setUserInfo({
          ...userInfo,
          ...{ phone: phoneNumber, validPhone: false },
        });
  }

  function renderConfirmationString() {
    const spanStyle = { color: "#2684c4", fontWeight: 430 };
    const feeStyle = { color: "#e1261f", fontWeight: 430 };
    const aptOwner = appointmentOwner
      ? appointmentUsers.find((u) => u.id == appointmentOwner)
      : null;
    return confirmationTextVisible ? (
      <>
        <h3
          style={{
            textAlign: smallScreen ? "center" : "left",
            color: "#958F8FFF",
            // lineHeight: 1.5,
            padding: "10px 10px",
            fontFamily: "Roboto",
            position: "sticky",
            top: "0px",
            backgroundColor: "#FFF",
            zIndex: 99,
          }}
        >
          {
            <span>
              Scheduling a
              <span style={spanStyle}>
                {" "}
                {aptOwner && aptOwner.slotTime ? `${aptOwner.slotTime}` : "30"}
                minute{" "}
              </span>
              appointment{" "}
              {appointmentDate && appointmentSlot && (
                <span>
                  on{" "}
                  <span style={spanStyle}>
                    {moment(appointmentDate).format("dddd[,] MMMM Do")}
                  </span>{" "}
                  at <span style={spanStyle}>{appointmentSlot}</span>{" "}
                </span>
              )}{" "}
              {appointmentDate && !appointmentSlot && (
                <span>
                  <span style={spanStyle}>
                    {moment(appointmentDate).format("dddd[,] MMMM Do")}
                  </span>{" "}
                </span>
              )}
              {aptOwner && (
                <span>
                  with{" "}
                  <span style={spanStyle}>
                    {`${aptOwner.firstName} ${aptOwner.lastName}.`}
                  </span>{" "}
                </span>
              )}
              {aptOwner && aptOwner.isPaid && aptOwner.paidAmount && (
                <span>
                  <span style={feeStyle}>
                    Consultation Fee: ${aptOwner.paidAmount}.
                  </span>
                  {/*<span*/}
                  {/*  style={{*/}
                  {/*    fontStyle: "italic",*/}
                  {/*    margin: 0,*/}
                  {/*    display: "inline",*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  (Fee for new clients only)*/}
                  {/*</span>*/}
                </span>
              )}
            </span>
          }
        </h3>
        <Divider></Divider>
      </>
    ) : null;
  }

  function renderAppointmentTimes() {
    if (!loading && availableSlots.length > 0) {
      const filteredSlot = availableSlots.filter((s) => s.slot.includes(amPm));
      return filteredSlot.map((slot) => {
        return (
          <FormControlLabel
            style={{
              color: slot.open ? "black" : "gray",
            }}
            key={slot.slot}
            value={slot.slot}
            disabled={!slot.open}
            control={<Radio />}
            label={slot.slot}
          />
        );
      });
    } else {
      return null;
    }
  }

  function getImg(user) {
    const makeCircle = appointmentOwner == user.id;
    const avatarStyle = {
      border: makeCircle ? "1.8px solid #EA3D5DFF" : "",
    };
    if (!user) {
      return <Avatar className={classes.purple}>""</Avatar>;
    }
    if (user.profileUrl) {
      return (
        <Avatar
          className={classes.aptAvatar}
          alt="profile-image"
          src={user.profileUrl}
          style={avatarStyle}
        />
      );
    } else {
      return (
        <Avatar style={avatarStyle} className={classes.purple}>
          {user.firstName.charAt(0).toUpperCase() +
            user.lastName.charAt(0).toUpperCase()}
        </Avatar>
      );
    }
  }

  function getAppointmentIcon(user) {
    const makeCircle = appointmentOwner == user.id;
    return (
      <div
        className={classes.aptUserInfo}
        style={{
          opacity: makeCircle ? "" : "0.6",
          transform: makeCircle ? "scale(1.05)" : "",
        }}
      >
        <div>{getImg(user)}</div>
        <div style={{ flexDirection: "row" }}>
          <div
            style={{ color: "#f35e7a", fontWeight: 500 }}
          >{`${user.firstName} ${user.lastName}`}</div>
          {user.isPaid && (
            <div>
              <p style={{ margin: 0, display: "inline", color: "#cc3300" }}>
                Consultation Fee - ${user.paidAmount}
              </p>
            </div>
          )}
          {user.appointmentNote && (
            <div>
              <p style={{ margin: 0, display: "inline", color: "#28a745" }}>
                {user.appointmentNote}
              </p>
            </div>
          )}
          <div style={{ color: "#646499" }}>
            {user.detail &&
              user.detail.split("\n").map((d, id) => (
                <p key={id} style={{ margin: 0 }}>
                  {d}
                </p>
              ))}
          </div>
        </div>
      </div>
    );
  }

  function renderAppointmentWith() {
    if (!loading && appointmentUsers.length > 0) {
      return appointmentUsers.map((user, id) => {
        return (
          <FormControlLabel
            key={id}
            value={`${user.id}`}
            control={<Radio />}
            labelPlacement={"end"}
            label={getAppointmentIcon(user)}
          />
        );
      });
    } else {
      return null;
    }
  }

  function BranchDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Select Branch</DialogTitle>
        <List sx={{ pt: 0 }}>
          {BranchURLs.map((branch, id) => (
            <a style={{ textDecoration: "none" }} href={branch.url} key={id}>
              <ListItem button>
                <ListItemText primary={branch.name} />
              </ListItem>
            </a>
          ))}
        </List>
      </Dialog>
    );
  }

  // Confirmation##
  function renderAppointmentConfirmation() {
    const aptOwner = appointmentOwner
      ? appointmentUsers.find((u) => u.id == appointmentOwner)
      : null;
    const spanStyle = { color: "#00bcd4" };
    const feeStyle = { color: "#e1261f" };
    const infoStyle = { color: "#bbb6b6", fontStyle: "italic", margin: "0px" };

    return (
      <DialogContent className={classes.dialogContent}>
        <p>
          Name: <span style={spanStyle}>{userInfo.name}</span>
        </p>
        <p>
          Number: <span style={spanStyle}>{userInfo.phone}</span>
        </p>
        <p>
          Email: <span style={spanStyle}>{userInfo.email}</span>
          {/*<span style={{ fontStyle: "italic" }}>*/}
          {/*  {isNewUser ? " (New client)" : " (Returning Client)"}*/}
          {/*</span>*/}
        </p>
        <p>
          Appointment:{" "}
          <span style={spanStyle}>
            {moment(appointmentDate).format("dddd[,] MMMM Do[,] YYYY")}
          </span>{" "}
          <span style={spanStyle}>
            at <span style={spanStyle}>{appointmentSlot}</span>{" "}
          </span>
        </p>
        {aptOwner && (
          <p>
            With:{" "}
            <span style={spanStyle}>
              {aptOwner.firstName} {aptOwner.lastName}
            </span>
          </p>
        )}
        {aptOwner && aptOwner.isPaid && aptOwner.paidAmount && (
          <p>
            Fee: <span style={feeStyle}>${aptOwner.paidAmount}</span>{" "}
            <p style={infoStyle}>
              You can pay this fee at the office during your visit.
            </p>
            <p style={infoStyle}>We accept major credit cards / cash.</p>
          </p>
        )}
      </DialogContent>
    );
  }

  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const { student, visa, skillAssessment, healthInsurance, other } =
    serviceSelected;
  const error =
    [student, visa, skillAssessment, healthInsurance, other].filter((v) => v)
      .length === 0;
  const contactFormFilled =
    userInfo.name &&
    userInfo.phone &&
    userInfo.email &&
    userInfo.validPhone &&
    userInfo.validEmail;

  const [branchModalOpen, setBranchModalOpen] = React.useState(false);
  const handleBranchModalOpen = () => {
    setBranchModalOpen(true);
  };
  const handleBranchModalClose = () => {
    setBranchModalOpen(false);
    setStepIndex(1);
  };

  const iframe = `<iframe
                    src=${BRANCH_LOCATION_MAP}
                    width="100%"
                    height="100%"
                    style="border:0;"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>`;

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Schedule an Appointment | {COMPANY_NAME}</title>
        <meta
          name="description"
          content="Schedule an Appointment. Student | Visa | Skill Assessment | Insurance services"
        />
        <meta
          name="og:title"
          content={`Schedule Appointment for ${COMPANY_NAME})`}
        />
      </Helmet>
      <Card>
        <CardBody>
          <GridContainer justifyContent="center">
            <GridItem md={4} sm={8} xs={8}>
              <img className={classes.imgLogo} src={LOGO_HORIZONTAL} />
            </GridItem>
          </GridContainer>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={8}>
              {processed ? (
                <ThankYou
                  appointment={finalAppointment}
                  isApt
                  summary={renderConfirmationString}
                />
              ) : (
                <Card>
                  {renderConfirmationString()}
                  <GridContainer>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_CAPTCHA_INVISIBLE_KEY}
                      ref={recaptchaRef}
                      size="invisible"
                    />
                    <GridItem xs={12} sm={12}>
                      <h3 className={classes.titleApt}>
                        Schedule an Appointment - {BRANCH_NAME}
                      </h3>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <Stepper
                        activeStep={stepIndex}
                        nonLinear={true}
                        orientation="vertical"
                      >
                        <Step completed>
                          <StepButton
                            style={{ textAlign: "initial" }}
                            onClick={() => {
                              setStepIndex(0);
                              handleBranchModalOpen();
                            }}
                          >
                            Branch - {BRANCH_NAME} (Click to Change)
                          </StepButton>
                          <StepContent>
                            <div>
                              <BranchDialog
                                open={branchModalOpen}
                                onClose={handleBranchModalClose}
                              />
                            </div>
                          </StepContent>
                        </Step>
                        <Step
                          disabled={loading}
                          completed={isServiceSelected()}
                        >
                          <StepButton
                            onClick={() => setStepIndex(1)}
                            style={{ textAlign: "initial" }}
                          >
                            Select a service
                          </StepButton>
                          <StepContent>
                            <FormControl
                              required
                              error={error}
                              component="fieldset"
                              className={classes.formControl}
                            >
                              <FormLabel component="legend">
                                At least select one
                              </FormLabel>
                              <FormGroup>
                                <FormControlLabel
                                  style={{
                                    color: serviceSelected.student
                                      ? "black"
                                      : "gray",
                                  }}
                                  control={
                                    <Checkbox
                                      checked={serviceSelected.student}
                                      onChange={handleService}
                                      name="student"
                                    />
                                  }
                                  label="Student Service"
                                />
                                <FormControlLabel
                                  style={{
                                    color: serviceSelected.visa
                                      ? "black"
                                      : "gray",
                                  }}
                                  control={
                                    <Checkbox
                                      checked={serviceSelected.visa}
                                      onChange={handleService}
                                      name="visa"
                                    />
                                  }
                                  label="Visa Service"
                                />
                                <FormControlLabel
                                  style={{
                                    color: serviceSelected.skillAssessment
                                      ? "black"
                                      : "gray",
                                  }}
                                  control={
                                    <Checkbox
                                      checked={serviceSelected.skillAssessment}
                                      onChange={handleService}
                                      name="skillAssessment"
                                    />
                                  }
                                  label="Skill Assessment"
                                />
                                <FormControlLabel
                                  style={{
                                    color: serviceSelected.healthInsurance
                                      ? "black"
                                      : "gray",
                                  }}
                                  control={
                                    <Checkbox
                                      checked={serviceSelected.healthInsurance}
                                      onChange={handleService}
                                      name="healthInsurance"
                                    />
                                  }
                                  label="Health Insurance"
                                />
                                <FormControlLabel
                                  style={{
                                    color: serviceSelected.other
                                      ? "black"
                                      : "gray",
                                  }}
                                  control={
                                    <Checkbox
                                      checked={serviceSelected.other}
                                      onChange={handleService}
                                      name="other"
                                    />
                                  }
                                  label="Other"
                                />
                              </FormGroup>
                            </FormControl>
                          </StepContent>
                        </Step>
                        <Step disabled={error} completed={!!appointmentDate}>
                          <StepButton
                            onClick={() => setStepIndex(2)}
                            style={{ textAlign: "initial" }}
                          >
                            Choose an available day for your appointment
                          </StepButton>
                          <StepContent>
                            <InputLabel htmlFor="appointmentOwner">
                              Appointment With:
                            </InputLabel>
                            <RadioGroup
                              style={{ marginTop: 10 }}
                              name="appointmentOwner"
                              value={appointmentOwner}
                              onChange={(event) =>
                                handleAppointmentOwnerSelect(event)
                              }
                            >
                              {renderAppointmentWith()}
                            </RadioGroup>
                            {/*<Select*/}
                            {/*  inputProps={{*/}
                            {/*    name: "appointmentOwner",*/}
                            {/*    id: "appointmentOwner",*/}
                            {/*  }}*/}
                            {/*  value={appointmentOwner}*/}
                            {/*  onChange={(evt) =>*/}
                            {/*    handleAppointmentOwnerSelect(evt)*/}
                            {/*  }*/}
                            {/*>*/}
                            {/*  {appointmentUsers &&*/}
                            {/*    appointmentUsers.map((user) => (*/}
                            {/*      <MenuItem*/}
                            {/*        key={user.id}*/}
                            {/*        value={user.id}*/}
                            {/*      >{`${user.firstName} ${user.lastName}`}</MenuItem>*/}
                            {/*    ))}*/}
                            {/*</Select>*/}
                          </StepContent>
                          <StepContent>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                InputProps={{ readOnly: true }}
                                style={{
                                  marginTop: 10,
                                }}
                                autoOk
                                onClick={() => setIsPickerOpen(true)}
                                open={isYearPickerOpen}
                                onClose={() => setIsPickerOpen(false)}
                                shouldDisableDate={disableWeekends}
                                format="MMM dd, yyyy"
                                value={appointmentDate || null}
                                onChange={(date) =>
                                  handleSetAppointmentDate(
                                    date,
                                    appointmentOwner
                                  )
                                }
                                disablePast
                                emptyLabel={"Please select a date"}
                              />
                            </MuiPickersUtilsProvider>
                          </StepContent>
                        </Step>
                        <Step
                          disabled={!appointmentDate}
                          completed={!!appointmentSlot}
                        >
                          <StepButton
                            onClick={() => setStepIndex(3)}
                            style={{ textAlign: "initial" }}
                          >
                            Choose an available time for your appointment
                          </StepButton>
                          <StepContent>
                            <Select
                              value={amPm}
                              onChange={(evt) => handleAmPm(evt)}
                            >
                              <MenuItem value="AM">AM</MenuItem>
                              <MenuItem value="PM">PM</MenuItem>
                            </Select>
                            <RadioGroup
                              style={{ marginTop: 15 }}
                              name="appointmentTimes"
                              value={appointmentSlot || ""}
                              onChange={(event) =>
                                handleSetAppointmentSlot(event.target.value)
                              }
                            >
                              {renderAppointmentTimes()}
                            </RadioGroup>
                          </StepContent>
                        </Step>
                        <Step
                          disabled={!appointmentSlot}
                          completed={contactFormFilled}
                        >
                          <StepButton
                            onClick={() => setStepIndex(4)}
                            style={{ textAlign: "initial" }}
                          >
                            Share your contact information
                          </StepButton>
                          <StepContent>
                            <form
                              className={classes.root}
                              noValidate
                              autoComplete="off"
                            >
                              <TextField
                                style={{ marginTop: "10px" }}
                                required
                                id="name"
                                label="Full Name"
                                name="name"
                                value={userInfo.name || ""}
                                onChange={(evt) => {
                                  setUserInfo({
                                    ...userInfo,
                                    ...{ name: evt.currentTarget.value },
                                  });
                                }}
                              />
                              <TextField
                                style={{ marginTop: "10px" }}
                                error={
                                  !!(userInfo.email && !userInfo.validEmail)
                                }
                                // helperText={
                                //   userInfo.validEmail
                                //     ? isNewUser
                                //       ? "*New Client"
                                //       : "*Returning Client"
                                //     : ""
                                // }
                                required
                                value={userInfo.email || ""}
                                id="email"
                                label="Email"
                                name="email"
                                onBlur={(evt) =>
                                  checkForEmail(evt.currentTarget.value)
                                }
                                onChange={(evt) =>
                                  validateEmail(evt.currentTarget.value)
                                }
                              />

                              <TextField
                                style={{ marginTop: "10px" }}
                                error={
                                  !!(userInfo.phone && !userInfo.validPhone)
                                }
                                value={userInfo.phone || ""}
                                required
                                id="phone"
                                label="Phone"
                                name="phone"
                                // type={"number"}
                                onChange={(evt) =>
                                  validatePhone(evt.currentTarget.value)
                                }
                              />
                              <Button
                                style={{ marginTop: "10px" }}
                                variant={"contained"}
                                fullWidth={true}
                                color={
                                  !contactFormFilled || processed
                                    ? "default"
                                    : "primary"
                                }
                                onClick={() =>
                                  setConfirmationModalOpen(
                                    !confirmationModalOpen
                                  )
                                }
                                disabled={!contactFormFilled || processed}
                              >
                                {contactFormFilled
                                  ? "Schedule"
                                  : "Fill out your information to schedule"}
                              </Button>
                            </form>
                          </StepContent>
                        </Step>
                      </Stepper>
                    </GridItem>
                  </GridContainer>
                </Card>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <div
                className={classes.contact}
                style={{
                  position: "sticky",
                  top: "0px",
                }}
              >
                <h3
                  style={{
                    fontSize: "17px",
                    fontWeight: "500",
                    lineHeight: "1.5",
                    color: "#333",
                  }}
                >
                  Contact info
                </h3>
                <div className={classes.map}>
                  <Iframe iframe={iframe} />
                </div>
                <div className={classes.contactInfo}>
                  <div className={classes.contactItem}>
                    <HBGInfo isVertical />
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={confirmationModalOpen}
        title="Confirm your appointment"
      >
        <DialogTitle id="dialog-id">Confirm your appointment</DialogTitle>
        {renderAppointmentConfirmation()}
        {confirmationSnackbarMessage && isError && (
          <SnackbarContent
            message={confirmationSnackbarMessage}
            color="warning"
          />
        )}
        <DialogActions>
          <Button
            onClick={() => {
              setConfirmationModalOpen(false);
              setIsError(false);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={async () => {
              setSending(true);
              await handleSubmit();
              setSending(false);
            }}
            color="primary"
            autoFocus
            disabled={sending || isError}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <SnackBar
        open={confirmationSnackbarOpen || loading}
        message={loading ? "Loading... " : confirmationSnackbarMessage || ""}
        autoHideDuration={10000}
        onClose={() => setConfirmationSnackbarOpen(false)}
      />
    </div>
  );
}
