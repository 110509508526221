import {
  VISA_APPLICANT_STATS_SUCCEEDED,
  VISA_APPLICANT_STATS_REQUESTED,
  VISA_APPLICANT_STATS_FAILED,
  EDUCATION_STATS_FAILED,
  EDUCATION_STATS_REQUESTED,
  EDUCATION_STATS_SUCCEEDED,
  SKILL_ASSESSMENT_STATS_FAILED,
  SKILL_ASSESSMENT_STATS_REQUESTED,
  SKILL_ASSESSMENT_STATS_SUCCEEDED,
  HEALTH_INSURANCE_STATS_FAILED,
  HEALTH_INSURANCE_STATS_REQUESTED,
  HEALTH_INSURANCE_STATS_SUCCEEDED,
  CONVERTED_STATS_FAILED,
  CONVERTED_STATS_REQUESTED,
  CONVERTED_STATS_SUCCEEDED,
  CLEAR_ERROR,
  LEAD_STATS_REQUESTED,
  LEAD_STATS_FAILED,
  LEAD_STATS_SUCCEEDED,
} from "../actions";

const defaultError = {
  visaApplicantGetStatsError: false,
  visaApplicantGetStatsSuccess: false,
  visaApplicantStatsFetching: false,
  educationGetStatsError: false,
  educationGetStatsSuccess: false,
  educationStatsFetching: false,
  skillAssementGetStatsError: false,
  skillAssementGetStatsSuccess: false,
  skillAssementStatsFetching: false,
  healthInsuranceGetStatsError: false,
  healthInsuranceGetStatsSuccess: false,
  healthInsuranceStatsFetching: false,
  leadGetStatsError: false,
  leadGetStatsSuccess: false,
  leadStatsFetching: false,
  convertedStatsError: false,
  convertedStatsSuccess: false,
  convertedStatsFetching: false,
  errorMsg: "",
};

const defaultState = {
  visaApplicantsStats: [],
  educationStats: [],
  skillAssesementStats: [],
  healthInsuranceStats: [],
  leadStats: [],
  convertedStats: {},
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    //visa applicant
    case VISA_APPLICANT_STATS_REQUESTED:
      return {
        ...state,
        visaApplicantStatsFetching: true,
        visaApplicantGetStatsError: false,
      };
    case VISA_APPLICANT_STATS_FAILED:
      return {
        ...state,
        visaApplicantStatsFetching: false,
        visaApplicantGetStatsError: true,
      };
    case VISA_APPLICANT_STATS_SUCCEEDED:
      return {
        ...state,
        visaApplicantsStats: action.payload,
        visaApplicantStatsFetching: false,
        visaApplicantGetStatsError: false,
      };
    //education
    case EDUCATION_STATS_REQUESTED:
      return {
        ...state,
        educationStatsFetching: true,
        educationGetStatsError: false,
      };
    case EDUCATION_STATS_FAILED:
      return {
        ...state,
        educationStatsFetching: false,
        educationGetStatsError: true,
      };
    case EDUCATION_STATS_SUCCEEDED:
      return {
        ...state,
        educationStats: action.payload,
        educationStatsFetching: false,
        educationGetStatsError: false,
      };
    //skill assessment
    case SKILL_ASSESSMENT_STATS_REQUESTED:
      return {
        ...state,
        skillAssessmentStatsFetching: true,
        skillAssessmentGetStatsError: false,
      };
    case SKILL_ASSESSMENT_STATS_FAILED:
      return {
        ...state,
        skillAssessmentStatsFetching: false,
        skillAssessmentGetStatsError: true,
      };
    case SKILL_ASSESSMENT_STATS_SUCCEEDED:
      return {
        ...state,
        skillAssesementStats: action.payload,
        skillAssessmentStatsFetching: false,
        skillAssessmentGetStatsError: false,
      };
    //health insurance
    case HEALTH_INSURANCE_STATS_REQUESTED:
      return {
        ...state,
        healthInsuranceStatsFetching: true,
        healthInsuranceGetStatsError: false,
      };
    case HEALTH_INSURANCE_STATS_FAILED:
      return {
        ...state,
        healthInsuranceStatsFetching: false,
        healthInsuranceGetStatsError: true,
      };
    case HEALTH_INSURANCE_STATS_SUCCEEDED:
      return {
        ...state,
        healthInsuranceStats: action.payload,
        healthInsuranceStatsFetching: false,
        healthInsuranceGetStatsError: false,
      };

    //lead
    case LEAD_STATS_REQUESTED:
      return {
        ...state,
        leadStatsFetching: true,
        leadGetStatsError: false,
      };
    case LEAD_STATS_FAILED:
      return {
        ...state,
        leadStatsFetching: false,
        leadGetStatsError: true,
      };
    case LEAD_STATS_SUCCEEDED:
      return {
        ...state,
        leadStats: action.payload,
        leadStatsFetching: false,
        leadGetStatsError: false,
      };
    //converted stats
    case CONVERTED_STATS_REQUESTED:
      return {
        ...state,
        convertedStatsFetching: true,
        convertedStatsError: false,
      };
    case CONVERTED_STATS_FAILED:
      return {
        ...state,
        convertedStatsFetching: false,
        convertedStatsError: true,
      };
    case CONVERTED_STATS_SUCCEEDED:
      return {
        ...state,
        convertedStats: action.payload,
        convertedStatsFetching: false,
        convertedStatsError: false,
      };
    default:
      return state;
  }
}
