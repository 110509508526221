import React, { useEffect } from "react";
import {
  capsFirstLetter,
  convertDate,
  getUserName,
} from "../../../scripts/util";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { SUPER_ADMIN_ROLES, TINY_INIT } from "../../../scripts/const";
import Interweave from "interweave";
import {
  LEAVE_GET_ALL_REQUESTED,
  LEAVE_GET_USER_REQUESTED,
  LEAVE_UPDATE_REQUESTED,
} from "../../../redux/actions";
import ReactGA from "react-ga4";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import { Editor } from "@tinymce/tinymce-react";

const PendingList = ({ leaveData, userId, viewOnly }) => {
  const dispatch = useDispatch();
  const apiSuccess = useSelector((state) => state.leave.leaveUpdateSuccess);
  const apiError = useSelector((state) => state.leave.leaveUpdateError);
  const apiProgress = useSelector((state) => state.leave.leaveUpdateFetching);
  const allUsers = useSelector((state) => state.user.users);
  const [approvalModalOpen, setApprovalModalOpen] = React.useState(false);
  const [isApprove, setIsApprove] = React.useState(false);
  const [leaveID, setLeaveID] = React.useState(null);

  useEffect(() => {
    if (apiSuccess && userId) {
      const payload = { userId };
      dispatch({ type: LEAVE_GET_USER_REQUESTED, payload });
      dispatch({ type: LEAVE_GET_ALL_REQUESTED });
    }
  }, [apiSuccess]);

  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );

  useEffect(() => {
    if (!leaveData || leaveData.length === 0) {
      return;
    }
  }, []);

  if (!leaveData || leaveData.length === 0) {
    return null;
  }

  const approveLeave = (id, managerNote) => {
    const payload = { id, payload: { status: "approved", managerNote } };
    dispatch({ type: LEAVE_UPDATE_REQUESTED, payload });
    ReactGA.event({
      category: "Leave",
      action: "Leave Approved",
    });
    approvalModalOnClose();
  };

  const rejectLeave = (id, managerNote) => {
    const payload = { id, payload: { status: "rejected", managerNote } };
    dispatch({ type: LEAVE_UPDATE_REQUESTED, payload });
    ReactGA.event({
      category: "Leave",
      action: "Leave Rejected",
    });
    approvalModalOnClose();
  };

  const pendingLeave = viewOnly
    ? leaveData
    : leaveData.filter((leave) => leave.status === "pending");
  if (!pendingLeave) {
    return null;
  }
  const getStatusColor = (status) => {
    if (status === "approved") {
      return "green";
    }
    if (status === "rejected") {
      return "red";
    }
    if (status === "pending") {
      return "orange";
    }
    return null;
  };

  const list = pendingLeave.map((leave, id) => {
    return (
      <tr key={id}>
        <td>{capsFirstLetter(leave.type)}</td>
        <td>{leave.startDate}</td>
        <td>{leave.endDate}</td>
        <td>{leave.hoursPerDay}</td>
        <td>{convertDate(leave.createdAt)}</td>
        <td
          style={{
            color: getStatusColor(leave.status),
          }}
        >
          {capsFirstLetter(leave.status)}
        </td>
        <td>{getUserName(leave.updatedBy, allUsers).split(" ")[0]}</td>
        <td>
          <Interweave content={leave.note} />
        </td>
        <td>
          <Interweave content={leave.managerNote} />
        </td>
        <td>
          {leave.attachmentURL ? (
            <a
              rel={"noopener noreferrer"}
              href={leave.attachmentURL}
              target={"_blank"}
            >
              View
            </a>
          ) : (
            "N/A"
          )}
        </td>
        {SUPER_ADMIN_ROLES.includes(currentRoleId) && !viewOnly && (
          <td style={{ whiteSpace: "nowrap" }}>
            <OverlayTrigger overlay={<Tooltip>Approve</Tooltip>}>
              {/*<Button size="sm" onClick={() => approveLeave(leave.id)}>*/}
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  setLeaveID(leave.id);
                  handleApprovalOpen();
                }}
              >
                ✓
              </Button>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Reject</Tooltip>}>
              {/*<Button*/}
              {/*  size="sm"*/}
              {/*  onClick={() => cancelLeave(leave.id)}*/}
              {/*  style={{ marginLeft: "5px" }}*/}
              {/*>*/}
              <Button
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  setLeaveID(leave.id);
                  handleRejectOpen();
                }}
                style={{ marginLeft: "5px" }}
              >
                x
              </Button>
            </OverlayTrigger>
          </td>
        )}
      </tr>
    );
  });

  const handleApprovalOpen = () => {
    setIsApprove(true);
    setApprovalModalOpen(true);
  };

  const handleRejectOpen = () => {
    setIsApprove(false);
    setApprovalModalOpen(true);
  };

  const approvalModalOnClose = () => {
    setApprovalModalOpen(false);
    setLeaveID(null);
    setIsApprove(false);
  };
  return list.length > 0 ? (
    <>
      {!viewOnly && <h3>Pending Request:</h3>}
      {apiError && (
        <SnackbarContent message={"Something went wrong"} color="warning" />
      )}
      {apiProgress && <CustomLinearProgress color="primary" />}
      {apiSuccess && <SnackbarContent message="Success" color="success" />}
      <Table size="sm" responsive>
        <tbody>
          <tr>
            <th>Type</th>
            <th>Start date</th>
            <th>End Date</th>
            <th>Hrs/day</th>
            <th>Created_At</th>
            <th>Status</th>
            <th>Updated_By</th>
            <th>Note</th>
            <th>ManagerNote</th>
            <th>Attachment</th>
            {SUPER_ADMIN_ROLES.includes(currentRoleId) && !viewOnly && (
              <th>Action</th>
            )}
          </tr>
        </tbody>
        <tbody>{list}</tbody>
      </Table>
      <ApprovalModal
        open={approvalModalOpen}
        onClose={approvalModalOnClose}
        isApproval={isApprove}
        leaveID={leaveID}
        rejectLeave={rejectLeave}
        approveLeave={approveLeave}
      />
    </>
  ) : (
    <>
      {apiError && (
        <SnackbarContent
          autoHideDuration={3000}
          message={"Something went wrong"}
          color="warning"
        />
      )}
      {apiProgress && <CustomLinearProgress color="primary" />}
      {apiSuccess && (
        <SnackbarContent
          autoHideDuration={3000}
          message="Leave Update Success"
          color="success"
        />
      )}
    </>
  );
};

function ApprovalModal(props) {
  const { open, onClose, leaveID, isApproval, rejectLeave, approveLeave } =
    props;
  const [managerNote, setManagerNote] = React.useState(null);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{isApproval ? "Approve Leave" : "Reject Leave"}</DialogTitle>
      <DialogContent id="classic-modal-slide-description">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <h4>Manager's Note:</h4>
            <Editor
              licenseKey={"gpl"}
              tinymceScriptSrc={
                "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
              }
              value={managerNote || ""}
              init={TINY_INIT}
              onEditorChange={(value) => setManagerNote(value)}
            />
          </GridItem>
        </GridContainer>
        <Button
          color="success"
          onClick={() => {
            isApproval
              ? approveLeave(leaveID, managerNote)
              : rejectLeave(leaveID, managerNote);
          }}
        >
          {isApproval ? "Approve" : "Reject"}
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default PendingList;
