import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import ReactGA from "react-ga4";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import { FORGOT_PASSWORD_REQUESTED } from "../../redux/actions";
import Version from "../../components/Version";
const useStyles = makeStyles(styles);

const ForgotPasswordPage = (props) => {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [user, setState] = React.useState({ email: "" });
  const [emailState, setEmailState] = React.useState("");
  const [emailSentState, setEmailSentState] = React.useState(false);

  const dispatch = useDispatch();
  const forgotProgress = useSelector(
    (state) => state.password.forgotPasswordFetching
  );
  const forgotSuccess = useSelector(
    (state) => state.password.forgotPasswordSuccess
  );
  const forgotError = useSelector(
    (state) => state.password.forgotPasswordError
  );

  const verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  function forgotPassword() {
    let allFieldGood;
    // if (emailSentState) {
    //   return;
    // }
    if (user.email && verifyEmail(user.email)) {
      allFieldGood = true;
      setEmailState("success");
    } else {
      allFieldGood = false;
      setEmailState("error");
      return;
    }
    if (allFieldGood) {
      dispatch({ type: FORGOT_PASSWORD_REQUESTED, payload: user });
      setEmailSentState(true);
    }
  }

  const handleChange = (e) => {
    setState({
      ...user,
      [e.target.id]: e.target.value,
    });
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Forgot Password</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  success={emailState === "success"}
                  error={emailState === "error"}
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: handleChange,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                {forgotProgress && <CustomLinearProgress color="primary" />}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  disabled={forgotProgress ? true : false}
                  color="rose"
                  simple
                  size="lg"
                  block
                  onClick={() => {
                    ReactGA.event({
                      category: "Login",
                      action: "Forgot Password",
                    });
                    forgotPassword(props);
                  }}
                >
                  Send Reset Email
                </Button>
              </CardFooter>
              {forgotSuccess && (
                <SnackbarContent message={"Email Sent"} color="success" />
              )}
              {forgotError && (
                <SnackbarContent
                  message={"Something went wrong"}
                  color="danger"
                />
              )}
            </Card>
            <Version />
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ForgotPasswordPage;
