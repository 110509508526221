import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  CLOCK_LOGIN_REQUESTED,
  CLOCK_LOGIN_FAILED,
  CLOCK_LOGIN_SUCCEEDED,
  CLOCK_IN_REQUESTED,
  CLOCK_IN_FAILED,
  CLOCK_IN_SUCCEEDED,
  CLOCK_OUT_REQUESTED,
  CLOCK_OUT_FAILED,
  CLOCK_OUT_SUCCEEDED,
  CLOCK_BREAK_START_REQUESTED,
  CLOCK_BREAK_START_FAILED,
  CLOCK_BREAK_START_SUCCEEDED,
  CLOCK_BREAK_END_REQUESTED,
  CLOCK_BREAK_END_FAILED,
  CLOCK_BREAK_END_SUCCEEDED,
  CLOCK_GET_REQUESTED,
  CLOCK_GET_FAILED,
  CLOCK_GET_SUCCEEDED,
  CLOCK_IN_UPDATE_REQUESTED,
  CLOCK_IN_UPDATE_SUCCEEDED,
  CLOCK_IN_UPDATE_FAILED,
} from "../actions";
import { getClockInAuth, getToken } from "./selectors";

function* clockLogin(action) {
  try {
    const payload = action.payload;
    const clockLogin = yield call(remoteResource.clockLogin, payload);
    yield put({
      type: CLOCK_LOGIN_SUCCEEDED,
      payload: clockLogin.data,
    });
  } catch (e) {
    yield put({
      type: CLOCK_LOGIN_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* getClockInData(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    const clockIn = yield call(remoteResource.getClockInData, id, token);
    yield put({
      type: CLOCK_GET_SUCCEEDED,
      payload: clockIn.data,
    });
  } catch (e) {
    yield put({ type: CLOCK_GET_FAILED, payload: e.response.data.message });
  }
}

function* clockIn() {
  try {
    const { token } = yield select(getClockInAuth);
    yield call(remoteResource.clockIn, token);
    yield put({
      type: CLOCK_IN_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: CLOCK_IN_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* clockOut() {
  try {
    const { token } = yield select(getClockInAuth);
    yield call(remoteResource.clockOut, token);
    yield put({
      type: CLOCK_OUT_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: CLOCK_OUT_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* breakStart() {
  try {
    const { token } = yield select(getClockInAuth);
    yield call(remoteResource.breakStart, token);
    yield put({
      type: CLOCK_BREAK_START_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: CLOCK_BREAK_START_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* breakEnd() {
  try {
    const { token } = yield select(getClockInAuth);
    yield call(remoteResource.breakEnd, token);
    yield put({
      type: CLOCK_BREAK_END_SUCCEEDED,
    });
  } catch (e) {
    yield put({
      type: CLOCK_BREAK_END_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* updateClockIn(action) {
  try {
    const { payload, id, userId } = action;
    const token = yield select(getToken);
    const clock = yield call(
      remoteResource.updateClockIn,
      userId,
      id,
      payload,
      token
    );
    yield put({
      type: CLOCK_IN_UPDATE_SUCCEEDED,
      payload: clock.data,
    });
  } catch (e) {
    yield put({ type: CLOCK_IN_UPDATE_FAILED, payload: e.message });
  }
}

export function* watchClockIn() {
  yield takeLatest(CLOCK_LOGIN_REQUESTED, clockLogin);
  yield takeLatest(CLOCK_IN_REQUESTED, clockIn);
  yield takeLatest(CLOCK_OUT_REQUESTED, clockOut);
  yield takeLatest(CLOCK_BREAK_START_REQUESTED, breakStart);
  yield takeLatest(CLOCK_BREAK_END_REQUESTED, breakEnd);
  yield takeLatest(CLOCK_GET_REQUESTED, getClockInData);
  yield takeLatest(CLOCK_IN_UPDATE_REQUESTED, updateClockIn);
}
