import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";

import StepProgressBar from "react-step-progress";
import "react-step-progress/dist/index.css";
import { STEP_MAPPER, STUDENT_STEPS } from "variables/student.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import {
  convertDate,
  getDropDown,
  getUserName,
  isValidStudentInputs,
} from "../../../scripts/util";
import { useDispatch, useSelector } from "react-redux";
import {
  CLEAR_ERROR,
  STUDENT_LOG_GET_REQUESTED,
  STUDENT_GET_ALL_REQUESTED,
  STUDENT_UPDATE_REQUESTED,
  STUDENT_DELETE_REQUESTED,
} from "../../../redux/actions";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import Muted from "../../../components/Typography/Muted";
import ReactGA from "react-ga4";
import {
  DEFAULT_DATE_FORMAT,
  SUPER_ADMIN_ROLES,
  GENERAL_ROLE,
  SUPER_ROLE,
  TINY_INIT,
} from "../../../scripts/const";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { Editor } from "@tinymce/tinymce-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import DropDownWithSearch from "../../../components/DropDownWithSearch/DropDownWithSearch";
import FileUpload from "../../../components/CustomUpload/FileUpload";

const useStyles = makeStyles({ ...styles, ...styles2 });

export default function EditStudent(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);
  const isSuperUser = SUPER_ROLE.includes(currentRoleId);

  const [error, setError] = React.useState(false);
  const [studentId, setStudentId] = React.useState(false);
  const [validationErrMsg, setValidationErrMsg] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [student, setStudent] = React.useState({});
  const [studentCopy, setStudentCopy] = React.useState({});
  const [studentLog, setStudentLog] = React.useState([]);
  const [isLog, setIsLog] = React.useState(false);
  const sLog = useSelector((state) => state.student.studentLog);
  const [logId, setLogId] = React.useState(null);

  const handleFileUpload = (fileUrl) => {
    if (!student.files) {
      setStudent({
        ...student,
        files: [fileUrl],
      });
    } else {
      setStudent({
        ...student,
        files: [...student.files, fileUrl],
      });
    }
  };

  const handleFileDelete = (fileUrl) => {
    setStudent({
      ...student,
      files: student.files.filter((f) => f != fileUrl),
    });
  };
  const handleLog = (event) => {
    if (isLog) {
      setStudent(studentCopy);
    } else {
      ReactGA.event({
        category: "ViewHistory",
        action: "View Student History",
      });
      setStudentCopy(student);
    }
    if (!isLog && studentLog.length === 0) {
      dispatch({ type: STUDENT_LOG_GET_REQUESTED, payload: studentId });
    }
    setIsLog(!isLog);
  };

  useEffect(() => {
    setStudentLog(sLog);
  }, [sLog]);

  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );
  const errMsg = useSelector((state) => state.student.errorMsg);
  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);
  const handleSelect = (e) => {
    setStudent({
      ...student,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditorChange = (content) => {
    setStudent({
      ...student,
      remarks: content,
    });
  };

  const dispatch = useDispatch();

  const editError = useSelector((state) => state.student.studentUpdateError);
  const editProgress = useSelector(
    (state) => state.student.studentUpdateFetching
  );
  const editSuccess = useSelector(
    (state) => state.student.studentUpdateSuccess
  );

  const deleteProgress = useSelector(
    (state) => state.student.studentDeleteFetching
  );
  const deleteSuccess = useSelector(
    (state) => state.student.studentDeleteFetching
  );

  function clearErrors() {
    setValidationErrMsg("");
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      clearErrors();
      setStudent(studentCopy);
      setStudentLog([]);
      setIsLog(false);
      setLogId(null);
      dispatch({ type: CLEAR_ERROR });
      hideAlert();
    }
  }, [modalOpen]);

  useEffect(() => {
    setValidationErrMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    setStudent(props.student);
    setStudentId(props.student.id);
  }, [props.student, props.open]);

  useEffect(() => {
    setError(editError);
  }, [editError]);

  useEffect(() => {
    setSuccess(editSuccess);
  }, [editSuccess]);

  useEffect(() => {
    if (editSuccess === true || deleteSuccess === true) {
      setTimeout(() => {
        if (deleteSuccess) {
          props.handleSuccess(false);
        }
        dispatch({ type: STUDENT_GET_ALL_REQUESTED });
      }, 720);
      setTimeout(() => {
        // setStudent({});
        setIsLog(false);
        dispatch({ type: CLEAR_ERROR });
      }, 750);
    }
  }, [editSuccess, deleteSuccess]);

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setStudent({
        ...student,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setStudent({
        ...student,
        [name]: date,
      });
    }
  };

  function editStudent() {
    const errors = isValidStudentInputs(student);
    if (errors.length > 0) {
      setValidationErrMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else {
      setValidationErrMsg("");
      dispatch({
        type: STUDENT_UPDATE_REQUESTED,
        payload: { payload: student, id: studentId },
      });
    }
  }

  function getLog() {
    return studentLog.map((st, index) => (
      <Card key={index} color={index === logId ? "primary" : null}>
        <CardBody
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setLogId(index);
            setStudent(studentLog[index]);
          }}
        >
          <small>{getUserName(st.updatedBy, props.allUsers)}</small>
          <br></br>
          <small>{convertDate(st.version_timestamp)}</small>
        </CardBody>
      </Card>
    ));
  }

  function deleteEntity() {
    dispatch({
      type: STUDENT_DELETE_REQUESTED,
      payload: { id: studentId },
    });
    ReactGA.event({
      category: "Student",
      action: "Deleted a Student",
    });
  }

  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      />
    );
  };
  const confirmationMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteEntity();
          successDelete();
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      />
    );
  };
  const classes = useStyles();
  const currentStep = STEP_MAPPER.education;

  return (
    <div>
      <StepProgressBar
        startingStep={currentStep}
        steps={STUDENT_STEPS}
        buttonWrapperClass={classes.hidden}
      />

      <GridContainer>
        <GridItem
          style={{
            textAlign: "right",
          }}
          xs={12}
          sm={12}
          md={12}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isLog}
                onChange={handleLog}
                value={isLog}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={isLog ? "HideHistory" : "ShowHistory"}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={3} sm={3} md={3}>
          {isLog && <div>{getLog()}</div>}
        </GridItem>
        <GridItem xs={isLog ? 9 : 12} sm={isLog ? 9 : 12} md={isLog ? 9 : 12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {editProgress && <CustomLinearProgress color="primary" />}
                  {validationErrMsg && (
                    <SnackbarContent
                      message={validationErrMsg}
                      color="warning"
                    />
                  )}
                  {error && (
                    <SnackbarContent message={errMessage} color="warning" />
                  )}
                  {success && (
                    <SnackbarContent
                      message="Updated the student"
                      color="success"
                    />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="ID"
                    id="editUser-id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: studentId || "",
                      disabled: true,
                      name: "id",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Email address *"
                    id="student-edit-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.email || "",
                      onChange: handleSelect,
                      name: "email",
                      type: "email",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="student-edit-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="First Name *"
                    id="student-edit-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Middle Name"
                    id="student-edit-middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.middleName || "",
                      onChange: handleSelect,
                      name: "middleName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Last Name *"
                    id="student-edit-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                      disabled: isLog,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Date of Birth
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.dob || ""}
                      onChange={(moment) => handleDatePicker(moment, "dob")}
                      inputProps={{
                        name: "dob",
                        id: "edit-student-dob-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                        disabled: isLog,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Passport No"
                    id="student-edit-passport"
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: student.passport,
                      onChange: handleSelect,
                      name: "passport",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Issue Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.issueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "issueDate")
                      }
                      inputProps={{
                        name: "issueDate",
                        id: "student-edit-issueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Expiry Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.expiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "expiryDate")
                      }
                      inputProps={{
                        name: "expiryDate",
                        id: "student-edit-expiryDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  {props.allUniversities && (
                    <DropDownWithSearch
                      dbOptions={props.allUniversities}
                      selectedOption={student.universityId || ""}
                      setValue={setStudent}
                      currentState={student}
                      name={"universityId"}
                      label={"University"}
                    />
                  )}
                </GridItem>
                {student.universityId && (
                  <GridItem xs={12} sm={12} md={6}>
                    {props.allCourses && (
                      <DropDownWithSearch
                        dbOptions={props.allCourses.filter(
                          (c) => c.universityId === student.universityId
                        )}
                        selectedOption={student.courseId || ""}
                        setValue={setStudent}
                        currentState={student}
                        name={"courseId"}
                        label={"Course"}
                      />
                    )}
                  </GridItem>
                )}
              </GridContainer>
              <GridContainer className={classes.gridUp}>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Uni Start Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.startDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "startDate")
                      }
                      inputProps={{
                        name: "startDate",
                        id: "edit-student-start-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                        disabled: isLog,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Uni End Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.endDate || ""}
                      onChange={(moment) => handleDatePicker(moment, "endDate")}
                      inputProps={{
                        name: "endDate",
                        id: "edit-student-end-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                        disabled: isLog,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Fee Due Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={student.feeDueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "feeDueDate")
                      }
                      inputProps={{
                        name: "feeDueDate",
                        id: "student-edit-feeDueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                        disabled: isLog,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <br></br>
                  <br></br>
                </GridItem>
              </GridContainer>
              {isSuperUser && (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Fees Paid"
                        id="student-edit-feesPaid"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          value: student.feesPaid || "",
                          onChange: handleSelect,
                          name: "feesPaid",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel className={classes.label}>
                        Fee Paid Date
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          closeOnSelect
                          timeFormat={false}
                          dateFormat={DEFAULT_DATE_FORMAT}
                          value={student.feesPaidDate || ""}
                          onChange={(moment) =>
                            handleDatePicker(moment, "feesPaidDate")
                          }
                          inputProps={{
                            name: "feesPaidDate",
                            id: "student-edit-feesPaidDate",
                            placeholder: DEFAULT_DATE_FORMAT,
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Fees Due Amount"
                        id="student-edit-fees-due-amount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          value: student.feesDueAmount || "",
                          onChange: handleSelect,
                          name: "feesDueAmount",
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Invoice Number"
                        id="student-edit-invoice-no"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: student.invoiceNo || "",
                          onChange: handleSelect,
                          name: "invoiceNo",
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel className={classes.label}>
                        Invoice Date
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          closeOnSelect
                          timeFormat={false}
                          dateFormat={DEFAULT_DATE_FORMAT}
                          value={student.invoiceDate || ""}
                          onChange={(moment) =>
                            handleDatePicker(moment, "invoiceDate")
                          }
                          inputProps={{
                            name: "invoiceDate",
                            id: "student-edit-invoiceDate",
                            placeholder: DEFAULT_DATE_FORMAT,
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <DropDownWithSearch
                        optionsList={getDropDown("invoice-status")}
                        selectedOption={student.invoiceStatus || ""}
                        setValue={setStudent}
                        currentState={student}
                        name={"invoiceStatus"}
                        label={"Invoice Status"}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Commission Amount"
                        id="student-edit-commission-amount"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "number",
                          value: student.commissionAmount || "",
                          onChange: handleSelect,
                          name: "commissionAmount",
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <br></br>
                    </GridItem>
                  </GridContainer>
                </>
              )}
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={
                    student.status &&
                    [
                      "Unconditional Offer",
                      "Follow Up",
                      "Coe Received",
                      "Canceled",
                      "Refused",
                    ].includes(student.status)
                      ? 4
                      : 6
                  }
                >
                  <DropDownWithSearch
                    optionsList={getDropDown("country")}
                    selectedOption={student.country || ""}
                    setValue={setStudent}
                    currentState={student}
                    name={"country"}
                    label={"Country"}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={
                    student.status &&
                    [
                      "Unconditional Offer",
                      "Follow Up",
                      "Coe Received",
                      "Canceled",
                      "Refused",
                    ].includes(student.status)
                      ? 4
                      : 6
                  }
                >
                  <DropDownWithSearch
                    optionsList={getDropDown("student-status")}
                    selectedOption={student.status || ""}
                    setValue={setStudent}
                    currentState={student}
                    name={"status"}
                    label={"Status *"}
                  />
                </GridItem>
                {student.status &&
                  [
                    "Unconditional Offer",
                    "Follow Up",
                    "Coe Received",
                    "Canceled",
                    "Refused",
                  ].includes(student.status) && (
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel className={classes.label}>
                        {student.status} Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          closeOnSelect
                          timeFormat={false}
                          dateFormat={DEFAULT_DATE_FORMAT}
                          value={student.statusDate || ""}
                          onChange={(moment) =>
                            handleDatePicker(moment, "statusDate")
                          }
                          inputProps={{
                            name: "statusDate",
                            id: "add-student-statusDate",
                            placeholder: DEFAULT_DATE_FORMAT,
                            disabled: isLog,
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  )}
              </GridContainer>
              <GridContainer className={classes.gridUp}>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("location")}
                    selectedOption={student.location || ""}
                    setValue={setStudent}
                    currentState={student}
                    name={"location"}
                    label={"Location"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.gridUp}>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allSources && (
                        <DropDownWithSearch
                          dbOptions={props.allSources}
                          selectedOption={student.sourceId || ""}
                          setValue={setStudent}
                          currentState={student}
                          name={"sourceId"}
                          label={"Source"}
                        />
                      )}
                    </GridItem>
                    <GridItem
                      className={classes.gridUp}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      {props.allUsers && (
                        <DropDownWithSearch
                          dbOptions={props.allUsers}
                          dbLabel={"firstName"}
                          selectedOption={student.userId || ""}
                          setValue={setStudent}
                          currentState={student}
                          name={"userId"}
                          label={"Assigned To"}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.gridUp}>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={student.remarks || ""}
                    init={TINY_INIT}
                    onEditorChange={handleEditorChange}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div>
                    <h4>Documents:</h4>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      type={"student"}
                      setFileUrl={(fileUrl) => handleFileUpload(fileUrl)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {student.files &&
                  student.files.length > 0 &&
                  student.files.map((fileUrl, id) => {
                    return (
                      <GridItem key={id}>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            backgroundColor: "#B0BEC5",
                            padding: "20px",
                            borderRadius: "25px",
                            marginTop: "10px",
                          }}
                        >
                          <FileUpload
                            fileUrl={fileUrl}
                            key={id}
                            handleDelete={(fileUrl) => {
                              handleFileDelete(fileUrl);
                            }}
                          />
                        </div>
                      </GridItem>
                    );
                  })}
              </GridContainer>
              <br />
              <br />
              <GridContainer
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <GridItem>
                  <Muted>Updated At: {convertDate(student.updatedAt)}</Muted>
                  <Muted>
                    Updated By: {getUserName(student.updatedBy, props.allUsers)}
                  </Muted>
                </GridItem>
                <GridItem
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.3rem",
                  }}
                >
                  {editProgress && <CircularProgress size={"2rem"} />}
                  {error && <CloseIcon style={{ color: "red" }} />}
                  {success && <CheckIcon style={{ color: "green" }} />}
                  {SUPER_ADMIN_ROLES.includes(currentRoleId) && (
                    <Button
                      color="danger"
                      disabled={!!(deleteProgress || isLog)}
                      className={classes.moveToRight}
                      onClick={(e) => {
                        e.preventDefault();
                        confirmationMessage();
                      }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    color="primary"
                    disabled={editProgress || isLog ? true : false}
                    className={classes.moveToRight}
                    onClick={(e) => {
                      e.preventDefault();
                      ReactGA.event({
                        category: "Student",
                        action: "Updated a Student",
                      });
                      editStudent();
                    }}
                  >
                    Update
                  </Button>
                </GridItem>
              </GridContainer>
              <Clearfix />
              {alert}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
