import React from "react";

import { useDispatch, useSelector } from "react-redux";

import MaterialTable, { MTableToolbar } from "material-table";

import {
  ANNOUNCEMENT_CREATE_REQUESTED,
  ANNOUNCEMENT_DELETE_REQUESTED,
  ANNOUNCEMENT_GET_ALL_REQUESTED,
  ANNOUNCEMENT_UPDATE_REQUESTED,
} from "../../../../redux/actions";
import ReactGA from "react-ga4";

export default function Announcement() {
  const getColumns = () => {
    return [
      {
        title: "Title",
        field: "title",
      },
      {
        title: "Description",
        field: "description",
      },
    ];
  };
  const dispatch = useDispatch();

  const announcementList = useSelector(
    (state) => state.announcement.announcements
  );

  const visaFetching = useSelector(
    (state) => state.occupation.occupationGetAllFetching
  );

  return (
    <>
      <MaterialTable
        title="Announcement"
        columns={getColumns()}
        data={announcementList}
        options={{
          exportButton: true,
          addRowPosition: "last",
          pageSize: 50,
          pageSizeOptions: [50, 100, 200, 500, 1410],
          headerStyle: {
            backgroundColor: "#0077c2",
            color: "#FFF",
            marginTop: "15px",
          },
          sorting: true,
        }}
        style={{
          marginTop: "30px",
        }}
        editable={{
          isEditable: () => true,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const payload = { id: oldData?.id, payload: newData };

                dispatch({
                  type: ANNOUNCEMENT_UPDATE_REQUESTED,
                  payload: payload,
                });
                ReactGA.event({
                  category: "Announcement",
                  action: "Announcement Updated",
                });
              }, 200);

              setTimeout(() => {
                dispatch({ type: ANNOUNCEMENT_GET_ALL_REQUESTED });
              }, 2000);
            }),
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                dispatch({
                  type: ANNOUNCEMENT_CREATE_REQUESTED,
                  payload: newData,
                });
                ReactGA.event({
                  category: "Announcement",
                  action: "Announcement Added",
                });
              }, 200);

              setTimeout(() => {
                dispatch({ type: ANNOUNCEMENT_GET_ALL_REQUESTED });
              }, 2000);
            }),
          onRowDelete: (newData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                dispatch({
                  type: ANNOUNCEMENT_DELETE_REQUESTED,
                  payload: newData,
                });
                ReactGA.event({
                  category: "Announcement",
                  action: "Announcement Added",
                });
              }, 200);

              setTimeout(() => {
                dispatch({ type: ANNOUNCEMENT_GET_ALL_REQUESTED });
              }, 2000);
            }),
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
      />
    </>
  );
}
