import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Badge from "components/Badge/Badge.js";

import { getRoleMapping, filterCaseInsensitive } from "../../../scripts/util";

import { useSelector } from "react-redux";
import withTimer from "components/Timer/withTimer";
import Button from "../../../components/CustomButtons/Button";
import CardHeader from "../../../components/Card/CardHeader";
import { NavLink } from "react-router-dom";
import { SUPER_ADMIN_ROLES } from "../../../scripts/const";

const EmployeeList = (props) => {
  const data = useSelector((state) => state.user.users);
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const getData = () => {
    if (SUPER_ADMIN_ROLES.includes(currentRoleId)) {
      return data;
    } else {
      const filteredUsers = data.filter(
        (u) => !SUPER_ADMIN_ROLES.includes(u.roleId)
      );
      return filteredUsers;
    }
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary">
            <h4 style={{ textAlign: "center" }}>
              View and Manage Employee's Time Sheet
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              pageSizeOptions={[10, 25, 50, 100]}
              data={getData()}
              filterable
              resizable={true}
              columns={[
                {
                  Header: "ID",
                  accessor: "id",
                  width: 70,
                },
                {
                  Header: "First Name",
                  accessor: "firstName",
                },
                {
                  Header: "Last Name",
                  accessor: "lastName",
                },
                {
                  Header: "Email",
                  accessor: "email",
                },
                {
                  Header: "Phone",
                  accessor: "phone",
                },
                {
                  Header: "Role",
                  accessor: "roleId",
                  Cell: (props) => {
                    if (props.value) {
                      const role = getRoleMapping(props.value);
                      if (role) {
                        return role;
                      } else {
                        return props.value;
                      }
                    } else {
                      return props.value;
                    }
                  },
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "Super Admin") {
                      return row[filter.id] === 1;
                    } else if (filter.value === "Manager") {
                      return row[filter.id] === 2;
                    } else if (filter.value === "General User") {
                      return row[filter.id] === 3;
                    } else if (filter.value === "Accountant") {
                      return row[filter.id] === 4;
                    } else {
                      return false;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                      <option value="Super Admin">Super Admin</option>
                      <option value="Manager">Manager</option>
                      <option value="General User">General User</option>
                      <option value="Accountant">Accountant</option>
                    </select>
                  ),
                },
                {
                  Header: "Status",
                  accessor: "isActive",
                  width: 125,
                  Cell: (props) => {
                    if (props.value) {
                      return <Badge color={"success"}>Active</Badge>;
                    }
                    return <Badge color={"danger"}>Inactive</Badge>;
                  },
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "Active") {
                      return row[filter.id] === true;
                    } else if (filter.value === "Inactive") {
                      return row[filter.id] === false;
                    } else {
                      return false;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  ),
                },
                {
                  headerStyle: { textAlign: "left" },
                  Header: "Action",
                  accessor: "id",
                  Cell: (props) => {
                    return (
                      <NavLink to={`/admin/employees/${props.value}`}>
                        <Button color="info">View</Button>
                      </NavLink>
                    );
                  },
                },
              ]}
              defaultPageSize={25}
              showPaginationBottom={true}
              className="-striped -highlight"
              defaultFilterMethod={filterCaseInsensitive}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withTimer(EmployeeList);
