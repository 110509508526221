import React, { useEffect } from "react";

import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";

import {
  CLEAR_ERROR,
  UNIVERSITY_CREATE_REQUESTED,
  UNIVERSITY_GET_ALL_REQUESTED,
  UNIVERSITY_UPDATE_REQUESTED,
} from "../../../../redux/actions";
import { SUPER_ROLE } from "../../../../scripts/const";
import { getDropDown } from "../../../../scripts/util";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../../../components/CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import UniversityContainer from "./UniversityContainer";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import ReactGA from "react-ga4";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(styles);

export default function University() {
  const classes = useStyles();
  const accessName = "universityManagement";
  const currentUser = useSelector((state) => state.login.loginUser.detail);
  const hasEditAccess =
    SUPER_ROLE.includes(currentUser.roleId) || currentUser[accessName];
  const educationLevelObj = () => {
    const educationLevels = getDropDown("education-level");
    const obj = {};
    for (let i = 0; i < educationLevels.length; i++) {
      obj[educationLevels[i]] = educationLevels[i];
    }
    return obj;
  };

  const trackInReportObj = () => {
    const trueFalseObj = getDropDown("true-false");
    const obj = {};
    for (let i = 0; i < trueFalseObj.length; i++) {
      obj[`${trueFalseObj[i]}`] = `${trueFalseObj[i]}`.toUpperCase();
    }
    return obj;
  };
  const getColumns = () => {
    return [
      // {
      //   width: 50,
      //   title: "ID",
      //   field: "id",
      //   editable: "never",
      //   type: "numeric",
      // },
      {
        title: "Name *",
        field: "name",
        defaultSort: "asc",
        validate: (rowData) => Boolean(rowData.name),
      },
      { title: "Location", field: "location" },
      { title: "Description", field: "description" },
      {
        title: "Group Report By",
        field: "educationLevel",
        lookup: educationLevelObj(),
      },
      {
        title: "Track In Report",
        field: "trackInReport",
        lookup: trackInReportObj(),
      },
      { title: "Comment", field: "comment" },
      {
        title: "Attachments",
        field: "files",
        editable: "never",
        render: (rowData) => {
          return rowData.files && rowData.files.length > 0 ? "YES" : "NO";
        },
      },
      // { title: "CreatedAt", field: "createdAt", editable: "never" },
      { title: "UpdatedAt", field: "updatedAt", editable: "never" },
    ];
  };

  const [data, setData] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedUniversity, setSelectedUniversity] = React.useState();

  const universities = useSelector((state) => state.university.universities);
  const saveSuccess = useSelector(
    (state) => state.university.universityCreateSuccess
  );
  const updateSuccess = useSelector(
    (state) => state.university.universityUpdateSuccess
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    setData(universities);
  }, [universities]);

  useEffect(() => {
    if (saveSuccess === true) {
      dispatch({ type: UNIVERSITY_GET_ALL_REQUESTED });
      dispatch({ type: CLEAR_ERROR });
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (updateSuccess === true) {
      dispatch({ type: UNIVERSITY_GET_ALL_REQUESTED });
      dispatch({ type: CLEAR_ERROR });
    }
  }, [updateSuccess]);

  return (
    <>
      <MaterialTable
        title="University Setup"
        columns={getColumns()}
        data={data}
        style={{
          marginTop: "30px",
        }}
        actions={
          hasEditAccess
            ? [
                {
                  icon: "visibility",
                  tooltip: "View",
                  onClick: (e, rowData) => {
                    e.preventDefault();
                    setSelectedUniversity(rowData);
                    setOpenModal(true);
                    ReactGA.event({
                      category: "University",
                      action: "View University",
                    });
                  },
                },
              ]
            : []
        }
        options={{
          // actionsColumnIndex: -1,
          exportButton: true,
          pageSize: 25,
          pageSizeOptions: [25, 50],
          headerStyle: {
            backgroundColor: "#0077c2",
            color: "#FFF",
          },
        }}
        editable={
          hasEditAccess
            ? {
                onRowAdd: (newData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      dispatch({
                        type: UNIVERSITY_CREATE_REQUESTED,
                        payload: newData,
                      });
                      ReactGA.event({
                        category: "University",
                        action: "University Added",
                      });
                    }, 200);
                  }),
                onRowUpdate: (newData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      dispatch({
                        type: UNIVERSITY_UPDATE_REQUESTED,
                        payload: { payload: newData, id: newData.id },
                      });
                      ReactGA.event({
                        category: "University",
                        action: "University Updated",
                      });
                    }, 200);
                  }),
              }
            : {}
        }
      />

      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={openModal}
        TransitionComponent={Transition}
        onClose={() => setOpenModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setOpenModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>{"Update University"}</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <UniversityContainer
            open={openModal}
            setOpen={setOpenModal}
            editData={selectedUniversity}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
