import React, { useEffect } from "react";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  isValidInsuranceApplicantInputs,
  getInsuranceProviderMapping,
  getInsuranceTypeMapping,
  getDropDown,
  isDuplicateApplicant,
  getAnzsco,
  getOccupation,
  labelStyleFunc,
} from "../../../scripts/util";
import {
  CLEAR_ERROR,
  INSURANCE_APPLICANT_CREATE_REQUESTED,
  INSURANCE_APPLICANT_GET_ALL_REQUESTED,
} from "../../../redux/actions";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import ReactGA from "react-ga4";
import {
  DEFAULT_DATE_FORMAT,
  GENERAL_ROLE,
  occupationCodeOption,
  useGetOccupationOptions,
  useGetVisaTypeOptions,
  TINY_INIT,
} from "../../../scripts/const";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import ConfirmationAlert from "../../../components/Alert/ConfirmationAlert";
import { Editor } from "@tinymce/tinymce-react";
import { default as ReactSelect } from "react-select";
import FileUpload from "../../../components/CustomUpload/FileUpload";
import DropDownWithSearch, {
  customReactSelectStyles,
} from "../../../components/DropDownWithSearch/DropDownWithSearch";

const useStyles = makeStyles(styles);

export default function AddInsurance(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const occupationOption = useGetOccupationOptions();
  const visaTypeOption = useGetVisaTypeOptions();

  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const submit = () => {
    confirmInsurance();
  };
  const selectedEvent = () => {
    setAlert(<ConfirmationAlert cancel={hideAlert} submit={submit} />);
  };

  const [validationErrorMsg, setValidationErrorMsg] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [insuranceApplicant, setInsuranceApplicant] = React.useState({});
  const insuranceProviders = getInsuranceProviderMapping();
  const insuranceTypes = getInsuranceTypeMapping();
  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );
  const errMsg = useSelector((state) => state.insuranceApplicant.errorMsg);
  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);

  const handleFileUpload = (fileUrl) => {
    if (!insuranceApplicant.files) {
      setInsuranceApplicant({
        ...insuranceApplicant,
        files: [fileUrl],
      });
    } else {
      setInsuranceApplicant({
        ...insuranceApplicant,
        files: [...insuranceApplicant.files, fileUrl],
      });
    }
  };

  const handleFileDelete = (fileUrl) => {
    setInsuranceApplicant({
      ...insuranceApplicant,
      files: insuranceApplicant.files.filter((f) => f != fileUrl),
    });
  };

  const handleSelect = (e) => {
    setInsuranceApplicant({
      ...insuranceApplicant,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditorChange = (content) => {
    setInsuranceApplicant({
      ...insuranceApplicant,
      remarks: content,
    });
  };

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      setInsuranceApplicant({
        ...insuranceApplicant,
        [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
      });
    } else {
      setInsuranceApplicant({
        ...insuranceApplicant,
        [name]: date,
      });
    }
  };

  const dispatch = useDispatch();

  const saveError = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantCreateError
  );
  const saveProgress = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantCreateFetching
  );
  const saveSuccess = useSelector(
    (state) => state.insuranceApplicant.insuranceApplicantCreateSuccess
  );

  function createInsuranceApplicant() {
    const errors = isValidInsuranceApplicantInputs(insuranceApplicant);
    if (errors.length > 0) {
      setValidationErrorMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else if (
      isDuplicateApplicant(insuranceApplicant, props.allInsuranceApplicants)
    ) {
      selectedEvent();
    } else {
      confirmInsurance();
    }
  }

  function confirmInsurance() {
    setValidationErrorMsg("");
    dispatch({
      type: INSURANCE_APPLICANT_CREATE_REQUESTED,
      payload: insuranceApplicant,
    });
  }

  function clearErrors() {
    setValidationErrorMsg("");
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      clearErrors();
      // setStudent({});
    }
  }, [modalOpen]);

  useEffect(() => {
    setValidationErrorMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    setError(saveError);
  }, [saveError]);

  useEffect(() => {
    setSuccess(saveSuccess);
  }, [saveSuccess]);

  useEffect(() => {
    if (saveSuccess === true) {
      setTimeout(() => {
        props.handleSuccess(false);
      }, 700);
      dispatch({ type: INSURANCE_APPLICANT_GET_ALL_REQUESTED });
      setTimeout(() => {
        setInsuranceApplicant({});
        dispatch({ type: CLEAR_ERROR });
      }, 701);
    }
  }, [saveSuccess]);

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {validationErrorMsg && (
                    <SnackbarContent
                      message={validationErrorMsg}
                      color="warning"
                    />
                  )}
                  {saveProgress && <CustomLinearProgress color="primary" />}
                  {error && (
                    <SnackbarContent message={errMessage} color="warning" />
                  )}
                  {success && (
                    <SnackbarContent
                      message="Added the insurance applicant"
                      color="success"
                    />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email Address *"
                    id="insurance-add-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.email || "",
                      onChange: handleSelect,
                      name: "email",
                      type: "email",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="insurance-add-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="First Name *"
                    id="add-ins-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Middle Name"
                    id="add-ins-middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.middleName || "",
                      onChange: handleSelect,
                      name: "middleName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Last Name *"
                    id="add-ins-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Date of Birth
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.dob || ""}
                      onChange={(moment) => handleDatePicker(moment, "dob")}
                      inputProps={{
                        name: "dob",
                        id: "add-ins-dob-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Passport No"
                    id="add-ins-passport"
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.passport,
                      onChange: handleSelect,
                      name: "passport",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Issue Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.passportIssueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "passportIssueDate")
                      }
                      inputProps={{
                        name: "passportIssueDate",
                        id: "add-ins-passportIssueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Expiry Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.passportExpiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "passportExpiryDate")
                      }
                      inputProps={{
                        name: "passportExpiryDate",
                        id: "add-ins-passportExpiryDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(insuranceApplicant.currentVisa)}
                    >
                      Current Visa
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        insuranceApplicant.currentVisa
                          ? {
                              label: insuranceApplicant.currentVisa,
                              value: insuranceApplicant.currentVisa,
                            }
                          : ""
                      }
                      options={visaTypeOption}
                      name="currentVisa"
                      styles={customReactSelectStyles}
                      placeholder={"Current Visa"}
                      onChange={(options) => {
                        if (!options) {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            currentVisa: "",
                          });
                        } else {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            currentVisa: options.value,
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Current Insurance"
                    id="add-ins-currentInsurance"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.currentInsurance || "",
                      onChange: handleSelect,
                      name: "currentInsurance",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(insuranceApplicant.occupation)}
                    >
                      Occupation
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        insuranceApplicant.occupation
                          ? {
                              label: insuranceApplicant.occupation,
                              value: insuranceApplicant.occupation,
                            }
                          : ""
                      }
                      options={occupationOption}
                      name="occupation"
                      styles={customReactSelectStyles}
                      placeholder={"Occupation"}
                      onChange={(options) => {
                        if (!options) {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            occupation: "",
                            anzsco: "",
                          });
                        } else {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            occupation: options.value,
                            anzsco: getAnzsco(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(insuranceApplicant.anzsco)}
                    >
                      ANZSCO
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        insuranceApplicant.anzsco
                          ? {
                              label: insuranceApplicant.anzsco,
                              value: insuranceApplicant.anzsco,
                            }
                          : ""
                      }
                      options={occupationCodeOption}
                      name="anzsco"
                      styles={customReactSelectStyles}
                      placeholder={"ANZSCO"}
                      onChange={(options) => {
                        if (!options) {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            anzsco: "",
                            occupation: "",
                          });
                        } else {
                          setInsuranceApplicant({
                            ...insuranceApplicant,
                            anzsco: options.value,
                            occupation: getOccupation(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Insurance Start Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.startDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "startDate")
                      }
                      inputProps={{
                        name: "startDate",
                        id: "startDate-add-ins",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Insurance Expiry Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={insuranceApplicant.expiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "expiryDate")
                      }
                      inputProps={{
                        name: "expiryDate",
                        id: "expiryDate-add-ins",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <br />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("insurance-category")}
                    selectedOption={insuranceApplicant.category || ""}
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"category"}
                    label={"Category"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Payment Plan"
                    id="add-ins-payment-plan"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.paymentPlan || "",
                      onChange: handleSelect,
                      name: "paymentPlan",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Policy Number"
                    id="add-ins-policy-no"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.policyNumber || "",
                      onChange: handleSelect,
                      name: "policyNumber",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Total Paid"
                    id="add-ins-total-paid"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: insuranceApplicant.totalPaid || "",
                      onChange: handleSelect,
                      name: "totalPaid",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    dbOptions={insuranceTypes}
                    selectedOption={insuranceApplicant.insuranceTypeId || ""}
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"insuranceTypeId"}
                    label={"Insurance Type"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    dbOptions={insuranceProviders}
                    selectedOption={
                      insuranceApplicant.insuranceProviderId || ""
                    }
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"insuranceProviderId"}
                    label={"Insurance Provider"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("country")}
                    selectedOption={insuranceApplicant.country || ""}
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"country"}
                    label={"Country"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("insurance-status")}
                    selectedOption={insuranceApplicant.status || ""}
                    setValue={setInsuranceApplicant}
                    currentState={insuranceApplicant}
                    name={"status"}
                    label={"Status *"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allSources && (
                        <DropDownWithSearch
                          dbOptions={props.allSources}
                          selectedOption={insuranceApplicant.sourceId || ""}
                          setValue={setInsuranceApplicant}
                          currentState={insuranceApplicant}
                          name={"sourceId"}
                          label={"Source"}
                        />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allUsers && (
                        <DropDownWithSearch
                          dbOptions={props.allUsers}
                          dbLabel={"firstName"}
                          selectedOption={insuranceApplicant.userId || ""}
                          setValue={setInsuranceApplicant}
                          currentState={insuranceApplicant}
                          name={"userId"}
                          label={"Assigned To"}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={insuranceApplicant.remarks || ""}
                    init={TINY_INIT}
                    onEditorChange={handleEditorChange}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div>
                    <h4>Documents:</h4>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      type={"insuranceApplicant"}
                      setFileUrl={(fileUrl) => handleFileUpload(fileUrl)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {insuranceApplicant.files &&
                  insuranceApplicant.files.length > 0 &&
                  insuranceApplicant.files.map((fileUrl, id) => {
                    return (
                      <GridItem key={id}>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            backgroundColor: "#B0BEC5",
                            padding: "20px",
                            borderRadius: "25px",
                            marginTop: "10px",
                          }}
                        >
                          <FileUpload
                            fileUrl={fileUrl}
                            key={id}
                            handleDelete={(fileUrl) => {
                              handleFileDelete(fileUrl);
                            }}
                          />
                        </div>
                      </GridItem>
                    );
                  })}
              </GridContainer>
              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={(e) => {
                  e.preventDefault();
                  ReactGA.event({
                    category: "InsuranceService",
                    action: "Added a Insurance Applicant Profile",
                  });
                  createInsuranceApplicant();
                }}
              >
                Add
              </Button>
              {alert}
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
