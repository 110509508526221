import {
  STUDENT_CREATE_REQUESTED,
  STUDENT_CREATE_FAILED,
  STUDENT_CREATE_SUCCEEDED,
  STUDENT_DELETE_REQUESTED,
  STUDENT_DELETE_FAILED,
  STUDENT_DELETE_SUCCEEDED,
  STUDENT_GET_ALL_REQUESTED,
  STUDENT_GET_ALL_FAILED,
  STUDENT_GET_ALL_SUCCEEDED,
  STUDENT_GET_REQUESTED,
  STUDENT_GET_FAILED,
  STUDENT_GET_SUCCEEDED,
  STUDENT_LOG_GET_REQUESTED,
  STUDENT_LOG_GET_FAILED,
  STUDENT_LOG_GET_SUCCEEDED,
  STUDENT_UPDATE_REQUESTED,
  STUDENT_UPDATE_FAILED,
  STUDENT_UPDATE_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  studentCreateError: false,
  studentCreateFetching: false,
  studentCreateSuccess: false,

  studentDeleteError: false,
  studentDeleteFetching: false,
  studentDeleteSuccess: false,

  studentUpdateError: false,
  studentUpdateFetching: false,
  studentUpdateSuccess: false,

  studentGetError: false,
  studentGetFetching: false,
  studentGetSuccess: false,

  studentLogGetError: false,
  studentLogGetFetching: false,
  studentLogGetSuccess: false,

  studentGetAllError: false,
  studentGetAllSuccess: false,
  errorMsg: "",
};

const defaultState = {
  students: [],
  studentLog: [],
  studentDetail: {},
  studentGetAllFetching: false,
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case STUDENT_CREATE_REQUESTED:
      return {
        ...state,
        studentCreateFetching: true,
        studentCreateError: false,
        studentCreateSuccess: false,
      };
    case STUDENT_CREATE_FAILED:
      return {
        ...state,
        studentCreateFetching: false,
        studentCreateError: true,
        studentCreateSuccess: false,
        errorMsg: action.payload,
      };
    case STUDENT_CREATE_SUCCEEDED:
      return {
        ...state,
        studentDetail: action.payload,
        studentCreateFetching: false,
        studentCreateError: false,
        studentCreateSuccess: true,
      };

    case STUDENT_DELETE_REQUESTED:
      return {
        ...state,
        studentDeleteFetching: true,
        studentDeleteError: false,
        studentDeleteSuccess: false,
      };
    case STUDENT_DELETE_FAILED:
      return {
        ...state,
        studentDeleteFetching: false,
        studentDeleteError: true,
        studentDeleteSuccess: false,
        errorMsg: action.payload,
      };
    case STUDENT_DELETE_SUCCEEDED:
      return {
        ...state,
        studentDeleteFetching: false,
        studentDeleteError: false,
        studentDeleteSuccess: true,
      };

    case STUDENT_UPDATE_REQUESTED:
      return {
        ...state,
        studentUpdateFetching: true,
        studentUpdateError: false,
        studentUpdateSuccess: false,
      };
    case STUDENT_UPDATE_FAILED:
      return {
        ...state,
        studentUpdateFetching: false,
        studentUpdateError: true,
        studentUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case STUDENT_UPDATE_SUCCEEDED:
      return {
        ...state,
        studentDetail: action.payload,
        studentUpdateFetching: false,
        studentUpdateError: false,
        studentUpdateSuccess: true,
      };

    case STUDENT_GET_REQUESTED:
      return {
        ...state,
        studentGetFetching: true,
        studentGetError: false,
      };
    case STUDENT_GET_FAILED:
      return {
        ...state,
        studentGetFetching: false,
        studentGetError: true,
      };
    case STUDENT_GET_SUCCEEDED:
      return {
        ...state,
        studentDetail: action.payload,
        studentGetFetching: false,
        studentGetError: false,
      };

    case STUDENT_LOG_GET_REQUESTED:
      return {
        ...state,
        studentLogGetFetching: true,
        studentLogGetError: false,
      };
    case STUDENT_LOG_GET_FAILED:
      return {
        ...state,
        studentLogGetFetching: false,
        studentLogGetError: true,
      };
    case STUDENT_LOG_GET_SUCCEEDED:
      return {
        ...state,
        studentLog: action.payload,
        studentLogGetFetching: false,
        studentLogGetError: false,
      };

    case STUDENT_GET_ALL_REQUESTED:
      return {
        ...state,
        studentGetAllFetching: true,
        studentGetAllError: false,
        studentGetAllSuccess: false,
      };
    case STUDENT_GET_ALL_FAILED:
      return {
        ...state,
        studentGetAllFetching: false,
        studentGetAllError: true,
        studentGetAllSuccess: false,
      };
    case STUDENT_GET_ALL_SUCCEEDED:
      return {
        ...state,
        students: action.payload,
        studentGetAllFetching: false,
        studentGetAllError: false,
        studentGetAllSuccess: true,
      };
    default:
      return state;
  }
}
