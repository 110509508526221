import React, { useEffect, useState } from "react";
import moment from "moment";
import { default as ReactSelect } from "react-select";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ConfirmationAlert from "components/Alert/ConfirmationAlert.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  getDropDown,
  isValidVisaApplicantInputs,
  isDuplicateApplicant,
  getAnzsco,
  getOccupation,
  labelStyleFunc,
} from "../../../scripts/util";
import {
  CLEAR_ERROR,
  VISA_APPLICANT_GET_ALL_REQUESTED,
  VISA_APPLICANT_CREATE_REQUESTED,
} from "../../../redux/actions";
import SnackbarContent from "../../../components/Snackbar/SnackbarContent";
import ReactGA from "react-ga4";
import {
  DEFAULT_DATE_FORMAT,
  GENERAL_ROLE,
  nominationStatus,
  occupationCodeOption,
  useGetOccupationOptions,
  useGetVisaTypeOptions,
  TINY_INIT,
} from "../../../scripts/const";
import CustomLinearProgress from "../../../components/CustomLinearProgress/CustomLinearProgress";
import { Editor } from "@tinymce/tinymce-react";
import DropDownWithSearch, {
  customReactSelectStyles,
} from "../../../components/DropDownWithSearch/DropDownWithSearch";
import FileUpload from "../../../components/CustomUpload/FileUpload";

const useStyles = makeStyles(styles);

export default function AddVisa(props) {
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  const occupationOption = useGetOccupationOptions();
  const visaTypeOption = useGetVisaTypeOptions();

  const isGeneralUser = GENERAL_ROLE.includes(currentRoleId);
  const [alert, setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const submit = () => {
    confirmVisaApplicant();
  };
  const selectedEvent = () => {
    setAlert(<ConfirmationAlert cancel={hideAlert} submit={submit} />);
  };

  const [validationErrorMsg, setValidationErrorMsg] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [visaApplicant, setVisaApplicant] = React.useState({});

  const handleFileUpload = (fileUrl) => {
    if (!visaApplicant.files) {
      setVisaApplicant({
        ...visaApplicant,
        files: [fileUrl],
      });
    } else {
      setVisaApplicant({
        ...visaApplicant,
        files: [...visaApplicant.files, fileUrl],
      });
    }
  };

  const handleFileDelete = (fileUrl) => {
    setVisaApplicant({
      ...visaApplicant,
      files: visaApplicant.files.filter((f) => f != fileUrl),
    });
  };

  const [errMessage, setErrorMsg] = React.useState(
    "Something went wrong. Please try again."
  );
  const errMsg = useSelector((state) => state.visaApplicant.errorMsg);
  useEffect(() => {
    setErrorMsg(errMsg);
  }, [errMsg]);

  const handleSelect = (e) => {
    setVisaApplicant({
      ...visaApplicant,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditorChange = (content) => {
    setVisaApplicant({
      ...visaApplicant,
      remarks: content,
    });
  };

  const handleDatePicker = (date, name) => {
    if (moment.isMoment(date)) {
      if (name === "requestedDate") {
        setVisaApplicant({
          ...visaApplicant,
          [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
          dueDate: date
            .clone()
            .add(27, "days")
            .format(DEFAULT_DATE_FORMAT)
            .toString(),
        });
      } else if (
        name === "statusDate" &&
        visaApplicant.status === "Nomination Lodged"
      ) {
        setVisaApplicant({
          ...visaApplicant,
          [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
          nominationLodged: date.format(DEFAULT_DATE_FORMAT).toString(),
        });
      } else {
        setVisaApplicant({
          ...visaApplicant,
          [name]: date.format(DEFAULT_DATE_FORMAT).toString(),
        });
      }
    } else {
      setVisaApplicant({
        ...visaApplicant,
        [name]: date,
      });
    }
  };

  const dispatch = useDispatch();

  const saveError = useSelector(
    (state) => state.visaApplicant.visaApplicantCreateError
  );
  const saveSuccess = useSelector(
    (state) => state.visaApplicant.visaApplicantCreateSuccess
  );
  const saveProgress = useSelector(
    (state) => state.visaApplicant.visaApplicantCreateFetching
  );

  function createVisaApplicant() {
    const errors = isValidVisaApplicantInputs(visaApplicant);
    if (errors.length > 0) {
      setValidationErrorMsg(errors);
      dispatch({ type: CLEAR_ERROR });
    } else if (isDuplicateApplicant(visaApplicant, props.allVisaApplicants)) {
      selectedEvent();
    } else {
      confirmVisaApplicant();
    }
  }

  function confirmVisaApplicant() {
    setValidationErrorMsg("");
    dispatch({
      type: VISA_APPLICANT_CREATE_REQUESTED,
      payload: visaApplicant,
    });
  }

  function clearErrors() {
    setValidationErrorMsg("");
  }

  useEffect(() => {
    setModalOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (!modalOpen) {
      clearErrors();
      // setStudent({});
    }
  }, [modalOpen]);

  useEffect(() => {
    setValidationErrorMsg("");
    dispatch({ type: CLEAR_ERROR });
  }, []);

  useEffect(() => {
    setError(saveError);
  }, [saveError]);

  useEffect(() => {
    setSuccess(saveSuccess);
  }, [saveSuccess]);

  useEffect(() => {
    if (saveSuccess === true) {
      setTimeout(() => {
        props.handleSuccess(false);
      }, 700);
      dispatch({ type: VISA_APPLICANT_GET_ALL_REQUESTED });
      setTimeout(() => {
        setVisaApplicant({});
        dispatch({ type: CLEAR_ERROR });
      }, 701);
    }
  }, [saveSuccess]);

  const classes = useStyles();
  const [selectedPlace, setSelectedPlace] = useState(null);

  const handlePlaceSelected = (place) => {
    setSelectedPlace({
      name: place.display_name,
      lat: place.lat,
      lon: place.lon,
    });
  };
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {validationErrorMsg && (
                    <SnackbarContent
                      message={validationErrorMsg}
                      color="warning"
                    />
                  )}
                  {saveProgress && <CustomLinearProgress color="primary" />}
                  {error && (
                    <SnackbarContent message={errMessage} color="warning" />
                  )}
                  {success && (
                    <SnackbarContent
                      message="Added the visa applicant"
                      color="success"
                    />
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email address *"
                    id="visa-add-email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: visaApplicant.email || "",
                      onChange: handleSelect,
                      name: "email",
                      type: "email",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Phone Number *"
                    id="visa-add-phone"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: visaApplicant.phone || "",
                      onChange: handleSelect,
                      name: "phone",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="First Name *"
                    id="visa-add-firstName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: visaApplicant.firstName || "",
                      onChange: handleSelect,
                      name: "firstName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Middle Name"
                    id="visa-add-middleName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: visaApplicant.middleName || "",
                      onChange: handleSelect,
                      name: "middleName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Last Name *"
                    id="visa-add-lastName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: visaApplicant.lastName || "",
                      onChange: handleSelect,
                      name: "lastName",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Date of Birth
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.dob || ""}
                      onChange={(moment) => handleDatePicker(moment, "dob")}
                      inputProps={{
                        name: "dob",
                        id: "add-visa-dob-date",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Passport No"
                    id="visa-add-passport"
                    type="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: visaApplicant.passport,
                      onChange: handleSelect,
                      name: "passport",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Issue Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.issueDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "issueDate")
                      }
                      inputProps={{
                        name: "issueDate",
                        id: "visa-add-issueDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Expiry Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.expiryDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "expiryDate")
                      }
                      inputProps={{
                        name: "expiryDate",
                        id: "visa-add-expiryDate",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(visaApplicant.currentVisa)}
                    >
                      Current Visa
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        visaApplicant.currentVisa
                          ? {
                              label: visaApplicant.currentVisa,
                              value: visaApplicant.currentVisa,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={visaTypeOption}
                      name="currentVisa"
                      placeholder={"Current Visa"}
                      onChange={(options) => {
                        if (!options) {
                          setVisaApplicant({
                            ...visaApplicant,
                            currentVisa: "",
                          });
                        } else {
                          setVisaApplicant({
                            ...visaApplicant,
                            currentVisa: options.value,
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Visa Expiry Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.endDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "visaExpiry")
                      }
                      inputProps={{
                        name: "visaExpiry",
                        id: "visaExpiry-add",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(visaApplicant.proposedVisa)}
                    >
                      Proposed Visa
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        visaApplicant.proposedVisa
                          ? {
                              label: visaApplicant.proposedVisa,
                              value: visaApplicant.proposedVisa,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={visaTypeOption}
                      name="proposedVisa"
                      placeholder={"Proposed Visa"}
                      onChange={(options) => {
                        if (!options) {
                          setVisaApplicant({
                            ...visaApplicant,
                            proposedVisa: "",
                          });
                        } else {
                          setVisaApplicant({
                            ...visaApplicant,
                            proposedVisa: options.value,
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(visaApplicant.occupation)}
                    >
                      Occupation
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        visaApplicant.occupation
                          ? {
                              label: visaApplicant.occupation,
                              value: visaApplicant.occupation,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={occupationOption}
                      name="occupation"
                      placeholder={"Occupation"}
                      onChange={(options) => {
                        if (!options) {
                          setVisaApplicant({
                            ...visaApplicant,
                            occupation: "",
                            anzsco: "",
                          });
                        } else {
                          setVisaApplicant({
                            ...visaApplicant,
                            occupation: options.value,
                            anzsco: getAnzsco(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(visaApplicant.anzsco)}
                    >
                      ANZSCO
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        visaApplicant.anzsco
                          ? {
                              label: visaApplicant.anzsco,
                              value: visaApplicant.anzsco,
                            }
                          : ""
                      }
                      options={occupationCodeOption}
                      styles={customReactSelectStyles}
                      name="anzsco"
                      placeholder={"ANZSCO"}
                      onChange={(options) => {
                        if (!options) {
                          setVisaApplicant({
                            ...visaApplicant,
                            anzsco: "",
                            occupation: "",
                          });
                        } else {
                          setVisaApplicant({
                            ...visaApplicant,
                            anzsco: options.value,
                            occupation: getOccupation(options.value),
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Invoice Number"
                    id="add-visa-invoiceNumber"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: visaApplicant.invoiceNumber || "",
                      onChange: handleSelect,
                      name: "invoiceNumber",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Payment"
                    id="add-visa-payment"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: visaApplicant.payment || "",
                      onChange: handleSelect,
                      name: "payment",
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <DropDownWithSearch
                    optionsList={getDropDown("payment-status")}
                    selectedOption={visaApplicant.paymentStatus || ""}
                    setValue={setVisaApplicant}
                    currentState={visaApplicant}
                    name={"paymentStatus"}
                    label={"Payment Status"}
                  />
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <DropDownWithSearch
                    optionsList={getDropDown("visa-csa-status")}
                    selectedOption={visaApplicant.csaStatus || ""}
                    setValue={setVisaApplicant}
                    currentState={visaApplicant}
                    name={"csaStatus"}
                    label={"CSA Status"}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <DropDownWithSearch
                    optionsList={getDropDown("visa-status")}
                    selectedOption={visaApplicant.status || ""}
                    setValue={setVisaApplicant}
                    currentState={visaApplicant}
                    name={"status"}
                    label={"Status *"}
                  />
                </GridItem>
                {visaApplicant.status &&
                  [
                    "Nomination Lodged",
                    "Nomination Approved",
                    "Nomination Refused",
                    "Visa Applied",
                    "Info Requested",
                    "Visa Granted",
                    "Visa Rejected",
                    "Withdrawn",
                  ].includes(visaApplicant.status) && (
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel className={classes.label}>
                        {visaApplicant.status} Date *
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          closeOnSelect
                          timeFormat={false}
                          dateFormat={DEFAULT_DATE_FORMAT}
                          value={visaApplicant.statusDate || ""}
                          onChange={(moment) =>
                            handleDatePicker(moment, "statusDate")
                          }
                          inputProps={{
                            name: "statusDate",
                            id: "add-visa-statusDate",
                            placeholder: DEFAULT_DATE_FORMAT,
                          }}
                        />
                      </FormControl>
                    </GridItem>
                  )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Nomination Lodged
                  </InputLabel>
                  <br />
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.nominationLodged || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "nominationLodged")
                      }
                      inputProps={{
                        name: "nominationLodged",
                        id: "add-visa-nominationLodged",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InputLabel className={classes.label}>
                    Nomination Decision
                  </InputLabel>
                  <br />
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.nominationDecision || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "nominationDecision")
                      }
                      inputProps={{
                        name: "nominationDecision",
                        id: "add-visa-nominationDecision",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <div
                      className={classes.label}
                      style={labelStyleFunc(visaApplicant.nominationStatus)}
                    >
                      Nomination Status
                    </div>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable
                      isSearchable
                      value={
                        visaApplicant.nominationStatus
                          ? {
                              label: visaApplicant.nominationStatus,
                              value: visaApplicant.nominationStatus,
                            }
                          : ""
                      }
                      styles={customReactSelectStyles}
                      options={nominationStatus}
                      name="nominationStatus"
                      placeholder={"Nomination Status"}
                      onChange={(options) => {
                        if (!options) {
                          setVisaApplicant({
                            ...visaApplicant,
                            nominationStatus: "",
                          });
                        } else {
                          setVisaApplicant({
                            ...visaApplicant,
                            nominationStatus: options.value,
                          });
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Visa Submitted Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      autoOk
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.visaSubmitted || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "visaSubmitted")
                      }
                      inputProps={{
                        name: "visaSubmitted",
                        id: "visaSubmitted-add3",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Visa Grant Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.visaGranted || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "visaGranted")
                      }
                      inputProps={{
                        name: "visaGranted",
                        id: "visaGranted-add4",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>
                    Requested Date
                  </InputLabel>
                  <br />
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Datetime
                      closeOnSelect
                      autoOk
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.requestedDate || ""}
                      onChange={(moment) =>
                        handleDatePicker(moment, "requestedDate")
                      }
                      inputProps={{
                        name: "requestedDate",
                        id: "requestedDate-add3",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel className={classes.label}>Due Date</InputLabel>
                  <br />
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Datetime
                      closeOnSelect
                      timeFormat={false}
                      dateFormat={DEFAULT_DATE_FORMAT}
                      value={visaApplicant.dueDate || ""}
                      onChange={(moment) => handleDatePicker(moment, "dueDate")}
                      inputProps={{
                        name: "dueDate",
                        id: "dueDate-add4",
                        placeholder: DEFAULT_DATE_FORMAT,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <br></br>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("country")}
                    selectedOption={visaApplicant.country || ""}
                    setValue={setVisaApplicant}
                    currentState={visaApplicant}
                    name={"country"}
                    label={"Country"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("location")}
                    selectedOption={visaApplicant.location || ""}
                    setValue={setVisaApplicant}
                    currentState={visaApplicant}
                    name={"location"}
                    label={"Location"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <DropDownWithSearch
                    optionsList={getDropDown("visa-state")}
                    selectedOption={visaApplicant.state || ""}
                    setValue={setVisaApplicant}
                    currentState={visaApplicant}
                    name={"state"}
                    label={"State"}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allSources && (
                        <DropDownWithSearch
                          dbOptions={props.allSources}
                          selectedOption={visaApplicant.sourceId || ""}
                          setValue={setVisaApplicant}
                          currentState={visaApplicant}
                          name={"sourceId"}
                          label={"Source"}
                        />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {props.allUsers && (
                        <DropDownWithSearch
                          dbOptions={props.allUsers}
                          dbLabel={"firstName"}
                          selectedOption={visaApplicant.userId || ""}
                          setValue={setVisaApplicant}
                          currentState={visaApplicant}
                          name={"userId"}
                          label={"Assigned To"}
                        />
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Note:</h4>
                  <Editor
                    licenseKey={"gpl"}
                    tinymceScriptSrc={
                      "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                    }
                    value={visaApplicant.remarks || ""}
                    init={TINY_INIT}
                    onEditorChange={handleEditorChange}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem>
                  <div>
                    <h4>Documents:</h4>
                    <FileUpload
                      autoUpload
                      uploadOnly
                      label={"Upload new file"}
                      type={"visa"}
                      setFileUrl={(fileUrl) => handleFileUpload(fileUrl)}
                    />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {visaApplicant.files &&
                  visaApplicant.files.length > 0 &&
                  visaApplicant.files.map((fileUrl, id) => {
                    return (
                      <GridItem key={id}>
                        <div
                          style={{
                            flexDirection: "column",
                            display: "flex",
                            backgroundColor: "#B0BEC5",
                            padding: "20px",
                            borderRadius: "25px",
                            marginTop: "10px",
                          }}
                        >
                          <FileUpload
                            fileUrl={fileUrl}
                            key={id}
                            handleDelete={(fileUrl) => {
                              handleFileDelete(fileUrl);
                            }}
                          />
                        </div>
                      </GridItem>
                    );
                  })}
              </GridContainer>
              <Button
                color="primary"
                className={classes.moveToRight}
                onClick={(e) => {
                  ReactGA.event({
                    category: "VisaService",
                    action: "Added a Visa Applicant profile",
                  });
                  e.preventDefault();
                  createVisaApplicant();
                }}
              >
                Add
              </Button>
              {alert}
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
