import {
  CLEAR_ERROR,
  CLOCK_LOGIN_REQUESTED,
  CLOCK_LOGIN_FAILED,
  CLOCK_LOGIN_SUCCEEDED,
  CLOCK_GET_REQUESTED,
  CLOCK_GET_FAILED,
  CLOCK_GET_SUCCEEDED,
  CLOCK_IN_REQUESTED,
  CLOCK_IN_FAILED,
  CLOCK_IN_SUCCEEDED,
  CLOCK_OUT_REQUESTED,
  CLOCK_OUT_FAILED,
  CLOCK_OUT_SUCCEEDED,
  CLOCK_BREAK_START_REQUESTED,
  CLOCK_BREAK_START_FAILED,
  CLOCK_BREAK_START_SUCCEEDED,
  CLOCK_BREAK_END_REQUESTED,
  CLOCK_BREAK_END_FAILED,
  CLOCK_BREAK_END_SUCCEEDED,
  CLOCK_IN_UPDATE_FAILED,
  CLOCK_IN_UPDATE_SUCCEEDED,
  CLOCK_IN_UPDATE_REQUESTED,
} from "../actions";

const defaultError = {
  clockGetError: false,
  clockGetFetching: false,
  clockGetSuccess: false,

  clockInError: false,
  clockInFetching: false,
  clockInSuccess: false,

  clockOutError: false,
  clockOutFetching: false,
  clockOutSuccess: false,

  breakStartError: false,
  breakStartFetching: false,
  breakStartSuccess: false,

  breakEndError: false,
  breakEndFetching: false,
  breakEndSuccess: false,

  clockLoginError: false,
  clockLoginFetching: false,
  clockLoginSuccess: false,

  clockInUpdateError: false,
  clockInUpdateFetching: false,
  clockInUpdateSuccess: false,

  errorMsg: "",
};

const defaultState = {
  clockInAuth: null,
  clockInData: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultState,
      };
    case CLOCK_GET_REQUESTED:
      return {
        ...state,
        errorMsg: "",
        clockGetFetching: true,
        clockGetError: false,
        clockGetSuccess: false,
      };
    case CLOCK_GET_FAILED:
      return {
        ...state,
        clockGetFetching: false,
        clockGetError: true,
        clockGetSuccess: false,
        errorMsg: action.payload,
      };
    case CLOCK_GET_SUCCEEDED:
      return {
        ...state,
        errorMsg: "",
        clockGetFetching: false,
        clockGetError: false,
        clockGetSuccess: true,
        clockInData: action.payload,
      };
    case CLOCK_LOGIN_REQUESTED:
      return {
        ...state,
        errorMsg: "",
        clockLoginFetching: true,
        clockLoginError: false,
        clockLoginSuccess: false,
      };
    case CLOCK_LOGIN_FAILED:
      return {
        ...state,
        clockLoginFetching: false,
        clockLoginError: true,
        clockLoginSuccess: false,
        errorMsg: action.payload,
      };
    case CLOCK_LOGIN_SUCCEEDED:
      return {
        ...state,
        errorMsg: "",
        clockLoginFetching: false,
        clockLoginError: false,
        clockLoginSuccess: true,
        clockInAuth: action.payload,
      };

    case CLOCK_IN_REQUESTED:
      return {
        ...state,
        clockInFetching: true,
        clockInError: false,
        clockInSuccess: false,
        errorMsg: "",
      };
    case CLOCK_IN_FAILED:
      return {
        ...state,
        clockInFetching: false,
        clockInError: true,
        clockInSuccess: false,
        errorMsg: action.payload,
      };
    case CLOCK_IN_SUCCEEDED:
      return {
        ...state,
        clockInFetching: false,
        clockInError: false,
        clockInSuccess: true,
        errorMsg: "",
      };

    case CLOCK_IN_UPDATE_REQUESTED:
      return {
        ...state,
        clockInUpdateFetching: true,
        clockInUpdateError: false,
        clockInUpdateSuccess: false,
        errorMsg: "",
      };
    case CLOCK_IN_UPDATE_FAILED:
      return {
        ...state,
        clockInUpdateFetching: false,
        clockInUpdateError: true,
        clockInUpdateSuccess: false,
        errorMsg: action.payload,
      };
    case CLOCK_IN_UPDATE_SUCCEEDED:
      return {
        ...state,
        clockInUpdateFetching: false,
        clockInUpdateError: false,
        clockInUpdateSuccess: true,
        errorMsg: "",
      };

    case CLOCK_OUT_REQUESTED:
      return {
        ...state,
        clockOutFetching: true,
        clockOutError: false,
        clockOutSuccess: false,
        errorMsg: "",
      };
    case CLOCK_OUT_FAILED:
      return {
        ...state,
        clockOutFetching: false,
        clockOutError: true,
        clockOutSuccess: false,
        errorMsg: action.payload,
      };
    case CLOCK_OUT_SUCCEEDED:
      return {
        ...state,
        clockOutFetching: false,
        clockOutError: false,
        clockOutSuccess: true,
        errorMsg: "",
      };

    case CLOCK_BREAK_START_REQUESTED:
      return {
        ...state,
        breakStartFetching: true,
        breakStartError: false,
        breakStartSuccess: false,
        errorMsg: "",
      };
    case CLOCK_BREAK_START_FAILED:
      return {
        ...state,
        breakStartFetching: false,
        breakStartError: true,
        breakStartSuccess: false,
        errorMsg: action.payload,
      };
    case CLOCK_BREAK_START_SUCCEEDED:
      return {
        ...state,
        breakStartFetching: false,
        breakStartError: false,
        breakStartSuccess: true,
        errorMsg: "",
      };

    case CLOCK_BREAK_END_REQUESTED:
      return {
        ...state,
        breakEndFetching: true,
        breakEndError: false,
        breakEndSuccess: false,
        errorMsg: "",
      };
    case CLOCK_BREAK_END_FAILED:
      return {
        ...state,
        breakEndFetching: false,
        breakEndError: true,
        breakEndSuccess: false,
        errorMsg: action.payload,
      };
    case CLOCK_BREAK_END_SUCCEEDED:
      return {
        ...state,
        breakEndFetching: false,
        breakEndError: false,
        breakEndSuccess: true,
        errorMsg: "",
      };
    default:
      return state;
  }
}
