import { all } from "redux-saga/effects";
import { watchUser } from "./User";
import { watchAuth } from "./Login";
import { watchLead } from "./Lead";
import { watchStudent } from "./Student";
import { watchVisaApplicant } from "./VisaApplicant";
import { watchVisa } from "./Visa";
import { watchOccupation } from "./Occupation";
import { watchInsuranceApplicant } from "./InsuranceApplicant";
import { watchSkillAssessment } from "./SkillAssessment";
import { watchUniversity } from "./University";
import { watchCourse } from "./Course";
import { watchSource } from "./Source";
import { watchPass } from "./Password";
import { watchSetting } from "./Setting";
import { watchSmsEmail } from "./SmsEmail";
import { watchPublicLead } from "./Public";
import { watchTodo } from "./ToDo";
import { watchAppointment } from "./Appointment";
import { watchCheckin } from "./Checkin";
import { watchReport } from "./Report";
import { watchAnalytics } from "./Analytics";
import { watchClockIn } from "./ClockIn";
import { watchAgreement } from "./Agreement";
import { watchImmigration } from "./Immigration";
import { watchLeave } from "./Leave";
import { watchStats } from "./Stats";
import { watchFacePhoto } from "./FacePhoto";
import { watchAnnouncement } from "./Announcement";

export function* watcherSaga() {
  yield all([
    watchUser(),
    watchAuth(),
    watchLead(),
    watchVisa(),
    watchOccupation(),
    watchStudent(),
    watchVisaApplicant(),
    watchInsuranceApplicant(),
    watchSkillAssessment(),
    watchUniversity(),
    watchCourse(),
    watchSource(),
    watchPass(),
    watchSetting(),
    watchSmsEmail(),
    watchPublicLead(),
    watchTodo(),
    watchAppointment(),
    watchCheckin(),
    watchReport(),
    watchAnalytics(),
    watchClockIn(),
    watchAgreement(),
    watchImmigration(),
    watchLeave(),
    watchStats(),
    watchFacePhoto(),
    watchAnnouncement(),
  ]);
}
