import {
  CLEAR_ERROR,
  FACE_PHOTO_CREATE_FAILED,
  FACE_PHOTO_CREATE_SUCCEEDED,
  FACE_PHOTO_GET_FAILED,
  FACE_PHOTO_GET_SUCCEEDED,
  CREATE_FACE_PHOTO_REQUESTED,
  GET_FACE_PHOTO_REQUESTED,
} from "../actions";

const defaultError = {
  facePhotoCreateError: false,
  facePhotoCreateFetching: false,
  facePhotoCreateSuccess: false,

  facePhotoGetAllError: false,
  facePhotoGetAllFetching: false,
  facePhotoGetAllSuccess: false,
};

const defaultState = {
  facePhoto: [],
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case CREATE_FACE_PHOTO_REQUESTED:
      return {
        ...state,
        facePhotoCreateFetching: true,
        facePhotoCreateError: false,
        facePhotoCreateSuccess: false,
      };
    case FACE_PHOTO_CREATE_FAILED:
      return {
        ...state,
        facePhotoCreateFetching: false,
        facePhotoCreateError: true,
        facePhotoCreateSuccess: false,
      };
    case FACE_PHOTO_CREATE_SUCCEEDED:
      return {
        ...state,
        facePhotoDetail: action.payload,
        facePhotoCreateFetching: false,
        facePhotoCreateError: false,
        facePhotoCreateSuccess: true,
      };

    case GET_FACE_PHOTO_REQUESTED:
      return {
        ...state,
        facePhotoGetAllFetching: true,
        facePhotoGetAllError: false,
      };
    case FACE_PHOTO_GET_FAILED:
      return {
        ...state,
        facePhotoGetAllFetching: false,
        facePhotoGetAllError: true,
      };
    case FACE_PHOTO_GET_SUCCEEDED:
      return {
        ...state,
        facePhoto: action.payload,
        facePhotoGetAllFetching: false,
        facePhotoGetAllError: false,
      };
    default:
      return state;
  }
}
