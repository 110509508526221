import { call, put, takeLatest, select } from "redux-saga/effects";
import remoteResource from "../../lib/RemoteResource";

import {
  TODO_CREATE_REQUESTED,
  TODO_CREATE_FAILED,
  TODO_CREATE_SUCCEEDED,
  TODO_GET_ALL_REQUESTED,
  TODO_GET_ALL_FAILED,
  TODO_GET_ALL_SUCCEEDED,
  TODO_GET_REQUESTED,
  TODO_GET_FAILED,
  TODO_GET_SUCCEEDED,
  TODO_UPDATE_REQUESTED,
  TODO_UPDATE_FAILED,
  TODO_UPDATE_SUCCEEDED,
  TODO_DELETE_REQUESTED,
  TODO_DELETE_FAILED,
  TODO_DELETE_SUCCEEDED,
  TODO_COUNT_GET_SUCCEEDED,
  TODO_COUNT_GET_FAILED,
  TODO_COUNT_GET_REQUESTED,
  TODO_UPDATE_ALL_REQUESTED,
  TODO_UPDATE_ALL_SUCCEEDED,
  TODO_UPDATE_ALL_FAILED,
} from "../actions";
import { getToken } from "./selectors";

function* fetchTodo(action) {
  try {
    const id = action.payload;
    const token = yield select(getToken);
    const todo = yield call(remoteResource.getTodo, id, token);
    yield put({
      type: TODO_GET_SUCCEEDED,
      payload: todo.data,
    });
  } catch (e) {
    yield put({ type: TODO_GET_FAILED, payload: e.message });
  }
}

function* fetchTodoCount() {
  try {
    const token = yield select(getToken);
    const todo = yield call(remoteResource.getTodoCount, token);
    yield put({
      type: TODO_COUNT_GET_SUCCEEDED,
      payload: todo.data,
    });
  } catch (e) {
    yield put({ type: TODO_COUNT_GET_FAILED, payload: e.message });
  }
}

function* fetchAllTodos() {
  try {
    const token = yield select(getToken);
    const todos = yield call(remoteResource.getAllTodos, token);
    yield put({
      type: TODO_GET_ALL_SUCCEEDED,
      payload: todos.data,
    });
  } catch (e) {
    yield put({ type: TODO_GET_ALL_FAILED, payload: e.message });
  }
}

function* createTodo(action) {
  try {
    const { payload } = action;
    const token = yield select(getToken);
    const todo = yield call(remoteResource.createTodo, payload, token);
    yield put({
      type: TODO_CREATE_SUCCEEDED,
      payload: todo.data,
    });
  } catch (e) {
    yield put({ type: TODO_CREATE_FAILED, payload: e.response.data.message });
  }
}

function* updateTodo(action) {
  try {
    const { payload, id } = action.payload;
    const token = yield select(getToken);
    const todo = yield call(remoteResource.updateTodo, id, payload, token);
    yield put({
      type: TODO_UPDATE_SUCCEEDED,
      payload: todo.data,
    });
  } catch (e) {
    yield put({ type: TODO_UPDATE_FAILED, payload: e.response.data.message });
  }
}

function* updateAllTodos(action) {
  try {
    const { payload } = action.payload;
    const token = yield select(getToken);
    const todo = yield call(remoteResource.updateAllTodo, payload, token);
    yield put({
      type: TODO_UPDATE_ALL_SUCCEEDED,
      payload: todo.data,
    });
  } catch (e) {
    yield put({
      type: TODO_UPDATE_ALL_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* deleteTodo(action) {
  try {
    const { id } = action.payload;
    const token = yield select(getToken);
    const todo = yield call(remoteResource.deleteTodo, id, token);
    yield put({
      type: TODO_DELETE_SUCCEEDED,
      payload: todo.data,
    });
  } catch (e) {
    yield put({ type: TODO_DELETE_FAILED, payload: e.response.data.message });
  }
}

export function* watchTodo() {
  yield takeLatest(TODO_GET_REQUESTED, fetchTodo);
  yield takeLatest(TODO_COUNT_GET_REQUESTED, fetchTodoCount);
  yield takeLatest(TODO_CREATE_REQUESTED, createTodo);
  yield takeLatest(TODO_UPDATE_REQUESTED, updateTodo);
  yield takeLatest(TODO_GET_ALL_REQUESTED, fetchAllTodos);
  yield takeLatest(TODO_DELETE_REQUESTED, deleteTodo);
  yield takeLatest(TODO_UPDATE_ALL_REQUESTED, updateAllTodos);
}
