import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  FACE_PHOTO_CREATE_FAILED,
  FACE_PHOTO_CREATE_SUCCEEDED,
  CREATE_FACE_PHOTO_REQUESTED,
  GET_FACE_PHOTO_REQUESTED,
  FACE_PHOTO_GET_SUCCEEDED,
  FACE_PHOTO_GET_FAILED,
} from "../actions";
import remoteResource from "../../lib/RemoteResource";
import { getToken } from "./selectors";

function* saveFacePhoto(action) {
  const token = yield select(getToken);

  try {
    const { payload } = action;
    const facePhoto = yield call(remoteResource.saveFacePhoto, payload, token);
    yield put({
      type: FACE_PHOTO_CREATE_SUCCEEDED,
      payload: facePhoto.data,
    });
  } catch (e) {
    yield put({
      type: FACE_PHOTO_CREATE_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* getFacePhoto() {
  const token = yield select(getToken);

  try {
    const facePhoto = yield call(remoteResource.getFacePhoto, token);
    yield put({
      type: FACE_PHOTO_GET_SUCCEEDED,
      payload: facePhoto.data,
    });
  } catch (e) {
    yield put({
      type: FACE_PHOTO_GET_FAILED,
      payload: e.response.data.message,
    });
  }
}

export function* watchFacePhoto() {
  yield takeLatest(CREATE_FACE_PHOTO_REQUESTED, saveFacePhoto);
  yield takeLatest(GET_FACE_PHOTO_REQUESTED, getFacePhoto);
}
