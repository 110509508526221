import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {
  SEND_BIRTHDAY_EMAIL,
  SEND_BIRTHDAY_SMS,
  SEND_FEE_DUE_EMAIL,
  SEND_FEE_DUE_SMS,
  SEND_VISA_EXPIRY_EMAIL,
  SEND_VISA_EXPIRY_SMS,
  SEND_TAX_EMAIL,
  SEND_TAX_SMS,
  CUSTOM_SMS,
  CUSTOM_EMAIL,
  EMAIL_ACTION,
  SMS_ACTION,
  EMAIL_TEMPLATE,
  SMS_TEMPLATE,
  TINY_INIT,
} from "scripts/const";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import Close from "@material-ui/icons/Close";
import GridItem from "../Grid/GridItem";
import CustomInput from "../CustomInput/CustomInput";
import GridContainer from "../Grid/GridContainer";
import SnackbarContent from "../Snackbar/SnackbarContent";
import CustomLinearProgress from "../CustomLinearProgress/CustomLinearProgress";
import {
  CUSTOM_SMS_REQUESTED,
  CUSTOM_EMAIL_REQUESTED,
  TEMPLATE_EMAIL_REQUESTED,
  TEMPLATE_SMS_REQUESTED,
  CLEAR_ERROR,
} from "../../redux/actions";
import { Editor } from "@tinymce/tinymce-react";

const useStyles = makeStyles(styles);

function getIdFromSelection(sel) {
  return sel.map((s) => parseInt(s.split("-")[1]));
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function getSelectedData(data, selection) {
  const ids = getIdFromSelection(selection);
  const selectedData = data.filter((d) => ids.includes(d.id));
  return selectedData;
}

export default function SmsEmailAction({ option, data, selection }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [action, setAction] = React.useState("");
  const [label, setLabel] = React.useState("Send SMS/EMAIL");
  const [smsModal, setSmsModal] = React.useState(false);
  const [emailModal, setEmailModal] = React.useState(false);
  const [message, setMessage] = React.useState({
    sms: "",
    subject: "",
    emailBody: "",
  });
  const [errorMessage, setErrorMessage] = React.useState("");
  const [customSmsError, setCustomSmsError] = React.useState(false);
  const [customSmsSuccess, setCustomSmsSuccess] = React.useState(false);
  const [customSmsProgress, setCustomSmsProgress] = React.useState(false);
  const [customEmailError, setCustomEmailError] = React.useState(false);
  const [customEmailSuccess, setCustomEmailSuccess] = React.useState(false);
  const [customEmailProgress, setCustomEmailProgress] = React.useState(false);

  const [tEProgress, setTEmailProgress] = React.useState(false);
  const [tESuccess, setTEmailSuccess] = React.useState(false);
  const [tEError, setTEmailError] = React.useState(false);

  const [tSProgress, setTSmsProgress] = React.useState(false);
  const [tSSuccess, setTSmsSuccess] = React.useState(false);
  const [tSError, setTSmsError] = React.useState(false);

  const [smsCharLength, setSMSCharLength] = React.useState(0);

  const tSmsError = useSelector((state) => state.smsEmail.templateSmsError);
  const tSmsProgress = useSelector(
    (state) => state.smsEmail.templateSmsFetching
  );
  const tSmsSuccess = useSelector((state) => state.smsEmail.templateSmsSuccess);

  const tEmailError = useSelector((state) => state.smsEmail.templateEmailError);
  const tEmailProgress = useSelector(
    (state) => state.smsEmail.templateEmailFetching
  );
  const tEmailSuccess = useSelector(
    (state) => state.smsEmail.templateEmailSuccess
  );

  const cSmsError = useSelector((state) => state.smsEmail.customSmsError);
  const cSmsProgress = useSelector((state) => state.smsEmail.customSmsFetching);
  const cSmsSuccess = useSelector((state) => state.smsEmail.customSmsSuccess);

  const cEmailError = useSelector((state) => state.smsEmail.customEmailError);
  const cEmailProgress = useSelector(
    (state) => state.smsEmail.customEmailFetching
  );
  const cEmailSuccess = useSelector(
    (state) => state.smsEmail.customEmailSuccess
  );

  useEffect(() => {
    setCustomSmsError(cSmsError);
  }, [cSmsError]);

  useEffect(() => {
    setCustomSmsSuccess(cSmsSuccess);
  }, [cSmsSuccess]);

  useEffect(() => {
    setCustomSmsProgress(cSmsProgress);
  }, [cSmsProgress]);

  useEffect(() => {
    setCustomEmailError(cEmailError);
  }, [cEmailError]);

  useEffect(() => {
    setCustomEmailSuccess(cEmailSuccess);
  }, [cEmailSuccess]);

  useEffect(() => {
    setCustomEmailProgress(cEmailProgress);
  }, [cEmailProgress]);

  // Template Email/ SMS
  useEffect(() => {
    setTSmsError(tSmsError);
  }, [tSmsError]);

  useEffect(() => {
    setTSmsSuccess(tSmsSuccess);
  }, [tSmsSuccess]);

  useEffect(() => {
    setTSmsProgress(tSmsProgress);
  }, [tSmsProgress]);

  useEffect(() => {
    setTEmailError(tEmailError);
  }, [tEmailError]);

  useEffect(() => {
    setTEmailSuccess(tEmailSuccess);
  }, [tEmailSuccess]);

  useEffect(() => {
    setTEmailProgress(tEmailProgress);
  }, [tEmailProgress]);

  const handleSelect = (e) => {
    setErrorMessage(false);
    let value = e.target.value;
    if (e.target.name === "sms") {
      setSMSCharLength(value.length);
    }
    setMessage({
      ...message,
      [e.target.name]: value,
    });
  };

  const handleEmailBody = (value) => {
    setMessage({
      ...message,
      emailBody: value,
    });
  };

  const clearErrors = () => {
    setErrorMessage("");
    setCustomSmsError(false);
    setCustomSmsSuccess(false);
    setCustomSmsProgress(false);
    setCustomEmailError(false);
    setCustomEmailSuccess(false);
    setCustomEmailProgress(false);
    setMessage({
      sms: "",
      subject: "",
      emailBody: "",
    });
  };

  function sendSmsEmail() {
    dispatch({
      type: CLEAR_ERROR,
    });
    const selectedData = getSelectedData(data, selection);
    if (action === CUSTOM_SMS) {
      setSmsModal(true);
    } else if (action === CUSTOM_EMAIL) {
      setEmailModal(true);
    } else if (action === SEND_BIRTHDAY_EMAIL) {
      dispatch({
        type: TEMPLATE_EMAIL_REQUESTED,
        payload: {
          users: selectedData,
          templateName: EMAIL_TEMPLATE.BIRTHDAY_EMAIL,
        },
      });
    } else if (action === SEND_BIRTHDAY_SMS) {
      dispatch({
        type: TEMPLATE_SMS_REQUESTED,
        payload: {
          users: selectedData,
          templateName: SMS_TEMPLATE.BIRTHDAY,
        },
      });
    } else if (action === SEND_FEE_DUE_EMAIL) {
      dispatch({
        type: TEMPLATE_EMAIL_REQUESTED,
        payload: {
          users: selectedData,
          templateName: EMAIL_TEMPLATE.FEE_DUE_EMAIL,
        },
      });
    } else if (action === SEND_FEE_DUE_SMS) {
      dispatch({
        type: TEMPLATE_SMS_REQUESTED,
        payload: {
          users: selectedData,
          templateName: SMS_TEMPLATE.FeeDue,
        },
      });
    } else if (action === SEND_VISA_EXPIRY_EMAIL) {
      dispatch({
        type: TEMPLATE_EMAIL_REQUESTED,
        payload: {
          users: selectedData,
          templateName: EMAIL_TEMPLATE.VISA_EXPIRY_EMAIL,
        },
      });
    } else if (action === SEND_VISA_EXPIRY_SMS) {
      dispatch({
        type: TEMPLATE_SMS_REQUESTED,
        payload: {
          users: selectedData,
          templateName: SMS_TEMPLATE.VisaExpiry,
        },
      });
    } else if (action === SEND_TAX_EMAIL) {
      dispatch({
        type: TEMPLATE_EMAIL_REQUESTED,
        payload: {
          users: selectedData,
          templateName: EMAIL_TEMPLATE.TAX_EMAIL,
        },
      });
    } else if (action === SEND_TAX_SMS) {
      dispatch({
        type: TEMPLATE_SMS_REQUESTED,
        payload: {
          users: selectedData,
          templateName: SMS_TEMPLATE.TAX,
        },
      });
    }
  }

  function sendCustomSMS() {
    const { sms } = message;
    if (!sms) {
      return setErrorMessage("Message can not be empty");
    }
    const selectedData = getSelectedData(data, selection);
    dispatch({
      type: CUSTOM_SMS_REQUESTED,
      payload: { users: selectedData, message: sms },
    });
  }

  function sendCustomEmail() {
    const { emailBody, subject } = message;
    if (!subject) {
      return setErrorMessage("Subject can not be empty");
    }
    if (!emailBody) {
      return setErrorMessage("Email body can not be empty");
    }
    const selectedData = getSelectedData(data, selection);
    dispatch({
      type: CUSTOM_EMAIL_REQUESTED,
      payload: { users: selectedData, subject, message: emailBody },
    });
  }

  const handleChange = (event) => {
    setAction(event.target.value);
    if (EMAIL_ACTION.includes(event.target.value)) {
      setLabel("Send Email");
    } else if (SMS_ACTION.includes(event.target.value)) {
      setLabel("Send SMS");
    } else if (CUSTOM_SMS === event.target.value) {
      setLabel("Create SMS");
    } else if (CUSTOM_EMAIL === event.target.value) {
      setLabel("Create Email");
    } else {
      setLabel("Send SMS/EMAIL");
    }
  };

  return (
    <>
      {option && option.length > 0 && (
        <>
          <FormControl className={classes.selectAction}>
            <InputLabel htmlFor="action">Action</InputLabel>
            <Select
              native
              value={action}
              onChange={handleChange}
              inputProps={{
                name: "action",
                id: "action",
              }}
            >
              <option aria-label="None" value="" />
              {option.includes(SEND_BIRTHDAY_EMAIL) && (
                <option value={SEND_BIRTHDAY_EMAIL}>Send Birthday Email</option>
              )}
              {option.includes(SEND_BIRTHDAY_SMS) && (
                <option value={SEND_BIRTHDAY_SMS}>Send Birthday SMS</option>
              )}
              {option.includes(SEND_FEE_DUE_EMAIL) && (
                <option value={SEND_FEE_DUE_EMAIL}>Send Fee Due Email</option>
              )}
              {option.includes(SEND_FEE_DUE_SMS) && (
                <option value={SEND_FEE_DUE_SMS}>Send Fee Due SMS</option>
              )}
              {option.includes(SEND_VISA_EXPIRY_EMAIL) && (
                <option value={SEND_VISA_EXPIRY_EMAIL}>
                  Send Visa Expiry Email
                </option>
              )}
              {option.includes(SEND_VISA_EXPIRY_SMS) && (
                <option value={SEND_VISA_EXPIRY_SMS}>
                  Send Visa Expiry SMS
                </option>
              )}
              {option.includes(SEND_TAX_EMAIL) && (
                <option value={SEND_TAX_EMAIL}>Send TAX Reminder Email</option>
              )}
              {option.includes(SEND_TAX_SMS) && (
                <option value={SEND_TAX_SMS}>Send TAX Reminder SMS</option>
              )}
              {option.includes(CUSTOM_SMS) && (
                <option value={CUSTOM_SMS}>Custom SMS</option>
              )}
              {option.includes(CUSTOM_EMAIL) && (
                <option value={CUSTOM_EMAIL}>Custom Email</option>
              )}
            </Select>
            <>
              {action && (
                <>
                  <Button
                    disabled={tEProgress || tSProgress ? true : false}
                    size={"sm"}
                    className={classes.marginLeft}
                    onClick={() => {
                      ReactGA.event({
                        category: "SMS_EMAIL",
                        action: `${action} - Clicked`,
                      });
                      sendSmsEmail();
                    }}
                    color="info"
                  >
                    {label}
                  </Button>
                </>
              )}
            </>
            <h5 className={classes.actionH5 + " " + classes.marginLeft}>
              Total: {selection.length}
            </h5>
          </FormControl>
          {(tEProgress || tSProgress) && <CircularProgress />}
          {(tEError || tSError) && <CloseIcon style={{ color: "red" }} />}
          {(tESuccess || tSSuccess) && <CheckIcon style={{ color: "green" }} />}
        </>
      )}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMedium,
        }}
        open={smsModal}
        TransitionComponent={Transition}
        onClose={() => {
          setSmsModal(false);
          clearErrors();
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setSmsModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>Send SMS</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {errorMessage && (
                <SnackbarContent message={errorMessage} color="warning" />
              )}
              {customSmsError && (
                <SnackbarContent
                  message="Something went wrong."
                  color="warning"
                />
              )}
              {customSmsSuccess && (
                <SnackbarContent message="SMS sent" color="success" />
              )}
              {customSmsProgress && <CustomLinearProgress color="primary" />}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h5 className={classes.actionH5}>
                Total Recipients: {selection.length}
              </h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Message *"
                helperText={`Total Char: ${smsCharLength}.`}
                id="sms-message"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  rows: 4,
                  value: message.sms || "",
                  onChange: handleSelect,
                  name: "sms",
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                size={"sm"}
                onClick={() => {
                  ReactGA.event({
                    category: "SMS_EMAIL",
                    action: `Send Custom SMS`,
                  });
                  sendCustomSMS();
                }}
                color="info"
              >
                Send SMS
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMedium,
        }}
        open={emailModal}
        TransitionComponent={Transition}
        onClose={() => setEmailModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setEmailModal(false);
              clearErrors();
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>Send Email</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {errorMessage && (
                <SnackbarContent message={errorMessage} color="warning" />
              )}
              {customEmailError && (
                <SnackbarContent
                  message="Something went wrong."
                  color="warning"
                />
              )}
              {customEmailSuccess && (
                <SnackbarContent message="Email Sent" color="success" />
              )}
              {customEmailProgress && <CustomLinearProgress color="primary" />}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <h5>Total Recipients: {selection.length}</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Subject *"
                id="email-subject"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  value: message.subject || "",
                  onChange: handleSelect,
                  name: "subject",
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Editor
                licenseKey={"gpl"}
                tinymceScriptSrc={
                  "https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.2/tinymce.min.js"
                }
                value={message.emailBody || ""}
                init={TINY_INIT}
                onEditorChange={handleEmailBody}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                size={"sm"}
                onClick={() => {
                  ReactGA.event({
                    category: "SMS_EMAIL",
                    action: `Send Custom Email`,
                  });
                  sendCustomEmail();
                }}
                color="info"
              >
                Send Email
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

SmsEmailAction.defaultProps = {
  option: [],
};

SmsEmailAction.propTypes = {
  option: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  selection: PropTypes.arrayOf(PropTypes.string),
};
