// src/components/LocationAutoComplete.js
import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import "./LocationAutoComplete.css"; // Import the CSS file
import CustomInput from "components/CustomInput/CustomInput";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useOutsideClick } from "./useOutsideHook";
import { formatAddressForDisplay } from "./utils";

const LocationAutoComplete = ({ onPlaceSelected, defaultValue }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const ref = useOutsideClick(() => setSuggestions([]));

  const fetchSuggestions = async (value) => {
    if (value.length > 2) {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?format=json&q=${value}&addressdetails=1&limit=5`
      );
      setSuggestions(response.data);
    } else {
      setSuggestions([]);
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 300),
    []
  );

  const handleInputChange = (event) => {
    const value = event.target ? event.target.value : event;
    setInputValue(value);
    debouncedFetchSuggestions(value);
    onPlaceSelected({ display_name: value });
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(formatAddressForDisplay(suggestion, inputValue));
    setSuggestions([]);
    onPlaceSelected(formatAddressForDisplay(suggestion, inputValue));
  };

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className="autocomplete-container" ref={ref}>
      <div className="input-wrapper">
        <CustomInput
          labelText="Address"
          id="addUser-address"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: inputValue,
            onChange: handleInputChange,
            name: "address",
          }}
        />
      </div>
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.place_id}
              onClick={() => handleSuggestionClick(suggestion)}
              className="suggestion-item"
            >
              <LocationOnIcon />
              {formatAddressForDisplay(suggestion, inputValue)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationAutoComplete;
