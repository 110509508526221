import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import { google, ics } from "calendar-link";
import moment from "moment-timezone";
import { DEFAULT_TIME_ZONE, BRANCH_LOCATION } from "../../scripts/const";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
export default function CalendarPopover({ event }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const updatedEvent = {
    title: event.title || "New Event",
    description: event.description || "",
    location: BRANCH_LOCATION,
    start: moment
      .tz(event.start, event.timezone || DEFAULT_TIME_ZONE)
      .toISOString(),
    end: moment
      .tz(event.end, event.timezone || DEFAULT_TIME_ZONE)
      .toISOString(),
  };
  function handleClickPop(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClosePop() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button siz={"small"} aria-describedby={id} onClick={handleClickPop}>
        Add to Private Calendar
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div>
          <Typography className={classes.typography}>
            <a
              href={google(updatedEvent)}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Google
            </a>
          </Typography>
          <Typography className={classes.typography}>
            <a
              href={ics(updatedEvent)}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              iCal
            </a>
          </Typography>
        </div>
      </Popover>
    </div>
  );
}
