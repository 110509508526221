import {
  CLIENT_COUNTRY_GET_REQUESTED,
  CLIENT_COUNTRY_GET_FAILED,
  CLIENT_COUNTRY_GET_SUCCEEDED,
  SKILL_ANALYTICS_GET_REQUESTED,
  SKILL_ANALYTICS_GET_FAILED,
  SKILL_ANALYTICS_GET_SUCCEEDED,
  VISA_ANALYTICS_GET_REQUESTED,
  VISA_ANALYTICS_GET_FAILED,
  VISA_ANALYTICS_GET_SUCCEEDED,
  STUDENT_ANALYTICS_GET_REQUESTED,
  STUDENT_ANALYTICS_GET_FAILED,
  STUDENT_ANALYTICS_GET_SUCCEEDED,
  CLEAR_ERROR,
} from "../actions";

const defaultError = {
  clientCountryGetError: false,
  clientCountryGetFetching: false,
  clientCountryGetSuccess: false,

  studentAnalyticsGetError: false,
  studentAnalyticsGetFetching: false,
  studentAnalyticsGetSuccess: false,

  visaAnalyticsGetError: false,
  visaAnalyticsGetFetching: false,
  visaAnalyticsGetSuccess: false,

  skillAnalyticsGetError: false,
  skillAnalyticsGetFetching: false,
  skillAnalyticsGetSuccess: false,
};

const defaultState = {
  clientCountries: {},
  skillAnalytics: {},
  visaAnalytics: {},
  studentAnalytics: {},
  ...defaultError,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CLEAR_ERROR:
      return {
        ...state,
        ...defaultError,
      };
    case CLIENT_COUNTRY_GET_REQUESTED:
      return {
        ...state,
        clientCountryGetFetching: true,
        clientCountryGetError: false,
        clientCountryGetSuccess: false,
      };
    case CLIENT_COUNTRY_GET_FAILED:
      return {
        ...state,
        clientCountryGetFetching: false,
        clientCountryGetError: true,
        clientCountryGetSuccess: false,
      };
    case CLIENT_COUNTRY_GET_SUCCEEDED:
      return {
        ...state,
        clientCountries: action.payload,
        clientCountryGetFetching: false,
        clientCountryGetError: false,
        clientCountryGetSuccess: true,
      };

    case STUDENT_ANALYTICS_GET_REQUESTED:
      return {
        ...state,
        studentAnalyticsGetFetching: true,
        studentAnalyticsGetError: false,
        studentAnalyticsGetSuccess: false,
      };
    case STUDENT_ANALYTICS_GET_FAILED:
      return {
        ...state,
        studentAnalyticsGetFetching: false,
        studentAnalyticsGetError: true,
        studentAnalyticsGetSuccess: false,
      };
    case STUDENT_ANALYTICS_GET_SUCCEEDED:
      return {
        ...state,
        studentAnalytics: action.payload,
        studentAnalyticsGetFetching: false,
        studentAnalyticsGetError: false,
        studentAnalyticsGetSuccess: true,
      };

    case VISA_ANALYTICS_GET_REQUESTED:
      return {
        ...state,
        visaAnalyticsGetFetching: true,
        visaAnalyticsGetError: false,
        visaAnalyticsGetSuccess: false,
      };
    case VISA_ANALYTICS_GET_FAILED:
      return {
        ...state,
        visaAnalyticsGetFetching: false,
        visaAnalyticsGetError: true,
        visaAnalyticsGetSuccess: false,
      };
    case VISA_ANALYTICS_GET_SUCCEEDED:
      return {
        ...state,
        visaAnalytics: action.payload,
        visaAnalyticsGetFetching: false,
        visaAnalyticsGetError: false,
        visaAnalyticsGetSuccess: true,
      };

    case SKILL_ANALYTICS_GET_REQUESTED:
      return {
        ...state,
        skillAnalyticsGetFetching: true,
        skillAnalyticsGetError: false,
        skillAnalyticsGetSuccess: false,
      };
    case SKILL_ANALYTICS_GET_FAILED:
      return {
        ...state,
        skillAnalyticsGetFetching: false,
        skillAnalyticsGetError: true,
        skillAnalyticsGetSuccess: false,
      };
    case SKILL_ANALYTICS_GET_SUCCEEDED:
      return {
        ...state,
        skillAnalytics: action.payload,
        skillAnalyticsGetFetching: false,
        skillAnalyticsGetError: false,
        skillAnalyticsGetSuccess: true,
      };
    default:
      return state;
  }
}
