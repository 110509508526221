import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export const SortableItem = ({ children, id }) => {
  const { setNodeRef, listeners, transform, transition } = useSortable({ id });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  return (
    <div ref={setNodeRef} style={styles}>
      <DragIndicatorIcon {...listeners} />

      {children}
    </div>
  );
};
