/*!
 * Template used for this project: https://www.creative-tim.com/product/material-dashboard-pro-react
 */
import React from "react";
import { createRoot } from "react-dom/client";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { store, persistor } from "./redux/store";
import history from "./history";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import PublicLayout from "layouts/Public.js";
import MigrationLayout from "layouts/Immigration.js";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import { PersistGate } from "redux-persist/integration/react";

import ReactGA from "react-ga4";
import "../src/assets/css/table.css";
import "../src/assets/css/react-datetime.css";

/*
  React Bootstrap Configuration
 */
import "bootstrap/dist/css/bootstrap.min.css";

if (!process.env.REACT_APP_GA_CODE) {
  console.log("No GA Code Found ->", process.env.REACT_APP_GA_CODE);
} else {
  ReactGA.initialize(process.env.REACT_APP_GA_CODE || "");
}
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
} else {
  // Configuring Sentry on Canberra branch only coz of quota
  if (process.env.REACT_APP_BRANCH_NAME === "Canberra") {
    Sentry.init({
      dsn: "https://78a5f4bb524b413da71eff822229e01b@o465290.ingest.sentry.io/5477711",
      integrations: [new BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
}

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
});

function isAuth() {
  const { login } = store.getState();
  if (login.authorized && login.loginUser && login.loginUser.token) {
    ReactGA.set({
      userId: `${login.loginUser.detail.id}`,
    });
    return true;
  }
  return false;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth() ? <Component {...props} /> : <Redirect to="/auth" />
    }
  />
);

const PrivateRouteForLogin = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth() ? <Redirect to="/admin" /> : <Component {...props} />
    }
  />
);

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Router history={history}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Switch>
          <Route path="/public/form" component={MigrationLayout} />
          <Route path="/public" component={PublicLayout} />
          <PrivateRouteForLogin path="/auth" component={AuthLayout} />
          <PrivateRoute path="/admin" component={AdminLayout} />
          <Redirect from="/" to="/admin/" />
        </Switch>
      </PersistGate>
    </Provider>
  </Router>
);
