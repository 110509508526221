/* eslint-disable react/display-name */
import { maxDescriptorDistance } from "globalData";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_FACE_PHOTO_REQUESTED } from "redux/actions";

import { createMatcher } from "../../faceUtil";

import ProcessFaceRecognition from "./ProcessFaceRecognition";

export default (props) => {
  const threshold = maxDescriptorDistance;

  const dispatch = useDispatch();

  const [facePhotos, setFacePhotos] = useState([]);
  const [faceMatcher, setFaceMatcher] = useState(null);

  const data = useSelector((state) => state.face.facePhoto);

  useEffect(() => {
    dispatch({ type: GET_FACE_PHOTO_REQUESTED });
  }, []);
  useEffect(() => {
    if (data) {
      // eslint-disable-next-line no-unused-expressions
      data?.matcher?.forEach((item) =>
        item?.facePhotos?.forEach((photo) =>
          setFacePhotos((prevState) => [...prevState, photo])
        )
      );
    }
  }, [data]);

  useEffect(() => {
    async function matcher() {
      //check there should be at least one matcher
      if (data && facePhotos?.length > 0) {
        console.log("******", data, facePhotos);

        const validMatcher = data?.matcher.filter(
          (m) => m.facePhotos.length > 0
        );
        const profileList = await createMatcher(validMatcher, threshold);
        console.log("profileList", profileList);
        setFaceMatcher(profileList);
      }
    }
    console.log("******", data, facePhotos);

    if (!!data) {
      matcher();
    }
  }, [data, facePhotos, threshold]);

  return (
    <div className="layout">
      <div>
        <ProcessFaceRecognition
          {...props}
          faceMatcher={faceMatcher}
          facePhotos={facePhotos}
        />
      </div>
    </div>
  );
};
