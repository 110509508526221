import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import { USER_GET_ALL_REQUESTED } from "../../../redux/actions";

import AddUser from "./AddUser";
import EditUser from "./EditUser";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  getRoleMapping,
  filterCaseInsensitive,
  getColumns,
} from "../../../scripts/util";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { connect, useSelector } from "react-redux";
import { GetApp } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import ReactGA from "react-ga4";
import { compose } from "redux";
import withTimer from "components/Timer/withTimer";
import { SUPER_ADMIN_ROLES } from "../../../scripts/const";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UsersTables = (props) => {
  const [addUserModal, setAddUserModal] = React.useState(false);
  const [editUserModal, setEditUserModal] = React.useState(false);

  const [currentUser, setCurrentUser] = React.useState({});

  const [data, setData] = React.useState([]);
  const { roleId: currentRoleId } = useSelector(
    (state) => state.login.loginUser.detail
  );
  React.useEffect(() => {
    if (SUPER_ADMIN_ROLES.includes(currentRoleId)) {
      setData(props.users);
    } else {
      const filteredUsers = props.users.filter(
        (u) => !SUPER_ADMIN_ROLES.includes(u.roleId)
      );
      setData(filteredUsers);
    }
  }, [props.users]);

  let reactTable = null;
  let csvLink = null;
  function download(event) {
    const columns = getColumns("User");
    const currentRecords = reactTable.getResolvedState().sortedData;
    var data_to_download = [];
    for (var index = 0; index < currentRecords.length; index++) {
      let record_to_download = {};
      for (var colIndex = 0; colIndex < columns.length; colIndex++) {
        record_to_download[columns[colIndex].Header] =
          currentRecords[index][columns[colIndex].accessor];
      }
      data_to_download.push(record_to_download);
    }
    setDataToDownload(data_to_download);
  }

  const [dataToDownload, setDataToDownload] = React.useState([]);
  const [isDownload, setIsDownload] = React.useState(false);

  React.useEffect(() => {
    if (dataToDownload.length > 0) {
      setIsDownload(true);
    }
  }, [dataToDownload]);

  React.useEffect(() => {
    if (isDownload) {
      csvLink.link.click();
      setIsDownload(false);
    }
  }, [isDownload]);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <Button
              onClick={() => {
                setAddUserModal(true);
              }}
              color="primary"
            >
              Add User
            </Button>
          </CardHeader>
          <CardBody>
            <div>
              <Button
                title="Download CSV"
                justIcon
                color="info"
                onClick={download}
              >
                <GetApp />
              </Button>
            </div>
            <CSVLink
              data={dataToDownload}
              filename="users.csv"
              className="hidden"
              ref={(r) => (csvLink = r)}
              target="_blank"
            />
            <ReactTable
              ref={(r) => (reactTable = r)}
              pageSizeOptions={[10, 25, 50, 100]}
              data={data}
              filterable
              resizable={true}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: (e) => {
                      e.preventDefault();
                      ReactGA.event({
                        category: "User",
                        action: "View User Profile",
                      });
                      setCurrentUser(rowInfo.row._original);
                      setEditUserModal(true);
                    },
                  };
                } else {
                  return {};
                }
              }}
              columns={[
                {
                  Header: "ID",
                  accessor: "id",
                  width: 70,
                },
                {
                  Header: "First Name",
                  accessor: "firstName",
                },
                {
                  Header: "Last Name",
                  accessor: "lastName",
                },
                {
                  Header: "Email",
                  accessor: "email",
                },
                {
                  Header: "Phone",
                  accessor: "phone",
                },
                {
                  Header: "Role",
                  accessor: "roleId",
                  Cell: (props) => {
                    if (props.value) {
                      const role = getRoleMapping(props.value);
                      if (role) {
                        return role;
                      } else {
                        return props.value;
                      }
                    } else {
                      return props.value;
                    }
                  },
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "Super Admin") {
                      return row[filter.id] === 1;
                    } else if (filter.value === "Manager") {
                      return row[filter.id] === 2;
                    } else if (filter.value === "General User") {
                      return row[filter.id] === 3;
                    } else if (filter.value === "Accountant") {
                      return row[filter.id] === 4;
                    } else if (filter.value === "Lead Generator") {
                      return row[filter.id] === 5;
                    } else {
                      return false;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                      <option value="Super Admin">Super Admin</option>
                      <option value="Manager">Manager</option>
                      <option value="General User">General User</option>
                      <option value="Accountant">Accountant</option>
                      <option value="Lead Generator">Lead Generator</option>
                    </select>
                  ),
                },
                {
                  headerStyle: { textAlign: "left" },
                  Header: "Status",
                  accessor: "isActive",
                  width: 125,
                  Cell: (props) => {
                    if (props.value) {
                      return <Badge color={"success"}>Active</Badge>;
                    }
                    return <Badge color={"danger"}>Inactive</Badge>;
                  },
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "Active") {
                      return row[filter.id] === true;
                    } else if (filter.value === "Inactive") {
                      return row[filter.id] === false;
                    } else {
                      return false;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">All</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  ),
                },
              ]}
              defaultPageSize={25}
              showPaginationBottom={true}
              className="-striped -highlight"
              defaultFilterMethod={filterCaseInsensitive}
            />
          </CardBody>
        </Card>
      </GridItem>

      {/*Add User*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        open={addUserModal}
        disableScrollLock={true}
        TransitionComponent={Transition}
        onClose={() => setAddUserModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAddUserModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>New User</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <AddUser open={addUserModal} handleSuccess={setAddUserModal} />
        </DialogContent>
      </Dialog>

      {/*Edit User*/}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal + " " + classes.modalMediumXL,
        }}
        maxWidth={"lg"}
        open={editUserModal}
        disableScrollLock={true}
        TransitionComponent={Transition}
        onClose={() => setEditUserModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setEditUserModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h3 className={classes.modalTitle}>Edit User</h3>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <EditUser
            open={editUserModal}
            user={currentUser}
            handleSuccess={setEditUserModal}
          />
        </DialogContent>
      </Dialog>
    </GridContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    loading: state.user.userGetAllFetching,
    error: state.user.userGetAllError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllUsers: () => {
    dispatch({ type: USER_GET_ALL_REQUESTED });
  },
});

export default compose(
  withTimer,
  connect(mapStateToProps, mapDispatchToProps)
)(UsersTables);
