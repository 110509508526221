import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./ClockInButtonStyle";

const useStyles = makeStyles(styles);

export default function ClockInButton({ name, isNumber = true, handleClick }) {
  const classes = useStyles();

  return (
    <button
      onClick={handleClick}
      className={
        isNumber ? classes.clockInButton : classes.clockInButtonNoNumber
      }
    >
      {name}
    </button>
  );
}
